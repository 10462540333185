/* eslint-disable no-unreachable */
/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";

import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
//import Grid from '../layouts/gridnosidebarComponent';
import Grid from '../layouts/gridComponent';
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import CommentActions from '../layouts/commentsComponent';
import KpiActions from '../manage/kpiActionsComponent';
import KpiDeviationActions from '../manage/kpiDeviationActionsComponent';
import KpiTransitDelayActions from '../manage/kpiTransitDelayActions';
import Nooverspeed from '../manage/nooverspeedComponent.js';
import Notransit from '../manage/notransitComponent.js';
import RoutemapAction from '../manage/routemapActionComponent';
import RoutemapTransitAction from '../manage/routemaptransit';
import RoutemapNoGPSAction from '../manage/routemapnogps';
import RouteNightDrivemap from '../manage/routenightdrivemap';
import RoutemapenrouteAction from '../manage/routemapenroute';
import KpiNightDriveActions from '../manage/kpiNightDriveActionsComponent';
import Nonightdrive from '../manage/nonightdriveComponent.js';
import KpiEnrouteStoppagesActions from '../manage/kpiEnrouteStoppagesActionsComponent';
import KpiLoadingDelayActions from '../manage/kpiLoadingDelayActions';
import KpiUnLoadingDelayActions from '../manage/kpiUnLoadingDelayActions';
import Noenroutes from '../manage/noenroutesComponent.js';
import Gps from '../manage/gpsActionComponent.js';
import NoGps from '../manage/nogpsActionComponent.js';
import NoGpsLifetime from '../manage/nogpslifetimeActionComponent.js';
import Consignmentforceclose from '../manage/consignmentforceclose.js';
import ConsignmentDctComment from '../manage/consignmentdctcommentaction.js';
import CustomTooltip from '../common/tooltipaggrid';
import Select from 'react-select';
import ForceCloseSideBar from "./forceclosuresidebarcomponent";
import Modal from 'react-responsive-modal';
import CustomLoadingCellRenderer from "./customLoadingCellRenderer";
import AppendComponentIndex from '../common/appendComponents';
import { Timeline, TimelineItem }  from 'vertical-timeline-component-for-react';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
//import Counters from '../layouts/consignmentcountersComponent';
import Counters from './forceclosecountersComponent';
import DateFormater from './dateComponent';
import CSVFileValidator from 'csv-file-validator';
import { getDDMMYYYY,
	getDDMMYYYYHHMMSS,
	getYYYYMMDD,
	getYYYYMMDDHHMMSS,
	getHyphenYYYYMMDDHHMMSS,
	getHyphenYYYYMMDD,
	getHyphenDDMMYYYYHHMMSS,
	getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,
	getHyphenDDMMYYYY } from '../common/utils';
import 'react-vertical-timeline-component/style.min.css';
import DrawMap from '../common/drawmap';
import ConsignmentDrawMap from '../common/consignmentdrawmap';
import ComponentIndex from '../common/appendComponents';
import CustomDateComponent from '../common/dateFilterComponent';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import ProbableAction from "../layouts/probableDataComponent";

$.datetimepicker.setLocale('en');
var infoBox = require('../common/google-infowindow');

var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");
var reasonFlag=0;
var otherReasonFlag=0;
var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749','#F73E3E', '#B7B3B3' ];
var selectedColor;
var colorButtons = {};
var fencingLength=0;
var mapZoom=12;
var coordinates=[];
var geoFencingArea;
var containerslist=[];


const decipher = salt => {
	const textToChars = text => text.split('').map(c => c.charCodeAt(0));
	const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
	return encoded => encoded.match(/.{1,2}/g)
		.map(hex => parseInt(hex, 16))
		.map(applySaltToChar)
		.map(charCode => String.fromCharCode(charCode))
		.join('');
}
var decode = decipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
export default class ForceClosureComponent extends Component {

	constructor(props){

		super(props);
		this.displayData=[];
		this.state={
			pageTitle: "",
			deptcode:"",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
			eventAction: null,
			allRowData: [],
			activeFilter: null,
			uploadDivWidth:'0%',
			sliderTranslate:'',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			timelineinfo:'',
			consignment_code:'',
			mapinfo:'',
			selectedReason : "",
			dealer:'',
			maptruckno:'',
			loadshow:'show-n',
			showDiv:'show-n',
			alertshow:'fade',
			alertmg:'show-n',
			alerterrmg:'show-n',
			sliderCommentTranslate:"",
			commentsRowData:[],
			overly:'show-n',
			alerterrshow:'fade',
			alert:null,
            show: false,
			basicTitle:'',
			rownode:'',
			leg_no:'',
			basicType:"default",
			modules: AllModules,
			filterstatess:{},
      	    columnsdatas:{},
      	    rowgroupdcols:[],
			valuecolumns:[],
			activeconsignemnt:[],
			activetrucks:[],
			transitdelays:[],
			gpsdatana:[],
			overspeedtrucks:[],
			nightdrive:[],
			open: false,
			openforceclose: false,
      	    pivotmodeis:false,
			pivotcols:[],
			defTransitCoords:'',
			routeTruck:{},
			sidebarSubHeader:'',
			googelRoutes:'',
			forceCloseRowNode : "",
			plants:[],
      	    defaultColDef: {
      	    	sortable: true,
				filter: true,
				resizable: true,
				tooltipComponent: 'customTooltip',
      	    },
      	    rowData: null,
			  maprowData:null,
			rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
			  rowGroupPanelShow: 'always',
			  overlayNoRowsTemplate: "",
			  frameworkComponents: {
				consignmentActions:ConsignmentActions,
				commentActions : CommentActions,
				kpiActions:KpiActions,
				kpiDeviationActions:KpiDeviationActions,
				kpiTransitDelayActions:KpiTransitDelayActions,
				nooverspeed:Nooverspeed,
				notransit:Notransit,
				kpiNightDriveActions:KpiNightDriveActions,
				nonightdrive:Nonightdrive,
				kpiEnrouteStoppagesActions:KpiEnrouteStoppagesActions,
				noenroutes:Noenroutes,
				kpiLoadingDelayActions:KpiLoadingDelayActions,
				kpiUnLoadingDelayActions:KpiUnLoadingDelayActions,
				dateFormater:DateFormater,
				gps:Gps,
				nogps:NoGps,
				noGpsLifetime:NoGpsLifetime,
				RoutemapenrouteAction:RoutemapenrouteAction,
				customLoadingCellRenderer: CustomLoadingCellRenderer,
				consignmentforceclose:Consignmentforceclose,
				customDateComponent:CustomDateComponent,
				consignmentDctComment:ConsignmentDctComment,
				customTooltip: CustomTooltip,
				ProbableAction : ProbableAction
			},
			tooltipShowDelay: 0,
      	    animateRows: true,
      	    debug: true,
      	    showToolPanel: false,
			uppressAggFuncInHeader: true,

			childconfs: [],
			childrow:[],
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams:{},
			rowClassRules: {
				//"highlitRow": "data.transit_delay == 1",
				//"green":"data.status >= 3"

			},
			triptype:[{"value": "all", "label": "All"}],
			tripvalue:'',
			consigner:[{"value": "all", "label": "All"}],
			originalData:[],
			isCounterDisplay:1,
			screenpage:'',
			pagetitle:'Active Consignments',
			countersjson:'',
			originalcountersjson:'',
			consigneecoords:'',
			consigner_coordinates:'',
			containerslist:[],
			forceclosedata:'',
			reached_dealer_time:moment.parseZone().format('YYYY-MM-DD'),
			reachedhh:'',
			reachedmm:'',
			reachedss:'00',
			left_dealer_time:moment.parseZone().format('YYYY-MM-DD'),
			lefthh:'',
			leftmm:'',
			leftss:'00',
			invoice_time:'',
			invoicehh:'',
			invoicemm:'',
			invoicess:'00',
			reasonforceclose:'',
			sliderTranslatesidebar:'',
			consignment_details:'',
			bulkslide:'',
			dtccommentRowNode : [],
			dct_comment:'',
			sliderDCTCommentTranslate:"",
			tolls : [],
			getRowHeight: function(params) {
				if(typeof params.data.dct_comment != 'undefined'){
					return (
					//params.api.getSizesForCurrentTheme().rowHeight *
					(50 * Math.floor(params.data.dct_comment.length / 50))
					);
				}
				else{
					return 30;
				}
			},
			defaultsdate:'',
			defaultedate:'',
			usergridstate:[],
			screenurl:"",
			screentitle:"",
			loginplant:"",
			deliveryDate:''
		}
		this.onShowRouteDiv = this.onShowRouteDiv.bind(this);
		this.onShowTimelineDiv = this.onShowTimelineDiv.bind(this);
		this.onShowRouteMap = this.onShowRouteMap.bind(this);
		this.onShowTransitRouteMap = this.onShowTransitRouteMap.bind(this);
		this.onShowNoGPSRouteMap = this.onShowNoGPSRouteMap.bind(this);
		this.onShowGoogleRoute = this.onShowGoogleRoute.bind(this);
		this.selectConsignerOptionsItems = this.selectConsignerOptionsItems.bind(this);
		this.onShowEnrouteRouteMap = this.onShowEnrouteRouteMap.bind(this);
		this.onClickShowMapView = this.onClickShowMapView.bind(this);
		this.onClickForceClose = this.onClickForceClose.bind(this);
		this.formForceCloseHandler = this.formForceCloseHandler.bind(this);
		this.formDctCommentHandler = this.formDctCommentHandler.bind(this);
		this.changeHandler = this.changeHandler.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.uploadBulkFormHandler = this.uploadBulkFormHandler.bind(this);
		this.onShowCommentsDiv = this.onShowCommentsDiv.bind(this);
		this.onLoadShowForceConsignmentsList = this.onLoadShowForceConsignmentsList.bind(this);
		this.cipher = this.cipher.bind(this);
	}
	cipher = (salt) => {
		const textToChars = text => text.split('').map(c => c.charCodeAt(0));
		const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
		const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
		try{
			return text => text.split('')
				.map(textToChars)
				.map(applySaltToChar)
				.map(byteHex)
				.join('');
		}
		catch(e){}

	}

	logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
	componentDidMount(){
		// alert("function calling");
		// loadDateTimeScript();

		var loguser = decode(localStorage.getItem("m"));
		// console.log("LOGUSER ", JSON.parse(loguser));
		var loginplant = "";
		try{
			var luser = JSON.parse(loguser);
			loginplant = luser.is_plant_code;
			// console.log("logplant", loginplant)
			this.setState({
				loginplant:loginplant,
				// consigner:[{"value":luser.is_plant_code,"label":luser.is_plant_name}]

			})
		}
		catch(e){

		}


		var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		$('.datetimepicker_mask').datetimepicker({
			mask:'39-19-9999 29:59',
			format:'d-m-Y H:i'
		});
		$('.datetimepicker_date').datetimepicker({
			mask:'39-19-9999',
			format:'d-m-Y',
			timepicker:false
		});
		var index  =window.document.getElementsByTagName("script")[1]
		var script = window.document.createElement("script")
		script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
		script.async=true
		script.defer = true
		index.parentNode.insertBefore(script,index)

		var edate = moment.parseZone().format('YYYY-MM-DD');
		var sdate = moment.parseZone().subtract(30, 'days').format('YYYY-MM-DD');
		// var sdate = '2020-03-01';

		this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({eventAction: eventAction});
		//console.log("Props ", this.props.match)
		this.setState({
			loadshow:'show-m',
			startDate:sdate,
			endDate:edate,
			defaultsdate:sdate,
			defaultedate:edate
		});
		var urlpath = '/consignments/forceclosureconsignments';
		var dpt;
		var isCounterDisplay=1;
		var screenpage='';
		var title='';
		var parameters={};

		if(this.props.match.path == "/sndforceclosure")
		{
			dpt='SNDG';
			title = 'Force Closure';
			var parameters = {
				consigner:this.state.consigner,
				dept_code:encode(dpt),
				startDate:sdate,
				endDate:edate,
			}
		}

		else if(this.props.match.path == "/tnpforceclosure")
		{
			dpt='LOG-TNP';
			title = 'Force Closure (Train and Production)'
			var parameters = {
				consigner:this.state.consigner,
				triptype:this.state.triptype,
				dept_code:encode(dpt),
				startDate:sdate,
				endDate:edate,
			}
		}

		else if(this.props.match.path == "/prtforceclosure")
		{
			dpt='LOG-PRT';
			title = 'Force Closure (Spare Parts)'
			var parameters = {
				consigner:this.state.consigner,
				dept_code:encode(dpt),
				startDate:sdate,
				endDate:edate,
			}
		}

		//console.log("isCounterDisplay ", isCounterDisplay);
		//console.log("this.props.match.path ", this.props.match.path)
		this.setState({
			deptcode:dpt,
			isCounterDisplay:isCounterDisplay,
			screenpage:screenpage,
			pagetitle:title
		});

		//Use following code after ACL enable


		//console.log("dept_code ", this.state.dept_code)
		/*urlpath = '/consignments/consignmentByDepartment';
			dpt=localStorage.getItem('dept_code');
		redirectURL.post(urlpath,consignData)    */

		var activeconsignemntarr=[];
		var activetrucksarr=[];
		var transitdelaysarr=[];
		var gpsdatanaarr=[];
		var overspeedtrucksarr=[];
		var nightdrivearr=[];
		var enroutearr=[];
		var loadingarr=[];
		var unloadingarr=[];
		var alltrucksarr =[];

		redirectURL.post("/consignments/plants",{dept_code:encode(dpt)})
		.then((resp) => {
				var records = resp.data;
				var plants=[];
				if(records.length > 0)
				{
					records.map((item) => {
						if(loginplant != "" && loginplant != undefined)
						{
							if(item.consigner_code == loginplant)
							{
									plants.push(item);
							}
						}
						else {
							plants.push(item);
						}

					})
				}
				this.setState({
					plants:plants
				});
		})

		redirectURL.post("/consignments/usergridstates",{userId:localStorage.getItem("userid"),screenurl:window.location.pathname})
		.then( async (resp) => {
			await this.setState({
				usergridstate:resp.data
			});
			await this.restoreGridStates();
		})

		this.onLoadShowForceConsignmentsList(urlpath, parameters)
	};

	onLoadShowForceConsignmentsList(urlpath, parameters)
	{
		redirectURL.post(urlpath, parameters)
		  .then((response) => {
			var records = response.data.consignments;
			//console.log(records.length);
            var pagecounters = response.data.counters;
			// console.log("pagecounters", pagecounters);
			// console.log("records ", records)
			this.setState({
				rowData:records,
				allRowData:records,
				originalData:records,
				loadshow:'show-n',
				countersjson:pagecounters,
				originalcountersjson:pagecounters
			});


		  })
		  .catch(function (error) {
		    console.log(error);
			});
	}
	onShowCommentsDiv = async (params) =>{
		// console.log('',params);
	   var reqparams = {
		   consignment_code : params.data.consignment_code,
		   truck_no : params.data.truck_no,
		   dept_code : this.state.deptcode,
		   screen : "consignments",
		   type : "Force Close"
	   }
	//    console.log('',reqparams);

	   let eventLabel = googleAnalytics.page.action.comments;
	   let eventOptions = {
		   "category": this.state.pagetitle,
		   "action": this.state.eventAction,
		   "label": eventLabel,
	   }
	   googleAnalytics.logEvent(eventOptions);

	   var commentHeaders = [
		   {
			   headerName : "Truck No",
			   field : "truck_no",
			   resizable : true,
			   width:100,
			   filter : true
			 },
			 {
			   headerName : "Consignment Code",
			   field : "consignment_code",
			   resizable : true,
			   width:120,
			   filter : true
			 },
			 {
			   headerName : "User Type",
			   field : "user_type",
			   width:100,
			   resizable : true,
			   filter : true,
			   valueGetter : function(params){
				 if(params.data.user_type == "ORGUSER"){
				   return "MSIL"
				 }
				 if(params.data.user_type == "DCTUSER"){
				   return "DCT"
				 }
				 if(params.data.user_type == "TRANSPORTER"){
				   return "TRANSPORTER"
				 }
			   }
			 },
			 {
			   headerName : "Comment",
			   field : "comment",
			   width:250,
			   cellClass: ['wraptext'],
			   resizable : true,
			   filter : true
			 },
			 {
			   headerName : "Commented By",
			   field : "username",
			   width:150,
			   resizable : true,
			   filter:true
			 },

			 {
			   headerName : "Commented Date",
			   field : "comment_date",
			   resizable : true,
			   filter : true,
			   width:150,
			   valueGetter : function(params){
				 if(params.data.comment_date != ""){
				   return getDDMMYYYYHHMMSS(params.data.comment_date);
				 }
			   }
			 }
	   ]
	   if(params != 0)
	   {
		   var overspeedData=[];

		   //console.log("Step 3 ",overspeedData)
		   var propsdata = params;
		   var deptcode = this.state.deptcode;
		   await this.setState({

			   detailCellRendererParams:{
				   detailGridOptions: {
					   columnDefs: commentHeaders,
					   overlayNoRowsTemplate: 'No rows to show',

				   },
				   getDetailRowData:async function(param) {
					   param.successCallback([]);
					//    console.log("Step 4 ",param)
					//    console.log("reqparams",reqparams);
					   await redirectURL.post("/consignments/getCommentLogs",{
						   consignment_code : param.data.consignment_code,
						   truck_no : param.data.truck_no,
						   dept_code : deptcode,
						   screen : "consignments",
						   type : "Force Close"
					   }).then(async (response) =>{
						//    console.log("getCommentLogs ",response.data)
						//    console.log(response.data.data);
						   var comments=response.data.data;
						   param.successCallback(comments);
						   //console.log("Step 2 ",response.data)

					   }).catch(function(error){
						   console.log(error);
					   })

				   },
				   masterDetail: true
			   }
		   });
		//    console.log(propsdata);
		   if(params.column.colDef.field == 'comment_exists')
		   {

			   params.node.setExpanded(!params.node.expanded);
		   }
		   else{

			   params.node.setExpanded(false);
		   }

	   }
	   else{
		   this.setState({

			   detailCellRendererParams:{
				   detailGridOptions: {
					 columnDefs: commentHeaders

				   },
				   getDetailRowData: function(params) {
					   //console.log("Child ", params);
					 params.successCallback([]);
				   }
				 }
		   });
	   }
   }
	onShowTimelineDiv = (e) => {
		//console.log("Props data ", e)
		this.setState({
			loadshow:'show-m'
		});
		var reqData = {
            consignment_code:e.consignment_code
        }
        //console.log("reqData: ", reqData);
		redirectURL.post('/consignments/timeline',reqData, {
            headers:{
                'content-type':'application/json'
            }
        })
		.then((response) => {
			var records = response.data;
			//console.log("records", records);

			this.setState({
				uploadDivWidth:'30%',
				sliderTranslate:"slider-translate-50p",
				showDiv:'show-m',
				timelinedata:records,
				loadshow:'show-n',
				overly:'show-m'
			});
		})
		.catch(function (error) {
		console.log(error);
		});

	}

	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderTranslate:"",
			tabsliderTranslate:'',
			sliderCommentTranslate:"",
			showDiv:'show-n',
			sliderTranslatesidebar:"",
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			overly:'show-n',
			loadshow:'show-n'
		});
	}
	onShowGoogleRoute(consignmentcode, consigneecode, legno,truck_no){
		// console.log("GOogle params ", consignmentcode+", "+ consigneecode+", "+legno)
		var reqData = {
			consignment_code:consignmentcode,
			consignee_code:consigneecode,
			leg_no:legno,
			truck_no:truck_no
		}
		redirectURL.post('/consignments/googlemaproutes',reqData, {
            headers:{
                'content-type':'application/json'
            }
        })
		.then((response) => {
			// console.log("Google ", response)
			//var gdt = '[{	"lat": 28.367420000000003,	"lng": 76.90442},{	"lat": 28.379795,	"lng": 76.903625},{	"lat": 28.380361999999998,	"lng": 76.899445},{	"lat": 28.383162,	"lng": 76.90275},{	"lat": 28.384619,	"lng": 76.900024},{	"lat": 28.385069,	"lng": 76.900024},{	"lat": 28.384990000000002,	"lng": 76.8998}]'
			this.setState({
				googelRoutes:response.data.googleroute
			})
		})
		.catch(function(e){
			console.log(e)
		})
	}
	onShowRouteDiv = (e) => {
		if (googleAnalytics.page.enableGA) {
            let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		// console.log("IN onShowRouteDiv, Rute params ", e)
		this.setState({
			loadshow:'show-m',
			maptruckno:e.truck_no
		});

		// If gateouttime is current time - It shows "No Route data available"
		if(e.invoice_time)
		{
			// console.log("came into invoice time", e.invoice_time)
			//console.log("Step1", e.invoice_time)
			var gateouttime = moment.parseZone(e.invoice_time).format('YYYY-MM-DD HH:mm:ss');
		}
		else{
			//console.log("Step2")
			if(e.gate_in_time)
			{
				// console.log("came into gate in time", e.gate_in_time)
				//console.log("Step3")
				var gateouttime = moment.parseZone(e.gate_in_time).format('YYYY-MM-DD HH:mm:ss');
			}
			else{
				//console.log("Step4")
				if(e.gate_out_time){
					//console.log("Step5")
					// console.log("came into gate out time", e.gate_out_time)
					var gateouttime = moment.parseZone(e.gate_out_time).format('YYYY-MM-DD HH:mm:ss');
				}
			}
		}

		// if (e.gate_out_time) {
		// 	var gateouttime = e.gate_out_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		// }

		var edate ='';
		var end_date;
		if(e.status <= 4)
		{
			//var ms = new Date(e.gate_out_time).getTime() + ((e.transit_time+1) * 86400000);
			//var edatesecond = new Date(ms);
			end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')

			edate = getHyphenYYYYMMDDHHMMSS(end_date);
		}
		else if(e.status == 5)
		{
			if (e.left_dealer_marked) {
				end_date = e.left_dealer_marked.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
				edate = getHyphenYYYYMMDDHHMMSS(e.left_dealer_marked);
			}

		}
		else{
			end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')

			edate = getHyphenYYYYMMDDHHMMSS(end_date);

		}

		//console.log("end date",end_date);
		//console.log("checking e",e)

		if ((e.transit_time) > 0)
		{
			var seconddate = new Date(new Date(e.gate_out_time).getTime() + ((e.transit_time+1) *24*60*60*1000));
		}
		else{
			var seconddate = new Date(new Date(e.gate_out_time).getTime() + 2*24*60*60*1000);
		}

		var sdate = seconddate.getFullYear()+"-"+(seconddate.getMonth()+1)+"-"+seconddate.getDate()+" "+seconddate.getHours()+":"+seconddate.getMinutes()+":"+seconddate.getSeconds();
		if (!end_date) {
			// When end date is unavailable then current date is considered as end date.
			end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
			//console.log("Defining end_date ", end_date);
		}
		var reqData = {
            truck_no:e.truck_no,
			invoice_no:e.invoice_no,
			consignee_code:e.consignee_code,
			gate_out_time:gateouttime,
			seconddate:end_date,
			screen:"consignment"
        }
        // console.log("reqData: ", reqData);
		redirectURL.post('/consignments/maproutesNewWithInvoice',reqData, {
            headers:{
                'content-type':'application/json'
            }
        })
		.then((response) => {
			var records = response.data;
			//console.log("Props data ", e)

			this.setState({
				loadshow:'show-m'
			});
			if(records.coords.length == 0)
			{
				//console.log("records", records.coords);
				this.setState({
					show: true,
					basicTitle:'No Route data available',
					basicType:"danger",
					loadshow:'show-n'
				});
			}
			else{
				if(records != '')
				{
					var sdate = e.gate_out_time;
					if(e.invoice_time)
					{
						// console.log("came into invoice time", e.invoice_time)
						//console.log("Step1", e.invoice_time)
						var sdate = e.invoice_time;
					}
					else{
						//console.log("Step2")
						if(e.gate_in_time)
						{
							// console.log("came into gate in time", e.gate_in_time)
							//console.log("Step3")
							var sdate = e.gate_in_time;
						}
						else{
							//console.log("Step4")
							if(e.gate_out_time){
								//console.log("Step5")
								// console.log("came into gate out time", e.gate_out_time)
								var sdate = e.gate_out_time;
							}
						}
					}


					var edate ='';
					if(e.status <= 4)
					{
						//var ms = new Date(e.gate_out_time).getTime() + ((e.transit_time+1)*86400000);
						//var edatesecond = new Date(ms);
						end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
						edate = getDDMMYYYYHHMMSS(end_date);
					}
					else if(e.status == 5)
					{
						if (e.left_dealer_marked)
						{
							edate = getDDMMYYYYHHMMSS(e.left_dealer_marked);
						}
						else{
							end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
							edate = getDDMMYYYYHHMMSS(end_date);
						}
					}
					//console.log("End Date ", edate)
					this.setState({
						sliderRouteTranslate:"slider-translate-60p",
						showDiv:'show-m',
						mapinfo:records,
						dealer:e.consignee_code,
						consignment_code:"Consignment : "+e.consignment_code,
						maptruckno:e.truck_no,
						routeTruck:	{"truck_no":e.truck_no,"startTime":sdate,"endTime": edate},
						loadshow:'show-n',
						sidebarSubHeader:"Consignment Information",
						overly:'show-m',
						rownode:e,
						leg_no:0,
						tolls : records.tolls
					});
					this.onShowGoogleRoute(e.consignment_code, e.consignee_code, 0, e.truck_no)
					this.renderMap();

				}
			}


		})
		.catch(function (error) {
			console.log(error);
		});
	}
	onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderRouteTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
			sliderCommentTranslate:'',
			overly:'show-n'
		});
	}



	onGridReady = params => {
		this.gridApi = params.api;

	    //console.log("Grid is: ", params);
	    this.gridColumnApi = params.columnApi;

	   //    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;

	//    };
	    if(this.state.deptcode == 'LOG-PRT')
	    {
			// console.log("Step 1 ")
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception","loading_delay_exception","unloading_delay_exception","trip_type"], false);

			this.gridColumnApi.setColumnsVisible(["trip_type"], false);
		}
		else if(this.state.deptcode == 'SNDG')
		{
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], true);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], true);
			//this.gridColumnApi.setColumnsVisible(["unloading_delay_exception"], true);
			this.gridColumnApi.setColumnsVisible(["consignee_city"], true);
			this.gridColumnApi.setColumnsVisible(["trip_type"], false);
		}
		else if(this.state.deptcode == 'LOG-TNP')
		{
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], true);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], true);
			//this.gridColumnApi.setColumnsVisible(["unloading_delay_exception"], true);
			this.gridColumnApi.setColumnsVisible(["consignee_city"], true);
			this.gridColumnApi.setColumnsVisible(["trip_type"], true);
		}
		else if((this.props.match.path == "/sndconsignments")
		|| (this.props.match.path == "/prtconsignments")
		|| (this.props.match.path == "/tnpconsignments"))
		{
			this.gridColumnApi.setColumnsVisible(["unloading_delay_exception"], false);

		}
		else{}

	};


	onGridState = () =>{
		 //console.log(this.gridApi);

		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns,
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();

		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
		   this.gridApi.setFilterModel(window.filterState);
	}

	renderMap = () => {
		//loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyA08YKZGPl0ii3K-t4_guTbdtjscGgdPns&libraries=places,drawing&callback=initMap");
		//window.initMap = this.initMap
	}

	initMap = () => {
    	//console.log("mapinfo ",this.state.mapinfo);
    	var currentwindow;

		var jsondata = this.state.mapinfo;
		var locations = jsondata.breaks;
		var arr = jsondata.coords;


		//console.log("Here",arr);
		if(arr.length > 0)
		{
			var lt=arr[0].lat;
			var ln=arr[0].lng;
		}
		else{
			if(this.state.defTransitCoords == '')
			{
				var lt=28.4519751;
				var ln=77.0310713;
			}
			else{
				var lt=JSON.parse(this.state.defTransitCoords).lat;
				var ln=JSON.parse(this.state.defTransitCoords).lng;
			}

		}
        var routeinfo = jsondata.route_details;
    	 var mapOptions = {
    			zoom: 7,
				zoomControl: true,
				mapTypeControl: true,
				scaleControl: true,
				streetViewControl: true,
				rotateControl: true,
				fullscreenControl: true,
    	          labels:true,
    	          mapTypeControlOptions: {
    	            mapTypeIds: ['hybrid', 'roadmap'],
    	          },
    	          center: new window.google.maps.LatLng(lt,ln),
    	          mapTypeId: window.google.maps.MapTypeId.ROADMAP
    	        };

    	        var map = new window.google.maps.Map(document.getElementById('map'),
    	            mapOptions);

				var bounds = new window.google.maps.LatLngBounds();
				bounds.extend(new window.google.maps.LatLng(lt,ln));
    			 // Create our info window content
    			var currentinfowindow = null;
    	       var line=new window.google.maps.Polyline(
    	              {
    	                map:map,
    									strokeColor: '#157254',
    									strokeOpacity: 1.0,
    									strokeWeight: 2.5,
    	                		icons: [{
    	                          icon: {
    	                                  path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    	                                  strokeColor:'#ff8c52',
    	                                  fillColor:'#ff8c52',
    	                                  fillOpacity:1,
    									  strokeWeight: 2
    	                                },
    	                          repeat:'100px',
    	                          path:[]
    	                       }]
    	                 });

			if(arr.length > 0)
			{
    		  for (let i = 0; i < arr.length; i++) {
    				var path=line.getPath().getArray();

    						let latLng=new window.google.maps.LatLng(arr[i].lat,arr[i].lng);
    						path.push(latLng);
    	          			line.setPath(path);




    	          //Change line color based on map type
    	        window.google.maps.event.addListener( map, 'maptypeid_changed', function() {
    	            if (map.getMapTypeId() =='hybrid' || map.getMapTypeId() =='satellite')
    	            {
    	                var line=new window.google.maps.Polyline(
    	                  {
    	                    map:map,
    	                    strokeColor: '#FFFFFF',
    	                    strokeOpacity: 1.0,
    	                    strokeWeight: 2.5,
    	                    icons: [{
    	                              icon: {
    	                                      path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    	                                      strokeColor:'#ff8c52',
    	                                      fillColor:'#ff8c52',
    	                                      fillOpacity:1,
    	                                      strokeWeight: 2
    	                                    },
    	                              repeat:'100px',
    	                              path:[]
    	                           }]
    	                     });
    	            }
    	            else
    	            {
    	                var line=new window.google.maps.Polyline(
    	                  {
    	                    map:map,
    	                    strokeColor: '#157254',
    	                    strokeOpacity: 1.0,
    	                    strokeWeight: 2.5,
    	                    icons: [{
    	                              icon: {
    	                                      path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    	                                      strokeColor:'#ff8c52',
    	                                      fillColor:'#ff8c52',
    	                                      fillOpacity:1,
    	                                      strokeWeight: 2
    	                                    },
    	                              repeat:'100px',
    	                              path:[]
    	                           }]
    	                     });

    	            }

    	              for (i = 0; i < arr.length; i++) {
    	                    var path=line.getPath().getArray();
    	                    let latLng=new window.google.maps.LatLng(arr[i].lat,arr[i].lng);
    						path.push(latLng);
    	          			line.setPath(path);
    	                            //map.setCenter(latLng);
    	                  }

    	             var x = map.getZoom();
    	             var c = map.getCenter();
    	             window.google.maps.event.trigger(map, 'resize');
    	             map.setZoom(x);
    	             map.setCenter(c);
				} );

				bounds.extend(new window.google.maps.LatLng(arr[i].lat,arr[i].lng));
    		  }

		  var infowindow = new window.google.maps.InfoWindow();
			var marker, l;

			for (l = 0; l < locations.length; l++) {
		      marker = new window.google.maps.Marker({
	    	  position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
		  	  icon:require('../../assets/icons/cf.png'),
		      map: map,
			});

		      window.google.maps.event.addListener(marker, 'click', (function(marker, l) {
		        return function() {
		          //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time_seconds)+")</p>";
				 var contentarr = []
				 var header = "Break - "+(l+1)
				 contentarr.push({"key":"Start time", "value":getDDMMYYYYHHMMSS(locations[l].break_start)})
				 contentarr.push({"key":"End time", "value":getDDMMYYYYHHMMSS(locations[l].break_end)+" ("+ secondsToString(locations[l].break_time_seconds)+")"})
				  var contentString = infoBox(marker.icon, header, contentarr)
				  infowindow.setContent(contentString);
				  currentwindow = infowindow;
				  infowindow.open(map, marker);
				  //console.log(marker.position.toJSON());
				  //console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
				}
			})(marker, l));
			}
			marker = new window.google.maps.Marker({
		        position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
		  		icon:require('../../assets/icons/track_start.png'),
		        map: map,
		      });
	   	   window.google.maps.event.addListener(marker, 'click', (function(marker) {
	   	        return function() {
	   	        	//	console.log(clusters[0])
					   var contentarr = []
	       		//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
	   	        var header = "Starting Point"
				contentarr.push({"key":"Started at ", "value":getDDMMYYYYHHMMSS(routeinfo.start_time)})
				var contentString = infoBox(marker.icon, header, contentarr,'')


	   			  infowindow.setContent(contentString);
	   			  currentwindow = infowindow;
	   			  infowindow.open(map, marker);
	   			  //console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
	   			}
	   		})(marker));
	   	  marker = new window.google.maps.Marker({
	        position: new window.google.maps.LatLng(arr[(arr.length-1)].lat, arr[(arr.length-1)].lng),
	  		icon:require('../../assets/icons/truck-end.png'),
	        map: map,
	      });
		   window.google.maps.event.addListener(marker, 'click', (function(marker) {
		        return function() {
		        	//	console.log(clusters[0])
					var contentarr = []
	   		    //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
				   var header = "End Point"
				   contentarr.push({"key":"End at ", "value":getDDMMYYYYHHMMSS(routeinfo.end_time)})
				   var contentString = infoBox(marker.icon, header, contentarr)

				  infowindow.setContent(contentString);
				  currentwindow = infowindow;
				  infowindow.open(map, marker);
				 // console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
				}
			})(marker));
		}
		map.fitBounds(bounds)
    }



	onShowOverspeedKpi = async (params) => {
		let eventLabel = googleAnalytics.page.action.overspeed;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		// console.log("IN onShowOverspeedKpi, params = ",params);
		//console.log(params.column.colId);
		if(params != 0)
		{
			var overspeedData=[];

			//console.log("Step 3 ",overspeedData)
			var propsdata = params;
			await this.setState({

				detailCellRendererParams:{
					detailGridOptions: {
						columnDefs: [
							{ headerName: "",field: "_id",
								width:50,
								cellRendererFramework:RoutemapAction,
								resizable: true
							},
							{ headerName: "Truck No",field: "truck_no", filter:true,resizable: true },
							{ headerName: "Speed (km/h)",field: "speed", filter:true,resizable: true },
							{ headerName: "Reported At",field: "first_instance", filter:true,resizable: true,
								valueGetter:function(params){
									return getDDMMYYYYHHMMSS(params.data.first_instance);
								}
							},
						],

					},
					getDetailRowData: function(param) {
						// console.log("Step 4 ",param)
						redirectURL.post('/consignments/overspeed', {
							consignment_code:param.data.consignment_code,
							truck_no:param.data.truck_no
						})
						.then(async (response) =>{
							//console.log("Step 1 ",response.data)
							overspeedData=response.data;
							param.successCallback(overspeedData);
							//console.log("Step 2 ",response.data)

						});

					},
					masterDetail: true
				}
			});

			if(propsdata.column.colDef.field == 'overspeeding_exception')
			{

				propsdata.node.setExpanded(!propsdata.node.expanded);
			}
			else{

				propsdata.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else{
			this.setState({

				detailCellRendererParams:{
					detailGridOptions: {
					  columnDefs: [
						{ headerName: "Truck No",field: "truck_no" },
						{ headerName: "Speed",field: "speed" },
						{ headerName: "Reported At",field: "first_instance" }
					]

					},
					getDetailRowData: function(params) {
						//console.log("Child ", params);
					  params.successCallback([]);
					}
				  }
			});
		}

	}

	onShowTransitKpi = async (params) => {
		if(params != 0)
		{
			let eventLabel = googleAnalytics.page.action.transitDelay;
			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventAction,
				"label": eventLabel,
			}
			googleAnalytics.logEvent(eventOptions);
			//console.log("IN onShowTransitKpi, params = ",params);
			//console.log(params);
			//console.log("Consign onShowTransitKpi params = ",params);
			//console.log(params.column.colId);
			if(params != 0)
			{
				var transitedelaydata=[];

				await this.setState({

					detailCellRendererParams:{
						suppressRefresh: true,
						detailGridOptions: {
							headerHeight:50,
							columnDefs: [
								{ headerName: "",field: "_id",

									cellRendererFramework:RoutemapTransitAction,
									width:50,
									suppressSizeToFit: true,
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}

									}
								},
								{ headerName: "Truck No",field: "truck_no", width:100,resizable: true,
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}

									}
								},
								{ headerName: "Leg Start Time",field: "leg_start", width:120,resizable: true,
									valueGetter:function(params){
										return getDDMMYYYYHHMMSS(params.data.leg_start);
									},
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}

									}
								},
								{ headerName: "Leg End Time",field: "leg_end_eta",
								width:120,resizable: true,
									valueGetter:function(params){
										return getDDMMYYYYHHMMSS(params.data.leg_end_eta);
									},
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}

									}
								},
								{ headerName: "Exp. Leg Distance (KM)",
								field: "leg_expected_distance", width:140,resizable: true,
									valueGetter:function(params){
										return Math.round(params.data.leg_expected_distance);
									},
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}

									}
								},
								{ headerName: "Actual Leg Distance Covered (KM)",
								field: "actual_leg_distance", width:140,resizable: true,
									valueGetter:function(params){
										//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
										if(params.data.actual_leg_distance != '' && params.data.actual_leg_distance != undefined)
										{
											return Math.round(params.data.actual_leg_distance);
										}
										else{
											return 0;
										}

									},
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}

									}
								},
								{ headerName: "Exp. Distance from Trip Start(KM)",
								field: "expected_distance_start_to_leg", width:140,resizable: true,
									valueGetter:function(params){
										//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
										if(params.data.expected_distance_start_to_leg != '' && params.data.expected_distance_start_to_leg != undefined)
										{
											return Math.round(params.data.expected_distance_start_to_leg);
										}
										else{
											return 0;
										}

									},
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}

									}

								},
								{ headerName: "Actual Distance from Trip Start (KM)",
								field: "actual_distance_traveled_start_to_leg", width:140,resizable: true,
									valueGetter:function(params){
										//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
										if(params.data.actual_distance_traveled_start_to_leg != '' && params.data.actual_distance_traveled_start_to_leg != undefined)
										{
											return Math.round(params.data.actual_distance_traveled_start_to_leg);
										}
										else{
											return 0;
										}

									},
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}

									}


								},

								{
									headerName: "Google Distance from Start (KM)",
									field: "actual_start_to_leg_google_distance",
									width:140,
									resizable: true,
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}

									}


								},
								{
									headerName: "GPS Data Available",
									field: "no_gps_data",
									width:140,
									resizable: true,
									valueGetter:function(params){
										//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
										if(params.data.no_gps_data == 1)
										{
											return "Not Available";
										}
										if(params.data.no_gps_data == 0)
										{
											return "Available";
										}

									},
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}

									}


								},


								{
									headerName: "Trip Completed",
									field: "trip_completed",
									width:140,
									resizable: true,
									valueGetter:function(params){
										//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
										if(params.data.trip_completed == 1)
										{
											return "Yes";
										}
										if(params.data.trip_completed == 2)
										{
											return "";
										}
										if(params.data.trip_completed == 0)
										{
											return "";
										}

									},
									cellClass:function(params){
										if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
										{
											return 'green';
										}
										else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
										{
											return 'lightred';
										}
										else{
											return '';
										}

									}


								}
							],
							overlayNoRowsTemplate: 'No transit time available for this Consignee',

						},
						getDetailRowData: function(params) {
							//console.log("Step 4 ",transitedelaydata)
							//console.log("Step 4 ",params)
							redirectURL.post('/consignments/transitdelay', {
								consignment_code:params.data.consignment_code,
								consignee_code:params.data.consignee_code,
								truck_no:params.data.truck_no
							})
							.then(async (response) =>{
								//console.log("Step 1 ",response.data)
								transitedelaydata=response.data;
								var legsarr=[]
								transitedelaydata.map((item) => {
									//if(item.trip_completed < 2)
									//{
									legsarr.push(item)
									//}
								})
								// console.log('transitedelaydata ', transitedelaydata);

								params.successCallback(legsarr);
								//console.log("Step 2 ",response.data)

							});

						}
					}
				});

				//if( params.column.colId == 'transit_delay' || params.column.colId == 'transit_delay_1' ||   params.column.colId == 'no_gps_data' || params.column.colId == 'no_gps_data_1')
				if(params.column.colDef.field == 'transit_delay' || params.column.colDef.field == 'no_gps_data')
				{
					params.node.setExpanded(!params.node.expanded);
					//console.log("expanded ",params.node.expanded)
					if(params.node.expanded == false)
					{
						transitedelaydata=[]
					}
				}
				else{

					params.node.setExpanded(false);
				}
				//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

			}
			else{
				this.setState({

					detailCellRendererParams:{
						detailGridOptions: {
						columnDefs: [
							{ headerName: "Truck No",field: "truck_no" },
							{ headerName: "Speed (km/h)",field: "speed" },
							{ headerName: "Reported At",field: "first_instance" }
						]

						},
						getDetailRowData: function(params) {
							//console.log("Child ", params);
						params.successCallback([]);
						}
					}
				});
			}
		}
		else
		{
			this.setState({
				show:true,
				basicTitle:"Transit for this consignment has not yet started",
				basicType:"info"
			});
		}

	}

	/*NO GPS DATA*/


	onShowNoGPSKpi = async (params) => {
		let eventLabel = googleAnalytics.page.action.gpsAvailable;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		//console.log("IN onShowNoGPSKpi, params = ",params);
		//console.log(params);
		//console.log("Consign onShowNoGPSKpi params = ",params);
		//console.log(params.column.colId);
		if(params != 0)
		{
			var transitedelaydata=[];

			await this.setState({

				detailCellRendererParams:{
					suppressRefresh: true,
					detailGridOptions: {
						columnDefs: [
							{ headerName: "",field: "_id",

								cellRendererFramework:RoutemapNoGPSAction,
								width:50,
								suppressSizeToFit: true,
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}

								}
							},

							{ headerName: "Truck No",field: "truck_no", width:100,resizable: true,
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}

								}
							},
							{
								headerName: "Last Data Receieved",
								field: "last_packet_datetime",
								width:180,
								resizable: true,
								valueGetter:function(params){
									return getDDMMYYYYHHMMSS(params.data.last_packet_datetime);
								},
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}

								}
							},
							{
								headerName: "Data Received on",
								field: "data_received_on",
								width:180,
								resizable: true,
								valueGetter:function(params){
									return getDDMMYYYYHHMMSS(params.data.packet_received_on);
								},
								cellClass:function(params){
									if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
									{
										return 'green';
									}
									else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
									{
										return 'lightred';
									}
									else{
										return '';
									}

								}
						 	}
						],
						overlayNoRowsTemplate: 'No transit time available for this Consignee',

					},
					getDetailRowData: function(params) {
						//console.log("Step 4 ",transitedelaydata)
						//console.log("Step 4 ",params)
						redirectURL.post('/consignments/nogpsdata', {
							consignment_code:params.data.consignment_code,
							truck_no:params.data.truck_no
						})
						.then(async (response) =>{
							//console.log("Step 1 ",response.data)
							transitedelaydata=response.data;
							var legsarr=[]
							transitedelaydata.map((item) => {
								//if(item.trip_completed < 2)
								//{
								legsarr.push(item)
								//}
							})
							// console.log('transitedelaydata ', transitedelaydata);
							params.successCallback(legsarr);
							//console.log("Step 2 ",response.data)

						});

					}
				}
			});

			//if( params.column.colId == 'transit_delay' || params.column.colId == 'transit_delay_1' ||   params.column.colId == 'no_gps_data' || params.column.colId == 'no_gps_data_1')
			if(params.column.colDef.field == 'transit_delay' || params.column.colDef.field == 'no_gps_data')
			{
				params.node.setExpanded(!params.node.expanded);
				//console.log("expanded ",params.node.expanded)
				if(params.node.expanded == false)
				{
					transitedelaydata=[]
				}
			}
			else{

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else{
			this.setState({

				detailCellRendererParams:{
					detailGridOptions: {
					  columnDefs: [
						{ headerName: "Truck No",field: "truck_no" },
						{ headerName: "Speed (km/h)",field: "speed" },
						{ headerName: "Reported At",field: "first_instance" }
					]

					},
					getDetailRowData: function(params) {
						//console.log("Child ", params);
					  params.successCallback([]);
					}
				  }
			});
		}

	}


	onShowNightDriveKpi = async (params) => {
		let eventLabel = googleAnalytics.page.action.nightDrive;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		//console.log("IN onShowNightDriveKpi, params = ",params);
		//console.log(e);
		//console.log("Consign Overspeed ",params);

		if(params != 0)
		{
			var overspeedData=[];


			//console.log("Step 3 ",overspeedData)

			await this.setState({

				detailCellRendererParams:{
					detailGridOptions: {
						columnDefs: [
							{ headerName: "",field: "_id",

								cellRendererFramework:RouteNightDrivemap,
								width:50,
								suppressSizeToFit: true
							},
							{ headerName: "Truck No",field: "truck_no", width:150,resizable: true },
							{ headerName: "Traveled From",field: "first_instance", width:180,resizable: true,
								valueGetter:function(params){
									return getDDMMYYYYHHMMSS(params.data.first_instance);
								}
							},
							{ headerName: "Traveled Until",field: "recent_instance",resizable: true ,
								valueGetter:function(params){
									return getDDMMYYYYHHMMSS(params.data.recent_instance);
								}
							},
							{ headerName: "Distance Traveled (km)",field: "distance_from_first_instance", width:180,
								resizable: true,
								valueGetter:function(params){
									return Math.round(params.data.distance_from_first_instance)
								}
							}
						],

					},
					getDetailRowData: function(params) {
						//console.log("Step 4 ",overspeedData)
						redirectURL.post('/consignments/nightdriving', {
							consignment_code:params.data.consignment_code,
							gate_out_time:params.data.gate_out_time,
							truck_no:params.data.truck_no
						})
						.then(async (response) =>{
							//console.log("Step 1 ",response.data)
							overspeedData=response.data;
							params.successCallback(overspeedData);
							//console.log("Step 2 ",response.data)

						});

					}

				}
			});

			//if(params.column.colId == 'nightdriving_exception')
			if(params.column.colDef.field == 'nightdriving_exception')
			{

				params.node.setExpanded(!params.node.expanded);
			}
			else{

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else{
			this.setState({

				detailCellRendererParams:{
					detailGridOptions: {
					  columnDefs: [
						{ headerName: "Truck No",field: "truck_no" },
						{ headerName: "Speed (km/h)",field: "speed" },
						{ headerName: "Reported At",field: "first_instance" }
					]

					},
					getDetailRowData: function(params) {
						//console.log("Child ", params);
					  params.successCallback([]);
					}
				  }
			});
		}

	}


	onShowEnrouteStoppagesKpi = async (params) => {
		let eventLabel = googleAnalytics.page.action.enrouteStoppages;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		//console.log("IN onShowEnrouteStoppagesKpi, params = ",params);
		//console.log(e);
		//console.log("Consign Overspeed ",params);

		if(params != 0)
		{
			var overspeedData=[];


			//console.log("Step 3 ",overspeedData)

			await this.setState({

				detailCellRendererParams:{
					detailGridOptions: {
						columnDefs: [

							{ headerName: "",field: "_id",

								cellRendererFramework:RoutemapenrouteAction,
								width:50,
								suppressSizeToFit: true,

							},
							{
								headerName: "Break Start",
								field: "stoppage_start_time",
								width:150,
								resizable: true,
								valueGetter:function(params){
									if(params.data.stoppage_start_time != undefined)
									{
										return getDDMMYYYYHHMMSS(params.data.stoppage_start_time)
									}
									else{
										return '';
									}

								}
						    },
							// {
							// 	headerName: "Break End",
							// 	field: "reset_start_time",
							// 	width:180,
							// 	resizable: true,
							// 	valueGetter:function(params){
							// 		if(params.data.reset_start_time != undefined)
							// 		{
							// 			return getDDMMYYYYHHMMSS(params.data.reset_start_time)
							// 		}
							// 		else{
							// 			return '';
							// 		}

							// 	}
							// },

							{
								headerName: "Exception Closed On",
								field: "modified_date",
								width:220,
								resizable: true,
								valueGetter:function(params){
									if(params.data.exception_closed ==1)
									{

										if(params.data.modified_date != undefined)
										{
											return getDDMMYYYYHHMMSS(params.data.modified_date)
										}
										else{
											return '';
										}
									}
									else{
										return '';
									}

								}
							},
							{
								headerName: "Break Time",
								field: "elaped_time_from_last_moved_seconds",
								width:180,
								resizable: true,
								valueGetter:function(params){
									if(params.data.exception_closed == 1)
									{
										// if(params.data.elaped_time_from_last_moved_seconds != undefined)
										// {
										// 	var seconds = params.data.elaped_time_from_last_moved_seconds;
										// 	var days = parseInt(seconds / (24 * 3600));
										// 	var hours = parseInt(seconds/3600);
										// 	var minutes = Math.round((seconds-(hours*3600))/60)
										// 	//return hours+" Hr(s) "+minutes+" Min(s)";
										// 	return secondsToString(seconds)
										// }
										// else{
										// 	return '';
										// }
										var sdate = moment.parseZone(params.data.stoppage_start_time).format("x");
										if(typeof params.data.reset_datetime == 'undefined')
										{
											var edate = moment.parseZone().format("x");
										}
										else
										{
											var edate = moment.parseZone(params.data.reset_datetime).format("x");
										}
										var diff = (edate-sdate)/1000;
										return secondsToString(diff);
									}
									else{
										return '';
									}
								}
							}
						]
					},
					getDetailRowData: async function(params) {
						//console.log("Step 4 ",overspeedData)
						redirectURL.post('/consignments/enroutes', {
							consignment_code:params.data.consignment_code,
							truck_no:params.data.truck_no
						})
						.then(async (response) =>{
							//console.log("Step 1 ",response.data)
							overspeedData=response.data;
							params.successCallback(overspeedData);
							//console.log("Step 2 ",response.data)

						});

					}
				}
			});

			if(params.column.colDef.field == 'enroute_exception')
			{

				params.node.setExpanded(!params.node.expanded);
			}
			else{

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else{
			this.setState({

				detailCellRendererParams:{
					detailGridOptions: {
					  columnDefs: [
						{ headerName: "Truck No",field: "truck_no" },
						{ headerName: "Speed (km/h)",field: "speed" },
						{ headerName: "Reported At",field: "first_instance" }
					]

					},
					getDetailRowData: function(params) {
						//console.log("Child ", params);
					  params.successCallback([]);
					}
				  }
			});
		}

	}

onShowEnrouteRouteMap(rownode){
	// console.log("IN onShowEnrouteRouteMap, params = ",rownode);
	if (googleAnalytics.page.enableGA) {
		let pageTitle = this.state.pagetitle;
		let eventOptions = {
			"category": pageTitle,
			"action": this.state.eventGridAction,
			"label": googleAnalytics.page.action.viewRoute,
		}
		googleAnalytics.logEvent(eventOptions);
	}
	this.setState({
		loadshow:'show-m'
	})
	var fintance = rownode.stoppage_start_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
	if(rownode.reset_datetime)
	{
		var eintance = rownode.reset_datetime.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();

	}
	else{
		var eintance = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');

	}
	//var eintance = rownode.packet_received_on.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
	var params = {
		truck_no:rownode.truck_no,
		//consignment_code:rownode.consignment_code,
		//consignee_code:rownode.consignee_code,
		first_instance:fintance,
		recent_instance:eintance,
		screen:"consignment"
	}
		redirectURL.post('/reports/nightdriveroutes',params)
		.then((response) => {

		//var records = JSON.parse(JSON.stringify(response)).data;
		//console.log("Inner Grid ",response.data);
		if(response.data.coords.length == 0)
		{
			this.setState({
				show: true,
				basicTitle:'No Route data available',
				basicType:"danger",
				loadshow:'show-n'
			});
		}
		else{
			var sdate='';
			var edate='';

			if(rownode.leg_start != '' && rownode.leg_start != undefined)
			{
				sdate=rownode.leg_start;
			}
			if(rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined)
			{
				edate=getDDMMYYYYHHMMSS(rownode.leg_end_eta)
			}
		this.setState({
			mapinfo:response.data,
			dealer:rownode.consignee_code,
			sliderRouteTranslate:"slider-translate-60p",
			defTransitCoords: rownode.consignee_coordinates,
			consignment_code:"Consignment : "+rownode.truck_no,
			maptruckno:rownode.truck_no,
			routeTruck:	{"truck_no":rownode.truck_no,"startTime":sdate,"endTime": edate},
			loadshow:'show-n',
			sidebarSubHeader:"Consignment Leg Information",
			overly:'show-m',
			rownode:rownode,
			leg_no:rownode.leg_no
		});

		this.onShowGoogleRoute(rownode.consignment_code, rownode.consignee_code, rownode.leg_no, rownode.truck_no)
			this.renderMap();
		}
		})
		.catch(function (error) {
		console.log(error);
		});

	}

	onShowLoadingDelayKpi = async (params) => {

		let eventLabel = googleAnalytics.page.action.loadingDelay;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		//console.log("IN onShowLoadingDelayKpi, params = ",params);
		//console.log(e);
		//console.log("Consign onShowLoadingDelayKpi ",params);
		//console.log(params.column.colId);
		if(params != 0)
		{
			var overspeedData=[];


			//console.log("Step 3 ",overspeedData)

			await this.setState({

				detailCellRendererParams:{
					detailGridOptions: {
						columnDefs: [

							{
								headerName: "Gate In Time",
								field: "gate_in_time",
								width:180,
								resizable: true,
								valueGetter:function(params){
									if(params.data.gate_in_time)
									{
										//return getDDMMYYYYHHMMSS(params.data.gate_in_time);
										return params.data.gate_in_time;
									}
									else
									{
										return "NA"
									}

								}
							},
							{
								headerName: "Gate Out Time",
								field: "gate_out_time",
								width:180,
								resizable: true,
								valueGetter:function(params){
									// console.log("IN gate_out_time valueGetter, params = ", params);

									if(params.data.gate_out_time)
									{
										// console.log("IN gate_out_time valueGetter, IF params.data.gate_out_time = ", params.data.gate_out_time);
										//return getDDMMYYYYHHMMSS(params.data.gate_out_time);
										return params.data.gate_out_time;
									}
									else
									{
										// console.log("IN gate_out_time valueGetter, ELSE params.data.gate_out_time = ", params.data.gate_out_time);
										return "NA"
									}
								}
							},
							{
								headerName:"Loading Time",
								field:"loading_time",
								width:180,

							}

						],

					},
					getDetailRowData: function(params) {
						//console.log("Step 4 ",params)
						if(params.data.gate_in_time != undefined)
						{
							var g1 = getDDMMYYYYHHMMSS(params.data.gate_in_time);
							//var g1 = (params.data.gate_in_time) ? moment.parseZone(params.data.gate_in_time).format('DD-MM-YYYY') : "";
							var gdate1 = new Date(params.data.gate_in_time);
							var fromDate = g1;
						}
						else{

							var g1 = getDDMMYYYYHHMMSS(params.data.inside_fence);
							var gdate1 = new Date(params.data.inside_fence);
							var fromDate = g1;
						}
						//console.log("g1 ", g1)//console.log("Step 4 ",params)
						if(params.data.gate_out_time != undefined)
						{
							var g2 = getDDMMYYYYHHMMSS(params.data.gate_out_time);
							var gdate2 = new Date(params.data.gate_out_time);
							var toField = g2;
							//console.log("gatein ", gatein)
							//console.log("gateout ", gateout)
							//var total =0 ;
						}
						else{
							var c = new Date();
							var todaydate = new Date(moment(c).format("YYYY-MM-DD HH:mm:ss"));
							var g2 = getDDMMYYYYHHMMSS(todaydate);
							var gdate2 = new Date(todaydate);
							var toField = g2;
						}
						//var g2 = getDDMMYYYY(params.data.gate_out_time);

						//console.log("g1 ", g2)
						var s1 = g1.split("-");
						var s2 = g2.split("-");


						var gatein = gdate1.getTime();
						var gateout = gdate2.getTime();
						var total = gateout-gatein;
						//console.log("total ", total)
						var diffMs = total;
						var diffDays = Math.ceil(diffMs / 86400000); // days
						var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
						var diffMins = parseInt(((diffMs % 86400000) % 3600000) / 60000);

						let loadingTimeDifference = null;

						// let timeDiffereceDays = null;
						// let timeDiffereceHours = null;
						// let timeDiffereceMinutes = null;
						// let totalDifferece = null;

						// gdate1 = g1;
						// gdate2 = g2;
						// console.log("gdate1 && gdate2 =", gdate1, gdate2);
						// if(gdate1 && gdate2) {
						// 	console.log("IN IF gdate1 && gdate2");
						// 	timeDiffereceDays = gdate2.diff(gdate1, 'days');  // calculating time difference in days between two dates
						// 	timeDiffereceHours = gdate2.diff(gdate1, 'hours');  // calculating time difference in days between two dates
						// 	timeDiffereceMinutes = gdate2.diff(gdate1, 'minutes');  // calculating time difference in days between two dates
						// 	totalDifferece = gdate2.diff(gdate1);  // calculating time difference in days between two dates

						// } else {
						// 	console.log("IN ELSE  gdate1 && gdate2");
						// }
						// console.log("timeDiffereceDays = ", timeDiffereceDays);
						// console.log("timeDiffereceHours = ", timeDiffereceHours);
						// console.log("timeDiffereceMinutes = ", timeDiffereceMinutes);
						// console.log("totalDifferece = ", totalDifferece);

						var dataarr = []
							// dataarr.push({
							// 	"gate_in_time":fromDate,
							// 	"gate_out_time": toField,
							// 	"loading_time":diffHrs+" Hr(s) "+" "+diffMins+" Min(s)"
							// })

							// if (diffDays) {
							// 	loadingTimeDifference = diffDays+" Day(s) " + diffHrs + " Hr(s) "+" "+diffMins+" Min(s)";
							// } else {
							// 	loadingTimeDifference = diffHrs+" Hr(s) "+" "+diffMins+" Min(s)";
							// }

							dataarr.push({
								"gate_in_time": fromDate,
								"gate_out_time": toField,
								//"loading_time": diffHrs+" Hr(s) "+" "+diffMins+" Min(s)"
								"loading_time": secondsToString(diffMs/1000)
							})
							params.successCallback(dataarr);
					}
					/*getDetailRowData: function(params) {
						//console.log("Step 4 ",params)
						if(params.data.gate_in_time != undefined)
						{
							//var g1 = getDDMMYYYYHHMMSS(params.data.gate_in_time);
							var g1 = (params.data.gate_in_time) ? moment.parseZone(params.data.gate_in_time).format('DD-MM-YYYY') : "";
							var gdate1 = new Date(params.data.gate_in_time);
							var fromDate = g1;
						}
						else{

							var g1 = getDDMMYYYYHHMMSS(params.data.inside_fence);
							var gdate1 = new Date(params.data.inside_fence);
							var fromDate = g1;
						}
						//console.log("g1 ", g1)//console.log("Step 4 ",params)
						if(params.data.gate_out_time != undefined)
						{
							var g2 = getDDMMYYYYHHMMSS(params.data.gate_out_time);
							var gdate2 = new Date(params.data.gate_out_time);
							var toField = g2;
							//console.log("gatein ", gatein)
							//console.log("gateout ", gateout)
							//var total =0 ;
						}
						else{
							var c = new Date();
							var todaydate = new Date(moment(c).format("YYYY-MM-DD HH:mm:ss"));
							var g2 = getDDMMYYYYHHMMSS(todaydate);
							var gdate2 = new Date(todaydate);
							var toField = g2;
						}
						//var g2 = getDDMMYYYY(params.data.gate_out_time);

						//console.log("g1 ", g2)
						var s1 = g1.split("-");
						var s2 = g2.split("-");


						var gatein = gdate1.getTime();
						var gateout = gdate2.getTime();
						var total = gateout-gatein;
						//console.log("total ", total)
						var diffMs = total;
						var diffDays = Math.floor(diffMs / 86400000); // days
						var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
						var diffMins = parseInt(((diffMs % 86400000) % 3600000) / 60000);
						//console.log("diffHrs ", diffHrs);
						//console.log("diffMins ", diffMins);
						//console.log("diffDays ", diffDays);

						var dataarr = []
							// dataarr.push({
							// 	"gate_in_time":fromDate,
							// 	"gate_out_time": toField,
							// 	"loading_time":diffHrs+" Hr(s) "+" "+diffMins+" Min(s)"
							// })
							dataarr.push({
								"gate_in_time": fromDate,
								"gate_out_time": toField,
								"loading_time":diffHrs+" Hr(s) "+" "+diffMins+" Min(s)"
							})
							params.successCallback(dataarr);

					}*/

				}
			});

			//if(params.column.colId == 'loading_delay_exception')

			if(params.column.colDef.field == 'loading_delay_exception')
			{

				params.node.setExpanded(!params.node.expanded);
			}
			else{

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else{

		}

	}

	onShowUnLoadingDelayKpi = async (params) => {
		let eventLabel = googleAnalytics.page.action.unloadingDelay;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		//console.log("IN onShowUnLoadingDelayKpi, params = ",params);
		//console.log(e);
		//console.log("Consign Overspeed ",params);
		//console.log(params.column.colId);
		if(params != 0)
		{
			var overspeedData=[];


			//console.log("Step 3 ",overspeedData)

			await this.setState({

				detailCellRendererParams:{
					detailGridOptions: {
						columnDefs: [

							{
								headerName: "From Date",
								field: "from_date",
								width:180,
								resizable: true,
								valueGetter:function(params){
									// console.log("child params ", params)
									if(params.data.from_date != undefined)
									{
										return getDDMMYYYYHHMMSS(params.data.from_date);
									}
									else
									{
										return "NA"
									}

								}
							},
							{
								headerName: "To Date",
								field: "to_date",
								width:180,
								resizable: true,
								valueGetter:function(params){
									if(params.data.to_date != undefined)
									{
										return getDDMMYYYYHHMMSS(params.data.to_date);
									}
									else
									{
										return "NA"
									}
								}
							},
							{
								headerName:"Unloading Time",
								field:"unloading_time",
								width:180,

							}

						],

					},
					getDetailRowData: function(params) {
						//console.log("Step 4 ",params)
						if(params.data.locked_dealer_reported == undefined)
						{
							var g1 = getYYYYMMDDHHMMSS(params.data.first_dealer_reported);
							var fromField = g1;
						}
						else
						{

							var g1 = getYYYYMMDDHHMMSS(params.data.locked_dealer_reported);
							var fromField = g1;
						}
						//console.log("params.data.left_dealer_marked ", params.data.left_dealer_marked)
						if(params.data.left_dealer_marked == undefined)
						{
							var c = new Date();
							var todaydate = c;
							var g2 = getYYYYMMDDHHMMSS(todaydate);
							var toField = g2;
						}
						else
						{
							var g2 = getYYYYMMDDHHMMSS(params.data.left_dealer_marked);
							var toField = g2;
							//console.log("toField stee " ,toField);
						}
							var fstr = fromField.split("-");
							//console.log(fstr);
							var gdate1 = new Date(fromField);
							var gdate2 = new Date(toField);
							var frmd = gdate1.getTime();
							var tod = gdate2.getTime();
							var total = tod-frmd;
							//console.log("From Date ", fromField)
							//console.log("To Date ", toField)
							//var total =0 ;
							//console.log("gdate1 ", frmd)
							//console.log("gdate2 ", tod)
							//console.log("Total ", total)
							var diffMs = total;
							var diffDays = Math.floor(diffMs / 86400000); // days
							var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
							var diffMins = parseInt(((diffMs % 86400000) % 3600000) / 60000);

							// var sr = params.data.unloading_time_in_hrs;
							// var hrs=sr.toString().split(".");
							// var hours = parseInt(hrs[0]);
							// var mins = Math.round((hrs[1]/100)*60)
							var dataarr = []
							// dataarr.push({
							// 	"from_date":fromField,
							// 	"to_date": toField,
							// 	"unloading_time":diffHrs+" Hr(s) "+" "+diffMins+" Min(s)"
							// 	//"unloading_time":hours+"Hr(s) "+mins+" min(s)"
							// })
							let loadingTimeDifference = null;
							// if (diffDays) {
							// 	loadingTimeDifference = diffDays+" Day(s) " + diffHrs + " Hr(s) "+" "+diffMins+" Min(s)";
							// } else {
							// 	loadingTimeDifference = diffHrs+" Hr(s) "+" "+diffMins+" Min(s)";
							// }
							loadingTimeDifference = secondsToString(diffMs/1000)
							dataarr.push({
									"from_date":fromField,
									"to_date": toField,
									"unloading_time":loadingTimeDifference
									//"unloading_time":hours+"Hr(s) "+mins+" min(s)"
							})
							params.successCallback(dataarr);

					}

				}
			});

			//if(params.column.colId == 'unloading_delay_exception')
			if(params.column.colDef.field == 'unloading_delay_exception')
			{

				params.node.setExpanded(!params.node.expanded);
			}
			else{

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else{

		}

	}


	onShowDeviate = async (params) => {
		let eventLabel = googleAnalytics.page.action.deviation;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		//console.log("IN onShowDeviate, params = ",params);

		//console.log(e);
		//console.log("Consign Overspeed ",params);

		if(params != 0)
		{
			var overspeedData=[];

			await this.setState({

				detailCellRendererParams:{
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Truck No",field: "truck_no" },
							{ headerName: "Speed (km/h)",field: "speed" },
							{ headerName: "Reported At",field: "first_instance" }
						]
					},
					getDetailRowData: async function(params) {
						// console.log("Step 4 ",overspeedData)
						await params.successCallback(overspeedData);
					}
				}
			});

			if(params.column.colId == '_id_1' || params.column.colId == '_id_2')
			{

				params.node.setExpanded(!params.node.expanded);
			}
			else{

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else{
			this.setState({

				detailCellRendererParams:{
					detailGridOptions: {
					  columnDefs: [
						{ headerName: "Truck No",field: "truck_no" },
						{ headerName: "Speed (km/h)",field: "speed" },
						{ headerName: "Reported At",field: "first_instance" }
					]

					},
					getDetailRowData: function(params) {
						//console.log("Child ", params);
					  params.successCallback([]);
					}
				  }
			});
		}

	}
	onRowClicked(params) {
		//console.log("IN onRowClicked, params = ",params);
		//console.log("row click ", params)
		if(params.column.colId == '_id_1' || params.column.colId == '_id_2')
		{

			params.node.setExpanded(!params.node.expanded);
		}
		else{

			params.node.setExpanded(false);
		}

	  }

	onShowRouteMap(rownode){
		// console.log("IN onShowRouteMap, params = ",rownode);
		// console.log("rownode", rownode);
		// console.log("rownode.consignment_code ", rownode.consignment_code)
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.setState({
			loadshow:'show-m'
		})
		var d = new Date(rownode.first_instance);
    	var r = new Date(rownode.recent_instance);
		var finstance = rownode.first_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var rinstance = rownode.recent_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var params = {
			truck_no:rownode.truck_no,
			consignment_code:rownode.consignment_code,
			consignee_code:rownode.consignee_code,
            first_instance:finstance,
			recent_instance:rinstance,
			screen:"consignment"
		}

		 redirectURL.post('/reports/overspeedgpsroutes',params)
		  .then((response) => {

		   //var records = JSON.parse(JSON.stringify(response)).data;
		    // console.log(response);
		   this.setState({
				mapinfo:response.data,
				dealer:rownode.consignee_code,
				sliderRouteTranslate:"slider-translate-50p",
				consignment_code:rownode.truck_no,
				maptruckno:rownode.truck_no,
				routeTruck:	{"truck_no":rownode.truck_no,"startTime":response.data['route_details']['start_time'],
					"endTime": response.data['route_details']['end_time']},
				overly:'show-m',
				loadshow:'show-n',
				rownode:rownode
		   });
		   	this.renderMap();


		  })
		  .catch(function (error) {
		    console.log(error);
		  });

	}

	onShowTransitRouteMap(rownode){
		//console.log("IN onShowTransitRouteMap, params = ",rownode);
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		//console.log("rownode", rownode);
		this.setState({
			loadshow:'show-m'
		})
		var d = new Date(rownode.leg_start);
    	var r = new Date(rownode.leg_end_eta);
    	var fintance = rownode.leg_start.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var eintance = rownode.leg_end_eta.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var params = {
			truck_no:rownode.truck_no,
			consignment_code:rownode.consignment_code,
			consignee_code:rownode.consignee_code,
            first_instance:fintance,
			recent_instance:eintance,
			screen:"consignment"
		}
		 redirectURL.post('/reports/nightdriveroutes',params)
		  .then((response) => {

		   //var records = JSON.parse(JSON.stringify(response)).data;
			//console.log("Inner Grid ",response.data);
			if(response.data.coords.length == 0)
			{
				this.setState({
					show: true,
					basicTitle:'No Route data available',
					basicType:"danger",
					loadshow:'show-n'
				});
			}
			else{
				var sdate='';
				var edate='';

				if(rownode.leg_start != '' && rownode.leg_start != undefined)
				{
					sdate=rownode.leg_start;
				}
				if(rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined)
				{
					edate=getDDMMYYYYHHMMSS(rownode.leg_end_eta)
				}
			this.setState({
					mapinfo:response.data,
					dealer:rownode.consignee_code,
					sliderRouteTranslate:"slider-translate-60p",
					defTransitCoords: rownode.consignee_coordinates,
					consignment_code:"Consignment : "+rownode.truck_no,
					maptruckno:rownode.truck_no,
					routeTruck:	{"truck_no":rownode.truck_no,"startTime":sdate,"endTime": edate},
					loadshow:'show-n',
					sidebarSubHeader:"Consignment Leg Information",
					overly:'show-m',
					rownode:rownode,
					leg_no:rownode.leg_no

			});

			this.onShowGoogleRoute(rownode.consignment_code, rownode.consignee_code, rownode.leg_no, rownode.truck_no)
				this.renderMap();

			}
		  })
		  .catch(function (error) {
		    console.log(error);
		  });

	}

	onShowNoGPSRouteMap(rownode){
		//console.log("IN onShowNoGPSRouteMap, params = ",rownode);
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		//console.log("rownode", rownode);
		this.setState({
			loadshow:'show-m'
		})
		var fintance = rownode.last_packet_datetime.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		//var eintance = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
		var eintance = rownode.packet_received_on.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var params = {
			truck_no:rownode.truck_no,
			consignment_code:rownode.consignment_code,
			//consignee_code:rownode.consignee_code,
            first_instance:fintance,
			recent_instance:eintance,
			screen:"consignment"
		}
		 redirectURL.post('/reports/nightdriveroutes',params)
		  .then((response) => {

		   //var records = JSON.parse(JSON.stringify(response)).data;
			//console.log("Inner Grid ",response.data);
			if(response.data.coords.length == 0)
			{
				this.setState({
					show: true,
					basicTitle:'No Route data available',
					basicType:"danger",
					loadshow:'show-n'
				});
			}
			else{
				var sdate='';
				var edate='';

				if(rownode.leg_start != '' && rownode.leg_start != undefined)
				{
					sdate=rownode.leg_start;
				}
				if(rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined)
				{
					edate=getDDMMYYYYHHMMSS(rownode.leg_end_eta)
				}
			this.setState({
					mapinfo:response.data,
					dealer:rownode.consignee_code,
					sliderRouteTranslate:"slider-translate-60p",
					defTransitCoords: rownode.consignee_coordinates,
					consignment_code:"Consignment : "+rownode.truck_no,
					maptruckno:rownode.truck_no,
					routeTruck:	{"truck_no":rownode.truck_no,"startTime":sdate,"endTime": edate},
					loadshow:'show-n',
					sidebarSubHeader:"Consignment Leg Information",
					overly:'show-m',
					rownode:rownode,
					leg_no:rownode.leg_no

			});

			this.onShowGoogleRoute(rownode.consignment_code, rownode.consignee_code, rownode.leg_no, rownode.truck_no)
				this.renderMap();

			}
		  })
		  .catch(function (error) {
		    console.log(error);
		  });

	}

	onShowNightDriveRouteMap(rownode){
		//console.log("IN onShowNightDriveRouteMap, params = ",rownode);
		//console.log("nightdrivenode", rownode);
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.setState({
			loadshow:'show-m'
		});
		var d = new Date(rownode.first_instance);
    	var r = new Date(rownode.recent_instance);
    	var fintance = rownode.first_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var eintance = rownode.recent_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
        var params = {
			truck_no:rownode.truck_no,
            first_instance:fintance,
            recent_instance:eintance,
		}

		 redirectURL.post('/reports/nightdriveroutes',params)
		  .then((response) => {

		   //var records = JSON.parse(JSON.stringify(response)).data;
			// console.log("nightroutedata",response.data);

		   this.setState({
				mapinfo:response.data,
				sliderRouteTranslate:"slider-translate-50p",
				consignment_code:rownode.truck_no,
				defTransitCoords: {"lat":rownode.first_lat,"lng":rownode.first_lng},
				maptruckno:rownode.truck_no,
				routeTruck:	{"truck_no":rownode.truck_no,"startTime":response.data['route_details']['start_time'],
					"endTime": response.data['route_details']['end_time']},
				loadshow:'show-n',
				overly:'show-m'
		   });
		   	this.renderMap();


		  })
		  .catch(function (error) {
		    console.log(error);
		  });


	}
	onClickHideAll(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
            sliderTranslatesidebar:'',
			sliderForceCloseTranslate:'',
			sliderDCTCommentTranslate:'',
			sliderCommentTranslate:'',
			bulkslide:''
		});

	}

	showGoogleRouteAlert = () => {
        this.setState({
			show: true,
			basicTitle:"No google routes found",
			basicType:"danger"
        });
	}

	closeAlert = () => {
        this.setState({
            show: false
        });
	}
	onClickCounterShowDataTEST(params){
		// console.log("IN onClickCounterShowData Do NOTHING- params = ", params);
	}

	onClickCounterShowData(params){
		// console.log("IN onClickCounterShowData, params = ",params);
		if (googleAnalytics.page.enableGA) {
			let eventLabel = null;
			if (params) {
				eventLabel = googleAnalytics.page.kpi[params];
				//console.log("eventLabel = ", eventLabel);
			}

			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventCounterAction,
				"label": eventLabel,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		if(params == "all")
		{
			this.gridApi.setRowData(this.state.allRowData);
			this.gridApi.onFilterChanged();
		}
		else
		{

			let filterInstance = null;
			let filterInstance2 = null;
			let filterActivated = false;
			this.gridApi.setFilterModel(null);
			this.gridApi.onFilterChanged();

			filterInstance = this.gridApi.getFilterInstance(params);
			filterActivated = true;


			//console.log("filterActivated ",filterActivated)
			if (filterActivated) {

				let isFilterDataAvailable = this.state.rowData.filter(rec => {

					if(params == 'transit_delay')
					{
						if (rec["transit_delay"]) {
							return rec["transit_delay"] == 1;
						}
					}
					if(params == 'tpt_confirm_delivery')
					{
						if (rec["tpt_confirm_delivery"]) {
							return rec["tpt_confirm_delivery"] == 1;
						}
					}

				});
				if (isFilterDataAvailable.length) {

					this.gridApi.setRowData(isFilterDataAvailable);
					this.gridApi.onFilterChanged();
				}
				else{
					// filterInstance.selectNothing();
					//filterInstance.selectValue("");
					// filterInstance.applyModel();
					this.gridApi.setRowData([]);
					this.gridApi.onFilterChanged();
				}
			}
			else{
				this.gridApi.setRowData(this.state.rowData);
				this.gridApi.onFilterChanged();
			}

		}

		// if(params == "transit_delay")
		// {
		// 	// var filterComponent = this.gridApi.getFilterInstance("transit_delay");
		// 	// filterComponent.selectNothing();
		// 	// filterComponent.selectValue("1");
		// 	// if(this.state.tripvalue != undefined && this.state.tripvalue != '')
		// 	// {
		// 	// 	filterComponent.selectValue(this.state.tripvalue);
		// 	// }
		// 	// filterComponent.applyModel();
		// 	let isFilterDataAvailable = this.state.rowData.filter(rec => {
		// 		if (rec["transit_delay"]) {
		// 			return rec["transit_delay"] == 1;
		// 		}
		// 	});
		// 	this.gridApi.setRowData(isFilterDataAvailable);
		// 	this.gridApi.onFilterChanged();
		// }


		// if(params == "tpt_confirm_delivery")
		// {
		// 	// var filterComponent = this.gridApi.getFilterInstance("tpt_confirm_delivery");
		// 	// filterComponent.selectNothing();
		// 	// filterComponent.selectValue("1");
		// 	// if(this.state.tripvalue != undefined && this.state.tripvalue != '')
		// 	// {
		// 	// 	filterComponent.selectValue(this.state.tripvalue);
		// 	// }
		// 	// filterComponent.applyModel();
		// 	let isFilterDataAvailable = this.state.rowData.filter(rec => {
		// 		if (rec["tpt_confirm_delivery"]) {
		// 			return rec["tpt_confirm_delivery"] == 1;
		// 		}
		// 	});
		// 	this.gridApi.setRowData(isFilterDataAvailable);
		// 	this.gridApi.onFilterChanged();
		// }
		// else if(params == "no_gps_data")
		// {
		// 	// var filterComponent = this.gridApi.getFilterInstance("no_gps_data");
		// 	// filterComponent.selectNothing();
		// 	// filterComponent.selectValue("1");
		// 	// if(this.state.tripvalue != undefined && this.state.tripvalue != '')
		// 	// {
		// 	// 	filterComponent.selectValue(this.state.tripvalue);
		// 	// }
		// 	// filterComponent.applyModel();
		// 	let isFilterDataAvailable = this.state.rowData.filter(rec => {
		// 		if (rec["no_gps_data"]) {
		// 			return rec["no_gps_data"] == 1;
		// 		}
		// 	});
		// 	this.gridApi.setRowData(isFilterDataAvailable);
		// 	this.gridApi.onFilterChanged();
		// }

		// else if(params == "no_gps_data_lifetime")
		// {
		// 	// var filterComponent = this.gridApi.getFilterInstance("no_gps_data_lifetime");
		// 	// //filterComponent.selectNothing();
		// 	// //filterComponent.selectValue("1");
		// 	// if(this.state.tripvalue != undefined && this.state.tripvalue != '')
		// 	// {
		// 	// 	this.gridApi.getFilterInstance("no_gps_data").selectValue(this.state.tripvalue);
		// 	// }
		// 	//filterComponent.applyModel();
		// 	let isFilterDataAvailable = this.state.rowData.filter(rec => {
		// 		if (rec["no_gps_data_lifetime"]) {
		// 			return rec["no_gps_data_lifetime"] == 1;
		// 		}
		// 	});
		// 	this.gridApi.setRowData(isFilterDataAvailable);
		// 	this.gridApi.onFilterChanged();
		// }


		// else if(params == "overspeeding_exception")
		// {
		// 	// var filterComponent = this.gridApi.getFilterInstance("overspeeding_exception");
		// 	// filterComponent.selectNothing();
		// 	// filterComponent.selectValue("1");
		// 	// if(this.state.tripvalue != undefined && this.state.tripvalue != '')
		// 	// {
		// 	// 	filterComponent.selectValue(this.state.tripvalue);
		// 	// }
		// 	// filterComponent.applyModel();
		// 	let isFilterDataAvailable = this.state.rowData.filter(rec => {
		// 		if (rec["overspeeding_exception"]) {
		// 			return rec["overspeeding_exception"] == 1;
		// 		}
		// 	});
		// 	this.gridApi.setRowData(isFilterDataAvailable);
		// 	this.gridApi.onFilterChanged();
		// }
		// else if(params == "nightdriving_exception")
		// {
		// 	// var filterComponent = this.gridApi.getFilterInstance("nightdriving_exception");
		// 	// filterComponent.selectNothing();
		// 	// filterComponent.selectValue("1");
		// 	// if(this.state.tripvalue != undefined && this.state.tripvalue != '')
		// 	// {
		// 	// 	filterComponent.selectValue(this.state.tripvalue);
		// 	// }
		// 	// filterComponent.applyModel();
		// 	let isFilterDataAvailable = this.state.rowData.filter(rec => {
		// 		if (rec["nightdriving_exception"]) {
		// 			return rec["nightdriving_exception"] == 1;
		// 		}
		// 	});
		// 	this.gridApi.setRowData(isFilterDataAvailable);
		// 	this.gridApi.onFilterChanged();
		// }
		// else if(params == "unloading_delay_exception")
		// {
		// 	// var filterComponent = this.gridApi.getFilterInstance("unloading_delay_exception");
		// 	// filterComponent.selectNothing();
		// 	// filterComponent.selectValue("1");
		// 	// if(this.state.tripvalue != undefined && this.state.tripvalue != '')
		// 	// {
		// 	// 	filterComponent.selectValue(this.state.tripvalue);
		// 	// }
		// 	// filterComponent.applyModel();
		// 	let isFilterDataAvailable = this.state.rowData.filter(rec => {
		// 		if (rec["unloading_delay_exception"]) {
		// 			return rec["unloading_delay_exception"] == 1;
		// 		}
		// 	});
		// 	this.gridApi.setRowData(isFilterDataAvailable);
		// 	this.gridApi.onFilterChanged();
		// }
		// else if(params == "loading_delay_exception")
		// {
		// 	// var filterComponent = this.gridApi.getFilterInstance("loading_delay_exception");
		// 	// filterComponent.selectNothing();
		// 	// filterComponent.selectValue("1");
		// 	// if(this.state.tripvalue != undefined && this.state.tripvalue != '')
		// 	// {
		// 	// 	filterComponent.selectValue(this.state.tripvalue);
		// 	// }
		// 	// filterComponent.applyModel();
		// 	let isFilterDataAvailable = this.state.rowData.filter(rec => {
		// 		if (rec["loading_delay_exception"]) {
		// 			return rec["loading_delay_exception"] == 1;
		// 		}
		// 	});
		// 	this.gridApi.setRowData(isFilterDataAvailable);
		// 	this.gridApi.onFilterChanged();
		// }
		// else if(params == "enroute_exception")
		// {
		// 	let isFilterDataAvailable = this.state.rowData.filter(rec => {
		// 		if (rec["enroute_exception"] != undefined || rec["enroute_exception"] != '') {
		// 			return rec["enroute_exception"] == 1;
		// 		}
		// 	});
		// 	//console.log("isFilterDataAvailable Enrotue ", isFilterDataAvailable)
		// 	if(isFilterDataAvailable.length > 0)
		// 	{
		// 		// var filterComponent = this.gridApi.getFilterInstance("enroute_exception");
		// 		// try{
		// 		// 	if (filterComponent) {
		// 		// 		filterComponent.selectNothing();
		// 		// 		filterComponent.selectValue(1);
		// 		// 		if(this.state.tripvalue != undefined && this.state.tripvalue != '')
		// 		// 		{
		// 		// 			filterComponent.selectValue(this.state.tripvalue);
		// 		// 		}
		// 		// 		filterComponent.applyModel();
		// 		// 	}

		// 		// }
		// 		// catch(e){
		// 		// 	console.log(e)
		// 		// }

		// 		this.gridApi.setRowData(isFilterDataAvailable);
		// 		this.gridApi.onFilterChanged();
		// 	}
		// 	else{
		// 		this.gridApi.setRowData([]);
		// 		this.gridApi.onFilterChanged();
		// 	}

		// }
		// else{
		// 	if(this.state.tripvalue != undefined && this.state.tripvalue != '')
		// 	{

		// 	this.gridApi.setRowData(this.state.rowData);
		// 	}
		// 	else{

		// 		this.gridApi.setRowData(this.state.allRowData);
		// 	}
		// 	this.gridApi.onFilterChanged();
		// }

	}
	// onSelectTrip(value){
	// 	//console.log(value.target.value)
	// 	this.setState({
	// 		triptype:value.target.value
	// 	});
	// }


	onSelectTrip= triptype =>{
		this.setState(
			{ triptype },
			// () => console.log(`Trip Option selected:`, this.state.triptype)
		  );

		  this.setState({
			  errorMessage:'',
			  successMessage:''
		  })
	}

	// onClickTripButton = async () => {
	// 	// var sDate = document.getElementById("from_date").value;
	// 	// var eDate = document.getElementById("to_date").value;
	// 	// // console.log(sDate,eDate);
	// 	// if(sDate.split("-")[2].length == 4){
	// 	// sDate = sDate.split("-")[2]+"-"+sDate.split("-")[1]+"-"+sDate.split("-")[0]
	// 	// }
	// 	// if(eDate.split("-")[2].length == 4){
	// 	// eDate = eDate.split("-")[2]+"-"+eDate.split("-")[1]+"-"+eDate.split("-")[0]
	// 	// }

	// 	// sDate = moment(new Date(sDate)).format("Y-MM-DD");
	// 	// eDate = moment(new Date(eDate)).format("Y-MM-DD");

	// 	// console.log(sDate,eDate);

	// 	this.setState({
	// 		// startDate:moment(sDate).format("DD-MM-Y"),
	// 		// endDate:moment(eDate).format("DD-MM-Y"),
	// 		loadshow:'show-m'
	// 	});
	// 	if (googleAnalytics.page.enableGA) {
	// 		let pageTitle = this.state.pagetitle;
	// 		let eventOptions = {
	// 			"category": pageTitle,
	// 			"action": this.state.eventFormAction,
	// 			"label": googleAnalytics.page.action.formSubmittedSelectConsigner,
	// 		}
	// 		googleAnalytics.logEvent(eventOptions);
	// 	}
	// 	console.log("Buton this.state.triptype ", this.state.triptype.value)
	// 	if(this.state.triptype.value == undefined)
	// 	{
	// 		var tripvale = "all";
	// 	}
	// 	else{
	// 		var tripvale =this.state.triptype.value;
	// 	}
	// 	var selectcons = [];
	// 	if(this.state.consigner != null)
	// 	{
	// 		if(this.state.consigner.length > 0)
	// 		{
	// 			this.state.consigner.forEach(item => {
	// 				selectcons.push(item.value);
	// 			});
	// 		}
	// 	}
	// 	var tdata='';
	// 	console.log("trip ",tripvale)
	// 	if(tripvale != "")
	// 	{
	// 		console.log("Step 1", tripvale);
	// 		if(tripvale == 'all')
	// 		{
	// 			console.log("Step 2", tripvale);
	// 			tdata = {
	// 				triptype: tripvale,
	// 				consigner_code:selectcons,
	// 				dept_code: this.state.deptcode,
	// 				screenpage:this.state.screenpage,
	// 				startDate:this.state.startDate,
	// 				endDate:this.state.endDate
	// 			}
	// 		}
	// 		else
	// 		{
	// 			console.log("Step 3", tripvale);
	// 			tdata = {
	// 				triptype: tripvale,
	// 				consigner_code:selectcons,
	// 				dept_code: this.state.deptcode,
	// 				screenpage:this.state.screenpage,
	// 				startDate:this.state.startDate,
	// 				endDate:this.state.endDate
	// 			}
	// 		}

	// 	}
	// 	else if(selectcons.length > 0)
	// 	{
	// 		console.log("Step 4", tripvale);
	// 		tdata = {
	// 			consigner_code:selectcons,
    //             dept_code: this.state.deptcode,
	// 			screenpage:this.state.screenpage,
	// 			startDate:this.state.startDate,
	// 			endDate:this.state.endDate
	// 		}
	// 	}
	// 	else{
	// 		console.log("Step 5", tripvale);
	// 		tdata=''
	// 	}
	// 	console.log("tdata ",tdata)
	// 	if(tdata != '')
	// 	{

	// 		await redirectURL.post("/consignments/forceclosetripconsigners", tdata)
	// 		.then((response) => {
	// 			console.log("Trip array ", response.data)
	// 			var records = response.data.consignments;
    //         	var pagecounters = response.data.counters;
	// 			this.setState({
	// 				tripvalue:tripvale,
	// 				rowData:records,
	// 				allRowData:records,
	// 				loadshow:'show-n',
	// 				countersjson:pagecounters
	// 				//consigner:''
	// 			});
	// 		})
	// 		.catch(function(e){
	// 			console.log("Counters Grid Error ", e)
	// 		})

	// 	}
	// 	else{
	// 		console.log("this.state.allRowData ", this.state.originalData)
	// 		this.setState({
	// 			rowData:this.state.originalData,
	// 			allRowData:this.state.originalData,
	// 			tripvalue:tripvale,
	// 			loadshow:'show-n',
	// 			countersjson:this.state.originalcountersjson
	// 		});
	// 	}



	// }


	/*New COde*/
	onClickTripButton = async () => {
		var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		if(this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN")
		{
			var sDate = this.state.defaultsdate;
		}
		else{
			var sDate = this.state.startDate;
		}
		if(this.state.endDate == '' || this.state.startDate == "NaN-NaN-NaN")
		{
			var eDate = this.state.defaultedate;
		}
		else{
			var eDate = this.state.endDate;
		}
		// var sDate=this.state.startDate;
		// var eDate = this.state.endDate;
		var selectconsigners=[]
		if(this.state.consigner == null)
		{
			selectconsigners = [{"value":'all', "label":'All'}]
		}
		else{
			selectconsigners=this.state.consigner
		}

		var selecttrip=[]
		if(this.state.triptype == null)
		{
			selecttrip = [{"value":'all', "label":'All'}]
		}
		else{
			selecttrip=[this.state.triptype]
		}

		var requestparams = {
			consigner: selectconsigners,
			dept_code: encode(this.state.deptcode),
			startDate:sDate,
			endDate:eDate,
			triptype:selecttrip
		}
		var urlpath = '/consignments/forceclosureconsignments';
		this.onLoadShowForceConsignmentsList(urlpath, requestparams);
	}

	changeConsignerArrayHandler = consigner =>{
        this.setState(
	      { consigner },
	    //   () => console.log(`Consigner Option selected:`, this.state.consigner)
        );

        this.setState({
			errorMessage:'',
			successMessage:''
        })
	}
	selectConsignerOptionsItems(){
        let items = [];
		//console.log(this.state.trucks);
		var consigners=this.state.plants;

		//console.log("consigners ", consigners)
		items.push({value:'all',label:'All'});
		consigners.forEach(item =>{
			items.push({value:item.plant_code,label:item.plant_name});
		});
        // if(this.state.originalData.length > 0)
        // {
		// 	var unqis=[];
		// 	this.state.originalData.forEach(item => {
		// 		unqis.push(item.consigner_code);
		// 	});
		// 	const uniqueCosnigners = unqis.filter((value, index, self) => {
		// 		return self.indexOf(value) === index
		// 	  })
		// 	  uniqueCosnigners.forEach(item => {
        //        	items.push({value:item,label:item});

        //     });
        // }

        return items;
	}

	// onGetConsigners = async (event) => {
	// 	// var sDate = document.getElementById("from_date").value;
	// 	// var eDate = document.getElementById("to_date").value;
	// 	// // console.log(sDate,eDate);
	// 	// if(sDate.split("-")[2].length == 4){
	// 	// sDate = sDate.split("-")[2]+"-"+sDate.split("-")[1]+"-"+sDate.split("-")[0]
	// 	// }
	// 	// if(eDate.split("-")[2].length == 4){
	// 	// eDate = eDate.split("-")[2]+"-"+eDate.split("-")[1]+"-"+eDate.split("-")[0]
	// 	// }

	// 	// sDate = moment(new Date(sDate)).format("Y-MM-DD");
	// 	// eDate = moment(new Date(eDate)).format("Y-MM-DD");

	// 	// console.log(sDate,eDate);

	// 	this.setState({
	// 		// startDate:moment(sDate).format("DD-MM-Y"),
	// 		// endDate:moment(eDate).format("DD-MM-Y"),
	// 		loadshow:'show-m'
	// 	});
	// 	if (googleAnalytics.page.enableGA) {
	// 		let pageTitle = this.state.pagetitle;
	// 		let eventOptions = {
	// 			"category": pageTitle,
	// 			"action": this.state.eventFormAction,
	// 			"label": googleAnalytics.page.action.formSubmittedSelectConsigner,
	// 		}
	// 		googleAnalytics.logEvent(eventOptions);
	// 	}

	// 	var selectcons = []
	// 	if(this.state.consigner != null)
	// 	{
	// 		if(this.state.consigner.length > 0)
	// 		{
	// 			this.state.consigner.forEach(item => {
	// 				selectcons.push(item.value);
	// 			});
	// 		}
	// 	}

	// 	// if(selectcons.includes("all") || this.state.consigner == null)
	// 	// {
	// 	// 	//window.location.reload()
	// 	// 	this.setState({
	// 	// 		rowData:this.state.originalData,
	// 	// 		allRowData:this.state.originalData,
	// 	// 		loadshow:'show-n',
	// 	// 		countersjson:this.state.originalcountersjson
	// 	// 	});
	// 	// }
	// 	// else{
	// 		var tdata = {
	// 			consigner_code: selectcons,
    //             dept_code: this.state.deptcode,
	// 			screenpage:this.state.screenpage,
	// 			startDate:this.state.startDate,
	// 			endDate:this.state.endDate,
	// 		}
	// 		await redirectURL.post("/consignments/forcecloseconsigners", tdata)
	// 		.then((response) => {
	// 			//console.log("Consinger COde array ", response.data)
	// 			var records = response.data.consignments;
    //         	var pagecounters = response.data.counters;
	// 			this.setState({
	// 				rowData:records,
	// 				allRowData:records,
	// 				loadshow:'show-n',
	// 				countersjson:pagecounters
	// 				//tripvalue:'',
	// 				//triptype:"0"
	// 			});
	// 		})
	// 		.catch(function(e){
	// 			console.log("Counters Grid Error ", e)
	// 		})

	// 	//}
	// }


	onGetConsigners = async (event) => {
		var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		var urlpath = '/consignments/forceclosureconsignments';
		if(this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN")
		{
			var sDate = this.state.defaultsdate;
		}
		else{
			var sDate = this.state.startDate;
		}
		if(this.state.endDate == '' || this.state.startDate == "NaN-NaN-NaN")
		{
			var eDate = this.state.defaultedate;
		}
		else{
			var eDate = this.state.endDate;
		}
		// var sDate=this.state.startDate;
		// var eDate = this.state.endDate;
		var selectconsigners=[]
		if(this.state.consigner == null)
		{
			selectconsigners = [{"value":'all', "label":'All'}]
		}
		else{
			selectconsigners=this.state.consigner
		}

		var selecttrip=[]
		if(this.state.triptype == null)
		{
			selecttrip = [{"value":'all', "label":'All'}]
		}
		else{
			selecttrip=this.state.triptype
		}
		if(this.state.dept_code == 'SNDG' || this.state.dept_code == 'LOG-PRT')
		{
			var requestparams = {
				consigner: selectconsigners,
				dept_code: encode(this.state.deptcode),
				startDate:sDate,
				endDate:eDate,
			}

		}
		else{
			var requestparams = {
				consigner: selectconsigners,
				dept_code: encode(this.state.deptcode),
				startDate:sDate,
				endDate:eDate,
				triptype:selecttrip
			}

		}
		this.onLoadShowForceConsignmentsList(urlpath, requestparams);
	}
	handlerStartDateTime = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);

    	var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());

    	this.setState({
    		startDate:startdate
    	});
    	//let value = event.target.value;
        //this.setState({'startDate':value});
    }

    handlerEndDateTime  = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);

        var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())
        this.setState({
    		endDate:edate
    	});
	}
	onRowSelection(event){
		var containerslist=[];
		//console.log("rowselect", event)
		var rwCount = event.api.getSelectedNodes();
		// console.log("rwCount ", rwCount)
		//if(event.node.selected == true)
		//{
			//var containerlist=[];
			// console.log("rowselect", event.data)
			var rowCount =  event.data
			//console.log("rowCount ", rowCount)

			rwCount.map((item) => {
				containerslist.push(item.data.truck_no)
			});

			this.setState({
				containerslist:containerslist
			})
			//  console.log("checkedlist ", containerslist)
		// }
		// else{
		// 	if(containerslist.length > 0)
		// 	{
		// 		containerslist=[]
		// 		this.setState({
		// 			containerslist:containerslist
		// 		})
		// 	}
		// }

	}
	onClickShowMapView = async()=>{
		//this.gridApi.deselectAll();
		if(this.state.containerslist.length > 0)
		{
			var reqparms = {
				truck_no:this.state.containerslist,
				dept_code:this.state.deptcode
			}
		}
		else{
			var reqparms = {
				truck_no:[],
				dept_code:this.state.deptcode
			}
		}

		await redirectURL.post("/consignments/mapview",reqparms)
		.then((response) => {
			//console.log("Res ",response.data.message)
			var records = response.data.message;

			 var truck = JSON.parse(records.truck_info);
			 if(truck.length > 0)
			 {
				 if( truck.length > 1)
				{
					var consinees = this.state.plants;
				}
				else{
					var consinees = records.consignee_coordinates;
				}
			 }
			 else{
				var consinees = [];
			}
			//  console.log("truckinfo,",truck)
			 //console.log("truck.consignee_coordinates,",JSON.parse(records.consignee_coordinates))
			this.setState({
				open:true,
				maprowData:truck,
				rownode:truck,
				maptruckno:records.truck_no,
				consigneecoords:consinees,
				consigner_coordinates:records.consigner_coordinates
			});


			//containerslist=[];
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}
	onOpenModal = () => {
		this.setState({ open: true });
	 };

	 onCloseModal = () => {
		this.gridApi.deselectAll();
	   this.setState({ open: false });
	 };


	onOpenForceCloseModal = () => {
		this.setState({ openforceclose: true });
	 };

	 onCloseForceCloseModal = () => {
		this.gridApi.deselectAll();
	   this.setState({ openforceclose: false });
	 };

	 onCellUpdateData = (params) => {
		//  console.log("Parms ", params);
		 var reqs={
				rowid:params.data._id,
				tnpconsignment:params.value
		 }
		 redirectURL.post("consignments/updatetnpmarking", reqs)
		 .then((response) =>{
			//  console.log("Res ", response);
		 })
	 }
	 async onShowSidebarAquireComponent(e){

		// console.log(e);
		if(e.colDef.field == 'truck_no')
		{
			this.setState({
				sliderTranslatesidebar:"",
				loadshow:'show-m'
			});
			let ComponentInfo = ComponentIndex['truck'];
			//console.log("ComponentInfo", ComponentInfo);
			var layoutdata1 = {
				truck_no:e.data['truck_no']
			};
			//console.log("layoutdata",layoutdata);
			await redirectURL.post('/consignments/truckDetails', layoutdata1)
				.then((response) => {
					//console.log("trucks response", response);
					var records = JSON.parse(JSON.stringify(response)).data;
					var truckrecords = records.truck;
					var consignrecords = records.consignments;
					var currenttrucklocation = records.maplocation;
					// console.log("Current Location",currenttrucklocation);
					this.setState({
						truckInfo:truckrecords,
						consignments:consignrecords,
						currenttrucklocation:currenttrucklocation
					});
				})
				.catch(function (error) {
					console.log(error);
				});
			this.displayData=await (<ComponentInfo context={this} truckNo={e.data[e.colDef.field]} truckInfo={this.state.truckInfo}  consignments={this.state.consignments} currenttrucklocation={this.state.currenttrucklocation}></ComponentInfo>);
			this.setState({
				showdata : this.displayData,
				sliderTranslatesidebar:"slider-translate",
				loadshow:'show-n',
				overly:'show-m'
			});
		}
		else if(e.colDef.field == 'consignment_code')
		{
			this.setState({
				sliderTranslatesidebar:"",
				loadshow:'show-m'
			});
			let ComponentInfo = ComponentIndex['consignment'];
			//console.log("ComponentInfo", ComponentInfo);
			var layoutdata1 = {
				consignment_code:e.data['consignment_code'],
				consignee_code:e.data['consignee_code']
			};
			// console.log(layoutdata1);
			await redirectURL.post('/consignments/consignmentDetails', layoutdata1)
				.then((response) => {
					// console.log("trucks response", response.data[0]);
					var consignment_details = response.data;
							// var truckrecords = records.truck;
					// var consignrecords = records.consignments;
					// var currenttrucklocation = records.maplocation;
					this.setState({
						consignment_details:consignment_details,
						//consignments:consignrecords,
						//currenttrucklocation:currenttrucklocation
					});
				})
				.catch(function (error) {
					console.log(error);
				});
				try{
					this.displayData=await (<ComponentInfo context={this}  consignment={this.state.consignment_details}></ComponentInfo>);
					this.setState({
						showdata : this.displayData,
						sliderTranslatesidebar:"slider-translate",
						loadshow:'show-n',
						overly:'show-m'
					});
				}
				catch(e){
					this.setState({
						showdata : "",
						sliderTranslatesidebar:"",
						loadshow:'show-n',
						overly:'show-n'
					});
				}

		}
	}


	 handlerReachedDealerTime = (event, currentDate, selectedDate) => {
		 var d = new Date(event._d);

		 var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());

		 this.setState({
			reached_dealer_time:startdate
		 });
		 //let value = event.target.value;
		 //this.setState({'startDate':value});
	 }

	 handlerLeftDealerTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());

		this.setState({
			left_dealer_time:startdate
		});
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}

	handlerInvoiceTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());

		this.setState({
			invoice_time:startdate
		});
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}
	 onClickForceClose = (params) =>{
		 //console.log("Pareas ", params)
		 this.setState({
			forceCloseRowNode : params,
			 forceclosedata:params.data,
             sliderForceCloseTranslate:"slider-translate-40p",
             overly:'show-m'
		 })
	 }
	 resetUpload = () => {
		this.setState({
			uploadDivWidth:'0%',
			bulkslide:'',
			showDiv:'show-n',
			overly:'show-n',
			file:''
		});
		document.getElementById("upform").reset();
	}
	onClickShowBulkUpload(){
		this.setState({
			bulkslide:"slider-translate",
			overly:'show-m'
		});
	}

	 changeHandler = (event) => {
    	//console.log("Select", event.target.value);
    	let name = event.target.name;
        let value = event.target.value;
        this.setState({[name]:value});
    }
	handleInput(val) {
		this.setState({ reasonforceclose: val[1],selectedReason : val[0], deliveryDate : val[2] });
		// console.log("val",val)
	  }
	  onClickShowProbableData = async (params) =>{
		let eventLabel = googleAnalytics.page.action.nightDrive;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": "Probable Accident",
		}
		googleAnalytics.logEvent(eventOptions);
		// console.log("IN probable data, params = ",params);
		var probableData=[];
		await this.setState({

			detailCellRendererParams:{
				detailGridOptions: {
					columnDefs: [
						{ headerName: "Truck No",field: "truck_no", width:150,resizable: true },
						{ headerName: "GPS Provider",field: "actual_lspuser", width:180,resizable: true,},
						{ headerName: "Event Time",field: "timestamp",resizable: true ,
							valueGetter:function(params){
								return getHyphenDDMMMYYYYHHMM(params.data.timestamp);
							}
						},
						{
							headerName: "Event",field: "", width:180,resizable: true,
							// width:220,
							valueGetter : function(params){
								return "Harsh Braking / Probable Accident"
							}
						},
					],
					overlayNoRowsTemplate: 'No rows to show',
				},
				getDetailRowData: function(params) {
					//console.log("Step 4 ",overspeedData)
					redirectURL.post('/consignments/probableAccidentsData', {
						consignment_code:params.data.consignment_code,
					})
					.then(async (response) =>{
						// console.log("Step 1 ",response.data)
						probableData = response.data;
						params.successCallback(probableData);
						//console.log("Step 2 ",response.data)

					});

				}

			}
		});
		// console.log("Parmas ", params)
		if(params.column.colDef.field == 'probable_accident')
		{

			params.node.setExpanded(!params.node.expanded);
		}
		else{

			params.node.setExpanded(false);
		}

	}

	formForceCloseHandler(event){
		 event.preventDefault()

		var forcecloserow = this.state.forceCloseRowNode;
		// console.log(this.state.reasonforceclose);
		var consignemnt = this.state.forceCloseRowNode.data;
		var consignment_code = consignemnt.invoice_no;
		var consignee_code = consignemnt.consignee_code;

		var truck_no = consignemnt.truck_no;
		var dept_code = this.state.deptcode;
		var force_closure_time = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
		var userid = localStorage.getItem('userid');
		var reasonforceclose = this.state.reasonforceclose;
		var others_Reason = this.state.selectedReason;
		let deliveryDate = this.state.deliveryDate
	   if(this.state.deptcode == "SNDG")
		{
		   var invoice_time = getHyphenYYYYMMDDHHMMSS(consignemnt.invoice_time);
		   var reqdata = {
			   dept_code:dept_code,
			   invoice_no:[consignment_code],
			   //consignee_code:consignee_code,
			   truck_no:truck_no,
			   //force_closure_time:force_closure_time,
			   userid:userid,
			   reasonforceclose:reasonforceclose,
			   others_Reason : others_Reason,
			   screen : "TMS",
			   deliveryDate
		   }
		}
		else
		{
		   var reqdata = {
			   dept_code:dept_code,
			   consignment_code:[consignment_code],
			   truck_no:truck_no,
			   userid:userid,
			   screen : "TMS",
			   others_Reason : others_Reason,
			   reasonforceclose:reasonforceclose,
		   }
		}
	   var flag = 0;
	   if(others_Reason == "" && reasonforceclose == "Others"){
		   flag = 1;
	   }
	   if(flag == 0 && reasonforceclose == "Others")
	   {
		//    console.log(others_Reason.replace(/\s/g,"").length,"lengt");
		   if(others_Reason.replace(/\s/g,"").length == 0)
		   {
			flag = 1;
		   }
	   }
	//    console.log("reqdata ",reqdata)
	   if(flag == 0)
	   {
		   redirectURL.post("/consignments/updateConsignmentForceClose", reqdata)
		   .then((response) => {
			//    console.log("Resposne updateConsignmentForceClose ", response)
			   if(response.data.status == 'Success')
			   {
				// document.getElementById("reached_dealer_time").value='';
				// document.getElementById("left_dealer_time").value='';
				   this.setState({
					   show:true,
					   basicTitle:"Success",
					   basicType:"success",
					   loadshow:'show-n',
					   overly:'show-n',
					   left_dealer_time:'',
					   reached_dealer_time:'',
					   reachedhh:'',
					   reachedmm:'',
					   reachedss:'',
					   lefthh:'',
					   leftmm:'',
					   leftss:'',
					   reasonforceclose:"",
					   openforceclose:false,
					   sliderForceCloseTranslate:''
				   });
			   }
			   else
			   {
				   this.setState({
					   show:true,
					   basicTitle:"Error",
					   basicType:"danger",
					   loadshow:'show-n',
					   left_dealer_time:'',
					   reached_dealer_time:'',
					   reachedhh:'',
					   reachedmm:'',
					   reachedss:'',
					   lefthh:'',
					   leftmm:'',
					   leftss:'',
					   reasonforceclose:'',
					   openforceclose:false,
					   sliderForceCloseTranslate:'',
					   overly:'show-n'
				   });
			   }

		   })
		   .catch(function(e){
			   console.log("Error ", e)
		   })
	   }
	   else{
		   this.setState({
			   show: true,
			   basicTitle:'Reason for closue should not be empty',
			   basicType:"danger",
		   });
	   }
	   event.target.reset();
	 }

	 onClickDCTComment = (params) =>{
		// console.log("Pareas ", params)
		this.setState({
		    dtccommentRowNode : params.data,
			sliderDCTCommentTranslate:"slider-translate",
			overly:'show-m'
		})
	}

	formDctCommentHandler(event){
		event.preventDefault()
		var dct_comment = this.state.dct_comment;
		var dtccommentRowNode = this.state.dtccommentRowNode;
		// console.log("dtccommentRowNode ", dtccommentRowNode)
		var user = localStorage.getItem("userid");
		if(dct_comment.replace(/\s/g, '').length)
		{
			var reqparams = {
				dct_comment : dct_comment,
				consignment_code:dtccommentRowNode.consignment_code,
				userId : user,
				truck_no : dtccommentRowNode.truck_no,
				dept_code : this.state.deptcode,
				screen : "TMS"
			}
			// console.log("reqparams",reqparams);
			redirectURL.post("/consignments/savedctcomment", reqparams)
			.then((response) => {
				console.log("response ",response.data)
				if(response.data.message == 'Success')
				{
				// document.getElementById("reached_dealer_time").value='';
				// document.getElementById("left_dealer_time").value='';
					this.setState({
						show:true,
						basicTitle:"Success",
						basicType:"success",
						loadshow:'show-n',
						overly:'show-n',
						dct_comment:'',
						openforceclose:false,
						sliderDCTCommentTranslate:''
					});
				}
				else
				{
					this.setState({
						show:true,
						basicTitle:"Error",
						basicType:"danger",
						loadshow:'show-n',
						overly:'show-n',
						dct_comment:'',
						openforceclose:false,
						sliderDCTCommentTranslate:''
					});
				}
			})
			.catch(function(e){
				console.log("Error ", e)
			})
		}
		else
		{
			this.setState({
				show:true,
				basicTitle:"DCT Comment should not be empty.",
				basicType:"danger",
				loadshow:'show-n',
				overly:'show-n',
				dct_comment:'',
				openforceclose:false,
			});
		}

	}

	uploadBulkFormHandler(event){
		event.preventDefault();

		if(this.state.file != '')
		{
			// console.log("this.state.csvcontent ",this.state.csvcontent)
			var csvdd = this.state.file;

			//console.log("event ",event.target.uploadFile.value);
			var formData = new FormData();
			formData.append('uploadFile',this.state.file);
			formData.append("userId", localStorage.getItem('userid'));
			formData.append("csvcontent",this.state.csvcontent);
			// console.log("formData",formData)
			var rparams = {
				uploadFile:this.state.file,
				userId:localStorage.getItem('userid'),
				csvcontent:this.state.csvcontent,
				deptCode : this.state.deptcode
			}
			var reasonList=["GPS Data Not Available","Consignee location not updated","Consignment unloaded at different/secondary location",'Transshipment',"TVP-B: Chennai Stockyard Consignment","Others"];
			var csvContent = this.state.csvcontent;
			// console.log(csvContent)
			// console.log(typeof(csvContent));
			var reasonFlag = 0;
			var otherReasonFlag = 0
			for(var i=1;i<csvContent.length;i++)
			{
				// console.log(csvContent[i]);
				var reasonvalue = csvContent[i].reason;
				// console.log(reasonvalue);
				var otherReason = csvContent[i].other_reason;
				if(reasonvalue != "Others " && reasonvalue != "others")
				{
					for(var j=0;j<reasonList.length;j++)
					{

						if(reasonList[j].toLowerCase() === reasonvalue.toLowerCase())
						{
							reasonFlag = 0;
							// console.log(reasonList[j].toLowerCase(),reasonvalue.toLowerCase())
							break;
						}
					}
				}
				else{
					if(otherReason == "" || otherReason == undefined || otherReason == null)
					{
						// otherReasonFlag = 1;
					}
				}

			}
			//console.log('file',formData);
			//console.log('rparams',rparams);
			this.setState({
				loadshow:'show-m'
			});
			if(otherReasonFlag == 0 && reasonFlag == 0 )
			{
				redirectURL.post("/consignments/uploadBulkForceClose", rparams)
				.then(
				(response)=>{
					//console.log(response);
					//console.log(response.data);
					document.getElementById("upform").reset();
					if(response.data.status == 'failure')
					{
						if(response.data.message.empty!="")
						{
							var emtymesg = response.data.message.empty
						}
						else{
							var emtymesg = "NA";
						}
						if(response.data.message.wrong!="")
						{
							var wrongymesg = response.data.message.wrong
						}
						else{
							var wrongymesg = "NA";
						}
						if(response.data.message.scriptings!="")
						{
							var scriptingsmesg = response.data.message.scriptings
						}
						else{
							var scriptingsmesg = "NA";
						}
						var resmessage = "";
						resmessage=resmessage+emtymesg+", ";
						resmessage=resmessage+wrongymesg+", ";
						resmessage=resmessage+scriptingsmesg

						this.setState({
							//csverrmessage:response.data.status,
							show: true, basicType:'danger', basicTitle:resmessage,
							uploadDivWidth:'0%',
							sliderTranslate:'',
							showDiv:'show-n',
							loadshow:'show-n',
							uploadFile:'',
							file:''
						});

					}
					else
					{
						this.setState({
							show: true, basicType:'success', basicTitle:response.data.message,
							uploadDivWidth:'0%',
							sliderTranslate:'',
							showDiv:'show-n',
							uploadFile:'',
							loadshow:'show-n',
							file:''
						});
						window.location.reload();
					}


				}
				)
				.catch(function(error){
					console.log(error);
				});
			}
			else
			{
				$("#bulkUploadID").val("");
				if(reasonFlag)
				{
					this.setState({
						//csverrmessage:response.data.status,
						show: true, basicType:'danger', basicTitle:"Reason Column Values Are Not Found In Required Reasons",
						uploadDivWidth:'0%',
						sliderTranslate:'',
						showDiv:'show-n',
						loadshow:'show-n',
						uploadFile:'',
						file:''
					});
				}
				else
				{
					this.setState({
						//csverrmessage:response.data.status,
						show: true, basicType:'danger', basicTitle:"Other Reasons Must Not Be Empty",
						uploadDivWidth:'0%',
						sliderTranslate:'',
						showDiv:'show-n',
						loadshow:'show-n',
						uploadFile:'',
						file:''
					});
				}
			}

		}

	}


	changeFileHandler = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
				
				{
					name: 'truck_no',
					inputName: 'truck_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'invoice_no',
					inputName: 'invoice_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				// {
				// 	name: 'dept_code',
				// 	inputName: 'dept_code',
				// 	required: true,
				// 	requiredError: function (headerName, rowNumber, columnNumber) {
				// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
				// 	}
				// },
				{
					name: 'reason',
					inputName: 'reason',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'other_reason',
					inputName: 'other_reason',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
                    name: 'delivery_date',
                    inputName: 'delivery_date',
                    required: false,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			// console.log("csvdata",csvData.data)
			this.setState({
				csvcontent:csvData.data
			});
		})
		.catch(err => {})
		// console.log("e.target.files[0]",e.target.result);
		var contentList = []

		var out = new Promise(function(reject, resolve){
			var reader =  new FileReader();
			reader.onload = async function(e) {
				var contents = await e.target.result;
				resolve(contents);
			};
			var tt =  reader.readAsText(e.target.files[0]);
		});
		// console.log(contentList);
		this.setState({
			file:e.target.files[0]
		});
		// console.log("e.target.files[0].type ", e.target.files[0]);

		if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
		{
			// console.log(fileData);
			// console.log(typeof(fileData))

		}
		else{
			e.target.value = null;
			this.setState({
				uploadFile:'',
				show: true,
				basicType:'danger',
				basicTitle:'Please upload file having extensions .csv only.',
			});
		}

	}

	onClickSaveGridState(){
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();

		let screenpage = '';

		if(this.props.match.path == "/sndforceclosure")
		{
			screenpage='snd force closure';
		}
		else if(this.props.match.path == "/prtforceclosure")
		{
			screenpage='prt force closure';
		}
		else if(this.props.match.path == "/tnpforceclosure")
		{
			screenpage='tnp force closure';
		}


		let reqparams = {
			gridcolumns:window.colState,
			gridgroup:window.groupState,
			gridcolsort:window.sortState,
			gridcolfilter:window.filterState,
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:screenpage
		}
		//console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
		.then((response) => {
			//console.log("State response ",response.data)
			this.setState({
				show:true,
				basicTitle:"Successfully saved grid layout",
				basicType:"success",
				screenurl:window.location.pathname,
				screentitle:screenpage
			})
		})
		.catch(function(e){
			console.log("Error ", e)
		})
	}

	restoreGridStates()
	{

		if(this.state.usergridstate.length > 0)
		{
			var windowstates = this.state.usergridstate;
			this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
			this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
			this.gridApi.setSortModel(windowstates[0].gridcolsort);
			this.gridApi.setFilterModel(windowstates[0].gridcolfilter);

		}
	}

	resetState = () => {
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId:localStorage.getItem("userid"),
			screenurl:window.location.pathname,
			screentitle:this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
		.then((response) => {
			this.setState({
				show:true,
				basicTitle:"Successfully reset default grid layout",
				basicType:"success"
			})
		})

	};

	render(){
		const modalStyles  = {
			width:'1300px !important',
		}
		const { open } = this.state;
		const { openforceclose } = this.state;
		var maptransporter='';
		try{
			if(this.state.rownode.transporter)
			{
				///console.log("map rownode ",this.state.rownode.transporter[0])
				maptransporter=this.state.rownode.transporter[0].transporter_name;
			}
		}
		catch(e){
			console.log(e)
		}
		//console.log("Rendere ",this.state.deptcode)
		let transitTimeHeading = (this.state.deptcode === "LOG-TNP") ? "Transit Time (Hours)" : "Transit Time (Days)";
		let isHiddenLocalMovement = (this.state.deptcode === "LOG-PRT") ? false : true;
		//console.log("isHiddenLocalMovement = ", isHiddenLocalMovement, this.state.deptcode);
		let hideonlyPRT = (this.state.deptcode === "LOG-PRT") ? true : false;
		let hideonlyTNP = (this.state.deptcode === "LOG-TNP") ? true : false;
		let hideTNPconsignment = (this.state.deptcode === "LOG-TNP") ? false : true;
		//let hideOnlyActive =
		let hideonlyCol = ((this.props.match.path == "/sndconsignments")
		|| (this.props.match.path == "/prtconsignments")
		|| (this.props.match.path == "/tnpconsignments")) ? true : false;




		let showOnlyColDeliver = ((this.props.match.path == "/snddeliveredconsignments")
		|| (this.props.match.path == "/prtdeliveredconsignments")
		|| (this.props.match.path == "/tnpdeliveredconsignments")) ? false : true;

		let showonlyactivencr = ((this.props.match.path == "/sndconsignments")
		|| (this.props.match.path == "/prtconsignments")
		|| (this.props.match.path == "/tnpconsignments")
		|| (this.props.match.path == "/ncrconsignments")) ? false : true;

		let invoicedisable=(this.state.deptcode == 'SNDG')?false:true;
		//console.log("hideonlyCol ", hideonlyCol)
		var pagepath = this.props.match.path;
		var hideProbableValue = true;
		if(this.state.deptcode == "SNDG")
		{
			hideProbableValue = false;
		}
		const columnwithDefs = [

				{
					headerName: "",
					field: "_id",
					colId: "_id",
					width: 50,
					pinned: 'left',
					cellRenderer:'consignmentActions',
					filter: false,resizable: true

				},

				{
					// headerName: params =>{
					// 	if(params.data.transit_delay == 1) {
					// 		return "Transit Delay"
					// 	}else{
					// 		return 'No Transit Delay'
					// 	}	
					// },
					headerValueGetter :  params =>{
						try{
							if(params.data.transit_delay == 1) {
								return "Transit Delay"
							}else{
								return 'No Transit Delay'
							}	
						}
						catch(e){}						
					},
					field: "transit_delay",
					colId: "transit_delay",
					width: 40,
					pinned: 'left',
				    pivot: false,
					cellRendererSelector:function(params){
						if(params.data.transit_delay == 1)
						{
							var rendComponent = {
								component: 'kpiTransitDelayActions'
							};
							return rendComponent
						}
						else{
							var rendComponent = {
								component: 'notransit'
							};
							return rendComponent
						}
					},
					filter: true,resizable: true
				},
				{
					headerName: "",
					field: "no_gps_data",
					colId: "no_gps_data",
					width: 40,
					pinned: 'left',
				    pivot: false,
					cellRendererSelector:function(params){
						//console.log(params.data)
						var comp='gps';
						if(params.data.no_gps_data == 0 && params.data.no_gps_data_lifetime == 1)
						{
							comp='noGpsLifetime'

						}
						else if(params.data.no_gps_data == 1)
						{
							comp='nogps'
						}

						else if(params.data.no_gps_data == 0)
						{
							comp='gps'

						}
						var rendComponent = {
							component:comp
						};
						return rendComponent
					},
					filter: true,resizable: true
				},
				{
					headerName: "Actions",
					field: "comment_exists",
					colId: "_id",
					width: 40,
					pinned: 'left',
					cellRenderer:'commentActions',
					filter: false,resizable: true

				},
				{
					headerName: "Invoice No",
					field: "invoice_no",
					width: 120,
					pinned: 'left',
					editable:false,
					filter: "agSetColumnFilter",resizable: true
					//cellRenderer: "agGroupCellRenderer"
				},
				{
					headerName: "GR No",
					field: "consignment_code",
					width: 120,
					pinned: 'left',
					editable:false,
					filter: "agSetColumnFilter",resizable: true
					//cellRenderer: "agGroupCellRenderer"
				},
				{
					headerName: "Status",
					field: "status",
					width: 120,
					pinned: 'left',
					filter: "agSetColumnFilter",
					resizable: true,
					valueGetter:function(params){
						//console.log("GPS ", params.data.gps_data_available);
						if(params.data.status == 1)
						{
							return "Inside Plant"
						}
						else if(params.data.status == 2)
						{
							return "In Transit"
						}
						else if(params.data.status == 3)
						{
							return "Near Destination"
						}
						else if(params.data.status == 4)
						{
							return "Reached Destination"
						}

						else if(params.data.status == 5)
						{
							return "Left Destination"
						}
						else if(params.data.status == 6)
						{
							return "Force Closed"
						}
						else{
							return ""
						}

					}
				},
				{
					headerName: "Original ETA",
					field: "expected_trip_end",
					width: 120,
					//pinned: 'left',
					resizable: true,
					valueGetter:function(params){
						return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end);
					},
					//filter: "agDateColumnFilter",
					comparator: dateComparator,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			else{
					// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			if(filterValue.split("-")[2].length < 2)
					// 			{
					// 				var temp = filterValue
					// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },
				},
				{
					headerName: "Revised ETA",
					field: "revised_trip_end",
					width: 120,
					//pinned: 'left',
					resizable: true,
					valueGetter:function(params){
						//console.log(" ETA ", params.data)
						  if(params.data.revised_trip_end == '' || params.data.revised_trip_end == undefined)
						  {
							  return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end);
						  }
						  else{
							  return getHyphenDDMMMYYYYHHMM(params.data.revised_trip_end);
						  }

					},
					//filter: "agDateColumnFilter",
					comparator: dateComparator,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			else{
					// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			if(filterValue.split("-")[2].length < 2)
					// 			{
					// 				var temp = filterValue
					// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },
					cellClass:function(params){
						let hourDifference = 0;
						if (params.data.revised_trip_end && params.data.expected_trip_end) {
							let revisedDateOnlyMoment  = moment(params.data.revised_trip_end);
							let expectedDateOnlyMoment  = moment(params.data.expected_trip_end);
							hourDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'hours');
							//console.log("diff= ", params.data.consignment_code, hourDifference);
						} else {
							//console.log("ELSE = ", params.data.revised_trip, params.data.expected_trip_end);
						}

						if(hourDifference >= 1)
						{
							return 'bgColorDangerMedium'
						}

						if(hourDifference <= -1)
						{
							return 'bgColorSuccessMedium'
						}
						else{
							return ''
						}
					}
				},
				{
					headerName: "Truck No",
					field: "truck_no",
					width: 100,
					filter: "agSetColumnFilter",
				    cellRenderer:'',resizable: true
			    },
 	  	        {
		          headerName: "Transporter Name",
                  field: "transporter_name",
                //   valueGetter:function(params){
				// 	  //console.log(params.data.transporter);
				// 	  if (params.data.hasOwnProperty('transporter'))
				// 	  {
				// 		  if (params.data.transporter.length > 0)
				// 		  {
				// 			return params.data.transporter[0].transporter_name;
				// 		  }
				// 	  }

                //   },
                  width: 150,
                  filter: "agSetColumnFilter",
				  cellRenderer:'',resizable: true
				},


				// {
				// 	headerName : "TPT Confirmed Delivery",
				// 	field : "tpt_confirm_delivery",
				// 	width:140,
				// 	resizable : true,
				// 	filter:true,
				// 	valueGetter:function(params){
				// 		if(params.data.tpt_confirm_delivery == 1)
				// 		{
				// 			return "Yes";
				// 		}
				// 		else
				// 		{
				// 			return "No";
				// 		}
				// 	}
				//   },
				// {
				//   headerName : "TPT Delivered On",
				//   field : "tpt_delivery_date",
				//   resizable : true,
				//   filter : true,
				//   width:150,
				//   valueGetter : function(params){
				// 	if(params.data.tpt_delivery_date != ""){
				// 	  return getDDMMYYYYHHMMSS(params.data.tpt_delivery_date);
				// 	}
				//   }
				// },
				  {
                    headerName:"Last City/Town",
                    field:"truck",
					width:150,
					valueGetter:function(params){
						//console.log(params.data.truck);
						if (params.data.hasOwnProperty('truck'))
						{
							if (params.data.truck.length > 0)
							{
							  return params.data.truck[0].area;
							}
						}

					},
                    filter:"agSetColumnFilter",
					cellRenderer:'',resizable: true
                },
				  {
                    headerName:"Last State",
                    field:"truck",
                    width:120,
                    filter:"agSetColumnFilter",
					cellRenderer:'',resizable: true,
					valueGetter:function(params){
						//console.log(params.data.truck);
						if (params.data.hasOwnProperty('truck'))
						{
							if (params.data.truck.length > 0)
							{
							  return params.data.truck[0].state;
							}
						}

					}
                },

				{
					headerName: "Last Packet Time",
					field: "truck",
					valueGetter:function(params){
						//console.log(params.data.transporter);
						if (params.data.hasOwnProperty('truck'))
						{
							if (params.data.truck.length > 0)
							{
								return getHyphenDDMMMYYYYHHMM(params.data.truck[0].timestamp);
							}
						}

					},
					comparator: dateComparator,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			else{
					// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			if(filterValue.split("-")[2].length < 2)
					// 			{
					// 				var temp = filterValue
					// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },
					width: 150,
					//filter: "agDateColumnFilter",
					cellRenderer:'',resizable: true
				},
				{
					headerName: "GPS Provider",
					field: "truck",
					width: 150,
					filter: "agSetColumnFilter",
					cellRenderer:'',resizable: true,
					valueGetter:function(params){
						//console.log(params.data.transporter);
						if (params.data.hasOwnProperty('truck'))
						{
							if (params.data.truck.length > 0)
							{
							  return params.data.truck[0].actual_lspuser;
							}
						}

					},
				},
				{
					headerName: "Invoice Date",
					field: "invoice_time",
					width: 150,
					//filter: "agDateColumnFilter",
					cellRenderer:'',resizable: true,
					hide:hideonlyPRT,
					comparator: dateComparator,
					// filterParams: {
					// 	// browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			else{
					// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			if(filterValue.split("-")[2].length < 2)
					// 			{
					// 				var temp = filterValue
					// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			console.log(cellDate,filteredDate);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },
					valueGetter:function(params){
						  if(params.data.invoice_time != '' && params.data.invoice_time != undefined)
						  {
							  return getHyphenDDMMMYYYYHHMM(params.data.invoice_time);
						  }
						  else{
							  return "NA";
						  }
					},
				},
	  	        {
                    headerName: "Consigner Code",
                    field: "consigner_code",
                    width: 150,
                    filter: "agSetColumnFilter",
					cellRenderer:''	,resizable: true
                },
                {
                  headerName: "Consignee Code",
                  field: "consignee_code",
                  width: 150,
                  filter: "agSetColumnFilter",
				  cellRenderer:'',resizable: true
				},

                {
					headerName: "Dist. From Destination (km)",
					field: "distance_from_dealer_location",
					width: 150,
					filter: "agSetColumnFilter",
					cellRenderer:'',resizable: true	,
					valueGetter:function(params){
						//console.log("params ", params.data);
						if(typeof params.data.distance_from_dealer_location == 'undefined')
						{

						}
						else
						{
							return Math.round(params.data.distance_from_dealer_location);
						}

					},
					hide:showonlyactivencr
				  },

				{
					headerName: "Consignee Name",
					field: "consignee_name",
					width: 150,
					hide:hideonlyTNP,
					filter: "agSetColumnFilter",
					cellRenderer:'',resizable: true
				  },
				  {
					headerName: "Consignee City",
					field: "consignee_city",
					width: 150,
					hide:hideonlyTNP,
					filter: "agSetColumnFilter",
					cellRenderer:'',resizable: true
				  },
				  {
                    headerName: "Consignee State",
                    field: "consignee_state",
                    width: 120,
                    filter: "agSetColumnFilter",
					cellRenderer:''	,resizable: true
                },
                // {
                //   headerName: "Department Code",
                //   field: "dept_code",
                //   width: 120,
                //   filter: "agSetColumnFilter",
				//   cellRenderer:''  ,resizable: true
                // },

        //         {
        //             headerName: "Gate in Time",
        //             field: "gate_in_time",
        //             width: 180,
        //             //filter: "agDateColumnFilter",
				// 	//cellRenderer:'dateFormater',
				// 	comparator: dateComparator,
				// 	// filterParams: {
				// 	// 	browserDatePicker: true,
				// 	// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
				// 	// 	// provide comparator function
				// 	// 	comparator: function(filteredDate,cellValue,secondDate) {
				// 	// 		cellValue = cellValue.replace(/\//g,"-")
				// 	// 		if(cellValue != "" && cellValue != " "){
				// 	// 			cellValue = cellValue.split(" ")[0].split("-");
				// 	// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
				// 	// 			var cellDate = new Date(cellValue);
				// 	// 			if(filteredDate.getMonth() < 10){
				// 	// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
				// 	// 			}
				// 	// 			else{
				// 	// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
				// 	// 			}
				// 	// 			if(filterValue.split("-")[2].length < 2)
				// 	// 			{
				// 	// 				var temp = filterValue
				// 	// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
				// 	// 			}
				// 	// 			var filteredDate = new Date(filterValue);
				// 	// 			cellDate = cellDate.getTime();
				// 	// 			filteredDate = filteredDate.getTime();
				// 	// 			// console.log(cellDate,filteredDate);
				// 	// 			if(cellDate === filteredDate)
				// 	// 			{
				// 	// 				return 0;
				// 	// 			}
				// 	// 			if(cellDate < filteredDate){
				// 	// 				return -1;
				// 	// 			}
				// 	// 			if(cellDate > filteredDate)
				// 	// 			{
				// 	// 				return 1;
				// 	// 			}
				// 	// 		}
				// 	// 	}
				// 	// },
				// 	valueGetter:function(params){
				// 		return getHyphenDDMMMYYYYHHMM(params.data.gate_in_time);
				// 	},
				// 	resizable: true
				// },
                {
                    headerName: "Invoice Time",
                    field: "invoice_time",
                    width: 180,
					//filter: "agDateColumnFilter",
					//cellRenderer:'dateFormater',
					// sortable:true,
					comparator: dateComparator,
					// filterParams: {
					// 	browserDatePicker: true,
					// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
					// 	// provide comparator function
					// 	comparator: function(filteredDate,cellValue,secondDate) {
					// 		cellValue = cellValue.replace(/\//g,"-")
					// 		if(cellValue != "" && cellValue != " "){
					// 			cellValue = cellValue.split(" ")[0].split("-");
					// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
					// 			var cellDate = new Date(cellValue);
					// 			if(filteredDate.getMonth() < 10){
					// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			else{
					// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
					// 			}
					// 			if(filterValue.split("-")[2].length < 2)
					// 			{
					// 				var temp = filterValue
					// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
					// 			}
					// 			var filteredDate = new Date(filterValue);
					// 			cellDate = cellDate.getTime();
					// 			filteredDate = filteredDate.getTime();
					// 			// console.log(cellDate,filteredDate);
					// 			if(cellDate === filteredDate)
					// 			{
					// 				return 0;
					// 			}
					// 			if(cellDate < filteredDate){
					// 				return -1;
					// 			}
					// 			if(cellDate > filteredDate)
					// 			{
					// 				return 1;
					// 			}
					// 		}
					// 	}
					// },
					valueGetter:function(params){
						return getHyphenDDMMMYYYYHHMM(params.data.invoice_time);
					},
					resizable: true
                },

          //       {
          //           headerName:"Local Movement(Yes/No)",
          //           field:"local_movement",
          //           width:200,
          //           filter:"agSetColumnFilter",
					// cellRenderer:'',resizable: true,
					// hide: isHiddenLocalMovement,
					// valueGetter:function(params){
					// 	if(params.data.local_movement == true){
					// 		return "Yes";
					// 	}
					// 	else
					// 	{
					// 		return "No";
					// 	}
					//
					// }
          //       },
                {
                    headerName: transitTimeHeading,
                    field:"transit_time",
                    width:200,
                    filter:"agSetColumnFilter",
					cellRenderer:'',resizable: true
                },
                {
                    headerName:"Expected Distance (KM)",
                    field:"distance_in_km",
                    width:200,
                    filter:"agSetColumnFilter",
					cellRenderer:'',resizable: true
                },
                {
                    headerName:"Zone",
                    field:"zone",
                    width:100,
                    filter:"agSetColumnFilter",
					cellRenderer:'',resizable: true
                },
        //         {
        //             headerName:"Trip Type",
        //             field:"trip_type",
        //             widht:100,
				// 	hide:hideonlyPRT,
        //             filter:"agSetColumnFilter",
				// 	cellRenderer:'',resizable: true
				// },
				//
				//
        //         {
        //             headerName:"SPOC Contact Name",
        //             field:"spoc_contact_name",
        //             width:180,
        //             filter:"agSetColumnFilter",
				// 	resizable: true,
				// 	// valueGetter:function(params){
				// 	// 	if(typeof params.data.spocdetails != 'undefined' && params.data.spocdetails.length > 0){
				// 	// 		return params.data.spocdetails[0].spoc_contact_name;
				// 	// 	}
				// 	// }
        //         },
        //         {
        //             headerName:"SPOC Contact Number",
        //             field:"spoc_contact_number",
        //             width:180,
        //             filter:"agSetColumnFilter",
				// 	resizable: true,
				// 	// valueGetter:function(params){
				// 	// 	if(typeof params.data.spocdetails != 'undefined' && params.data.spocdetails.length > 0){
				// 	// 		return params.data.spocdetails[0].spoc_contact_number;
				// 	// 	}
				// 	// }
				// },
				// {
        //             headerName:"Probable Accident",
        //             field:"probable_accident",
        //             width:180,
        //             filter:"agSetColumnFilter",
				// 	resizable: true,
				// 	hide : hideProbableValue,
				// 	cellRenderer : "ProbableAction"
        //         },

				// {
        //             headerName: "TNP Consignment",
        //             field: "tnpconsignment",
        //             width: 200,
				// 	filter: "agSetColumnFilter",
				// 	hide:hideTNPconsignment,
				// 	cellEditor: 'agRichSelectCellEditor',
				// 	cellEditorParams: {
				// 		values: [
				// 		'Yes',
				// 		'No'
				// 		],
				// 	},
				// 	editable: true,
				// 	resizable: true,
				// 	valueGetter:function(params){
				// 		if(params.data.tnpconsignment == 1)
				// 		{
				// 			return "Yes";
				// 		}
				// 		else
				// 		{
				// 			return "No";
				// 		}
				// 	}
				// },
				// {
				// 	headerName: "Critical",
				// 	field: "is_critical",
				// 	width: 90,
				// 	cellRenderer: function(params) {
				// 		var input = document.createElement('input');
				// 		input.type="checkbox";
				// 		params.value=0;
				// 		var status = false;
				// 		if(params.data.is_critical == true)
				// 		{
				// 			status=true;
				// 			input.checked=true;
				// 			params.value=1;
				// 		}
				// 		input.addEventListener('click', function (event) {
				// 			//console.log(event);
				// 			params.value=!params.value;
				// 			//console.log(params);
				// 			onSetCritical(params.data, params.value)
				// 		});
				// 		return input;
				// 	},
				// 	filter: false,resizable: true

				// },
				{
					headerName: "",
					field: "consignment_code",
					width: 120,
					cellRendererSelector:function(params){
						var rendComponent = {
							component: 'consignmentDctComment'
						};
						return rendComponent;
					},
					filter: false,resizable: true,
					hide: true
				},
				// {
				// 	headerName: "DCT Comment",
				// 	field: "dct_comment",
				// 	width: 200,
				// 	filter: false,
				// 	resizable: true,
				// 	cellClass: ['wraptext'],
				// 	autoHeight: true,
				// 	//tooltipField: 'dct_comment',
          		// 	//tooltipComponentParams: { color: '#ececec' },
				// 	valueGetter:function(params){
				// 		//console.log("params.data.dct_marked_user ",params.data.dct_marked_user)
				// 		if(typeof params.data.dct_marked_user != 'undefined')
				// 		{
				// 			var dctuser = params.data.dct_marked_user;
				// 			var dctdate = getDDMMYYYYHHMMSS(params.data.dct_comment_on);
				// 			var dctcomment = params.data.dct_comment;
				// 			return dctuser+": Updated on "+dctdate+": "+dctcomment;
				// 		}
				// 		else{
				// 			return "";
				// 		}

				// 	}
				// },

				{
					headerName: "Force Close",
					field: "consignment_code",
					width: 80,
					cellRendererSelector:function(params){
						var rendComponent = {
							component: 'consignmentforceclose'
						};
						return rendComponent;
					},
					filter: false,resizable: true,


				},

				{
					headerName: "Force Closed By",
					field: "force_closure_performed_by",
					width: 150,
					hide:showOnlyColDeliver,
					filter: false,resizable: true,


				},

				{
					headerName: "Force Closed Reason",
					field: "force_closure_reason",
					width: 200,
					hide:showOnlyColDeliver,

					filter: false,resizable: true,


				},

				{
					headerName: "Force Closed On",
					field: "force_closure_time",
					width: 180,
					hide:showOnlyColDeliver,

					filter: false,resizable: true,
					valueGetter:function(params){
						return getHyphenDDMMMYYYYHHMM(params.data.force_closure_time)
					}

				}



		];
		const dStyles={
			width:this.props.width,
			height:'500px',
			marginLeft:this.props.marginLeft,

	}
	const commentColumns = [
		{
		  headerName : "Truck No",
		  field : "truck_no",
		  resizable : true,
		  width:100,
		  filter : true
		},
		{
		  headerName : "Consignment Code",
		  field : "consignment_code",
		  resizable : true,
		  width:120,
		  filter : true
		},
		{
		  headerName : "User Type",
		  field : "user_type",
		  width:100,
		  resizable : true,
		  filter : true,
		  valueGetter : function(params){
			if(params.data.user_type == "ORGUSER"){
			  return "MSIL"
			}
			if(params.data.user_type == "DCTUSER"){
			  return "DCT"
			}
			if(params.data.user_type == "TRANSPORTER"){
			  return "TRANSPORTER"
			}
		  }
		},
		{
		  headerName : "Comment",
		  field : "comment",
		  width:250,
		  cellClass: ['wraptext'],
		  resizable : true,
		  filter : true
		},
		{
		  headerName : "Commented By",
		  field : "username",
		  width:150,
		  resizable : true,
		  filter:true
		},

		{
		  headerName : "Commented Date",
		  field : "comment_date",
		  resizable : true,
		  filter : true,
		  width:150,
		  valueGetter : function(params){
			if(params.data.comment_date != ""){
			  return getDDMMYYYYHHMMSS(params.data.comment_date);
			}
		  }
		}

	  ]
	var hText = 'All';
	if(this.state.deptcode == 'SNDG')
	{
		hText = 'Sales and Dispatch'
	}
	if(this.state.deptcode == 'LOG-TNP')
	{
		hText = 'Train and Production'
	}

	if(this.state.deptcode == 'LOG-PRT')
	{
		hText = 'Spare Parts'
	}
	const tripoptoins = [
		{"value":"all", label:"All"},
		{"value":"S", label:"Short Haul"},
		{"value":"L", label:"Long Haul"}
	]

		return(
			<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
				{(this.state.deptcode == 'LOG-TNP')?
				<div className="row col-xl-12 col-lg-12">
					<div className="row col-xl-12 col-lg-12">
						<div className="form-group col-xl-3 col-lg-3">
							<label>Trip Type: </label>
							<Select placeholder={"All"} value={this.state.triptype} className="border-radius-0" onChange={this.onSelectTrip.bind(this)} style={{borderRadius:"0px"}} options={tripoptoins} required />
							{/* <select className="form-control" value={this.state.triptype} onClick={this.onSelectTrip.bind(this)}>
								<option value="0">All</option>
								<option value="S">Short Haul</option>
								<option value="L">Long Haul</option>
							</select> */}
						</div>
						{/* <div className="form-group col-xl-4 col-lg-4">
							<label>&nbsp;</label><br />
							<button type="button" className="btn btn-success" onClick={this.onClickTripButton.bind(this)}>Submit</button>
						</div>
					 */}
						<div className="form-group col-xl-3 col-lg-3">
							<label>Select Consigner: </label>
							<Select placeholder={"All"} closeMenuOnSelect={false} value={this.state.consigner} isMulti="true" className="border-radius-0" onChange={this.changeConsignerArrayHandler.bind(this)} style={{borderRadius:"0px"}} options={this.selectConsignerOptionsItems()} required />

						</div>
						<div className="col-xl-2 col-lg-2 form-group">
							<label>From Date</label>
							<Datetime value={this.state.startDate}
							disableCloseOnClickOutside={false}
							closeOnSelect={true}
							inputProps={{ placeholder: 'From', name: 'startDate', autoComplete:'off' }}
							 dateFormat="YYYY-MM-DD"
							  name="startDate"
							  onChange={this.handlerStartDateTime.bind(this)} />
							{/* <input type="text" placeholder="From Date" id="from_date" value={this.state.startDate} autocomplete="off" className="datetimepicker_date form-control"  required /> */}

						</div>

						<div className="col-xl-2 col-lg-2 form-group">
							<label>To Date</label>
							{/* <input type="text" placeholder="To Date" id="to_date" value={this.state.endDate} autoComplete="off" className="datetimepicker_date form-control" autoComplete="off" required /> */}
							<Datetime value={this.state.endDate} disableCloseOnClickOutside={false} closeOnSelect={true} inputProps={{ placeholder: 'To', name: 'endDate', autoComplete:'off'}} dateFormat="YYYY-MM-DD" onChange={this.handlerEndDateTime.bind(this)} />
						</div>
						<div className="form-group col-xl-2 col-lg-2">
							<label>&nbsp;</label><br />
							<button type="button" className="btn btn-success" autoComplete="off" onClick={this.onClickTripButton.bind(this)}>Submit</button>
						</div>

					</div>
				</div>
				:""}

				{(this.state.deptcode == 'LOG-PRT' || this.state.deptcode == 'SNDG')?
				<div className="row col-xl-12 col-lg-12">
					<div className="form-group col-xl-4 col-lg-4">
						<label>Select Consigner: </label>
						<Select placeholder={"All"} closeMenuOnSelect={false} value={this.state.consigner} isMulti="true" className="border-radius-0" onChange={this.changeConsignerArrayHandler.bind(this)} style={{borderRadius:"0px"}} options={this.selectConsignerOptionsItems()} required />

					</div>
					<div className="col-xl-3 col-lg-3 form-group">
						<label>From Date</label>
						{/* <input type="text" placeholder="From Date" id="from_date" value={this.state.startDate} autoComplete="off" className="datetimepicker_date form-control"  required /> */}
						<Datetime value={this.state.startDate} disableCloseOnClickOutside={false} closeOnSelect={true} inputProps={{ placeholder: 'From', name: 'startDate', autoComplete:'off' }} dateFormat="YYYY-MM-DD"  name="startDate" onChange={this.handlerStartDateTime.bind(this)} />
					</div>

					<div className="col-xl-3 col-lg-3 form-group">
						<label>To Date</label>
						{/* <input type="text" placeholder="To Date" id="to_date" value={this.state.endDate} autoComplete="off" className="datetimepicker_date form-control"  required /> */}
						<Datetime value={this.state.endDate} disableCloseOnClickOutside={false} closeOnSelect={true} inputProps={{ placeholder: 'To', name: 'endDate', autoComplete:'off'}} dateFormat="YYYY-MM-DD" onChange={this.handlerEndDateTime.bind(this)} />
					</div>

					<div className="form-group col-xl-2 col-lg-2">
						<label>&nbsp;</label><br />
						<button type="button" className="btn btn-success" onClick={this.onGetConsigners.bind(this)}>Submit</button>
					</div>

				</div>
				:""}

				{
					(this.state.isCounterDisplay == 1)?
					<Counters context={this} countersjson={this.state.countersjson} unloadshow={hideonlyCol} gridData={this.state.allRowData} deptcode={this.state.deptcode} triptype={this.state.tripvalue} />

					:""
				}
				 <div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
                            <div className="card-header">
				       			<h5>
								<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle} </span>
								<button className="float-right f14 custom-btn btn-warning" onClick={this.onClickShowBulkUpload.bind(this)}>
										Bulk Force Closure
									</button>
								</h5>
				   			</div>
		            		<div className="card-body pt-15px" >
								<div className="row">
									<div className="col-xl-12 col-lg-12 mb-10p">
										{(this.props.match.path == "/sndconsignments" || this.props.match.path == "/prtconsignments" || this.props.match.path == "/tnpconsignments")?

										<button className="float-left custom-btn btn-warning" onClick={this.onClickShowMapView.bind(this)}>
											<i className="icofont icofont-social-google-map"></i> Map View
										</button>
										:""}
										<span className="float-right" style={{fontSize:"13px",fontWeight:"100"}}>
										<i className="icofont icofont-map-pins f20" style={{ fontSize: "22px" }}></i> - View Route
										&nbsp;
										<i className="icofont icofont-clock-time" style={{color:"#ff0000", fontSize:"22px	"}}></i> - Transit Delay
										&nbsp;<i className="icofont icofont-social-google-map txt-danger" style={{ fontSize: "22px" }}></i> - GPS Data Not Available
											&nbsp;<i className="icofont icofont-social-google-map txt-secondary" style={{ fontSize: "22px" }}></i> - GPS Data Not Adequate
										&nbsp;
										<i className="fa fa-comment f15" style={{ fontSize:"22px"}}></i> - View Comments
										{/*(this.state.deptcode == "SNDG")?
											<span>
												&nbsp;<i className="icofont icofont-barricade" style={{fontSize:"22px",color:"green"}}></i> - Probable Accident
											</span>
											:""*/}

										</span>
									</div>
									<div className="col-xl-12">
										<span className="layoutbtns float-right">
											<button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
												<i className="icofont icofont-save"></i> Save Grid Layout
											</button>
											<button className="float-right custom-btn white btn-info" style={{marginRight:"10px"}} onClick={this.resetState.bind(this)}>
												<i className="icofont icofont-refresh"></i> Reset Default Layout
											</button>
										</span>
									</div>
								</div>
							{/*
				   				   <Grid
								   ashow={this.state.show}
								   atype={this.state.basicType}
								   atitle={this.state.basicTitle}
								   actionCall={"manage"}
									actionMethod={"updateTruckRow"}
									table={"trucklocation"}
									columnyaxis={this.state.rowData}
									columnaxis={columnwithDefs}
									frameworkComponents={this.state.frameworkComponents}
									detailCellRendererParams={this.state.detailCellRendererParams}
									childconfs={this.state.childconfs}
									childrow={this.state.childrow}
									context={this }

									/>
									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>
								*/}

								 <div id="myGrid" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
		          		          <AgGridReact
		          		            modules={this.state.modules}
		          		            columnDefs={columnwithDefs}
		          		            defaultColDef={this.state.defaultColDef}
									rowData={this.state.rowData}
									onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
		          		            enableCharts={true}
		          		            enableRangeSelection={true}
		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
		          		            onGridReady={this.onGridReady}
		          		            onGridState={this.onGridState}
		          		            statusBar={this.state.statusBar}
									sideBar={this.state.sideBar}
									paginationPageSize={this.state.paginationPageSize}
									pagination={true}
									frameworkComponents={this.state.frameworkComponents}
									detailCellRendererParams={this.state.detailCellRendererParams}
									//editType={this.state.editType}
									stopEditingWhenGridLosesFocus={true}
									//floatingFilter={true}
									enableCellChangeFlash={true}
									suppressCellFlash={true}
									rowClassRules={this.state.rowClassRules}
									//onCellClicked={this.onRowClicked.bind(this)}
									gridOptions={{
										context: { componentParent: this }
									}}
									masterDetail={true}
									onCellEditingStopped={this.onCellUpdateData.bind(this)}

									rowSelection={this.state.rowSelection}
									onRowSelected={this.onRowSelection.bind(this)}
									suppressRowClickSelection={true}
									overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
									tooltipShowDelay={this.state.tooltipShowDelay}
		          		          	//getRowHeight={this.state.getRowHeight}
								/>
{/*
									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
									</div> */}

		          		        </div>
				   			</div>
				   		</div>
				   	</div>

				 </div>
				 {this.state.sliderTranslate != ''?
					<div className={"slide-r "+(this.state.sliderTranslate)} style={{overflow:"auto"}}>
						<div className="slide-r-title">
							<h4>
								Timeline #{this.state.timelinedata[0].consignment_code}
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseUploadDiv.bind(this)} >X</span>

							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>

							<div className="row col-xl-12 col-lg-12">
							<VerticalTimeline>
								<VerticalTimelineElement
									className="vertical-timeline-element--work"
									contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' ,borderTopLeftRadius: "20%", borderBottomRightRadius: "20%"}}
									contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
									date="Basic Information"
									iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}

								>
									<h3 className="vertical-timeline-element-title">Truck No</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].truck_no }</h4>
									<h3 className="vertical-timeline-element-title">Transporter Code</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].transporter_code }</h4>
									<h3 className="vertical-timeline-element-title">Department Code</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].dept_code }</h4>
									<h3 className="vertical-timeline-element-title">Vehicle Mode</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].vehicle_mode }</h4>
									<h3 className="vertical-timeline-element-title">Items</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].items }</h4>
									<h3 className="vertical-timeline-element-title">Distance (Kms)</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].distance_in_km }</h4>

								</VerticalTimelineElement>


								<VerticalTimelineElement
									className="vertical-timeline-element--work"
									date="Gate in time"
									contentStyle={{ background: 'rgb(234, 68, 99)', color: '#fff' ,borderTopRightRadius: "20%", borderBottomLeftRadius: "20%" }}
									contentArrowStyle={{ borderRight: '7px solid  rgb(243, 33, 33)' }}
									iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}

								>
									<h3 className="vertical-timeline-element-title">MSIL Gate intime</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].gate_in_time }</h4>

									<h3 className="vertical-timeline-element-title">GPS Gate intime</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].inside_fence }</h4>

									<h3 className="vertical-timeline-element-title">Invoice time</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].invoice_time }</h4>

								</VerticalTimelineElement>

								<VerticalTimelineElement
									className="vertical-timeline-element--work"
									date="MSIL Gate out time"
									contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' ,borderTopLeftRadius: "20%", borderBottomRightRadius: "20%" }}
									contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
									iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}

								>
									<h3 className="vertical-timeline-element-title">MSIL Gate out time</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].gate_out_time }</h4>

									<h3 className="vertical-timeline-element-title">GPS Gate out time</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].outside_fence }</h4>

								</VerticalTimelineElement>

								<VerticalTimelineElement
									className="vertical-timeline-element--work"
									date="Consignee Code"
									contentStyle={{ background: 'rgb(234, 68, 99)', color: '#fff' ,borderTopRightRadius: "20%", borderBottomLeftRadius: "20%" }}
									contentArrowStyle={{ borderRight: '7px solid  rgb(243, 33, 33)' }}
									iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}

								>
									<h3 className="vertical-timeline-element-title">Consignee Code</h3>
									<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].consignee_code }</h4>

								</VerticalTimelineElement>

								</VerticalTimeline>

							</div>
						</div>
					 </div>
				:""
				}

				 {this.state.sliderRouteTranslate != ''?
					 <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								{(this.state.consignment_code)}
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>

							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>

							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
								<DrawMap context={this} tolls={this.state.tolls} rownode={this.state.rownode} mapFor={"consignment"} googleroutes={this.state.googelRoutes} truckno={this.state.maptruckno} dealer={this.state.dealer} mapinfo={this.state.mapinfo} defTransitCoords={this.state.defTransitCoords} />
								{/*
								<div id="map" className="" style={{width:'100%',height:"70vh"}}></div>
								*/}
								<div className="col-xl-12 col-lg-12 n-p-0">
									<div className="crm-numbers pb-0">
										<h3 className="subH">{this.state.sidebarSubHeader}</h3>
										<div className="col-xl-12 col-lg-12 row">
											<div className="col route-block">
												<label className="sidebar-label">Truck No</label>
												<div>{this.state.routeTruck.truck_no}</div>
											</div>
											{(maptransporter != '')?
											<div className="col route-block">
												<label className="sidebar-label">Transporter</label>
												<div>{(!isEmpty(maptransporter))?maptransporter:""}</div>
											</div>

											:""}
											<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(this.state.routeTruck.startTime != '')?getDDMMYYYYHHMMSS(this.state.routeTruck.startTime):"NA"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(this.state.routeTruck.endTime != '')?this.state.routeTruck.endTime:"NA"}</div>
											</div>

											<div className="col route-block">
												<label className="sidebar-label">GPS Distance</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?Math.round(this.state.mapinfo.route_details.distance/1000):"0"} Kms</div>
											</div>
											{(this.state.leg_no == 0 || this.state.leg_no == null)?	""
											:
											<div className="col route-block">
												<label className="sidebar-label">Google Distance</label>
												<div>{(this.state.rownode.actual_google_leg_distance)?parseInt(this.state.rownode.actual_google_leg_distance):"0"} Kms</div>
											</div>
											}

											{/*<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.start_time):"-"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.end_time):"-"}</div>
											</div>
										*/}

										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				:""
				}
				<div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>

				<Modal open={open} onClose={this.onCloseModal} styles={modalStyles} classNames={{
          overlay: 'customOverlay',
          modal: 'customModal',
        }}>
	                <div className={"row"} style={{padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',}} >
						{(this.state.maprowData != '')?
							<ConsignmentDrawMap context={this} griddata={this.state.maprowData} consigner_coordinates={this.state.consigner_coordinates} consigneecoords={this.state.consigneecoords} rownode={this.state.rownode} mapFor={"consignment"} />
							:""}

					</div>
				</Modal>
				{this.state.sliderCommentTranslate != ''?
					 <div className={"slide-r "+(this.state.sliderCommentTranslate)} style={{overflow:"auto"}}>
					 	<div className="slide-r-title">
							<h4>
								Consignment Comments
								<span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							<div id="myGrid1" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
		          		          <AgGridReact
		          		            modules={this.state.modules}
		          		            columnDefs={commentColumns}
		          		            defaultColDef={this.state.defaultColDef}
									rowData={this.state.commentsRowData}
									onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
		          		            enableCharts={true}
		          		            enableRangeSelection={true}
		          		            autoGroupColumnDef={this.state.autoGroupColumnDef}
		          		            onGridReady={this.onGridReady}
		          		            onGridState={this.onGridState}
		          		            statusBar={this.state.statusBar}
									// sideBar={this.state.sideBar}
									paginationPageSize={this.state.paginationPageSize}
									pagination={true}

		          		          />



		          		    </div>
						</div>

					</div>
				:""
				}

				<div className={"slide-r "+(this.state.sliderForceCloseTranslate)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Force Closure
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>

                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>

					<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',height:"100em"}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formForceCloseHandler.bind(this)}>
							{/* <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Reached Dealer Time</label>

                                <input type="text" placeholder="Reached Dealer " id="reached_dealer_time" className="datetimepicker_mask form-control"  />

							</div>

							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Left Dealer Time</label>

                                <input type="text" placeholder="Left Dealer " id="left_dealer_time" className="datetimepicker_mask form-control" />

							</div> */}
							<ForceCloseSideBar context={this} handleInput = {this.handleInput} deptcode={this.state.deptcode}/>
							{/* <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Reason for closure</label>
								<textarea name="reasonforceclose" onChange={this.changeHandler.bind(this)} value={this.state.reasonforceclose} rows="5" className="form-control col-xl-12 col-lg-12" required></textarea>
							</div> */}
							{/* <div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div> */}
						</form>
                        </div>
					</div>
				</div>



				<div className={"slide-r "+(this.state.sliderDCTCommentTranslate)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           DCT Comment
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHideAll.bind(this)} >X</span>

                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>

					<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', overflow: 'hidden',zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formDctCommentHandler.bind(this)}>

							<div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Comment</label>
                                <textarea name="dct_comment" onChange={this.changeHandler.bind(this)} value={this.state.dct_comment} rows="5" className="form-control col-xl-12 col-lg-12" required></textarea>
                            </div>
                            <div className="col-xl-12 col-lg-12 form-group">
                                <button type="submit" className="btn btn-success">Submit</button>
                            </div>
						</form>
                        </div>
					</div>
				</div>

				{/* Bulk Assign Excel Upload */}
				<div className={"slide-r "+(this.state.bulkslide)} style={{overflow:"hidden"}}>
					<h3 className="subH">Bulk Update</h3>

					<div className="slide-r-body" style={{position:"relative"}}>

						<div className="container-fluid">
							<form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkFormHandler}>

							<div className="form-group mt-20p">
								<label className="">Upload File</label>
								<input type="file" name="uploadFile" id="bulkUploadID" onChange={this.changeFileHandler}  className="form-control" required  />
							</div>
							<div className="form-group">
								<button type="submit" className="btn btn-success">Submit</button>
								<button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
							</div>
							</form>
							<div className="form-group">
								<p style={{color:"#ff0000"}}>*Note: All fields related to Force close are mandatory</p>
								<p><b>Force Close bulk update rules:</b></p>

								<p><b>Reasons :</b> GPS Data Not Available/Consignee location not updated/Consignment unloaded at different location/Transshipment/
								{/* {(this.state.deptcode == 'SNDG')?"TVP-B: Chennai Stockyard Consignment":""} */}
								{/* {(this.state.deptcode == 'LOG-PRT')?"Pass Through Courier - Direct Delivery Project":""} */}
								/Others</p>
								<p style={{color:"#ff0000"}}>*Note: If Others is selected, The other_reason Field must not be empty</p>


							</div>
							<div className="form-group">
								<a className="btn btn-primary" href={require('../../assets/json/bulk_force_closure_file.csv')} target="_blank">Sample Template</a>
							</div>
							<div className="form-group">
								{/* <a className="btn btn-primary" href={require('../../assets/json/Bulk_tickets_update_file.csv')} target="_blank">Sample Template</a> */}

							</div>
						</div>
					</div>
				</div>

				<div className={"slide-r "+(this.state.sliderTranslatesidebar)} >
					{this.displayData}
				</div>

			</div>

		);
	}
}



function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}
function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}
function timeConvert(n) {
	// var num = n/1000;
	// var hours = (num / (3600*24));
	// var rhours = Math.floor(hours);
	// var minutes = (n) / (60*60);
	// var rminutes = Math.round(minutes);

	var diffMs = n;
	var diffDays = Math.floor(diffMs / 86400000); // days
	var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
	var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes


	return diffHrs + " hour(s) and " + (diffMins/60) + " minute(s).";
}
var currentinfowindow = null;
function getTimeInfo(marker,timedet,coords)
{
	var timeinfo = new window.google.maps.InfoWindow({
          content: "Time at this location: " + timedet
        });

	marker.addListener('click', function() {

				if (currentinfowindow != null) {
					currentinfowindow.close();
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}
				else
				{
					currentinfowindow = timeinfo;
					timeinfo.open(marker.get('map'), marker);
				}

        });
}

function onSetCritical(params, status){
	// console.log("onSetCritical ", params);
	// console.log("onSetCritical value ", status);
	redirectURL.post('/consignments/setCriticalStatus',{
		params:params,
		is_critical:status
	})
		.then((response) => {

		//var records = JSON.parse(JSON.stringify(response)).data;
		console.log(response.data);
		});

}

function secondsToString(seconds)
{
var numdays = Math.floor(seconds / 86400);
var numhours = Math.floor((seconds % 86400) / 3600);
var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
var numseconds = ((seconds % 86400) % 3600) % 60;
return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}
function CheckForHtmlTag(){
	var rs = document.getElementById("invalid_reason");
	var reg =/<(.|\n)*?>/g;
	if (reg.test(rs.value)) {
		var ErrorText = "Oops! HTML or Script is not allowed.";
		rs.value=''
		//alert();
		return 1;
	}
}
function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
        // console.log(date1,date2);
    var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
    date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
    var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
    date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
    // console.log(date1,date2);
    var date1Number = monthToComparableNumber(date1);
    var date2Number = monthToComparableNumber(date2);
    // console.log(date1Number,date2Number);
    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
    }
  }
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName){
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
        ];
    var monthNum = months.indexOf(monthName)+1;
    if(monthNum < 10){
        monthNum = "0"+monthNum.toString();
    }
    return monthNum;
}

window.onload = function (){
	// $(".datepicker").datepicker({
    //     dateFormat: "dd-mm-yy",
    //     changeMonth: true,
    //     changeYear: true
	// });
	// alert("loading");
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
};


function loadDateTimeScript(){
	// alert("datetime");
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async=true
    script.defer = true
	index.parentNode.insertBefore(script,index)
	// alert("function called");
}
