import React, { Component } from 'react';
import CountUp from 'react-countup';
import axios from 'axios';
var redirectURL = require('../redirectURL');

export default class CourierCounters extends Component {
    constructor(props){
    	super(props);
    	
    	this.state={
    		records:[],
            intransitcount:0,
            outdelivercount:0,
            delivercount:0,
            datanacount:0,
            total:0,
            counttype:"all",
            confirmDelivery:0,
            critical_counter_data : 0,
            cancelled_count : 0,
            trip_closed_by_pod: 0,
            pod_received_data: 0,
            prioritydatacount: 0,
            on_time_data:0,
            delayed_data:0,
            delayed_data_count:0,
            on_time_data_count:0,
            force_close_count:0
    	}
    
    }
    componentWillReceiveProps = async (newprops) =>{
        console.log(newprops,"newprops")
        var cancelled_count = 0
       if(newprops.cancelled_data != "Not Applicable")
       {
        cancelled_count = newprops.cancelled_data.length
       }
        await this.setState({
            total:newprops.total,
            intransitcount:newprops.intransitcount,
            outdelivercount:newprops.outdelivercount,
            delivercount:newprops.delivercount,
            datanacount:newprops.datanacount,
            counttype:newprops.counttype,
            confirmDelivery : newprops.confirmDelivery,
            critical_counter_data : newprops.critical_counter_data,
            cancelled_count: cancelled_count,
            on_time_data:newprops.on_time_data,
            delayed_data:newprops.delayed_data,
            trip_closed_by_pod: newprops.trip_closed_by_pod,
            pod_received_data: newprops.pod_received_data,
            prioritydatacount: newprops.prioritydatacount,
            on_time_data_count:newprops.on_time_data_count,
            delayed_data_count:newprops.delayed_data_count,
            forceclosecount:newprops.forceclosecount,
            force_close_count:newprops.force_close_count
        });


    }

    onClickCounter(data){
        this.props.context.onClickCounterShowData(data)
    }
    render(){
        var activeshow=(this.state.counttype == 'all' || this.state.counttype == 'active' )?'show-m':'show-n'
        return (
            <div className="row">

                <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                    <div className="card">
                        <div className="card-body" id="counterCardBody">
                            <div className="crm-numbers pb-0">
                                {/* {(this.state.counttype == 'all' || this.state.counttype == 'active')? */}
                                    <div className="row">
                                        {(this.state.counttype == 'all' || this.state.counttype == 'active')?
                                            <div className="col cirlce-d cursorPointer"  onClick={this.onClickCounter.bind(this,"all")}>
                                                <span className="f13"><i className="icofont icofont-ui-browser f24 greenfont"></i><br /> Total</span>
                                                <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.total != '')?this.state.total:0}/></span></h4> 
                                            </div>
                                        : ""}
                                        {(this.state.counttype == 'all' || this.state.counttype == 'active')?
                                            <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"intransit")}>
                                                <span className="f13"><i className="icofont icofont-clock-time f22 redfont"></i><br /> In Transit</span>
                                                <h4 className="txt-danger f40"><span className="counter"><CountUp end={(this.state.intransitcount != '')?this.state.intransitcount:0}/></span></h4>
                                                
                                            </div>
                                        :""}
                                        {(this.state.counttype == 'all' || this.state.counttype == 'active')?
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"outfordeliver")}>
                                            <span className="f13"><i className="icofont icofont-social-google-map f24 txt-secondary"></i><br /> Out for Delivery</span>
                                            <h4 className="txt-secondary f40"><span className="counter"><CountUp end={(this.state.outdelivercount != '')?this.state.outdelivercount:0}/></span></h4>
                                            
                                        </div>:""}

                                        {(this.state.counttype == 'deliver' || this.state.counttype == 'all')?
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"deliver")}>
                                            <span className="f13"><i className="fa fa-tachometer f22 txt-success"></i><br /> Delivered</span>
                                            <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.delivercount != '')?this.state.delivercount:0}/></span></h4>
                                        
                                        </div>
                                        :""}
                                        {(this.state.counttype == 'all')?
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"forceclose")}>
                                            <span className="f13"><i className="fa fa-tachometer f22 txt-success"></i><br /> Force Closed</span>
                                            <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.force_close_count != '')?this.state.force_close_count:0}/></span></h4>
                                        
                                        </div>
                                        :""}
                                        {(this.state.counttype == 'all' || this.state.counttype == 'active')?
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"datana")}>
                                            <span className="f13"><i className="fa fa-tachometer f22 txt-warning"></i><br /> Tracking NA</span>
                                            <h4 className="txt-warning f40"><span className="counter"><CountUp end={(this.state.datanacount != '')?this.state.datanacount:0}/></span></h4>
                                        
                                        </div>:""}
                                        {/* {(localStorage.getItem("user_type") == "TRANSPORTER")?"":
                                            <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"confirmDelivery")}>
                                                <span className="f13"><i className="icofont icofont-vehicle-delivery-van cus-i"></i><br /> Transporter Confirmed Delivery</span>
                                                <h4 className="f40" style={{color:"#777777"}}><span className="counter"><CountUp end={(this.state.confirmDelivery != '')?this.state.confirmDelivery:0}/></span></h4>
                                        
                                            </div> 
                                        } */}
                                        {/* {(this.state.counttype == 'all' || this.state.counttype == 'active')?
                                            <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"critical")}>
                                                <span className="f13"><i className="icofont icofont-minus-circle f22 maroon"></i><br /> Critical</span>
                                                <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.critical_counter_data != '')?this.state.critical_counter_data:0}/></span></h4>
                                            
                                            </div>   
                                        :""}     */}
                                        {(this.state.counttype == 'active')?
                                            <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"priority")}>
                                                <span className="f13"><i className="icofont icofont-check f22 green"></i><br /> Priority</span>
                                                <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.prioritydatacount != '')?this.state.prioritydatacount:0}/></span></h4>
                                            
                                            </div>   
                                        :""}
                                        {(this.state.counttype == 'invalid')?
                                        // <div className="row">
                                            <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"datana")}>
                                                <span className="f13"><i className="fa fa-tachometer f22 txt-warning"></i><br /> Tracking NA</span>
                                                <h4 className="txt-warning f40"><span className="counter"><CountUp end={(this.state.datanacount != '')?this.state.datanacount:0}/></span></h4>
                                            
                                            {/* </div> */}
                                        </div>
                                        :""}
                                        {(this.props.path == "/deliveredcourierconsignments")?
                                        // <div className="">
                                        
                                            <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"ontime")}>
                                                <span className="f13"><i className="fa fa-clock-o f22 txt-danger"></i><br /> OnTime</span>
                                                <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.on_time_data_count != '')?this.state.on_time_data_count:0}/></span></h4>
                                            
                                            </div>                                   
                                        // </div>
                                        :""}
                                        {(this.props.path == "/deliveredcourierconsignments")?
                                        // <div className="">
                                        
                                            <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"delayed")}>
                                                <span className="f13"><i className="fa fa-clock-o f22 txt-warning"></i><br /> Delayed</span>
                                                <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.delayed_data_count != '')?this.state.delayed_data_count:0}/></span></h4>
                                            
                                            </div>                                   
                                        // </div>
                                        :""}
                                        {/* {(this.state.counttype == 'deliver')?
                                        // <div className="row">
                                        
                                            <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"deliver")}>
                                                <span className="f13"><i className="fa fa-tachometer f22 txt-success"></i><br /> Delivered</span>
                                                <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.delivercount != '')?this.state.delivercount:0}/></span></h4>
                                            
                                            </div>                                   
                                        // </div>
                                        :""} */}
                                        {/* {(this.props.path == "/courierconsignments" || this.props.path == "/deliveredcourierconsignments")?
                                        // <div className="">
                                        
                                            <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"cancelled")}>
                                                <span className="f13"><i className="fa fa-ban f22 txt-success"></i><br /> Cancelled (VE)</span>
                                                <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.cancelled_count != '')?this.state.cancelled_count:0}/></span></h4>
                                            
                                            </div>                                   
                                        // </div>
                                        :""} */}
                                        {/* {(this.props.path == "/courierconsignments" || this.props.path == "/activecourierconsignments" 
			                                || this.props.path == "/deliveredcourierconsignments")?
                                        // <div className="">
                                        
                                            <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"pod")}>
                                                <span className="f13"><i className="icofont icofont-vehicle-delivery-van cus-i f22 txt-success"></i><br /> POD Received (VE)</span>
                                                <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.pod_received_data != '')?this.state.pod_received_data:0}/></span></h4>
                                            
                                            </div>                                   
                                        // </div>
                                        :""}       */}
                                        {/* {(this.props.path == "/courierconsignments"
			                                || this.props.path == "/deliveredcourierconsignments")?
                                        // <div className="">
                                        
                                            <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"trip_closed")}>
                                                <span className="f13"><i className="icofont icofont-vehicle-delivery-van cus-i f22 txt-warning"></i><br /> Trip Closed By POD (VE)</span>
                                                <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.trip_closed_by_pod != '')?this.state.trip_closed_by_pod:0}/></span></h4>
                                            
                                            </div>                                   
                                        // </div>
                                        :""}                             */}
                                    </div>

                                
                                {/* {((this.state.counttype == 'all')||(this.state.counttype == "active"))?
                                    
                                :""} */}
                                
                            </div>
                        
                        </div>
                    </div>
                </div>
            
            </div>
        );
    }
}






