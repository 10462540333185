/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import ReactDOM from "react-dom";
import SweetAlert from "react-bootstrap-sweetalert";

import Select from "react-select";
import _ from "lodash";

import MarkerClusterer from "@google/markerclusterer";
import Modal from "react-responsive-modal";
import axios from "axios";
import { AgGridReact } from "@ag-grid-community/react";
import { AllModules } from "@ag-grid-enterprise/all-modules";
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import * as Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import CustomLoadingCellRenderer from "./customLoadingCellRenderer";
import {
    getDDMMYYYY,
    getYYYYMMDD,
    getDDMMYYYYHHMMSS,
    getHyphenDDMMMYYYYHHMM,
    getHyphenDDMMMYYYY,
} from "../common/utils";
import "react-vertical-timeline-component/style.min.css";
import DrawMap from "../common/drawmap";
import ConsignmentActions from "../manage/ConsignmentActionsComponent";
import ReportCarrier from "./reportcarrierbtn";
// import WithdrawCarrier from "./withdrawalcarrierbtn";
// import ParkingoutCarrier from "./parkingcarrierbtn";
import $ from "jquery";
import "jquery-ui/ui/core";
import "jquery-ui/ui/widgets/datepicker";
var infoBox = require("../common/google-infowindow");
var googleAnalytics = require("../common/googleanalytics");

var redirectURL = require("../redirectURL");
var moment = require("moment");

var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);

var marker;
var markers = [];
var circle;
var selectlat;
var selectlng;
var allcords = [];
var map;
var rad = function (x) {
    return (x * Math.PI) / 180;
};
var rlatitude;
var rlongitude;

var fencingLength = 0;
var geoFencingArea;

const decipher = (salt) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const applySaltToChar = (code) =>
        textToChars(salt).reduce((a, b) => a ^ b, code);
    return (encoded) =>
        encoded
            .match(/.{1,2}/g)
            .map((hex) => parseInt(hex, 16))
            .map(applySaltToChar)
            .map((charCode) => String.fromCharCode(charCode))
            .join("");
};
var decode = decipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

export default class ParkingOutReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle: "",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            uploadDivWidth: "0%",
            sliderTranslate: "",
            sliderRouteTranslate: "",
            tabsliderTranslate: "",
            loadshow: "show-n",
            showDiv: "show-n",
            alertshow: "fade",
            alertmg: "show-n",
            alerterrmg: "show-n",
            overly: "show-n",
            alerterrshow: "fade",
            alert: null,
            show: false,
            basicTitle: "",
            basicType: "default",
            show1: false,
            basicTitle1: "",
            basicType1: "default",
            modules: AllModules,
            sidebarSubHeader: "",
            defTransitCoords: "",
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
            },
            rowData: [],

            rowModelhighchartoptionsType: "enterprise",
            rowGroupPanelShow: "always",
            overlayNoRowsTemplate: "",
            frameworkComponents: {
                consignmentActions: ConsignmentActions,
                customLoadingCellRenderer: CustomLoadingCellRenderer,
                ReportCarrier: ReportCarrier,
                // WithdrawCarrier: WithdrawCarrier,
                // ParkingoutCarrier: ParkingoutCarrier,
            },

            animateRows: true,
            debug: true,
            showToolPanel: false,
            uppressAggFuncInHeader: true,

            childconfs: [],
            childrow: [],
            rowModelType: "serverSide",
            paginationPageSize: 50,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left",
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center",
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" },
                ],
            },
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel",
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel",
                    },
                ],
            },
            detailCellRendererParams: {},
            rowClassRules: {
                // "highlitRow": "data.transit_delay == 1",
                // "green":"data.status >= 3"
            },
            fencelist: [],
            startDate: "",
            endDate: "",
            mapinfo: "",
            defaultsdate: "",
            defaultedate: "",
            withdrawmodal: false,
            parkinglist: [],
            geofence_name: { value: "", label: "Select Parking" },
            parkingyard: "show-m",
            truckinfo: "show-n",
            pact: "btn-warning",
            tact: "btn-defaultc",
            truckslist: [],
            truck_no: { value: "", label: "Select Truck" },
            dept_code: "",
            transporters: [],
            transporter_code: { value: "", label: "Select Transporter" },
            struckslist: [],
            struck_no: { value: "", label: "Select Truck" },
            radius: "5000",
            rlatitude: "",
            rlongitude: "",
            latitude: "",
            longitude: "",
            defaultradius: 0,
            coordinates: [],
            alltrucks: [],
            search_truck_no: "",
            search_transporter_name: "",
            search_gps_provider: "",
            search_speed: "",
            search_last_packet_time: "",
            search_gps_status: "",
            search_address: "",
            search_city: "",
            search_state: "",
            search_carrier_reported: "",
            reporttruck: 0,
            fence_name: { value: "", label: "Select Parking" },
            plants: [],
            plant_code: { value: "", label: "Select Parking Yard" },
            withdrawdata: "",
            withdraw_reason: "",
            gpsplant: "",
            gpsradius: 15,
            mwidth: "col-xl-5 col-lg-5",
            rowData1: [],
            nsearch: "show-n",
            selectedTabLat: "",
            loginplant: "",
            filteredData: [],
            manual_truck: "",
            manual_transporter: { value: "", label: "Select Transporter" },
            manual_trucktype: { value: "", label: "Select Type" },
            manual_parkingyard: { value: "", label: "Select Parking Yard" },
            manual_reported_on: "",
            showTrucksList: "show-n",
            manual_parking_out: "",

            parkingdata: "",
            slideerParkingOut: "",
        };
        this.selectOptionsItems = this.selectOptionsItems.bind(this);
        this.onShowRouteDiv = this.onShowRouteDiv.bind(this);
        this.cipher = this.cipher.bind(this);
        this.onClickReportCarrierAction = this.onClickReportCarrierAction.bind(
            this
        );
        // this.onClickWithdrawCarrierAction = this.onClickWithdrawCarrierAction.bind(
        //     this
        // );
        this.onClickParkingoutCarrierAction = this.onClickParkingoutCarrierAction.bind(
            this
        );
        this.onLoadData = this.onLoadData.bind(this);
        this.onLoadFilterData = this.onLoadFilterData.bind(this);
        this.formHandler = this.formHandler.bind(this);
        this.formSearchHandler = this.formSearchHandler.bind(this);
        this.formCarrierHandler = this.formCarrierHandler.bind(this);
        this.onSubmitFormData = this.onSubmitFormData.bind(this);
        this.onSubmitWithdrawAction = this.onSubmitWithdrawAction.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.closeAlert1 = this.closeAlert1.bind(this);
        this.formGeofenceHandler = this.formGeofenceHandler.bind(this);
        this.submitFormHandler = this.submitFormHandler.bind(this);
        this.popmarker = this.popmarker.bind(this);
        this.onSubmitManualParking = this.onSubmitManualParking.bind(this);
        this.onClickShowManualParkingForm = this.onClickShowManualParkingForm.bind(
            this
        );
        this.formParkingoutCarrier = this.formParkingoutCarrier.bind(this);
    }
    cipher = (salt) => {
        const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
        const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
        const applySaltToChar = (code) =>
            textToChars(salt).reduce((a, b) => a ^ b, code);
        try {
            return (text) =>
                text
                    .split("")
                    .map(textToChars)
                    .map(applySaltToChar)
                    .map(byteHex)
                    .join("");
        // eslint-disable-next-line no-unreachable
        } catch (e) { }
    };

    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction =
                    googleAnalytics.page.actionType.gridAction +
                    " - " +
                    window.location.pathname;
                let eventCounterAction =
                    googleAnalytics.page.actionType.counterAction +
                    " - " +
                    window.location.pathname;
                let eventFormAction =
                    googleAnalytics.page.actionType.formAction +
                    " - " +
                    window.location.pathname;
                this.setState({
                    eventGridAction: eventGridAction,
                    eventCounterAction: eventCounterAction,
                    eventFormAction: eventFormAction,
                });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    };

    componentDidMount() {
        loadDateTimeScript();
        loadDatepicker();
        var loguser = decode(localStorage.getItem("m"));
        // console.log("LOGUSER ", JSON.parse(loguser));
        var loginplant = "";
        try {
            var luser = JSON.parse(loguser);
            loginplant = luser.is_plant_code;
            // console.log("logplant", loginplant)
            this.setState({
                loginplant: loginplant,
                // consigner:[{"value":luser.is_plant_code,"label":luser.is_plant_name}]
            });
        } catch (e) { }

        var sdate = moment.parseZone().format("YYYY-MM-DD");
        var edate = moment.parseZone().format("YYYY-MM-DD");
        //console.log("Props ", this.props.match)
        this.logPageView(); // Log page view to GA
        var urlpath;
        var dpt;
        var hText = "(All)";
        this.setState({
            loadshow: "show-m",
        });
        var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
        // if (this.props.match.path == "/sndparkingreport") {
            urlpath = "/dashboard/sndparkingreport";
            var urlpath1 = "/dashboard/sndparkingslist";
            var urlpath2 = "/dashboard/snddeptplants";
            dpt = "SNDG";
            hText = "( Sales and Dispatch )";
        // } else if (this.props.match.path == "/prtparkingreport") {
        //     urlpath = "/dashboard/prtparkingreport";
        //     urlpath1 = "/dashboard/prtparkingslist";
        //     urlpath2 = "/dashboard/prtdeptplants";
        //     dpt = "LOG-PRT";
        //     hText = "( Spare Parts )";
        // } else {
        //     urlpath = "/dashboard/parkingreports";
        //     urlpath1 = "/dashboard/parkingslist";
        //     dpt = "all";
        // }
        let pageTitle = "Parking Out Report " + hText + "";

        //console.log(dpt);
        this.setState({
            dept_code: dpt,
            pageTitle: pageTitle,
            startDate: sdate,
            endDate: edate,
        });

        var reqparams = {
            dept_code: encode(dpt),
            startDate: sdate,
            endDate: edate,
        };
        redirectURL.post(urlpath1).then((response) => {
            var records = response.data.records;
            // console.log("TT records ", records);
            var transporters = response.data.transporters;
            var stransporters = [];
            if (transporters.length > 0) {
                transporters.map((item) => {
                    stransporters.push({
                        value: item.transporter_code,
                        label: item.transporter_name,
                    });
                });
            }
            var parkings = [];
            if (records.length > 0) {
                records.map((item) => {
                    if (loginplant != "" && loginplant != undefined) {
                        if (item.plant_code == loginplant) {
                            parkings.push({
                                value: item.geofence_name,
                                label: item.geofence_name,
                            });
                        }
                    } else {
                        parkings.push({
                            value: item.geofence_name,
                            label: item.geofence_name,
                        });
                    }
                });
            }
            this.setState({
                parkinglist: parkings,
                transporters: stransporters,
            });
            // console.log("parksings ", records)
        });

        redirectURL.post(urlpath2).then((response) => {
            var records = response.data.records;
            var plants = [];
            if (records.length > 0) {
                records.map((item) => {
                    if (loginplant != "" && loginplant != undefined) {
                        if (item.plant_code == loginplant) {
                            plants.push({
                                value: item.plant_code,
                                label: item.plant_name,
                            });
                        }
                    } else {
                        plants.push({
                            value: item.plant_code,
                            label: item.plant_name,
                        });
                    }
                });
                this.setState({
                    plants: plants,
                });
            }
        });
        this.onLoadData(reqparams);
    }

    onLoadData(reqparams) {
        // if (this.props.match.path == "/sndparkingreport") {
            var urlpath = "/dashboard/sndparkingreport";
        // } else if (this.props.match.path == "/prtparkingreport") {
        //     urlpath = "/dashboard/prtparkingreport";
        // } else {
        //     urlpath = "/dashboard/parkingreports";
        // }
        redirectURL.post(urlpath, reqparams).then((response) => {
                var records = response.data.records;
                // console.log("rcords ", records)
                var trucks = [];
                var recordsarr = [];
                var transporters = [];
                if (records.length > 0) {
                    trucks.push({ value: "", label: "Select Truck" });
                    records.map((item) => {
                        if (
                            this.state.loginplant != "" &&
                            this.state.loginplant != undefined
                        ) {
                            if (item.plant_code == this.state.loginplant) {
                                trucks.push({ value: item.truck_no, label: item.truck_no });
                                if (
                                    item.trucks.length > 0 &&
                                    item.trucks[0].transporter_code != undefined &&
                                    item.trucks[0].transporter_code != ""
                                ) {
                                    transporters.push({
                                        transporter_code: item.trucks[0].transporter_code,
                                        transporter_name: item.trucks[0].transporter_name,
                                    });
                                }
                            }
                        } else {
                            trucks.push({ value: item.truck_no, label: item.truck_no });
                            if (
                                item.trucks.length > 0 &&
                                item.trucks[0].transporter_code != undefined &&
                                item.trucks[0].transporter_code != ""
                            ) {
                                transporters.push({
                                    transporter_code: item.trucks[0].transporter_code,
                                    transporter_name: item.trucks[0].transporter_name,
                                });
                            }
                        }

                        var d1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss");
                        var d2 = moment
                            .parseZone(item.truckloc[0].timestamp)
                            .format("YYYY-MM-DD HH:mm:ss");
                        var ms = moment(d1, "YYYY-MM-DD HH:mm:ss").diff(
                            moment(d2, "YYYY-MM-DD HH:mm:ss")
                        );
                        var d = moment.duration(ms);
                        var s = Math.floor(d.asHours());
                        // console.log("d1 ", d1)
                        // console.log("d2 ", d2)
                        // console.log("Hrs ", s)
                        item.travelstatus = s;
                        if (s >= 24) {
                            item.gps_status = "Inactive";
                        } else {
                            item.gps_status = "Active";
                        }
                        if (
                            this.state.loginplant != "" &&
                            this.state.loginplant != undefined
                        ) {
                            if (item.plant_code == this.state.loginplant) {
                                recordsarr.push(item);
                            }
                        } else {
                            recordsarr.push(item);
                        }
                    });
                }

                let newArray = [];
                // Declare an empty object
                let totuniqueObject = {};
                var totobjTitle;
                // Loop for the array elements
                for (let i in transporters) {
                    // Extract the title
                    totobjTitle = transporters[i]["transporter_code"];

                    // Use the title as the index
                    totuniqueObject[totobjTitle] = transporters[i];
                }

                // Loop to push unique object into array
                for (var a in totuniqueObject) {
                    newArray.push(totuniqueObject[a]);
                }
                var transporterlist = [];
                // console.log("Newrar ", newArray);
                if (newArray.length > 0) {
                    newArray.map((item) => {
                        transporterlist.push({
                            value: item.transporter_code,
                            label: item.transporter_name,
                        });
                    });
                }
                this.setState({
                    rowData: recordsarr,
                    truckslist: trucks,
                    loadshow: "show-n",
                    overly: "show-n",
                    // transporters:transporterlist
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    onLoadFilterData(reqparams) {
        // if (this.props.match.path == "/sndparkingreport") {
            var urlpath = "/dashboard/sndparkingreport";
        // } else if (this.props.match.path == "/prtparkingreport") {
        //     urlpath = "/dashboard/prtparkingreport";
        // } else {
        //     urlpath = "/dashboard/parkingreports";
        // }
        redirectURL
            .post(urlpath, reqparams)
            .then((response) => {
                var records = response.data.records;
                // console.log("rcords ", records)
                var trucks = [];
                var recordsarr = [];
                var transporters = [];
                if (records.length > 0) {
                    records.map((item) => {
                        if (
                            this.state.loginplant != "" &&
                            this.state.loginplant != undefined
                        ) {
                            if (item.plant_code == this.state.loginplant) {
                                if (
                                    item.trucks.length > 0 &&
                                    item.trucks[0].transporter_code != undefined &&
                                    item.trucks[0].transporter_code != ""
                                ) {
                                    transporters.push({
                                        transporter_code: item.trucks[0].transporter_code,
                                        transporter_name: item.trucks[0].transporter_name,
                                    });
                                }
                            }
                        } else {
                            if (
                                item.trucks.length > 0 &&
                                item.trucks[0].transporter_code != undefined &&
                                item.trucks[0].transporter_code != ""
                            ) {
                                transporters.push({
                                    transporter_code: item.trucks[0].transporter_code,
                                    transporter_name: item.trucks[0].transporter_name,
                                });
                            }
                        }

                        var d1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss");
                        var d2 = moment
                            .parseZone(item.truckloc[0].timestamp)
                            .format("YYYY-MM-DD HH:mm:ss");
                        var ms = moment(d1, "YYYY-MM-DD HH:mm:ss").diff(
                            moment(d2, "YYYY-MM-DD HH:mm:ss")
                        );
                        var d = moment.duration(ms);
                        var s = Math.floor(d.asHours());
                        // console.log("d1 ", d1)
                        // console.log("d2 ", d2)
                        // console.log("Hrs ", s)
                        item.travelstatus = s;
                        if (s >= 24) {
                            item.gps_status = "Inactive";
                        } else {
                            item.gps_status = "Active";
                        }
                        if (
                            this.state.loginplant != "" &&
                            this.state.loginplant != undefined
                        ) {
                            if (item.plant_code == this.state.loginplant) {
                                recordsarr.push(item);
                            }
                        } else {
                            recordsarr.push(item);
                        }
                    });
                }

                let newArray = [];
                // Declare an empty object
                let totuniqueObject = {};
                var totobjTitle;
                // Loop for the array elements
                for (let i in transporters) {
                    // Extract the title
                    totobjTitle = transporters[i]["transporter_code"];

                    // Use the title as the index
                    totuniqueObject[totobjTitle] = transporters[i];
                }

                // Loop to push unique object into array
                for (var a in totuniqueObject) {
                    newArray.push(totuniqueObject[a]);
                }
                var transporterlist = [];
                // console.log("Newrar ", newArray);
                if (newArray.length > 0) {
                    newArray.map((item) => {
                        transporterlist.push({
                            value: item.transporter_code,
                            label: item.transporter_name,
                        });
                    });
                }
                this.setState({
                    rowData: recordsarr,
                    loadshow: "show-n",
                    overly: "show-n",
                    // transporters:transporterlist
                });
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    onGridReady = (params) => {
        this.gridApi = params.api;

        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
    };

    onGridState = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns,
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    };

    onClickHideAll() {
        this.setState({
            loadshow: "show-n",
            overly: "show-n",
            sliderRouteTranslate: "",
            tabsliderTranslate: "",
            slideAddManualForm: "",
            slideerParkingOut: "",
        });
    }
    closeAlert = async () => {
        await this.setState({
            show: false,
        });
        //await window.location.reload();
    };

    closeAlert1 = () => {
        this.setState({
            show1: false,
        });
    };
    handlerStartDateTime = (event, currentDate, selectedDate) => {
        //console.log("event ", event)
        //var d = new Date(event._d);
        var d = new Date(moment(event).format("YYYY-MM-DD"));
        //console.log("d ", d)
        var startdate =
            d.getFullYear() +
            "-" +
            (d.getMonth() + 1) +
            "-" +
            (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());
        // console.log("startdate ", startdate)
        this.setState({
            startDate: startdate,
        });
        //let value = event.target.value;
        //this.setState({'startDate':value});
    };

    handlerEndDateTime = (event, currentDate, selectedDate) => {
        //var d = new Date(event._d);
        var d = new Date(moment(event).format("YYYY-MM-DD"));
        // var edate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate())
        var enddate =
            d.getFullYear() +
            "-" +
            (d.getMonth() + 1) +
            "-" +
            (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

        this.setState({
            endDate: enddate,
        });

        //let value = event.target.value;
        //this.setState({'startDate':value});
    };
    formHandler = (event) => {
        event.preventDefault();
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pageTitle;
            let eventOptions = {
                category: pageTitle,
                action: this.state.eventFormAction,
                label: googleAnalytics.page.action.formSubmittedGetData,
            };
            googleAnalytics.logEvent(eventOptions);
        }
        this.setState({
            loadshow: "show-m",
        });
        //console.log("code ", this.state.dept_code)
        if (this.state.startDate != "" && this.state.endDate != "") {
            var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
            var sdate = $("#startDate").val();
            var edate = $("#endDate").val();
            if (this.state.truck_no.value != "") {
                var truck_no = this.state.truck_no.value;
            } else {
                truck_no = "";
            }
            if (this.state.geofence_name.value != "") {
                var geofence_name = this.state.geofence_name.value;
            } else {
                geofence_name = "";
            }
            var parameters = {
                dept_code: encode(this.state.dept_code),
                startDate: sdate,
                endDate: edate,
                truck_no: truck_no,
                geofence_name: geofence_name,
            };
            console.log("Params ", parameters);
            this.onLoadFilterData(parameters);
        } else {
            this.setState({
                show1: true,
                basicTitle1: "Start date and end Date should  not be empty",
                basicType1: "danger",
            });
        }
    };
    selectOptionsItems() {
        let items = [];
        //console.log(this.state.fencelist);
        this.state.fencelist.forEach((item) => {
            if (item != "" && item != null) {
                items.push(
                    <option key={item} value={item}>
                        {item}
                    </option>
                );
            }
        });
        return items;
    }

    handlerForm = (event) => {
        console.log("Select", event.target.value);
        let name = event.target.name;
        let value = event.target.value;
        this.setState({ [name]: value });
    };
    onCloseUploadDiv = () => {
        this.setState({
            uploadDivWidth: "0%",
            sliderRouteTranslate: "",
            tabsliderTranslate: "",
            slideerParkingOut: "",
            showDiv: "show-n",
            overly: "show-n",
        });
    };
    onCloseRouteDiv = () => {
        this.setState({
            uploadDivWidth: "0%",
            sliderRouteTranslate: "",
            slideAddManualForm: "",
            slideerParkingOut: "",
            showDiv: "show-n",
            overly: "show-n",
        });
    };

    onShowRouteDiv = (params) => {
        // console.log("Params ", params);
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pageTitle;
            let eventOptions = {
                category: pageTitle,
                action: this.state.eventGridAction,
                label: googleAnalytics.page.action.viewRoute,
            };
            googleAnalytics.logEvent(eventOptions);
        }
        this.setState({
            loadshow: "show-m",
            overly:"show-m"
        });
        var fdate = moment
            .parseZone(params.reporting_in_date)
            .format("YYYY-MM-DD HH:mm:ss");
        if (
            params.reporting_out_date == undefined ||
            params.reporting_out_date == ""
        ) {
            var edate = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
        } else {
            edate = moment
                .parseZone(params.reporting_out_date)
                .format("YYYY-MM-DD HH:mm:ss");
        }
        var reqData = {
            truck_no: params.truck_no,
            gate_out_time: fdate,
            seconddate: edate,
            screen: "geofence",
        };
        //console.log("reqData: ", reqData);
        redirectURL
            .post("/consignments/maproutes", reqData, {
                headers: {
                    "content-type": "application/json",
                },
            })
            .then((response) => {
                var records = response.data;
                this.setState({
                    sliderRouteTranslate: "slider-translate-65p",
                    overly: "show-m",
                    loadshow: "show-n",
                    maptruckno: params.truck_no,
                    mapinfo: records,
                    sidebarSubHeader: "Truck Information",
                    routeTruck: {
                        truck_no: params.truck_no,
                        startTime: response.data.route_details.start_time,
                        endTime: response.data.route_details.end_time,
                    },
                });
                // console.log("end Time", typeof(response.data.route_details.end_time), response.data.route_details.end_time)
            })
            .catch(function (e) {
                console.log("Error ", e);
            });
    };
    onCloseModal = () => {
        this.setState({ withdrawmodal: false });
    };

    onChangeParkingItem(geofence_name) {
        this.setState({ geofence_name }, () =>
            console.log(`Name  Option selected:`, this.state.geofence_name)
        );

        this.setState({
            errorMessage: "",
            successMessage: "",
        });
    }

    onChangeTruckItem(truck_no) {
        this.setState({ truck_no }, () =>
            console.log(`Name  Option selected:`, this.state.truck_no)
        );

        this.setState({
            errorMessage: "",
            successMessage: "",
        });
    }
    onChangeFenceItem(fence_name) {
        this.setState({ fence_name }, () =>
            console.log(`Name  Option selected:`, this.state.fence_name)
        );

        this.setState({
            errorMessage: "",
            successMessage: "",
        });
    }

    onChangePlantItem(plant_code) {
        this.setState({ plant_code }, () =>
            console.log(`Name  Option selected:`, this.state.plant_code)
        );

        this.setState({
            errorMessage: "",
            successMessage: "",
        });
    }

    onChangeTransporterItem(transporter_code) {
        this.setState({ transporter_code }, () => {
            console.log(`Name  Option selected:`, this.state.transporter_code);
            var parm = {
                transporter_code: this.state.transporter_code.value,
            };
            redirectURL
                .post("/dashboard/transportertrucklist", parm)
                .then((response) => {
                    var records = response.data.records;
                    var strucks = [];
                    if (records.length > 0) {
                        records.map((item) => {
                            console.log("item ", item);
                            if (item.is_active == 1 && item.carrier_reported == 1) {
                            } else {
                                strucks.push({
                                    value: item.truck_no,
                                    label: item.truck_no,
                                });
                            }
                        });
                    }
                    this.setState({
                        struckslist: strucks,
                    });
                });
            // var records = this.state.rowData;
            // var trans=[]
            // var tranp = [this.state.transporter_code];
            // if(records.length > 0)
            // {
            //     // records.map((item) => {
            //     //   console.log("item.trucks[0].transporter_code ", item.trucks[0].transporter_code)
            //     //   if(item.trucks.length > 0 && item.trucks[0].transporter_code != undefined
            //     //      && item.trucks[0].transporter_code != "" && item.trucks[0].transporter_code != this.state.transporter_code.value)
            //     //      {
            //     //        trans.push({
            //     //          value:item.truck_no,
            //     //          label:item.truck_no,
            //     //        })
            //     //      }
            //     // })
            //     trans = records.filter(f =>
            // 			tranp.some(d => d.value == f.trucks[0].transporter_code)
            // 		);
            // }
            // console.log("Trans ", trans);
            // var strucks = []
            // if(trans.length > 0)
            // {
            //   trans.map((item) => {
            //     strucks.push({
            //       value:item.truck_no,
            //       label:item.truck_no
            //     })
            //   })
            // }
            // this.setState({
            //   struckslist:strucks
            // })
        });

        this.setState({
            errorMessage: "",
            successMessage: "",
        });
    }

    onChangeManualTransporterItem(manual_transporter) {
        this.setState({ manual_transporter }, () => {
            console.log(`Name  Option selected:`, this.state.manual_transporter);
            var parm = {
                transporter_code: this.state.manual_transporter.value,
            };
            redirectURL
                .post("/dashboard/transportertrucklist", parm)
                .then((response) => {
                    var records = response.data.records;
                    var strucks = [];
                    if (records.length > 0) {
                        records.map((item) => {
                            strucks.push({
                                value: item.truck_no,
                                label: item.truck_no,
                            });
                        });
                    }
                    this.setState({
                        struckslist: strucks,
                    });
                });
        });

        this.setState({
            errorMessage: "",
            successMessage: "",
        });
    }

    onChangeSearchTruckItem(struck_no) {
        this.setState({ struck_no }, () =>
            console.log(`Name  Option selected:`, this.state.struck_no)
        );

        this.setState({
            errorMessage: "",
            successMessage: "",
        });
    }

    onChangeManualTruckTypeItem(manual_trucktype) {
        this.setState({ manual_trucktype }, () =>
            console.log(`Name  Option selected:`, this.state.manual_trucktype)
        );

        this.setState({
            errorMessage: "",
            successMessage: "",
        });
    }

    onClickTab(params) {
        if (params == "parking") {
            this.setState({
                pact: "btn-warning",
                tact: "btn-defaultc",
                parkingyard: "show-m",
                truckinfo: "show-n",
            });
        }
        if (params == "truck") {
            this.setState({
                pact: "btn-defaultc",
                tact: "btn-warning",
                parkingyard: "show-n",
                truckinfo: "show-m",
            });
        }
    }

    onClickReportCarrierAction(params) {
        //  console.log("Report carrier",params);

        var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
        var encryptjson = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

        var cnf = window.confirm("Are you sure you want to perform this action?");
        if (cnf == true) {
            var jsondata = encryptjson(JSON.stringify(params));
            var parameters = {
                rownode: jsondata,
            };
            redirectURL
                .post("/dashboard/setreportcarrier", parameters)
                .then((response) => {
                    // console.log("response", response.data)
                    if (response.data.status == "success") {
                        this.setState({
                            show: true,
                            basicType: "success",
                            basicTitle: "Successfully reported carrier",
                        });
                        var reqparams = {
                            dept_code: encode(this.state.dept_code),
                            startDate: this.state.startDate,
                            endDate: this.state.endDate,
                        };
                        this.onLoadData(reqparams);
                    } else {
                        this.setState({
                            show: true,
                            basicType: "danger",
                            basicTitle: "Failed to report carrier",
                        });
                    }
                });
        }
    }
    // onClickWithdrawCarrierAction(params) {
    //     //  console.log("Withdraw carrier",params);
    //     var cnf = window.confirm("Are you sure you want to perform this action?");
    //     if (cnf == true) {
    //         this.setState({
    //             withdrawdata: params,
    //             withdrawmodal: true,
    //         });
    //         loadDateTimeScript();
    //     } else {
    //     }
    // }
    onClickParkingoutCarrierAction(params) {
        var cnf = window.confirm("Are you sure you want to perform this action?");
        if (cnf == true) {
            this.setState({
                parkingdata: params,
                slideerParkingOut: "slider-translate",
                overly: "show-m",
            });
        } else {
        }
    }

    formParkingoutCarrier(event) {
        event.preventDefault();
        //  console.log("Withdraw carrier",params);
        var cnf = window.confirm("Are you sure you want to perform this action?");
        if (cnf == true) {
            this.setState({
                overly: "show-m",
            });
            // console.log(params)
            var rownode = this.state.parkingdata;
            var encryptjson = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
            var jsondata = encryptjson(JSON.stringify(rownode));
            var manual_parking_out = $("#manual_parking_out").val();
            var parameters = {
                rownode: jsondata,
                parking_out: manual_parking_out,
            };
            redirectURL
                .post("/dashboard/setparkingoutcarrier", parameters)
                .then((response) => {
                    //console.log("response", response.data)
                    if (response.data.status == "success") {
                        this.setState({
                            show: true,
                            basicType: "success",
                            basicTitle: "Successfully update item",
                            slideerParkingOut: "",
                            overly: "show-n",
                        });
                        var encode = this.cipher(
                            "V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x"
                        );
                        var reqparams = {
                            dept_code: encode(this.state.dept_code),
                            startDate: this.state.startDate,
                            endDate: this.state.endDate,
                        };
                        this.onLoadData(reqparams);
                    } else {
                        this.setState({
                            show: true,
                            basicType: "danger",
                            basicTitle: "Failed to update item",
                        });
                    }
                });
        } else {
        }
    }
    onSubmitWithdrawAction(event) {
        event.preventDefault();
        var rownode = this.state.withdrawdata;
        var encryptjson = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
        var jsondata = encryptjson(JSON.stringify(rownode));
        // var manual_parking_out = $("#manual_parking_out").val();
        //var manual_parking_out = this.state.manual_parking_out;
        var parameters = {
            rownode: jsondata,
            withdraw_reason: this.state.withdraw_reason,
            //	parking_out:manual_parking_out
        };
        redirectURL
            .post("/dashboard/setwithdrawcarrier", parameters)
            .then((response) => {
                //console.log("response", response.data)
                if (response.data.status == "success") {
                    this.setState({
                        show: true,
                        basicType: "success",
                        basicTitle: "Successfully withdraw carrier",
                        withdrawdata: "",
                        withdrawmodal: false,
                        withdraw_reason: "",
                    });
                    var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
                    var reqparams = {
                        dept_code: encode(this.state.dept_code),
                        startDate: this.state.startDate,
                        endDate: this.state.endDate,
                    };
                    this.onLoadData(reqparams);
                } else {
                    this.setState({
                        show: true,
                        basicType: "danger",
                        basicTitle: "Failed to withdraw carrier",
                    });
                }
            });
    }
    formSearchHandler(event) {
        event.preventDefault();
        this.onSubmitFormData();
    }
    onSubmitFormData() {
        if (
            this.state.struck_no.value != "" &&
            this.state.struck_no.value != undefined
        ) {
            this.setState({
                rowData1: [],
                coordinates: [],
                defaultradius: "",
                mwidth: "col-xl-5 col-lg-5",
                gpsradius: 15,
                loadshow: "show-m",
                showform: "show-m",
                showrefresh: "show-n",
                rlatitude: "",
                rlongitude: "",
                tabplants: [],
                nsearch: "show-m",
                gpsplant: "",
            });
            var sdate = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
            var edate = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");

            var truck_no = [this.state.struck_no];
            var transportercode = [this.state.transporter_code.value];
            var parameters = {
                truck_no: this.state.struck_no.value,
            };
            redirectURL
                .post("/dashboard/truckreportdata", parameters)
                .then((response) => {
                    var records = response.data.records;
                    console.log("records ", records);
                    if (records.length == 0) {
                        this.setState({
                            reporttruck: 1,
                        });
                    } else {
                        this.setState({
                            reporttruck: 0,
                        });
                    }
                });
            // redirectURL.post("/gmap",parameters)
            // .then((response) => {
            //   console.log("response ", response.data)
            // })
            var formdata = {
                radius: 3000,
                latitude: "20.73568784876339",
                longitude: "79.38999444931353",
                dept_code: this.state.dept_code,
                transporter_code: JSON.stringify(transportercode),
                // status:this.state.truckstatus,
                trip_type: 1,
            };
            var rlatitude = "20.73568784876339";
            var rlongitude = "79.38999444931353";
            //http://autometrics.in:5000/getNearByTrucks
            redirectURL
                .post("/vicinity/getRadiusCoordinates", formdata, {
                    headers: {
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, OPTIONS",
                        "Access-Control-Allow-Headers":
                            "Origin, X-Requested-With, Content-Type, Accept, Authorization",
                    },
                })
                .then(async (response) => {
                    // console.log("response ",response.data.apiData.body);
                    var mrkers = JSON.parse(
                        JSON.parse(response.data.apiData.body).trucks_data
                    );
                    // console.log("mrkers ", mrkers)
                    if (mrkers.length > 0) {
                        // console.log("truck_no ",truck_no)
                        //console.log(transporterTrucks,"transporterTrucks")
                        var markers = [];

                        markers = mrkers.filter((f) =>
                            truck_no.filter((d) => {
                                // console.log(d.value)
                                // console.log("ffff",f.truck_no)
                                if (d.value == f.truck_no) {
                                    return f;
                                }
                            })
                        );
                    }
                    // console.log("markers ", markers)
                    var markersarr = [];
                    if (markers.length > 0) {
                        markers.map((item) => {
                            if (item.truck_no == truck_no[0].value) {
                                markersarr.push(item);
                            }
                        });
                    }
                    //console.log(markersarr,"markersfilter")
                    var mapdata = [];
                    var rowrecords = this.state.rowData;
                    if (markersarr.length > 0) {
                        mapdata = await markersarr.filter((f) =>
                            rowrecords.filter((d) => {
                                if (d.truck_no == f.truck_no) {
                                    f.fence_name = d.fence_name;
                                    f.plant_code = d.plant_code;
                                    f.geofence_center = d.geofence_center;
                                    f.reportid = d._id;
                                    f.reporting_in_date = d.reporting_in_date;
                                    f.location_type = d.location_type;
                                    f.is_active = d.is_active;
                                    f.arrival_marked_on = d.arrival_marked_on;
                                    f.carrier_reported = d.carrier_reported;
                                    f.reported_marked_on = d.reported_marked_on;
                                    f.withdraw_carrier = d.withdraw_carrier;
                                    f.withdraw_on = d.withdraw_on;
                                    f.withdraw_reason = d.withdraw_reason;
                                    f.address = d.truckloc[0].address;
                                    f.area = d.truckloc[0].area;
                                    f.city = d.truckloc[0].city;
                                    f.state = d.truckloc[0].state;
                                    f.gps_provider = d.truckloc[0].actual_lspuser;
                                    return f;
                                }
                            })
                        );
                    }
                    try {
                        if (mapdata[0].truck_travelling_status != undefined) {
                            if (mapdata[0].truck_travelling_status == 0) {
                                var travelstat = "Active";
                            } else {
                                travelstat = "Active";
                            }
                        } else {
                            travelstat = "Inctive";
                        }
                    } catch (e) { }
                    // console.log("mapdata ", mapdata
                    if (mapdata.length > 0) {
                        if (mapdata[0].truck_no != undefined) {
                            var mtruck = mapdata[0].truck_no;
                        } else {
                            mtruck = this.state.struck_no.value;
                        }
                        if (mapdata[0].transporter_name != undefined) {
                            var mtrans = mapdata[0].transporter_name;
                        } else {
                            mtrans = this.state.transporter_code.value;
                        }
                        if (mapdata[0].gps_provider != undefined) {
                            var mgpsprovider = mapdata[0].gps_provider;
                        } else {
                            mgpsprovider = "";
                        }
                        if (mapdata[0].speed != undefined) {
                            var mspeed = mapdata[0].speed;
                        } else {
                            mspeed = 0;
                        }
                        if (mapdata[0].timestamp != undefined) {
                            var mtime = getHyphenDDMMMYYYYHHMM(mapdata[0].timestamp);
                        } else {
                            mtime = "";
                        }

                        var setparams = {
                            nsearch: "show-m",
                            defaultradius: formdata.radius,
                            coordinates: mapdata,
                            totalTrucksCount: mapdata.length,
                            mwidth: "col-xl-5 col-lg-5",
                            // rowData:markersarr,
                            radius: 5000,
                            loadshow: "show-n",
                            showform: "show-n",
                            showrefresh: "show-m",
                            rlatitude: "21.144644112601775",
                            rlongitude: "79.08860126768066",
                            alltrucks: mapdata,

                            search_truck_no: mtruck,
                            search_transporter_name: mtrans,
                            search_gps_provider: mgpsprovider,
                            search_speed: mspeed,
                            search_last_packet_time: mtime,
                            search_gps_status: travelstat,
                            search_address: mapdata[0].address,
                            search_city: mapdata[0].city,
                            search_state: mapdata[0].state,
                            search_carrier_reported: mapdata[0].carrier_reported,
                        };
                    } else {
                        mtruck = this.state.struck_no.value;
                        mtrans = this.state.transporter_code.value;
                        mgpsprovider = "";
                        mspeed = 0;
                        mtime = "";
                        setparams = {
                            nsearch: "show-m",
                            defaultradius: formdata.radius,
                            coordinates: mapdata,
                            totalTrucksCount: mapdata.length,
                            mwidth: "col-xl-5 col-lg-5",
                            // rowData:markersarr,
                            radius: 5000,
                            loadshow: "show-n",
                            showform: "show-n",
                            showrefresh: "show-m",
                            rlatitude: "21.144644112601775",
                            rlongitude: "79.08860126768066",
                            alltrucks: mapdata,

                            search_truck_no: mtruck,
                            search_transporter_name: mtrans,
                            search_gps_provider: mgpsprovider,
                            search_speed: mspeed,
                            search_last_packet_time: mtime,
                            search_gps_status: travelstat,
                            search_address: "",
                            search_city: "",
                            search_state: "",
                            search_carrier_reported: "",
                        };
                    }
                    await this.setState(setparams);
                    await this.renderMap();
                });
        } else {
            this.setState({
                show1: true,
                basicType1: "danger",
                basicTitle1: "Please select truck no",
            });
        }
    }

    formCarrierHandler(event) {
        event.preventDefault();
        var encryptjson = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

        if (this.state.plant_code.value != "") {
            var plant = this.state.plant_code.value;
            var truck_no = this.state.search_truck_no;
            var parameters = {
                dept_code: encryptjson(this.state.dept_code),
                plant_code: plant,
                truck_no: truck_no,
            };
            redirectURL
                .post("/dashboard/createcarrier", parameters)
                .then((response) => {
                    if (response.data.status == "success") {
                        this.setState({
                            show: true,
                            basicType: "success",
                            basicTitle: "Successfully added carrier item",
                        });
                        this.onSubmitFormData();
                    } else {
                        this.setState({
                            show1: true,
                            basicType1: "danger",
                            basicTitle1: "Failed to add carrier",
                        });
                    }
                });
        } else {
            this.setState({
                show1: true,
                basicType1: "danger",
                basicTitle1: "Please select plant",
            });
        }
    }

    onCLickReportBtn() {
        //  console.log("Report carrier",params);
        redirectURL
            .post("/dashboard/truckreportdata", {
                truck_no: this.state.struck_no.value,
            })
            .then((response) => {
                var record = response.data.records;
                var encryptjson = this.cipher(
                    "V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x"
                );
                var jsondata = encryptjson(JSON.stringify(record[0]));
                var parameters = {
                    rownode: jsondata,
                };
                redirectURL
                    .post("/dashboard/setreportcarrier", parameters)
                    .then((response) => {
                        // console.log("response", response.data)
                        if (response.data.status == "success") {
                            this.setState({
                                show: true,
                                basicType: "success",
                                basicTitle: "Successfully reported carrier",
                            });
                        } else {
                            this.setState({
                                show: true,
                                basicType: "danger",
                                basicTitle: "Failed to report carrier",
                            });
                        }
                    });
            });
    }
    onCLickWithBtn() {
        //  console.log("Withdraw carrier",params);
        redirectURL
            .post("/dashboard/truckreportdata", {
                truck_no: this.state.struck_no.value,
            })
            .then((response) => {
                var record = response.data.records;
                var encryptjson = this.cipher(
                    "V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x"
                );
                var jsondata = encryptjson(JSON.stringify(record[0]));
                var parameters = {
                    rownode: jsondata,
                };
                redirectURL
                    .post("/dashboard/setwithdrawcarrier", parameters)
                    .then((response) => {
                        //console.log("response", response.data)
                        if (response.data.status == "success") {
                            this.setState({
                                show: true,
                                basicType: "success",
                                basicTitle: "Successfully withdraw carrier",
                            });
                        } else {
                            this.setState({
                                show: true,
                                basicType: "danger",
                                basicTitle: "Failed to withdraw carrier",
                            });
                        }
                    });
            });
    }

    renderMap = () => {
        loadScript(
            "https://maps.googleapis.com/maps/api/js?key=AIzaSyA08YKZGPl0ii3K-t4_guTbdtjscGgdPns&libraries=places,drawing&callback=initMap"
        );
        window.initMap = this.initMap;
    };

    initMap = () => {
        allcords = [];
        this.state.coordinates.map((marker) => {
            allcords.push(marker);
        });
        //	console.log("allcords ", this.state.coordinates)
        var contentdata = this.state.contentString;

        var lt = 21.144644112601775;
        var ln = 79.08860126768066;

        try {
            map = new window.google.maps.Map(document.getElementById("search_map"), {
                zoom: 11,
                center: new window.google.maps.LatLng(lt, ln),
                mapTypeId: window.google.maps.MapTypeId.ROADMAP,
                /*mapTypeControl: true,
                        gestureHandling: 'greedy',
                        mapTypeControlOptions: {
                            style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                            position: window.google.maps.ControlPosition.TOP_RIGHT
                          },
                        disableDefaultUI: true,
                        zoomControl: true*/
            });
        } catch (e) {
            // map = new window.google.maps.Map(document.getElementById('search_map'), {
        }

        circle = new window.google.maps.Circle({
            map: map,
        });

        marker = new window.google.maps.Marker({
            map: map,
            draggable: true,
            animation: window.google.maps.Animation.DROP,
            //position: {lat: 28.48, lng: 77.06}
        });

        marker.addListener("click", function () {
            marker.setAnimation(window.google.maps.Animation.BOUNCE);
        });

        window.google.maps.event.addListener(map, "click", function (event) {
            //document.getElementById("latitude").value = event.latLng.lat();
            //document.getElementById("longitude").value = event.latLng.lng();
            marker.setPosition(event.latLng);
        });
        circle.bindTo("center", marker, "position");
        var markerLatlng1;
        //console.log("Radius ", this.state.radius)
        // console.log(allcords);
        var bounds = new window.google.maps.LatLngBounds();
        var markers = allcords.map((marker) => {
            var image = require("../../assets/icons/truck_na_24.png");
            var contentarr = [];
            var headerTitle = marker.truck_no;
            contentarr.push({ key: "Truck No", value: marker.truck_no });
            contentarr.push({
                key: "Transporter Name",
                value: marker.transporter_name,
            });
            //contentarr.push({"key":"Distance from geofence", "value":marker.distance});
            //contentarr.push({"key":"GPS Provider", "value":marker.actual_lspuser});
            contentarr.push({ key: "GPS Provider", value: marker.gps_provider });
            contentarr.push({ key: "Speed (km/h)", value: Math.ceil(marker.speed) });
            contentarr.push({
                key: "Last Packet Time",
                value: getHyphenDDMMMYYYYHHMM(marker.timestamp),
            });
            // truck_travelling_status
            if (marker.truck_travelling_status == 0) {
                var travelstat = "Active";
            } else {
                travelstat = "Active";
            }
            contentarr.push({ key: "GPS Status", value: travelstat });
            contentarr.push({ key: "Address", value: marker.device_address });
            contentarr.push({ key: "City", value: marker.device_city });
            contentarr.push({ key: "State", value: marker.device_state });
            var contentString = infoBox(image, headerTitle, contentarr, "");

            var infowindow = new window.google.maps.InfoWindow({
                content: contentString,
            });
            //console.log("parseFloat(marker.latitude) ", parseFloat(marker.latitude))
            var markerLatlng = new window.google.maps.LatLng(
                parseFloat(marker.latitude),
                parseFloat(marker.longitude)
            );

            image = require("../../assets/icons/truck_na_24.png");

            var mark = new window.google.maps.Marker({
                position: markerLatlng,
                map: map,
                title: "Truck No: " + marker.truck_no,
                icon: image,
            });
            mark.addListener("click", function () {
                infowindow.open(map, mark);
            });
            if (this.state.rowData1.length == 0) {
                infowindow.open(map, mark);
            }
            //
            //return mark
            // mark.setMap(map);

            bounds.extend(
                new window.google.maps.LatLng(
                    parseFloat(marker.latitude),
                    parseFloat(marker.longitude)
                )
            );
        });

        if (this.state.selectedTabLat != "" && this.state.selectedTabLng != "") {
            //console.log(" this.state.selectedTabLat ",parseFloat(this.state.selectedTabLat.toFixed(6)))
            //console.log(" this.state.selectedTabLng ",parseFloat(this.state.selectedTabLng.toFixed(6)))
            markerLatlng1 = new window.google.maps.LatLng(
                this.state.selectedTabLat,
                this.state.selectedTabLng
            );
            //console.log("markerLatlng", markerLatlng1)
            circle = new window.google.maps.Circle({
                strokeColor: "#71caab",
                strokeOpacity: 0.7,
                strokeWeight: 1,
                fillColor: "#71caab",
                fillOpacity: 0.25,
                map: map,
                center: markerLatlng1,
                radius: Math.sqrt(this.state.radius) * 8000,
            });
            bounds.extend(
                new window.google.maps.LatLng(
                    parseFloat(this.state.selectedTabLat),
                    parseFloat(this.state.selectedTabLng)
                )
            );
            var mainmark = new window.google.maps.Marker({
                position: new window.google.maps.LatLng(
                    parseFloat(this.state.selectedTabLat),
                    parseFloat(this.state.selectedTabLng)
                ),
                map: map,
                //title:item.plant_code
            });

            mainmark.setMap(map);
            map.setZoom(8);
            console.log("mainmark.getPosition() ", mainmark.getPosition());
            //	map.setCenter(mainmark.getPosition());
            //map.setZoom(10)
            //map.fitBounds(bounds)
        } else {
            // console.log("Here")
            if (this.state.tabplants.length > 0) {
                this.state.tabplants.forEach((item) => {
                    // console.log("Item Marker ", item)
                    markerLatlng1 = new window.google.maps.LatLng(item.lat, item.lng);
                    //console.log("markerLatlng", markerLatlng1)
                    circle = new window.google.maps.Circle({
                        strokeColor: "#71caab",
                        strokeOpacity: 0.7,
                        strokeWeight: 1,
                        fillColor: "#71caab",
                        fillOpacity: 0.25,
                        map: map,
                        center: markerLatlng1,
                        radius: Math.sqrt(this.state.gpsradius) * 8000,
                    });
                    bounds.extend(
                        new window.google.maps.LatLng(
                            parseFloat(item.lat),
                            parseFloat(item.lng)
                        )
                    );
                    var mainmark = new window.google.maps.Marker({
                        position: new window.google.maps.LatLng(
                            parseFloat(item.lat),
                            parseFloat(item.lng)
                        ),
                        map: map,
                        title: item.plant_code,
                    });
                    mainmark.addListener(
                        "click",
                        (function () {
                            var contentarr = [];
                            //var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
                            // var header = "Plant"
                            // contentarr.push({"key":"Code", "value":item.plant_code})
                            //
                            // var contentString = infoBox("", header, contentarr,'')
                            //
                            // infowindow.setContent(contentString);
                            // currentwindow = infowindow;
                            // infowindow.open(map, marker);
                        })(marker)
                    );
                    mainmark.setMap(map);
                });
            }
        }

        map.fitBounds(bounds);
        var eventtrigger = document.getElementsByClassName("truck");
        var markerCluster = new MarkerClusterer(map, markers, {
            imagePath:
                "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
        });
    };
    toggleBounce() {
        if (marker.getAnimation() !== null) {
            marker.setAnimation(null);
        } else {
            marker.setAnimation(window.google.maps.Animation.BOUNCE);
        }
    }

    getDistance = (p1, p2) => {
        //console.log("p1",p1);
        //console.log("p2",p2);
        var R = 6378137; // Earth’s mean radius in meter
        var dLat = rad(p2.lat - p1.lat);
        var dLong = rad(p2.lng - p1.lng);
        var a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(rad(p1.lat)) *
            Math.cos(rad(p2.lat)) *
            Math.sin(dLong / 2) *
            Math.sin(dLong / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c;
        return d / 1000; // returns the distance in meter
    };
    onShowInfo = (e) => {
        //	console.log(JSON.parse(e.target.id));
        this.setState({
            contentString: "Here Success",
        });
        var ourMarker = e.target.id;
        window.google.maps.event.trigger(ourMarker, "click");
        var infowindow = new window.google.maps.InfoWindow({
            content: "Here Success",
        });
        //	infowindow.open(map, marker);
    };
    popmarker(e) {
        var truckNo = e.data.truck_no;
        var truck = [];
        var trucks = this.state.alltrucks;
        console.log(trucks, "input truck");
        var filteredValue = trucks.filter(function (e) {
            return e.truck_no == truckNo;
        });
        if (filteredValue.length > 0) {
            // console.log("Matched")
            var lat = parseFloat(filteredValue[0].latitude);
            var lng = parseFloat(filteredValue[0].longitude);
            var data = filteredValue[0];
            map.setCenter(new window.google.maps.LatLng(lat, lng));
            map.setZoom(22);
            var markerLatlng = new window.google.maps.LatLng(lat, lng);

            if (filteredValue[0].status == 1) {
                var truckText = "Inside Plant";
                var color = "#333333";
            } else if (filteredValue[0].status == 2) {
                truckText = "Going to Dealer";
                color = "#333333";
            } else if (filteredValue[0].status == 3 || filteredValue[0].status == 4) {
                truckText = "At Dealer Location";
                color = "#333333";
            } else if (filteredValue[0].status == 5) {
                truckText = "Left Destination Location";
                color = "#333333";
            } else if (filteredValue[0].status == 6) {
                truckText = "Return to Plant";
                color = "#333333";
            } else if (filteredValue[0].status == 7) {
                truckText = "Empty Truck Outside Plant";
                color = "#333333";
            } else {
                truckText = "N/A";
                color = "#333333";
            }

            if (filteredValue[0].status == 1) {
                var image = require("../../assets/icons/truck_inside_24.png");
            } else if (filteredValue[0].status == 2) {
                image = require("../../assets/icons/truck_going_24.png");
            } else if (filteredValue[0].status == 3 || filteredValue[0].status == 4) {
                image = require("../../assets/icons/truck_atdealer_24.png");
            } else if (filteredValue[0].status == 5) {
                image = require("../../assets/icons/truck_leftdealer_24.png");
            } else if (filteredValue[0].status == 6) {
                image = require("../../assets/icons/truck_return_24.png");
            } else if (filteredValue[0].status == 7) {
                image = require("../../assets/icons/truck_empty_24.png");
            } else {
                image = require("../../assets/icons/truck_na_24.png");
            }
            image = require("../../assets/icons/truck_na_24.png");
            var marker = new window.google.maps.Marker({
                position: markerLatlng,
                map: map,
                title: data.truck_no,
                icon: image,
            });

            var contentarr = [];
            var headerTitle = filteredValue[0].truck_no;
            contentarr.push({ key: "Truck No", value: filteredValue[0].truck_no });
            contentarr.push({
                key: "Transporter Name",
                value: filteredValue[0].transporter_name,
            });
            //contentarr.push({"key":"Distance from geofence", "value":trucks[i].distance});
            //contentarr.push({"key":"GPS Provider", "value":filteredValue[0].actual_lspuser});
            contentarr.push({
                key: "GPS Provider",
                value: filteredValue[0].gps_provider,
            });
            contentarr.push({ key: "Speed (KMPH)", value: filteredValue[0].speed });
            contentarr.push({
                key: "Last Packet Time",
                value: getHyphenDDMMMYYYYHHMM(filteredValue[0].timestamp),
            });
            // contentarr.push({"key":"Address", "value":filteredValue[0].area+", "+filteredValue[0].state});
            if (filteredValue[0].truck_travelling_status == 1) {
                var travelstat = "Active";
            } else {
                travelstat = "Active";
            }
            contentarr.push({ key: "GPS Status", value: travelstat });
            contentarr.push({
                key: "Address",
                value: filteredValue[0].device_address,
            });
            contentarr.push({ key: "City", value: filteredValue[0].device_city });
            contentarr.push({ key: "State", value: filteredValue[0].device_state });

            var contentString = infoBox(image, headerTitle, contentarr, "");
            //console.log(contentarr,"contentarr")
            var infowindow = new window.google.maps.InfoWindow({
                content: contentString,
            });

            marker.setAnimation(window.google.maps.Animation.DROP);
            marker.addListener("click", function () {
                infowindow.open(map, marker);
            });

            marker.setMap(map);
        } else {
            this.setState({
                show: true,
                basicType: "warning",
                basicTitle: "No Data found.",
                loadshow: "show-n",
                showform: "show-m",
            });
        }
        console.log(filteredValue);
    }

    truckFormHandler() {
        //e.preventDefault();

        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pageTitle;
            let eventOptions = {
                category: pageTitle,
                action: this.state.eventFormAction,
                label: googleAnalytics.page.action.formSubmittedSearchByTruckNo,
            };
            googleAnalytics.logEvent(eventOptions);
        }
        var truck = [];
        var trucks = this.state.alltrucks;
        var struck = this.state.truckno;
        var filteredValue = trucks.filter(function (e) {
            return e.truck_no == struck;
        });
        // console.log(filteredValue,"filteredValue")
        if (filteredValue.length > 0) {
            // console.log("Matched")
            var lat = parseFloat(filteredValue[0].latitude);
            var lng = parseFloat(filteredValue[0].longitude);
            var data = filteredValue[0];
            // map.setCenter(new window.google.maps.LatLng(lat,lng));
            // map.setZoom(22);
            var markerLatlng = new window.google.maps.LatLng(lat, lng);

            var image = require("../../assets/icons/truck_na_24.png");
            var marker = new window.google.maps.Marker({
                position: markerLatlng,
                map: map,
                title: data.truck_no,
                icon: image,
            });

            var contentarr = [];
            var headerTitle = filteredValue[0].truck_no;
            // contentarr.push({"key":"Truck No", "value":filteredValue[0].truck_no});
            contentarr.push({
                key: "Transporter Name",
                value: filteredValue[0].transporter_name,
            });
            //contentarr.push({"key":"Distance from geofence", "value":trucks[i].distance});
            //contentarr.push({"key":"GPS Provider", "value":filteredValue[0].actual_lspuser});
            contentarr.push({ key: "GPS Provider", value: "Enmovil" });
            contentarr.push({ key: "Speed (KMPH)", value: filteredValue[0].speed });
            contentarr.push({
                key: "Last Packet Time",
                value: getHyphenDDMMMYYYYHHMM(filteredValue[0].timestamp),
            });
            // contentarr.push({"key":"Address", "value":filteredValue[0].area+", "+filteredValue[0].state});
            contentarr.push({
                key: "Address",
                value: filteredValue[0].device_address,
            });
            contentarr.push({ key: "City", value: filteredValue[0].device_city });
            contentarr.push({ key: "State", value: filteredValue[0].device_state });

            var contentString = infoBox(image, headerTitle, contentarr, "");

            var infowindow = new window.google.maps.InfoWindow({
                content: contentString,
            });

            marker.setAnimation(window.google.maps.Animation.DROP);
            marker.addListener("click", function () {
                infowindow.open(map, marker);
            });

            marker.setMap(map);
        } else {
            this.setState({
                show: true,
                basicType: "warning",
                basicTitle: "No Data found.",
                loadshow: "show-n",
                showform: "show-m",
            });
        }
    }
    changeHandler = (event) => {
        //console.log("Select", event.target.value);
        let name = event.target.name;
        let value = event.target.value;
        this.setState({ [name]: value });
    };
    onChangePlantCode = async (gpsplant) => {
        //console.log("Select", event.target.value);
        await this.setState({ gpsplant }, () =>
            console.log(`Option selected:`, this.state.gpsplant)
        );
    };

    formGeofenceHandler(event) {
        event.preventDefault();
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pageTitle;
            let eventOptions = {
                category: pageTitle,
                action: this.state.eventFormAction,
                label: googleAnalytics.page.action.formSubmitted,
            };
            googleAnalytics.logEvent(eventOptions);
        }

        this.setState({
            loadshow: "show-m",
        });
        var plantcodes = [];
        if (this.state.gpsplant.length > 0) {
            try {
                this.state.gpsplant.forEach((item) => {
                    plantcodes.push(item.value);
                });
            } catch (e) { }
        }

        // if(this.state.gpsplant != "")
        // {
        //   plantcodes.push(this.state.gpsplant.value);
        // }
        var formdata = {
            plant_codes: plantcodes,
            radius: this.state.gpsradius,
            request_type: "1",
            dept_code: this.state.dept_code.toUpperCase(),
            gps_records_flag: 0,
        };

        if (plantcodes.length > 0) {
            this.submitFormHandler(formdata);
        } else {
            this.setState({
                show: true,
                basicType: "danger",
                basicTitle: "Please select Plant",
                loadshow: "show-n",
                overly: "show-n",
            });
        }
    }

    submitFormHandler(formdata) {
        this.setState({
            loadshow: "show-m",
        });
        var urlpath = "/vicinity/plantRadiusCoordinates";
        redirectURL
            .post(urlpath, formdata)
            .then(async (response) => {
                // console.log("response", response);

                var tabplants = [];
                // console.log("response.data.cluster_centriods ", response.data.cluster_centriods);
                if (response.data.cluster_centriods.length > 0) {
                    // console.log("consigners ", this.state.gpsplant)
                    response.data.cluster_centriods.forEach((item) => {
                        item.label = this.state.gpsplant[0].value;
                        tabplants.push(item);
                    });
                }
                var atrucks = JSON.parse(response.data.trucks_data);
                var trucks_data = [];
                if (atrucks.length > 0) {
                    atrucks.map((item) => {
                        var d1 = moment.parseZone(new Date()).format("YYYY-MM-DD HH:mm:ss");
                        var d2 = moment
                            .parseZone(item.timestamp)
                            .format("YYYY-MM-DD HH:mm:ss");
                        var ms = moment(d1, "YYYY-MM-DD HH:mm:ss").diff(
                            moment(d2, "YYYY-MM-DD HH:mm:ss")
                        );
                        var d = moment.duration(ms);
                        var s = Math.floor(d.asHours());
                        // console.log("d1 ", d1)
                        // console.log("d2 ", d2)
                        // console.log("Hrs ", s)
                        item.travelstatus = s;
                        if (s >= 24) {
                            item.gps_status = "Inactive";
                        } else {
                            item.gps_status = "Active";
                        }
                        trucks_data.push(item);
                    });
                }
                //allcords=[];
                var allmarkers = response.data.trucks_data;
                // console.log("allmarkers ", trucks_data);
                var dCode = this.state.dept_code;
                if (trucks_data.length > 0) {
                    //console.log("UPpcase Dept ", dCode);
                    var markers = [];
                    var mainlat = "";
                    var mainlng = "";
                    var maincoords = [];
                    var mlat = "";
                    var mlng = "";
                    // console.log("tabplants ", tabplants)
                    this.setState({
                        defaultradius: formdata.radius,
                        coordinates: trucks_data,
                        alltrucks: trucks_data,
                        mwidth: "col-xl-7 col-lg-7",
                        rowData1: trucks_data,
                        originalrowData: trucks_data,
                        gpsradius: formdata.radius,
                        loadshow: "show-n",
                        showform: "show-n",
                        showrefresh: "show-m",
                        rlatitude: mlat,
                        rlongitude: mlng,
                        tabplants: tabplants,
                        nsearch: "show-n",
                    });

                    var coords = markers;
                    var p1 = {
                        lat: parseFloat(formdata.latitude),
                        lng: parseFloat(formdata.longitude),
                    };
                    console.log("allcords.length ", allcords);

                    window.initMap = await this.initMap;
                    await this.renderMap();
                } else {
                    this.setState({
                        show: true,
                        basicType: "warning",
                        basicTitle: "No Data found.",
                        loadshow: "show-n",
                        showform: "show-m",
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    onClickShowManualParkingForm() {
        this.setState({
            slideAddManualForm: "slider-translate-30p",
            overly: "show-m",
        });
    }
    onSubmitManualParking(event) {
        event.preventDefault();
        if (this.state.manual_transporter.value != "") {
            var transporter_code = this.state.manual_transporter.value;
            var transporter_name = this.state.manual_transporter.label;
        } else {
            var transporter = "";
        }

        if (this.state.manual_trucktype.value != "") {
            var trucktype = this.state.manual_trucktype.value;
        } else {
            trucktype = "";
        }

        if (this.state.manual_parkingyard.value != "") {
            var parkingyard = this.state.manual_parkingyard.value;
        } else {
            parkingyard = "";
        }
        var truck_no = $("#manual_truck").val();
        var reported_on = $("#manual_reported_on").val();
        var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

        var parameters = {
            truck_no: truck_no,
            transporter_code: transporter_code,
            transporter_name: transporter_name,
            truck_type: trucktype,
            reported_on: reported_on,
            fence_name: parkingyard,
            dept_code: encode(this.state.dept_code),
        };
        // console.log("paraets ", parameters);
        if (
            truck_no != "" &&
            transporter_code != "" &&
            trucktype != "" &&
            reported_on != "" &&
            parkingyard != ""
        ) {
            redirectURL
                .post("/dashboard/savereportcarrier", parameters)
                .then((response) => {
                    if (response.data.status == "success") {
                        this.setState({
                            show: true,
                            basicType: "success",
                            basicTitle: "Successfully added item",
                            overly: "show-n",
                            slideAddManualForm: "",
                            manual_parkingyard: { value: "", label: "Select Parking Yard" },
                            manual_transporter: { value: "", label: "Select Transporter" },
                            manual_trucktype: { value: "", label: "Select Type" },
                        });
                        var reqparams = {
                            dept_code: encode(this.state.dept_code),
                            startDate: this.state.startDate,
                            endDate: this.state.endDate,
                        };
                        this.onLoadData(reqparams);

                        var truck_no = $("#manual_truck").val("");
                        var reported_on = $("#manual_reported_on").val("");
                    } else {
                        this.setState({
                            show: true,
                            basicType: "danger",
                            basicTitle: "Failed to add item",
                        });
                    }
                });
        } else {
            this.setState({
                show: true,
                basicType: "danger",
                basicTitle: "All fields are mandatory",
            });
        }
    }

    initalList = (event) => {
        $(".trucks-dropdown").removeClass("show-n");
        var datasetarr = this.state.struckslist;
        // console.log("dataset",dataset);
        var dataset = [];
        if (datasetarr.length > 0) {
            datasetarr.map((item) => {
                dataset.push(item.value);
            });
        }
        dataset = dataset.slice(0, 100);

        this.setState({
            filteredData: dataset,
            showTrucksList: "show-m",
        });
    };

    handlerManualTruckForm = (event) => {
        try {
            $(".trucks-dropdown").removeClass("show-n");
            var datasetarr = this.state.struckslist;
            // console.log("dataset",dataset);
            var dataset = [];
            if (datasetarr.length > 0) {
                datasetarr.map((item) => {
                    dataset.push(item.value);
                });
            }
            var tptunqrecords = [];

            tptunqrecords = dataset;

            var filteredResult = tptunqrecords.filter(function (e) {
                if (e != "" && e != null) {
                    return e
                        .toString()
                        .toLowerCase()
                        .includes(
                            $("#manual_truck")
                                .val()
                                .toLocaleLowerCase()
                        );
                }
            });
            filteredResult = filteredResult.slice(0, 100);
            this.setState({
                filteredData: filteredResult,
                showTrucksList: "show-m",
            });
        } catch (e) { }
    };

    onChangeManualPlantItem(manual_parkingyard) {
        this.setState({ manual_parkingyard }, () =>
            console.log(`Name  Option selected:`, this.state.manual_parkingyard)
        );

        this.setState({
            errorMessage: "",
            successMessage: "",
        });
    }

    handlerManualDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);

        var startdate =
            d.getFullYear() +
            "-" +
            (d.getMonth() + 1) +
            "-" +
            (d.getDate() < 10 ? "0" + d.getDate() : d.getDate()) +
            " " +
            d.getHours() +
            ":" +
            (d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes());

        this.setState({
            manual_parking_out: startdate,
        });
        //let value = event.target.value;
        //this.setState({'startDate':value});
    };

    render() {
        var listData = [];
        try {
            this.state.filteredData.map(function (e) {
                listData.push(<li className="dropdown-truck-no">{e}</li>);
            });
        } catch (e) {
            console.log(e);
        }

        const modalStyles = {
            width: "600px !important",
        };

        //console.log("Rendere ",this.state.deptcode)
        const { withdrawmodal } = this.state;
        const columnwithDefs = [
            // {
            // 	headerName: "Actions",
            // 	field: "_id",
            // 	width: 40,
            // 	// cellRenderer:'consignmentActions',
            // 	filter: false,
            //   resizable: true,
            //   headerCheckboxSelection: true,
            //   headerCheckboxSelectionFilteredOnly: true,
            // 	checkboxSelection: true
            //
            // },
            // {
            //     headerName: "",
            //     field: "",
            //     width: 120,
            //     cellRendererSelector: function (params) {
            //         try {
            //             if (params.data.carrier_reported == 1) {
            //                 var rendComponent = {
            //                     component: "WithdrawCarrier",
            //                 };
            //             } else {
            //                 rendComponent = {
            //                     component: "ReportCarrier",
            //                 };
            //             }
            //             return rendComponent;
            //         } catch (e) { }
            //     },
            // },
            // {
            //     headerName: "",
            //     field: "",
            //     width: 120,
            //     cellRendererSelector: function (params) {
            //         try {
            //             var rendComponent = {
            //                 component: "ParkingoutCarrier",
            //             };
            //             return rendComponent;
            //         } catch (e) { }
            //     },
            // },
            {
                headerName: "",
                field: "",
                width: 50,
                cellRenderer: "consignmentActions",
            },
            {
                headerName: "Truck no",
                field: "truck_no",
                width: 100,
                filter: true,
                resizable: true,
            },
            {
                headerName: "Plant Parking",
                field: "fence_name",
                width: 140,
                filter: true,
                Routeresizable: true,
            },
            {
                headerName: "Transporter Name",
                field: "trucks",
                width: 200,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    try {
                        if (params.data.trucks.length > 0) {
                            if (
                                params.data.trucks[0].transporter_name != "" &&
                                params.data.trucks[0].transporter_name != undefined
                            ) {
                                return params.data.trucks[0].transporter_name;
                            } else {
                                return "";
                            }
                        } else {
                            return "NA";
                        }
                    } catch (e) { }
                },
            },

            {
                headerName: "Truck Type",
                field: "truck_type",
                width: 120,
                filter: true,
                resizable: true,
                valueGetter:function(params){
                	if(params.data.truck_type != "" && params.data.truck_type != undefined)
                	{
                    if(params.data.truck_type == "TK")
                    {
                      return "Truck";
                    }
                    else {
                      return params.data.truck_type;
                    }

                	}
                	else {
                		return "here";
                	}
                }
            },
            {
                headerName: "Reported On",
                field: "reporting_in_date",
                width: 140,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    return getHyphenDDMMMYYYYHHMM(params.data.reporting_in_date);
                },
                //filter: "agDateColumnFilter",
                comparator: dateComparator,
            },

            {
                headerName: "GPS Status",
                field: "gps_status",
                width: 160,
                filter: true,
                resizable: true,
                // //filter: "agDateColumnFilter",
                // comparator: dateComparator,
                valueGetter: function (params) {
                    try {
                        if (
                            params.data.gps_status != "" &&
                            params.data.gps_status != undefined
                        ) {
                            if (params.data.gps_status == "Inactive") {
                                return "Inactive";
                            } else {
                                return "Active";
                            }
                        } else {
                            if (params.data.truckloc.length > 0) {
                                if (
                                    params.data.truckloc[0].elaped_time_from_last_moved_seconds ==
                                    "" ||
                                    params.data.truckloc[0].elaped_time_from_last_moved_seconds ==
                                    undefined
                                ) {
                                    return "Inactive";
                                } else {
                                    return "Active";
                                    // if(params.data.truckloc[0].elaped_time_from_last_moved_seconds == 0)
                                    // {
                                    //     return "Travelling";
                                    // }
                                    // else{
                                    //     var mre = secondsToDhms(params.data.truckloc[0].elaped_time_from_last_moved_seconds);
                                    //     //console.log("mre ", mre)
                                    //     return "Idle ("+mre+")";
                                    // }
                                }
                            } else {
                                return "";
                            }
                        }
                    } catch (e) {
                        return "";
                    }
                },
                cellClass: function (params) {
                    try {
                        if (
                            params.data.gps_status != "" &&
                            params.data.gps_status != undefined
                        ) {
                            if (params.data.gps_status == "Inactive") {
                                return ["maroonbg", "fbold"];
                            } else {
                                return ["greenbg", "white", "fbold"];
                            }
                        } else {
                            if (params.data.truckloc.length > 0) {
                                if (
                                    params.data.truckloc[0].elaped_time_from_last_moved_seconds ==
                                    "" ||
                                    params.data.truckloc[0].elaped_time_from_last_moved_seconds ==
                                    undefined
                                ) {
                                    return ["maroonbg", "fbold"];
                                } else {
                                    // if(params.data.truckloc[0].elaped_time_from_last_moved_seconds == 0)
                                    // {
                                    //   return ["greenfont","fbold"];
                                    // }
                                    // else{
                                    //   return ["redfont","fbold"];
                                    // }
                                    return ["greenbg", "white", "fbold"];
                                }
                            } else {
                                return "";
                            }
                        }
                    } catch (e) {
                        return "";
                    }
                },
            },

            {
                headerName: "GPS Received On",
                field: "truckloc",
                width: 140,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    try {
                        if (params.data.truckloc.length > 0) {
                            return getHyphenDDMMMYYYYHHMM(params.data.truckloc[0].timestamp);
                        } else {
                            return "";
                        }
                    } catch (e) { }
                },
            },

            {
                headerName: "GPS Provider",
                field: "truckloc",
                width: 120,
                filter: true,
                resizable: true,
                // //filter: "agDateColumnFilter",
                // comparator: dateComparator
                valueGetter: function (params) {
                    try {
                        if (params.data.truckloc.length > 0) {
                            return params.data.truckloc[0].actual_lspuser;
                        } else {
                            return "";
                        }
                    } catch (e) { }
                },
            },
            {
                headerName: "Arrival Marked On",
                field: "arrival_marked_on",
                width: 140,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    try {
                        if (
                            params.data.arrival_marked_on != "" &&
                            params.data.arrival_marked_on != undefined
                        ) {
                            return getHyphenDDMMMYYYYHHMM(params.data.arrival_marked_on);
                        } else {
                            return "";
                        }
                    } catch (e) { }
                },
            },

            {
                headerName: "Withdraw On",
                field: "withdraw_on",
                width: 140,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    try {
                        if (
                            params.data.withdraw_on != "" &&
                            params.data.withdraw_on != undefined
                        ) {
                            return getHyphenDDMMMYYYYHHMM(params.data.withdraw_on);
                        } else {
                            return "";
                        }
                    } catch (e) { }
                },
            },
            {
                headerName: "Withdraw Reason",
                field: "withdraw_reason",
                width: 140,
                filter: true,
                resizable: true,
                // //filter: "agDateColumnFilter",
                comparator: dateComparator,
            },

            {
                headerName: "Gate In Time",
                field: "gate_in_time",
                width: 140,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    if (
                        params.data.gate_in_time != "" &&
                        params.data.gate_in_time != undefined
                    ) {
                        return getHyphenDDMMMYYYYHHMM(params.data.gate_in_time);
                    } else {
                        return "";
                    }
                },
                //filter: "agDateColumnFilter",
                comparator: dateComparator,
            },

            {
                headerName: "Gate Out Time",
                field: "gate_out_time",
                width: 140,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    if (
                        params.data.gate_out_time != "" &&
                        params.data.gate_out_time != undefined
                    ) {
                        return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
                    } else {
                        return "";
                    }
                },
                //filter: "agDateColumnFilter",
                comparator: dateComparator,
            },

            {
                headerName: "Parking Out Time",
                field: "parking_out_time",
                width: 140,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    if (
                        params.data.parking_out_time != "" &&
                        params.data.parking_out_time != undefined
                    ) {
                        return getHyphenDDMMMYYYYHHMM(params.data.parking_out_time);
                    } else {
                        return "";
                    }
                },
                //filter: "agDateColumnFilter",
                comparator: dateComparator,
            },

            {
                headerName: "Duration in Parking",
                field: "parking_duration",
                width: 140,
                filter: true,
                resizable: true,
                valueGetter: function (params) {
                    if (
                        params.data.parking_out_time != "" &&
                        params.data.parking_out_time != undefined
                    ) {
                        var s1 = moment.parseZone(params.data.parking_out_time).format("x");
                        var s2 = moment
                            .parseZone(params.data.arrival_marked_on)
                            .format("x");
                        var diff = (s1 - s2) / 1000;
                        console.log("secondsToDhms(diff) ", secondsToDhms(diff));
                        return secondsToDhms(diff);
                    } else {
                        return "";
                    }
                },
                //filter: "agDateColumnFilter",
                comparator: dateComparator,
            },
        ];
        const dStyles = {
            width: this.props.width,
            height: "500px",
            marginLeft: this.props.marginLeft,
        };
        var showonlysnd = true;
        var showonlyprt = true;
        var hidecol = true;
        var columnDefs = [
            {
                headerName: "Truck No",
                field: "truck_no",
                width: 140,
            },
            // 	{
            // 	  headerName: "LSP Code",
            // 	  field: "transporter_code",
            // 	  width: 200,

            //   },
            {
                headerName: "LSP Name",
                field: "transporter_name",
                width: 200,
            },

            {
                headerName: "Actual GPS Provider",
                field: "actual_lspuser",
                width: 200,
            },
            {
                headerName: "Plant Code",
                field: "plant_code",
                width: 120,
            },
            {
                headerName: "Invoice Time",
                field: "invoice_time",
                width: 150,
                hide: showonlysnd,
                valueGetter: function (params) {
                    return getHyphenDDMMMYYYYHHMM(params.data.invoice_time);
                },
            },
            // {
            //   headerName: "Distance from Location (KM)",
            //   field: "distance_from_coordinates",
            //   width: 120,
            //   hide : hideDistance
            // },
            {
                headerName: "Last Known City/Town",
                field: "area",
                width: 150,
            },
            {
                headerName: "Last Known State",
                field: "state",
                width: 150,
            },
            {
                headerName: "Last Packet Time",
                field: "timestamp",
                width: 150,
                valueGetter: function (params) {
                    return getHyphenDDMMMYYYYHHMM(params.data.timestamp);
                },
            },
            {
                headerName: "Gate Out Time",
                field: "gate_out_time",
                width: 150,
                valueGetter: function (params) {
                    return getHyphenDDMMMYYYYHHMM(params.data.gate_out_time);
                },
                hide: showonlyprt,
            },

            {
                headerName: "Consignee City",
                field: "consignee_city",
                width: 140,
                hide: hidecol,
            },

            {
                headerName: "Consignee State",
                field: "consignee_state",
                width: 140,
                hide: hidecol,
            },
            // {
            //   headerName: "Cluster",
            //   field: "cluster",
            //   width: 120,
            //   hide:showonlysnd
            // },
            {
                headerName: "Route",
                field: "route_id",
                width: 120,
                hide: showonlysnd,
            },
            {
                headerName: "GPS Status",
                field: "gps_status",
                width: 120,
                valueGetter: function (params) {
                    try {
                        if (
                            params.data.gps_status != "" &&
                            params.data.gps_status != undefined
                        ) {
                            return params.data.gps_status;
                        } else {
                            return "Inactive";
                        }
                    } catch (e) {
                        return "";
                    }
                },
                cellClass: function (params) {
                    try {
                        if (
                            params.data.gps_status != "" &&
                            params.data.gps_status != undefined
                        ) {
                            if (params.data.gps_status == "Inactive") {
                                return ["maroonbg", "fbold"];
                            } else {
                                return ["greenbg", "white", "fbold"];
                            }
                        } else {
                            return ["maroonbg", "white", "fbold"];
                        }
                    } catch (e) {
                        return "";
                    }
                },
            },
        ];

        return (
            <div className="">

                    <div className="col-xl-12 col-lg-12">

                                    <form
                                        className="row theme-form col-xl-12 col-lg-12"
                                        onSubmit={this.formHandler}
                                    > 
                                        <div className="col-xl-3 col-lg-3 form-group">
                                            <Select
                                                placeholder={"Select Parking"}
                                                closeMenuOnSelect={true}
                                                onChange={this.onChangeParkingItem.bind(this)}
                                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                                style={{ borderRadius: "0px" }}
                                                name="geofence_name"
                                                value={this.state.geofence_name}
                                                options={this.state.parkinglist}
                                            />
                                        </div>
                                        <div className="col-xl-3 col-lg-3 form-group">
                                            <Select
                                                placeholder={"Select Truck"}
                                                closeMenuOnSelect={true}
                                                onChange={this.onChangeTruckItem.bind(this)}
                                                className={"col-xl-12 col-lg-12 border-radius-0"}
                                                style={{ borderRadius: "0px" }}
                                                name="truck_no"
                                                value={this.state.truck_no}
                                                options={this.state.truckslist}
                                            />
                                        </div>
                                        <div className="col-xl-2 col-lg-2 form-group">
                                            {/* <Datetime value={this.state.startDate} inputProps={{ placeholder: 'Start Date', name: 'startDate', autoComplete:'off', required:true }} dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss"  name="startDate" onChange={this.handlerStartDateTime.bind(this)} /> */}
                                            <input
                                                type="text"
                                                name="startDate"
                                                autoComplete="Off"
                                                id="startDate"
                                                placeholder="Start Date"
                                                className="datepicker form-control"
                                                onChange={this.handlerStartDateTime.bind(this)}
                                                value={this.state.startDate}
                                            />
                                        </div>

                                        <div className="col-xl-2 col-lg-2 form-group">
                                            {/* <Datetime value={this.state.endDate} inputProps={{ placeholder: 'End Date', name: 'endDate', autoComplete:'off', required:true }} dateFormat="YYYY-MM-DD" timeFormat="HH:mm:ss" onChange={this.handlerEndDateTime.bind(this)} /> */}
                                            <input
                                                type="text"
                                                name="endDate"
                                                autoComplete="Off"
                                                id="endDate"
                                                placeholder="End Date"
                                                className="datepicker form-control"
                                                onChange={this.handlerEndDateTime.bind(this)}
                                                value={this.state.endDate}
                                            />
                                        </div>

                                        <div className="col-xl-2 col-lg-2 form-group">
                                            <button type="submit" className="btn btn-success">
                                                Get Data
                                            </button>
                                        </div>
                                    </form>
                                <div
                                    id="myGrid"
                                    style={{ height: "580px", width: "100%" }}
                                    className={"ag-theme-balham " + this.state.parkingyard}
                                >
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnwithDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={true}
                                        enableRangeSelection={true}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        frameworkComponents={this.state.frameworkComponents}
                                        detailCellRendererParams={
                                            this.state.detailCellRendererParams
                                        }
                                        //editType={this.state.editType}
                                        stopEditingWhenGridLosesFocus={true}
                                        //floatingFilter={true}
                                        //enableCellChangeFlash={true}
                                        suppressCellFlash={true}
                                        rowClassRules={this.state.rowClassRules}
                                        //onCellClicked={this.onRowClicked.bind(this)}
                                        gridOptions={{
                                            context: { componentParent: this },
                                        }}
                                        masterDetail={true}
                                        overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                    />
                                </div>


                    </div>

                {this.state.sliderRouteTranslate != "" ? (
                    <div
                        className={"slide-r " + this.state.sliderRouteTranslate}
                        style={{ overflow: "auto" }}
                    >
                        <div className="slide-r-title">
                            <h4>
                                View Route
                                <span
                                    className="float-right closebtn"
                                    style={{ marginRight: "25px" }}
                                    onClick={this.onCloseRouteDiv.bind(this)}
                                >
                                    X
                                </span>
                            </h4>
                        </div>
                        <div className="slide-r-body" style={{ position: "relative" }}>
                            <div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                                <DrawMap
                                    mapFor={"geofence"}
                                    truckno={this.state.maptruckno}
                                    mapinfo={this.state.mapinfo}
                                    defTransitCoords={this.state.defTransitCoords}
                                />
                                <div className="col-xl-12 col-lg-12 n-p-0">
                                    <div className="crm-numbers pb-0">
                                        <h3 className="subH">{this.state.sidebarSubHeader}</h3>
                                        <div className="col-xl-12 col-lg-12 row">
                                            <div className="col route-block">
                                                <label className="sidebar-label">Truck No</label>
                                                <div>{this.state.routeTruck.truck_no}</div>
                                            </div>
                                            {/* <div className="col route-block">
												<label className="sidebar-label">Transporter</label>
												<div>{(!isEmpty(maptransporter))?maptransporter:""}</div>
											</div> */}
                                            <div className="col route-block">
                                                <label className="sidebar-label">Start Time</label>
                                                <div>
                                                    {this.state.routeTruck.startTime != ""
                                                        ? getDDMMYYYYHHMMSS(this.state.routeTruck.startTime)
                                                        : "NA"}
                                                </div>
                                            </div>
                                            <div className="col route-block">
                                                <label className="sidebar-label">End Time</label>
                                                <div>
                                                    {this.state.routeTruck.endTime != ""
                                                        ? getDDMMYYYYHHMMSS(this.state.routeTruck.endTime)
                                                        : "NA"}
                                                </div>
                                            </div>

                                            <div className="col route-block">
                                                <label className="sidebar-label">Distance</label>
                                                <div>
                                                    {!isEmpty(this.state.mapinfo.route_details)
                                                        ? Math.round(
                                                            this.state.mapinfo.route_details.distance / 1000
                                                        )
                                                        : "0"}{" "}
                                                    Kms
                                                </div>
                                            </div>
                                            {/*<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.start_time):"-"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.end_time):"-"}</div>
											</div>
										*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}


                <div className={"dataLoadpage " + this.state.loadshow}></div>
                <div className={"dataLoadpageimg " + this.state.loadshow}>
                    <div class="loader-box">
                        <div class="loader-box">
                            <div class="rotate dashed colored"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function loadScript(url) {
    var index = window.document.getElementsByTagName("script")[0];
    var script = window.document.createElement("script");
    script.src = url;
    script.async = true;
    script.defer = true;
    index.parentNode.insertBefore(script, index);
}

function getPolygonCoords() {
    var len = geoFencingArea.getPath().getLength();
    console.log(len);
    var htmlStr = "";
    var coordinates = [];
    for (var i = 0; i < len; i++) {
        // var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
        // coordinates.push()
        var strlatlng = geoFencingArea
            .getPath()
            .getAt(i)
            .toUrlValue(5);
        var spliting = strlatlng.split(",");
        var latlngd = {
            lat: parseFloat(spliting[0]),
            lng: parseFloat(spliting[1]),
        };
        coordinates.push(latlngd);
        // htmlStr += "new google.maps.LatLng(" + geoFencingArea.getPath().getAt(i).toUrlValue(5) + "), ";
        //Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
        //htmlStr += "" + geoFencingArea.getPath().getAt(i).toUrlValue(5);
    }
    // console.log("htmlStr", coordinates);
}
function ConvertSeconds(n) {
    var days = Math.round(parseInt(n / (24 * 3600)));

    n = n % (24 * 3600);
    var hours = Math.round(parseInt(n / 3600));

    n %= 3600;
    var minutes = Math.round(n / 60);

    n %= 60;
    var seconds = Math.round(n);

    return (
        days + " Day(s) " + hours + " Hrs " + minutes + " mins " + seconds + " secs"
    );
}

function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key)) return false;
    }
    return true;
}
function timeConvert(n) {
    var num = n;
    var hours = num / 3600;
    var rhours = parseInt(hours);
    var minutes = (num - rhours * 3600) / 60;
    var rminutes = Math.round(minutes);
    return rhours + " hour(s) and " + rminutes + " minute(s).";
}
function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}
function loadDatepicker() {
    $(".datepicker").datepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true,
    });
}

window.onload = function () {
    var today = new Date();
    var time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    $("#manual_reported_on").datetimepicker({
        mask: "39-19-9999 29:59",
        format: "d-m-Y H:i",
        onShow: false,
        step: 5,
        minTime: time,
    });
    $("#manual_parking_out").datetimepicker({
        mask: "39-19-9999 29:59",
        format: "d-m-Y H:i",
        onShow: false,
        step: 5,
        minTime: time,
    });

    $(".styles_modal__gNwvD").css({ width: "450px" });
};
document.addEventListener("turbolinks:before-render", () => {
    loadDateTimeScript();
});

function loadDateTimeScript() {
    var today = new Date();
    var time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    $("#manual_reported_on").datetimepicker({
        mask: "39-19-9999 29:59",
        format: "d-m-Y H:i",
        onShow: false,
        step: 5,
        minTime: time,
    });
    $("#manual_parking_out").datetimepicker({
        mask: "39-19-9999 29:59",
        format: "d-m-Y H:i",
        onShow: false,
        step: 5,
        minTime: time,
    });

    var index = window.document.getElementsByTagName("script")[1];
    var script = window.document.createElement("script");
    script.src =
        "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js";
    //script.src="http://xdsoft.net/jqplugins/datetimepicker/"
    script.async = true;
    script.defer = true;
    index.parentNode.insertBefore(script, index);
}

function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor((seconds % (3600 * 24)) / 3600);
    var m = Math.floor((seconds % 3600) / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay;
}

$(document).ready(function () {
    $(document).on("click", ".dropdown-truck-no", function () {
        var vals = $(this).text();
        $("#manual_truck").val(vals);
        $(".trucks-dropdown").removeClass("show-m");
        $(".trucks-dropdown").addClass("show-n");
    });

    $("body").on("click", function (e) {
        var container = $("#manual_truck");
        if (!container.is(e.target)) {
            try {
                if (
                    $(".trucks-dropdown")
                        .attr("class")
                        .includes("show-m") ||
                    $(".trucks-dropdown").attr("class") == "trucks-dropdown"
                ) {
                    $(".trucks-dropdown").removeClass("show-m");
                    $(".trucks-dropdown").addClass("show-n");
                }
            } catch (e) { }
        }
    });
});
