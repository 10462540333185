var axios = require('axios');

var axiosInstance = axios.create({
  baseURL: 'http://bpclptd.in/bpcl/'
  // baseURL: 'http://localhost:5017/bpcl/'
  /* other custom settings */
});

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem("token"); // Replace with your actual Bearer token
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

module.exports = axiosInstance;
