'use strict';

import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import DrawMap from '../common/drawmap';
import CommentActions from "../layouts/commentsComponent";
import ForceCloseSideBar from "../layouts/forceclosuresidebarcomponent";
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import MarkDeliver from "../layouts/markDeliveredButton.js";
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import ConsignmentActions from '../manage/ConsignmentActionsComponent';
import CountUp from 'react-countup';
import SweetAlert from 'react-bootstrap-sweetalert';
import CSVFileValidator from 'csv-file-validator';
import Consignmentforceclose from '../manage/consignmentforceclose';
import UpdateButton from "../layouts/updateManualExportsBtn";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import CellGraphComponent from '../layouts/cellGraphComponent';
import $ from 'jquery';
import ColumnGroup from '../layouts/columngroupComponent';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS } from '../common/utils'; 
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { HardwareDesktopWindows } from 'material-ui/svg-icons';
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from "@fullcalendar/timegrid";  
import Modal from 'react-responsive-modal';  
import "../../assets/css/slots.css";
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var redirectURL = require('../redirectURL');
var moment = require('moment');
var filecontent="";
var googleAnalytics = require("../common/googleanalytics");
var CELL_DIMENSION_SIZE = 90;


export default class ManageSlots extends Component {
  constructor(props) {
    super(props);
      this.state = {
        modules : AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            editable: true,
        },
        eventAction: null,
        show: false,
        frameworkComponents : {
          consignmentforceclose:Consignmentforceclose,
          consignmentActions:ConsignmentActions,
          UpdateButton:UpdateButton,
          MarkDeliver : MarkDeliver,
          commentActions : CommentActions
        },
        modified_by:"",
        basicTitle:'',
        reasonforceclose:"",
        bulkslide:"",
        file:"",
        uploadHolidayFile:"",
        uploadSlotsFile: "",
        basicType:"default",
        loadshow:'show-n',
        forceCloseRowNode : "",
        forceclosedata:"",
        csvcontent:"",
        sliderForceCloseTranslate:"",
        overly:'',
        rowData:[],
        filterCustomerAreas: [],
        allFilterCustomerAreas: [],
        bulkslideholidays:"",
        bulkslideslots:"",
        showDiv:"",
        uploadDivWidth:"",
        mapinfo:"",
        dealer:"",
        sliderCommentTranslate:"",
        commentsRowData:"",
        consignment_code:"",
        sliderRouteTranslate:"",
        maptruckno:"",
        routeTruck:	"",
        rownode:"",
        googelRoutes:"",
        file:"",
        slotsfile: "",
        rowSelection:'multiple',
        sliderForceCloseTranslate:"",
        maptruckno:"",
        rowModelhighchartoptionsType: 'enterprise',
        rowGroupPanelShow: 'always',
            statusBar: {
        statusPanels: [
          {
          statusPanel: "agTotalAndFilteredRowCountComponent",
          align: "left"
          },
          {
          statusPanel: "agTotalRowCountComponent",
          align: "center"
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" }
        ]
            },
        paginationPageSize:200,
        geofencelist:[],
        geoFenceData:"",
        sideBar: {
          toolPanels: [
            {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel"
            },
            {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel"
            }
          ]
        },
        animateRows: true,
        dept_code:"",
        pagetitle:"",
        markDeliverRowNode : "",
        markDeliverData:"",
        sliderMarkDeliverTranslate:"",
        csvData:0,
        csvSlotsData: 0,
        overly:"show-n",
        loadshow:"show-n",
        distributors:[],
        distributor_code:"",
        flArea: "",
        flConsignee: "",
        cellDistributors:[],
        holidayEvents : [],
        selectedDate: "",
        showCalendar : "show-m",
        showGridData : "show-m",
        usermanualmodal:false,
        distributor_val : {value : '', label : ''},
        selectedFilter: [],
        consigneeCode: "",
        selectedEvent: null,
        events: [],
        userType:"",
        allConsignees: []
        //components : {datepicker:getDatePicker()}
    }
    this.changeHolidayFileHandler = this.changeHolidayFileHandler.bind(this);
    this.uploadBulkHolidayFormHandler = this.uploadBulkHolidayFormHandler.bind(this);
    this.changeSlotsFileHandler = this.changeSlotsFileHandler.bind(this);
    this.uploadBulkSlotsFormHandler = this.uploadBulkSlotsFormHandler.bind(this);
  }

  calendarRef = React.createRef();

componentDidMount = async () => {
    loadDateTimeScript();
    let userType = localStorage.getItem("user_type");
    let consigneeCode = localStorage.getItem("consignee_code");
    var today = moment.parseZone(new Date()).format("YYYY-MM-DD");
    this.setState({
      showGridData: "show-n",
      loadshow: "show-m",
      overly: "show-m",
      userType: userType,
      consigneeCode: consigneeCode,
    });
    var dept_code;
    if (this.props.match.path == "/sndholidaycalendar") {
      dept_code = "SNDG";
    }
    if (this.props.match.path == "/prtholidaycalendar") {
      dept_code = "LOG-PRT";
    }
    if (this.props.match.path == "/tnpholidaycalendar") {
      dept_code = "LOG-TNP";
    }
    var preqparams = {
      dept_code: dept_code,
    };
    if (userType === "DISTRIBUTOR") {
      preqparams.consigneeCode = consigneeCode;
    } else if (userType == "NAGPURORGUSER") {
      preqparams.customerCity = ["Nagpur", "NAGPUR"];
    } else if (userType == "NASIKORGUSER") {
      preqparams.customerCity = ["Nasik", "NASIK", "Nashik", "NASHIK"];
    } else if (userType == "SOLAPURORGUSER") {
      preqparams.customerCity = ["Solapur"];
    } else if (userType == "INDOREORGUSER") {
      preqparams.customerCity = ["Indore"];
    } else if (userType == "AHMEDABADORGUSER") {
        preqparams.customerCity = ["Ahmedabad"];
      }
    preqparams.userType = userType;
    await redirectURL
      .post("/consignments/distributors", preqparams)
      .then(
        (response) => {
          var records = response.data;
          console.log("distributors ", records);
          var distributors = [];
          var cellDistributors = [];
          var allConsignees = []; //get all consigner numbers into array which is used for filtering their respected areas
          if (records.length > 0) {
            records.map((item) => {
              allConsignees.push(item.consignee_code);
              distributors.push({
                value: item.consignee_code,
                label: item.consignee_name,
                consigneeCode: item.consignee_code
              });
              cellDistributors.push(item.consignee_name);
            });
          }
          this.setState({
            distributors: distributors,
            cellDistributors: cellDistributors,
            dept_code: dept_code,
            selectedDate: today,
            allConsignees: allConsignees,
            flConsignee: distributors[0] //setting first distributor to distributor dropdown
          },async () => {
            if (localStorage.getItem("user_type") == "DISTRIBUTOR") {
              var params = { consigneeCode: consigneeCode, userType };
            } else {
              params = {consigneeCode: this.state.allConsignees, userType};
            }

            await redirectURL.post("/masters/customerarea", params).then(
              (res) => {
                console.log("customerarea", res.data); //resp.data contains all areas irrespective of distributor and common user
                var filterCustomerAreas = [];
                // var allCustomerAreas = [];
                if(res.data && Array.isArray(res.data)){
                    res.data.map((item) => {
                      if (this.state.allConsignees.includes(item.consignee_code)) {
                        filterCustomerAreas.push({
                          label: item.customer_area,
                          value: item.customer_area_id,
                          consigneeCode: item.consignee_code, //here consigneeCode is also pushed for filtering in later steps
                        });
                      }
                    });
                }
                
                this.setState(
                  {
                    filterCustomerAreas: filterCustomerAreas, //assigned to common user's or distributor's areas dropdown
                    allFilterCustomerAreas: filterCustomerAreas, //storing for further stages
                  },() => {
                    let firstArea = "" //by default one area is assgined to areas dropdown for which time slots is fetched
                    for (let each of filterCustomerAreas){
                        if(each.consigneeCode === distributors[0].consigneeCode){
                            firstArea = each.value;
                            this.setState({
                                flArea: each
                            })
                        }
                        break;                        
                    }
                    if (localStorage.getItem("user_type") == "DISTRIBUTOR") {
                      var slotParams = {
                        distributors: consigneeCode,
                        selectedDate: this.state.selectedDate,
                        areaCode: firstArea
                      };
                    } else {
                      slotParams = {
                          distributors : this.state.allConsignees[0], //by default one area's time slot is fetching
                          selectedDate : this.state.selectedDate,
                          areaCode: firstArea
                      }
                    }
                    redirectURL
                      .post(
                        "/consignments/getCurrentMonthDistributorTimeSlots",
                        slotParams
                      )
                      .then((response) => {
                        console.log("slotEvents", response.data);
                        var availableSlotEvents = [];
                        var unavailableSlotEvents = [];
                        var availableSlots = response.data.filter(
                          (e) => e.available == 1
                        );
                        var unavailableSlots = response.data.filter(
                          (e) => e.available == 0
                        );
                        availableSlots.map((item) => {
                          availableSlotEvents.push({
                            title: "Slot available",
                            start: item.from_time,
                            end: item.to_time,
                          });
                        });
                        unavailableSlots.map((item) => {
                          unavailableSlotEvents.push({
                            title: "Slot unavailable",
                            start: item.from_time,
                            end: item.to_time,
                          });
                        });
                        var events = [
                          ...availableSlotEvents,
                          ...unavailableSlotEvents,
                        ];
                        this.setState({
                          events: [...this.state.events, ...events],
                          rowData: response.data,
                          loadshow: "show-n",
                          overly: "show-n",
                        });
                        console.log("Events", this.state.events);
                      })
                      .catch((e) => {
                        console.log(e);
                      });
                  }
                );
              }
            );
            await redirectURL
              .post("/consignments/getHolidays", params)
              .then((res) => {
                var holidaysarr = [];
                if(res.data && Array.isArray(res.data)){
                    res.data.map((item) => {
                      holidaysarr.push({
                        title: item.holiday_name,
                        start: item.holiday_date.slice(0, 10),
                        end: moment
                          .parseZone(new Date(item.holiday_date))
                          .add(1, "days")
                          .format("YYYY-MM-DD"),
                      });
                    });
                }
                var events = holidaysarr;
                this.setState({
                  holidays: holidaysarr,
                  events: [...this.state.events, ...events],
                });
                console.log("Holidays", this.state.holidays);
                console.log("Events", this.state.events);
              });
          });
        }
      )
      .catch(function (e) {
        console.log("Error ", e);
      });
  };
    
onGridReady = params => {
    this.gridApi = params.api;  
    //console.log("Grid is: ", params);
    this.gridColumnApi = params.columnApi;
};
onGridState = () =>{
        //console.log(this.gridApi);
    
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
                
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
}

closeAlert = () => {
    this.setState({
        show: false
    });
    // window.location.reload();
}

onClickHideAll(){
    this.setState({
    loadshow:'show-n',
    overly:'show-n',
    bulkslideholidays:"",
    bulkslideslots:""
    
    });
    
}
updateManualExports(params)
{
    console.log(params);
    
    redirectURL.post("/consignments/updateDeptHolidays",{"rowData":params,"dept_code":this.state.dept_code}).then((response)=>{
        console.log(response);
        if(response.data.message == "Success")
        {
            this.setState({
                basicTitle:"Updated Successfully.",
                basicType : "success",
                show : true
            })
            window.location.reload();
        }
        else
        {
            this.setState({
                basicTitle:"Fields Should not be Empty.",
                basicType : "warning",
                show : true
            })
        }
        
    })
    
}
addNewRow()
{
    // console.log(this.gridApi);
    this.gridApi.insertItemsAtIndex(0,[{holiday_date:"",holiday_name:""}])
    // this.gridApi.updateRowData({add:[{holiday_date:"",holiday_name:""}]});
}
showCalendar(){
    this.setState({
        showCalendar : "show-m",
        showGridData  : "show-n"
    })
}
showGrid(){
    this.setState({
        showCalendar : "show-n",
        showGridData  : "show-m"
    })
}

showBulkSlotsUpload(){
    this.setState({
        overly: "show-m",
        bulkslideslots: "slider-translate"
    })
}

resetSlotsUpload = () => {
    this.setState({
        bulkslideslots:'',
        overly:'show-n',
        file:''
    });
    document.getElementById("upformslots").reset();
}

changeSlotsFileHandler = async (e) => {
    var dt = '';
    const config = {
        headers: [
            { 
                name: 'Distributor Code',
                inputName: 'consignee_code',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'Area Code',
                inputName: 'customer_area_id',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'Date',
                inputName: 'date_slot',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'From Time',
                inputName: 'from_time',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'To Time',
                inputName: 'to_time',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            // { 
            //     name: 'Available',
            //     inputName: 'available',
            //     required: true,
            //     requiredError: function (headerName, rowNumber, columnNumber) {
            //         return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
            //     }
            // },
            { 
                name: 'Delivery Capacity',
                inputName: 'delivery_capacity',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            // { 
            //     name: 'Maximum Delivery Capacity',
            //     inputName: 'max_delivery_capacity',
            //     required: true,
            //     requiredError: function (headerName, rowNumber, columnNumber) {
            //         return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
            //     }
            // },
			{ 
                name: 'Amount',
                inputName: 'amount',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            // { 
            //     name: 'Holiday',
            //     inputName: 'holiday',
            //     required: true,
            //     requiredError: function (headerName, rowNumber, columnNumber) {
            //         return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
            //     }
            // }
            
        ]
    }
    var data = CSVFileValidator(e.target.files[0], config)
    .then((csvData) => {
        this.setState({
            csvSlotsData:csvData.data
        });
        console.log(csvData.data);
    })
    .catch(err => {})
    // console.log("e.target.files[0]",e.target.result);
    
    var out = new Promise(function(reject, resolve){
        var reader =  new FileReader();
        reader.onload = async function(e) {
            var contents = await e.target.result;
            resolve(contents);
        };
        var tt =  reader.readAsText(e.target.files[0]);
    });
    this.setState({
        slotsfile:e.target.files[0]
    });
    // console.log("e.target.files[0].type ", e.target.files[0]);

    if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
    {
        // console.log(fileData);
        // console.log(typeof(fileData))
        
    }
    else{
        e.target.value = null;
        this.setState({
            uploadSlotsFile:'',
            show: true, 
            basicType:'danger', 
            basicTitle:'Please upload file having extensions .csv only.',
        });
    }
}

uploadBulkSlotsFormHandler(event){
    event.preventDefault();
    var fileData = this.state.csvSlotsData;
    var flag=0;
    
    var date_flag = 0;
    // console.log(distributorCode);
    console.log(fileData,"fileData");
    fileData.map(function(e,index){
            
        if(e.date_slot == "" || e.consignee_code == "" || e.customer_area_id == "" || e.from_time == "" || e.to_time == "" || e.delivery_capacity == "" || e.amount == "")
        {
            flag = 1;
            console.log(e);
        }
        console.log(e.date_slot,"e.date_slot")
        if(flag == 0)
        {
            var dateslot = e.date_slot;
            console.log(dateslot);
            if(dateslot.indexOf("/") > 0)
            {
                dateslot = dateslot.split("/");
                if(dateslot[0].length !== 2 || dateslot[1].length !== 2 || dateslot[2].length !== 4)
                {
                    console.log(e.date_slot,"indexOf")
                    date_flag = 1;
                }
                
                if(date_flag == 0)
                {
                    e.date_slot = dateslot[2]+"-"+dateslot[1]+"-"+dateslot[0];
                }
            }
            else if(dateslot.indexOf("-") > 0)
            {
                dateslot = dateslot.split("-");
                console.log("dateslot",dateslot)
                if(dateslot[0].length !== 2 || dateslot[1].length !== 2 || dateslot[2].length !== 4)
                {
                    date_flag = 1;
                    console.log("date_flag", date_flag)
                }
                // if(holiday_date_flag == 0)
                // {
                //     holidaytime = holidaytime.split(":");
                //     if(holidaytime[0].length !== 2 || holidaytime[1].length !== 2 || holidaytime[2].length !== 2)
                //     {
                //         holiday_date_flag = 1;
                //     }
                // }
                if(date_flag == 0)
                {
                    e.date_slot = dateslot[2]+"-"+dateslot[1]+"-"+dateslot[0];
                }
            } else {}
                            
        }           
    });
    if(flag == 0 && date_flag == 0)
    {
        var reqparams = {
            "fileData" : fileData,
            // "dept_code" : this.state.dept_code,
        }
        console.log(reqparams);
        redirectURL.post("/consignments/updateBulkTimeSlots",reqparams).then((response)=>{
            this.setState({
                basicTitle : "Uploaded Successfully",
                basicType : "success",
                distributor_code : "",
                show : true,
            });
            // window.location.reload();
        });
    }
    else
    {
        if(flag == 1)
        {
            this.setState({
                basicTitle : "Fields Should not be Empty",
                basicType : "warning",
                show : true,
            })
        }
        if(date_flag == 1)
        {
            this.setState({
                basicTitle : "Incorrect Date Time Format in Date",
                basicType : "warning",
                show : true,
            })
        }
       
        
    }
};

createTimeSlots(){
    this.setState({
        loadshow: 'show-m',
        overly: 'show-m'
    })
    var consigneeCode = this.state.consigneeCode;
    var params = {consigneeCode: consigneeCode}
    try {
        redirectURL.post('/consignments/createDistributorTimeSlots', params).then((res) => {
            console.log("Slots Created", res.results)
            if (res.data.status == "Success") {
                this.setState({
                    basicTitle : "Created Successfully",
                    basicType : "success",
                    distributor_code : "",
                    show : true,
                    loadshow: 'show-n',
                    overly: 'show-n'
                })
            }
        })
    } catch(e){
        console.log(e)
    }
}

showBulkHolidayUpload(){
    this.setState({
        overly :"show-m",
        bulkslideholidays : "slider-translate"
    })
}
resetHolidayUpload = () => {
    this.setState({
        bulkslideholidays:'',
        overly:'show-n',
        file:''
    });
    document.getElementById("upformholidays").reset();
}
changeHolidayFileHandler = async (e) => {
    var dt = '';
    const config = {
        headers: [
            { 
                name: 'Distributor Code',
                inputName: 'consignee_code',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'Holiday Date',
                inputName: 'holiday_date',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            { 
                name: 'Holiday Name',
                inputName: 'holiday_name',
                required: true,
                requiredError: function (headerName, rowNumber, columnNumber) {
                    return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                }
            },
            
        ]
    }
    var data = CSVFileValidator(e.target.files[0], config)
    .then((csvData) => {
        if(csvData.inValidMessages.length > 0 || csvData.data.length === 0){
            this.setState({
                show: true,
                basicType: 'danger',
                basicTitle: 'Please upload file having correct fields..!',
                file: ''
            })
            document.getElementById("upformholidays").reset();
        }else if(csvData.inValidMessages.length === 0 && csvData.data.length > 0){
            this.setState({
                csvcontent:csvData.data,				
            },()=>{
                console.log("csvcontent",this.state.csvcontent);
            });
        }
    })
    .catch(err => {})
    // console.log("e.target.files[0]",e.target.result);
    
    var out = new Promise(function(reject, resolve){
        var reader =  new FileReader();
        reader.onload = async function(e) {
            var contents = await e.target.result;
            resolve(contents);
        };
        var tt =  reader.readAsText(e.target.files[0]);
    });
    this.setState({
        file:e.target.files[0]
    });
    // console.log("e.target.files[0].type ", e.target.files[0]);

    if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
    {
        // console.log(fileData);
        // console.log(typeof(fileData))
        
    }
    else{
        e.target.value = null;
        this.setState({
            uploadHolidayFile:'',
            show: true, 
            basicType:'danger', 
            basicTitle:'Please upload file having extensions .csv only.',
        });
    }
    
}

uploadBulkHolidayFormHandler(event){
    event.preventDefault();
    var fileData = this.state.csvcontent;
    var flag=0;
    if (localStorage.getItem('user_type') == "DISTRIBUTOR") {
        var distributorCode= localStorage.getItem("consignee_code");
    } else {
        var distributorCode= this.state.distributor_code;
    }
    
    var holiday_date_flag = 0;
    console.log(distributorCode);
    console.log(fileData,"fileData");
    fileData.map(function(e,index){
            
        if(e.holiday_date == "" || e.holiday_name == "")
        {
            flag = 1;
            console.log(e);
        }
        console.log(e.holiday_date,"e.holiday_date")
        if(flag == 0)
        {
            var holidaydate = e.holiday_date;
            console.log(holidaydate);
            if(holidaydate.indexOf("/") > 0)
            {
                holidaydate = holidaydate.split("/");
                if(holidaydate[0].length !== 2 || holidaydate[1].length !== 2 || holidaydate[2].length !== 4)
                {
                    holiday_date_flag = 1;
                }
                
                if(holiday_date_flag == 0)
                {
                    e.holiday_date = holidaydate[2]+"-"+holidaydate[1]+"-"+holidaydate[0];
                }
            }
            else if(holidaydate.indexOf("-") > 0)
            {
                holidaydate = holidaydate.split("-");
                if(holidaydate[0].length !== 2 || holidaydate[1].length !== 2 || holidaydate[2].length !== 4)
                {
                    holiday_date_flag = 1;
                }
                // if(holiday_date_flag == 0)
                // {
                //     holidaytime = holidaytime.split(":");
                //     if(holidaytime[0].length !== 2 || holidaytime[1].length !== 2 || holidaytime[2].length !== 2)
                //     {
                //         holiday_date_flag = 1;
                //     }
                // }
                if(holiday_date_flag == 0)
                {
                    e.holiday_date = holidaydate[2]+"-"+holidaydate[1]+"-"+holidaydate[0];
                }
            }
            else{}
        }
    });
    if(flag == 0 && holiday_date_flag == 0)
    {
        if (localStorage.getItem('user_type') == "DISTRIBUTOR") {
            var reqparams = {
                "fileData" : fileData,
                // "dept_code" : this.state.dept_code,
                "consigneeCode" : this.state.consigneeCode
            }
        } else {
            reqparams = {
                "fileData" : fileData,
                // "dept_code" : this.state.dept_code,
                "consigneeCode" : distributorCode
            }
        }
        
        console.log(reqparams);
        redirectURL.post("/consignments/updateBulkHolidays",reqparams).then((response)=>{
            this.setState({
                basicTitle : "Uploaded Successfully",
                basicType : "success",
                distributor_code : "",
                show : true,
                overly :"show-n",
                bulkslideholidays : ""
            });
            document.getElementById("upformholidays").reset();
            // window.location.reload();
        });
    }
    else
    {
        if(flag == 1)
        {
            this.setState({
                basicTitle : "Fields Should not be Empty",
                basicType : "warning",
                show : true,
            })
        }
        if(holiday_date_flag == 1)
        {
            this.setState({
                basicTitle : "Incorrect Date Time Format in Holiday Date",
                basicType : "warning",
                show : true,
            })
        }
       
        
    }
};
distributoroptions(){
    var items=[];
   // console.log("locations ",this.props.locations)
    var distributors = this.state.distributors;
    //console.log("locations ",locations)
    if(distributors.length > 0){
        // items.push({value:'all',label:'All'}); 
        distributors.forEach(item =>{
            items.push({ value: item.value, label: item.label}); 
        });
    }
    //console.log("Locas ", items)
    return items;
}

onSelectArea = (area) => {
    console.log("Area Filter selected", area)
    this.setState({
        flArea: area
    })
}

onSelectDistributor= (distributor) =>{
    console.log('distributor',distributor)
    // var distributors=distributor.value;
    this.setState({
        // distributor_val: distributor,
        // distributor_code : distributors
    });
}


handleFilterSubmit = (e) => {
    e.preventDefault();
    // var distributors = this.state.distributor_val.value;
    console.log(this.state.flArea);
    console.log(this.state.flConsignee);
    
    try {
        if(this.state.flConsignee && this.state.flArea){
            this.setState({
                loadshow: 'show-m',
                overly: 'show-m'
            })
            let params = {
                distributors : this.state.flConsignee.value,
                areaCode: this.state.flArea.value,
                selectedDate: this.state.selectedDate
            }
            redirectURL.post("/consignments/getDistributorTimeSlots",params).then((response)=>{
                console.log(response.data);
                var events = response.data;
                var holidayEvents = [];
                events.map(function(e){
                    holidayEvents.push({"is_holiday":e.holiday,"date":e.date_slot,"rowid":e._id})
                })
                this.setState({
                    loadshow: 'show-n',
                    overly: 'show-n',
                    rowData : response.data,
                    holidayEvents :holidayEvents
                })
            }).catch((e)=>{
                console.log(e);
            })

        }else{
            this.setState({
                show:true,
                basicType:"danger",
                basicTitle:"Filters shouldn't be empty"
            })
        }
        
    }
    catch(e)
    {
        console.log(e);
    }
}

onClickDate = (date) => {
    if (date !== '' && date !== null && date !== undefined) {
        var selectedDate = date
    } else {
        selectedDate = this.state.selectedDate
    }
    var distributors= this.state.flConsignee.value;
    var areaCode = this.state.flArea.value;
    try{
        var params = {
            distributors : distributors,
            areaCode: areaCode,
            selectedDate : selectedDate
        }
        redirectURL.post("/consignments/getDistributorTimeSlots",params).then((response)=>{
            console.log(response.data);
            var events = response.data;
            var holidayEvents = [];
            events.map(function(e){
                holidayEvents.push({"is_holiday":e.holiday,"date":e.date_slot,"rowid":e._id})
            })
            this.setState({
                rowData : response.data,
                holidayEvents :holidayEvents
            })
        }).catch((e)=>{
            console.log(e);
        })
    }
    catch(e)
    {
        console.log(e);
    }
}

onSelectBulkDistributors= (distributor) =>{
    var distributors=[];
    try{
        distributor.map(function(e){
            distributors.push(e.value);
        })
        console.log(distributors);
        this.setState({
            distributor_code : distributors
        });
    }
    catch(e)
    {
        console.log(e);
    }
    
}

    // handleDateSelect = (info) => {
    //     let calendarApi = this.calendarRef.current.getApi()
    //     // calendarApi.FullCalendar.select(start, end);
    //     console.log("selectedDate", this.state.selectedDate)
        
    // }

    // handleUnselect = (info) => {
    //     let calendarApi = this.calendarRef.current.getApi()
    //     info.dayEl.style.backgroundColor = "transparent";
    //     calendarApi.unselect();
    // }

    // handleDayRender = (date, cell) => {
    //     setTimeout(() => {
    //         var holidays = this.state.holidays
    //         console.log("holidays", holidays)
    //         var holidaysarr = []
    //         holidays.map((item) => {
    //             holidaysarr.push(item.holiday_date)
    //         })
    //         console.log("date to be set", new Date(holidaysarr[0]).getDate())
    //         for(let i=0; i<holidaysarr.length; i++) {
    //             if (date.getDate() === new Date(holidaysarr[i]).getDate()){
    //                 cell.css("background-color", "orange");
    //             }
    //         }
    //     }, 500)
    // }
    handleDayRender = (date, cell) => {
        if (date.getDate() === 29) {
            cell.style.backgroundColor = '#f0ad4e';
          }
    }

    handleValidRange = (nowDate) => {
        // console.log("nowDate",  nowDate)
        return {
            start: moment.parseZone(new Date(nowDate)).utcOffset("-05:30")._d
          };
    }

    // handleEvents = async () => {
    //     // setTimeout(() => {
    //         var holidays = this.state.holidays;
    //         var holidaysarr = [];
    //         await holidays.map((item) => {
    //             holidaysarr.push({
    //                 "title": item.holiday_name,
    //                 "start": item.holiday_date.slice(0,10)
    //             })
    //         })
    //         console.log("holidayssarr", holidaysarr)
    //         return holidaysarr;
    //     // }, 5500)
    // }

    renderEventContent = (info) => {
        // setTimeout(() => {
            return (
                <div>
                    {/* <b>{eventInfo.timeText}</b> */}
                    <i
                        style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis"
                        }}
                    >
                        {info.event.title}
                    </i>
                </div>
            )
        // }, 5500)
    }

    // handleSelectAllow = (info) => {
    //     return (info.start >= getDateWithoutTime(new Date()));
    // }
    
    newFilter = (filterType, selOption) => {
        this.setState({ [filterType]: selOption }, async ()=>{
            //upon changing distributor dropdown, areas' dropdown should also get changed
            if(filterType === "flConsignee"){
                this.setState({
                    flArea:"" //upon changing distributor dropdown, existing area value is emptied
                })
                if(selOption){
                    let areas = []
                    
                    for await (let eachOption of this.state.allFilterCustomerAreas){
                        if(eachOption.consigneeCode === selOption.value){
                            areas.push(eachOption)
                        }
                    }
                    await this.setState({
                        filterCustomerAreas: areas
                    })
                }else{
                    this.setState({
                        filterCustomerAreas: []
                    })
                }
            }
        })
	}

    handleGridCellClick = (e) => {
        // console.log("Row Data", e.data)
        var rowId = e.data._id;
        var rowConsigneeCode = e.data.consignee_code;
        var rowAreaCode = e.data.customer_area_id;
        var rowDateSlot = e.data.date_slot;
        var rowFromTime = e.data.from_time;
        var rowToTime = e.data.to_time;
        var action = "";
        if (e.colDef.field == "mark_available") {
            action = "mark_available"
        } 
        if (e.colDef.field == "mark_unavailable") {
            action = "mark_unavailable"
        }
        if (action !== "") {
            var params = {
                consigneeCode: rowConsigneeCode,
                areaCode: rowAreaCode,
                dateSlot: rowDateSlot,
                fromTime: rowFromTime,
                toTime: rowToTime,
                action: action
            };
    
            redirectURL.post("/consignments/updateDistributorTimeSlotAvailability", params)
                .then((res) => {
                    if(res.data.message == "Success") {
                        this.setState({
                            basicTitle:"Updated Successfully.",
                            basicType : "success",
                            show : true
                        })
                        // window.location.reload();
                    }
                    else
                    {
                        this.setState({
                            basicTitle: res.data.message,
                            basicType : "warning",
                            show : true
                        })
                    }
                })
        }        
    }

    handleAddHolidayClick = () => {
        var selectedDate = this.state.selectedDate;
        var distributor = this.state.distributor_code;
        var areaCode = this.state.flArea.map(e => e.value);

    }

    onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };
    render(){
        const modalStyles  = {
            width:'1300px !important',
        }
        
        const {usermanualmodal} = this.state;
    
    var columnwithDefs = [      
        {
            headerName: "Distributor Code",
            field: "consignee_code",
            width:100,
            filter: true,
            resizable: true,
            editable:false,
            cellEditor: 'agSelectCellEditor',
             cellEditorParams: {
                 values: this.state.cellDistributors,
             },
        },
        {
            headerName: "Area ID",
            field: "customer_area_id",
            width:100,
            filter: true,
            resizable: true,
            editable:false,
        },
        {
            headerName: "Date",
            field: "date_slot",
            width:100,
            resizable: true,
            editable:false,
            cellEditor: DateEditor,
            valueGetter:function(params){
                //console.log("params ", params);
                if (params.data.date_slot != null)
                {
                    if(moment(params.data.date_slot,'DD-MM-YYYY hh:mm',true).isValid() == false)
                    {
                        return getHyphenDDMMMYYYY(params.data.date_slot);
                    }
                    else{
                        return params.data.date_slot;
                    }
                    
                }
                else
                {
                    return ""
                }

            },
            //filter: "agDateColumnFilter",
            comparator: dateComparator1,
            // filterParams: {
            //     browserDatePicker: true,
            //     filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
            //     // provide comparator function
            //     comparator: function(filteredDate,cellValue,secondDate) {
            //         cellValue = cellValue.replace(/\//g,"-")
            //         if(cellValue != "" && cellValue != " "){
            //             cellValue = cellValue.split(" ")[0].split("-");
            //             cellValue = getMonthNumber(cellValue[1]) +"-"+cellValue[0]+"-"+cellValue[2];
            //             var cellDate = new Date(cellValue);
            //             if(filteredDate.getMonth() < 10){
            //             var filterValue = "0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
            //             }
            //             else{
            //                 var filterValue = (filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()+"-"+filteredDate.toString().split(" ")[3]
            //             }
            //             var filteredDate = new Date(filterValue);
            //             cellDate = cellDate.getTime();
            //             filteredDate = filteredDate.getTime();
            //             // console.log(cellDate,filteredDate);
            //             // console.log(cellValue,filterValue);
            //             if(cellDate === filteredDate)
            //             {
            //                 return 0;
            //             }
            //             if(cellDate < filteredDate){
            //                 return -1;
            //             }
            //             if(cellDate > filteredDate)
            //             {
            //                 return 1;
            //             }
            //         }
            //     }
            // },
        },
        {
            headerName: "Time Slot",
            field: "",
            width:100,
            filter: true,
            resizable: true,
            editable:false,
            valueGetter: function (params) {
                var time_slot = moment.parseZone(params.data.from_time).format("HH:mm") + " - " + moment.parseZone(params.data.to_time).format("HH:mm");
                return time_slot
            },
            cellClass: function (params) {
                if( params.data.available == 1 ) {
                    return 'bgColorSuccessMedium'
                } 
                if (params.data.available == 0) {
                    return 'bgColorDangerMedium'
                }
            }
        },
        // {
        //     headerName: "",
        //     field: "mark_available",
        //     width:50,
        //     filter: true,
        //     resizable: true,
        //     // editable:true,
        //     cellRenderer: function (params) {
        //         var htmloption = '<i class="icofont icofont-plus" style="font-size: 18px; margin-top: 6px;color: green; margin-left: 5px; font-weight: bold;"></i>';
        //         return htmloption
        //     }
        // },
        // {
        //     headerName: "",
        //     field: "mark_unavailable",
        //     width:50,
        //     filter: true,
        //     resizable: true,
        //     // editable:true,
        //     cellRenderer: function (params) {
        //         var htmloption = '<i class="icofont icofont-minus" style="font-size: 18px; margin-top: 6px;color: red; margin-left: 5px; font-weight: bold;"></i>'
        //         return htmloption
        //     }
        // },
        {
            headerName: "Delivery Capacity",
            field: "delivery_capacity",
            width:90,
            filter: true,
            resizable: true,
            editable:false,
        },
        // {
        //     headerName: "Max Delivery Capacity",
        //     field: "max_delivery_capacity",
        //     width:120,
        //     filter: true,
        //     resizable: true,
        //     // editable:true,
        // },
        {
            headerName: "Cost",
            field: "amount",
            width:75,
            filter: true,
            resizable: true,
            editable:false,
        },
        {
            headerName: "Status",
            field: "slot_status",
            width:75,
            filter: true,
            resizable: true,
            editable:false,
            cellClass: function (params) {
                if( params.data.slot_status == "Active" ) {
                    return 'bgColorSuccessMedium'
                } 
                if (params.data.slot_status == "Inactive") {
                    return 'bgColorDangerMedium'
                }
            }
        },
        // {
        //     headerName: "Buffer Period (Hrs)",
        //     field: "buffer_period_in_hrs",
        //     width:110,
        //     filter: true,
        //     resizable: true,
        //     editable:true,
        // },
        
        // {
        //     headerName: "Is Holiday",
        //     field: "holiday",
        //     width:60,
        //     filter: true,
        //     resizable: true,
        //     editable:true,
        //     valueGetter:function(params){
        //         if (params.data.holiday == 0) {
        //             return "No"
        //         } 
        //         if (params.data.holiday == 1) {
        //             return "Yes"
        //         }
        //     }
        // },
        
        // {
        //     headerName : "Update",
        //     field:"_id",
        //     width:50,
        //     resizable : true,
        //     editable : false,
        //     cellRendererSelector:function(params){	
        //             // if(params.data.consignment_code != "" && params.data.consignment_code != null && params.data.consignment_code != undefined)
        //         // {
        //             var rendComponent = {
        //                 component: 'UpdateButton'
        //             };
        //             return rendComponent;
        //         // }
        //         // else
        //         // {
        //         //     return "";
        //         // }		
                
        //     },
        // }
    ]
    return (
        <div class="container-fluid">
            <SweetAlert
                show={this.state.show}
                type={this.state.basicType}
                title={this.state.basicTitle}
                onConfirm={this.closeAlert}
            >
            </SweetAlert>
            <div className='card col-xl-12'>
                <div className='row card-body'>
                    <form className='row col-xl-12 col-lg-12' onSubmit={this.handleFilterSubmit}>
                        {
                            this.state.userType !== "DISTRIBUTOR" ?
                            <div className="form-gplantwiseloadingroup col-xl-3 col-lg-3" style={{marginBottom:"23px"}}>
                                <label>Select Distributor: </label>
                                    <Select                          
                                        closeMenuOnSelect={true}
                                        value={this.state.flConsignee}
                                        className="border-radius-0"
                                        onChange={this.newFilter.bind(this, "flConsignee")}
                                        style={{ borderRadius: "09px"}}
                                        options={this.distributoroptions()} 
                                        required
                                    />
                            </div>
                        : ""
                        }
                        <div className="form-group col-xl-3 col-lg-3" style={{zIndex:"2"}}>
                            <label>Area :  </label>
                                <Select
                                    // className="border-radius-0"
                                    // // isMulti={true}
                                    // id="selectedCustomerArea"
                                    // style={{ borderRadius: "0px" }}
                                    // value={this.state.flArea}
                                    // // onChange={this.onSelectArea.bind(this)}
                                    // onChange = {this.newFilter.bind(this,"flArea")}
                                    // options={this.state.filterCustomerAreas}

                                    closeMenuOnSelect={true}
                                    value={this.state.flArea}
                                    className="border-radius-0"
                                    onChange={this.newFilter.bind(this, "flArea")}
                                    style={{ borderRadius: "09px"}}
                                    options={this.state.filterCustomerAreas}
                                    required
                                />
                        </div>
                        <div className="form-group col-xl-3 col-lg-3" style={{ marginTop: "30px" }}>
                            <button type="submit" className="btn btn-success">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
            {/* <button className="btn btn-secondary" onClick={this.showGrid.bind(this)}>Grid View</button>
            <button className="btn btn-danger" onClick={this.showCalendar.bind(this)}>Calendar View</button> */}
            <div className="card row col-xl-12 mx-0">
                <div className="card-header col-xl-6 col-lg-6">
                    <h5 style={{width:"75.5%",float:"left", marginBottom: "15px"}}>
                    <i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>Manage Time Slots</span>
                    </h5>
                    {/* <button className="btn btn-success" onClick={this.handleAddHolidayClick}>Add New Holiday</button> */}
                    {localStorage.getItem('user_type') == "DISTRIBUTOR" ? 
                        <>
                            <button className="btn btn-info" onClick={this.showBulkHolidayUpload.bind(this)}>Bulk Upload Holidays</button>
                            {/* <button className="btn btn-danger" onClick={this.createTimeSlots.bind(this)}>Create Time Slots</button> */}
                        </> 
                    : ""}
                    
                    {/* <button className="btn btn-danger" onClick={this.showBulkSlotsUpload.bind(this)}>Bulk Upload Slots</button> */}
                </div>
                <div className='row'>
                    <div style={{ minHeight: "250px",marginTop:"20px", marginLeft:"10px", marginBottom: "20px"}} className={"row col-xl-6 col-lg-6 "}>
                        <FullCalendar
                            id="calendar"
                            ref={this.calendarRef}
                            defaultView="timeGridWeek"
                            header={{
                                left: "prev,next",
                                center: "title",
                                right: "dayGridMonth,timeGridWeek,timeGridDay"
                            }}
                            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                            selectable={true}                      
                            // select = {this.handleDateSelect}
                            // unselect={this.handleUnselect}
                            // unselectAuto={true}
                            validRange={this.handleValidRange}
                            // selectAllow={this.handleSelectAllow}
                            weekends={true}
                            // initialEvents={[{"title": "NKKJ", "start": "2023-01-29", "end": "2023-01-30"}]}
                            // initialEvents={this.state.holidays}
                            events={this.state.holidays}
                            eventContent={this.renderEventContent}
                            editable={true}
                            dateClick={(info) => {
                                $(".day-highlight").removeClass("day-highlight");
                                $(this).addClass("day-highlight");
                                var selectedDate = info.dateStr;
                                this.setState({selectedDate: selectedDate});
                                console.log("date info", info)
                                console.log('Clicked on: ' + selectedDate);
                                // console.log('Coordinates: ' + info.jsEvent.pageX + ',' + info.jsEvent.pageY);
                                // console.log('Current view: ' + info.view.type);
                                // change the day's background color just for fun
                                // info.dayEl.style.backgroundColor = '#EBF8FB';
                                this.onClickDate(selectedDate);
                            }}
                            dayRender={this.handleDayRender
                                // (date, cell) => {
                                // setTimeout(() => {
                                    // var holidays = this.state.holidays
                                    // console.log("holidays", holidays)
                                    // var holidaysarr = []
                                    // await holidays.map((item) => {
                                    //     holidaysarr.push(item.holiday_date)
                                    // })
                                    // console.log("date to be set", new Date(holidaysarr[0]).getDate())
                                    // for(let i=0; i<holidaysarr.length; i++) {
                                    //     if (date.getDate() === new Date(holidaysarr[i]).getDate()){
                                    //         cell.css("background-color", "orange");
                                    //     }
                                    // }
                                // },1000)
                            // }
                            }
                            // eventDrop={this.handleEventDrop}
                            // eventClick={this.handleEventClick}
                            
                        />
                    </div>
                    <div className="card-body col-xl-6 col-lg-6" style={{ marginTop: "50px" }}>
                        <div id="myGrid" style={{width:"100%",height:"490px"}} className={"ag-theme-balham "}>    
                            <AgGridReact
                                modules={this.state.modules}
                                rowHeight={40}
                                columnDefs={columnwithDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowData={this.state.rowData}
                                enableCharts={false}
                                // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                onGridReady={this.onGridReady}
                                onGridState={this.onGridState}
                                frameworkComponents={this.state.frameworkComponents}
                                statusBar={this.state.statusBar}
                                sideBar={this.state.sideBar}
                                stopEditingWhenGridLosesFocus= {true}
                                paginationPageSize={this.state.paginationPageSize}
                                pagination={false}
                                gridOptions={{
                                    context: { componentParent: this }
                                }}
                                // components={this.state.components}
                                enableRangeSelection= {true}
                                onCellClicked={this.handleGridCellClick}
                                // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                            />
                        </div>
                    </div>
                </div>
                
            </div>

        
        {/* <button className="btn btn-success" onClick={this.formAssignHandler.bind(this)} style={{marginLeft:"91em"}}>Insert Exports</button> */}
            <div className={"slide-r "+(this.state.bulkslideholidays)} style={{overflow:"hidden"}}>
                <h3 className="subH">Bulk Holidays Upload</h3>
                <div className="slide-r-body" style={{position:"relative"}}>
                
                    <div className="container-fluid">
                        <form method="POST" id="upformholidays" className="theme-form" onSubmit={this.uploadBulkHolidayFormHandler}>
                        {localStorage.getItem("user_type") == "DISTRIBUTOR" ? "" : 
                        <div className="form-group mt-20p">
                            <label>Select Distributor: </label>
                            <Select 
                                placeholder={"All"} 
                                isMulti={true} 
                                id="distributorCode"
                                // value={this.state.location} 
                                className="border-radius-0" 
                                onChange={this.onSelectBulkDistributors.bind(this)} style={{borderRadius:"0px"}} 
                                options={this.distributoroptions()} required />
                        </div>
                        }
                        <div className="form-group mt-20p">
                            <label className="">Upload File</label> 
                            <input type="file" name="uploadHolidayFile" id="bulkUploadHolidaysID" onChange={this.changeHolidayFileHandler}  className="form-control" required  />
                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn btn-success">Submit</button>
                            <button type="button" onClick={this.resetHolidayUpload.bind(this)} className="btn btn-default">Cancel</button>
                        </div>
                        </form>
                        <div className="form-group">
                            <p style={{color:"#ff0000"}}>*Note: All fields related to Manual uploads are mandatory and Should not be empty.</p>
                            <p><b>Bulk Upload rules:</b></p>

                            <p>All field headers must be same as the Headers given in template.</p>
                            <p ><b style={{color:"#ff0000"}}>Holiday Date Format :</b> DD/MM/YYYY</p>
                        
                        </div>
                        <div className="form-group">
                            <a className="btn btn-primary" href={require('../../assets/json/sample_holidays.csv')} target="_blank">Sample Template</a>
                        </div>
                        <div className="form-group">
                            {/* <a className="btn btn-primary" href={require('../../assets/json/Bulk_tickets_update_file.csv')} target="_blank">Sample Template</a> */}
                        
                        </div>		
                    </div>
                </div>
            </div>
            <div className={"slide-r "+(this.state.bulkslideslots)} style={{overflow:"hidden"}}>
                <h3 className="subH">Bulk Slots Upload</h3>
                <div className="slide-r-body" style={{position:"relative"}}>
                
                    <div className="container-fluid">
                        <form method="POST" id="upformslots" className="theme-form" onSubmit={this.uploadBulkSlotsFormHandler}>
                        {/* <div className="form-group mt-20p">
                            <label>Select Distributor: </label>
                            <Select 
                            placeholder={"All"} 
                            isMulti={true} 
                            id="distributorCode"
                            // value={this.state.location} 
                            className="border-radius-0" 
                            onChange={this.onSelectBulkDistributors.bind(this)} style={{borderRadius:"0px"}} 
                            options={this.distributoroptions()} required />
                        </div> */}
                        <div className="form-group mt-20p">
                            <label className="">Upload File</label> 
                            <input type="file" name="uploadSlotsFile" id="bulkUploadSlotsID" onChange={this.changeSlotsFileHandler}  className="form-control" required  />
                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn btn-success">Submit</button>
                            <button type="button" onClick={this.resetSlotsUpload.bind(this)} className="btn btn-default">Cancel</button>
                        </div>
                        </form>
                        <div className="form-group">
                            <p style={{color:"#ff0000"}}>*Note: All fields related to Manual uploads are mandatory and Should not be empty.</p>
                            <p><b>Bulk Upload rules:</b></p>

                            <p>All field headers must be same as the Headers given in template.</p>
                            <p ><b style={{color:"#ff0000"}}>Date Format :</b> DD/MM/YYYY</p>
                    
                        </div>
                        <div className="form-group">
                            <a className="btn btn-primary" href={require('../../assets/json/sample_upload_timeslots_data.csv')} target="_blank">Sample Template</a>
                        </div>
                        <div className="form-group">
                            {/* <a className="btn btn-primary" href={require('../../assets/json/Bulk_tickets_update_file.csv')} target="_blank">Sample Template</a> */}
                        
                        </div>		
                    </div>
                </div>
            </div>
            <div className={"dataLoadpage " +(this.state.loadshow)}>
            </div>
            <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
            </div>
            <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
        
            <span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span>
            <Modal open={usermanualmodal} onClose={this.onCloseUserManualModal.bind(this)} styles={modalStyles}>
                <div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
                    <h5 className="model-title">Holiday Calendar</h5>
                    <div className="col-xl-12 col-lg-12 mt-20p">
                        <div className="col-xl-12 col-lg-12">
                            This screen is available specifically for TNP department mentioning the list of holidays for mentioned financial year. The list can be viewed either in Grid view or in Calendar View. If any changes are to be done it can be done through either add holiday option for single addition or through bulk update option for multiple additions
                        </div>
                        
                        <div className="col-xl-12 col-lg-12">	
                            Image Here
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
  }
}

function getDateWithoutTime(dt)
{
  dt.setHours(0,0,0,0);
  return dt;
}

function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		mask:'39-19-9999',
		format:'d-m-Y',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd",
        changeMonth: true,
        changeYear: true
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};
