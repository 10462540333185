/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import $ from 'jquery';
import _ from "lodash";

import axios from 'axios';

import Grid from '../layouts/gridUpdateComponent';
import DateFormater from '../layouts/dateComponent';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getHyphenDDMMMYYYYHHMM } from '../common/utils';
import Modal from 'react-responsive-modal';
import Select from 'react-select';
import { verifyextcsv } from "../common/verifyext";

import CSVFileValidator from 'csv-file-validator'
var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");


export default class ManageCustomerArea extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pageTitle: "Manage Areas",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            departmentCode: null,
            currentDepartmentTitle: null,
            modules: AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            statusBar: {
                statusPanels: [
                    {
                        statusPanel: "agTotalAndFilteredRowCountComponent",
                        align: "left"
                    },
                    {
                        statusPanel: "agTotalRowCountComponent",
                        align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            paginationPageSize: 200,
            sideBar: {
                toolPanels: [
                    {
                        id: "columns",
                        labelDefault: "Columns",
                        labelKey: "columns",
                        iconKey: "columns",
                        toolPanel: "agColumnsToolPanel"
                    },
                    {
                        id: "filters",
                        labelDefault: "Filters",
                        labelKey: "filters",
                        iconKey: "filter",
                        toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            rowData: null,
            uploadDivWidth: '0%',
            sliderTranslate: '',
            showDiv: 'show-n',
            uploadFile: '',
            truck_no: '',
            transporter_code: '',
            dept_code: '',
            file: '',
            columnWithDynamicDefs: [],
            slotData: [],
            alltransporters: [],
            alltransporter_codes: [],
            areaDeliveryPersonMap: {},
            areaDeliveryMobileNoMap: {},
            areaSlotDataMap: {},
            consigneesMap: {},
            areaSlotMap: {},
            areaStatusMap: {},
            allDepts: [],
            csvmessage: '',
            csverrmessage: '',
            alertshow: 'fade',
            alertmg: 'show-n',
            alerterrmg: 'show-n',
            alerterrshow: 'fade',
            loadshow: 'show-n',
            overly: 'show-n',
            alert: null,
            show: false,
            basicTitle: '',
            basicType: "default",
            usermanualmodal: false,
            csvcontent: [],
            selectedFilter: { value: "Hours", label: "Hours" }

        }
    }

    getCurrentDepartmentCode(pathToMatch = null) {
        let department = null;
        let departmentName = null;
        switch (pathToMatch) {
            case "/manage/sndtrucks":
                department = 'SNDG';
                departmentName = " (Sales and Dispatch) ";
                break;
            case "/manage/prttrucks":
                department = 'LOG-PRT';
                departmentName = " (Spare Parts) ";
                break;
            case "/manage/tnptrucks":
                department = 'LOG-TNP';
                departmentName = " (Train and Production) ";
                break;
            case "/manage/sctrucks":
                department = 'LOG-SC';
                departmentName = " (Supply Chain) ";
                break;
            default:
                console.log("IN getDepartmentFilter, default, params = ", pathToMatch);
        }
        this.setState({
            currentDepartmentTitle: departmentName
        });
        return department;
    }
    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
            } else {
                console.log("GA disabled...");
            }
        } catch (error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    componentDidMount() {
        if (googleAnalytics.page.enableGA) {
            this.logPageView();
        }

        // this.setState({
        //     loadshow: 'show-m',
        //     overly: 'show-m'
        // });
        console.log("user_type", localStorage.getItem('user_type'));
        if (localStorage.getItem('user_type') == "DISTRIBUTOR") {
            var consigneeCode = localStorage.getItem('consignee_code')
            this.setState({
                consigneeCode: consigneeCode
            })
        };
        // get department code to filter records by department
        let currentDepartmentCode = this.getCurrentDepartmentCode(this.props.match.path);
        // console.log('currentDepartmentCode',currentDepartmentCode)
        this.setState({
            departmentCode: currentDepartmentCode
        });

        if (localStorage.getItem('user_type') == "DISTRIBUTOR") {
            var params = {
                consigneeCode: consigneeCode
            }
        } else {
            params = {}
        }
        var areaSlotDataMap = {}
        var customerarea_records = []
        redirectURL.post("/masters/consignees", params).then((res) => {
            var consigneesMap = {}
            var bufferPeriodMap = {}
            console.log("res data", res.data)
            var filteredConsignees = res.data.reduce((acc, each)=>{
                if(localStorage.getItem('user_type') === "NAGPURORGUSER" && ["Nagpur", "NAGPUR"].includes(each.consignee_city)){
                     acc.push(each.consignee_code)
                } else if(localStorage.getItem('user_type') === "NASIKORGUSER" && ["NASIK", "Nasik", "NASHIK", "Nashik"].includes(each.consignee_city)){
                    acc.push(each.consignee_code)
                } else if(localStorage.getItem('user_type') === "SOLAPURORGUSER" && ["Solapur"].includes(each.consignee_city)){
                    acc.push(each.consignee_code)
                } else if(localStorage.getItem('user_type') === "INDOREORGUSER" && ["Indore"].includes(each.consignee_city)){
                    acc.push(each.consignee_code)
                } else if(localStorage.getItem('user_type') === "AHMEDABADORGUSER" && ["Ahmedabad"].includes(each.consignee_city)){
                    acc.push(each.consignee_code)
                } else if (localStorage.getItem('user_type') === "DISTRIBUTOR") {
                    acc.push(each.consignee_code)
                }
                return acc
            },[])

            // console.log("filteredConsignees",filteredConsignees);
            if(res.data && Array.isArray(res.data)){
                res.data.map((item) => {
                    consigneesMap[`${item.consignee_code}`] = item.consignee_name
                    bufferPeriodMap[`${item.consignee_code}`] = item.buffer_period_in_hrs
                })
			}
            var bufferPeriod = bufferPeriodMap[`${consigneeCode}`]
            this.setState({
                consigneesMap: consigneesMap,
                bufferPeriodMap: bufferPeriodMap,
                bufferPeriod: bufferPeriod,
            })
            redirectURL.post('/masters/deliveryboy', params).then((response) => {
                var areaDeliveryPersonMap = {}
                var areaDeliveryMobileNoMap = {}
                response.data.map((item) => {
                    areaDeliveryPersonMap[`${item.customer_area_id}`] = item.delivery_person_name
                    areaDeliveryMobileNoMap[`${item.customer_area_id}`] = item.delivery_person_mobile_no
                })
                this.setState({
                    areaDeliveryPersonMap: areaDeliveryPersonMap,
                    areaDeliveryMobileNoMap: areaDeliveryMobileNoMap
                })
                
                redirectURL.post('/masters/timeslots', params).then((res) => {
                    // var areaSlotMap = {};
                    // res.data.map((item) => {
                    //     var timeSlotArr = []
                    //     item.slot_data.map((e) => {
                    //         var timeSlot = {};
                    //         var fromTime = moment.parseZone(e.from_time).format("HH:mm");
                    //         var toTime = moment.parseZone(e.to_time).format("HH:mm");
                    //         timeSlot[`${fromTime} - ${toTime}`] = {
                    //             "delivery_capacity": e.delivery_capacity,
                    //             "amount": e.amount,
                    //             "status": e.slot_status
                    //         };
                    //         timeSlotArr.push(timeSlot)
                    //     })
                    //     areaSlotMap[`${item.slot.customer_area_id}`] = timeSlotArr;
                    // })
                    // console.log("areaSlotMap", areaSlotMap);
                    // this.setState({
                    //     areaSlotMap: areaSlotMap
                    // })
                    var columnWithDynamicDefs = []
                    var slotData = res.data;
                    for (const item of slotData) {
                        console.log("slotData item", item)
                        columnWithDynamicDefs.push({
                            headerName: `${item.slot_start} - ${item.slot_end}`,
                            headerClass: ["cellTopHeaderTemplate14", "cellstylegridG"],
                            field: "",
                            cellClass: ["cellTopHeaderTemplate16", "cellstylegridG"],
                            width: 300,
                            chartDataType: "excluded",
                            children: [
                                {
                                    headerName: "Delivery Capacity",
                                    headerClass: ["cellstylegridG"],
                                    field: 'delivery_capacity',
                                    width: 100,
                                    // cellClass:["cellstylegridG"],
                                    editable: true,
                                    valueGetter: param => {
                                        // console.log("param", param)
                                        return param.data[`${item.slot_start} - ${item.slot_end}`]['delivery_capacity']
                                    },
                                    valueSetter: param => {
                                        console.log("param.data", param.data)
                                        const oldValue = param.oldValue
                                        const newValue = param.newValue
                                        if (oldValue !== newValue) {
                                            param.data[`${item.slot_start} - ${item.slot_end}`]['delivery_capacity'] = newValue;
                                            param.data.isChanged = 1;
                                            param.data.slotDeliveryCapChanged = 1;
                                        }
                                    },

                                },
                                {
                                    headerName: "Cost (Rs)",
                                    headerClass: ["cellstylegridG"],
                                    field: 'amount',
                                    width: 100,
                                    editable: true,
                                    // cellClass:["cellstylegridG"],
                                    valueGetter: param => {
                                        return param.data[`${item.slot_start} - ${item.slot_end}`]['cost']
                                    },
                                    valueSetter: param => {
                                        console.log("param.data", param.data)
                                        const oldValue = param.oldValue
                                        const newValue = param.newValue
                                        if (oldValue !== newValue) {
                                            param.data[`${item.slot_start} - ${item.slot_end}`]['cost'] = newValue;
                                            param.data.isChanged = 1;
                                            param.data.slotCostChanged = 1;
                                        }
                                    },
                                },
                                {
                                    headerName: "Status",
                                    headerClass: ["cellstylegridG"],
                                    field: 'slot_status',
                                    width: 100,
                                    // cellClass: function (params) {
                                    //     console.log("cellclass", params.data);
                                    //     if (params.data[`${item.slot_start} - ${item.slot_end}`]['status'] == "Active") {
                                    //         return 'bgColorSuccessMedium'
                                    //     }
                                    //     if (params.data[`${item.slot_start} - ${item.slot_end}`]['status'] == "Inactive") {
                                    //         return 'bgColorDangerMedium'
                                    //     }
                                    // },
                                    cellStyle : (params)=>{
                                        if(params.data[`${item.slot_start} - ${item.slot_end}`]['status'] === "Active"){
                                            return {background: "#00dea7"}
                                        }else{
                                            return {background: "#ff90a3"}
                                        }
                                    },
                                    editable: true,
                                    cellEditor: 'agRichSelectCellEditor',
                                    // cellClass:["cellstylegridG"],
                                    valueGetter: param => {
                                        return param.data[`${item.slot_start} - ${item.slot_end}`]['status']
                                    },
                                    cellEditorParams: param => {
                                        return {
                                            values: ['Inactive', 'Active']
                                        }
                                    },
                                    valueSetter: param => {
                                        console.log("param.data", param.data)
                                        const oldValue = param.oldValue
                                        const newValue = param.newValue
                                        if (oldValue !== newValue) {
                                            param.data[`${item.slot_start} - ${item.slot_end}`]['status'] = newValue;
                                            param.data.isChanged = 1;
                                            param.data.slotStatusChanged = 1;
                                        }
                                    },
                                },
                            ]
                        })
                    }
                    this.setState({
                        columnWithDynamicDefs: columnWithDynamicDefs,
                        slotData: slotData
                    })
                    console.log("slotData", slotData);
                    redirectURL.post('/masters/customerarea', params)
                        .then((response) => {

                            var customerarea_records = JSON.parse(JSON.stringify(response)).data;
                            // var consigneesMap = this.state.consigneesMap
                            console.log('records_329', customerarea_records);
                            // if (this.state.departmentCode) {
                            // 	records = records.filter(rec => rec.dept_code === this.state.departmentCode);
                            // }
                            redirectURL.post("/masters/areastatus", params).then((res) => {
                                var areaStatusMap = res.data
                                console.log("areastatus", areaStatusMap)

                                var recordsarr = []
                                redirectURL.post("/masters/areatimeslots", params).then(async (res) => {
                                    areaSlotDataMap = res.data
                                    console.log("areaSlotDataMap", areaSlotDataMap)
                                    if (customerarea_records !== undefined && customerarea_records !== "" && customerarea_records !== null) {
                                        if (customerarea_records.length > 0) {
                                            console.log("final_customerarea_records", customerarea_records)
                                            try {
                                                customerarea_records = await customerarea_records.filter((each) => {
                                                    if(filteredConsignees.includes(each.consignee_code)){
                                                        return each;
                                                    }                                                    
                                                })

                                                console.log("customerarea_records filtered",customerarea_records);

                                                var final_otpt = await customerarea_records.map(async (item) => {
                                                    // console.log("item", item)
                                                    // if (item.is_active == 0) {
                                                    // 	item.status = "Inactive";
                                                    // }

                                                    // if (item.is_active == 1) {
                                                    // 	item.status = "Active";
                                                    // }
                                                    var consigneesarr = []
                                                    if(item.consignee_code !== "" && item.consignee_code !== undefined)
                                                    {
                                                        // item.consignee_codes.map((e) => {
                                                        //     consigneesarr.push(consigneesMap[`${e}`])
                                                        // })
                                                        item.consignee_name = consigneesMap[`${item.consignee_code}`]
                                                    }
                                                    
                                                    item.delivery_person_name = areaDeliveryPersonMap[`${item.customer_area_id}`]
                                                    item.delivery_person_mobile_no = areaDeliveryMobileNoMap[`${item.customer_area_id}`]
                                                    item.area_status = areaStatusMap[`${item.customer_area_id}`]
                                                    // if (areaSlotMap.hasOwnProperty(`${item.customer_area_id}`)){
                                                    //     areaSlotMap[`${item.customer_area_id}`].map((e) => {
                                                    //         item[`${Object.keys(e)[0]}`] = e[`${Object.keys(e)[0]}`]
                                                    //     })
                                                    // }
                                                    console.log("here: ",areaSlotDataMap[`${item.customer_area_id}`])
                                                    if(areaSlotDataMap[`${item.customer_area_id}`] != "" && areaSlotDataMap[`${item.customer_area_id}`] != undefined)
                                                    {
                                                        let otpt = await areaSlotDataMap[`${item.customer_area_id}`].map((slot) => {
                                                            console.log("slotofareas", slot)
                                                            item[`${Object.keys(slot)[0]}`] = slot[`${Object.keys(slot)[0]}`]
                                                        })
                                                    
                                                        Promise.all([otpt]).then(() => {
                                                            recordsarr.push(item);
                                                            console.log("recordsarr", recordsarr)
    
                                                        })
                                                    }
                                                    

                                                })
                                                Promise.all([final_otpt]).then(() => {
                                                    this.setState({
                                                        areaSlotDataMap: areaSlotDataMap,
                                                        areaStatusMap: areaStatusMap,
                                                        rowData: recordsarr,
                                                        loadshow: 'show-n',
                                                        overly: 'show-n'
                                                    });
                                                })
                                            }

                                            catch (e) {
                                                console.log("error",e)
                                            }


                                        }
                                    }


                                })
                            })
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }).catch(function (err) {
                    console.log(err)
                })

            })
        })

        /*Get All Transporters from Trucks table */
        // redirectURL.post('/masters/getAllTransporters')
        // 	.then((response) => {
        // 		//console.log("/masters/getAllTransporters = ",response.data);
        // 		var all_transporters = [];
        // 		var transporter_codes = []
        // 		for (var rec in response.data) {
        // 			all_transporters.push({ "transporter_code": response.data[rec].transporter_code, "transporter_name": response.data[rec].transporter_name })
        // 			transporter_codes.push(response.data[rec].transporter_code + "_" + response.data[rec].transporter_name);
        // 		}
        // 		//console.log("/masters/getAllTransporters, all_transporters, transporter_codes = ", all_transporters, transporter_codes);
        // 		this.setState({
        // 			alltransporters: all_transporters,
        // 			alltransporter_codes: transporter_codes
        // 		});
        // 	});

        // /*Get All Departments Distinct from Trucks table*/
        // redirectURL.post('/masters/departments')
        // 	.then((response) => {
        // 		//console.log("/masters/departments = ",response.data);
        // 		this.setState({ allDepts: response.data });
        // 	});

    };

    /*Alert Popups*/
    closeAlert = () => {
        this.setState({
            show: false,
            loadshow: 'show-n',
            overly: 'show-n'
        });
    }

    onRecieveInput = (value) => {
        this.setState({
            alert: (
                <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
                    You wrote: {value}
                </SweetAlert>
            )
        });
    }

    buttonExample = (type) => {
        switch (type) {
            case 'custom':
                this.setState({
                    alert: (
                        <SweetAlert
                            custom
                            showCancel
                            confirmBtnText="Yes"
                            cancelBtnText="No"
                            confirmBtnBsStyle="primary"
                            cancelBtnBsStyle="default"
                            customIcon="thumbs-up.jpg"
                            title="Do you like thumbs?"
                            onConfirm={this.hideAlert}
                            onCancel={this.hideAlert}
                        >
                            You will find they are up!
                        </SweetAlert>
                    )
                });
                break;
            case 'input':
                this.setState({
                    alert: (
                        <SweetAlert
                            input
                            showCancel
                            cancelBtnBsStyle="default"
                            title="An input!"
                            placeHolder="Write something"
                            onConfirm={this.onRecieveInput}
                            onCancel={this.hideAlert}
                        >
                            Write something interesting:
                        </SweetAlert>
                    )
                });
                break;
            case 'password':
                this.setState({
                    alert: (
                        <SweetAlert
                            input
                            required
                            inputType="password"
                            title="Enter Password"
                            validationMsg="You must enter your password!"
                            onConfirm={this.hideAlert}
                        >
                            Write something interesting:
                        </SweetAlert>
                    )
                });
                break;
            default:
                this.setState({
                    alert: (
                        <SweetAlert
                            showCancel
                            confirmBtnText="Continue"
                            confirmBtnBsStyle={type}
                            type={type}
                            title="Are you sure?"
                            onCancel={this.hideAlert}
                            onConfirm={this.hideAlert}
                        >
                            You will not be able to recover this imaginary file!
                        </SweetAlert>
                    )
                });
                break;
        }
    }

    hideAlert = () => {
        this.setState({
            alert: null
        });
    }

    /*End*/

    handleSaveData = async () => {
        this.setState({
            loadshow: 'show-m',
            overly: 'show-m'
        })
        var slotData = this.state.slotData;
        console.log("slotData", slotData)
        const gridData = [];
        var filterGridData = [];
        const rowCount = this.gridApi.getDisplayedRowCount();
        for (let i = 0; i < rowCount; i++) {
            const rowData = this.gridApi.getDisplayedRowAtIndex(i).data;
            gridData.push(rowData);
        }
        filterGridData = gridData.filter(e => e.isChanged == 1);
        console.log("filterGridData", filterGridData)
        var deliveryParams;
        var customerAreaParams;
        var deliveryParamsList = [];
        var customerAreaParamsList = [];
        var slotParamsList = [];
        // let data = filterGridData.map(async (item) => {
        for (let item of filterGridData) {
            deliveryParams = {};
            deliveryParams.areaCode = item.customer_area_id;
            deliveryParams.area = item.customer_area;
            deliveryParams.consigneeCode = item.consignee_code;
            deliveryParams.deliveryPerson = item.delivery_person_name;
            deliveryParams.deliveryPersonMobileNo = item.delivery_person_mobile_no;

            if (item.delivery_person_name != undefined && item.delivery_person_mobile_no != undefined && item.delivery_person_name != "NA" && item.delivery_person_mobile_no != "NA") {

                if (parseInt(item["delivery_person_mobile_no"][0]) < 6 || parseInt(item["delivery_person_mobile_no"][0]) > 9 || item["delivery_person_mobile_no"].length !== 10) {
                    // console.log("check1")
                    // this.setState({
                    //     basicTitle: "Invalid Mobile No",
                    //     basicType: "danger",
                    //     show: true
                    // })
                    alert("Invalid Mobile No");

                } else {
                    if (item.deliveryMobileNoChanged == 1 || item.deliveryNameChanged == 1) {
                        deliveryParamsList.push(deliveryParams);
                    }
                }
            }
            customerAreaParams = {};
            customerAreaParams.areaCode = item.customer_area_id;
            customerAreaParams.consigneeCode = item.consignee_code;
            customerAreaParams.areaStatus = item.area_status;
            if (item.areaStatusChanged == 1) {
                customerAreaParamsList.push(customerAreaParams);
            }
            
            if (item.area_status == "Active") {
                if (item.slotStatusChanged == 1 || item.slotCostChanged == 1 || item.slotDeliveryCapChanged == 1) {
                    slotData.map((slot) => {
                        if (Object.keys(item).includes(`${slot.slot_start} - ${slot.slot_end}`)) {
                            slotParamsList.push({
                                "consignee_code": item.consignee_code,
                                "customer_area_id": item.customer_area_id,
                                "slot_start": slot.slot_start,
                                "slot_end": slot.slot_end,
                                "delivery_capacity": item[`${slot.slot_start} - ${slot.slot_end}`]['delivery_capacity'],
                                "amount": item[`${slot.slot_start} - ${slot.slot_end}`]['cost'],
                                "slot_status": item[`${slot.slot_start} - ${slot.slot_end}`]['status']
                            })
                            // break;
                        }
                    })
                }
            }
        }
            
        // })
        // await Promise.all([data]).then(() => {
        if (deliveryParamsList != "" && deliveryParamsList != undefined && deliveryParamsList.length > 0) {
            // console.log("deliveryParamsList", deliveryParamsList)
            var params = {
                deliveryParamsList: deliveryParamsList
            }
            await redirectURL.post('/consignments/updateDeliveryBoyData', params).then((res) => {
                console.log("deliveryDataUpdateResponse", res.data)
                if (res.data.status !== "Success") {
                    this.setState({
                        basicTitle: res.data.message,
                        basicType : "warning",
                        show : true,
                        loadshow: 'show-n',
                        overly: 'show-n'
                    })
                } 
                else {
                    this.setState({      
                        basicTitle:"Updated Successfully.",
                        basicType : "success",
                        show : true,
                        loadshow: 'show-n',
                        overly: 'show-n'
                    })
                }
            })
        }
        if (customerAreaParamsList != "" && customerAreaParamsList != undefined && customerAreaParamsList.length > 0) {
            params = {
                customerAreaParamsList: customerAreaParamsList
            }
            await redirectURL.post('/consignments/updateCustomerAreaStatus', params).then((res) => {
                console.log("areaStatusUpdateResponse", res.data)
                if (res.data.status == "Success") {
                    this.setState({
                        basicTitle:`Updated successfully`,
                        basicType : "success",
                        show : true,
                        loadshow: 'show-n',
                        overly: 'show-n'
                    })
                } else {
                    this.setState({
                        basicTitle: "Something went wrong",
                        basicType : "warning",
                        show : true,
                        loadshow: 'show-n',
                        overly: 'show-n'
                    })
                }
            })
        }
        if (slotParamsList != "" && slotParamsList != undefined && slotParamsList.length > 0) {
            params = {
                slotParamsList: slotParamsList
            };
            console.log("slotParamsList", params);
            await redirectURL.post("/consignments/updateSlotsDefinition", params).then((res) => {
                console.log("update slots response", res.data)
                if (res.data.status == "Success") {
                    this.setState({
                        loadshow: 'show-n',
                        overly: 'show-n',
                        basicTitle: `Updated Successfully.`,
                        basicType: "success",
                        show: true
                    })
                } else {
                    this.setState({
                        basicTitle: "Something went wrong",
                        basicType: "warning",
                        loadshow: 'show-n',
                        overly: 'show-n',
                        show: true
                    })
                }
            })
        }
        // })
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n'
        })
    }

    onShowUploadDiv = () => {
        console.log("Click Upload Button")
        this.setState({
            uploadDivWidth: '30%',
            sliderTranslate: "slider-translate",
            showDiv: 'show-m',
            csvmessage: '',
            csverrmessage: '',
        });
    }
    onCloseUploadDiv = () => {
        this.setState({
            uploadDivWidth: '0%',
            sliderTranslate: "",
            showDiv: 'show-n',
            uploadFile: '',
            file: '',
            csvmessage: '',
            csverrmessage: '',
        });
        document.getElementById("upform").reset();
    }
    resetUpload = () => {
        this.setState({
            uploadDivWidth: '0%',
            sliderTranslate: '',
            showDiv: 'show-n',
            uploadFile: '',
            file: ''
        });
        document.getElementById("upform").reset();
    }

    changeFileHandler = e => {
        // console.log("e.target.files[0] ",e.target.files[0].name)
        // var check = verifyextcsv(e.target.files[0].name);
        // console.log("Check ", check)
        // if(check == true)
        // {
        // 	this.setState({file:e.target.files[0]});
        // }
        // else{
        // 	this.setState({
        // 		//csverrmessage:response.data.status,
        // 		show: true, basicType:'danger', basicTitle:"Invalid file extension",
        // 		uploadFile:'',
        // 		file:''
        // 	});
        // }
        const config = {
            headers: [
                {
                    name: 'AreaCode',
                    inputName: 'customer_area_id',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                {
                    name: 'Status',
                    inputName: 'area_status',
                    required: true,
                    requiredError: function (headerName, rowNumber, columnNumber) {
                        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                    }
                },
                // {
                // 	name: 'consignee_code',
                // 	inputName: 'consignee_code',
                // 	required: true,
                // 	requiredError: function (headerName, rowNumber, columnNumber) {
                // 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                // 	}
                // },
                // {
                // 	name: 'consigner_code',
                // 	inputName: 'consigner_code',
                // 	required: true,
                // 	requiredError: function (headerName, rowNumber, columnNumber) {
                // 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                // 	}
                // },
                // {
                // 	name: 'consignee_address',
                // 	inputName: 'consignee_address',
                // 	required: true,
                // 	requiredError: function (headerName, rowNumber, columnNumber) {
                // 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                // 	}
                // },
                // {
                // 	name: 'consignee_city',
                // 	inputName: 'consignee_city',
                // 	required: true,
                // 	requiredError: function (headerName, rowNumber, columnNumber) {
                // 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                // 	}
                // },
                // {
                // 	name: 'consignee_state',
                // 	inputName: 'consignee_state',
                // 	required: true,
                // 	requiredError: function (headerName, rowNumber, columnNumber) {
                // 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                // 	}
                // },

                // {
                // 	name: 'truck_no',
                // 	inputName: 'truck_no',
                // 	required: true,
                // 	requiredError: function (headerName, rowNumber, columnNumber) {
                // 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                // 	}
                // },
                // {
                // 	name: 'transporter_code',
                // 	inputName: 'transporter_code',
                // 	required: true,
                // 	requiredError: function (headerName, rowNumber, columnNumber) {
                // 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                // 	}
                // },
                // {
                // 	name: 'dept_code',
                // 	inputName: 'dept_code',
                // 	required: true,
                // 	requiredError: function (headerName, rowNumber, columnNumber) {
                // 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                // 	}
                // },
                // {
                // 	name: 'load_capacity',
                // 	inputName: 'load_capacity',
                // 	required: true,
                // 	requiredError: function (headerName, rowNumber, columnNumber) {
                // 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
                // 	}
                // }
            ]
        }
        let data = CSVFileValidator(e.target.files[0], config).then((csvData) => {
            // console.log("csvdata", csvData.data)
            this.setState({
                csvcontent: csvData.data
            });
        })
            .catch(err => { })
        // console.log("e.target.files[0]",e.target.result);
        var contentList = []

        var out = new Promise(function (reject, resolve) {
            var reader = new FileReader();
            reader.onload = async function (e) {
                var contents = await e.target.result;
                resolve(contents);
            };
            var tt = reader.readAsText(e.target.files[0]);
        });
        // console.log(contentList);
        this.setState({
            file: e.target.files[0]
        });
        // console.log("e.target.files[0].type ", e.target.files[0]);

        if (e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
            // console.log(fileData);
            // console.log(typeof(fileData))

        }
        else {
            e.target.value = null;
            this.setState({
                uploadFile: '',
                show: true,
                basicType: 'danger',
                basicTitle: 'Please upload file having extensions .csv only.',
            });
        }

    }

    // changeSelectHandler(){
    // 	console.log("test");
    // }

    uploadFormHandler = (event) => {
        event.preventDefault();
        if (googleAnalytics.page.enableGA) {
            let pageTitle = this.state.pageTitle;
            if (this.state.currentDepartmentTitle) {
                pageTitle = pageTitle + " " + this.state.currentDepartmentTitle;

            }
            let eventOptions = {
                "category": pageTitle,
                "action": this.state.eventFormAction,
                "label": googleAnalytics.page.action.fileUploaded,
            }
            googleAnalytics.logEvent(eventOptions);
        }
        let csvContent = this.state.csvcontent
        let deptCode = localStorage.getItem("dept_code");
        if (deptCode.includes("SNDG")) {
            csvContent.forEach(e => {
                e.dept_code = "SNDG"
            })
        }
        var rparams = {
            uploadFile: this.state.file,
            userId: localStorage.getItem('userid'),
            csvcontent: csvContent
        }

        // var formData = new FormData(event.target);
        // formData.append('uploadFile',this.state.file);

        // console.log('rparams', rparams);
        // this.setState({
        // 	loadshow: 'show-m'
        // });
        let prevUrl = "/manage/uploadMasterTruckCsv";
        let url = "/manage/insertCustomerAreaStatusCsv"
        redirectURL.post(url, rparams,).then((response) => {
            // console.log(response);
            //console.log(response.data.status);
            document.getElementById("upform").reset();
            if (response.data.status == "Failure") {
                this.setState({
                    //csverrmessage:response.data.status,
                    show: true, basicType: 'danger', basicTitle: response.data.message,
                    uploadDivWidth: '0%',
                    sliderTranslate: '',
                    showDiv: 'show-n',
                    loadshow: 'show-n',
                    uploadFile: '',
                    file: ''
                });

            }
            else {
                this.setState({
                    show: true, basicType: 'success', basicTitle: response.data.message,
                    uploadDivWidth: '0%',
                    sliderTranslate: '',
                    showDiv: 'show-n',
                    uploadFile: '',
                    loadshow: 'show-n',
                    file: ''
                });
                setTimeout(() => {
                    var consigneeCode = this.state.consigneeCode;
                    if (localStorage.getItem('user_type') == "DISTRIBUTOR") {
                        var params = {
                            consigneeCode: consigneeCode
                        }
                    } else {
                        params = {}
                    }
                    redirectURL.post("/masters/consignees", params).then((res) => {
                        var consigneesMap = {}
                        var bufferPeriodMap = {}
                        console.log("res data", res.data)
                        if(res.data && Array.isArray(res.data)){
                            res.data.map((item) => {
                                consigneesMap[`${item.consignee_code}`] = item.consignee_name
                                bufferPeriodMap[`${item.consignee_code}`] = item.buffer_period_in_hrs
                            })
                        }
                        var bufferPeriod = bufferPeriodMap[`${consigneeCode}`]
                        this.setState({
                            consigneesMap: consigneesMap,
                            bufferPeriodMap: bufferPeriodMap,
                            bufferPeriod: bufferPeriod
                        })
                    })
                    var consigneesMap = this.state.consigneesMap;
                    var slotData = this.state.slotData;
                    var areaDeliveryPersonMap = this.state.areaDeliveryPersonMap;
                    var areaDeliveryMobileNoMap = this.state.areaDeliveryMobileNoMap;
                    redirectURL.post('/masters/customerarea', params)
                        .then((response) => {
                            var records = JSON.parse(JSON.stringify(response)).data;
                            //console.log(records);
                            if (this.state.departmentCode) {
                                records = records.filter(rec => rec.dept_code === this.state.departmentCode);
                            }
                            var recordsarr = []
                            var areaSlotDataMap = this.state.areaSlotDataMap;
                            var areaStatusMap = this.state.areaStatusMap;
                            if (records.length > 0) {
                                try {
                                    var final_otpt = records.map(async (item) => {

                                        var consigneesarr = []
                                        if(item.consignee_code !== "" && item.consignee_code !== undefined) {
                                            // item.consignee_codes.map((e) => {
                                            //     consigneesarr.push(consigneesMap[`${e}`])
                                            // })
                                            item.consignee_name = consigneesMap[`${item.consignee_code}`]
                                        }
                                        
                                        item.delivery_person_name = areaDeliveryPersonMap[`${item.customer_area_id}`]
                                        item.delivery_person_mobile_no = areaDeliveryMobileNoMap[`${item.customer_area_id}`]
                                        item.area_status = areaStatusMap[`${item.customer_area_id}`]

                                        if(areaSlotDataMap[`${item.customer_area_id}`] != "" && areaSlotDataMap[`${item.customer_area_id}`] != undefined) {
                                            let otpt = await areaSlotDataMap[`${item.customer_area_id}`].map((slot) => {
                                                console.log("slotofareas", slot)
                                                item[`${Object.keys(slot)[0]}`] = slot[`${Object.keys(slot)[0]}`]
                                            })
                                        
                                            Promise.all([otpt]).then(() => {
                                                recordsarr.push(item);
                                                console.log("recordsarr", recordsarr)

                                            })
                                        }
                                    })
                                } catch(err) {
                                    console.log("Error", err)
                                }
                            }
                            Promise.all([final_otpt]).then(() => {
                                console.log("recordsarr", recordsarr)
                                this.setState({
                                    rowData: recordsarr,
                                    loadshow: 'show-n',
                                    overly: 'show-n'
                                });
                            })

                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                }, 200)
            }

        }).catch(function (error) {
            console.log("error", error);
        });
    }
    onClickHideAll() {
        var uploadfilebulk = $("#uploadfile").val(null)
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n',
            slideuploadeway: '',
            sliderRouteTranslate: '',
            sliderSegmentTranslate: "",
            sliderTranslate: "",
            tabsliderTranslate: '',
            sliderTranslatesidebar: '',
            sliderForceCloseTranslate: '',
            sliderCommentTranslate: "",
            bulkPrioritySlide: "",
            sliderCreateConsignmentTranslate: "",
            sliderRso: "",
            uploadfilebulk: uploadfilebulk
        });

    }
    newFilter = (filterType, selOption) => {
		// console.log(filterType, selOption)
		this.setState({ [filterType]: selOption });
	}

    updateBufferPeriod = async (e) => {
        e.preventDefault();
        let selectedFilter = this.state.selectedFilter.value;
        let consigneeCode = this.state.consigneeCode;
        this.setState({
            loadshow: "show-m",
            overly: "show-m"
        })
        console.log("selectedFilter",selectedFilter)
        var inputBufferPeriod = $("#inputBufferPeriod").val();
        if (selectedFilter == "" || inputBufferPeriod == "") {
            this.setState({
                basicTitle: "Fields Should not be Empty",
                basicType: "danger",
                show: true,
                loadshow: "show-n",
                overly: "show-n"
            })
        } else if ((selectedFilter == "Hours" && inputBufferPeriod > 24) || (selectedFilter == "Days" && inputBufferPeriod > 1)) {
            this.setState({
                show: true,
                basicType: "danger",
                basicTitle: "Buffer Period cannot be more than 1 day"
            })
        }
        else {
            var params = {
                "selectedFilter": selectedFilter,
                "bufferPeriod": inputBufferPeriod,
                "distributor": consigneeCode
            }
            await redirectURL.post("/consignments/updateDistributorBufferPeriod", params).then((res) => {
                if (res.data.message == "Success") {
                    this.setState({
                        basicTitle: "Updated Successfully.",
                        basicType: "success",
                        show: true,
                        loadshow: "show-n",
                        overly: "show-n"
                    })
                    // window.location.reload();
                }
                else {
                    this.setState({
                        basicTitle: res.data.message,
                        basicType: "warning",
                        show: true,
                        loadshow: "show-n",
                        overly: "show-n"
                    })
                }
            })            
        }        
        this.setState({
            loadshow: 'show-n',
            overly: 'show-n'
        })
    }

    onCloseUserManualModal = () => {
        this.setState({ usermanualmodal: false });
    };

    onClickShowUserManaul = () => {
        this.setState({ usermanualmodal: true });
    };

    onGridReady = params => {
        this.gridApi = params.api;
        //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
        const columnDefs = params.columnApi.getAllColumns();
        columnDefs.forEach((column) => {
            if (column.getColId() == 'consignee_code' || column.getColId() == 'consignee_name' || column.getColId() == "customer_area_id" || column.getColId() == 'customer_area' || column.getColId() == 'delivery_person_name' || column.getColId() == 'delivery_person_mobile_no' || column.getColId() == 'area_status') {
                column.rowSpan = 2;
                params.columnApi.setColumnVisible(column, true);
            }
        })

        params.api.refreshHeader();
    };
    onGridState = () => {
        //console.log(this.gridApi);

        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();

        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
        * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();

        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }

    // onCellEdit = (params) => {
    //     // this.gridApi.setRowData(params.data)
    //     console.log("grid params", params);
    //     var rowNode = this.gridApi.getRowNode(params.data.rowIndex);
    //     console.log("rowNode",rowNode)
    //     rowNode.setDataValue('area_status', params.data.area_status);
    // }

    // onCellValueChanged(params) {
    //     if (params.value == 'Inactive') {
    //         params.node.setDataValue("color", "red");
    //     } else {
    //         params.node.setDataValue("color", "green");
    //     }
    //     params.api.refreshCells({ force: true });
    // }

    handleGridCellClick = (e) => {
        var rowId = e.data._id;
        var rowAreaCode = e.data.customer_area_id;
        var action = "";
        if (e.colDef.field == "mark_active") {
            action = "mark_active"
        }
        if (e.colDef.field == "mark_inactive") {
            action = "mark_inactive"
        }
        if (action !== "") {
            var params = {
                areaCode: rowAreaCode,
                action: action
            };

            redirectURL.post("/consignments/updateCustomerAreaStatus", params)
                .then((res) => {
                    if (res.data.message == "Success") {
                        this.setState({
                            basicTitle: "Updated Successfully.",
                            basicType: "success",
                            show: true
                        })
                        // window.location.reload();
                    }
                    else {
                        this.setState({
                            basicTitle: res.data.message,
                            basicType: "warning",
                            show: true
                        })
                    }
                })
        }
    }
    render() {
        const modalStyles = {
            width: '1300px !important',
        }
        const hideprtcolumns = (this.state.departmentCode == "LOG-PRT") ? false : true
        const { usermanualmodal } = this.state;
        const columnwithDefs = [
            {
                headerName: "Distributor Code",
                field: "consignee_code",
                width: 110,
                editable: false,
                filter: "agSetColumnFilter",
            },
            {
                headerName: "Distributor Name",
                field: "consignee_name",
                width: 150,
                editable: false,
                filter: "agSetColumnFilter",
            },
            {
                headerName: "Area ID",
                field: "customer_area_id",
                width: 90,
                editable: false,
                filter: "agSetColumnFilter",
            },
            {
                headerName: "Area Code",
                field: "customer_area",
                width: 160,
                editable: false,
                filter: "agSetColumnFilter",
            },
            {
                headerName: "Delivery Person",
                field: "delivery_person_name",
                width: 150,
                editable: true,
                filter: "agSetColumnFilter",
                valueSetter: param => {
                    console.log("param.data", param.data)
                    const oldValue = param.oldValue
                    const newValue = param.newValue
                    if (oldValue !== newValue) {
                        param.data.delivery_person_name = newValue;
                        param.data.isChanged = 1;
                        param.data.deliveryNameChanged = 1;
                    }
                },
            },
            {
                headerName: "Mobile No",
                field: "delivery_person_mobile_no",
                width: 120,
                editable: true,
                filter: "agSetColumnFilter",
                valueSetter: param => {
                    console.log("param.data", param.data)
                    const oldValue = param.oldValue
                    const newValue = param.newValue
                    if (oldValue !== newValue) {
                        param.data.delivery_person_mobile_no = newValue;
                        param.data.isChanged = 1;
                        param.data.deliveryMobileNoChanged = 1;
                    }
                },
                cellEditor: NumericCellEditor
            },

            // {
            // 	headerName: "Distributor Code",
            // 	field: "consignee_code",
            // 	width: 150,
            // 	editable: false,
            // 	filter: "agSetColumnFilter",
            // },
            // {
            // 	headerName: "Distributor Name",
            // 	field: "consignee_name",
            // 	width: 150,
            // 	editable: false,
            // 	filter: "agSetColumnFilter",
            // },
            // {
            // 	headerName: "Address",
            // 	field: "consignee_address",
            // 	width: 150,
            // 	editable: false,
            // 	filter: "agSetColumnFilter",
            // },
            // {
            // 	headerName: "City",
            // 	field: "customer_city",
            // 	width: 150,
            // 	editable: false,
            // 	filter: "agSetColumnFilter",
            // },
            // {
            // 	headerName: "State",
            // 	field: "customer_state",
            // 	width: 150,
            // 	editable: false,
            // 	filter: "agSetColumnFilter",
            // },
            {
                headerName: "Status",
                field: "area_status",
                width: 100,
                filter: "agSetColumnFilter",
                // editable : param =>{
                //     if (param.data.area_status !== "") return true
                //         return false 
                // },
                // cellClass: function (params) {
                //     if (params.data.area_status == "Active") {
                //         return 'bgColorSuccessMedium'
                //     }
                //     if (params.data.area_status == "Inactive") {
                //         return 'bgColorDangerMedium'
                //     }
                // },
                cellStyle : (params)=>{
                    if(params.data.area_status === "Active"){
                        return {background: "#00dea7"}
                    }else{
                        return {background: "#ff90a3"}
                    }
                },
                cellEditor: 'agRichSelectCellEditor',
                cellEditorParams: param => {
                    return {
                        values: ['Inactive', 'Active']
                    }
                },
                valueSetter: param => {
                    console.log("param.data", param.data)
                    const oldValue = param.oldValue
                    const newValue = param.newValue
                    if (oldValue !== newValue) {
                        param.data.area_status = newValue;
                        param.data.isChanged = 1;
                        param.data.areaStatusChanged = 1;
                    }
                },
            },
            // {
            //     headerName: "",
            //     field: "mark_active",
            //     width:50,
            //     filter: true,
            //     resizable: true,
            //     editable:true,
            //     cellRenderer: function (params) {
            //         var htmloption = '<i class="icofont icofont-check" style="font-size: 18px; margin-top: 6px;color: green; margin-left: 5px; font-weight: bold;"></i>';
            //         return htmloption
            //     }
            // },
            // {
            //     headerName: "",
            //     field: "mark_inactive",
            //     width:50,
            //     filter: true,
            //     resizable: true,
            //     editable:true,
            //     cellRenderer: function (params) {
            //         var htmloption = '<i class="icofont icofont-close-line" style="font-size: 18px; margin-top: 6px;color: red; margin-left: 5px; font-weight: bold;"></i>'
            //         return htmloption
            //     }
            // },
            // {
            // 	headerName: "Truck No",
            // 	field: "truck_no",
            // 	width: 150,
            // 	editable: false,
            // 	filter: "agSetColumnFilter",
            // },
            // {
            // 	headerName: "LSP Code",
            // 	field: "transporter_code",
            // 	width: 200,
            // 	cellEditor: "agRichSelectCellEditor",
            // 	cellEditorParams: {
            // 		values: this.state.alltransporter_codes,
            // 		cellRenderer: function (params) {
            // 			return params.value.split("_")[0]
            // 		}
            // 	},
            // 	valueSetter: function (params) {
            // 		//params.setValue('transporter_code') = params.newValue.split('_')[1]
            // 		//console.log(params.newValue)
            // 		params.data.transporter_name = params.newValue.split('_')[1];
            // 		params.data.transporter_code = params.newValue.split('_')[0];

            // 		return params.newValue.split('_')[0]
            // 	},
            // 	filter: "agSetColumnFilter"
            // },
            // {
            // 	headerName: "LSP Name",
            // 	field: "transporter_name",
            // 	width: 200,
            // 	editable: false,
            // 	filter: "agSetColumnFilter",

            // },
            // //   {
            // //   headerName: "Department Code",
            // //   field: "dept_code",
            // //   width: 120,
            // //     cellEditor: "agRichSelectCellEditor",
            // //     cellEditorParams: {
            // //   	  values: this.state.allDepts
            // //     },
            // //     filter: "agSetColumnFilter"
            // //
            // // },
            // {
            // 	headerName: "Actual GPS Provider",
            // 	field: "actual_lspuser",
            // 	width: 150,
            // 	editable: false,
            // 	filter: "agSetColumnFilter",

            // },
            // {
            // 	headerName: "Load Capacity",
            // 	field: "load_capacity",
            // 	width: 150,
            // 	filter: "agSetColumnFilter",
            // },
            // {
            // 	headerName: "Last Sample Time",
            // 	field: "timestamp",
            // 	width: 200,
            // 	editable: false,
            // 	valueGetter: function (params) {
            // 		if (params.data.timestamp != undefined) {
            // 			// console.log(new Date(params.data.timestamp));
            // 			return getHyphenDDMMMYYYYHHMM(params.data.timestamp);
            // 		}
            // 		else {
            // 			return "";
            // 		}

            // 	},
            // 	//   cellRenderer:'dateFormater',
            // 	//filter: "agDateColumnFilter",
            // 	comparator: dateComparator,
            // 	// filterParams: {
            // 	// 	browserDatePicker: true,
            // 	// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
            // 	// 	// provide comparator function
            // 	// 	comparator: function(filteredDate,cellValue,secondDate) {
            // 	// 		cellValue = cellValue.replace(/\//g,"-")
            // 	// 		if(cellValue != "" && cellValue != " "){
            // 	// 			cellValue = cellValue.split(" ")[0].split("-");
            // 	// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
            // 	// 			var cellDate = new Date(cellValue);
            // 	// 			if(filteredDate.getMonth() < 10){
            // 	// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
            // 	// 			}
            // 	// 			else{
            // 	// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
            // 	// 			}
            // 	// 			if(filterValue.split("-")[2].length < 2)
            // 	// 			{
            // 	// 				var temp = filterValue;
            // 	// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
            // 	// 			}
            // 	// 			var filteredDate = new Date(filterValue);
            // 	// 			cellDate = cellDate.getTime();
            // 	// 			filteredDate = filteredDate.getTime();
            // 	// 			// console.log(cellDate,filteredDate);
            // 	// 			if(cellDate === filteredDate)
            // 	// 			{
            // 	// 				return 0;
            // 	// 			}
            // 	// 			if(cellDate < filteredDate){
            // 	// 				return -1;
            // 	// 			}
            // 	// 			if(cellDate > filteredDate)
            // 	// 			{
            // 	// 				return 1;
            // 	// 			}
            // 	// 		}
            // 	// 	}
            // 	// },

            // },
            // {
            // 	headerName: "Last Packet Time",
            // 	field: "modified_date",
            // 	width: 200,
            // 	editable: false,
            // 	/*valueGetter:function(params){
            // 		var dsplit = params.data.last_gps_date.split("T");
            // 		var SSplit = dsplit[1].split(".");
            // 		var dateFormatIs = dsplit[0]+" "+SSplit[0]
            // 		return (dateFormatIs)
            // 	},*/
            // 	valueGetter: function (params) {
            // 		return getHyphenDDMMMYYYYHHMM(params.data.modified_date);
            // 	},
            // 	// cellRenderer:'dateFormater',
            // 	//filter: "agDateColumnFilter",
            // 	comparator: dateComparator,
            // 	// filterParams: {
            // 	// 	browserDatePicker: true,
            // 	// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
            // 	// 	// provide comparator function
            // 	// 	comparator: function(filteredDate,cellValue,secondDate) {
            // 	// 		cellValue = cellValue.replace(/\//g,"-")
            // 	// 		if(cellValue != "" && cellValue != " "){
            // 	// 			cellValue = cellValue.split(" ")[0].split("-");
            // 	// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
            // 	// 			var cellDate = new Date(cellValue);
            // 	// 			if(filteredDate.getMonth() < 10){
            // 	// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
            // 	// 			}
            // 	// 			else{
            // 	// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
            // 	// 			}
            // 	// 			if(filterValue.split("-")[2].length < 2)
            // 	// 			{
            // 	// 				var temp = filterValue;
            // 	// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
            // 	// 			}
            // 	// 			var filteredDate = new Date(filterValue);
            // 	// 			cellDate = cellDate.getTime();
            // 	// 			filteredDate = filteredDate.getTime();
            // 	// 			// console.log(cellDate,filteredDate);
            // 	// 			if(cellDate === filteredDate)
            // 	// 			{
            // 	// 				return 0;
            // 	// 			}
            // 	// 			if(cellDate < filteredDate){
            // 	// 				return -1;
            // 	// 			}
            // 	// 			if(cellDate > filteredDate)
            // 	// 			{
            // 	// 				return 1;
            // 	// 			}
            // 	// 		}
            // 	// 	}
            // 	// },
            // },
            // {
            // 	headerName: "Axle Type",
            // 	field: "axle_type",
            // 	width: 130,
            // 	editable: false,
            // 	filter: true,
            // 	hide: hideprtcolumns
            // },
            // {
            // 	headerName: "Truck Type",
            // 	field: "truck_type",
            // 	width: 130,
            // 	editable: false,
            // 	filter: true,
            // 	hide: hideprtcolumns
            // },
            // {
            // 	headerName: "Report Truck Type",
            // 	field: "report_truck_type",
            // 	width: 130,
            // 	editable: false,
            // 	filter: true,
            // 	hide: hideprtcolumns
            // },
            // {
            // 	headerName: "Status",
            // 	field: "status",
            // 	width: 90,
            // 	filter: true,
            // 	//singleClickEdit:true,
            // 	editable: true,
            // 	// cellRenderer: function(params){
            // 	// 	if(params.value == 1 || params.value == 'Active')
            // 	// 	{
            // 	// 		return 'Active';
            // 	// 	}
            // 	// 	else
            // 	// 	{
            // 	// 		return 'Inactive';
            // 	// 	}
            // 	// },
            // 	cellEditor: 'agRichSelectCellEditor',
            // 	cellEditorParams: {
            // 		cellRenderer: 'genderCellRenderer',
            // 		values: ['Active', 'Inactive']
            // 	}
            // },
            // {
            //   headerName: "Action",
            //   field: "_id",
            //   width: 100,
            //   filter: false,
            //   cellRenderer:'updateItem',
            //   cellRendererParams: {
            //         actionCall: "manage",
            //         actionMethod:"updateTruckRow"
            //     },
            // 	editable:false,
            // 	hide: true
            // }
            /*  {
                    headerName: "",
                    field: "_id",
                    width: 100,
                    cellRenderer:'statusChange',
                    cellRendererParams: {
                          actionCall: "manage",
                          actionMethod:"statusUpdate"
                      },
                      editable:false,
                        suppressCellFlash: true
                  }*/

        ];
        var finalColumns = [...columnwithDefs, ...this.state.columnWithDynamicDefs];
        console.log("finalColumns", finalColumns)
        return (
            <div className="container-fluid">
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                {this.state.alert}
                {localStorage.getItem('user_type') == "DISTRIBUTOR" ?
                    <div className="card col-xl-12">
                        <div className="row card-body">

                            <form className='row col-xl-12 col-lg-12' onSubmit={this.updateBufferPeriod}>
                                <div className='col-xl-3 col-lg-3'>
                                    <label>Select Buffer Period Type:</label>
                                    <Select
                                        value={this.state.selectedFilter}
                                        id="selectedFilter"
                                        options={[{ value: "Hours", label: "Hours" }, { value: "Days", label: "Days" }]}
                                        onChange={this.newFilter.bind(this, "selectedFilter")}
                                        required
                                    />
                                </div>
                                <div className='col-xl-3 col-lg-3'>
                                    <label className="reason">Buffer Period</label>
                                    <input type="text" name="buffer" defaultValue={this.state.bufferPeriod} autoComplete="off" class="form-control" id="inputBufferPeriod" />
                                </div>
                                <div className="form-group col-xl-3 col-lg-3" style={{ marginTop: "30px" }}>
                                    <button type="submit" className="btn btn-success">Set Buffer</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    : ""}

                <div className="row">
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">

                            <div className="card-header">
                                {localStorage.getItem('user_type') == "DISTRIBUTOR" ?
                                    <h5>
                                        <i className="icofont icofont-vehicle-delivery-van cus-i"></i>
                                        <span>{this.state.pageTitle} {this.state.currentDepartmentTitle}</span>
                                        <button type="button" onClick={this.handleSaveData.bind(this)} className="btn btn-info pull-right cs-btn"> <i className="icofont icofont-save"></i> Save</button>
                                        {/* <button type="button" onClick={this.onShowUploadDiv.bind(this)} className="btn btn-success pull-right cs-btn"> <i className="icofont icofont-upload-alt"></i> Upload CSV</button> */}
                                    </h5>
                                    : ""}
                            </div>
                            <div className="card-body col-xl-12 col-lg-12" style={{ marginTop: "20px" }}>
                                <div id="myGrid" style={{ width: "100%", height: "490px" }} className={"ag-theme-balham "}>
                                    {/*<div className={"dataLoad " +(this.state.loadshow)}>
                                        <img src={require("../../assets/icons/loader.gif")} />
                                    </div>
                                        */}

                                    {/* <Grid
                                        ashow={this.state.show}
                                        atype={this.state.basicType}
                                        atitle={this.state.basicTitle}
                                        actionCall={"manage"}
                                        actionMethod={"updateTruckRow"}
                                        table={"trucklocation"}
                                        rowData={this.state.rowData}
                                        columnwithDefs={columnwithDefs}

                                    /> */}
                                    <AgGridReact
                                        modules={this.state.modules}
                                        rowHeight={25}
                                        columnDefs={finalColumns}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={false}
                                        // autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        // onCellEditingStopped={this.onCellEdit}
                                        // onCellValueChanged={this.onCellValueChanged}
                                        // frameworkComponents={this.state.frameworkComponents}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        stopEditingWhenGridLosesFocus={true}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        // components={this.state.components}
                                        enableRangeSelection={true}
                                        // enableCellChangeFlash= {true}
                                        onCellClicked={this.handleGridCellClick}
                                    // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                
                <div className={"slide-r " + (this.state.sliderTranslate)} >

                    <div className="slide-r-title">
                        <h4>
                            Bulk Upload
                            <span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>

                        </h4>
                    </div>
                    <div className="slide-r-body" style={{ position: "relative" }}>
                        <div className={"dataLoad " + (this.state.loadshow)}>
                            <div style={{ position: "absolute", left: "38%" }}>
                                <div class="loader-box">
                                    <div class="loader-box">
                                        <div class="rotate dashed colored"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12 col-lg-12">
                            <div className={"col-xl-12 col-lg-12 alert alert-light uploadcscmsg " + (this.state.alerterrshow) + " " + (this.state.alerterrmg)}>{this.state.csverrmessage}</div>

                            <form id="upform" className="theme-form" onSubmit={this.uploadFormHandler}>

                                <div className="form-group mt-20p">
                                    <label className="">Upload File</label>
                                    <input type="file" name="uploadFile" onChange={this.changeFileHandler} className="form-control" required />
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="btn btn-success">Submit</button>
                                    <button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
                                </div>
                                <div className="form-group">
                                    <a className="btn btn-primary" href={require('../../assets/json/sample_upload_customer_area_status_data.csv')} target="_blank">Sample Template</a>
                                </div>
                            </form>
                            {/* <div className="form-group mt-50p">
			 					<a href={require('../../assets/json/truck_template.csv')} target="_blank" className="btn btn-info">Download Template</a>
								</div> */ }
                        </div>
                    </div>
                </div>

                <span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span>
                <Modal open={usermanualmodal} onClose={this.onCloseUserManualModal.bind(this)} styles={modalStyles}>
                    <div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                        <h5 className="model-title">Trucks</h5>
                        <div className="col-xl-12 col-lg-12 mt-20p">
                            <div className="col-xl-12 col-lg-12">
                                This screen consists of master file of the entire fleet list which serves a particular department for carrying the material along with its transporter and GPS service provider details. Each column is having filter option through which one can find particular details, make particular changes and update the master file. Various grid columns are described below:
                            </div>

                            <div className="col-xl-12 col-lg-12">

                                <table className="table table-border">
                                    <tr>
                                        <th>Column</th>
                                        <th>
                                            Remarks
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>
                                            Truck No
                                        </td>
                                        <td>
                                            Truck detail
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            LSP Code
                                        </td>
                                        <td>
                                            MSIL code provided for transporter
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            LSP Name
                                        </td>
                                        <td>
                                            Transporter Name
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Department Code
                                        </td>
                                        <td>
                                            Name of Department
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Actual GPS Provider
                                        </td>
                                        <td>
                                            GPS Service provider Name
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Last Sample Time
                                        </td>
                                        <td>
                                            Sample GPS data details
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Last Packet Time
                                        </td>
                                        <td>
                                            Last packet time details by service provider
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Status
                                        </td>
                                        <td>
                                            Current status of vehicle wheter actively used by dept or not
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Action
                                        </td>
                                        <td>
                                            update status of any changes made in master
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>

                                        </td>
                                        <td>

                                        </td>
                                    </tr>
                                </table>

                            </div>
                            <div className="col-xl-12 col-lg-12">
                                If user wants to update multiple vehicles, this can be done through bulk option available as shown in figure below:
                            </div>
                            <div className="col-xl-12 col-lg-12">
                                Image here
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>

        );
    }
}

function alltransporters() {

    /*Truck Transporters*/
    var arr = [];
    redirectURL.post('/masters/truckTransporters')
        .then((response) => {
            // console.log(response.data)
            return response.data;
        });

}

// function dateComparator(date1, date2) {
// 	// console.log("dateComparator");
// 	var date1Number = monthToComparableNumber(date1);
// 	var date2Number = monthToComparableNumber(date2);
// 	console.log(date1,date2);
// 	console.log(date1Number,date2Number);
// 	if (date1Number === null && date2Number === null) {
// 	  return 0;
// 	}
// 	if (date1Number === null) {
// 	  return -1;
// 	}
// 	if (date2Number === null) {
// 	  return 1;
// 	}
// 	return date1Number - date2Number;
//   }
// function monthToComparableNumber(date) {
// 	console.log(date);

// 	if (date === undefined || date === null) {
// 		return null;
// 	}
// 	if(date.search("T") == -1 )
// 	{
// 		if(date.length !== 19)
// 		{
// 			return null;
// 		}

// 	}

// 	var yearNumber = date.substring(0, 4);
// 	var monthNumber = date.substring(5,7);
// 	var dayNumber = date.substring(8,10);
// 	// console.log(yearNumber,monthNumber,dayNumber);
// 	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
// 	return result;
// }
function dateComparator(date1, date2) {
    // console.log("dateComparator");
    // console.log(date1.length);
    if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
        // console.log(date1,date2);
        var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
        date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
        var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
        date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
        // console.log(date1,date2);
        var date1Number = monthToComparableNumber(date1);
        var date2Number = monthToComparableNumber(date2);
        // console.log(date1Number,date2Number);
        if (date1Number === null && date2Number === null) {
            return 0;
        }
        if (date1Number === null) {
            return -1;
        }
        if (date2Number === null) {
            return 1;
        }
        return date1Number - date2Number;
    }
}
function monthToComparableNumber(date) {
    // console.log(date,date.length);
    if (date === undefined || date === null || date.length !== 16) {
        return null;
    }
    var yearNumber = date.substring(6, 10);
    var monthNumber = date.substring(3, 5);
    var dayNumber = date.substring(0, 2);
    // console.log(yearNumber,monthNumber,dayNumber);
    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    return result;
}

function getMonthNumber(monthName) {
    var months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May',
        'Jun', 'Jul', 'Aug', 'Sep',
        'Oct', 'Nov', 'Dec'
    ];
    var monthNum = months.indexOf(monthName) + 1;
    if (monthNum < 10) {
        monthNum = "0" + monthNum.toString();
    }
    return monthNum;
}

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr) || isCharDecimal(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};
