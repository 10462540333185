import React, { Component } from "react";
import * as Datetime from "react-datetime";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import CountUp from "react-countup";
import { AgGridReact } from "@ag-grid-community/react";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
var moment = require("moment");
var redirectURL = require("../redirectURL");

export default class feedback extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadshow: "show-n",
      overly: "show-n",
      userType: "",
      filterConsignees: [],
      startDate: moment
        .parseZone()
        .subtract(7, "days")
        .format("YYYY-MM-DD"),
      endDate: moment.parseZone().format("YYYY-MM-DD"),
      flArea: [{ value: "all", label: "All" }],
      flConsignee: [{ value: "all", label: "All" }],
      filterCustomerAreas: [],
      consignerAreas: {},
      show: false,
      basicTitle: "",
      basicType: "default",
      consigneeCode: "",
      questions: [],
      allData: [],
      statics: [],
      question1_yes: 0,
      question1_no: 0,
      question2_yes: 0,
      question2_no: 0,
      defaultColDef: {
        // sortable: true,
        filter: true,
        resizable: true,
        pivot: true,
        enableValue: true,
        enableRowGroup: false,
      },
      rowData: [],
      paginationPageSize: 50,
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      },
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      columnwithDefs: [
        {
          headerName: "Question",
          field: "question",
          width: 400,
        },
        {
          headerName: "Answer",
          field: "answer",
          width: 110,
        },
        {
          headerName: "Consignee Code",
          field: "consignee_code",
          width: 120,
        },
        {
          headerName: "Order No",
          field: "consignment_no",
          width: 90,
        },
        {
          headerName: "Delivery Person Name",
          field: "delivery_person_name",
          width: 180,
        },
        {
          headerName: "Delivery Person Contact",
          field: "delivery_person_mobile_no",
          width: 200,
        },
      ],
    };
  }

  componentDidMount() {
    let consigneeCode = localStorage.getItem("consignee_code");
    this.setState({
      consigneeCode: consigneeCode,
      loadshow: "show-m",
    });
    let userType = localStorage.getItem("user_type");
    if (userType == "DISTRIBUTOR") {
      var params = {
        consigneeCode: consigneeCode,
      };
    } else if (userType == "NAGPURORGUSER") {
      params = {
        customerCity: ["Nagpur", "NAGPUR"],
      };
    } else if (userType == "NASIKORGUSER") {
      params = {
        customerCity: ["Nasik", "NASIK", "Nashik", "NASHIK"],
      };
    } else if (userType == "SOLAPURORGUSER") {
      params = {
        customerCity: ["Solapur"],
      };
    } else if (userType == "INDOREORGUSER") {
      params = {
        customerCity: ["Indore"],
      };
    } else if (userType == "AHMEDABADORGUSER") {
      params = {
        customerCity: ["Ahmedabad"],
      };
    } else {
      params = {};
    }

    redirectURL
      .post("/consignments/getConsignmentsNewFilterKeys", params)
      .then((resp) => {
        var cities = resp.data.cities;
        let consignerAreas = resp.data.consignerAreas;
        this.setState({
          consignerAreas: consignerAreas,
        });
        var filterCities = [];
        if (cities != "" && cities != undefined && cities.length > 0) {
          cities.map(function(c) {
            filterCities.push({
              label: c,
              value: c,
            });
          });
        }
        var states = resp.data.states;
        var filterStates = [];
        if (states != "" && states != undefined && states.length > 0) {
          states.map(function(s) {
            filterStates.push({
              label: s,
              value: s,
            });
          });
        }
        // var clusters = resp.data.clusters;
        // var filterClusters = [];
        // if (clusters.length > 0) {
        // 	clusters.map(function (cl) {
        // 		filterClusters.push({
        // 			label: cl,
        // 			value: cl,
        // 		});
        // 	});
        // }
        var consignmentAreas = resp.data.consignmentAreas;
        console.log("consignmentAreas", consignmentAreas);
        var filterConsignmentAreas = [];
        if (
          consignmentAreas != "" &&
          consignmentAreas != undefined &&
          consignmentAreas.length > 0
        ) {
          consignmentAreas.map(function(cl) {
            filterConsignmentAreas.push({
              label: cl,
              value: cl,
            });
          });
        }

        var customerAreas = resp.data.customerAreas;
        var filterCustomerAreas = [];
        if (
          customerAreas != "" &&
          customerAreas != undefined &&
          customerAreas.length > 0
        ) {
          customerAreas.map((area) => {
            filterCustomerAreas.push({
              label: area.customer_area,
              value: area.customer_area_id,
            });
          });
          filterCustomerAreas.unshift({ value: "all", label: "All" });
        }

        var consignees = resp.data.consignees;
        var filterConsignees = [];
        if (
          consignees != "" &&
          consignees != undefined &&
          consignees.length > 0
        ) {
          consignees.map((consignee) => {
            filterConsignees.push({
              label: consignee.consignee_name,
              value: consignee.consignee_code,
            });
          });
          filterConsignees.unshift({ value: "all", label: "All" });
        }

        var consigneeAddressMap = {};
        var consigneeContactNoMap = {};
        var consigneeEmergencyNoMap = {};
        if (
          consignees != "" &&
          consignees != undefined &&
          consignees.length > 0
        ) {
          consignees.map((consignee) => {
            consigneeAddressMap[`${consignee.consignee_code}`] =
              consignee.consignee_address;
            consigneeContactNoMap[`${consignee.consignee_code}`] = [
              consignee.consignee_contact_no_1,
              consignee.consignee_contact_no_2,
            ];
            consigneeEmergencyNoMap[`${consignee.consignee_code}`] = [
              consignee.consignee_emergency_no_1,
              consignee.consignee_emergency_no_2,
            ];
          });
        }

        var consigners = resp.data.consigners;
        var filterConsigners = [];
        if (
          consigners != "" &&
          consigners != undefined &&
          consigners.length > 0
        ) {
          consigners.map((consigner) => {
            filterConsigners.push({
              label: consigner.consigner_name,
              value: consigner.consigner_code,
            });
          });
          filterConsigners.unshift({ value: "all", label: "All" });
        }

        // var transporters = resp.data.transporters;
        // var filterTransporters = [];
        // if (transporters.length > 0) {
        // 	transporters.map(function (t) {
        // 		filterTransporters.push({
        // 			label: t,
        // 			value: t,
        // 		});
        // 	});
        // }
        this.setState(
          {
            filterCities: filterCities,
            filterStates: filterStates,
            // filterClusters: filterClusters,
            filterConsignmentAreas: filterConsignmentAreas,
            filterCustomerAreas: filterCustomerAreas,
            filterConsignees: filterConsignees,
            filterConsigners: filterConsigners,
            consigneeAddressMap: consigneeAddressMap,
            consigneeContactNoMap: consigneeContactNoMap,
            consigneeEmergencyNoMap: consigneeEmergencyNoMap,
            // filterTransporters: filterTransporters,
          },
          () => {
            // console.log(this.state.filterConsignees,"filterConsignees");
            // console.log(this.state.filterCustomerAreas,"filterCustomerAreas");
            this.getFeedbackForFilters();
          }
        );
      });
    // redirectURL
    //   .post("/consignments/getConsignmentsNewFilterKeys", params)
    //   .then((resp) => {
    //     var cities = resp.data.cities;
    //     var filterCities = [];
    //     if (cities != "" && cities != undefined && cities.length > 0) {
    //       cities.map(function(c) {
    //         filterCities.push({
    //           label: c,
    //           value: c,
    //         });
    //       });
    //     }
    //     var states = resp.data.states;
    //     var filterStates = [];
    //     if (states != "" && states != undefined && states.length > 0) {
    //       states.map(function(s) {
    //         filterStates.push({
    //           label: s,
    //           value: s,
    //         });
    //       });
    //     }
    //     // var clusters = resp.data.clusters;
    //     // var filterClusters = [];
    //     // if (clusters.length > 0) {
    //     // 	clusters.map(function (cl) {
    //     // 		filterClusters.push({
    //     // 			label: cl,
    //     // 			value: cl,
    //     // 		});
    //     // 	});
    //     // }
    //     var consignmentAreas = resp.data.consignmentAreas;
    //     var filterConsignmentAreas = [];
    //     if (
    //       consignmentAreas != "" &&
    //       consignmentAreas != undefined &&
    //       consignmentAreas.length > 0
    //     ) {
    //       consignmentAreas.map(function(cl) {
    //         filterConsignmentAreas.push({
    //           label: cl,
    //           value: cl,
    //         });
    //       });
    //     }

    //     var customerAreas = resp.data.customerAreas;
    //     var filterCustomerAreas = [];
    //     if (
    //       customerAreas != "" &&
    //       customerAreas != undefined &&
    //       customerAreas.length > 0
    //     ) {
    //       customerAreas.map((area) => {
    //         filterCustomerAreas.push({
    //           label: area.customer_area,
    //           value: area.customer_area_id,
    //         });
    //       });
    //       filterCustomerAreas.unshift({ value: "all", label: "All" });
    //     }

    //     var consigners = resp.data.consigners;
    //     var filterConsigners = [];
    //     if (
    //       consigners != "" &&
    //       consigners != undefined &&
    //       consigners.length > 0
    //     ) {
    //       consigners.map((consigner) => {
    //         filterConsigners.push({
    //           label: consigner.consigner_name,
    //           value: consigner.consigner_code,
    //         });
    //       });
    //       filterConsigners.unshift({ value: "all", label: "All" });
    //     }
    //     this.setState(
    //       {
    //         filterCustomerAreas: filterCustomerAreas,
    //       },
    //       () => {
    //         this.getFeedbackForFilters();
    //         console.log("filterCustomerAreas", this.state.filterCustomerAreas);
    //       }
    //     );
    //   });
  }

  closeAlert = () => {
    this.setState({
      show: false,
    });
  };

  handlerStartDateTime = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);
    console.log("dd", d);
    const date = new Date(d);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    console.log("formattedDate", formattedDate);

    var startdate =
      d.getFullYear() +
      "-" +
      (d.getMonth() + 1) +
      "-" +
      (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());
    if (this.state.endDate != undefined && this.state.endDate != "") {
      // console.log(startdate,"startdate")
      // console.log(this.state.endDate,"endDate")
      var startDate = new Date(startdate);
      var endDate = new Date(this.state.endDate);
      var startTime = startDate.getTime();
      var endTime = endDate.getTime();
      // console.log(startTime,endTime);
      if (startTime > endTime) {
        this.setState({
          startDate: this.state.startDate,
          show: true,
          basicType: "danger",
          basicTitle: "From Date shouldn't be greater than To Date"
        });
      } else {
        this.setState({
          startDate: formattedDate,
        });
      }
    } else {
      this.setState({
        startDate: formattedDate,
      });
    }
    //let value = event.target.value;
    //this.setState({'startDate':value});
  };

  handlerEndDateTime = (event, currentDate, selectedDate) => {
    var d = new Date(event._d);
    const date = new Date(d);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    var edate =
      d.getFullYear() +
      "-" +
      (d.getMonth() + 1) +
      "-" +
      (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());
    if (this.state.endDate != undefined && this.state.endDate != "") {
      // console.log(this.state.startDate,"startdate")
      // console.log(edate,"endDate")
      var startDate = new Date(this.state.startDate);
      var endDate = new Date(edate);
      var startTime = startDate.getTime();
      var endTime = endDate.getTime();
      // console.log(startTime,endTime);
      if (startTime > endTime) {
        this.setState({
          endDate: this.state.endDate,
          show: true,
          basicType: "danger",
          basicTitle: "To Date shouldn't be lesser than From Date"
        });
      } else {
        this.setState({
          endDate: formattedDate,
        });
      }
    } else {
      this.setState({
        endDate: formattedDate,
      });
    }
  };

  // newFilter = (filterType, selOption) => {
  //   // console.log(filterType, selOption)
  //   this.setState({ [filterType]: selOption });
  // };

  // newFilter = (filterType, selOption) => {
  //   console.log("selOption",selOption);
  // 	this.setState({ [filterType]: selOption },()=>{
  // 		if(filterType === "flConsignee"){
  // 			if(!selOption){
  // 				this.setState({
  // 					filterCustomerAreas:[],
  // 					["flArea"]: []
  // 				})
  // 			}
  // 			if(selOption && this.state.flConsignee.length === 0){
  // 				this.setState({
  // 					filterCustomerAreas:[],
  // 					["flArea"]: []
  // 				})
  // 			}
  // 			if(selOption && this.state.flConsignee.length > 0){
  // 				for(let each of selOption){
  // 					let {value} = each;
  // 					if(value === "all"){
  // 						let Areas = [{label:"All", value:"all"}]
  // 						console.log(this.state.consignerAreas["all"],"all");
  // 						for(let each of this.state.consignerAreas["all"]){
  // 							for(let eachArea of each){
  // 								let {customer_area_id,customer_area} = eachArea;
  // 								let option = {value:customer_area_id,label:customer_area}
  // 								Areas.push(option)
  // 							}
  // 						}
  // 						this.setState({
  // 							filterCustomerAreas : Areas
  // 						})
  // 						break;
  // 					}else{
  //             let Areas = []
  //             for(let eachArea of this.state.consignerAreas[Number(value)]){
  //               console.log(eachArea);
  // 								let {customer_area_id,customer_area} = eachArea;
  // 								let option = {value:customer_area_id,label:customer_area}
  // 								Areas.push(option)
  // 						}
  //             Areas.unshift({label:"All", value:"all"})
  // 						this.setState({
  // 							filterCustomerAreas : Areas
  // 						},()=>{
  //               console.log("this.state.filterCustomerAreas",this.state.filterCustomerAreas);
  //             })
  // 					}
  // 				}
  // 			}
  // 		}
  // 	});
  // }

  newFilter = (filterType, selOption) => {
    this.setState({ [filterType]: selOption }, () => {
      if (filterType === "flConsignee" && this.state.userType !== "DISTRIBUTOR") {
        this.setState({
          loadshow: "show-m",
          ["flArea"]: [],
        });
        let Areas = [{ label: "All", value: "all" }];
        if (!selOption) {
          this.setState({
            filterCustomerAreas: [],
            loadshow: "show-n",
          });
        }
        if (selOption && this.state.flConsignee.length === 0) {
          this.setState({
            filterCustomerAreas: [],
            loadshow: "show-n",
          });
        }
        if (selOption && this.state.flConsignee.length > 0) {
          for (let each of selOption) {
            let { value } = each;
            if (value === "all") {
              let Areas = [{ label: "All", value: "all" }];
              console.log(this.state.consignerAreas["all"], "all");
              for (let each of this.state.consignerAreas["all"]) {
                for (let eachArea of each) {
                  let { customer_area_id, customer_area } = eachArea;
                  let option = {
                    value: customer_area_id,
                    label: customer_area,
                  };
                  Areas.push(option);
                }
              }
              this.setState({
                filterCustomerAreas: Areas,
                loadshow: "show-n",
              });
              break;
            } else {
              console.log(this.state.consignerAreas[value], "value");
              this.state.consignerAreas[Number(value)].forEach((eachArea) => {
                let { customer_area_id, customer_area } = eachArea;
                let option = { value: customer_area_id, label: customer_area };
                Areas.push(option);
              });
              this.setState({
                filterCustomerAreas: Areas,
                loadshow: "show-n",
              });
            }
          }
        }
      }
    });
  };

  getFeedbackForFilters() {
    let userType = localStorage.getItem("user_type");
    this.setState({
      userType: userType,
    });

    let params = {
      fromDate: this.state.startDate,
      toDate: this.state.endDate,
      selectedAreas: this.state.flArea,
      userType: userType,
    };

    if (userType === "DISTRIBUTOR") {
      params.consigneeCode = this.state.consigneeCode;
    } else {
        params.selectedConsignees = this.state.flConsignee
        if (userType === "NAGPURORGUSER") {
          params.customerCity = ["Nagpur", "NAGPUR"];
        } else if (userType == "NASIKORGUSER") {
          params.customerCity = ["Nasik", "NASIK", "Nashik", "NASHIK"];
        } else if (userType == "SOLAPURORGUSER") {
          params.customerCity = ["Solapur"];
        } else if (userType == "INDOREORGUSER") {
          params.customerCity = ["Indore"];
        } else if (userType == "AHMEDABADORGUSER") {
          params.customerCity = ["Ahmedabad"];
        } else{
          params.selectedConsignees = this.state.flConsignee
        }
    }

    this.setState({
      loadshow: "show-m",
    });
    redirectURL
      .post("/consignments/getFeedback", params)
      .then((resp) => {
        console.log("getFeedback resp", resp.data);
        let {
          questions,
          question1_yes,
          question1_no,
          question2_yes,
          question2_no,
          allDocx,
        } = resp.data;
        this.setState({
          questions,
          allData: allDocx,
          rowData: allDocx,
          loadshow: "show-n",
          question1_yes,
          question1_no,
          question2_yes,
          question2_no,
        });
      })
      .catch((err) => {
        console.log("getfeedback err", err);
      });


    


    // if (userType === "DISTRIBUTOR") {
    //   if (
    //     this.state.startDate &&
    //     this.state.endDate &&
    //     this.state.flArea.length > 0
    //   ) {
    //     this.setState({
    //       loadshow: "show-m",
    //     });
    //     redirectURL
    //       .post("/consignments/getFeedback", params)
    //       .then((resp) => {
    //         console.log("getFeedback resp", resp.data);
    //         let {
    //           questions,
    //           question1_yes,
    //           question1_no,
    //           question2_yes,
    //           question2_no,
    //           allDocx,
    //         } = resp.data;
    //         this.setState({
    //           questions,
    //           allData: allDocx,
    //           rowData: allDocx,
    //           loadshow: "show-n",
    //           question1_yes,
    //           question1_no,
    //           question2_yes,
    //           question2_no,
    //         });
    //       })
    //       .catch((err) => {
    //         console.log("getfeedback err", err);
    //       });
    //   } else {
    //     this.setState({
    //       show: true,
    //       basicType: "danger",
    //       basicTitle: "Filters should not be empty",
    //     });
    //   }
    // }else{
    //   if(this.state.startDate &&
    //     this.state.endDate &&
    //     this.state.flConsignee.length > 0
    //     ){
    //       this.setState({
    //         loadshow: "show-m",
    //       });
    //       params.selectedConsignees = this.state.flConsignee
    //       redirectURL
    //         .post("/consignments/getFeedback", params)
    //         .then((resp) => {
    //           console.log("getFeedback resp", resp.data);
    //           let {
    //             questions,
    //             question1_yes,
    //             question1_no,
    //             question2_yes,
    //             question2_no,
    //             allDocx,
    //           } = resp.data;
    //           this.setState({
    //             questions,
    //             allData: allDocx,
    //             rowData: allDocx,
    //             loadshow: "show-n",
    //             question1_yes,
    //             question1_no,
    //             question2_yes,
    //             question2_no,
    //           });
    //         })
    //         .catch((err) => {
    //           console.log("getfeedback err", err);
    //         });
    //   }else{
    //     this.setState({
    //       show: true,
    //       basicType: "danger",
    //       basicTitle: "Filters should not be empty",
    //     });
    //   }
    // }

    // }else{
    // if(userType === "NAGPURORGUSER"){
    //   params.customerCity = ["Nagpur", "NAGPUR"]
    // } else if(userType == "NASIKORGUSER"){
    //   params.customerCity = ["Nasik", "NASIK", "Nashik", "NASHIK"]
    // } else if (userType == "SOLAPURORGUSER") {
    //   params.customerCity = ["Solapur"]

    // } else if (userType == "INDOREORGUSER") {
    //   params.customerCity =  ["Indore"]
    // }

    // this.setState({
    //   show: true,
    //   basicType: "danger",
    //   basicTitle: "This is super user",
    //   loadshow: "show-m"
    // });
    // }
  }

  handleCounterData = (feedbackQquestion, response) => {
    let allDocx = this.state.allData.filter((each) => {
      let { question, answer } = each;
      if (question === feedbackQquestion && response === answer) {
        return each;
      }
    });
    this.setState({
      rowData: allDocx,
    });
  };

  onClickHideAll() {
    this.setState({
      loadshow: "show-n",
      overly: "show-n",
    });
  }
  render() {
    return (
      <div className="container-fluid">
        <div className={"dataLoadpage " + this.state.loadshow}></div>
        <div className={"dataLoadpageimg " + this.state.loadshow}>
          <div className="loader-box">
            <div className="loader-box">
              <div className="rotate dashed colored"></div>
            </div>
          </div>
        </div>
        <div
          className={"overlay-part " + this.state.overly}
          onClick={this.onClickHideAll.bind(this)}
        ></div>
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        ></SweetAlert>
        <div className="card pl-3 pt-3" style={{ minHeight: "100px" }}>
          <div
            className="row col-xl-12 col-lg-12"
            style={{ marginLeft: "0px", padding: "0px" }}
          >
            <div className="col-xl-3 col-lg-3 form-group">
              <label>From Date : </label>
              <Datetime
                value={this.state.startDate}
                disableCloseOnClickOutside={false}
                closeOnSelect={true}
                inputProps={{
                  placeholder: "From",
                  name: "startDate",
                  autoComplete: "off",
                  readOnly: true,
                  value: this.state.startDate,
                }}
                dateFormat="YYYY-MM-DD"
                name="startDate"
                onChange={this.handlerStartDateTime.bind(this)}
              />
            </div>

            <div className="col-xl-3 col-lg-3 form-group">
              <label>To Date : </label>
              <Datetime
                value={this.state.endDate}
                disableCloseOnClickOutside={false}
                closeOnSelect={true}
                inputProps={{
                  placeholder: "To",
                  name: "endDate",
                  autoComplete: "off",
                  readOnly: true,
                }}
                dateFormat="YYYY-MM-DD"
                onChange={this.handlerEndDateTime.bind(this)}
              />
            </div>
            {this.state.userType !== "DISTRIBUTOR" ? (
              <div className="form-group col-xl-3 col-lg-3">
                <label>Distributor : </label>
                <Select
                  closeMenuOnSelect={false}
                  value={this.state.flConsignee}
                  isMulti={true}
                  className="border-radius-0"
                  onChange={this.newFilter.bind(this, "flConsignee")}
                  style={{ borderRadius: "09px" }}
                  options={this.state.filterConsignees}
                  required
                />
              </div>
            ) : (
              ""
            )}
            <div className="form-group col-xl-3 col-lg-3">
              <label>Area : </label>
              <Select
                closeMenuOnSelect={false}
                value={this.state.flArea}
                isMulti={true}
                className="border-radius-0"
                onChange={this.newFilter.bind(this, "flArea")}
                style={{ borderRadius: "09px" }}
                options={this.state.filterCustomerAreas}
                required
              />
            </div>
            <div className="form-group col-xl-12 col-lg-12">
              <button
                type="button"
                style={{ margin: "0 auto" }}
                className="btn btn-success"
                onClick={this.getFeedbackForFilters.bind(this)}
              >
                Submit
              </button>
            </div>
          </div>
        </div>

        <div className="card px-3">
          <div className="justify-content-between m-4 row">
            <div className="col" style={{borderRight:"1px solid rgb(224, 222, 222)", marginRight: "2.5rem" }}>
              <div style={{ marginBottom: "2.5rem" }}>
                <h4
                  className="header"
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    fontFamily: "inherit",
                  }}
                >
                  <span>
                    {/* Did you receive cylinder as per your preferred time? */}
                    {this.state.questions[0] ? this.state.questions[0] : ""}
                  </span>
                </h4>
              </div>
              <div className="row justify-content-center">
                <div
                  className="col-4 text-center cursorPointer"
                  style={{ borderRight: "1px solid #e0dede" }}
                  onClick={this.handleCounterData.bind(
                    this,
                    // "Did you receive cylinder as per your preferred time?",
                    this.state.questions[0],
                    "YES"
                  )}
                >
                  <span className="f13">
                    <i class="fa fa-check f22" aria-hidden="true"></i>
                    <br /> Yes
                  </span>
                  <h4 className="f40">
                    <span className="counter" style={{ color: "#098719" }}>
                      <CountUp end={this.state.question1_yes} />
                    </span>
                  </h4>
                </div>

                <div
                  className="col-4 text-center cursorPointer"
                  onClick={this.handleCounterData.bind(
                    this,
                    // "Did you receive cylinder as per your preferred time?",
                    this.state.questions[0],
                    "NO"
                  )}
                >
                  <span className="f13">
                    <i class="fa fa-times f22" aria-hidden="true"></i>
                    <br />
                    No
                  </span>
                  <h4 className="f40">
                    <span className="counter" style={{ color: "#af1f1f" }}>
                      <CountUp end={this.state.question1_no} />
                    </span>
                  </h4>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="mb-3">
                <h4
                  className="header"
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    fontFamily: "inherit",
                  }}
                >
                  <span>
                    {/* Was the cylinder checked for leakage & weight at the time of
                    delivery? */}
                    {this.state.questions[1] ? this.state.questions[1] : ""}
                  </span>
                </h4>
              </div>
              <div className="row justify-content-center">
                <div
                  className="col-4 text-center cursorPointer"
                  style={{ borderRight: "1px solid #e0dede" }}
                  onClick={this.handleCounterData.bind(
                    this,
                    // "Was the cylinder checked for leakage & weight at the time of delivery?",
                    this.state.questions[1],
                    "YES"
                  )}
                >
                  <span className="f13">
                    <i class="fa fa-check f22" aria-hidden="true"></i>
                    <br />
                    Yes
                  </span>
                  <h4 className="f40">
                    <span className="counter" style={{ color: "#098719" }}>
                      <CountUp end={this.state.question2_yes} />
                    </span>
                  </h4>
                </div>

                <div
                  className="col-4 text-center cursorPointer"
                  onClick={this.handleCounterData.bind(
                    this,
                    // "Was the cylinder checked for leakage & weight at the time of delivery?",
                    this.state.questions[1],
                    "NO"
                  )}
                >
                  <span className="f13">
                    <i class="fa fa-times f22" aria-hidden="true"></i>
                    <br />
                    No
                  </span>
                  <h4 className="f40">
                    <span className="counter" style={{ color: "#af1f1f" }}>
                      <CountUp end={this.state.question2_no} />
                    </span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card pt-3 px-3">
          <h5 mb-3>
            <span className="mr-2">
              <i class="fa fa-comments-o" aria-hidden="true"></i>
            </span>
            <span>Feedback</span>
          </h5>
          <div
            id="myGrid"
            style={{ height: "580px", width: "100%" }}
            className="ag-theme-balham"
          >
            <AgGridReact
              columnDefs={this.state.columnwithDefs}
              defaultColDef={this.state.defaultColDef}
              rowData={this.state.rowData}
              // onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
              // enableCharts={true}
              // enableRangeSelection={true}
              // autoGroupColumnDef={this.state.autoGroupColumnDef}
              // onGridReady={this.onGridReady}
              // onGridState={this.onGridState}
              statusBar={this.state.statusBar}
              sideBar={this.state.sideBar}
              paginationPageSize={this.state.paginationPageSize}
              pagination={true}
              // frameworkComponents={this.state.frameworkComponents}
              // detailCellRendererParams={this.state.detailCellRendererParams}
              //editType={this.state.editType}
              // stopEditingWhenGridLosesFocus={true}
              //floatingFilter={true}

              // enableCellChangeFlash={false}
              //suppressCellFlash={true}
              // rowClassRules={this.state.rowClassRules}
              // onCellDoubleClicked={this.onRowClicked.bind(this)}
              // gridOptions={{
              // 	context: { componentParent: this }
              // }}
            />
          </div>
        </div>
      </div>
    );
  }
}
