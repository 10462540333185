
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
var redirectURL = require('../redirectURL');
var moment = require('moment');

 
const RailInventoryTransLoadingYard = (props) => {
    // console.log("props ", props)
    const handleClick = (e) => {
        e.stopPropagation();
        props.context.componentParent.onClickTransLoadingYard(props);
    };
    
    return (
        <div>
            <a href="#" style={{color:"#000"}} onClick={handleClick}>{props.data[props.colDef.field]}</a>
        </div>
    );
};

export default RailInventoryTransLoadingYard;
