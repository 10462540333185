import React, {Component} from "react";
import ReactDom,{ render } from 'react-dom';
import axios from 'axios';
import { Redirect } from 'react-router';
import LoginForm from './Loginform';
import LmLoginForm from './LmLoginform';

//import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

var images = require.context('../../assets/images/', true);

class Login extends Component {

    constructor(props) {
        super(props);
        var refur='';
        
        this.state = {
            style: {},
            email:'',
            password:'',
            loginMessage:'',
            is_enmovil_transporter:0,
            is_load_management:0,
        };
    }

    componentDidMount() {
        setTimeout(function () {
            this.setState({style: {display:'none'}});
        }.bind(this),1000)
        var urlPath = this.props.match.path;
        //console.log(urlPath,"urlPath")
       // console.log("location.hostname ", window.location.hostname)
        if(urlPath == "/tptlogin")
        {
            this.setState({
                is_enmovil_transporter:1
            });
        }
        else if(urlPath == "/optirun")
        {
            this.setState({
                is_load_management:1
            });
        }
        else if(urlPath == "/loadmanagement/login")
        {
            this.setState({
                is_load_management:1
            });
        }
    }
    
   
    render() {
        let style = this.state.style;
        if(window.location.hostname == "3.108.113.108")
        {
            var background = require('../../assets/images/login-image.jpg');
        }
        else
        {
            if(this.state.is_enmovil_transporter == 1 || window.location.hostname != "autometrics.in" || this.state.is_load_management == 1)
            {
                var background = require('../../assets/images/auth-layer.png');
            }
            else
            {
                var background = require('../../assets/images/login-image.jpg');
            }
        }
               
       // console.log("window.location.hostname-",window.location.hostname)
        return (
            <div>
                {/* Loader starts */}
                <div className="loader-wrapper" style={style}>
                    <div className="loader bg-white">
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <h4>Have a great day at work today <span>&#x263A;</span></h4>
                    </div>
                </div>
                {/* Loader ends */}

                {/*page-wrapper Start*/}
                {/* <div className="page-wrapper aimg ">
                    <div className="container-fluid">
                        <div className="authentication-main" >
                            <div className="row">
                                <div className="login-form">
                                    <div className="auth-innerright" style={{background:"none"}}>
                                        <div className="authentication-box" style={{padding:"0px 30px"}}>
                                            
                                            <div className="card mt-4 p-4 mb-0">
                                                <h4 style={{textAlign:"center"}}>
                                                    <img src={require('../../assets/images/autometrics_logo.png')} className="logo-login" alt="" />
                                                    <div className="poweredby">
                                                        <span className="">Powered by </span>
                                                        <img src={require('../../assets/images/enmovil_logo.png')}
                                                            className="logo-login-pw" alt=""/>
                                                    </div>
                                                
                                                </h4>
                                                <LoginForm is_enmovil_transporter={this.state.is_enmovil_transporter}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                                
                        </div>
                    </div>
                </div> */}
                {/*page-wrapper Ends*/}
					{/*
                <div className="page-wrapper ovrfl-h">
                    <div className="container-fluid">
                        <div className="row">
							<div className="lgbg">
								<img src={require('../../assets/images/banner_volvo_eicher_v2.png')} />
							</div>
                            <div className="col-sm-6 col-md-6 loginbg">
                                
                                
                            </div>
                            <div className="col-sm-6 col-md-6 ovrfl-h" style={{backgroundColor:"#04396e"}}>
                                <div className="curvebg1">
                                    <img src={require('../../assets/images/autometrics_logo.png')} style={{width:"auto"}} className="logo-login tplgimg" alt=""/><br /><br />
                                    <div className="toplog"><span style={{color:"rgb(4, 57, 110)",fontWeight:"bold"}}>Volvo</span>&nbsp;<span  style={{color:"rgba(227,50,57)",fontWeight:"bold"}}>Eicher</span></div>
                                </div>
                                <div className="curvebg"></div>
                                <div className="row">
                                    <div className="col-sm-10 lgn ml-60p ">
                                        <div className="lgform">
                                            <h5 className="h5fnt">Login</h5>
                                            <LoginForm is_enmovil_transporter={this.state.is_enmovil_transporter}/>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
				*/}
				
				 <div className="page-wrapper ovrfl-h backbg">
                    <div className="container-fluid">
                        <div className="row">
							<div className="lgbg">
								<img src={require('../../assets/images/PTD_Final_Logo.png')} style={{width:"300px", boxShadow:"0px 0px 25px #1D2855", borderRadius: "50%"}}/>
							</div>
                            <div className="col-sm-7 col-md-7 loginbg">
                                
								<div className="toplog">
								{/* <span style={{color:"rgb(4, 57, 110)",fontWeight:"bold",fontSize:"70px"}}>Volvo</span>&nbsp;<span  style={{color:"rgba(227,50,57)",fontWeight:"bold",fontSize:"70px"}}>Eicher</span> */}
									
								</div>
                                
                            </div>
                            <div className="col-sm-4 col-md-4 ovrfl-h">
								
                                {/*<div className="curvebg1">
                                    <img src={require('../../assets/images/autometrics_logo.png')} style={{width:"auto"}} className="logo-login tplgimg" alt=""/><br /><br />
                                    <div className="toplog"><span style={{color:"rgb(4, 57, 110)",fontWeight:"bold"}}>Volvo</span>&nbsp;<span  style={{color:"rgba(227,50,57)",fontWeight:"bold"}}>Eicher</span></div>
                                </div>
	<div className="curvebg"></div>*/}
                                <div className="row">
                                    <img src={require('../../assets/images/autometrics_logo.png')} style={{width:"auto"}} className="logo-login float-right tplgimg" alt=""/>
                                    <div className="poweredby">
                                        <span className="">Powered by </span>
                                        <img src={require('../../assets/images/enmovil_logo.png')}
                                            className="logo-login-pw" alt=""/>
                                    </div>
                                    <div className="col-sm-10 lgn">
									
										<div className="loverly">
                                            
                                        </div>
                                        <div className="lgform">
                                            <h5 className="h5fnt mt-3">Login</h5>
                                            <LoginForm is_enmovil_transporter={this.state.is_enmovil_transporter}/>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
				
            </div>

        )
    }
}


export default Login;
