/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import _ from "lodash";
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
// //import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css"; import {
    getDDMMYYYY,
    getDDMMYYYYHHMMSS,
    getYYYYMMDD,
    getYYYYMMDDHHMMSS,
    getHyphenYYYYMMDDHHMMSS,
    getHyphenYYYYMMDD,
    getHyphenDDMMMYYYYHHMM, getHyphenDDMMMYYYY,
    getHyphenDDMMYYYYHHMMSS,
    getHyphenDDMMYYYY
} from '../common/utils';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import Select from 'react-select';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import DashboardChart from "./dashboardcharts";
import ColumnGroupDrilldownChart from "./columngroupdrilldowncharts";
import ChildChart from './childchartcomponent';
import drilldown from 'highcharts-drilldown';
import { NavItem } from 'reactstrap';
drilldown(Highcharts);
window.jQuery = $;
var moment = require('moment');
window.$ = $;

var googleAnalytics = require("../common/googleanalytics");
var redirectURL = require('../redirectURL');

export default class CourierConsignmentDashboard extends Component {
    state = {
        modules: AllModules,
        defaultColDef: {
            sortable: true,
            filter: true,
            resizable: true,
            enablePivot: true,
            pivot: true,
            enableValue: true,
            enableRowGroup: true,
        },
        sideBar: {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel"
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel"
                }
            ]
        },
        paginationPageSize: 50,
        statusBar: {
            statusPanels: [
                {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                },
                {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center"
                },
                { statusPanel: "agFilteredRowCountComponent" },
                { statusPanel: "agSelectedRowCountComponent" },
                { statusPanel: "agAggregationComponent" }
            ]
        },
        highchartoptions: {},
        state_highchartoptions: {},
        day_highchartoptions: {},
        transporters: [],
        statesList: [],
        service_provider: [{ "value": "all", "label": 'All' }],
        location: [{ "value": "all", "label": 'All' }],
        consignee_states: [{ "value": "all", "label": 'All' }],
        loadshow: 'show-n',
        consignee_city: [{ "value": "all", "label": 'All' }],
        data_search_on: [{ value: 'BOOKING', label: 'Invoice Date' }],
        transit_days:{ "value": "ALL", "label": 'All' },
        citiesList: [],
        startDate: '2022-07-01',
        endDate: moment.parseZone().format('YYYY-MM-DD'),
        weight_highchartoptions: {},
        chartOptions: {},
        seriesData: [],
        categories: [],
        drilldownseries: [{}],
        tptDispatches: [],
        stateseriesData: [],
        statecategories: [],
        statedrilldownseries: [{}],
        stateDispatches: [],
        statecseriesData: [],
        stateccategories: [],
        statecdrilldownseries: [{}],
        dayccategories: [],
        daycdrilldownseries: [{}],
        daycDispatches: [],
        daywcategories: [],
        daywdrilldownseries: [{}],
        daywDispatches: [],
        rowData: [],
        daycseriesData: [],
        showCountLineGridData: "show-n",
        showCountColumnChart: "show-n",
        showLineGridData: "show-n",
        showColumnChart: "show-n",
        showStateLineGridData: "show-n",
        showStateColumnChart: "show-n",
        showWeightLineGridData: "show-n",
        showWeightColumnChart: "show-m",
        city_list: [],
        daywseriesData: {},
        firstchartseries: [],
        secondChartSeries: [],
        dealerThresholdList: [],
        dealer_threshold: { "value": "all", "label": 'All' },
        dealerDealayedChartData: [],
        dealerDelayDrillDownList: [],
        dealerCategory: [],
        series: [],
        stateLevelSeries: [],
        overAllChartStateChart: [],
        daycseriesweightData: [],
        dayweightcategories: [],
        dayweightdrilldownseries:[]
    }

    componentDidMount() {
        // var start_date = moment.parseZone().subtract(1, 'months').format('YYYY-MM-DD');
        var start_date = '2022-07-01';
        var end_date = moment.parseZone().format('YYYY-MM-DD');
        let params = {
            startDate: start_date,
            endDate: end_date,
            service_provider: [{ "value": "all", "label": 'All' }],
            consignee_city: [{ "value": "all", "label": 'All' }],
            consignee_states: [{ "value": "all", "label": 'All' }],
            transit_days:{ "value": "ALL", "label": 'All' }

        }
        this.setState({
            loadshow: 'show-m',
            seriesData: [],
            categories: [],
            drilldownseries: [{}],
            tptDispatches: [],
            stateseriesData: [],
            statecategories: [],
            statedrilldownseries: [{}],
            stateDispatches: [],
            statecseriesData: [],
            stateccategories: [],
            statecdrilldownseries: [{}],
            dayccategories: [],
            daycdrilldownseries: [{}],
            daycDispatches: [],
            daywcategories: [],
            daywdrilldownseries: [{}],
            daywDispatches: [],
            rowData: [],
        })
        this.onLoadGetData(params)
        // this.tptWiseDispatches();
        redirectURL.post('/dashboard/getDropDownDataCC').then(resp => {
            // console.log('resp',resp.data)
            this.setState({
                statesList: [{ "value": "all", "label": 'All' }, ...resp.data.statesList],
                city_list: [{ "value": "all", "label": 'All' }, ...resp.data.cityList],
                transList: [{ "value": "all", "label": 'All' }, ...resp.data.transporterList]
            })
        })

    }

    onLoadGetData = (params) => {
        // console.time();
        
        var daywise = { ...params, apiName: "courierConsignmentsPackageAndWeightWiseChartsData" }
        redirectURL.post("/dashboard/daywisepkgwg", daywise).then(async resp => {
            // console.log('response', resp.data)
            let response = resp.data
            // let tptDispatches = JSON.parse(response.lsp_state_city_dealer_status_wise_grps_df)
            // console.log(tptDispatches, "tptDispatches")
            // var stateDispatches = JSON.parse(response.overall_state_city_dealer_status_wise_grps);
            // console.log(stateDispatches,"stateDispatches")

            var day_package_wise_grps = JSON.parse(response.day_package_wise_grps);
            var day_lsp_package_wise_grps = JSON.parse(response.day_lsp_package_wise_grps);
            var day_lsp_state_package_wise_grps = JSON.parse(response.day_lsp_state_package_wise_grps);
            var day_lsp_state_city_package_wise_grps = JSON.parse(response.day_lsp_state_city_package_wise_grps);
            var daycDispatches = JSON.parse(response.day_lsp_state_city_dealer_package_wise_grps);

            
            // var days_weight_wise_grps = JSON.parse(response.overall_lsp_status_wise_grps_df);
            // var days_lsp_weight_wise_grps = JSON.parse(response.overall_lsp_status_wise_grps_df);
            // var days_lsp_state_weight_wise_grps = JSON.parse(response.overall_lsp_state_city_status_wise_grps_df);
            // var days_lsp_state_city_weight_wise_grps = JSON.parse(response.overall_lsp_state_status_wise_grps_df);
            // var days_lsp_state_city_dealer_weight_wise_grps = JSON.parse(response.overall_lsp_state_city_dealer_status_wise_grps_df);

            // console.log(day_lsp_state_package_wise_grps,"day_lsp_state_package_wise_grps")

            // var daywDispatches = JSON.parse(response.overall_lsp_state_city_dealer_status_wise_grps_df);
            // console.log(daywDispatches,"daywDispatches")

            let total_list = JSON.parse(response.total_df)
            // let city_list = [...new Set(total_list.map(item => item.consignee_city))]
            await this.setState({
                // tptDispatches: tptDispatches,
                // stateDispatches: stateDispatches,
                daycDispatches: daycDispatches,
                // daywDispatches: daywDispatches,
                rowData: total_list,
                "loadshow": "show-n"
            })
            // await this.tptWiseDispatches()
            // await this.statecWiseDispatches()
            // await this.stateWiseDispatches()
            // await this.daycWiseDispatches()


            try{
                var daypkgSeries = [];
                var seqseries = [];
                var dayscategories = [];
                day_package_wise_grps.map((item, inde) => {
                    // console.log(item,"item")
                    dayscategories.push(item.days);
                    seqseries.push({
                        name: item.days,
                        drilldown: item.days,
                        y: item.count,
                        label: item.count
                    });
                })
                daypkgSeries.push({
                    name: "No Of Dispatches",
                    colorByPoint: true,
                    data: seqseries
                })
                // console.log(daypkgSeries,"daypkgSeries")
                var dayslists = groupBy(day_lsp_package_wise_grps, tdata => tdata.days);
                // console.log("dayslists ", dayslists)
                var daywisetranslist=[]
                var daywisestranslist=[]
                dayslists.forEach((daysitm,dayKey) => {
                    var drilldownstateseries = [];
                    if(daysitm.length > 0)
                    {
                        daysitm.map((item) => {
                            if(item.days == dayKey)
                            {
                                drilldownstateseries.push({
                                    name: item.service_provider,
                                    // id:item.transporter_name+"_overall",
                                    drilldown: item.days + "_" + item.service_provider,
                                    y: item.count,
                                    label: item.count
                                })
                            }
                        })
                    }
                    daywisetranslist.push({
                        name: dayKey,
                        id: dayKey,
                        data: drilldownstateseries
                    });
                });
                // console.log("daywisetranslist ", daywisetranslist);
                // console.log("dayscategories ", dayscategories);

                var daysstatelists = groupBy(day_lsp_state_package_wise_grps, tdata => tdata.days);
                // console.log("dayslists ", dayslists)
                
                daysstatelists.forEach((daysitm,dayKey) => {
                    var daystrns = groupBy(daysitm, tdata => tdata.service_provider);
                    // console.log("daystrns ", daystrns)
                    daystrns.forEach((daystrn,daytrankey) => {
                        var drilldowntransseries = [];
                        if(daystrn.length > 0)
                        {
                            daystrn.map((item) => {
                                if(item.days == dayKey && item.service_provider == daytrankey)
                                {
                                    drilldowntransseries.push({
                                        name: item.consignee_state,
                                        // id:item.transporter_name+"_overall",
                                        drilldown: item.days + "_" + item.service_provider+"_"+item.consignee_state,
                                        y: item.count,
                                        label: item.count
                                    })
                                }
                            })
                        }
                        daywisetranslist.push({
                            name: dayKey+"_"+daytrankey,
                            id: dayKey+"_"+daytrankey,
                            data: drilldowntransseries
                        });    
                    })
                    
                });

                var daysstatecitylists = groupBy(day_lsp_state_city_package_wise_grps, tdata => tdata.days);
                // console.log("dayslists ", dayslists)
                
                daysstatecitylists.forEach((daysitm,dayKey) => {
                    var daystrns = groupBy(daysitm, tdata => tdata.service_provider);
                    // console.log("daystrns ", daystrns)
                    daystrns.forEach((daystrn,daytrankey) => {
                        var daystrnstates = groupBy(daystrn, tdata => tdata.consignee_state);
                        daystrnstates.forEach((daystrnstate,daystrnstatekey) => {
                            var drilldowntransseries = [];
                            if(daystrnstate.length > 0)
                            {
                                daystrnstate.map((item) => {
                                    if(item.days == dayKey && item.service_provider == daytrankey && item.consignee_state == daystrnstatekey)
                                    {
                                        drilldowntransseries.push({
                                            name: item.consignee_city,
                                            // id:item.transporter_name+"_overall",
                                            drilldown: item.days + "_" + item.service_provider+"_"+item.consignee_state+"_"+item.consignee_city,
                                            y: item.count,
                                            label: item.count
                                        })
                                    }
                                })
                            }
                            daywisetranslist.push({
                                name: dayKey+"_"+daytrankey+"_"+daystrnstatekey,
                                id: dayKey+"_"+daytrankey+"_"+daystrnstatekey,
                                data: drilldowntransseries
                            });  
                        })
                          
                    })
                    
                });

                
                var daysstatecitydlrlists = groupBy(daycDispatches, tdata => tdata.days);
                // console.log("dayslists ", dayslists)
                
                daysstatecitydlrlists.forEach((daysitm,dayKey) => {
                    var daystrns = groupBy(daysitm, tdata => tdata.service_provider);
                    // console.log("daystrns ", daystrns)
                    daystrns.forEach((daystrn,daytrankey) => {
                        var daystrnstates = groupBy(daystrn, tdata => tdata.consignee_state);
                        daystrnstates.forEach((daystrnstate,daystrnstatekey) => {
                            var daystrnstatescity = groupBy(daystrnstate, tdata => tdata.consignee_city);
                            daystrnstatescity.forEach((daystrnstatesci,daystrnstatescitykey) => {
                                var drilldowntransseries = [];
                                if(daystrnstatesci.length > 0)
                                {
                                    daystrnstatesci.map((item) => {
                                        if(item.days == dayKey && item.service_provider == daytrankey && item.consignee_state == daystrnstatekey && item.consignee_city == daystrnstatescitykey)
                                        {
                                            drilldowntransseries.push({
                                                name: item.consignee_name,
                                                // id:item.transporter_name+"_overall",
                                                // drilldown: item.days + "_" + item.transporter_name+"_"+item.consignee_state+"_"+item.consignee_city,
                                                y: item.count,
                                                label: item.count
                                            })
                                        }
                                    })
                                }
                                daywisetranslist.push({
                                    name: dayKey+"_"+daytrankey+"_"+daystrnstatekey+"_"+daystrnstatescitykey,
                                    id: dayKey+"_"+daytrankey+"_"+daystrnstatekey+"_"+daystrnstatescitykey,
                                    data: drilldowntransseries
                                });  
                            })
                          
                        })
                          
                    })
                    
                });


                // Weights
                var dayweightSeries = [];
                var seqseriesw = [];
                var daysweightcategories = [];
                // days_weight_wise_grps.map((item, inde) => {
                //     daysweightcategories.push(item.days);
                //     seqseriesw.push({
                //         name: item.days,
                //         drilldown: item.days,
                //         y: item.actual_weight_kgs,
                //         label: item.actual_weight_kgs
                //     });
                // })
                // dayweightSeries.push({
                //     name: "Weights",
                //     colorByPoint: true,
                //     data: seqseriesw
                // })

                // var dayslistsw = groupBy(days_lsp_weight_wise_grps, tdata => tdata.days);
                // console.log("dayslists ", dayslists)
                var daywiseweighttranslist=[]
                var daywisestranslist=[]
                // dayslistsw.forEach((daysitm,dayKey) => {
                //     var drilldownstateseries = [];
                //     if(daysitm.length > 0)
                //     {
                //         daysitm.map((item) => {
                //             if(item.days == dayKey)
                //             {
                //                 drilldownstateseries.push({
                //                     name: item.service_provider,
                //                     // id:item.transporter_name+"_overall",
                //                     drilldown: item.days + "_" + item.service_provider,
                //                     y: item.actual_weight_kgs,
                //                     label: item.actual_weight_kgs
                //                 })
                //             }
                //         })
                //     }
                //     daywiseweighttranslist.push({
                //         name: dayKey,
                //         id: dayKey,
                //         data: drilldownstateseries
                //     });
                // });
                // console.log("daywisetranslist ", daywisetranslist);
                // console.log("dayscategories ", dayscategories);

                // var daysstatelistsw = groupBy(days_lsp_state_weight_wise_grps, tdata => tdata.days);
                // console.log("dayslists ", dayslists)
                
                // daysstatelistsw.forEach((daysitm,dayKey) => {
                //     var daystrns = groupBy(daysitm, tdata => tdata.service_provider);
                //     // console.log("daystrns ", daystrns)
                //     daystrns.forEach((daystrn,daytrankey) => {
                //         var drilldowntransseries = [];
                //         if(daystrn.length > 0)
                //         {
                //             daystrn.map((item) => {
                //                 if(item.days == dayKey && item.service_provider == daytrankey)
                //                 {
                //                     drilldowntransseries.push({
                //                         name: item.consignee_state,
                //                         // id:item.transporter_name+"_overall",
                //                         drilldown: item.days + "_" + item.service_provider+"_"+item.consignee_state,
                //                         y: item.actual_weight_kgs,
                //                         label: item.actual_weight_kgs
                //                     })
                //                 }
                //             })
                //         }
                //         daywiseweighttranslist.push({
                //             name: dayKey+"_"+daytrankey,
                //             id: dayKey+"_"+daytrankey,
                //             data: drilldowntransseries
                //         });    
                //     })
                    
                // });

                // var daysstatecitylistsw = groupBy(days_lsp_state_city_weight_wise_grps, tdata => tdata.days);
                // // console.log("dayslists ", dayslists)
                
                // daysstatecitylistsw.forEach((daysitm,dayKey) => {
                //     var daystrns = groupBy(daysitm, tdata => tdata.service_provider);
                //     // console.log("daystrns ", daystrns)
                //     daystrns.forEach((daystrn,daytrankey) => {
                //         var daystrnstates = groupBy(daystrn, tdata => tdata.consignee_state);
                //         daystrnstates.forEach((daystrnstate,daystrnstatekey) => {
                //             var drilldowntransseries = [];
                //             if(daystrnstate.length > 0)
                //             {
                //                 daystrnstate.map((item) => {
                //                     if(item.days == dayKey && item.service_provider == daytrankey && item.consignee_state == daystrnstatekey)
                //                     {
                //                         drilldowntransseries.push({
                //                             name: item.consignee_city,
                //                             // id:item.transporter_name+"_overall",
                //                             drilldown: item.days + "_" + item.service_provider+"_"+item.consignee_state+"_"+item.consignee_city,
                //                             y: item.actual_weight_kgs,
                //                             label: item.actual_weight_kgs
                //                         })
                //                     }
                //                 })
                //             }
                //             daywiseweighttranslist.push({
                //                 name: dayKey+"_"+daytrankey+"_"+daystrnstatekey,
                //                 id: dayKey+"_"+daytrankey+"_"+daystrnstatekey,
                //                 data: drilldowntransseries
                //             });  
                //         })
                          
                //     })
                    
                // });

                
                // var daysstatecitydlrlistsw = groupBy(days_lsp_state_city_dealer_weight_wise_grps, tdata => tdata.days);
                // // console.log("dayslists ", dayslists)
                
                // daysstatecitydlrlistsw.forEach((daysitm,dayKey) => {
                //     var daystrns = groupBy(daysitm, tdata => tdata.service_provider);
                //     // console.log("daystrns ", daystrns)
                //     daystrns.forEach((daystrn,daytrankey) => {
                //         var daystrnstates = groupBy(daystrn, tdata => tdata.consignee_state);
                //         daystrnstates.forEach((daystrnstate,daystrnstatekey) => {
                //             var daystrnstatescity = groupBy(daystrnstate, tdata => tdata.consignee_city);
                //             daystrnstatescity.forEach((daystrnstatesci,daystrnstatescitykey) => {
                //                 var drilldowntransseries = [];
                //                 if(daystrnstatesci.length > 0)
                //                 {
                //                     daystrnstatesci.map((item) => {
                //                         if(item.days == dayKey && item.service_provider == daytrankey && item.consignee_state == daystrnstatekey && item.consignee_city == daystrnstatescitykey)
                //                         {
                //                             drilldowntransseries.push({
                //                                 name: item.consignee_name,
                //                                 // id:item.transporter_name+"_overall",
                //                                 // drilldown: item.days + "_" + item.transporter_name+"_"+item.consignee_state+"_"+item.consignee_city,
                //                                 y: item.actual_weight_kgs,
                //                                 label: item.actual_weight_kgs
                //                             })
                //                         }
                //                     })
                //                 }
                //                 daywiseweighttranslist.push({
                //                     name: dayKey+"_"+daytrankey+"_"+daystrnstatekey+"_"+daystrnstatescitykey,
                //                     id: dayKey+"_"+daytrankey+"_"+daystrnstatekey+"_"+daystrnstatescitykey,
                //                     data: drilldowntransseries
                //                 });  
                //             })
                          
                //         })
                          
                //     })
                    
                // });


                this.setState({
                    daycseriesData: daypkgSeries,
                    dayccategories: dayscategories,
                    daycdrilldownseries:daywisetranslist,

                    
                    daycseriesweightData: dayweightSeries,
                    // dayweightcategories: daysweightcategories,
                    // dayweightdrilldownseries:daywiseweighttranslist,
                    
                    showCountLineGridData: "show-n",
                    showCountColumnChart: "show-m",
                    
                    showWeightLineGridData: "show-n",
                    showWeightColumnChart: "show-m",
                })
            }catch(e){

            }


            // await this.daywWiseDispatches()

        })
        let secondParams = { ...params, apiName: 'courierConsignmentsStateWiseChartData' }
        this.newSecondChartApiData(secondParams)
        let firstParams = { ...params, apiName: "courierConsignmentsFirstChartData" }

        redirectURL.post("/dashboard/courierfirstchartdata", firstParams).then(async resp => {
            // console.log('response', resp.data)
            try {


                var lsp_wise_ontime_data = JSON.parse(resp.data.lsp_wise_ontime_data);
                var lsp_wise_state_ontime_data = JSON.parse(resp.data.lsp_wise_state_ontime_data);
                var lsp_state_city_status_wise_grps_df = JSON.parse(resp.data.lsp_state_city_status_wise_grps_df);
                var lsp_state_city_dealer_status_wise_grps_df = JSON.parse(resp.data.lsp_state_city_dealer_status_wise_grps_df);


                // console.log("lsp_wise_ontime_data ",lsp_wise_ontime_data)
                // console.log("lsp_wise_state_ontime_data ",lsp_wise_state_ontime_data)
                // console.log("lsp_state_city_status_wise_grps_df ", lsp_state_city_status_wise_grps_df)
                // console.log("lsp_state_city_dealer_status_wise_grps_df ", lsp_state_city_dealer_status_wise_grps_df)
                await this.dealerDelayChart(lsp_state_city_dealer_status_wise_grps_df)
                var transporterSeries = [];
                var overallseries = [];
                var ontimeseries = [];
                var delayedseries = [];
                var transcategories = [];
                lsp_wise_ontime_data.map((item, inde) => {
                    transcategories.push(item.service_provider);
                    overallseries.push({
                        name: item.service_provider,
                        drilldown: item.service_provider + "_overall",
                        y: item.overall_count,
                        label: item.overall_count
                    });
                    ontimeseries.push({
                        name: item.service_provider,
                        drilldown: item.service_provider + "_ontime",
                        y: item["On Time"],
                        label: item.ontime_per + "%"
                    });
                    delayedseries.push({
                        name: item.service_provider,
                        drilldown: item.service_provider + "_delayed",
                        y: item.Delayed,
                        label: item.delayed_per + "%"
                    });
                })

                var dridowntransporterseries = [];
                var tranportertates = groupBy(lsp_wise_state_ontime_data, tdata => tdata.service_provider);
                // console.log("tranportertates", tranportertates)
                tranportertates.forEach((transporterstate, transtatekey) => {
                    var drilldownoverallseries = [];
                    var drilldownontimeseries = [];
                    var drilldowndelayedseries = [];
                    if (transporterstate.length > 0) {
                        transporterstate.map((item) => {
                            if (item.service_provider == transtatekey) {

                                drilldownoverallseries.push({
                                    name: item.consignee_state,
                                    // id:item.transporter_name+"_overall",
                                    drilldown: item.service_provider + "_overall_" + item.consignee_state,
                                    y: item.overall_count,
                                    label: item.overall_count
                                });
                                drilldownontimeseries.push({
                                    name: item.consignee_state,
                                    // id:item.transporter_name+"_ontime",
                                    drilldown: item.service_provider + "_ontime_" + item.consignee_state,
                                    y: item["On Time"],
                                    label: item["On Time"]
                                });
                                drilldowndelayedseries.push({
                                    name: item.consignee_state,
                                    // id:item.transporter_name+"_delayed",
                                    drilldown: item.service_provider + "_delayed_" + item.consignee_state,
                                    y: item.Delayed,
                                    label: item.Delayed
                                });
                            }
                        })
                    }
                    // console.log("drilldownoverallseries ", drilldownoverallseries)
                    dridowntransporterseries.push({
                        name: transtatekey + "_overall",
                        id: transtatekey + "_overall",
                        data: drilldownoverallseries
                    }, {
                        name: transtatekey + "_ontime",
                        id: transtatekey + "_ontime",
                        data: drilldownontimeseries
                    }, {
                        name: transtatekey + "_delayed",
                        id: transtatekey + "_delayed",
                        data: drilldowndelayedseries
                    });

                })

                var tranportertatescities = groupBy(lsp_state_city_status_wise_grps_df, tdata => tdata.service_provider);
                tranportertatescities.forEach((transp, transk) => {

                    var tranpstates = groupBy(transp, tdata => tdata.consignee_state);
                    tranpstates.forEach((tranpstate, tranpstatekey) => {
                        var drilldownoverallseries = [];
                        var drilldownontimeseries = [];
                        var drilldowndelayedseries = [];
                        if (tranpstate.length > 0) {
                            tranpstate.map((item) => {
                                if (item.service_provider == transk && item.consignee_state == tranpstatekey) {

                                    drilldownoverallseries.push({
                                        name: item.consignee_city,
                                        // id:item.transporter_name+"_overall_"+item.consignee_state,
                                        drilldown: item.service_provider + "_overall_" + item.consignee_state + "_" + item.consignee_city,
                                        y: item.overall_count,
                                        label: item.overall_count
                                    });
                                    drilldownontimeseries.push({
                                        name: item.consignee_city,
                                        // id:item.transporter_name+"_ontime_"+item.consignee_state,
                                        drilldown: item.service_provider + "_ontime_" + item.consignee_state + "_" + item.consignee_city,
                                        y: item["On Time"],
                                        label: item["On Time"]
                                    });
                                    drilldowndelayedseries.push({
                                        name: item.consignee_city,
                                        // id:item.transporter_name+"_delayed_"+item.consignee_state,
                                        drilldown: item.service_provider + "_delayed_" + item.consignee_state + "_" + item.consignee_city,
                                        y: item.Delayed,
                                        label: item.Delayed
                                    });
                                }
                            })
                        }
                        dridowntransporterseries.push({
                            name: transk + "_overall_" + tranpstatekey,
                            id: transk + "_overall_" + tranpstatekey,
                            data: drilldownoverallseries
                        }, {
                            name: transk + "_ontime_" + tranpstatekey,
                            id: transk + "_ontime_" + tranpstatekey,
                            data: drilldownontimeseries
                        }, {
                            name: transk + "_delayed_" + tranpstatekey,
                            id: transk + "_delayed_" + tranpstatekey,
                            data: drilldowndelayedseries
                        });
                    })
                })


                var tranportertatescitiesdrl = groupBy(lsp_state_city_dealer_status_wise_grps_df, tdata => tdata.service_provider);
                tranportertatescitiesdrl.forEach((transp, transk) => {

                    var tranpstates = groupBy(transp, tdata => tdata.consignee_state);
                    tranpstates.forEach((tranpstate, tranpstatekey) => {
                        var tranpcities = groupBy(tranpstate, tdata => tdata.consignee_city);
                        tranpcities.forEach((tranpstatecity, tranpstatecitykey) => {
                            var drilldownoverallseries = [];
                            var drilldownontimeseries = [];
                            var drilldowndelayedseries = [];
                            if (tranpstatecity.length > 0) {
                                tranpstatecity.map((item) => {
                                    if (item.service_provider == transk && item.consignee_state == tranpstatekey && item.consignee_city == tranpstatecitykey) {

                                        drilldownoverallseries.push({
                                            name: item.consignee_name,
                                            // id:item.transporter_name+"_overall_"+item.consignee_state,
                                            // drilldown:item.transporter_name+"_overall_"+item.consignee_state+"_"+item.consignee_city,
                                            y: item.overall_count,
                                            label: item.overall_count
                                        });
                                        drilldownontimeseries.push({
                                            name: item.consignee_name,
                                            // id:item.transporter_name+"_ontime_"+item.consignee_state,
                                            // drilldown:item.transporter_name+"_ontime_"+item.consignee_state+"_"+item.consignee_city,
                                            y: item["On Time"],
                                            label: item["On Time"]
                                        });
                                        drilldowndelayedseries.push({
                                            name: item.consignee_name,
                                            // id:item.transporter_name+"_delayed_"+item.consignee_state,
                                            // drilldown:item.transporter_name+"_delayed_"+item.consignee_state+"_"+item.consignee_city,
                                            y: item.Delayed,
                                            label: item.Delayed
                                        });
                                    }
                                })
                            }
                            dridowntransporterseries.push({
                                name: transk + "_overall_" + tranpstatekey + "_" + tranpstatecitykey,
                                id: transk + "_overall_" + tranpstatekey + "_" + tranpstatecitykey,
                                data: drilldownoverallseries
                            }, {
                                name: transk + "_ontime_" + tranpstatekey + "_" + tranpstatecitykey,
                                id: transk + "_ontime_" + tranpstatekey + "_" + tranpstatecitykey,
                                data: drilldownontimeseries
                            }, {
                                name: transk + "_delayed_" + tranpstatekey + "_" + tranpstatecitykey,
                                id: transk + "_delayed_" + tranpstatekey + "_" + tranpstatecitykey,
                                data: drilldowndelayedseries
                            });
                        })
                    })
                })

                transporterSeries.push({
                    name: "Overall",
                    data: overallseries,
                    color: '#185cdb',
                }, {
                    name: "Ontime",
                    data: ontimeseries,
                    color: '#50ed45',
                }, {
                    name: "Delayed",
                    data: delayedseries,
                    color: '#d93a25',
                })

                // console.log("transporterSeries ", transporterSeries)
                // console.log("dridowntransporterseries ", dridowntransporterseries)
                var firstchartseries = [];
                firstchartseries.push({
                    series: transporterSeries,
                    drilldown: dridowntransporterseries,
                    categories: transcategories
                })
                this.setState({
                    firstchartseries: firstchartseries,
                    // loadshow: 'show-n',
                    showLineGridData: "show-n",
                    showColumnChart: "show-m",
                    // showWeightLineGridData: "show-n",
                    // showWeightColumnChart: "show-m",

                })
            }
            catch (e) {

            }
        })
    }

    newSecondChartApiData = async (params) => {
        redirectURL.post("/dashboard/courierfirstchartdata", params).then(resp => {
            // console.log('respdata', resp.data)
            let overall_state_city_dealer_status_wise_grps = JSON.parse(resp.data.overall_state_city_dealer_status_wise_grps)
            let overall_state_city_status_wise_grps = JSON.parse(resp.data.overall_state_city_status_wise_grps)
            let overall_state_status_wise_grps = JSON.parse(resp.data.overall_state_status_wise_grps)
            let state_city_dealer_status_wise_grps = JSON.parse(resp.data.state_city_dealer_status_wise_grps)
            // console.log('state_city_dealer_status_wise_grps',state_city_dealer_status_wise_grps)
            let state_city_status_wise_grps = JSON.parse(resp.data.state_city_status_wise_grps)
            // console.log('state_city_status_wise_grps',state_city_status_wise_grps)
            let state_status_wise_grps = JSON.parse(resp.data.state_status_wise_grps)
            // console.log("state_status_wise_grps ", state_status_wise_grps)
            try {
                var stateLevelSeries = [];
                var statesCategories = [];
                var overallstatedata = []
                var ontimestatedata = []
                var delaystatedata = []
                var onlyOverallStateWiseSeries = []
                state_status_wise_grps.map((item, statekey) => {
                    statesCategories.push(item.consignee_state);

                    overallstatedata.push({
                        name: item.consignee_state,
                        y: item.overall_count,
                        drilldown: item.consignee_state + "_overall",
                        label: item.overall_count
                    });

                    ontimestatedata.push({
                        name: item.consignee_state,
                        y: item["On Time"],
                        drilldown: item.consignee_state + "_ontime",
                        label: item.ontime_per + "%"
                    });

                    delaystatedata.push({
                        name: item.consignee_state,
                        y: item.Delayed,
                        drilldown: item.consignee_state + "_delayed",
                        label: item.delayed_per + "%"
                    });
                })
                // overall chart 3rd one

                onlyOverallStateWiseSeries.push({
                    name: "Overall",
                    data: overallstatedata,
                    // color: '#185cdb'
                    colorByPoint: true,
                },)


                stateLevelSeries.push({
                    name: "Overall",
                    data: overallstatedata,
                    color: '#185cdb'
                }, {
                    name: "Ontime",
                    data: ontimestatedata,
                    color: '#50ed45'
                }
                    , {
                        name: "Delayed",
                        data: delaystatedata,
                        color: '#d93a25'
                    }
                )
                // state_city_status_wise_grps
                var drilldownStateSeries = []
                var overallDrillDownStateSeries = []
                var consigneeStates = groupBy(state_city_status_wise_grps, tdata => tdata.consignee_state);
                // console.log("tranportertates", tranportertates)
                consigneeStates.forEach((consigneeState, consigneeKey) => {
                    var drilldownoverallseries = [];
                    var drilldownontimeseries = [];
                    var drilldowndelayedseries = [];
                    if (consigneeState.length > 0) {
                        consigneeState.map((item) => {
                            if (item.consignee_state == consigneeKey) {

                                drilldownoverallseries.push({
                                    name: item.consignee_city,
                                    drilldown: item.consignee_city + "_overall",
                                    y: item.overall_count,
                                    label: item.overall_count
                                });
                                drilldownontimeseries.push({
                                    name: item.consignee_city,
                                    drilldown: item.consignee_city + '_ontime',
                                    y: item["On Time"],
                                    label: item["On Time"]
                                });
                                drilldowndelayedseries.push({
                                    name: item.consignee_city,
                                    drilldown: item.consignee_city + "_delayed",
                                    y: item.Delayed,
                                    label: item.Delayed
                                });
                            }
                        })
                    }
                    // console.log("drilldownoverallseries ", drilldownoverallseries)

                    drilldownStateSeries.push({
                        name: consigneeKey + "_overall",
                        id: consigneeKey + "_overall",
                        data: drilldownoverallseries
                    }, {
                        name: consigneeKey + "_ontime",
                        id: consigneeKey + "_ontime",
                        data: drilldownontimeseries
                    }, {
                        name: consigneeKey + "_delayed",
                        id: consigneeKey + "_delayed",
                        data: drilldowndelayedseries
                    });

                    // overallDrilldown
                    overallDrillDownStateSeries.push({
                        name: consigneeKey + "_overall",
                        id: consigneeKey + "_overall",
                        data: drilldownoverallseries
                    })

                })
                var consigneeCities = groupBy(state_city_dealer_status_wise_grps, tdata => tdata.consignee_city);
                // console.log("tranportertates", tranportertates)
                consigneeCities.forEach((consigneeCity, consigneeKey) => {
                    var drilldownoverallseries = [];
                    var drilldownontimeseries = [];
                    var drilldowndelayedseries = [];
                    if (consigneeCity.length > 0) {
                        consigneeCity.map((item) => {
                            if (item.consignee_city == consigneeKey) {

                                drilldownoverallseries.push({
                                    name: item.consignee_name,
                                    id: item.consignee_state + "_" + item.consignee_city + "_overall",
                                    // drilldown:item.consignee_state+"_overall_"+item.consignee_city,
                                    y: item.overall_count,
                                    label: item.overall_count
                                });
                                drilldownontimeseries.push({
                                    name: item.consignee_name,
                                    id: item.consignee_state + "_" + item.consignee_city + "_ontime",
                                    // drilldown:item.consignee_state+"_ontime_"+item.consignee_city,
                                    y: item["On Time"],
                                    label: item["On Time"]
                                });
                                drilldowndelayedseries.push({
                                    name: item.consignee_name,
                                    id: item.consignee_state + "_" + item.consignee_city + "_delayed",
                                    // drilldown:item.consignee_city+"_delayed_"+item.consignee_city,
                                    y: item.Delayed,
                                    label: item.Delayed
                                });
                            }
                        })
                    }
                    // console.log("drilldownoverallseries ", drilldownoverallseries)

                    drilldownStateSeries.push({
                        name: consigneeKey + "_overall",
                        id: consigneeKey + "_overall",
                        data: drilldownoverallseries
                    }, {
                        name: consigneeKey + "_ontime",
                        id: consigneeKey + "_ontime",
                        data: drilldownontimeseries
                    }, {
                        name: consigneeKey + "_delayed",
                        id: consigneeKey + "_delayed",
                        data: drilldowndelayedseries
                    });

                    // overall 
                    overallDrillDownStateSeries.push({
                        name: consigneeKey + "_overall",
                        id: consigneeKey + "_overall",
                        data: drilldownoverallseries
                    })

                })


                var secondChartSeries = [];
                secondChartSeries.push({
                    series: stateLevelSeries,
                    drilldown: drilldownStateSeries,
                    categories: statesCategories
                })


                let overALlStateTotalChart = [
                    {
                        series: onlyOverallStateWiseSeries,
                        drilldown: overallDrillDownStateSeries,
                        categories: statesCategories
                    }
                ]
                // console.log('stateLevelSeries', stateLevelSeries)
                // console.log('secondChartSeries',secondChartSeries)
                // console.log('drilldownStateSeries', drilldownStateSeries)
                this.setState({
                    'secondChartSeries': secondChartSeries,
                    'overAllChartStateChart': overALlStateTotalChart,
                    showStateLineGridData: "show-n",
                    showStateColumnChart: "show-m",
                })

            } catch (e) {

            }
        })
    }

    dealerDelayChart = async (lsp_state_city_dealer_status_wise_grps_df) => {
        // var tptDispatches = this.state.tptDispatches;
        var tptDispatches = lsp_state_city_dealer_status_wise_grps_df;
        if (tptDispatches.length > 0) {

            let dealerCategory = []

            let uniqueDealer = groupBy(tptDispatches, rData => rData.consignee_name)
            let srData = [];
            let processCheck = await uniqueDealer.forEach(async (dealer, key) => {
                // console.log("dealer", dealer, "\n key", key)
                if (key != "") {
                    dealerCategory.push(key)
                    var delayedDealerCount = 0;
                    dealer.map(function (tn) {
                        delayedDealerCount = parseInt(delayedDealerCount) + parseInt(tn["Delayed"]);
                    });
                    srData.push({
                        "name": key,
                        "y": delayedDealerCount,
                        // 'label' : `${dealer[0]['delayed_per']} %`
                    })
                }
            })
            Promise.all([processCheck]).then(async () => {
                // console.log('srData',srData)
                srData.sort(GetSortDescOrder("y"))
                // console.log('AfterSrData',srData)

                let seriesData = [
                    {
                        name: "Dealer Delay Data",
                        colorByPoint: true,
                        data: srData.slice(0, 100)
                    }
                ]

                // setTimeout(() => {
                    this.setState({
                        series: seriesData,
                        dealerCategory,
                        title: "Dealer Delay Chart (Top 100)",
                        subtitle: "",
                        yaxistitle: 'Total Delays'
                    })
                    // console.log(seriesData, "seriesData")
                // }, 2500)
            })
        }
    }


    tptWiseDispatches = async () => {
        var tptDispatches = this.state.tptDispatches;
        if (tptDispatches.length > 0) {
            var uniqueTpts = groupBy(tptDispatches, tdata => tdata.service_provider);
            var seriesData = [];
            var overalltptData = [];
            var onTimetptData = [];
            var delayedTimetptData = [];
            var drilldownseriesData = [];
            var categories = [];
            var processChk = await uniqueTpts.forEach(async (trans, transkey) => {
                var tnData = []
                categories.push(transkey);
                var overallCount = 0;
                var onTimeCount = 0;
                var delayedCount = 0;
                await trans.map(function (tn, index) {
                    overallCount = parseInt(overallCount) + parseInt(tn["overall_count"]);
                    onTimeCount = parseInt(onTimeCount) + parseInt(tn["On Time"]);
                    delayedCount = parseInt(delayedCount) + parseInt(tn["Delayed"]);
                });
                // console.log(transkey,overallCount,onTimeCount,delayedCount,"tptname")
                await overalltptData.push({
                    name: transkey,
                    y: overallCount,
                    drilldown: transkey + "_overall",
                    color: '#185cdb',
                    // legendColor : '#53d9db',
                });
                await onTimetptData.push({
                    name: transkey,
                    y: onTimeCount,
                    drilldown: transkey + "_ontime",
                    color: '#50ed45',
                    // legendColor : '#50ed45',
                });
                await delayedTimetptData.push({
                    name: transkey,
                    y: delayedCount,
                    drilldown: transkey + "_delayed",
                    color: '#d93a25',
                    // legendColor :  '#3d3538'
                });
                // STATE LEVEL DRILL DOWN DATA
                var uniqueTptStates = groupBy(trans, sdata => sdata.consignee_state);
                var drStatesOverallData = [];
                var drStatesOnTimeData = [];
                var drStatesDelayedData = [];
                await uniqueTptStates.forEach(async (states, stateskey) => {
                    var overallStateCount = 0;
                    var onTimeStateCount = 0;
                    var delayedStateCount = 0;
                    await states.map(async function (tn) {
                        overallStateCount = parseInt(overallStateCount) + parseInt(tn["overall_count"]);
                        onTimeStateCount = parseInt(onTimeStateCount) + parseInt(tn["On Time"]);
                        delayedStateCount = parseInt(delayedStateCount) + parseInt(tn["Delayed"]);
                    });
                    drStatesOverallData.push({
                        y: overallStateCount,
                        name: stateskey,
                        drilldown: stateskey + "_" + transkey + "_overall"
                    });
                    drStatesOnTimeData.push({
                        y: onTimeStateCount,
                        name: stateskey,
                        drilldown: stateskey + "_" + transkey + "_ontime"
                    });
                    drStatesDelayedData.push({
                        y: delayedStateCount,
                        name: stateskey,
                        drilldown: stateskey + "_" + transkey + "_delayed"
                    })

                    // CITY LEVEL DRILL DOWN DATA
                    var uniqueTptCities = groupBy(states, cdata => cdata.consignee_city);
                    var drCitiesOverallData = [];
                    var drCitiesOnTimeData = [];
                    var drCitiesDelayedData = [];
                    uniqueTptCities.forEach((cities, citieskey) => {
                        var overallCityCount = 0;
                        var onTimeCityCount = 0;
                        var delayedCityCount = 0;
                        cities.map(function (tn) {
                            overallCityCount = parseInt(overallCityCount) + parseInt(tn["overall_count"]);
                            onTimeCityCount = parseInt(onTimeCityCount) + parseInt(tn["On Time"]);
                            delayedCityCount = parseInt(delayedCityCount) + parseInt(tn["Delayed"]);
                        });
                        drCitiesOverallData.push({
                            y: overallCityCount,
                            name: citieskey,
                            drilldown: citieskey + "_" + stateskey + "_" + transkey + "_overall"
                        });
                        drCitiesOnTimeData.push({
                            y: onTimeCityCount,
                            name: citieskey,
                            drilldown: citieskey + "_" + stateskey + "_" + transkey + "_ontime"
                        });
                        drCitiesDelayedData.push({
                            y: delayedCityCount,
                            name: citieskey,
                            drilldown: citieskey + "_" + stateskey + "_" + transkey + "_delayed"
                        });


                        // DEALER LEVEL DRILL DOWN DATA
                        var uniqueTptDealers = groupBy(cities, ddata => ddata.consignee_name);
                        var drDealersOverallData = [];
                        var drDealersOnTimeData = [];
                        var drDealersDelayedData = [];
                        uniqueTptDealers.forEach((dealers, dealerskey) => {
                            var overallDealerCount = 0;
                            var onTimeDealerCount = 0;
                            var delayedDealerCount = 0;
                            dealers.map(function (tn) {
                                overallDealerCount = parseInt(overallDealerCount) + parseInt(tn["overall_count"]);
                                onTimeDealerCount = parseInt(onTimeDealerCount) + parseInt(tn["On Time"]);
                                delayedDealerCount = parseInt(delayedDealerCount) + parseInt(tn["Delayed"]);
                            });
                            drDealersOverallData.push({
                                y: overallDealerCount,
                                name: dealerskey,
                            });
                            drDealersOnTimeData.push({
                                y: onTimeDealerCount,
                                name: dealerskey,
                            });
                            drDealersDelayedData.push({
                                y: delayedDealerCount,
                                name: dealerskey,
                            });
                        })
                        drilldownseriesData.push(
                            {
                                id: citieskey + "_" + stateskey + "_" + transkey + "_overall",
                                data: drDealersOverallData
                            },
                            {
                                id: citieskey + "_" + stateskey + "_" + transkey + "_ontime",
                                data: drDealersOnTimeData
                            },
                            {
                                id: citieskey + "_" + stateskey + "_" + transkey + "_delayed",
                                data: drDealersDelayedData
                            },
                        );
                        var drDealersOverallData = [];
                        var drDealersOnTimeData = [];
                        var drDealersDelayedData = [];
                    })
                    drilldownseriesData.push(
                        {
                            id: stateskey + "_" + transkey + "_overall",
                            data: drCitiesOverallData
                        },
                        {
                            id: stateskey + "_" + transkey + "_ontime",
                            data: drCitiesOnTimeData
                        },
                        {
                            id: stateskey + "_" + transkey + "_delayed",
                            data: drCitiesDelayedData
                        },
                    );
                    var drCitiesOverallData = [];
                    var drCitiesOnTimeData = [];
                    var drCitiesDelayedData = [];
                })


                drilldownseriesData.push(
                    {
                        name: transkey + " Overall",
                        id: transkey + "_overall",
                        data: drStatesOverallData
                    },
                    {
                        name: transkey + " On Time",
                        id: transkey + "_ontime",
                        data: drStatesOnTimeData
                    },
                    {
                        name: transkey + " Delayed",
                        id: transkey + "_delayed",
                        data: drStatesDelayedData
                    }
                );
                var drStatesOverallData = [];
                var drStatesOnTimeData = [];
                var drStatesDelayedData = [];
            });
            Promise.all([processChk]).then(async () => {
                var seriesData = [{
                    name: "Overall",
                    data: overalltptData,
                    color: '#185cdb',

                },
                {
                    name: "On Time",
                    data: onTimetptData,
                    color: '#50ed45',

                },
                {
                    name: "Delayed",
                    data: delayedTimetptData,
                    color: '#d93a25',

                }
                ];

                // console.log(drilldownseriesData,"drilldownseriesData")
                var drilldownseries = {
                    allowPointDrilldown: false,
                    plotOptions: {
                        series: {
                            borderWidth: 0,
                            dataLabels: {
                                enabled: false
                            }
                        }
                    },
                    series: drilldownseriesData
                }
                setTimeout(() => {
                    this.setState({
                        seriesData: seriesData,
                        categories: categories,
                        drilldownseries: drilldownseries,
                        loadshow: 'show-n',
                        showLineGridData: "show-n",
                        showColumnChart: "show-m",
                        showStateLineGridData: "show-n",
                        showStateColumnChart: "show-m",
                        // showWeightLineGridData: "show-n",
                        // showWeightColumnChart: "show-m",
                        showCounttLineGridData: "show-n",
                        showCounttColumnChart: "show-m"
                    })
                    // console.timeEnd();
                    // console.log(categories,"categories")
                    // console.log(seriesData,"seriesData")
                    // console.log(drilldownseries,"drilldownseries")
                }, 2000)
            });

        }
        // var seriesData1 = [{
        //     name: "On Time",
        //     data: [{
        //       name: "Bhanu",
        //       y: 28,
        //       drilldown: 'high'
        //     },
        //     {
        //         name: "Chaitu",
        //         y: 25,
        //         drilldown: 'high'
        //     },
        //     ]
        //   }, {
        //     name: "Delayed",
        //     data: [{
        //       name: "Bhanu",
        //       y: 28,
        //       drilldown: 'high'
        //     },
        //     {
        //         name: "Chaitu",
        //         y: 25,
        //         drilldown: 'high'
        //     },
        //     ]
        //   }];
        // var drilldownseries1 = {
        //     allowPointDrilldown: false,
        //     plotOptions: {
        //         series: {
        //             borderWidth: 0,
        //             dataLabels: {
        //                 enabled: false
        //             }
        //         }
        //     },
        //     series: [{
        //         id: 'high',
        //         data: [{
        //             y: 25,
        //             drilldown: 'high-next'
        //           },
        //           {
        //             y: 30,
        //             drilldown: 'high-next'
        //         }]
        //     },
        //     {
        //         id: 'high-next',
        //         data: [{
        //             y: 100,
        //             drilldown: 'high-next-next'
        //           },
        //           {
        //             y: 120,
        //             drilldown: 'high-next-next'
        //         }]
        //     }, 
        //     {
        //         id: 'high-next-next',
        //         data: [{
        //             y: 200,
        //             drilldown: 'high-next-next'
        //           },
        //           {
        //             y: 180,
        //             drilldown: 'high-next-next'
        //         }]
        //     },
        //     {
        //         id: 'high2',
        //         data: [{
        //             y: 25,
        //             drilldown: 'high-next-2'
        //           },
        //           {
        //             y: 30,
        //             drilldown: 'high-next-2'
        //         }]
        //     }]
        // }
        // var categories1 = ["Bhanu","Chaitu"];
        // console.log(drilldownseries1,"drilldownseries1")
    }

    statecWiseDispatches = async () => {
        var tptDispatches = this.state.tptDispatches;
        if (tptDispatches.length > 0) {
            var seriesData = [];
            var overalltptData = [];
            var onTimetptData = [];
            var delayedTimetptData = [];
            var drilldownseriesData = [];
            var categories = [];
            // STATE LEVEL DRILL DOWN DATA
            var uniqueTptStates = groupBy(tptDispatches, sdata => sdata.consignee_state);
            var processChk = await await uniqueTptStates.forEach(async (states, stateskey) => {
                categories.push(stateskey);
                var overallCount = 0;
                var onTimeCount = 0;
                var delayedCount = 0;
                await states.map(async function (tn) {
                    overallCount = parseInt(overallCount) + parseInt(tn["overall_count"]);
                    onTimeCount = parseInt(onTimeCount) + parseInt(tn["On Time"]);
                    delayedCount = parseInt(delayedCount) + parseInt(tn["Delayed"]);
                });
                await overalltptData.push({
                    name: stateskey,
                    y: overallCount,
                    drilldown: stateskey + "_overall",
                    color: '#185cdb',

                });
                await onTimetptData.push({
                    name: stateskey,
                    y: onTimeCount,
                    drilldown: stateskey + "_ontime",
                    color: '#50ed45',

                });
                await delayedTimetptData.push({
                    name: stateskey,
                    y: delayedCount,
                    drilldown: stateskey + "_delayed",
                    color: '#d93a25',

                });

                // CITY LEVEL DRILL DOWN DATA
                var uniqueTptCities = groupBy(states, cdata => cdata.consignee_city);
                var drCitiesOverallData = [];
                var drCitiesOnTimeData = [];
                var drCitiesDelayedData = [];
                uniqueTptCities.forEach((cities, citieskey) => {
                    var overallCityCount = 0;
                    var onTimeCityCount = 0;
                    var delayedCityCount = 0;
                    cities.map(function (tn) {
                        overallCityCount = parseInt(overallCityCount) + parseInt(tn["overall_count"]);
                        onTimeCityCount = parseInt(onTimeCityCount) + parseInt(tn["On Time"]);
                        delayedCityCount = parseInt(delayedCityCount) + parseInt(tn["Delayed"]);
                    });
                    drCitiesOverallData.push({
                        y: overallCityCount,
                        name: citieskey,
                        drilldown: citieskey + "_" + stateskey + "_overall"
                    });
                    drCitiesOnTimeData.push({
                        y: onTimeCityCount,
                        name: citieskey,
                        drilldown: citieskey + "_" + stateskey + "_ontime"
                    });
                    drCitiesDelayedData.push({
                        y: delayedCityCount,
                        name: citieskey,
                        drilldown: citieskey + "_" + stateskey + "_delayed"
                    });


                    // DEALER LEVEL DRILL DOWN DATA
                    var uniqueTptDealers = groupBy(cities, ddata => ddata.consignee_name);
                    var drDealersOverallData = [];
                    var drDealersOnTimeData = [];
                    var drDealersDelayedData = [];
                    uniqueTptDealers.forEach((dealers, dealerskey) => {
                        var overallDealerCount = 0;
                        var onTimeDealerCount = 0;
                        var delayedDealerCount = 0;
                        dealers.map(function (tn) {
                            overallDealerCount = parseInt(overallDealerCount) + parseInt(tn["overall_count"]);
                            onTimeDealerCount = parseInt(onTimeDealerCount) + parseInt(tn["On Time"]);
                            delayedDealerCount = parseInt(delayedDealerCount) + parseInt(tn["Delayed"]);
                        });
                        drDealersOverallData.push({
                            y: overallDealerCount,
                            name: dealerskey,
                        });
                        drDealersOnTimeData.push({
                            y: onTimeDealerCount,
                            name: dealerskey,
                        });
                        drDealersDelayedData.push({
                            y: delayedDealerCount,
                            name: dealerskey,
                        });
                    })
                    drilldownseriesData.push(
                        {
                            id: citieskey + "_" + stateskey + "_overall",
                            data: drDealersOverallData
                        },
                        {
                            id: citieskey + "_" + stateskey + "_ontime",
                            data: drDealersOnTimeData
                        },
                        {
                            id: citieskey + "_" + stateskey + "_delayed",
                            data: drDealersDelayedData
                        },
                    );
                    var drDealersOverallData = [];
                    var drDealersOnTimeData = [];
                    var drDealersDelayedData = [];
                })
                drilldownseriesData.push(
                    {
                        id: stateskey + "_overall",
                        data: drCitiesOverallData
                    },
                    {
                        id: stateskey + "_ontime",
                        data: drCitiesOnTimeData
                    },
                    {
                        id: stateskey + "_delayed",
                        data: drCitiesDelayedData
                    },
                );
                var drCitiesOverallData = [];
                var drCitiesOnTimeData = [];
                var drCitiesDelayedData = [];
            })
            Promise.all([processChk]).then(async () => {
                var seriesData = [{
                    name: "Total Dispatches",
                    data: overalltptData,
                    color: '#185cdb',
                },
                {
                    name: "On Time",
                    data: onTimetptData,
                    color: '#50ed45',
                },
                {
                    name: "Delayed",
                    data: delayedTimetptData,
                    color: '#d93a25',
                }
                ];

                // console.log(drilldownseriesData,"drilldownseriesData")
                var drilldownseries = {
                    allowPointDrilldown: false,
                    plotOptions: {
                        series: {
                            borderWidth: 0,
                            dataLabels: {
                                enabled: false
                            }
                        }
                    },
                    series: drilldownseriesData
                }
                setTimeout(() => {
                    this.setState({
                        statecseriesData: seriesData,
                        stateccategories: categories,
                        statecdrilldownseries: drilldownseries,
                        loadshow: 'show-n',
                        showStateLineGridData: "show-n",
                        showStateColumnChart: "show-m",
                    })
                    // console.log(categories,"statesc-categories")
                    // console.log(seriesData,"statesc-seriesData")
                    // console.log(drilldownseries.series,"statesc-drilldownseries")
                }, 4000)
            });

        }
    }

    stateWiseDispatches = async () => {
        var stateDispatches = this.state.stateDispatches;
        if (stateDispatches.length > 0) {
            var subseriesData = [];
            var overalltptData = [];
            var onTimetptData = [];
            var delayedTimetptData = [];
            var drilldownseriesData = [];
            var categories = [];

            // STATE LEVEL DRILL DOWN DATA
            var uniqueTptStates = groupBy(stateDispatches, sdata => sdata.consignee_state);
            var drStatesOverallData = [];
            var drStatesOnTimeData = [];
            var drStatesDelayedData = [];
            // let statesList = []
            var processChk = await uniqueTptStates.forEach(async (states, stateskey) => {
                var overallStateCount = 0;
                await states.map(async function (tn) {
                    overallStateCount = parseInt(overallStateCount) + parseInt(tn["count"]);
                });
                subseriesData.push({
                    y: overallStateCount,
                    name: stateskey,
                    data: [overallStateCount],
                    drilldown: stateskey + "_overall"
                });

                // CITY LEVEL DRILL DOWN DATA
                var uniqueTptCities = groupBy(states, cdata => cdata.consignee_city);
                var drCitiesOverallData = [];
                uniqueTptCities.forEach((cities, citieskey) => {
                    var overallCityCount = 0;
                    cities.map(function (tn) {
                        overallCityCount = parseInt(overallCityCount) + parseInt(tn["count"]);
                    });
                    drCitiesOverallData.push({
                        y: overallCityCount,
                        name: citieskey,
                        drilldown: citieskey + "_" + stateskey + "_overall"
                    });


                    // DEALER LEVEL DRILL DOWN DATA
                    var uniqueTptDealers = groupBy(cities, ddata => ddata.consignee_name);
                    var drDealersOverallData = [];
                    uniqueTptDealers.forEach((dealers, dealerskey) => {
                        var overallDealerCount = 0;
                        dealers.map(function (tn) {
                            overallDealerCount = parseInt(overallDealerCount) + parseInt(tn["count"]);
                        });
                        drDealersOverallData.push({
                            y: overallDealerCount,
                            name: dealerskey,
                        });
                    })
                    drilldownseriesData.push(
                        {
                            id: citieskey + "_" + stateskey + "_overall",
                            data: drDealersOverallData
                        }
                    );
                    var drDealersOverallData = [];
                })
                drilldownseriesData.push(
                    {
                        id: stateskey + "_overall",
                        data: drCitiesOverallData
                    }
                );
                var drCitiesOverallData = [];
            });
            Promise.all([processChk]).then(async () => {
                var seriesData = [
                    {
                        name: "States",
                        colorByPoint: true,
                        data: subseriesData
                    }
                ];

                // console.log(drilldownseriesData,"drilldownseriesData")
                var drilldownseries = {
                    allowPointDrilldown: false,
                    plotOptions: {
                        series: {
                            borderWidth: 0,
                            dataLabels: {
                                enabled: false
                            }
                        }
                    },
                    series: drilldownseriesData
                }
                setTimeout(() => {
                    this.setState({
                        stateseriesData: seriesData,
                        statecategories: categories,
                        statedrilldownseries: drilldownseries,
                        // loadshow: 'show-n',
                    })
                    // console.log(categories,"state-categories")
                    // console.log(seriesData,"state-seriesData")
                    // console.log(drilldownseries,"state-drilldownseries")
                }, 2000)
            });

        }
    }

    daycWiseDispatches = async () => {
        try{
        
        var daycDispatches = this.state.daycDispatches;
        if (daycDispatches.length > 0) {
            var subseriesData = [];
            var overalltptData = [];
            var onTimetptData = [];
            var delayedTimetptData = [];
            var drilldownseriesData = [];
            var categories = [];
            var uniqueDays = groupBy(daycDispatches, sdata => sdata.days);
            var drStatesOverallData = [];
            var drStatesOnTimeData = [];
            var drStatesDelayedData = [];
            var processChk = uniqueDays.forEach(async (days, dayskey) => {
                var overallDaysCount = 0;
                days.map(async function (tn) {
                    overallDaysCount = parseInt(overallDaysCount) + parseInt(tn["count"]);
                });
                subseriesData.push({
                    y: overallDaysCount,
                    name: dayskey,
                    data: [overallDaysCount],
                    drilldown: dayskey + "_overall"
                });
                // STATE LEVEL DRILL DOWN DATA
                var uniqueTptStates = groupBy(days, sdata => sdata.consignee_state);
                var drStatesOverallData = [];
                uniqueTptStates.forEach(async (states, stateskey) => {
                    var overallStateCount = 0;
                    states.map(async function (tn) {
                        overallStateCount = parseInt(overallStateCount) + parseInt(tn["count"]);
                    });
                    drStatesOverallData.push({
                        y: overallStateCount,
                        name: stateskey,
                        drilldown: stateskey + "_" + dayskey + "_overall"
                    });
                    // CITY LEVEL DRILL DOWN DATA
                    var uniqueTptCities = groupBy(states, cdata => cdata.consignee_city);
                    var drCitiesOverallData = [];
                    uniqueTptCities.forEach((cities, citieskey) => {
                        var overallCityCount = 0;
                        cities.map(function (tn) {
                            overallCityCount = parseInt(overallCityCount) + parseInt(tn["count"]);
                        });
                        drCitiesOverallData.push({
                            y: overallCityCount,
                            name: citieskey,
                            drilldown: citieskey + "_" + stateskey + "_" + dayskey + "_overall"
                        });


                        // DEALER LEVEL DRILL DOWN DATA
                        var uniqueTptDealers = groupBy(cities, ddata => ddata.consignee_name);
                        var drDealersOverallData = [];
                        uniqueTptDealers.forEach((dealers, dealerskey) => {
                            var overallDealerCount = 0;
                            dealers.map(function (tn) {
                                overallDealerCount = parseInt(overallDealerCount) + parseInt(tn["count"]);
                            });
                            drDealersOverallData.push({
                                y: overallDealerCount,
                                name: dealerskey,
                            });
                        })
                        drilldownseriesData.push(
                            {
                                id: citieskey + "_" + stateskey + "_" + dayskey + "_overall",
                                data: drDealersOverallData
                            }
                        );
                        var drDealersOverallData = [];
                    })
                    drilldownseriesData.push(
                        {
                            id: stateskey + "_" + dayskey + "_overall",
                            data: drCitiesOverallData
                        }
                    );
                    var drCitiesOverallData = [];
                });
                drilldownseriesData.push(
                    {
                        id: dayskey + "_overall",
                        data: drStatesOverallData
                    }
                );
                var drStatesOverallData = [];
            });
            Promise.all([processChk]).then(async () => {
                var seriesData = [
                    {
                        name: "No Of Dispatches",
                        colorByPoint: true,
                        data: subseriesData
                    }
                ];

                // console.log(drilldownseriesData,"drilldownseriesData")
                var drilldownseries = {
                    allowPointDrilldown: false,
                    plotOptions: {
                        series: {
                            borderWidth: 0,
                            dataLabels: {
                                enabled: false
                            }
                        }
                    },
                    series: drilldownseriesData
                }
                setTimeout(() => {
                    this.setState({
                        daycseriesData: seriesData,
                        dayccategories: categories,
                        daycdrilldownseries: drilldownseries,
                        loadshow: 'show-n',
                        showCountLineGridData: "show-n",
                        showCountColumnChart: "show-m"
                    })
                    // console.log(categories,"dayc-categories")
                    // console.log(seriesData,"dayc-seriesData")
                    // console.log(drilldownseries,"dayc-drilldownseries")
                }, 2000)
            });

        }
        }catch(e){

        }
    }

    daywWiseDispatches = async () => {
        try{
        var daywDispatches = this.state.daywDispatches;
        if (daywDispatches.length > 0) {
            var subseriesData = [];
            var overalltptData = [];
            var onTimetptData = [];
            var delayedTimetptData = [];
            var drilldownseriesData = [];
            var categories = [];
            var uniqueDays = groupBy(daywDispatches, sdata => sdata.days);
            var drStatesOverallData = [];
            var drStatesOnTimeData = [];
            var drStatesDelayedData = [];
            var processChk = await uniqueDays.forEach(async (days, dayskey) => {
                var overallDaysCount = 0;
                await days.map(async function (tn) {
                    overallDaysCount = parseFloat(overallDaysCount) + parseFloat(tn["actual_weight_kgs"]);
                });
                if (overallDaysCount == Math.floor(overallDaysCount)) {

                }
                else {
                    overallDaysCount = parseFloat(overallDaysCount.toFixed(2))
                }
                subseriesData.push({
                    y: overallDaysCount,
                    name: dayskey,
                    data: [overallDaysCount],
                    drilldown: dayskey + "_overall"
                });
                // STATE LEVEL DRILL DOWN DATA
                var uniqueTptStates = groupBy(days, sdata => sdata.consignee_state);
                var drStatesOverallData = [];
                await uniqueTptStates.forEach(async (states, stateskey) => {
                    var overallStateCount = 0;
                    await states.map(async function (tn) {
                        overallStateCount = parseFloat(overallStateCount) + parseFloat(tn["actual_weight_kgs"]);
                    });
                    if (overallStateCount == Math.floor(overallStateCount)) {

                    }
                    else {
                        overallStateCount = parseFloat(overallStateCount.toFixed(2))
                    }
                    drStatesOverallData.push({
                        y: overallStateCount,
                        name: stateskey,
                        drilldown: stateskey + "_" + dayskey + "_overall"
                    });
                    // CITY LEVEL DRILL DOWN DATA
                    var uniqueTptCities = groupBy(states, cdata => cdata.consignee_city);
                    var drCitiesOverallData = [];
                    uniqueTptCities.forEach((cities, citieskey) => {
                        var overallCityCount = 0;
                        cities.map(function (tn) {
                            overallCityCount = parseFloat(overallCityCount) + parseFloat(tn["actual_weight_kgs"]);
                        });
                        if (overallCityCount == Math.floor(overallCityCount)) {

                        }
                        else {
                            overallCityCount = parseFloat(overallCityCount.toFixed(2))
                        }
                        drCitiesOverallData.push({
                            y: overallCityCount,
                            name: citieskey,
                            drilldown: citieskey + "_" + stateskey + "_" + dayskey + "_overall"
                        });


                        // DEALER LEVEL DRILL DOWN DATA
                        var uniqueTptDealers = groupBy(cities, ddata => ddata.consignee_name);
                        var drDealersOverallData = [];
                        uniqueTptDealers.forEach((dealers, dealerskey) => {
                            var overallDealerCount = 0;
                            dealers.map(function (tn) {
                                overallDealerCount = parseFloat(overallDealerCount) + parseFloat(tn["actual_weight_kgs"]);
                            });
                            if (overallDealerCount == Math.floor(overallDealerCount)) {

                            }
                            else {
                                overallDealerCount = parseFloat(overallDealerCount.toFixed(2))
                            }
                            drDealersOverallData.push({
                                y: overallDealerCount,
                                name: dealerskey,
                            });
                        })
                        drilldownseriesData.push(
                            {
                                id: citieskey + "_" + stateskey + "_" + dayskey + "_overall",
                                data: drDealersOverallData
                            }
                        );
                        var drDealersOverallData = [];
                    })
                    drilldownseriesData.push(
                        {
                            id: stateskey + "_" + dayskey + "_overall",
                            data: drCitiesOverallData
                        }
                    );
                    var drCitiesOverallData = [];
                });
                drilldownseriesData.push(
                    {
                        id: dayskey + "_overall",
                        data: drStatesOverallData
                    }
                );
                var drStatesOverallData = [];
            });
            Promise.all([processChk]).then(async () => {
                var seriesData = [
                    {
                        name: "Weights",
                        colorByPoint: true,
                        data: subseriesData
                    }
                ];

                // console.log(drilldownseriesData,"drilldownseriesData")
                var drilldownseries = {
                    allowPointDrilldown: false,
                    plotOptions: {
                        series: {
                            borderWidth: 0,
                            dataLabels: {
                                enabled: false
                            }
                        }
                    },
                    series: drilldownseriesData
                }
                setTimeout(() => {
                    this.setState({
                        daywseriesData: seriesData,
                        daywcategories: categories,
                        daywdrilldownseries: drilldownseries,
                        loadshow: 'show-n',
                        showCounttLineGridData: "show-n",
                        showCounttColumnChart: "show-m"
                    })
                    // console.log(categories,"dayw-categories")
                    // console.log(seriesData,"dayw-seriesData")
                    // console.log(drilldownseries,"dayw-drilldownseries")
                }, 2000)
            });
        }
        }catch(e){

        }
    }

    handlerStartDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);

        var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());
        if (this.state.endDate != undefined && this.state.endDate != "") {
            // console.log(startdate,"startdate")
            // console.log(this.state.endDate,"endDate")
            var startDate = new Date(startdate);
            var endDate = new Date(this.state.endDate);
            var startTime = startDate.getTime();
            var endTime = endDate.getTime();
            // console.log(startTime,endTime);
            if (startTime > endTime) {
                this.setState({
                    startDate: ""
                });
                alert("To Date should be greater than From Date");
            }
            else {
                this.setState({
                    startDate: startdate
                });
            }
        }
        else {
            this.setState({
                startDate: startdate
            });
        }
        //let value = event.target.value;
        //this.setState({'startDate':value});
    }

    handlerEndDateTime = (event, currentDate, selectedDate) => {
        var d = new Date(event._d);
        var edate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
        if (this.state.endDate != undefined && this.state.endDate != "") {
            // console.log(this.state.startDate,"startdate")
            // console.log(edate,"endDate")
            var startDate = new Date(this.state.startDate);
            var endDate = new Date(edate);
            var startTime = startDate.getTime();
            var endTime = endDate.getTime();
            // console.log(startTime,endTime);
            if (startTime > endTime) {
                this.setState({
                    endDate: ""
                });
                alert("To Date should be greater than From Date");
            }
            else {
                this.setState({
                    endDate: edate
                });
            }
        }
        else {
            this.setState({
                endDate: edate
            });
        }
    }

    onClickGetLoadingData = () => {
        const { service_provider, consignee_states, consignee_city, startDate, endDate, statesList, data_search_on, day_highchartoptions, transit_days } = this.state
        // console.log('transporter', transporter, '\n consignee_states', consignee_states, '\n consignee_city', consignee_city, '\n startDate', startDate, '\n endDate', endDate, '\n data_search_on', data_search_on)
        if (consignee_states == null || service_provider == null || consignee_city == null) {
            alert('please select atleast one option')
        } else {
            let params = {
                'service_provider': service_provider,
                'consignee_city': consignee_city,
                'consignee_states': consignee_states,
                'startDate': startDate,
                'endDate': endDate,
                'data_search_on': data_search_on.value,
                'transit_days': transit_days
            }
            this.setState({
                "loadshow": "show-m"
            });
            this.onLoadGetData(params)
        }
    }
    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };

    onClickShowMonthlyChartGrid = () => {
        this.setState({
            showLineGridData: "show-m",
            showColumnChart: "show-n"
        })
    }

    onClickShowMonthlyChartView = () => {
        this.setState({
            showLineGridData: "show-n",
            showColumnChart: "show-m"
        })
    }

    onClickShowMonthlyStateChartGrid = () => {
        this.setState({
            showStateLineGridData: "show-m",
            showStateColumnChart: "show-n"
        })
    }

    onClickShowMonthlyStateChartView = () => {
        this.setState({
            showStateLineGridData: "show-n",
            showStateColumnChart: "show-m"
        })
    }

    onClickShowMonthlyCountChartGrid = () => {
        this.setState({
            showCountLineGridData: "show-m",
            showCountColumnChart: "show-n"
        })
    }
    onClickShowMonthlyCountChartView = () => {
        this.setState({
            showCountLineGridData: "show-n",
            showCountColumnChart: "show-m"
        })
    }

    onClickShowMonthlyWeightChartGrid = () => {
        this.setState({
            showWeightLineGridData: "show-m",
            showWeightColumnChart: "show-n"
        })
    }
    onClickShowMonthlyWeightChartView = () => {
        this.setState({
            showWeightLineGridData: "show-n",
            showWeightColumnChart: "show-m"
        })
    }


    onGridState = () => {
        //console.log(this.gridApi);
        /*Get  Current Columns State and Store in this.colState */
        this.colState = this.gridColumnApi.getColumnState();
        /*Get Current RowGroup Columns State and Store in this.rowGroupState */
        this.rowGroupState = this.gridColumnApi.getRowGroupColumns();
        /*Get Current Pivot Columns State and Store in this.pivotColumns, 
         * User should enable the Pivot mode.*/
        this.pivotColumns = this.gridColumnApi.getPivotColumns();
        /*Get Current Filter State and Store in window.filterState */
        window.filterState = this.gridApi.getFilterModel();
        this.gridApi.setFilterModel(window.filterState);
    }

    render() {
        const dStyles = {
            width: '100%',
            height: '100%'
        }
        const { highchartoptions, day_highchartoptions, state_highchartoptions, weight_highchartoptions, transList } = this.state
        const columnwithDefs = [
            {
                headerName: "GR No",
                field: "consignment_code",
                width: 115,
                editable: false,
                pinned: 'left',
                filter: "agSetColumnFilter",
                //cellRenderer: "agGroupCellRenderer"
            },
            {
                headerName: "Service Provider Name",
                field: "service_provider",
                width: 130,
                filter: "agSetColumnFilter",
                cellRenderer: ''
            },
            {
                headerName: "Invoice Date",
                field: "invoice_date",
                width: 120,
                // filter: "agSetColumnFilter",
                cellRenderer: '',
                // pinned: 'left',
                // sort: "desc",
                valueGetter: function (params) {
                    if(params.data.invoice_date !== "" && params.data.invoice_date !== undefined){
                        return getHyphenDDMMMYYYYHHMM(params.data.invoice_date)
                    }else{
                        return ""
                    }
                },
            },
            {
                headerName: "Consignee Name",
                field: "consignee_name",
                width: 130,
                filter: "agSetColumnFilter",
                resizable: true
            },
            {
                headerName: "Consignee City",
                field: "consignee_city",
                width: 130,
                filter: "agSetColumnFilter",
                cellRenderer: ''
            },

            {
                headerName: "Consignee State",
                field: "consignee_state",
                width: 130,
                filter: "agSetColumnFilter",
                cellRenderer: ''
            },
            // {
            //     headerName: "No Of Dispatches",
            //     field: "no_of_packages",
            //     width: 130,
            //     filter: "agSetColumnFilter",
            //     cellRenderer: ''
            // },
            {
                headerName: "Consigner Code",
                field: "consigner_code",
                width: 130,
                filter: "agSetColumnFilter",
                cellRenderer: ''
            },
            // {
            //     headerName: "Courier Weight (KGs)",
            //     field: "actual_weight_kgs",
            //     width: 130,
            //     filter: "agSetColumnFilter",
            //     cellRenderer: ''
            // }, 
            {
                headerName: "Status",
                field: "consignment_status",
                width: 120,
                pinned: 'left',
                filter: "agSetColumnFilter",
                cellStyle: function (params) {
                    if (params.value == 'Delivered') {
                        //mark police cells as red
                        return { color: 'green' };
                    } else {
                        return null;
                    }
                },
                valueGetter: function (params) {
                    if (params.data.consignment_status == "Cancelled") {
                        return "Cancelled As Per Volvo Eicher"
                    }
                    else {
                        return params.data.consignment_status
                    }
                }
            },
            {
                headerName: "Delivered On",
                field: "delivery_datetime",
                // hide: hideonlyacive,
                width: 150,
                filter: "agSetColumnFilter",
                cellRenderer: '',
                valueGetter: function (params) {
                    if (params.data.consignment_status !== 'IN TRANSIT') {
                        if(params.data.delivery_datetime !== "" && params.data.delivery_datetime !== undefined){
                            return getHyphenDDMMMYYYYHHMM(params.data.delivery_datetime)
                        }else{
                        return ""

                        }
                    }else{
                        return ""
                    }
                },
            }

        ]
        // console.log('highchartoptions', highchartoptions)
        return (
            <div className="col-xl-12 col-lg-12">
                <div className={"col-xl-12 col-lg-12 tab- pt-20px ml-18p whiteRow "}>
                    <div className="col-xl-12 col-lg-12">
                        <h5>
                            <i className="icofont icofont-dashboard-web cus-i"></i> <span>Dashboard</span>

                        </h5>
                    </div>
                    <div className="card-body n-p-0 mt-10p">

                        <div className="row pt-20px tab-brd">
                            <div className="row col-xl-12 col-lg-12">
                                <div className="form-group col-xl-3 col-lg-3">
                                    <label>Data Search On: </label>
                                    <Select placeholder={"All"} isMulti={false} value={this.state.data_search_on} className="border-radius-0" onChange={(e) => this.setState({ data_search_on: e })} style={{ borderRadius: "0px" }} options={[{ value: 'BOOKING', label: 'Invoice Date' }, { label: 'Delivery Datetime', value: 'delivery_datetime' }]} required />
                                </div>

                                <div className="form-group col-xl-3 col-lg-3">
                                    <label>Start Date: </label>
                                    <Datetime value={this.state.startDate}
                                        disableCloseOnClickOutside={false}
                                        closeOnSelect={true}
                                        inputProps={{ placeholder: 'Start Date', name: 'startDate', autoComplete: 'off' }}
                                        dateFormat="YYYY-MM-DD"
                                        name="startDate"
                                        onChange={this.handlerStartDateTime} />
                                </div>

                                <div className="form-group col-xl-3 col-lg-3">
                                    <label>End Date: </label>
                                    <Datetime value={this.state.endDate}
                                        disableCloseOnClickOutside={false}
                                        closeOnSelect={true}
                                        inputProps={{ placeholder: 'End Date', name: 'endDate', autoComplete: 'off' }}
                                        dateFormat="YYYY-MM-DD"
                                        name="endDate"
                                        onChange={this.handlerEndDateTime} />
                                </div>

                                <div className="form-group col-xl-3 col-lg-3">
                                    <label>Service Provider: </label>
                                    <Select placeholder={"All"} isMulti={true} value={this.state.service_provider} className="border-radius-0" onChange={(e) => this.setState({ service_provider: e })} style={{ borderRadius: "0px" }} options={transList} required />
                                </div>

                                <div className="form-gplantwiseloadingroup col-xl-3 col-lg-3">
                                    <label>Consignee State: </label>
                                    <Select placeholder={"All"} isMulti={true} value={this.state.consignee_states} className="border-radius-0" onChange={(e) => this.setState({ consignee_states: e })} style={{ borderRadius: "0px" }} options={this.state.statesList} required />
                                </div>

                                <div className="form-gplantwiseloadingroup col-xl-3 col-lg-3">
                                    <label>Consignee City: </label>
                                    <Select placeholder={"All"} isMulti={true} value={this.state.consignee_city} className="border-radius-0" onChange={(e) => this.setState({ consignee_city: e })} style={{ borderRadius: "0px" }} options={this.state.city_list} required />
                                </div>

                                <div className="form-gplantwiseloadingroup col-xl-3 col-lg-3">
                                    <label>Delay Threshold: </label>
                                    <Select placeholder={"All"} isMulti={false} value={this.state.transit_days} className="border-radius-0" onChange={(e) => this.setState({ transit_days: e })} style={{ borderRadius: "0px" }} 
                                    options={[{label:"All",value:"ALL"},{label:"0 - 3 days",value:"0 - 3 days"}, 
                                    {label:"3 - 6 days",value:"3 - 6 days"}
                                    ,{label:"6 - 9 days",value:"6 - 9 days"},{label:"9 days or more",value:"9 days or more"}]} required />
                                </div>

                                <div className="form-group col-xl-3 col-lg-3">
                                    <label>&nbsp;</label><br />
                                    <button type="button" className="btn btn-success" onClick={this.onClickGetLoadingData}>Submit</button>
                                </div>

                            </div>
                        </div>

                        {/* Transporter wise */}
                        <div className="row mt-20p">
                            {(this.state.firstchartseries.length > 0) &&
                                (
                                    <div className={"col-xl-12 col-lg-12 "}>
                                        <span className={this.state.showColumnChart}>
                                            <ColumnGroupDrilldownChart
                                                title={"Service Provider Wise Dispatches"}
                                                subtitle={"Total Dispatches"}
                                                context={this}
                                                series={this.state.firstchartseries[0].series}
                                                categories={this.state.firstchartseries[0].categories}
                                                yaxistitle={"No Of Dispatches"}
                                                dept_code={"SNDG"}
                                                drilldownseries={this.state.firstchartseries[0].drilldown}
                                            />
                                        </span>
                                        <button
                                            type="button"
                                            className={"btn btn-defaultc " + (this.state.showColumnChart)}
                                            onClick={this.onClickShowMonthlyChartGrid}
                                            style={{ position: "absolute", right: "30px", top: "1px", zIndex: "99" }}>Show Data</button>
                                        <button
                                            type="button"
                                            className={"btn btn-defaultc " + (this.state.showLineGridData)}
                                            onClick={this.onClickShowMonthlyChartView}
                                            style={{ position: "absolute", right: "30px", top: "1px", zIndex: "99" }}>Show Chart</button>
                                        <div className={"col-sm-12 " + (this.state.showLineGridData)}>
                                            <h4 style={{ textAlign: "center", fontSize: "18px", lineHeight: "1.6em", fontFamily: "Lucida Grande, Lucida Sans Unicode, Arial" }}>
                                                Total Courier Consignments</h4>
                                            <div id="myGrid11" style={{ height: "400px", width: "100%", marginTop: "13px" }} className="ag-theme-balham">
                                                <AgGridReact
                                                    modules={this.state.modules}
                                                    columnDefs={columnwithDefs}
                                                    defaultColDef={this.state.defaultColDef}
                                                    rowData={this.state.rowData}
                                                    enableCharts={true}
                                                    enableRangeSelection={true}
                                                    onGridReady={this.onGridReady}
                                                    onGridState={this.onGridState}
                                                    statusBar={this.state.statusBar}
                                                    sideBar={this.state.sideBar}
                                                    paginationPageSize={this.state.paginationPageSize}
                                                    pagination={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                        </div>

                        <div className="row mt-20p">
                            {(this.state.secondChartSeries.length > 0) &&
                                (
                                    <div className={"col-xl-12 col-lg-12 "}>
                                        <span className={this.state.showStateColumnChart}>
                                            <ColumnGroupDrilldownChart
                                                title={"State Wise Dispatches"}
                                                subtitle={"Total Dispatches"}
                                                context={this}
                                                series={this.state.secondChartSeries[0].series}
                                                categories={this.state.secondChartSeries[0].categories}
                                                yaxistitle={"No Of Dispatches"}
                                                dept_code={"SNDG"}
                                                drilldownseries={this.state.secondChartSeries[0].drilldown}
                                            />
                                        </span>
                                        <button
                                            type="button"
                                            className={"btn btn-defaultc " + (this.state.showStateColumnChart)}
                                            onClick={this.onClickShowMonthlyStateChartGrid}
                                            style={{ position: "absolute", right: "30px", top: "1px", zIndex: "99" }}>Show Data</button>
                                        <button
                                            type="button"
                                            className={"btn btn-defaultc " + (this.state.showStateLineGridData)}
                                            onClick={this.onClickShowMonthlyStateChartView}
                                            style={{ position: "absolute", right: "30px", top: "1px", zIndex: "99" }}>Show Chart</button>
                                        <div className={"col-sm-12 " + (this.state.showStateLineGridData)}>
                                            <h4 style={{ textAlign: "center", fontSize: "18px", lineHeight: "1.6em", fontFamily: "Lucida Grande, Lucida Sans Unicode, Arial" }}>
                                                Total Courier Consignments</h4>
                                            <div id="myGrid11" style={{ height: "400px", width: "100%", marginTop: "13px" }} className="ag-theme-balham">
                                                <AgGridReact
                                                    modules={this.state.modules}
                                                    columnDefs={columnwithDefs}
                                                    defaultColDef={this.state.defaultColDef}
                                                    rowData={this.state.rowData}
                                                    enableCharts={true}
                                                    enableRangeSelection={true}
                                                    onGridReady={this.onGridReady}
                                                    onGridState={this.onGridState}
                                                    statusBar={this.state.statusBar}
                                                    sideBar={this.state.sideBar}
                                                    paginationPageSize={this.state.paginationPageSize}
                                                    pagination={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                        </div>
                        {/* State wise comparison */}
                        {/* <div className="row mt-20p">
                            {(Object.keys(this.state.statecdrilldownseries).length) &&
                                <div className={"col-xl-12 col-lg-12 "}>
                                    <span className={this.state.showStateColumnChart}>
                                        <DashboardChart
                                            title={"State Wise Dispatches"}
                                            subtitle={"Total Dispatches"}
                                            context={this}
                                            series={this.state.statecseriesData}
                                            categories={this.state.stateccategories}
                                            yaxistitle={"No Of Dispatches"}
                                            dept_code={"SNDG"}
                                            drilldownseries={this.state.statecdrilldownseries}
                                        />
                                    </span>
                                    <button
                                        type="button"
                                        className={"btn btn-defaultc " + (this.state.showStateColumnChart)}
                                        onClick={this.onClickShowMonthlyStateChartGrid}
                                        style={{ position: "absolute", right: "30px", top: "1px", zIndex: "99" }}>Show Data</button>
                                    <button
                                        type="button"
                                        className={"btn btn-defaultc " + (this.state.showStateLineGridData)}
                                        onClick={this.onClickShowMonthlyStateChartView}
                                        style={{ position: "absolute", right: "30px", top: "1px", zIndex: "99" }}>Show Chart</button>
                                    <div className={"col-sm-12 " + (this.state.showStateLineGridData)}>
                                        <h4 style={{ textAlign: "center", fontSize: "18px", lineHeight: "1.6em", fontFamily: "Lucida Grande, Lucida Sans Unicode, Arial" }}>
                                            Total Courier Consignments</h4>                                     
                                        <div id="myGrid11" style={{ height: "400px", width: "100%", marginTop: "13px" }} className="ag-theme-balham">
                                            <AgGridReact
                                                modules={this.state.modules}                                                
                                                columnDefs={columnwithDefs}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.rowData}
                                                enableCharts={true}
                                                enableRangeSelection={true}
                                                onGridReady={this.onGridReady}
                                                onGridState={this.onGridState}
                                                statusBar={this.state.statusBar}
                                                sideBar={this.state.sideBar}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                            />
                                        </div>
                                    </div>

                                </div>
                            }
                        </div> */}
                        {/* overAllChartStateChart */}
                        {this.state.overAllChartStateChart.length > 0 ? (
                            <div className="row mt-20p">
                                <ColumnGroupDrilldownChart
                                    title={"State Wise Dispatches Total"}
                                    subtitle={"Total Dispatches"}
                                    context={this}
                                    series={this.state.overAllChartStateChart[0].series}
                                    categories={this.state.overAllChartStateChart[0].categories}
                                    yaxistitle={"No Of Dispatches"}
                                    dept_code={"SNDG"}
                                    drilldownseries={this.state.overAllChartStateChart[0].drilldown}
                                />
                            </div>


                        ) : ""}

                        {this.state.daycseriesData.length > 0 ? (
                            <div className="row mt-20p">
                                    <ColumnGroupDrilldownChart
                                        title={"Day Wise Dispatches (Packages)"}
                                        subtitle={""}
                                        context={this}
                                        series={this.state.daycseriesData}
                                        categories={this.state.dayccategories}
                                        yaxistitle={"No Of Dispatches"}
                                        dept_code={"SNDG"}
                                        drilldownseries={this.state.daycdrilldownseries}
                                    />
                                
                            </div>


                        ) : ""}

                        {/* {this.state.daycseriesweightData.length > 0 ? (
                            <div className="row mt-20p">
                                <ColumnGroupDrilldownChart
                                    title={"Day Wise Dispatches (Weight In Kgs)"}
                                    subtitle={""}
                                    context={this}
                                    series={this.state.daycseriesweightData}
                                    categories={this.state.dayweightcategories}
                                    yaxistitle={"Weight(Kgs)"}
                                    dept_code={"SNDG"}
                                    drilldownseries={this.state.dayweightdrilldownseries}
                                />
                            </div>


                        ) : ""} */}

                        {/* State wise */}
                        {/* <div className="row mt-20p">
                            {(Object.keys(this.state.statedrilldownseries).length) ?
                                <DashboardChart
                                    title={"State Wise Dispatches"}
                                    subtitle={"Total Dispatches"}
                                    context={this}
                                    series={this.state.stateseriesData}
                                    categories={this.state.statecategories}
                                    yaxistitle={"No Of Dispatches"}
                                    dept_code={"SNDG"}
                                    drilldownseries={this.state.statedrilldownseries}
                                />
                                : ""}
                        </div> */}

                        {/* Day wise Count*/}
                        <div className="row mt-20p">
                            {(this.state.daycseriesData.length > 0) ?
                                <div className={"col-xl-12 col-lg-12 "}>
                                    <span className={this.state.showCountColumnChart}>
                                        {/* <DashboardChart
                                            title={"Day Wise Dispatches (Packages)"}
                                            subtitle={""}
                                            context={this}
                                            series={this.state.daycseriesData}
                                            categories={this.state.dayccategories}
                                            yaxistitle={"No Of Dispatches"}
                                            dept_code={"SNDG"}
                                            drilldownseries={this.state.daycdrilldownseries}
                                        /> */}

                                    {/* <ColumnGroupDrilldownChart
                                        title={"Day Wise Dispatches (Packages)"}
                                        subtitle={""}
                                        context={this}
                                        series={this.state.daycseriesData}
                                        categories={this.state.dayccategories}
                                        yaxistitle={"No Of Dispatches"}
                                        dept_code={"SNDG"}
                                        drilldownseries={this.state.daycdrilldownseries}
                                    /> */}
                                    </span>
                                    <button
                                        type="button"
                                        className={"btn btn-defaultc " + (this.state.showCountColumnChart)}
                                        onClick={this.onClickShowMonthlyCountChartGrid}
                                        style={{ position: "absolute", right: "30px", top: "1px", zIndex: "99" }}>Show Data</button>
                                    <button
                                        type="button"
                                        className={"btn btn-defaultc " + (this.state.showCountLineGridData)}
                                        onClick={this.onClickShowMonthlyCountChartView}
                                        style={{ position: "absolute", right: "30px", top: "1px", zIndex: "99" }}>Show Chart</button>
                                    <div className={"col-sm-12 " + (this.state.showCountLineGridData)}>
                                        <h4 style={{ textAlign: "center", fontSize: "18px", lineHeight: "1.6em", fontFamily: "Lucida Grande, Lucida Sans Unicode, Arial" }}>
                                            Total Courier Consignments</h4>
                                        <div id="myGrid11" style={{ height: "400px", width: "100%", marginTop: "13px" }} className="ag-theme-balham">
                                            <AgGridReact
                                                modules={this.state.modules}
                                                // columnDefs={monthlyColumnwithDefs}
                                                // defaultColDef={this.state.defaultColDef}
                                                // rowData={this.state.monthlyRowData}
                                                columnDefs={columnwithDefs}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.rowData}
                                                enableCharts={true}
                                                enableRangeSelection={true}
                                                onGridReady={this.onGridReady}
                                                onGridState={this.onGridState}
                                                statusBar={this.state.statusBar}
                                                sideBar={this.state.sideBar}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                : ""}
                        </div>
                        {/* Day wise Weight*/}
                        <div className="row mt-20p">
                            {(this.state.daycseriesweightData.length > 0) ?
                                <div className={"col-xl-12 col-lg-12 "}>
                                    <span className={this.state.showWeightColumnChart}>
                                        {/* <DashboardChart
                                            title={"Day Wise Dispatches (Weight In Kgs)"}
                                            subtitle={""}
                                            context={this}
                                            series={this.state.daywseriesData}
                                            categories={this.state.daywcategories}
                                            yaxistitle={"Weight(Kgs)"}
                                            dept_code={"SNDG"}
                                            drilldownseries={this.state.daywdrilldownseries}
                                        /> */}

                                        <ColumnGroupDrilldownChart
                                            title={"Day Wise Dispatches (Weight In Kgs)"}
                                            subtitle={""}
                                            context={this}
                                            series={this.state.daycseriesweightData}
                                            categories={this.state.dayweightcategories}
                                            yaxistitle={"Weight(Kgs)"}
                                            dept_code={"SNDG"}
                                            drilldownseries={this.state.dayweightdrilldownseries}
                                        />
                                    </span>
                                    <button
                                        type="button"
                                        className={"btn btn-defaultc " + (this.state.showWeightColumnChart)}
                                        onClick={this.onClickShowMonthlyWeightChartGrid}
                                        style={{ position: "absolute", right: "30px", top: "1px", zIndex: "99" }}>Show Data</button>
                                    <button
                                        type="button"
                                        className={"btn btn-defaultc " + (this.state.showWeightLineGridData)}
                                        onClick={this.onClickShowMonthlyWeightChartView}
                                        style={{ position: "absolute", right: "30px", top: "1px", zIndex: "99" }}>Show Chart</button>
                                    <div className={"col-sm-12 " + (this.state.showWeightLineGridData)}>
                                        <h4 style={{ textAlign: "center", fontSize: "18px", lineHeight: "1.6em", fontFamily: "Lucida Grande, Lucida Sans Unicode, Arial" }}>
                                            Total Courier Consignments</h4>
                                        <div id="myGrid11" style={{ height: "400px", width: "100%", marginTop: "13px" }} className="ag-theme-balham">
                                            <AgGridReact
                                                modules={this.state.modules}
                                                // columnDefs={monthlyColumnwithDefs}
                                                // defaultColDef={this.state.defaultColDef}
                                                // rowData={this.state.monthlyRowData}
                                                columnDefs={columnwithDefs}
                                                defaultColDef={this.state.defaultColDef}
                                                rowData={this.state.rowData}
                                                enableCharts={true}
                                                enableRangeSelection={true}
                                                onGridReady={this.onGridReady}
                                                onGridState={this.onGridState}
                                                statusBar={this.state.statusBar}
                                                sideBar={this.state.sideBar}
                                                paginationPageSize={this.state.paginationPageSize}
                                                pagination={true}
                                            />
                                        </div>
                                    </div>
                                </div>

                                : ""}
                        </div>
                        {/* dealer delay wise */}
                        <div className="row mt-20p">
                            {((this.state.dealerCategory).length) ?
                                <div className={"col-xl-12 col-lg-12 "}>
                                    <span className={this.state.showWeightColumnChart}>
                                        {/* <HighchartsReact containerProps={{ style: { width: '100%', height: '100%' } }} highcharts={Highcharts} style={dStyles} options={highchartoptionsChart} allowChartUpdate={true} immutable={true}/>  */}
                                        {/* <ChildChart
                                            title={"Dealer Wise Delay (Top 100)"}
                                            subtitle={""}
                                            context={this}
                                            series={this.state.series}
                                            seriesName={"Dealer Delay Count"}
                                            // categories={this.state.dealerCategory}
                                            yaxistitle={"Total Delays"}
                                            dept_code={"SNDG"}
                                        // drilldownseries={this.state.dealerDelayDrillDownList} 
                                        /> */}
                                    </span>
                                </div>
                                : ""}
                        </div>

                    </div>
                </div>
                <div className={"dataLoadpage " + (this.state.loadshow)}></div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
            </div>

        )
    }
}


function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

function GetSortDescOrder(prop) {
    return function (a, b) {
        if (a[prop] < b[prop]) {
            return 1;
        } else if (a[prop] > b[prop]) {
            return -1;
        }
        return 0;
    }
} 
