/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

import _ from "lodash";

import axios from 'axios';

import Grid from '../layouts/gridUpdateComponent';
import DateFormater from '../layouts/dateComponent';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getHyphenDDMMMYYYYHHMM } from '../common/utils';
import Modal from 'react-responsive-modal';
import { verifyextcsv } from "../common/verifyext";

import $ from 'jquery';
import CSVFileValidator from 'csv-file-validator'
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");


export default class ManageCustomers extends Component {

	constructor(props) {
		super(props);
		this.state = {
			pageTitle: "Manage Customers",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			departmentCode: null,
			currentDepartmentTitle: null,
			rowData: null,
			uploadDivWidth: '0%',
			sliderTranslate: '',
			showDiv: 'show-n',
			uploadFile: '',
			truck_no: '',
			transporter_code: '',
			dept_code: '',
			file: '',
			alltransporters: [],
			alltransporter_codes: [],
			allDepts: [],
			csvmessage: '',
			csverrmessage: '',
			alertshow: 'fade',
			alertmg: 'show-n',
			alerterrmg: 'show-n',
			alerterrshow: 'fade',
			loadshow: 'show-n',
			overly: 'show-n',
			alert: null,
			show: false,
			basicTitle: '',
			basicType: "default",
			usermanualmodal: false,
			csvcontent: []
		}
	}

	getCurrentDepartmentCode(pathToMatch = null) {
		let department = null;
		let departmentName = null;
		switch (pathToMatch) {
			case "/manage/sndtrucks":
				department = 'SNDG';
				departmentName = " (Sales and Dispatch) ";
				break;
			case "/manage/prttrucks":
				department = 'LOG-PRT';
				departmentName = " (Spare Parts) ";
				break;
			case "/manage/tnptrucks":
				department = 'LOG-TNP';
				departmentName = " (Train and Production) ";
				break;
			case "/manage/sctrucks":
				department = 'LOG-SC';
				departmentName = " (Supply Chain) ";
				break;
			default:
				console.log("IN getDepartmentFilter, default, params = ", pathToMatch);
		}
		this.setState({
			currentDepartmentTitle: departmentName
		});
		return department;
	}
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
			} else {
				console.log("GA disabled...");
			}
		} catch (error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
	componentDidMount() {
		if (googleAnalytics.page.enableGA) {
			this.logPageView();
		}

		this.setState({
			loadshow: 'show-m',
			overly: 'show-m'
		});
		console.log("user_type", localStorage.getItem('user_type'));
		if(localStorage.getItem('user_type') == "DISTRIBUTOR"){
			var consigneeCode = localStorage.getItem('consignee_code')
			this.setState({
				consigneeCode: consigneeCode
			})
		};
    	console.log("consigneeCode", consigneeCode)
		// get department code to filter records by department
		let currentDepartmentCode = this.getCurrentDepartmentCode(this.props.match.path);
		// console.log('currentDepartmentCode',currentDepartmentCode)
		this.setState({
			departmentCode: currentDepartmentCode
		});

		if (localStorage.getItem('user_type') == "DISTRIBUTOR") {
			var params = {
				consigneeCode: consigneeCode
			}
		} else {
			params = {}
		}
		/*Truck with gpsdata*/
		redirectURL.post('/masters/customers', params)
			.then((response) => {

				var records = JSON.parse(JSON.stringify(response)).data;
				// console.log('recordsTrucks',records);
				if (this.state.departmentCode) {
					records = records.filter(rec => rec.dept_code === this.state.departmentCode);
				}
				var recordsarr = []
				if (records.length > 0) {
					records.map((item) => {
						// if (item.is_active == 0) {
						// 	item.status = "Inactive";
						// }

						// if (item.is_active == 1) {
						// 	item.status = "Active";
						// }
						recordsarr.push(item);
					})
				}
				if (localStorage.getItem("user_type") == "NAGPURORGUSER") {
					recordsarr = recordsarr.filter(e => ["Nagpur", "NAGPUR"].includes(e.customer_city));
				} else if (localStorage.getItem('user_type') == "NASIKORGUSER") {
					recordsarr = recordsarr.filter(e => ["Nasik", "NASIK", "Nashik", "NASHIK"].includes(e.customer_city));
				} else if (localStorage.getItem('user_type') == "SOLAPURORGUSER") {
					recordsarr = recordsarr.filter(e => ["Solapur"].includes(e.customer_city));
				} else if (localStorage.getItem('user_type') == "INDOREORGUSER") {
					recordsarr = recordsarr.filter(e => ["Indore"].includes(e.customer_city));
				} else if (localStorage.getItem('user_type') == "AHMEDABADORGUSER") {
					recordsarr = recordsarr.filter(e => ["Ahmedabad"].includes(e.customer_city));
				}
				this.setState({
					rowData: recordsarr,
					loadshow: 'show-n',
					overly: 'show-n'
				});

			})
			.catch(function (error) {
				console.log(error);
			});

		/*Get All Transporters from Trucks table */
		// redirectURL.post('/masters/getAllTransporters')
		// 	.then((response) => {
		// 		//console.log("/masters/getAllTransporters = ",response.data);
		// 		var all_transporters = [];
		// 		var transporter_codes = []
		// 		for (var rec in response.data) {
		// 			all_transporters.push({ "transporter_code": response.data[rec].transporter_code, "transporter_name": response.data[rec].transporter_name })
		// 			transporter_codes.push(response.data[rec].transporter_code + "_" + response.data[rec].transporter_name);
		// 		}
		// 		//console.log("/masters/getAllTransporters, all_transporters, transporter_codes = ", all_transporters, transporter_codes);
		// 		this.setState({
		// 			alltransporters: all_transporters,
		// 			alltransporter_codes: transporter_codes
		// 		});
		// 	});

		/*Get All Departments Distinct from Trucks table*/
		// redirectURL.post('/masters/departments')
		// 	.then((response) => {
		// 		//console.log("/masters/departments = ",response.data);
		// 		this.setState({ allDepts: response.data });
		// 	});

	};

	/*Alert Popups*/
	closeAlert = () => {
		this.setState({
			show: false
		});
	}

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	}

	buttonExample = (type) => {
		switch (type) {
			case 'custom':
				this.setState({
					alert: (
						<SweetAlert
							custom
							showCancel
							confirmBtnText="Yes"
							cancelBtnText="No"
							confirmBtnBsStyle="primary"
							cancelBtnBsStyle="default"
							customIcon="thumbs-up.jpg"
							title="Do you like thumbs?"
							onConfirm={this.hideAlert}
							onCancel={this.hideAlert}
						>
							You will find they are up!
						</SweetAlert>
					)
				});
				break;
			case 'input':
				this.setState({
					alert: (
						<SweetAlert
							input
							showCancel
							cancelBtnBsStyle="default"
							title="An input!"
							placeHolder="Write something"
							onConfirm={this.onRecieveInput}
							onCancel={this.hideAlert}
						>
							Write something interesting:
						</SweetAlert>
					)
				});
				break;
			case 'password':
				this.setState({
					alert: (
						<SweetAlert
							input
							required
							inputType="password"
							title="Enter Password"
							validationMsg="You must enter your password!"
							onConfirm={this.hideAlert}
						>
							Write something interesting:
						</SweetAlert>
					)
				});
				break;
			default:
				this.setState({
					alert: (
						<SweetAlert
							showCancel
							confirmBtnText="Continue"
							confirmBtnBsStyle={type}
							type={type}
							title="Are you sure?"
							onCancel={this.hideAlert}
							onConfirm={this.hideAlert}
						>
							You will not be able to recover this imaginary file!
						</SweetAlert>
					)
				});
				break;
		}
	}

	hideAlert = () => {
		this.setState({
			alert: null
		});
	}

	/*End*/

	onShowUploadDiv = () => {
		console.log("Click Upload Button")
		this.setState({
			uploadDivWidth: '30%',
			sliderTranslate: "slider-translate",
			showDiv: 'show-m',
			csvmessage: '',
			csverrmessage: '',
		});
	}
	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth: '0%',
			sliderTranslate: "",
			showDiv: 'show-n',
			uploadFile: '',
			file: '',
			csvmessage: '',
			csverrmessage: '',
		});
		document.getElementById("upform").reset();
	}
	resetUpload = () => {
		this.setState({
			uploadDivWidth: '0%',
			sliderTranslate: '',
			showDiv: 'show-n',
			uploadFile: '',
			file: ''
		});
		document.getElementById("upform").reset();
	}

	changeFileHandler = e => {
		// console.log("e.target.files[0] ",e.target.files[0].name)
		// var check = verifyextcsv(e.target.files[0].name);
		// console.log("Check ", check)
		// if(check == true)
		// {
		// 	this.setState({file:e.target.files[0]});
		// }
		// else{
		// 	this.setState({
		// 		//csverrmessage:response.data.status,
		// 		show: true, basicType:'danger', basicTitle:"Invalid file extension",
		// 		uploadFile:'',
		// 		file:''
		// 	});
		// }
		const config = {
			headers: [
				{
					name: 'S.no',
					inputName: 'serial_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                {
					name: 'Consumer No',
					inputName: 'customer_id',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                {
					name: 'Name',
					inputName: 'customer_name',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                {
					name: 'Distributor Code',
					inputName: 'consignee_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                {
					name: 'Registered Mobile No',
					inputName: 'customer_mobile_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                {
					name: 'Shipping Address',
					inputName: 'shipping_address',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                {
					name: 'City',
					inputName: 'customer_city',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                {
					name: 'State',
					inputName: 'customer_state',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                {
					name: 'AreaId',
					inputName: 'customer_area_id',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                {
					name: 'Area',
					inputName: 'customer_area',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                {
					name: 'NoOfCylinder',
					inputName: 'no_of_cylinders',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				// {
				// 	name: 'truck_no',
				// 	inputName: 'truck_no',
				// 	required: true,
				// 	requiredError: function (headerName, rowNumber, columnNumber) {
				// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
				// 	}
				// },
				// {
				// 	name: 'transporter_code',
				// 	inputName: 'transporter_code',
				// 	required: true,
				// 	requiredError: function (headerName, rowNumber, columnNumber) {
				// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
				// 	}
				// },
				// {
				// 	name: 'dept_code',
				// 	inputName: 'dept_code',
				// 	required: true,
				// 	requiredError: function (headerName, rowNumber, columnNumber) {
				// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
				// 	}
				// },
				// {
				// 	name: 'load_capacity',
				// 	inputName: 'load_capacity',
				// 	required: true,
				// 	requiredError: function (headerName, rowNumber, columnNumber) {
				// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
				// 	}
				// }
			]
		}
		let data = CSVFileValidator(e.target.files[0], config).then((csvData) => {

			if(csvData.inValidMessages.length > 0 || csvData.data.length === 0){
				this.setState({show: true,
					basicType: 'danger',
					basicTitle: 'Please upload file having correct fields..!',
					uploadFile: '',
					file: ''
				})
				document.getElementById("upform").reset();
			}else if(csvData.inValidMessages.length === 0 && csvData.data.length > 0){
				this.setState({
					csvcontent:csvData.data,				
				});
			}
		})
			.catch(err => { })
		// console.log("e.target.files[0]",e.target.result);
		var contentList = []

		var out = new Promise(function (reject, resolve) {
			var reader = new FileReader();
			if (reader != "" && reader != undefined) {
				reader.onload = async function (e) {
					var contents = await e.target.result;
					resolve(contents);
				};
				if(e.target.files[0] != "" && e.target.files[0] != undefined) {
					var tt = reader.readAsText(e.target.files[0]);
				}
				
			}
		});
		// console.log(contentList);
		this.setState({
			file: e.target.files[0]
		});
		// console.log("e.target.files[0].type ", e.target.files[0]);
		if (e.target.files[0] != "" && e.target.files[0] != undefined) {
			if (e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
				// console.log(fileData);
				// console.log(typeof(fileData))
	
			}
			else {
				e.target.value = null;
				this.setState({
					uploadFile: '',
					show: true,
					basicType: 'danger',
					basicTitle: 'Please upload file having extensions .csv only.',
				});
			}
		}
	}

	// changeSelectHandler(){
	// 	console.log("test");
	// }

	uploadFormHandler = (event) => {
		event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			if (this.state.currentDepartmentTitle) {
				pageTitle = pageTitle + " " + this.state.currentDepartmentTitle;

			}
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.fileUploaded,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		// this.setState({
		// 	loadshow: 'show-m',
		// 	overly: 'show-m'
		// })
		let csvContent = this.state.csvcontent
		if (csvContent != "" && csvContent != undefined) {
			if (csvContent.length > 0) {
				// let deptCode = localStorage.getItem("dept_code");
				// if(deptCode.includes("SNDG")) {
				// 	csvContent.forEach(e =>{
				// 		e.dept_code = "SNDG"
				// 	})
				// }
				var duplicateCustomerNos = []
				var customermobilenumber = []
				var emptyFieldsFlag = 0
				var specialCharacters = 0
				var numberlength = true

				csvContent.map((item, i) => {
					if (item.customer_id != "" && item.customer_name != "" && item.customer_mobile_no != "" && item.consignee_code != "" && item.shipping_address != "" && item.customer_city != "" && item.customer_state != "" && item.customer_area_id != "" && item.customer_area != "" && item.no_of_cylinders != "") {
						if (item.customer_id != undefined) {
							duplicateCustomerNos.push(item.customer_id)
						}
						if(item.customer_mobile_no != undefined)
						{
							customermobilenumber.push(item.customer_mobile_no)
						}
					} else {
						emptyFieldsFlag = 1
					}
				})

				duplicateCustomerNos.map((e) => {
					if (e.match(/[^a-zA-Z0-9]/g)) {
						specialCharacters = 1
					}
				})

				customermobilenumber.map((number) => {
					// console.log(dm.length,"e.length")
					if (number.length != 10) 
					{
						numberlength = false
					}
				})
				var uniqueCheck = checkIfArrayIsUnique(duplicateCustomerNos)
				
				if (localStorage.getItem('user_type') == "DISTRIBUTOR") {
					var userType = localStorage.getItem("user_type");
					var consigneeCode = localStorage.getItem("consignee_code");
				}
				// if (emptyFieldsFlag == 0) {

					// if (duplicateCustomerNos != "" && duplicateCustomerNos != undefined) {

						// if (specialCharacters == 0) {
	
							// if (uniqueCheck == true) {
	
								// if (numberlength == true) {
									var rparams = {
										uploadFile: this.state.file,
										userId: localStorage.getItem('userid'),
										csvcontent: csvContent
									}
									if (userType == "DISTRIBUTOR") {
										rparams.consigneeCode = consigneeCode
									}
									// var formData = new FormData(event.target);
									// formData.append('uploadFile',this.state.file);
					
									// console.log('rparams', rparams);
									this.setState({
										loadshow: 'show-m',
										overly: 'show-m'
									});
									console.log("csvcontent", csvContent)
									let prevUrl = "/manage/uploadMasterTruckCsv";
									let url = "/manage/insertMasterCustomersCsv";
									
									redirectURL.post(url, rparams).then(async(response) => {
										// console.log(response);
										//console.log(response.data.status);
										document.getElementById("upform").reset();
										if (response.data.insertedCustomerCount > 0) {
											this.setState({
												//csverrmessage:response.data.status,
												show: false,
												basicTitle: `${response.data.insertedCustomerCount} Customer(s) Inserted`,
												basicType: "success",
												uploadDivWidth: '0%',
												sliderTranslate: '',
												// showDiv: 'show-n',
												// loadshow: 'show-n',
												uploadFile: '',
												file: ''
											});

											var consigneeCode = localStorage.getItem("consignee_code");
											if (localStorage.getItem('user_type') == "DISTRIBUTOR") {
												var params = {
													consigneeCode: consigneeCode
												}
											} else {
												params = {}
											}
											await redirectURL.post('/manage/processCustomerMasterCsv', params).then(async (res) => {
												console.log("process customers response", res.data)
												if (res.data.status == "success") {
													this.setState({
														show: false, 
														basicType: 'success', 
														basicTitle: `Processed Customer Data`,
														uploadDivWidth: '0%',
														sliderTranslate: '',
														showDiv: 'show-n',
														uploadFile: '',
														// loadshow: 'show-n',
														// overly:'show-n',
														file: ''
													});
													// setTimeout(() => {
												await redirectURL.post('/masters/customers', params).then(async (response) => {
													var records = JSON.parse(JSON.stringify(response)).data;
													//console.log(records);
													if (this.state.departmentCode) {
														records = records.filter(rec => rec.dept_code === this.state.departmentCode);
													}
													var recordsarr = []
													if (records.length > 0) {
														records.map((item) => {
															// if (item.is_active == 0) {
															// 	item.status = "Inactive";
															// }
									
															// if (item.is_active == 1) {
															// 	item.status = "Active";
															// }
															recordsarr.push(item);
														})
													}
													if (localStorage.getItem("user_type") == "NAGPURORGUSER") {
														recordsarr = recordsarr.filter(e => ["Nagpur", "NAGPUR"].includes(e.customer_city));
													} else if (localStorage.getItem('user_type') == "NASIKORGUSER") {
														recordsarr = recordsarr.filter(e => ["Nasik", "NASIK", "Nashik", "NASHIK"].includes(e.customer_city));
													} else if (localStorage.getItem('user_type') == "SOLAPURORGUSER") {
														recordsarr = recordsarr.filter(e => ["Solapur"].includes(e.customer_city));
													} else if (localStorage.getItem('user_type') == "INDOREORGUSER") {
														recordsarr = recordsarr.filter(e => ["Indore"].includes(e.customer_city));
													} else if (localStorage.getItem('user_type') == "AHMEDABADORGUSER") {
														recordsarr = recordsarr.filter(e => ["Ahmedabad"].includes(e.customer_city));
													}
													this.setState({
														rowData: recordsarr,
														// loadshow: 'show-n',
														// overly: 'show-n'
													});
													await redirectURL.post('/consignments/createAreaWiseTimeSlotsDefinition', params).then(async (res) => {
														console.log("createAreaWiseSlots resp", res.data)
													
														try {
															this.setState({
																show: true,
																basicTitle: "Inserted Successfully",
																basicType: "success",
																loadshow: 'show-n',
																overly: 'show-n'
															})
															await redirectURL.post('/consignments/createDistributorTimeSlots', params).then((res) => {
																console.log("Slots Created", res.results)
																if (res.data.status == "Success") {
																	this.setState({
																		basicTitle : "Created Time Slots Successfully",
																		basicType : "success",
																		distributor_code : "",
																		sliderTranslate: '',
																		// loadshow: 'show-n',
																		// overly: 'show-n',
																		show : false,
																	})
																}
															})
														} catch(e){
															console.log(e)
														}
													})
												})
												.catch(function (error) {
													console.log(error);
												});
												// }, 200)
												}
											})
					
										}
										else if (response.data.invalidCustomerIdCount > 0) {
											this.setState({
												show: true,
												basicTitle: `${response.data.invalidCustomerIdCount} Invalid Customer ID(s) found and ignored.`,
												basicType: "success",
												loadshow: 'show-n',
												overly: 'show-n',
												sliderTranslate: '',
											});
										} else if (response.data.invalidAreaIdCount > 0) {
											this.setState({
												show: true,
												basicTitle: `${response.data.invalidAreaIdCount} Invalid Area ID(s) found and ignored.`,
												basicType: "success",
												loadshow: 'show-n',
												overly: 'show-n',
												sliderTranslate: '',
											});
										} else if (response.data.invalidMobileNoCount > 0) {
											this.setState({
												show: true,
												basicTitle: `${response.data.invalidMobileNoCount} Invalid Mobile No(s) found and ignored.`,
												loadshow: 'show-n',
												overly: 'show-n',
												basicType: "success",
											});
										} else if (response.data.invalidDistIdCount > 0) {
											this.setState({
												show: true,
												basicTitle: `${response.data.invalidDistIdCount} Invalid Distributor ID(s) found and ignored.`,
												loadshow: 'show-n',
												overly: 'show-n',
												basicType: "success",
												sliderTranslate: '',
											});
										} else if (response.data.updatedCustomerCount > 0) {
											this.setState({
												show: true,
												basicTitle: `${response.data.updatedCustomerCount} Customer(s) updated`,
												loadshow: 'show-n',
												overly: 'show-n',
												basicType: "success",
												sliderTranslate: '',
											});
										} else {
											this.setState({
												show: true,
												basicType: "danger",
												loadshow: 'show-n',
												overly: 'show-n',
												basicTitle: "Failed to create customers",
												sliderTranslate: '',
											})
										}
									}).catch(function (error) {
										console.log("error",error);
									});
								// } else {
									// this.setState({
									// 	show: true,
									// 	basicTitle: 'Customer Mobile Number should be 10 digits',
									// 	basicType: "danger",
									// });
								// }
							// } else {
								// this.setState({
								// 	show: true,
								// 	basicTitle: 'Consumer No Should not be duplicate',
								// 	basicType: "danger",
								// });
							// }
						// } else {
						// 	this.setState({
						// 		show: true,
						// 		basicTitle: 'Special Characters are not allowed in Consumer No',
						// 		basicType: "danger",
						// 	});
						// }
					// } else {
						// this.setState({
						// 	show: true,
						// 	basicTitle: 'Consumer No Should not be empty',
						// 	basicType: "danger",
						// });
					// }
				// } else {
					// this.setState({
					// 	show: true,
					// 	basicTitle: 'Fields cannot be empty',
					// 	basicType: "danger",
					// });
				// }
			} else {
				this.setState({
					show: true,
					basicTitle: 'Choose file to continue',
					basicType: "danger",
				});
			}
		}	
	}
	onClickHideAll() {
		var uploadfilebulk = $("#uploadfile").val(null)
		this.setState({
			loadshow: 'show-n',
			overly: 'show-n',
			slideuploadeway: '',
			sliderRouteTranslate: '',
			sliderSegmentTranslate: "",
			sliderTranslate: "",
			tabsliderTranslate: '',
			sliderTranslatesidebar: '',
			sliderForceCloseTranslate: '',
			sliderCommentTranslate: "",
			bulkPrioritySlide: "",
			sliderCreateConsignmentTranslate: "",
			sliderRso:"",
			uploadfilebulk:uploadfilebulk
		});

	}
	onCloseUserManualModal = () => {
		this.setState({ usermanualmodal: false });
	};

	onClickShowUserManaul = () => {
		this.setState({ usermanualmodal: true });
	};

	updateCustomerData(params){
		console.log("Data to be modified",params.data);

		if (
			parseInt(params.data.customer_mobile_no[0]) < 6 ||
			parseInt(params.data.customer_mobile_no[0]) > 9 ||
			params.data.customer_mobile_no.length !== 10 ||
			params.data.customer_mobile_no.length > 10 
		  ) {
			this.setState({
			    basicTitle: "Invalid Mobile No",
			    basicType: "danger",
			    show: true
			})
		  } else if(params.data.customer_area.length === 0){
			this.setState({
			    basicTitle: "Customer area shouldn't be empty",
			    basicType: "danger",
			    show: true
			})
		  } else if(params.data.customer_area_id.length === 0){
			this.setState({
			    basicTitle: "Area ID shouldn't be empty",
			    basicType: "danger",
			    show: true
			})
		  } else if(params.data.customer_name.length === 0){
			this.setState({
			    basicTitle: "Customer name shouldn't be empty",
			    basicType: "danger",
			    show: true
			})
		  } else if(params.data.customer_state.length === 0){
			this.setState({
			    basicTitle: "State name shouldn't be empty",
			    basicType: "danger",
			    show: true
			})
		  } else if(params.data.customer_city.length === 0){
			this.setState({
			    basicTitle: "City name shouldn't be empty",
			    basicType: "danger",
			    show: true
			})
		  } else if(params.data.no_of_cylinders.length === 0){
			this.setState({
			    basicTitle: "Invalid Cylinder count",
			    basicType: "danger",
			    show: true
			})
		  } else if(params.data.shipping_address.length === 0){
			this.setState({
			    basicTitle: "Shipping address shouldn't be empty",
			    basicType: "danger",
			    show: true
			})
		  } else {
			  redirectURL.post('/consignments/updateCustomerDetails',params.data)
			  .then((response) => {
				  console.log(response.data)
				  if(response.data.status == "Success"){
					  if (response.data.modifiedCount > 0) {
						  this.setState({
							  show: true,
							  basicTitle: "Updated Successfuly",
							  basicType: "success"
						  })
					  }
				  }
			  });		
		  }
	}
	render() {
		const modalStyles = {
			width: '1300px !important',
		}
		const hideprtcolumns = (this.state.departmentCode == "LOG-PRT") ? false : true
		const { usermanualmodal } = this.state;
		const columnwithDefs = [
            {
				headerName: "Distributor Code",
				field: "consignee_code",
				width: 150,
				editable: false,
				filter: "agSetColumnFilter",
			},
            {
				headerName: "Consumer No",
				field: "customer_id",
				width: 150,
				editable: false,
				filter: "agSetColumnFilter",
			},
            {
				headerName: "Consumer Name",
				field: "customer_name",
				width: 150,
				editable: true,
				filter: "agSetColumnFilter",
			},
            {
				headerName: "Consumer Mobile No",
				field: "customer_mobile_no",
				width: 150,
				editable: true,
				filter: "agSetColumnFilter",
				cellEditor: NumericCellEditor
			},
            {
				headerName: "Shipping Address",
				field: "shipping_address",
				width: 250,
				editable: true,
				filter: "agSetColumnFilter",
			},
            {
				headerName: "Area ID",
				field: "customer_area_id",
				width: 150,
				editable: true,
				filter: "agSetColumnFilter",
				cellEditor: NumericCellEditor
			},
            {
				headerName: "Area Code",
				field: "customer_area",
				width: 150,
				editable: true,
				filter: "agSetColumnFilter",
			},
            {
				headerName: "City",
				field: "customer_city",
				width: 150,
				editable: true,
				filter: "agSetColumnFilter",
			},
            {
				headerName: "State",
				field: "customer_state",
				width: 150,
				editable: true,
				filter: "agSetColumnFilter",
			},
            {
				headerName: "No of Cylinders",
				field: "no_of_cylinders",
				width: 150,
				editable: true,
				filter: "agSetColumnFilter",
				cellEditor: NumericCellEditor
			},
			{
				headerName: "Update",
				field: "update",
				width: 150,
				filter:false,
				floatingFilter:false,
				cellRendererFramework: (params)=>{return <button style={{backgroundColor:"#00c292",border:"1px solid white",color:"white",cursor:"pointer" ,width:"120px"}} onClick={this.updateCustomerData.bind(this,params)}>Update</button>}
			},
			// {
			// 	headerName: "Truck No",
			// 	field: "truck_no",
			// 	width: 150,
			// 	editable: false,
			// 	filter: "agSetColumnFilter",
			// },
			// {
			// 	headerName: "LSP Code",
			// 	field: "transporter_code",
			// 	width: 200,
			// 	cellEditor: "agRichSelectCellEditor",
			// 	cellEditorParams: {
			// 		values: this.state.alltransporter_codes,
			// 		cellRenderer: function (params) {
			// 			return params.value.split("_")[0]
			// 		}
			// 	},
			// 	valueSetter: function (params) {
			// 		//params.setValue('transporter_code') = params.newValue.split('_')[1]
			// 		//console.log(params.newValue)
			// 		params.data.transporter_name = params.newValue.split('_')[1];
			// 		params.data.transporter_code = params.newValue.split('_')[0];

			// 		return params.newValue.split('_')[0]
			// 	},
			// 	filter: "agSetColumnFilter"
			// },
			// {
			// 	headerName: "LSP Name",
			// 	field: "transporter_name",
			// 	width: 200,
			// 	editable: false,
			// 	filter: "agSetColumnFilter",

			// },
			// //   {
			// //   headerName: "Department Code",
			// //   field: "dept_code",
			// //   width: 120,
			// //     cellEditor: "agRichSelectCellEditor",
			// //     cellEditorParams: {
			// //   	  values: this.state.allDepts
			// //     },
			// //     filter: "agSetColumnFilter"
			// //
			// // },
			// {
			// 	headerName: "Actual GPS Provider",
			// 	field: "actual_lspuser",
			// 	width: 150,
			// 	editable: false,
			// 	filter: "agSetColumnFilter",

			// },
			// {
			// 	headerName: "Load Capacity",
			// 	field: "load_capacity",
			// 	width: 150,
			// 	filter: "agSetColumnFilter",
			// },
			// {
			// 	headerName: "Last Sample Time",
			// 	field: "timestamp",
			// 	width: 200,
			// 	editable: false,
			// 	valueGetter: function (params) {
			// 		if (params.data.timestamp != undefined) {
			// 			// console.log(new Date(params.data.timestamp));
			// 			return getHyphenDDMMMYYYYHHMM(params.data.timestamp);
			// 		}
			// 		else {
			// 			return "";
			// 		}

			// 	},
			// 	//   cellRenderer:'dateFormater',
			// 	//filter: "agDateColumnFilter",
			// 	comparator: dateComparator,
			// 	// filterParams: {
			// 	// 	browserDatePicker: true,
			// 	// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
			// 	// 	// provide comparator function
			// 	// 	comparator: function(filteredDate,cellValue,secondDate) {
			// 	// 		cellValue = cellValue.replace(/\//g,"-")
			// 	// 		if(cellValue != "" && cellValue != " "){
			// 	// 			cellValue = cellValue.split(" ")[0].split("-");
			// 	// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
			// 	// 			var cellDate = new Date(cellValue);
			// 	// 			if(filteredDate.getMonth() < 10){
			// 	// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
			// 	// 			}
			// 	// 			else{
			// 	// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
			// 	// 			}
			// 	// 			if(filterValue.split("-")[2].length < 2)
			// 	// 			{
			// 	// 				var temp = filterValue;
			// 	// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
			// 	// 			}
			// 	// 			var filteredDate = new Date(filterValue);
			// 	// 			cellDate = cellDate.getTime();
			// 	// 			filteredDate = filteredDate.getTime();
			// 	// 			// console.log(cellDate,filteredDate);
			// 	// 			if(cellDate === filteredDate)
			// 	// 			{
			// 	// 				return 0;
			// 	// 			}
			// 	// 			if(cellDate < filteredDate){
			// 	// 				return -1;
			// 	// 			}
			// 	// 			if(cellDate > filteredDate)
			// 	// 			{
			// 	// 				return 1;
			// 	// 			}
			// 	// 		}
			// 	// 	}
			// 	// },

			// },
			// {
			// 	headerName: "Last Packet Time",
			// 	field: "modified_date",
			// 	width: 200,
			// 	editable: false,
			// 	/*valueGetter:function(params){
			// 		var dsplit = params.data.last_gps_date.split("T");
			// 		var SSplit = dsplit[1].split(".");
			// 		var dateFormatIs = dsplit[0]+" "+SSplit[0]
			// 		return (dateFormatIs)
			// 	},*/
			// 	valueGetter: function (params) {
			// 		return getHyphenDDMMMYYYYHHMM(params.data.modified_date);
			// 	},
			// 	// cellRenderer:'dateFormater',
			// 	//filter: "agDateColumnFilter",
			// 	comparator: dateComparator,
			// 	// filterParams: {
			// 	// 	browserDatePicker: true,
			// 	// 	filterOptions: ['equals','greaterThan','lessThan','notEqual','inRange','greaterThanOrEqual','lessThanOrEqual'],
			// 	// 	// provide comparator function
			// 	// 	comparator: function(filteredDate,cellValue,secondDate) {
			// 	// 		cellValue = cellValue.replace(/\//g,"-")
			// 	// 		if(cellValue != "" && cellValue != " "){
			// 	// 			cellValue = cellValue.split(" ")[0].split("-");
			// 	// 			cellValue = cellValue[2]+"-"+getMonthNumber(cellValue[1])+"-"+cellValue[0];
			// 	// 			var cellDate = new Date(cellValue);
			// 	// 			if(filteredDate.getMonth() < 10){
			// 	// 			var filterValue = filteredDate.toString().split(" ")[3]+"-0"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
			// 	// 			}
			// 	// 			else{
			// 	// 				var filterValue = filteredDate.toString().split(" ")[3]+"-"+(filteredDate.getMonth()+1).toString()+"-"+filteredDate.getDate().toString()
			// 	// 			}
			// 	// 			if(filterValue.split("-")[2].length < 2)
			// 	// 			{
			// 	// 				var temp = filterValue;
			// 	// 				filterValue = temp.split("-")[0]+"-"+temp.split("-")[1]+"-0"+temp.split("-")[2];
			// 	// 			}
			// 	// 			var filteredDate = new Date(filterValue);
			// 	// 			cellDate = cellDate.getTime();
			// 	// 			filteredDate = filteredDate.getTime();
			// 	// 			// console.log(cellDate,filteredDate);
			// 	// 			if(cellDate === filteredDate)
			// 	// 			{
			// 	// 				return 0;
			// 	// 			}
			// 	// 			if(cellDate < filteredDate){
			// 	// 				return -1;
			// 	// 			}
			// 	// 			if(cellDate > filteredDate)
			// 	// 			{
			// 	// 				return 1;
			// 	// 			}
			// 	// 		}
			// 	// 	}
			// 	// },
			// },
			// {
			// 	headerName: "Axle Type",
			// 	field: "axle_type",
			// 	width: 130,
			// 	editable: false,
			// 	filter: true,
			// 	hide: hideprtcolumns
			// },
			// {
			// 	headerName: "Truck Type",
			// 	field: "truck_type",
			// 	width: 130,
			// 	editable: false,
			// 	filter: true,
			// 	hide: hideprtcolumns
			// },
			// {
			// 	headerName: "Report Truck Type",
			// 	field: "report_truck_type",
			// 	width: 130,
			// 	editable: false,
			// 	filter: true,
			// 	hide: hideprtcolumns
			// },
			// {
			// 	headerName: "Status",
			// 	field: "status",
			// 	width: 90,
			// 	filter: true,
			// 	//singleClickEdit:true,
			// 	editable: true,
			// 	// cellRenderer: function(params){
			// 	// 	if(params.value == 1 || params.value == 'Active')
			// 	// 	{
			// 	// 		return 'Active';
			// 	// 	}
			// 	// 	else
			// 	// 	{
			// 	// 		return 'Inactive';
			// 	// 	}
			// 	// },
			// 	cellEditor: 'agRichSelectCellEditor',
			// 	cellEditorParams: {
			// 		cellRenderer: 'genderCellRenderer',
			// 		values: ['Active', 'Inactive']
			// 	}
			// },
			// {
			//   headerName: "Action",
			//   field: "_id",
			//   width: 100,
			//   filter: false,
			//   cellRenderer:'updateItem',
			//   cellRendererParams: {
			//         actionCall: "manage",
			//         actionMethod:"updateTruckRow"
			//     },
			// 	editable:false,
			// 	hide: true
			// }
			/*  {
					headerName: "",
					field: "_id",
					width: 100,
					cellRenderer:'statusChange',
					cellRendererParams: {
						  actionCall: "manage",
						  actionMethod:"statusUpdate"
					  },
					  editable:false,
						suppressCellFlash: true
				  }*/

		];
		return (
			<div className="container-fluid">
				<div className={"dataLoadpage " + (this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " + (this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}
				>
				</SweetAlert>
				{this.state.alert}
				<div className="row">
					<div className="col-xl-12 col-lg-12">
						<div className="card">

							<div className="card-header">
								<h5>
									<i className="icofont icofont-vehicle-delivery-van cus-i"></i>
									<span>{this.state.pageTitle} {this.state.currentDepartmentTitle}</span>
									<button type="button" onClick={this.onShowUploadDiv.bind(this)} className="btn btn-success pull-right cs-btn"> <i className="icofont icofont-upload-alt"></i> Upload CSV</button>
								</h5>
							</div>
							<div className="card-body">
								{/*<div className={"dataLoad " +(this.state.loadshow)}>
				   					<img src={require("../../assets/icons/loader.gif")} />
				   				</div>
				   					*/}

								<Grid
									ashow={this.state.show}
									atype={this.state.basicType}
									atitle={this.state.basicTitle}
									actionCall={"manage"}
									actionMethod={"updateTruckRow"}
									table={"trucklocation"}
									rowData={this.state.rowData}
									columnwithDefs={columnwithDefs}

								/>
							</div>
						</div>
					</div>
				</div>
				
				<div className={"slide-r " + (this.state.sliderTranslate)} >

					<div className="slide-r-title">
						<h4>
							Bulk Upload
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>

						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className={"dataLoad " + (this.state.loadshow)}>
							<div style={{ position: "absolute", left: "38%" }}>
								<div class="loader-box">
									<div class="loader-box">
										<div class="rotate dashed colored"></div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-12 col-lg-12">
							<div className={"col-xl-12 col-lg-12 alert alert-light uploadcscmsg " + (this.state.alerterrshow) + " " + (this.state.alerterrmg)}>{this.state.csverrmessage}</div>

							<form id="upform" className="theme-form" onSubmit={this.uploadFormHandler}>

								<div className="form-group mt-20p">
									<label className="">Upload File</label>
									<input type="file" name="uploadFile" onChange={this.changeFileHandler} className="form-control" required />
								</div>
								<div className="form-group">
									<button type="submit" className="btn btn-success">Submit</button>
									<button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
								</div>
								<div className="form-group">
									<a className="btn btn-primary" href={require('../../assets/json/sample_manage_customers.csv')} target="_blank">Sample Template</a>
								</div>
							</form>
							{/* <div className="form-group mt-50p">
			 					<a href={require('../../assets/json/truck_template.csv')} target="_blank" className="btn btn-info">Download Template</a>
								</div> */ }
						</div>
					</div>
				</div>

				<span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span>
				<Modal open={usermanualmodal} onClose={this.onCloseUserManualModal.bind(this)} styles={modalStyles}>
					<div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
						<h5 className="model-title">Trucks</h5>
						<div className="col-xl-12 col-lg-12 mt-20p">
							<div className="col-xl-12 col-lg-12">
								This screen consists of master file of the entire fleet list which serves a particular department for carrying the material along with its transporter and GPS service provider details. Each column is having filter option through which one can find particular details, make particular changes and update the master file. Various grid columns are described below:
							</div>

							<div className="col-xl-12 col-lg-12">

								<table className="table table-border">
									<tr>
										<th>Column</th>
										<th>
											Remarks
										</th>
									</tr>
									<tr>
										<td>
											Truck No
										</td>
										<td>
											Truck detail
										</td>
									</tr>
									<tr>
										<td>
											LSP Code
										</td>
										<td>
											MSIL code provided for transporter
										</td>
									</tr>
									<tr>
										<td>
											LSP Name
										</td>
										<td>
											Transporter Name
										</td>
									</tr>
									<tr>
										<td>
											Department Code
										</td>
										<td>
											Name of Department
										</td>
									</tr>
									<tr>
										<td>
											Actual GPS Provider
										</td>
										<td>
											GPS Service provider Name
										</td>
									</tr>
									<tr>
										<td>
											Last Sample Time
										</td>
										<td>
											Sample GPS data details
										</td>
									</tr>
									<tr>
										<td>
											Last Packet Time
										</td>
										<td>
											Last packet time details by service provider
										</td>
									</tr>
									<tr>
										<td>
											Status
										</td>
										<td>
											Current status of vehicle wheter actively used by dept or not
										</td>
									</tr>
									<tr>
										<td>
											Action
										</td>
										<td>
											update status of any changes made in master
										</td>
									</tr>
									<tr>
										<td>

										</td>
										<td>

										</td>
									</tr>
									<tr>
										<td>

										</td>
										<td>

										</td>
									</tr>
								</table>

							</div>
							<div className="col-xl-12 col-lg-12">
								If user wants to update multiple vehicles, this can be done through bulk option available as shown in figure below:
							</div>
							<div className="col-xl-12 col-lg-12">
								Image here
							</div>
						</div>
					</div>
				</Modal>
			</div>

		);
	}
}

function alltransporters() {

	/*Truck Transporters*/
	var arr = [];
	redirectURL.post('/masters/truckTransporters')
		.then((response) => {
			// console.log(response.data)
			return response.data;
		});

}

// function dateComparator(date1, date2) {
// 	// console.log("dateComparator");
// 	var date1Number = monthToComparableNumber(date1);
// 	var date2Number = monthToComparableNumber(date2);
// 	console.log(date1,date2);
// 	console.log(date1Number,date2Number);
// 	if (date1Number === null && date2Number === null) {
// 	  return 0;
// 	}
// 	if (date1Number === null) {
// 	  return -1;
// 	}
// 	if (date2Number === null) {
// 	  return 1;
// 	}
// 	return date1Number - date2Number;
//   }
// function monthToComparableNumber(date) {
// 	console.log(date);

// 	if (date === undefined || date === null) {
// 		return null;
// 	}
// 	if(date.search("T") == -1 )
// 	{
// 		if(date.length !== 19)
// 		{
// 			return null;
// 		}

// 	}

// 	var yearNumber = date.substring(0, 4);
// 	var monthNumber = date.substring(5,7);
// 	var dayNumber = date.substring(8,10);
// 	// console.log(yearNumber,monthNumber,dayNumber);
// 	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
// 	return result;
// }
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
		// console.log(date1,date2);
		var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
		date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
		var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
		date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
		// console.log(date1,date2);
		var date1Number = monthToComparableNumber(date1);
		var date2Number = monthToComparableNumber(date2);
		// console.log(date1Number,date2Number);
		if (date1Number === null && date2Number === null) {
			return 0;
		}
		if (date1Number === null) {
			return -1;
		}
		if (date2Number === null) {
			return 1;
		}
		return date1Number - date2Number;
	}
}
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	// console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName) {
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
	];
	var monthNum = months.indexOf(monthName) + 1;
	if (monthNum < 10) {
		monthNum = "0" + monthNum.toString();
	}
	return monthNum;
}

function checkIfArrayIsUnique(myArray) {
    return myArray.length === new Set(myArray).size;
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr) || isCharDecimal(charStr);
}

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

// function isKeyPressedNumeric(event) {
//     const charCode = getCharCodeFromEvent(event);
//     const charStr = String.fromCharCode(charCode);
//     return isCharNumeric(charStr) || isCharDecimal(charStr);
// }

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};
