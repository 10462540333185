/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import $ from "jquery";
import _ from "lodash";
import MarkerClusterer from "@google/markerclusterer";
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import CountUp from 'react-countup';
import LiveTruckMap from './livetruckmapComponent';

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { getDDMMYYYY, 
	getDDMMYYYYHHMMSS, 
	getYYYYMMDD, 
	getYYYYMMDDHHMMSS, 
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD, 
	getHyphenDDMMYYYYHHMMSS, 
	getHyphenDDMMYYYY,
	getDDMMMYYYYHHMMDefault } from '../common/utils'; 
var redirectURL = require('../redirectURL');

var infoBox = require('../common/google-infowindow');
var oms = require(`npm-overlapping-marker-spiderfier/lib/oms.min`);
var googleAnalytics = require("../common/googleanalytics");

var marker;
var markers = [];
var circle;
var selectlat;
var selectlng
var allcords=[];
var map;
var rad = function(x) {
	return x * Math.PI / 180;
  };
var rlatitude;
var rlongitude;

var fencingLength=0;
var geoFencingArea;
export default class LiveTruckLocationMap extends Component {

    constructor(props) {
		super(props);
		this.displayData = [];
        this.state = {
			pageTitle: "",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			showform:'show-m',
			loadshow:'show-n',
			showrefresh:'show-n',
			sliderTranslate:'',
			showdata:this.displayData,
			radius:'5000',
			rlatitude:'',
			rlongitude:'',
			latitude:'',
			longitude:'',
			defaultradius:0,
			coordinates:[],
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			contentString:"testing",
			mwidth:"col-xl-12 col-lg-12",
			alldepts:[],
			transportervalue:'ALL',
			alltransporters:[],
			truckstatus:'0',
			kpicode:'0',
			deptcode:'',
			truckNoList:[],
			alltrucks:[],
			truckno:'',
			showTrucksList : "show-n",
			filteredData : [],
			triptype :{"value":1,"label":"ALL"},
			plantLocations : [],
			totalTrucksCount : 0,
            onTripCounter : 0,
			mapdetails:[],
			path1:"",
			path2:"",
			path3:"",
			path4:"",
			currentslide:0,
			orignalarr:[],
			temparr:[],
			sactive:"activet",
			pactive:"",
			tactive:""
			
		};
		this.popmarker=this.popmarker.bind(this);
		this.goBack = this.goBack.bind(this);
		this.onSlideFrame = this.onSlideFrame.bind(this);
		this.timer = this.timer.bind(this);
		//this.applydept = this.applydept.bind(this);
		this.cipher = this.cipher.bind(this);
		
	}
	cipher = (salt) => {
        const textToChars = text => text.split('').map(c => c.charCodeAt(0));
        const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
        const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);
        try{
            return text => text.split('')
                .map(textToChars)
                .map(applySaltToChar)
                .map(byteHex)
                .join('');
        }
        // eslint-disable-next-line no-unreachable
        catch(e){}
    }
	
	/*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onRecieveInput = (value) => {
        this.setState({
            alert: (
                <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
                    You wrote: {value}
                </SweetAlert>
            )
        });
    }
	hideAlert = () => {
        this.setState({
            alert: null
        });
	}
	
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
		
    componentDidMount(){
		var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		//console.log(this.props.match.)
		this.logPageView(); // Log page view to GA
	
		this.setState({
			loadshow:'show-m',
			coordinates:[],
			rowData:[]
		});
		var dept;
		var hText = 'All';
		try{
			if(this.props.match.url == '/nearbyprttrucks')
			{
				dept='LOG-PRT';
				hText = 'Spare Parts';
			}
	
			if(this.props.match.url == "/nearbysndtrucks")
			{
				dept='SNDG';
				hText = 'Sales and Dispatch';
			}
			if(this.props.match.url == "/nearbysctrucks")
			{
				dept='LOG-SC';
				hText = 'Supply Chain';
			}
			if(this.props.match.url == "/nearbytnptrucks")
			{
				dept='LOG-TNP';
				hText = 'Train and Production';
			}
	
		}
		catch(e){
			var dept='SNDG';
			var hText = 'All';
		}
		
		let pageTitle = "Trucks Locations ( " + hText + " )";
		this.setState({
			deptcode:"sndg",
			pageTitle: pageTitle,
			loadshow:'show-n'
		});
		const layoutdata={
			token:encode(localStorage.getItem('token')),
			deptcode:encode(dept)
		}
		redirectURL.post('/masters/transportersbydept', layoutdata,{
			headers:{
				"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
			}
		})
  		  .then((response) => {
  			//console.log(response);
  			 var records = JSON.parse(JSON.stringify(response)).data;
  			 var optss={};
  			records.map((item, key) => {
  			   var optValue = item.dept_code;
  			 optss[optValue] = item.dept_name
  			});
  			this.setState({
				alltransporters:records
  			});
  		  })
  		  .catch(function (error) {
  		    console.log(error);
  		  });


		var formdata = {
			radius:10000,
			latitude:"20.73568784876339",
			longitude:"79.38999444931353",
			dept_code:dept,
			transporter_code:this.state.transportervalue,
			status:this.state.truckstatus,
			trip_type : 1
		}
		rlatitude = "20.73568784876339";
		rlongitude = "79.38999444931353";
		//http://autometrics.in:5000/getNearByTrucks
		redirectURL.post('/vicinity/getRadiusCoordinates',formdata,{
			headers:{
				'Content-Type':'application/json',
				'Access-Control-Allow-Methods': 'PUT, GET, POST, DELETE, OPTIONS',
				'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept, Authorization"

			} 
		})
		.then(
			(response) =>{
			//console.log(response)
			//console.log("response", JSON.parse(response.data.body));
			// console.log(response.data.body)
			var markers=JSON.parse(JSON.parse(response.data.body).trucks_data);
			//console.log(markers);
			//console.log(JSON.parse(response.data.body).gps_trucks);
			if(markers.length > 0)
			{
				this.setState({
					defaultradius:formdata.radius,
					coordinates:markers,
					totalTrucksCount : markers.length,
					mwidth:"col-xl-12 col-lg-12",
					rowData:markers,
					radius:5000,
					loadshow:'show-n',
					showform:'show-n',
					showrefresh:'show-m',
					rlatitude:"21.144644112601775",
					rlongitude:"79.08860126768066",
					alltrucks:markers,
					onTripCounter : JSON.parse(response.data.body).gps_trucks
				});
				redirectURL.post("/weather/getPlants").then((rsp)=>{
					this.setState({
					  plantLocations : rsp.data,
					});
					console.log(rsp.data);
					//this.renderMap();
		
				})								
				
			}
			else{
				this.setState({
					// show: true, basicType:'warning', basicTitle:"No Data found.",
					loadshow:'show-n',
					// showform:'show-m',
				});
				
			}
		})
		.catch(function(error){
			console.log(error);
		});

		redirectURL.post("consignments/getAllTrucksData").then((response)=>{
            // var trucks = response.data.map(function(e)
            // {
            //     if(e.truck_no != "" && e.dept_code == dept && e.truck_no != undefined)
            //     {
            //         return e.truck_no;
            //     }
            // })
            // this.setState({
            //     truckNoList :trucks
			// });
			try{
				var trucks = response.data.filter(function(e){return e.dept_code == dept;})
				//console.log("trucks ", trucks)
				trucks = trucks.map(function(e){
					if(e.truck_no != "")
					{
						return e.truck_no;
					}
				})
				this.setState({
						truckNoList :trucks
				});
			}catch(e){}
			
			//console.log(trucks.length);
        });
		var deptarr = ['sndg','prt','tnp'];
		this.setState({
			orignalarr:deptarr,
			temparr:deptarr,
		})
		//setInterval(this.timer,5000)
		this.timer();
		
	}
	timer(){
		try{
			var userroles = localStorage.getItem("roles")
			if(userroles.indexOf("led") >= 0)
			{
				var arr = ['prt','tnp'];
				var index = 0;
				var interval = setInterval(() => {
					 var currnt = arr[index++];
					 console.log("currnt ", currnt)
					 if(currnt == 'prt')
					 {
						var sactive = ''
						var pactive = 'activet'
						var tactive = ''
					 }
					 if(currnt == 'tnp')
					 {
						var sactive = ''
						var pactive = ''
						var tactive = 'activet'
					 }
					 this.setState({
						deptcode:currnt,
						sactive:sactive,
						pactive:pactive,
						tactive:tactive
					})
					 if(index > arr.length){
						 console.log(index);
						clearInterval(interval);
						//this.timer()
						window.location.reload();
					 }
				}, 120000)
			}
			else
			{

			}
			
		}
		catch(e){

		}
		
	}

	// applydept = (dept) => {
	// 	console.log("dept ",dept);
	// 	this.setState({
	// 		deptcode:dept
	// 	})
	// }					
	createSelectTransportersItems() {
		 let items = []; 
		 items.push({ value: 'ALL', label: "All Transporters" });
    	this.state.alltransporters.map(item=>{
    		items.push({ value: item.transporter_code, label: item.transporter_name });
    		//items.push(<option key={item.dept_code} value={item.dept_code}>{item.dept_name}</option>); 
    	});
    	return items;
	} 
	/*
	createSelectItems() {
        let items = [];         
        //console.log(this.state.alldepts);
        items.push(<option key='all' value='all'>All Departments </option>);  
        this.state.alldepts.map((item, key) => {
        	//console.log("Here "+item,key);
        	items.push(<option key={item.dept_code} value={item.dept_code}>{item.dept_name}</option>);  
		});
        return items;
	} 
	
	*/


    changeHandler = truckstatus => {

    	this.setState(
	      { truckstatus },
	      () => console.log(`Option selected:`, this.state.truckstatus)
	    );
	}	

    changeKpiHandler = kpicode => {

    	this.setState(
	      { kpicode },
	      () => console.log(`Option selected:`, this.state.kpicode)
	    );
	}	

	setTripType = trip =>{
		this.setState({
			triptype : trip
		})
	}
	handleChange = transportervalue => {
    	this.setState(
			{ transportervalue },
			() => console.log(`Option selected:`, this.state.transportervalue)
		  );
	}
	/*handleTruckStatusChange(e){
		this.setState({ truckstatus: e.target.value });
	}*/
	initalList =(event)=>{
        $(".trucks-dropdown").removeClass("show-n");
        var dataset = this.state.truckNoList;
        dataset = dataset.slice(0,100);
        this.setState({
            filteredData : dataset,
            showTrucksList : "show-m",
        })
    }
    handlerForm = (event) => {
		try
		{
			$(".trucks-dropdown").removeClass("show-n");
			var dataset = this.state.truckNoList;
			var filteredResult = dataset.filter(function(e){
				if(e != "" && e != null)
				{
					return e.toString().toLowerCase().includes($("#inputTruck").val().toLocaleLowerCase());
				}
				
			});
			filteredResult = filteredResult.slice(0,100);
			this.setState({
				filteredData : filteredResult,
				showTrucksList : "show-m",
			});
		}catch(e){}
    }
    onClickTruck(event)
    {
         $("#inputTruck").val($(this).text());
    }
	handleFilter(e){
    	//console.log("filter clicked");
		e.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedSearchByTransporter,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		this.setState({
			loadshow:'show-m',
		});
		//console.log("Here ",this.props.match.url);
		var dept;
		if(this.props.match.url == '/nearbyprttrucks')
		{
			dept='LOG-PRT'
		}

		if(this.props.match.url == "/nearbysndtrucks")
		{
			dept='SNDG'
		}
		//console.log("dept ",dept);
		//console.log("Checking ",this.state.transportervalue);
		var transcode=[];
		var tstatus;
		//console.log(this.state.transportervalue);
		var selectedTransporters = this.state.transportervalue;
		try{
			if(selectedTransporters.length == 0)
			{
				selectedTransporters = "ALL";
			}
			if(selectedTransporters != "ALL")
			{
				selectedTransporters.map(function(e){
					transcode.push(e.value);
				})
				// if(this.state.transportervalue == 'ALL')
				// {
				// 	transcode=this.state.transportervalue;
				// }
				// else{
				// 	transcode=this.state.transportervalue.value;
				// }
				if(transcode.indexOf("ALL") != -1)
				{
					transcode = "ALL";
				}
				else
				{
					transcode = JSON.stringify(transcode);
				}
			}
			else
			{
				transcode = selectedTransporters;
			}
		}catch(e){
			transcode = "ALL";
		}
		
		
		if(this.state.truckstatus == '0')
		{
			tstatus=this.state.truckstatus;
		}
		else{
			tstatus=this.state.truckstatus.value;
		}
		var trip_type = this.state.triptype.value;
		
		var formdata = {
			radius:3000,
			latitude:"20.73568784876339",
			longitude:"79.38999444931353",
			dept_code:dept,
			transporter_code:transcode,
			status:tstatus,
			trip_type : trip_type
		}
		//console.log(formdata);
		rlatitude="20.73568784876339";
		rlongitude="79.38999444931353";
		redirectURL.post('/vicinity/getRadiusCoordinates',formdata,{
			headers:{
				'Content-Type':'application/json',
				'Access-Control-Allow-Methods': 'PUT, GET, POST, DELETE, OPTIONS',
				'Access-Control-Allow-Headers': "Origin, X-Requested-With, Content-Type, Accept, Authorization"

			}
		})
		.then(
			(response) =>{
			//console.log("response", JSON.parse(response.data.body));
		
			var markers = JSON.parse(JSON.parse(response.data.body).trucks_data);
			console.log(JSON.parse(JSON.parse(response.data.body).trucks_data))

			if(markers.length > 0)
			{
				this.setState({
					defaultradius:formdata.radius,
					coordinates:markers,
					onTripCounter : JSON.parse(response.data.body).gps_trucks,
					mwidth:"col-xl-12 col-lg-12",
					rowData:markers,
					radius:formdata.radius,
					loadshow:'show-n',
					showform:'show-n',
					showrefresh:'show-m',
					rlatitude:formdata.latitude,
					rlongitude:formdata.longitude,
				});
				redirectURL.post("/weather/getPlants").then((rsp)=>{
					this.setState({
						plantLocations : rsp.data,
					});
					console.log(rsp.data);
					//this.renderMap();
					})
				
				
			}
			else{
				this.setState({
					// show: true, basicType:'warning', basicTitle:"No Data found.",
					loadshow:'show-n',
					// showform:'show-m',
				});
				
			}
		})
		.catch(function(error){
			console.log(error);
		});
		
	}
	
	toggleBounce() {
		if (marker.getAnimation() !== null) {
		  marker.setAnimation(null);
		} else {
		  marker.setAnimation(window.google.maps.Animation.BOUNCE);
		}
		
	  }
	
	changeInputHandler = (event) => {
    	let name = event.target.name;
		let value = event.target.value;
    	this.setState({[name]:value});
    }
    
	changeRadiusHandler = (event) => {
    	let name = event.target.name;
		let value = event.target.value;
		this.setState({[name]:value});
		circle.setRadius(value);
    }
    
	
	goBack(){
		window.location.reload();
	}
	getDistance = (p1, p2) => {
		//console.log("p1",p1);
		//console.log("p2",p2);
		var R = 6378137; // Earth’s mean radius in meter
		var dLat = rad(p2.lat - p1.lat);
		var dLong = rad(p2.lng - p1.lng);
		var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
		var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		var d = R * c;
		return d/1000; // returns the distance in meter
	  };
	onShowInfo = (e) =>{
		console.log(JSON.parse(e.target.id));
		this.setState({
			contentString:"Here Success"
		});
		var ourMarker = e.target.id;
 			window.google.maps.event.trigger(ourMarker, 'click');
		var infowindow = new window.google.maps.InfoWindow({
			content: "Here Success"
		  });
		infowindow.open(map, marker);

	}
	popmarker(e)
	{
		//console.log("OnClick Table ",e.data)
		var lat = parseFloat(e.data.latitude);
		var lng =parseFloat(e.data.longitude);
		var data =e.data;
		map.setCenter(new window.google.maps.LatLng(lat,lng));
		map.setZoom(22);
		var markerLatlng = new window.google.maps.LatLng(lat, lng);
		var image =  require('../../assets/icons/truckblue.png');
		var marker = new window.google.maps.Marker({
			position: markerLatlng,
			map: map,
			title:data.truck_no,
			icon:image
		});
		// var contentString = "Truck No: "+data.truck_no+"<br />Name: "+data.transporter_name+" LSP Name:"+data.lsp_name+"<br />Address:"+data.address;
		// var contentString = "<table><tr><th>Status: </th><td style='text-align: right'>"+data.status;
		// contentString = contentString +"</td></tr><th>Truck No: </th><td style='text-align: right'>"+data.truck_no;
		// contentString = contentString + "</td></tr><tr><th>Name: </th><td style='text-align: right'>"+data.transporter_name+"</td></tr><tr><th> LSP Name:</th><td style='text-align: right'>"+data.lsp_name;
		// contentString = contentString + "</td></tr><tr><th>Address:</th><td style='text-align: right'>"+data.address+"</td></tr></table>";
		
		var contentarr = []
		var headerTitle = data.truck_no
		//contentarr.push({"key":"Truck No", "value":marker.truck_no});
		contentarr.push({"key":"Transporter Name", "value":data.transporter_name});
		//contentarr.push({"key":"Distance from geofence", "value":marker.distance});
		contentarr.push({"key":"GPS Provider", "value":data.actual_lspuser});
		contentarr.push({"key":"Last Packet Time", "value":getHyphenDDMMMYYYYHHMM(data.timestamp)});
		contentarr.push({"key":"Speed (km/h)", "value":Math.ceil(data.speed)});
		contentarr.push({"key":"Address", "value":data.area+", "+data.state});
		
		var contentString = infoBox(image, headerTitle, contentarr, '')
		var infowindow = new window.google.maps.InfoWindow({
			content: contentString
		});
		
		marker.setAnimation(window.google.maps.Animation.DROP)
		marker.addListener('click', function() {
			infowindow.open(map, marker);
		});
		
		marker.setMap(map);
		
		
	}
	onShowUploadDiv = () => {
		//console.log("Click Upload Button")
		this.setState({
			uploadDivWidth:'30%',
			sliderTranslate:"slider-translate",
			showDiv:'show-m',
			csvmessage:'',
			csverrmessage:'',
		});
	}
	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth:'0%',
			sliderTranslate:"",
			showDiv:'show-n',
			uploadFile:'',
			file:'',
			csvmessage:'',
			csverrmessage:'',
		});
	}

	onSelectTrucks(){
		var items = []
		this.state.alltrucks.map((item) => {
			items.push({ value: item, label: item.truck_no });
		})
		return items;
	}
	changeTruckHandler(event){
		let name = event.target.name;
		let value = event.target.value;
    	this.setState({[name]:value});
	}
	truckFormHandler(e){
		e.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmittedSearchByTruckNo
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var truck = []
		var trucks = this.state.alltrucks;
		console.log($("#inputTruck").val(),"input truck");
		var filteredValue = trucks.filter(function(e){
			return e.truck_no == $("#inputTruck").val()
		});
		if(filteredValue.length > 0)
		{
			console.log("Matched")
			  var lat = parseFloat(filteredValue[0].latitude);
				var lng =parseFloat(filteredValue[0].longitude);
				var data = filteredValue[0];
				map.setCenter(new window.google.maps.LatLng(lat,lng));
				map.setZoom(22);
				var markerLatlng = new window.google.maps.LatLng(lat, lng);
				
				if(filteredValue[0].status == 1)
				{
					var truckText =  "Inside Plant";
					var color =  "#333333";
				}
				else if(filteredValue[0].status == 2)
				{
					var truckText =  "Going to Dealer";
					var color =  "#333333";
				}
				else if(filteredValue[0].status == 3 || filteredValue[0].status == 4) 
				{
					var truckText =  "At Dealer Location";
					var color =  "#333333";
				}
				else if(filteredValue[0].status == 5)
				{
					var truckText =  "Left Destination Location";
					var color =  "#333333";
				}
				else if(filteredValue[0].status == 6)
				{
					var truckText =  "Return to Plant";
					var color =  "#333333";
				}
				else if(filteredValue[0].status == 7)
				{
					var truckText =  "Empty Truck Outside Plant";
					var color =  "#333333";
				}
				else{
					var truckText =  "N/A";
					var color =  "#333333";
				}
				
				if(filteredValue[0].status == 1)
				{
					var image =  require('../../assets/icons/truck_inside_24.png');
				}
				else if(filteredValue[0].status == 2)
				{
					var image =  require('../../assets/icons/truck_going_24.png');
				}
				else if(filteredValue[0].status == 3 || filteredValue[0].status == 4) 
				{
					var image =  require('../../assets/icons/truck_atdealer_24.png');
				}
				else if(filteredValue[0].status == 5)
				{
					var image =  require('../../assets/icons/truck_leftdealer_24.png');
				}
				else if(filteredValue[0].status == 6)
				{
					var image =  require('../../assets/icons/truck_return_24.png');
				}
				else if(filteredValue[0].status == 7)
				{
					var image =  require('../../assets/icons/truck_empty_24.png');
				}
				else{
					var image =  require('../../assets/icons/truck_na_24.png');
				}
				var image =  require('../../assets/icons/truck_na_24.png');
				var marker = new window.google.maps.Marker({
					position: markerLatlng,
					map: map,
					title:data.truck_no,
					icon:image
				});



				var contentarr = []
				var headerTitle = truckText
				contentarr.push({"key":"Truck No", "value":filteredValue[0].truck_no});
				contentarr.push({"key":"Transporter Name", "value":filteredValue[0].transporter_name});
				//contentarr.push({"key":"Distance from geofence", "value":trucks[i].distance});
				contentarr.push({"key":"LSP Name", "value":filteredValue[0].lsp_name});
				contentarr.push({"key":"Address", "value":filteredValue[0].address});
				
				var contentString = infoBox(image, headerTitle, contentarr, '')

			



				// var contentString = "<table><tr><th>Status: </th><td style='color:"+color+";font-weight:600;text-align: right'>"+truckText+"</td></tr><tr><th>Distance from geofence: </th><td style='text-align: right'>";
				// contentString = contentString + trucks[i].distance+"</td></tr><th>Truck No: </th><td style='text-align: right'>"+trucks[i].truck_no+"</td></tr><tr><th>Name: </th><td style='text-align: right'>";
				// contentString = contentString + trucks[i].transporter_name+"</td></tr><tr><th> LSP Name:</th><td style='text-align: right'>"+trucks[i].lsp_name+"</td></tr><tr><th>Address:</th><td style='text-align: right'>";
				// contentString = contentString  +trucks[i].address+"</td></tr></table>";
			
				
				var infowindow = new window.google.maps.InfoWindow({
					content: contentString
				});
				
				marker.setAnimation(window.google.maps.Animation.DROP)
				marker.addListener('click', function() {
					infowindow.open(map, marker);
				});
				
				marker.setMap(map);
		}
		else{
			this.setState({
				// show: true, basicType:'warning',
				// basicTitle:"No Data found.",
				loadshow:'show-n',
				// showform:'show-m',
			})
		}
		
	}

	onSlideFrame = event =>{
		// 
		console.log("event ",event)

		if(event == 0 || event == undefined)
		{
			var path1 = "sndg"
		}

		if(event == 1)
		{
			var path2 = "prt"
		}


		if(event == 2)
		{
			var path3 = "tnp"
		}


		if(event == 3)
		{
			var path4 = "sc"
		}


		this.setState({
			path1:path1,
			path2:path2,
			path3:path3,
			path4:path4,
			currentslide:event
		})

	}

	onClickTab(dept){
		if(dept == 'sndg')
		{
		   var sactive = 'activet'
		   var pactive = ''
		   var tactive = ''
		}
		if(dept == 'prt')
		{
		   var sactive = ''
		   var pactive = 'activet'
		   var tactive = ''
		}
		if(dept == 'tnp')
		{
		   var sactive = ''
		   var pactive = ''
		   var tactive = 'activet'
		}
		this.setState({
		   deptcode:dept,
		   sactive:sactive,
		   pactive:pactive,
		   tactive:tactive
	   })
	}
    render(){
							
		const { truckstatus } =this.state;
		const truckstatusoptions = [
			{ value: '0', label: 'All Status' },
			{ value: '1', label: 'Inside Plant' },
			{ value: '2', label: 'Going to Destination' },
			{ value: '3', label: 'At Destination Location' },
			{ value: '5', label: 'Left Destination Location' },
			/*{ value: '6', label: 'Return to Plant' },*/
			{ value: '7', label: 'Empty Truck Outside Plant' },
		  ];
		  const kpioptions = [
			  { value: '0', label: 'All Status' },
			  { value: '1', label: 'Overspeed' },
			  { value: '2', label: 'Transit Time Delay' },
			  { value: '3', label: 'Night Driving' },
			  { value: '4', label: 'Enroute Stoppages' }
			];
    	const dStyles={
			width:'90%',
			marginLeft:'0px',
			paddingLeft:'0px',
			height:'500px'
		}
		const btstye={
			marginBottom:"10px"
		}
		var listData = []
        try{
            this.state.filteredData.map(function(e){
                listData.push(<li className="dropdown-truck-no">{e}</li>)
                })
		}catch(e){console.log(e)}
		
        return (
            
    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        > 
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
            		<div className="col-xl-12 col-lg-12 whiteRow">
		            	
						<div className="row col-xl-12 col-lg-12">
							
							<div className={"dataLoadpage " +(this.state.loadshow)}>
							</div>
							<div className={"dataLoadpageimg " +(this.state.loadshow)}>
								<div className="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
							</div>
								
							{/* <Carousel style={{width:"100% !important"}} 
								activeIndex={0} 
								autoPlay={true} 
								infiniteLoop={true} 
								interval={300000} 
								onChange={this.onSlideFrame}  
								slide={true} 
								showArrows={true}> */}
								<div className="row">
									{/* <div className="col-xl-12 col-lg-12 f16 mt-40p">
										<ul className="d-tabs">
											<li onClick={this.onClickTab.bind(this,"sndg")} className={"ttabs "+(this.state.sactive)}>
												<button type="button" className="f16 btn" onClick={this.onClickTab.bind(this,"sndg")}>Sales and Dispatch</button>
											</li>

											<li onClick={this.onClickTab.bind(this,"prt")} className={"ttabs "+(this.state.pactive)}>
												<button type="button" className="f16 btn" onClick={this.onClickTab.bind(this,"prt")} href="">Spare Parts</button>
											</li>

											<li onClick={this.onClickTab.bind(this,"tnp")} className={"ttabs "+(this.state.tactive)}>
												<button type="button" className="f16 btn" onClick={this.onClickTab.bind(this,"tnp")} href="">Train and Production</button>
											</li>
										</ul>
									</div> */}
									<div className="col-xl-6 col-lg-6 mt-10p">
										
										<div className="col-xl-12 col-lg-12">
											{/* <LiveTruckMap 
												coordinates={this.state.coordinates}
												rlongitude={this.state.rlongitude}
												rlatitude={this.state.rlatitude}
												plantLocations={this.state.plantLocations}
											/> */}
											
											<iframe 
												style={{overflow:"hidden",border:"0px", margin:"0px", width:"100%"}} 
												width="100%" 
												frameborder="0"
												height="380px" 
												src={"/livemaptruck?type=1&dept="+(this.state.deptcode)+"&slide=1&cslide="+(this.state.currentslide)}>
												
											</iframe>
										</div>
									</div>
									<div className="col-xl-6 col-lg-6 mt-10p">
										
										<div className="col-xl-12 col-lg-12">
											{/* <LiveTruckMap 
												coordinates={this.state.coordinates}
												rlongitude={this.state.rlongitude}
												rlatitude={this.state.rlatitude}
												plantLocations={this.state.plantLocations}
											/> */}

											<iframe 
												style={{overflow:"hidden", margin:"0px", width:"100%"}} 
												width="100%" 
												height="380px" 
												frameborder="0"
												src={"/livemaptruck?type=2&dept="+(this.state.deptcode)+"&slide=1&cslide="+(this.state.currentslide)}>
												
											</iframe>
										</div>
									</div>
									<div className="col-xl-6 col-lg-6">
										
										<div className="col-xl-12 col-lg-12">
											
											<iframe 
												style={{overflow:"hidden", margin:"0px", width:"100%"}} 
												width="100%" 
												height="380px" 
												frameborder="0"
												src={"/livemaptruck?type=5&dept="+(this.state.deptcode)+"&slide=1&cslide="+(this.state.currentslide)}>
												
											</iframe>
										</div>
									</div>
									<div className="col-xl-6 col-lg-6">
										
										<div className="col-xl-12 col-lg-12">
											
											<iframe 
												style={{overflow:"hidden", margin:"0px", width:"100%"}} 
												width="100%" 
												height="380px" 
												frameborder="0"
												src={"/livemaptruck?type=3&dept="+(this.state.deptcode)+"&slide=1&cslide="+(this.state.currentslide)}>
												
											</iframe>
										</div>
									</div>
								</div>
								
							
				   		</div>	
				   	</div>
					   
				 </div>
				

            </div>
              
        );
    }
}


function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function getPolygonCoords()
{
	
	 var len = geoFencingArea.getPath().getLength();
	 console.log(len);
	 var htmlStr = "";
	 var coordinates=[];
	 for (var i = 0; i < len; i++) {
		// var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
		// coordinates.push()
		 var strlatlng = geoFencingArea.getPath().getAt(i).toUrlValue(5);
		 var spliting = strlatlng.split(",");
		 var latlngd = {"lat":parseFloat(spliting[0]),"lng":parseFloat(spliting[1])};
		 coordinates.push(latlngd);
	  // htmlStr += "new google.maps.LatLng(" + geoFencingArea.getPath().getAt(i).toUrlValue(5) + "), ";
	   //Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
	   //htmlStr += "" + geoFencingArea.getPath().getAt(i).toUrlValue(5);
	 }
	// console.log("htmlStr", coordinates);
	 
}


$(document).ready(function(){
    $(document).on("click",".dropdown-truck-no",function(){
        var vals = $(this).text();
         $("#inputTruck").val(vals);
         $(".trucks-dropdown").removeClass("show-m");
         $(".trucks-dropdown").addClass("show-n");
    });

    $("body").on("click",function(e){
        var container = $("#inputTruck");
        if(!container.is(e.target))
        {
            try{
                if($(".trucks-dropdown").attr("class").includes("show-m") || $(".trucks-dropdown").attr("class") == "trucks-dropdown")
                {
                    $(".trucks-dropdown").removeClass("show-m");
                    $(".trucks-dropdown").addClass("show-n");
                }
            }catch(e){}
            
        }
        
        
    })
})