import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import ColumnChart from './columnComponent';
var redirectURL = require('../redirectURL');

export default class TransporterWiseTrucksWithGPSChartComponent extends Component {

    constructor(props) {
		super(props);
		this.state = {
			transportschart:'',
			transportchartTitles:'',
			transportchartSeries:'',
			inlineFilters:'',
			filter_from_date:'',
			filter_to_date:'',
		}
    }
	componentDidMount(){
		const layoutdata={
            token:localStorage.getItem('token'),
            dept_code:this.props.deptcode
        }
		redirectURL.post('/overview/transporterWiseTrucksChart', layoutdata,{
			headers:{
				"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
			}
		})
		  .then((response) => {
			 
		   var records = JSON.parse(JSON.stringify(response)).data;
		  // console.log(records);
		   var xaxis=[];
		   var seriesdata=[];
		   
		   for(var rec in records)
		   {
			//   console.log(records[rec]);
			   xaxis.push(records[rec]._id);
			   seriesdata.push(parseInt(records[rec].count));
		   }
		   this.setState({
			   transportschart:records,
			   transportchartTitles:xaxis,
			   transportchartSeries:seriesdata
		   });
		    
		  })
		  .catch(function (error) {
		    console.log(error);
		  });

	}
	onShowInlineFilter = (i) => {
	   //console.log("i " ,i)
	   this.setState({
		   inlineFilters:"fcslide-translate-25p",
	   });	
   }
   onCloseiInlineFilter(){
	   this.setState({
		   inlineFilters:""
	   });	
   }
    render(){
    	
     
        return ( 
			<div className={"card"} >
			<div className="card-header">
				<h5 className="grid-h5">{this.props.componentTitle}
					<span className="pull-right">
						<ul className="actions-list">
							{/*<li>
								<a href="#" onClick={this.onShowInlineFilter.bind(this)} title="Filter">
									<i class="fa fa-filter"></i>
								</a>
							</li>
							*/}
							<li>
								<a className="grid-mx" href="" title="Maximize">
									<i class="fa fa-external-link"></i>
								</a>
							</li>
							
						</ul>
					</span>
				</h5>
			</div> 
			<div className="card-body" style={{padding:"0px"}}>
				<div className="dashboard-chart-container sales-overview-chart posrel">
							
					<ColumnChart columnaxis={this.state.transportchartTitles} columnyaxis={this.state.transportchartSeries} />
					<div className={"fcslide-r "+(this.state.inlineFilters)}>
						<div className="fcslide-r-title">
							<h4>
								<i class="fa fa-external-link"></i> Filter
								<span className="float-right fclose" onClick={this.onCloseiInlineFilter.bind(this)}>x</span>
							</h4>
						</div>	
					</div> 
				</div>
			</div>
			
		</div> 
			);
		}
}












