/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import _ from "lodash";
import Select from 'react-select';
import SweetAlert from 'react-bootstrap-sweetalert';

import axios from 'axios';
import Multiselect from 'multiselect-dropdown-react';
import Breadcrumb from '../common/breadcrumb.component';
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749', '#F73E3E', '#B7B3B3'];
var selectedColor;
var colorButtons = {};
var fencingLength = 0;
var mapZoom = 12;
var coordinates = [];
var newcoordinates = [];
var geoFencingArea;
const fenchtypeoptions = [
	{ value: 'Plant', label: 'Plant' },
	{ value: 'Parking', label: 'Parking' },
	// { value: 'TVP', label: 'TVP' },
	// { value: 'Stockyard', label: 'Stockyard' },
	// { value: 'Railyard', label: 'Railyard' },
	// { value: 'Port', label: 'Port' },
	// { value: 'Vendor', label: 'Vendor' },
	// { value: 'MSP', label: 'MSP' },
];


export default class EditGeoFence extends Component {
	state = {
		pageTitle: "Edit Geo Fence",
		eventGridAction: "gridAction",
		eventCounterAction: "counterAction",
		eventFormAction: "formAction",
		routemap: '',
		departments: [],
		coords: [],
		geo_name: '',
		geo_type: '',
		fence_type: '',
		dept_code: [],
		notify: '',
		erroMessage: '',
		successMessage: '',
		selectedDepts: [],
		oldgeofencename: '',
		coordinates: [],
		redUrl: "",
		show: false,
		basicType: "default",
		basicTitle: "",
		plantDetails: null,
	};


	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
			} else {
				console.log("GA disabled...");
			}
		} catch (error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
	componentDidMount = async () => {
		if (googleAnalytics.page.enableGA) {
			this.logPageView();
		}
		this.getDropDownList()

		await redirectURL.post('/overview/departments')
			.then((response) => {
				//console.log(response.data);
				this.setState({
					departments: response.data
				});
			})
			.catch(function (error) {
				console.log(error)
			});
		let dpt = JSON.parse(localStorage.getItem("dept_code"))[0]
		
		this.setState({
			oldgeofencename: this.props.match.params.geofencename,
			// 'selectedDepts': {"value": dpt, 'label': dpt}
		});
		var geoname = this.props.match.params.geofencename;
		var originalName = geoname.replace(/_/g, " ");
		var fdata = {
			"geofencename": originalName,
			dept_code : dpt
		}


		await redirectURL.post('/geofence/list', fdata, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
			.then(async (response) => {
				var deptarr = [];
				// console.log("response.data[0].dept_code; ", response.data[0].dept_code);
				var alldepts = response.data[0].dept_code;
				this.setState({
					dept_code: alldepts[0].value
				})
				// await alldepts.forEach(item => {
				// 	deptarr.push({value:item.value,label:item.label});
				// 	//deptarr.push({value:item.dept_code});
				// });
				//console.log("GeoFenc Coordinates ", response.data);
				//console.log("deptarr ", deptarr)
				// console.log("alldepts", alldepts[0].value)
				if (this.props.match.path.indexOf("mlleditGeofence") >= 0) {
					var redurl = '/manage/mllsndgeofences';

				}
				else {
					if (alldepts[0].value === "LOG-TNP") {
						redurl = '/manage/tnpgeofences';
					}
					else if (alldepts[0].value === "SNDG") {
						redurl = '/manage/sndgeofences';
					}
					else if (alldepts[0].value === "LOG-PRT") {
						redurl = '/manage/prtgeofences';
					}
					else if (alldepts[0].value === "LOG-SC") {
						redurl = '/manage/scgeofences';
					}


				}
				coordinates = JSON.parse(response.data[0].geofence_coordinates);
				//console.log("cc ", coordinates)
				//console.log("response.data[0].geofence_coordinates ", response.data[0].geofence_coordinates)
				var geotypesarr = '';

				geotypesarr = { value: response.data[0].geofence_type.value, label: response.data[0].geofence_type.label };
				// plant Details
				// let plantDetails = {value : response.data[0].plant_code, label : response.data[0].plant_code}
				if(geotypesarr.value == 'Parking'){
					
					this.setState({
						redUrl: redurl,
						geo_name: response.data[0].geofence_name,
						geo_type: geotypesarr,
						fence_type: response.data[0].fence_loading_type,
						dept_code: alldepts,
						notify: response.data[0].notify_me,
						selectedDepts: alldepts,
						coords: response.data[0].geofence_coordinates,
						coordinates: coordinates,
						isParking : true	
					});
				}else{
					this.setState({
						redUrl: redurl,
						geo_name: response.data[0].geofence_name,
						geo_type: geotypesarr,
						fence_type: response.data[0].fence_loading_type,
						dept_code: alldepts,
						notify: response.data[0].notify_me,
						selectedDepts: alldepts,
						coords: response.data[0].geofence_coordinates,
						coordinates: coordinates	
					});
				}

			})
			.catch(function (error) {
				console.log(error)
			});
		setTimeout(
			function () {
				this.showMap();
			}
				.bind(this),
			2000
		);


	}

	getDropDownList = () => {
		let dpt = JSON.parse(localStorage.getItem("dept_code"))[0]
		redirectURL.post('/consignments/getTypeDropDownForgeoFence',{dept_code : dpt}).then(response => {
			// console.log("response118", response.data)
			let typeListOptions = response.data.data1
			let plantListOptoins = response.data.pD1
			// console.log('typeListOptions',typeListOptions)
			// console.log('plantListOptoins',plantListOptoins)
			this.setState({
				typeListOptions,
				plantListOptoins
			})

		})
	}

	fenceTypeHandler = (event) => {
		console.log(event.target)
	}


	changeHandler = geo_type => {
		if (geo_type.value == 'Parking') {
			this.setState({
				isParking: true,
				geo_type
			})
		} else {
			this.setState(
				{ geo_type, isParking: false },
				// () => console.log(`Option selected:`, this.state.geo_type)
			);
		}
	}

	changePlantHandler = plantDetails => {
		this.setState({ plantDetails })
	}

	checkHandler = (event) => {

		let name = event.target.name;
		let value = event.target.value;
		this.setState({ [name]: value });
	}
	changeInputHandler = (event) => {
		let name = event.target.name;
		let value = event.target.value;
		this.setState({ [name]: value });
	}

	changeArrayHandler = selectedDepts => {
		/*this.setState({
			dept_code:[...this.state.dept_code,event.target.value]
		},() => {
			console.log(this.state.dept_code)
		});
		*/

		this.setState(
			{ selectedDepts },
			// () => console.log(`Option selected:`, this.state.selectedDepts)
		);


	}


	changeGeoTypeHandler = geo_type => {
		/*this.setState({
			dept_code:[...this.state.dept_code,event.target.value]
		},() => {
			console.log(this.state.dept_code)
		});
		*/

		this.setState(
			{ geo_type },
			() => console.log(`GeoType Option selected:`, this.state.geo_type)
		);


	}

	checkNotifyHandler = (event) => {
		let name = event.target.name;
		let value = event.target.value;
		this.setState({ [name]: value });
	}

	applyCoords = (e) => {
		console.log(e);
	}

	formHandler = (event) => {

		event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pageTitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventFormAction,
				"label": googleAnalytics.page.action.formSubmitted,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var formdata1 = new FormData(event.target);
		//console.log(coordinates.length, formdata1);


		if (coordinates.length > 0) {
			var formdata = {
				geo_name: this.state.geo_name,
				dept_code: this.state.selectedDepts,
				geo_type: this.state.geo_type,
				fence_type: this.state.fence_type,
				notify: this.state.notify,
				coordinates: coordinates,
				oldgeofencename: this.state.oldgeofencename
			}
			//console.log(formdata);
			//console.log("coordinates", coordinates);
			if (this.state.plantDetails !== null && this.state.plantDetails !== undefined) formdata.plant_code = this.state.plantDetails.value

			redirectURL.post("/geofence/updateGeo", formdata, {
				headers: {
					'Content-Type': 'application/json'
				}
			}).then(response => {
				var resp = response;
				console.log(this.state.dept_code)
				// console.log("respones ",resp.message)
				if (this.state.dept_code[0].value == 'LOG-PRT') {
					window.location.href = '/manage/prtgeofences';
				}
				else if (this.state.dept_code[0].value == 'SNDG') {
					window.location.href = '/manage/sndgeofences';
				}
				else {
					window.location.href = '/manage/geofences';
				}

				this.setState({
					successMessage: "Successfully updated",

					show: false,
					basicType: "success",
					basicTitle: "Successfully update geofence"
				});
			}
			)
				.catch(function (error) {
					console.log(error);
				});

		}
		else {
			this.setState({
				erroMessage: 'Please Add Coordinates'
			});
		}

	}

	showMap = () => {
		this.renderMap();
	}
	renderMap = () => {

		loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyA08YKZGPl0ii3K-t4_guTbdtjscGgdPns&libraries=places,drawing&callback=initMap");
		window.initMap = this.initMap
	}

	departmentDropDown() {
		let items = [];
		this.state.departments.map(item => {
			items.push({ value: item.dept_code, label: item.dept_name });
			//items.push(<option key={item.dept_code} value={item.dept_code}>{item.dept_name}</option>);
		});
		return items;
	}

	initMap = () => {
		//console.log("coordinates ", this.state.coordinates)
		var coordsvalues;
		var geoFencingObj;
		if (this.state.coords != '') {

		}
		geoFencingObj = coordinates;
		console.log("here coordinates", geoFencingObj);
		if (geoFencingObj.length > 0) {
			var map = new window.google.maps.Map(document.getElementById('map_canvas'), {
				zoom: 13,
				center: new window.google.maps.LatLng(geoFencingObj[0].lat, geoFencingObj[0].lng),
				mapTypeId: window.google.maps.MapTypeId.ROADMAP,
				mapTypeControl: true,
				mapTypeControlOptions: {
					style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
					position: window.google.maps.ControlPosition.TOP_RIGHT
				},
				disableDefaultUI: true,
				zoomControl: true
			});

			//added by nanda for places dropdown...
			var input = document.getElementById('search');
			var searchBox = new window.google.maps.places.SearchBox(input);
			map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

			// Bias the SearchBox results towards current map's viewport.
			map.addListener('bounds_changed', function () {

				searchBox.setBounds(map.getBounds());
			});

			var markers = [];
			searchBox.addListener('places_changed', function () {
				var places = searchBox.getPlaces();

				if (places.length == 0) {
					return;
				}

				// Clear out the old markers.
				markers.forEach(function (marker) {
					marker.setMap(null);
				});
				markers = [];
				// For each place, get the icon, name and location.
				var bounds = new window.google.maps.LatLngBounds();
				places.forEach(function (place) {
					if (!place.geometry) {
						console.log("Returned place contains no geometry");
						return;
					}
					var icon = {
						url: place.icon,
						size: new window.google.maps.Size(71, 71),
						origin: new window.google.maps.Point(0, 0),
						anchor: new window.google.maps.Point(17, 34),
						scaledSize: new window.google.maps.Size(25, 25)
					};

					// Create a marker for each place.
					markers.push(new window.google.maps.Marker({
						map: map,
						icon: icon,
						title: place.name,
						position: place.geometry.location
					}));

					if (place.geometry.viewport) {
						// Only geocodes have viewport.

						bounds.union(place.geometry.viewport);
					} else {

						bounds.extend(place.geometry.location);
					}
				});
				map.fitBounds(bounds);
			});
			//End of adding by nanda
			geoFencingArea = new window.google.maps.Polygon({
				//map:map,
				paths: geoFencingObj,
				editable: true,
				strokeColor: '#1D9567',
				strokeOpacity: 0.70,
				strokeWeight: 0,
				fillColor: '#1D9567',
				fillOpacity: 0.35,
				draggable: true

			});
			geoFencingArea.setMap(map);
			fencingLength = geoFencingObj;
			console.log("test ", geoFencingArea.getPath());
			//window.google.maps.event.addListener(geoFencingArea, "dragend", getPolygonCoords);

			window.google.maps.event.addListener(geoFencingArea.getPath(), 'insert_at', getPolygonCoords);
			window.google.maps.event.addListener(geoFencingArea.getPath(), 'set_at', getPolygonCoords);

			/*
			var getPolygonCoords = function() {
				var len = geoFencingArea.getPath().getLength();
				console.log(len);
				var htmlStr = "";
				for (var i = 0; i < len; i++) {
				 // htmlStr += "new google.maps.LatLng(" + geoFencingArea.getPath().getAt(i).toUrlValue(5) + "), ";
				  //Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
				  htmlStr += "" + geoFencingArea.getPath().getAt(i).toUrlValue(5);
				}
				console.log("htmlStr", htmlStr);
			}*/
		}
	}

	closeAlert = async () => {
		await this.setState({
			show: false
		});
		await window.location.reload();
	}
	render() {
		let order_cnt = [];

		const dStyles = {
			width: '100%',
			height: '500px'
		}
		const { dept_code, geo_type, fence_type, notify, selectedDepts } = this.state;
		//selectedDepts.push(this.state.dept_code)
		// console.log("Geo Data is ",this.state.geo_type);
		let redurl = this.state.redUrl
		return (
			<div className="container-fluid">
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}>
				</SweetAlert>
				<div className="row">
					<div className="col-xl-12 col-lg-12">
						<div className="card">

							<div className="card-header">
								<h5>
									<i className="icon-map cus-i"></i> <span>{this.state.pageTitle}</span>
								</h5>
							</div>
							<div className="row card-body">

								<form className=" theme-form col-xl-4 col-lg-4" onSubmit={this.formHandler}>
									<div className="col-xl-12 col-lg-12">
										<div className="form-group">
											<label className="c-lbl">Geofence Name *:</label>
											<input type="text" name="geo_name" value={this.state.geo_name} onChange={this.changeInputHandler.bind(this)} className="form-control" placeholder="Name" />
										</div>

										<div className="form-group">
											<label className="c-lbl">Department *:</label>
											<Select
											//  isDisabled="true"
											 value={selectedDepts} onChange={this.changeArrayHandler.bind(this)} style={{ borderRadius: "0px" }} options={this.departmentDropDown()} />
											{/*
					               			<select name="dept_code[]" multiple="multiple" onChange={this.changeArrayHandler.bind(this)} value={this.state.dept_code} className="form-control" >
					               				<option value="">Select Department</option>
					               				{this.departmentDropDown()}
					               			</select>
					               			*/}
										</div>

										<div className="form-group">
											<label className="c-lbl">Type *:</label>
											<Select value={this.state.geo_type} onChange={this.changeHandler.bind(this)} style={{ borderRadius: "0px" }}
												options={this.state.typeListOptions}
												//options={fenchtypeoptions}
												required />
										</div>
										{this.state.isParking == true ?
											<div className="form-group">
												<label className="c-lbl">Plant *:</label>
												<Select value={this.state.plantDetails} onChange={this.changePlantHandler.bind(this)} style={{ borderRadius: "0px" }}
													options={this.state.plantListOptoins}
													required />
											</div>
											: ''
										}

										<div className="form-group">
											<label className="c-lbl">Fence Type *:</label>
											<div className="row col-xl-12 col-lg-12">
												<div className="radio radio-primary col col-xl-6 col-lg-6">
													<input id="radio11" onChange={this.checkHandler} name="fence_type" type="radio" className="form-check-input" checked={fence_type == 'Both'} value="Both" />
													<label for="radio11" className="">Both</label>
												</div>
												<div className="radio radio-secondary col col-xl-6 col-lg-6">
													<input id="radio22" onChange={this.checkHandler} name="fence_type" type="radio" className="form-check-input" checked={fence_type == 'Loading Bay'} value="Loading Bay" />
													<label for="radio22" className="">Loading Bay</label>
												</div>
												<div className="radio radio-warning col col-xl-6 col-lg-6">
													<input id="radio33" onChange={this.checkHandler} name="fence_type" type="radio" className="form-check-input" checked={fence_type == 'Unloading Bay'} value="Unloading Bay" />
													<label for="radio33" className="">Unloading Bay</label>
												</div>
											</div>
										</div>

										<div className="form-group">
											<label className="c-lbl">Notify When *:</label>
											<div className="row col-xl-12 col-lg-12">
												<div className="radio radio-primary col col-xl-6 col-lg-6">
													<input id="radio44" onChange={this.checkNotifyHandler} name="notify" type="radio" className="form-check-input" checked={notify == 'Vehicle Comes in'} value="Vehicle Comes in" />
													<label for="radio44" className="">Vehicle Comes in</label>
												</div>
												<div className="radio radio-info col col-xl-6 col-lg-6">
													<input id="radio55" onChange={this.checkNotifyHandler} name="notify" type="radio" className="form-check-input" checked={notify == 'Vehicle Goes out'} value="Vehicle Goes out" />
													<label for="radio55" className="">Vehicle Goes out</label>
												</div>
												<div className="radio radio-warning col col-xl-6 col-lg-6">
													<input id="radio66" onChange={this.checkNotifyHandler} name="notify" type="radio" className="form-check-input" checked={notify == 'Both'} value="Both" />
													<label for="radio66" className="">Both</label>
												</div>
												<div className="radio radio-danger col col-xl-6 col-lg-6">
													<input id="radio77" onChange={this.checkNotifyHandler} name="notify" type="radio" className="form-check-input" checked={notify == 'Never'} value="Never" />
													<label for="radio77" className="">Never</label>
												</div>
											</div>
										</div>
										<div className="form-group">
											<button type="submit" className="btn btn-success cs-btn" id="saveGeo">Save Geofence</button>
											<a href={redurl} className="btn btn-info cs-btn">Cancel</a>

										</div>
									</div>

								</form>
								<div className="col-xl-8 col-lg-8">

									{this.state.erroMessage == '' ? '' :
										<div className="alert alert-danger"></div>
									}
									<input type="text" name="search" className="col-xl-6 col-lg-6 form-control mt-5p" id="search" placeholder="Enter Address to search" />


									<div id="map_canvas" className="col-xl-12 col-lg-12" style={dStyles}></div>

									<div className="col-xl-12 col-lg-12">
										<input type="hidden" name="coords" id="coords" value={this.state.coords} />
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		);
	}
}
function loadScript(url) {
	var index = window.document.getElementsByTagName("script")[0]
	var script = window.document.createElement("script")
	script.src = url
	script.async = true
	script.defer = true
	index.parentNode.insertBefore(script, index)
}

function timeConvert(n) {
	var num = n;
	var hours = (num / (3600));
	var rhours = Math.floor(hours);
	var minutes = (n) / (60);
	var rminutes = Math.round(minutes);
	return rhours + " hour(s) and " + rminutes + " minute(s).";
}
var currentinfowindow = null;
function getTimeInfo(marker, timedet, coords) {
	var timeinfo = new window.google.maps.InfoWindow({
		content: "Time at this location: " + timedet
	});

	marker.addListener('click', function () {

		if (currentinfowindow != null) {
			currentinfowindow.close();
			currentinfowindow = timeinfo;
			timeinfo.open(marker.get('map'), marker);
		}
		else {
			currentinfowindow = timeinfo;
			timeinfo.open(marker.get('map'), marker);
		}

	});
}


function clearSelection() {
	if (selectedShape) {
		selectedShape.setEditable(false);
		selectedShape = null;
	}
}

function setSelection(shape) {
	clearSelection();
	selectedShape = shape;
	//console.log("selectedShape",selectedShape);
	shape.setEditable(true);

	selectColor(shape.get('fillColor') || shape.get('strokeColor'));
}

function deleteSelectedShape() {
	fencingLength = 0;
	if (selectedShape) {
		selectedShape.setMap(null);
	}
	// To show:
	drawingManager.setOptions({
		drawingControl: true
	});
}


function selectColor(color) {
	selectedColor = color;
	for (var i = 0; i < colors.length; ++i) {
		var currColor = colors[i];
		//console.log(colorButtons[currColor]);
		//colorButtons[currColor].style.border = currColor == color ? '1px solid #789' : '1px solid #fff';
	}

	// Retrieves the current options from the drawing manager and replaces the
	// stroke or fill color as appropriate.
	var polylineOptions = drawingManager.get('polylineOptions');
	polylineOptions.strokeColor = color;
	drawingManager.set('polylineOptions', polylineOptions);

	var rectangleOptions = drawingManager.get('rectangleOptions');
	rectangleOptions.fillColor = color;
	drawingManager.set('rectangleOptions', rectangleOptions);

	var circleOptions = drawingManager.get('circleOptions');
	circleOptions.fillColor = color;
	drawingManager.set('circleOptions', circleOptions);

	var polygonOptions = drawingManager.get('polygonOptions');
	polygonOptions.fillColor = color;
	drawingManager.set('polygonOptions', polygonOptions);
}

function setSelectedShapeColor(color) {
	if (selectedShape) {
		if (selectedShape.type == window.google.maps.drawing.OverlayType.POLYLINE) {
			selectedShape.set('strokeColor', color);
		} else {
			selectedShape.set('fillColor', color);
		}
	}
}

function makeColorButton(color) {
	var button = document.createElement('span');
	button.className = 'color-button';
	button.style.backgroundColor = color;
	window.google.maps.event.addDomListener(button, 'click', function () {
		selectColor(color);
		setSelectedShapeColor(color);
	});

	return button;
}

function buildColorPalette() {
	var colorPalette = document.getElementById('color-palette');
	for (var i = 0; i < colors.length; ++i) {
		var currColor = colors[i];
		var colorButton = makeColorButton(currColor);
		colorPalette.appendChild(colorButton);
		colorButtons[currColor] = colorButton;
	}
	selectColor(colors[0]);
}

function getSelectedShape(coords) {
	var lt = []
	//console.log("coordsLatlngs ",coords);
	for (let value of Object.values(coords)) {
		//console.log("Val is ",value); // John, then 30
		lt.push(value)
	}
	//console.log("Combine ",lt);
	document.getElementById('coords').value = lt;
	//return lt;
}

function getPolygonCoords() {

	var len = geoFencingArea.getPath().getLength();
	console.log(len);
	var htmlStr = "";
	coordinates = [];
	for (var i = 0; i < len; i++) {
		// var latlngd = {"lat":parseFloat(each_lat_lng[0]),"lng":parseFloat(each_lat_lng[1])};
		// coordinates.push()
		var strlatlng = geoFencingArea.getPath().getAt(i).toUrlValue(5);
		var spliting = strlatlng.split(",");
		var latlngd = { "lat": parseFloat(spliting[0]), "lng": parseFloat(spliting[1]) };
		coordinates.push(latlngd);
		// htmlStr += "new google.maps.LatLng(" + geoFencingArea.getPath().getAt(i).toUrlValue(5) + "), ";
		//Use this one instead if you want to get rid of the wrap > new google.maps.LatLng(),
		//htmlStr += "" + geoFencingArea.getPath().getAt(i).toUrlValue(5);
	}
	// console.log("htmlStr", coordinates);

}
