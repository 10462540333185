/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import CountUp from 'react-countup';
import axios from 'axios';
var redirectURL = require('../redirectURL');

export default class ConsignmentCountersComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            records: [],
            active_consignments: 0,
            preferredDeliveries: 0,
            ongoingDeliveries: 0,
            offerPrefStatusCount: 0,
            prefLangStatusCount: 0,
            onTimeDeliveriesCount:0,
            delayedDeliveriesCount:0,
            forceClosedDeliveriesCount: 0,
            selectPrefDateStatusCount: 0,
            selectPrefTimeStatusCount: 0,
            confirmPrefStatusCount: 0,
            thankYouStatusCount: 0,
            invalidWhatsAppNoCount: 0,
            noAvailableSlotsCount: 0,
            messageNotTriggeredCount: 0,
            overspeeding: 0,
            night_driving: 0,
            transit_delay: 0,
            unloading_dealer: 0,
            left_dealer: 0,
            no_gps_data_available: 0,
            nogpslifetime: 0,
            is_critical: 0,
            is_priority: 0,
            deptcode: this.props.deptcode,
            triptype: this.props.triptype,
            active_trucks: 0,
            loading_delay: 0,
            unloading_delay: 0,
            enroute_stoppage: 0,
            probableAccident: 0,
            hidecol: true,
            probableAccidentconfirm: 0,
            cancelledCount: 0,
            pod_count: 0,
            trip_closed_by_pod_count: 0,
            returnConsignments: 0,
            counttype: "all",
            truckReportedDate: "",
            deviated_routes: [],
            withoutTruckGateOutTimeData: [],
            forceClosed : 0,
            areaStatusMap: {},
        }

    }
    componentDidMount() {
        if (localStorage.getItem('user_type') == "DISTRIBUTOR") {
            var consigneeCode = localStorage.getItem('consignee_code');
            var params = {
                consigneeCode: consigneeCode
            }
        } else {
            params = {}
        }
        redirectURL.post("/masters/areastatus", params).then((res) => {
            var areaStatusMap = res.data
            this.setState({
                areaStatusMap: areaStatusMap
            })
        })
    }
    componentWillReceiveProps = async (newprops) => {
        console.log("newProps", newprops)

        // if (newprops && typeof newprops.length !== 'undefined' && newprops.length > 0) {
            // console.log("newProps inside", newprops)
            var consigneeCode = localStorage.getItem('consignee_code');
            var userType = localStorage.getItem('user_type');
            if (newprops.deptcode != this.state.deptcode) {
                //console.log("Conters ",newprops.deptcode)
                if (newprops.triptype && newprops.triptype != 0) {
                    var layoutdata = {
                        token: localStorage.getItem('token'),
                        dept_code: newprops.deptcode,
                        trip_type: newprops.triptype
                    }
                }
                else {
                    var layoutdata = {
                        token: localStorage.getItem('token'),
                        dept_code: newprops.deptcode
                    }
                }
    
                /*Venkatesh Code*/
                var transitdelaysarr = [];
                var gpsdatanaarr = [];
                var overspeedtrucksarr = [];
                var nightdrivearr = [];
                var enroutearr = [];
                var loadingarr = [];
                var unloadingarr = [];
                var iscriticalarr = [];
                console.log("newprops.gridData ", newprops.gridData.length)
                if (newprops.gridData.length > 0) {
    
                    /*New Code for Counters*/
                    var counter = newprops.countersjson;
                    // console.log("counter: => ",counter)
                    let withoutTruckGateOutTimeData = 0
                    var overspdcnt = 0;
                    var nightdrvcnt = 0;
                    var transdly = 0;
                    var npgs = 0;
                    var nogpslifetime = 0;
                    var lodelay = 0;
                    var unlodelay = 0;
                    var enrote = 0;
                    var critical = 0;
                    var priority = 0;
                    var probableAccident = 0;
                    var probableAccidentConfirmCount = 0;
                    var cancelledCount = 0;
                    // var pod_count = counter.pod_received_data;
                    // var trip_closed_by_pod_count = counter.trip_closed_by_pod;
                    var preferredDeliveries = 0;
                    var offerPrefStatusCount = 0;
                    var prefLangStatusCount = 0;
                    var onTimeDeliveriesCount=0;
                    var delayedDeliveriesCount=0;
                    // var forceClosedDeliveriesCount=0;
                    var selectPrefDateStatusCount = 0;
                    var selectPrefTimeStatusCount = 0;
                    var confirmPrefStatusCount = 0;
                    var thankYouStatusCount = 0;
                    var invalidWhatsAppNoCount = 0;
                    var noAvailableSlotsCount = 0;
                    var messageNotTriggeredCount = 0;
                    var areaStatusMap = this.state.areaStatusMap;
                    console.log("counter", counter);
                    // console.log("counter.onTimeDeliveries",counter.onTimeDeliveries);
                    
                    // if (counter.cancelled_count != "Not Applicable") {
                    //     cancelledCount = counter.cancelled_count
                    // }
                    // if (counter.overspeeding.length > 0) {
                    //     var overspdcnt = counter.overspeeding[0].overspeeding_exception_count;
                    // }
                    // try {
                    //     if (counter.probableAccident.length > 0) {
                    //         var probableAccident = counter.probableAccident[0].probableAccidentCount;
                    //     }
                    // } catch (e) { }
                    try {
                        if (counter.preferredDeliveries.length > 0) {
                            if (this.props.path == "/prtconsignments") {
                                var preferredDeliveries = counter.preferredDeliveries.filter(e => areaStatusMap[`${e.customer_area_id}`] == "Active")
                            } 
    
                            if (userType == "NAGPURORGUSER") {
                                var preferredDeliveries = counter.preferredDeliveries.filter(e => ["NAGPUR", "Nagpur"].includes(e.customer_city));
                            } else if (userType == "NASIKORGUSER") {
                                var preferredDeliveries = counter.preferredDeliveries.filter(e => ["NASIK", "Nasik", "NASHIK", "Nashik"].includes(e.customer_city));
                            } else if (userType == "SOLAPURORGUSER") {
                                var preferredDeliveries = counter.preferredDeliveries.filter(e => ["Solapur"].includes(e.customer_city));
                            } else if (userType == "INDOREORGUSER") {
                                var preferredDeliveries = counter.preferredDeliveries.filter(e => ["Indore"].includes(e.customer_city));
                            } else if (userType == "AHMEDABADORGUSER") {
                                var preferredDeliveries = counter.preferredDeliveries.filter(e => ["Ahmedabad"].includes(e.customer_city));
                            } else if (userType == "DISTRIBUTOR") {
                                var preferredDeliveries = counter.preferredDeliveries.filter(e => e.consignee_code == consigneeCode);
                            } else {
                                var preferredDeliveries = counter.preferredDeliveries
                            }
    
                            // if (this.props.path == "/prtconsignments") {
                            //     if (userType == "NAGPURORGUSER") {
                            //         var preferredDeliveries = counter.preferredDeliveries[0].preferredDeliveries.filter(e => areaStatusMap[`${e.customer_area_id}`] == "Active" && ["NAGPUR", "Nagpur"].includes(e.customer_city));
                            //     } else if (userType == "NASIKORGUSER") {
                            //         var preferredDeliveries = counter.preferredDeliveries[0].preferredDeliveries.filter(e => areaStatusMap[`${e.customer_area_id}`] == "Active" && ["NASIK", "Nasik", "NASHIK", "Nashik"].includes(e.customer_city));
                            //     } else if (userType == "DISTRIBUTOR") {
                            //         var preferredDeliveries = counter.preferredDeliveries[0].preferredDeliveries.filter(e => areaStatusMap[`${e.customer_area_id}`] == "Active" && e.consignee_code == consigneeCode);
                            //     } else {
                            //         var preferredDeliveries = counter.preferredDeliveries[0].preferredDeliveries.filter(e => areaStatusMap[`${e.customer_area_id}`] == "Active");
                            //     }
                                  
                            // } else {
                            //     if (userType == "NAGPURORGUSER") {
                            //         var preferredDeliveries = counter.preferredDeliveries[0].preferredDeliveries.filter(e => ["NAGPUR", "Nagpur"].includes(e.customer_city));
                            //     } else if (userType == "NASIKORGUSER") {
                            //         var preferredDeliveries = counter.preferredDeliveries[0].preferredDeliveries.filter(e => ["NASIK", "Nasik", "NASHIK", "Nashik"].includes(e.customer_city));
                            //     } else if (userType == "DISTRIBUTOR") {
                            //         var preferredDeliveries = counter.preferredDeliveries[0].preferredDeliveries.filter(e => e.consignee_code == consigneeCode);
                            //     } else {
    
                            //     }
                            // }
                            console.log("preferredDeliveries", preferredDeliveries)
                            preferredDeliveries = preferredDeliveries.length;
                            console.log("preferredDeliveries len", preferredDeliveries)
                        }
                    } catch (e) { }
                    try {
                        if (counter.offerPrefStatusCount.length > 0) {
                            if (this.props.path == "/prtconsignments") {
                                var offerPrefStatusCount = counter.offerPrefStatusCount.filter(e => areaStatusMap[`${e.customer_area_id}`] == "Active")
                            }
    
                            if (userType == "NAGPURORGUSER") {
                                var offerPrefStatusCount = counter.offerPrefStatusCount.filter(e => ["NAGPUR", "Nagpur"].includes(e.customer_city));
                            } else if (userType == "NASIKORGUSER") {
                                var offerPrefStatusCount = counter.offerPrefStatusCount.filter(e => ["NASIK", "Nasik", "NASHIK", "Nashik"].includes(e.customer_city));
                            } else if (userType == "SOLAPURORGUSER") {
                                var offerPrefStatusCount = counter.offerPrefStatusCount.filter(e => ["Solapur"].includes(e.customer_city));
                            } else if (userType == "INDOREORGUSER") {
                                var offerPrefStatusCount = counter.offerPrefStatusCount.filter(e => ["Indore"].includes(e.customer_city));
                            } else if (userType == "AHMEDABADORGUSER") {
                                var offerPrefStatusCount = counter.offerPrefStatusCount.filter(e => ["Ahmedabad"].includes(e.customer_city));
                            } else if (userType == "DISTRIBUTOR") {
                                var offerPrefStatusCount = counter.offerPrefStatusCount.filter(e => e.consignee_code == consigneeCode);
                            } else {
                                var offerPrefStatusCount = counter.offerPrefStatusCount
                            }
                            
                            offerPrefStatusCount = offerPrefStatusCount.length;
                        }
                    } catch (e) { }
                    try {
                        if (counter.prefLangStatusCount.length > 0) {
                            if (this.props.path == "/prtconsignments") {
                                var prefLangStatusCount = counter.prefLangStatusCount.filter(e => areaStatusMap[`${e.customer_area_id}`] == "Active");
                            }
                            if (userType == "NAGPURORGUSER") {
                                var prefLangStatusCount = counter.prefLangStatusCount.filter(e => ["NAGPUR", "Nagpur"].includes(e.customer_city))
                            } else if (userType == "NASIKORGUSER") {
                                var prefLangStatusCount = counter.prefLangStatusCount.filter(e => ["NASIK", "Nasik", "NASHIK", "Nashik"].includes(e.customer_city))
                            }  else if (userType == "SOLAPURORGUSER") {
                                var prefLangStatusCount = counter.prefLangStatusCount.filter(e => ["Solapur"].includes(e.customer_city))
                            }  else if (userType == "INDOREORGUSER") {
                                var prefLangStatusCount = counter.prefLangStatusCount.filter(e => ["Indore"].includes(e.customer_city))
                            } else if (userType == "AHMEDABADORGUSER") {
                                var prefLangStatusCount = counter.prefLangStatusCount.filter(e => ["Ahmedabad"].includes(e.customer_city))
                            } else if (userType == "DISTRIBUTOR") {
                                var prefLangStatusCount = counter.prefLangStatusCount.filter(e => e.consignee_code == consigneeCode)
                            } else {
                                var prefLangStatusCount = counter.prefLangStatusCount
                            }
                            prefLangStatusCount = prefLangStatusCount.length;
                        }
                    } catch (e) { }
                    
                    try {
                        if (newprops.onTimeDeliveries.length > 0) {
                            if (this.props.path == "/prtconsignments") {
                                var onTimeDeliveriesCount = newprops.onTimeDeliveries.filter(e => areaStatusMap[`${e.customer_area_id}`] == "Active");
                            } 
                            if (userType == "NAGPURORGUSER") {
                                var onTimeDeliveriesCount = newprops.onTimeDeliveries.filter(e => ["NAGPUR", "Nagpur"].includes(e.customer_city));
                            } else if (userType == "NASIKORGUSER") {
                                var onTimeDeliveriesCount = newprops.onTimeDeliveries.filter(e => ["NASIK", "Nasik", "NASHIK", "Nashik"].includes(e.customer_city));
                            } else if (userType == "SOLAPURORGUSER") {
                                var onTimeDeliveriesCount = newprops.onTimeDeliveries.filter(e => ["Solapur"].includes(e.customer_city));
                            } else if (userType == "INDOREORGUSER") {
                                var onTimeDeliveriesCount = newprops.onTimeDeliveries.filter(e => ["Indore"].includes(e.customer_city));
                            } else if (userType == "AHMEDABADORGUSER") {
                                var onTimeDeliveriesCount = newprops.onTimeDeliveries.filter(e => ["Ahmedabad"].includes(e.customer_city));
                            } else if (userType == "DISTRIBUTOR") {
                                var onTimeDeliveriesCount = newprops.onTimeDeliveries.filter(e => e.consignee_code === consigneeCode);
                            } else {
                                var onTimeDeliveriesCount = newprops.onTimeDeliveries
                            }
                            
                            onTimeDeliveriesCount = onTimeDeliveriesCount.length;
                        }
                    } catch (e) { }
                    try {
                        if (newprops.delayedDeliveries.length > 0) {
                            if (this.props.path == "/prtconsignments") {
                                var delayedDeliveriesCount = newprops.delayedDeliveries.filter(e => areaStatusMap[`${e.customer_area_id}`] == "Active");
                            } 
                            if (userType == "NAGPURORGUSER") {
                                var delayedDeliveriesCount = newprops.delayedDeliveries.filter(e => ["NAGPUR", "Nagpur"].includes(e.customer_city));
                            } else if (userType == "NASIKORGUSER") {
                                var delayedDeliveriesCount = newprops.delayedDeliveries.filter(e => ["NASIK", "Nasik", "NASHIK", "Nashik"].includes(e.customer_city));
                            } else if (userType == "SOLAPURORGUSER") {
                                var delayedDeliveriesCount = newprops.delayedDeliveries.filter(e => ["Solapur"].includes(e.customer_city));
                            } else if (userType == "INDOREORGUSER") {
                                var delayedDeliveriesCount = newprops.delayedDeliveries.filter(e => ["Indore"].includes(e.customer_city));
                            } else if (userType == "AHMEDABADORGUSER") {
                                var delayedDeliveriesCount = newprops.delayedDeliveries.filter(e => ["Ahmedabad"].includes(e.customer_city));
                            } else if (userType == "DISTRIBUTOR") {
                                var delayedDeliveriesCount = newprops.delayedDeliveries.filter(e => e.consignee_code === consigneeCode);  
                            } else {
                                var delayedDeliveriesCount = newprops.delayedDeliveries
                            }                                        
                            delayedDeliveriesCount = delayedDeliveriesCount.length;
                        }
                    } catch (e) { }
                    try {
                        if (counter.selectPrefDateStatusCount.length > 0) {
                            if (this.props.path == "/prtconsignments") {
                                var selectPrefDateStatusCount = counter.selectPrefDateStatusCount.filter(e => areaStatusMap[`${e.customer_area_id}`] == "Active");
                            }
                            if (userType == "NAGPURORGUSER") {
                                var selectPrefDateStatusCount = counter.selectPrefDateStatusCount.filter(e => ["NAGPUR", "Nagpur"].includes(e.customer_city));
                            } else if (userType == "NASIKORGUSER") {
                                var selectPrefDateStatusCount = counter.selectPrefDateStatusCount.filter(e => ["NASIK", "Nasik", "NASHIK", "Nashik"].includes(e.customer_city));
                            } else if (userType == "SOLAPURORGUSER") {
                                var selectPrefDateStatusCount = counter.selectPrefDateStatusCount.filter(e => ["Solapur"].includes(e.customer_city));
                            } else if (userType == "INDOREORGUSER") {
                                var selectPrefDateStatusCount = counter.selectPrefDateStatusCount.filter(e => ["Indore"].includes(e.customer_city));
                            } else if (userType == "AHMEDABADORGUSER") {
                                var selectPrefDateStatusCount = counter.selectPrefDateStatusCount.filter(e => ["Ahmedabad"].includes(e.customer_city));
                            } else if (userType == "DISTRIBUTOR") {
                                var selectPrefDateStatusCount = counter.selectPrefDateStatusCount.filter(e => e.consignee_code === consigneeCode);
                            } else {
                                var selectPrefDateStatusCount = counter.selectPrefDateStatusCount
                            }
    
                            selectPrefDateStatusCount = selectPrefDateStatusCount.length;
                        }
                    } catch (e) { }
                    try {
                        if (counter.selectPrefTimeStatusCount.length > 0) {
                            if (this.props.path == "/prtconsignments") {
                                var selectPrefTimeStatusCount = counter.selectPrefTimeStatusCount.filter(e => areaStatusMap[`${e.customer_area_id}`] == "Active");
                            }
                            if (userType == "NAGPURORGUSER") {
                                var selectPrefTimeStatusCount = counter.selectPrefTimeStatusCount.filter(e => ["NAGPUR", "Nagpur"].includes(e.customer_city))
                            } else if (userType == "NASIKORGUSER") {
                                var selectPrefTimeStatusCount = counter.selectPrefTimeStatusCount.filter(e => ["NASIK", "Nasik", "NASHIK", "Nashik"].includes(e.customer_city))
                            } else if (userType == "SOLAPURORGUSER") {
                                var selectPrefTimeStatusCount = counter.selectPrefTimeStatusCount.filter(e => ["Solapur"].includes(e.customer_city))
                            } else if (userType == "INDOREORGUSER") {
                                var selectPrefTimeStatusCount = counter.selectPrefTimeStatusCount.filter(e => ["Indore"].includes(e.customer_city))
                            } else if (userType == "AHMEDABADORGUSER") {
                                var selectPrefTimeStatusCount = counter.selectPrefTimeStatusCount.filter(e => ["Ahmedabad"].includes(e.customer_city))
                            } else if (userType == "DISTRIBUTOR") {
                                var selectPrefTimeStatusCount = counter.selectPrefTimeStatusCount.filter(e => e.consignee_code === consigneeCode);
                            } else {
                                var selectPrefTimeStatusCount = counter.selectPrefTimeStatusCount
                            }
                            
                            selectPrefTimeStatusCount = selectPrefTimeStatusCount.length;
                        }
                    } catch (e) { }
                    try {
                        if (counter.confirmPrefStatusCount.length > 0) {
                            if (this.props.path == "/prtconsignments") {
                                var confirmPrefStatusCount = counter.confirmPrefStatusCount.filter(e => areaStatusMap[`${e.customer_area_id}`] == "Active");
                            }
                            if (userType == "NAGPURORGUSER") {
                                var confirmPrefStatusCount = counter.confirmPrefStatusCount.filter(e => ["NAGPUR", "Nagpur"].includes(e.customer_city));
                            } else if (userType == "NASIKORGUSER") {
                                var confirmPrefStatusCount = counter.confirmPrefStatusCount.filter(e => ["NASIK", "Nasik", "NASHIK", "Nashik"].includes(e.customer_city));
                            } else if (userType == "SOLAPURORGUSER") {
                                var confirmPrefStatusCount = counter.confirmPrefStatusCount.filter(e => ["Solapur"].includes(e.customer_city));
                            } else if (userType == "INDOREORGUSER") {
                                var confirmPrefStatusCount = counter.confirmPrefStatusCount.filter(e => ["Indore"].includes(e.customer_city));
                            } else if (userType == "AHMEDABADORGUSER") {
                                var confirmPrefStatusCount = counter.confirmPrefStatusCount.filter(e => ["Ahmedabad"].includes(e.customer_city));
                            } else if (userType == "DISTRIBUTOR") {
                                var confirmPrefStatusCount = counter.confirmPrefStatusCount.filter(e => e.consignee_code == consigneeCode);
                            } else {
                                var confirmPrefStatusCount = counter.confirmPrefStatusCount
                            }
                            
                            confirmPrefStatusCount = confirmPrefStatusCount.length;
                        }
                    } catch (e) { }
                    try {
                        if (counter.thankYouStatusCount.length > 0) {
                            if (this.props.path == "/prtconsignments") {
                                var thankYouStatusCount = counter.thankYouStatusCount.filter(e => areaStatusMap[`${e.customer_area_id}`] == "Active");
                            }
                            if (userType == "NAGPURORGUSER") {
                                var thankYouStatusCount = counter.thankYouStatusCount.filter(e => ["NAGPUR", "Nagpur"].includes(e.customer_city));    
                            } else if (userType == "NASIKORGUSER") {
                                var thankYouStatusCount = counter.thankYouStatusCount.filter(e => ["NASIK", "Nasik", "NASHIK", "Nashik"].includes(e.customer_city));
                            } else if (userType == "SOLAPURORGUSER") {
                                var thankYouStatusCount = counter.thankYouStatusCount.filter(e => ["Solapur"].includes(e.customer_city));
                            } else if (userType == "INDOREORGUSER") {
                                var thankYouStatusCount = counter.thankYouStatusCount.filter(e => ["Indore"].includes(e.customer_city));
                            } else if (userType == "AHMEDABADORGUSER") {
                                var thankYouStatusCount = counter.thankYouStatusCount.filter(e => ["Ahmedabad"].includes(e.customer_city));
                            } else if (userType == "DISTRIBUTOR") {
                                var thankYouStatusCount = counter.thankYouStatusCount.filter(e => e.consignee_code == consigneeCode);
                            } else {
                                var thankYouStatusCount = counter.thankYouStatusCount
                            }
                            
                            thankYouStatusCount = thankYouStatusCount.length;
                        }
                    } catch (e) { }
                    try {
                        if (counter.invalidWhatsAppNoCount.length > 0) {
                            if (this.props.path == "/prtconsignments") {
                                var invalidWhatsAppNoCount = counter.invalidWhatsAppNoCount.filter(e => areaStatusMap[`${e.customer_area_id}`] == "Active");
                            }
    
                            if (userType == "NAGPURORGUSER") {
                                var invalidWhatsAppNoCount = counter.invalidWhatsAppNoCount.filter(e => ["NAGPUR", "Nagpur"].includes(e.customer_city));
                            } else if (userType == "NASIKORGUSER") {
                                var invalidWhatsAppNoCount = counter.invalidWhatsAppNoCount.filter(e => ["NASIK", "Nasik", "NASHIK", "Nashik"].includes(e.customer_city));
                            } else if (userType == "SOLAPURORGUSER") {
                                var invalidWhatsAppNoCount = counter.invalidWhatsAppNoCount.filter(e => ["Solapur"].includes(e.customer_city));
                            } else if (userType == "INDOREORGUSER") {
                                var invalidWhatsAppNoCount = counter.invalidWhatsAppNoCount.filter(e => ["Indore"].includes(e.customer_city));
                            } else if (userType == "AHMEDABADORGUSER") {
                                var invalidWhatsAppNoCount = counter.invalidWhatsAppNoCount.filter(e => ["Ahmedabad"].includes(e.customer_city));
                            } else if (userType == "DISTRIBUTOR") {
                                var invalidWhatsAppNoCount = counter.invalidWhatsAppNoCount.filter(e => e.consignee_code == consigneeCode);
                            } else {
                                var invalidWhatsAppNoCount = counter.invalidWhatsAppNoCount
                            }
    
                            invalidWhatsAppNoCount = invalidWhatsAppNoCount.length;
                            console.log("invalidWhatsApp", invalidWhatsAppNoCount)
                        }
                    } catch (e) { }
                    try {
                        if (counter.noAvailableSlotsCount.length > 0) {
                            if (this.props.path == "/prtconsignments") {
                                var noAvailableSlotsCount = counter.noAvailableSlotsCount.filter(e => areaStatusMap[`${e.customer_area_id}`] == "Active");
                            }                        
    
                            if (userType == "NAGPURORGUSER") {
                                var noAvailableSlotsCount = counter.noAvailableSlotsCount.filter(e => ["NAGPUR", "Nagpur"].includes(e.customer_city));
                            } else if (userType == "NASIKORGUSER") {
                                var noAvailableSlotsCount = counter.noAvailableSlotsCount.filter(e => ["NASIK", "Nasik", "NASHIK", "Nashik"].includes(e.customer_city));
                            } else if (userType == "SOLAPURORGUSER") {
                                var noAvailableSlotsCount = counter.noAvailableSlotsCount.filter(e => ["Solapur"].includes(e.customer_city));
                            } else if (userType == "INDOREORGUSER") {
                                var noAvailableSlotsCount = counter.noAvailableSlotsCount.filter(e => ["Indore"].includes(e.customer_city));
                            } else if (userType == "AHMEDABADORGUSER") {
                                var noAvailableSlotsCount = counter.noAvailableSlotsCount.filter(e => ["Ahmedabad"].includes(e.customer_city));
                            } else if (userType == "DISTRIBUTOR") {
                                var noAvailableSlotsCount = counter.noAvailableSlotsCount.filter(e => e.consignee_code == consigneeCode);
                            } else {
                                var noAvailableSlotsCount = counter.noAvailableSlotsCount
                            }
    
                            noAvailableSlotsCount = noAvailableSlotsCount.length;
                            console.log("noAvailableSlotsCount", noAvailableSlotsCount)
                        }
                    } catch (e) { }
                    try {
                        if (counter.messageNotTriggeredCount.length > 0) {
                            if (this.props.path == "/prtconsignments") {
                                var messageNotTriggeredCount = counter.messageNotTriggeredCount.filter(e => areaStatusMap[`${e.customer_area_id}`] == "Active");
                            }                        
    
                            if (userType == "NAGPURORGUSER") {
                                var messageNotTriggeredCount = counter.messageNotTriggeredCount.filter(e => ["NAGPUR", "Nagpur"].includes(e.customer_city));
                            } else if (userType == "NASIKORGUSER") {
                                var messageNotTriggeredCount = counter.messageNotTriggeredCount.filter(e => ["NASIK", "Nasik", "NASHIK", "Nashik"].includes(e.customer_city));
                            } else if (userType == "SOLAPURORGUSER") {
                                var messageNotTriggeredCount = counter.messageNotTriggeredCount.filter(e => ["Solapur"].includes(e.customer_city));
                            } else if (userType == "INDOREORGUSER") {
                                var messageNotTriggeredCount = counter.messageNotTriggeredCount.filter(e => ["Indore"].includes(e.customer_city));
                            } else if (userType == "AHMEDABADORGUSER") {
                                var messageNotTriggeredCount = counter.messageNotTriggeredCount.filter(e => ["Ahmedabad"].includes(e.customer_city));
                            } else if (userType == "DISTRIBUTOR") {
                                var messageNotTriggeredCount = counter.messageNotTriggeredCount.filter(e => e.consignee_code == consigneeCode);
                            } else {
                                var messageNotTriggeredCount = counter.messageNotTriggeredCount
                            }
    
                            messageNotTriggeredCount = messageNotTriggeredCount.length;
                            console.log("messageNotTriggeredCount", messageNotTriggeredCount)
                        }
                    } catch (e) { }
                    // try {
                    //     if (counter.probableAccidentConfirm.length > 0) {
                    //         var probableAccidentConfirmCount = counter.probableAccidentConfirm[0].probableAccidentConfirmCount;
                    //     }
                    // } catch (e) { }
                    // try {
                    //     if (counter.criticalCount.length > 0) {
                    //         critical = counter.criticalCount[0].criticalCount;
                    //     }
                    // }
                    // catch (e) { }
                    // try {
                    //     if (counter.priorityCount.length > 0) {
                    //         priority = counter.priorityCount[0].priorityCount;
                    //     }
                    // }
                    // catch (e) { }
                    // if (counter.night_drive.length > 0) {
                    //     var nightdrvcnt = counter.night_drive[0].nightdriving_exception_count;
                    // }
                    // try{
                    //     if (counter.forceClosed.length > 0) {
                    //         var forceClosed = counter.forceClosed[0].forceClosedCount;
                    //     }
                    // }catch (e) { }
                    // if (counter.transit_delay.length > 0) {
                    //     var transdly = counter.transit_delay[0].transit_delay_count;
                    // }
    
                    // if (counter.no_gps.length > 0) {
                    //     var npgs = counter.no_gps[0].no_gps_data_count;
                    // }
                    //console.log("newprops.hide ",newprops.hide)
                    // if(newprops.hide == true)
                    // {
                    // try {
                    //     if (counter.nogpslifetime.length > 0) {
                    //         var nogpslifetime = counter.nogpslifetime[0].no_gps_data_lifetime_count;
                    //     }
                    // } catch (e) { }
                    // // }
                    // if (counter.loading_delay.length > 0) {
                    //     var lodelay = counter.loading_delay[0].loading_delay_exception_count;
                    // }
    
                    // if (counter.unloading_delay.length > 0) {
                    //     var unlodelay = counter.unloading_delay[0].unloading_delay_exception_count;
                    // }
    
                    // if (counter.enroute_stoppage.length > 0) {
                    //     var enrote = counter.enroute_stoppage[0].enroute_exception_count;
                    // }
                    try {
                        if (newprops.forceClosedDeliveriesData && newprops.forceClosedDeliveriesData.length > 0) {
                            // if (this.props.path == "/prtdeliveredconsignments") {
                            //     var forceClosedDeliveriesCount = newprops.forceClosedDeliveriesCount.length
                            // }

                            var forceClosedDeliveries = []
                            var forceClosedDeliveriesCount = 0;
                            if (this.props.path == "/prtdeliveredconsignments" && userType == "NAGPURORGUSER") {
                                forceClosedDeliveries = newprops.forceClosedDeliveriesData.filter(e => ["NAGPUR", "Nagpur"].includes(e.customer_city));
                                forceClosedDeliveriesCount = forceClosedDeliveries.length
                            } else if (this.props.path == "/prtdeliveredconsignments" &&  userType == "NASIKORGUSER") {
                                forceClosedDeliveries = newprops.forceClosedDeliveriesData.filter(e => ["NASIK", "Nasik", "NASHIK", "Nashik"].includes(e.customer_city));
                                forceClosedDeliveriesCount = forceClosedDeliveries.length
                            } else if (this.props.path == "/prtdeliveredconsignments" &&  userType == "SOLAPURORGUSER") {
                                forceClosedDeliveries = newprops.forceClosedDeliveriesData.filter(e => ["Solapur"].includes(e.customer_city));
                                forceClosedDeliveriesCount = forceClosedDeliveries.length
                            } else if (this.props.path == "/prtdeliveredconsignments" &&  userType == "INDOREORGUSER") {
                                forceClosedDeliveries = newprops.forceClosedDeliveriesData.filter(e => ["Indore"].includes(e.customer_city));
                                forceClosedDeliveriesCount = forceClosedDeliveries.length
                            } else if (this.props.path == "/prtdeliveredconsignments" &&  userType == "AHMEDABADORGUSER") {
                                forceClosedDeliveries = newprops.forceClosedDeliveriesData.filter(e => ["Ahmedabad"].includes(e.customer_city));
                                forceClosedDeliveriesCount = forceClosedDeliveries.length
                            } else if (this.props.path == "/prtdeliveredconsignments" &&  userType == "DISTRIBUTOR") {
                                forceClosedDeliveries = newprops.forceClosedDeliveriesData.filter(e => e.consignee_code == consigneeCode);
                                forceClosedDeliveriesCount = forceClosedDeliveries.length
                            } else {
                                var forceClosedDeliveries = newprops.forceClosedDeliveriesData
                                forceClosedDeliveriesCount = forceClosedDeliveries.length;
                            }

                            this.setState({
                                forceClosedDeliveriesCount: forceClosedDeliveriesCount
                            })
    
                            // console.log("forceClosedDeliveriesCount", forceClosedDeliveriesCount)
                        }
                    } catch (e) { }

                    let grid_data = []
                    if(this.props.path == "/prtdeliveredconsignments"){
                        grid_data = newprops.gridData.filter(item  => item.status !==6 )
                    }else{
                        grid_data = newprops.gridData
                    }
                    var ongoingDeliveries = selectPrefDateStatusCount + selectPrefTimeStatusCount + confirmPrefStatusCount;
                    //console.log("nogpslifetime ",nogpslifetime)
                    await this.setState({
                        active_consignments: grid_data.length,
                        preferredDeliveries: preferredDeliveries,
                        ongoingDeliveries: ongoingDeliveries,
                        offerPrefStatusCount: offerPrefStatusCount,
                        prefLangStatusCount: prefLangStatusCount,
                        onTimeDeliveriesCount: onTimeDeliveriesCount,
                        delayedDeliveriesCount: delayedDeliveriesCount,
                        selectPrefDateStatusCount: selectPrefDateStatusCount,
                        selectPrefTimeStatusCount: selectPrefTimeStatusCount,
                        confirmPrefStatusCount: confirmPrefStatusCount,
                        thankYouStatusCount: thankYouStatusCount,
                        invalidWhatsAppNoCount: invalidWhatsAppNoCount,
                        noAvailableSlotsCount: noAvailableSlotsCount,
                        messageNotTriggeredCount: messageNotTriggeredCount,
                        // overspeeding: overspdcnt,
                        // is_critical: critical,
                        // is_priority: priority,
                        // night_driving: nightdrvcnt,
                        // transit_delay: transdly,
                        // no_gps_data_available: npgs,
                        // nogpslifetime: nogpslifetime,
                        // loading_delay: lodelay,
                        // unloading_delay: unlodelay,
                        // enroute_stoppage: enrote,
                        // probableAccident: probableAccident,
                        // probableAccidentconfirm: probableAccidentConfirmCount,
                        // cancelledCount: cancelledCount,
                        // pod_count: pod_count,
                        // trip_closed_by_pod_count: trip_closed_by_pod_count,
                        returnConsignments: newprops.returnConsignments,
                        // counttype: newprops.counttype,
                        // deviated_routes: counter.deviated_routes,
                        // withoutTruckGateOutTimeData: newprops.withoutTruckGateOutTimeData.length,
                        // forceClosed
                    });
                }
                else {
                    await this.setState({
                        active_consignments: 0,
                        preferredDeliveries: 0,
                        ongoingDeliveries: 0,
                        offerPrefStatusCount: 0,
                        prefLangStatusCount: 0,
                        onTimeDeliveriesCount: 0,
                        delayedDeliveriesCount: 0,
                        selectPrefDateStatusCount: 0,
                        selectPrefTimeStatusCount: 0,
                        confirmPrefStatusCount: 0,
                        thankYouStatusCount: 0,
                        invalidWhatsAppNoCount: 0,
                        noAvailableSlotsCount: 0,
                        messageNotTriggeredCount: 0,
                        overspeeding: 0,
                        night_driving: 0,
                        transit_delay: 0,
                        is_critical: 0,
                        is_priority: 0,
                        //unloading_dealer:counterdata.unloading_dealer,
                        //left_dealer:counterdata.left_dealer,
                        no_gps_data_available: 0,
                        nogpslifetime: 0,
                        //active_trucks:counterdata.active_trucks,
                        loading_delay: 0,
                        unloading_delay: 0,
                        enroute_stoppage: 0,
                        probableAccident: 0,
                        probableAccidentconfirm: 0,
                        returnConsignments: newprops.returnConsignments,
                        deviated_routes: [],
                    });
                }
                this.setState({
                    hidecol: newprops.hide,
                    truckReportedDate: this.props.truckReportedDate
                });
            }
        
    }

    onClickCounter(data) {
        this.props.context.onClickCounterShowData(data)
    }
    render() {
        var activeshow = (this.state.counttype == 'all' || this.state.counttype == 'active') ? 'show-m' : 'show-n'
        // console.log("ths.state", this.state.deptcode, '\n this.prop', this.props)

        return (
            <div className="row">

                <div className="col-xl-12 col-lg-12 col-sm-12 beffect">
                    <div className="card">
                        <div className="card-body" id="counterCardBody">
                            <div className="crm-numbers pb-0">
                                {(this.state.returnConsignments == false) ?
                                    <div className="col">
                                        <div className='row'>
                                            <div className="col cirlce-d cursorPointer" style={(this.props.path == "/prtconsignments") ? {marginTop: "45px"} : {}} onClick={this.onClickCounter.bind(this, "all")}>
                                                <span className="f13"><i className="icofont icofont-ui-browser f24 greenfont"></i><br /> Orders</span>
                                                <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.active_consignments != '') ? this.state.active_consignments : 0} /></span></h4>

                                            </div>
                                            {/* <div className="col">
                                            <span className="f13"><i className="icofont icofont-truck f24 greenfont"></i><br /> Active Trucks</span>
                                            <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.active_trucks != '')?this.state.active_trucks:0}/></span></h4>

                                        </div> */}
                                            {(this.props.path == "/prtconsignments") ?
                                            <div className='col'>
                                                <div className='mb-3'>
                                                    <h4 className='header' style={{"fontSize": "18px", "fontWeight": 600, "fontFamily": "inherit"}}><span>Preferred Deliveries</span></h4>
                                                </div>
                                                <div className='row'>
                                                    <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "ongoingDeliveries")}>
                                                        <span className="f13"><i className="icofont icofont-refresh f24 maroon"></i><br />Ongoing</span>
                                                        <h4 className="maroon f40"><span className="counter"><CountUp end={(this.state.ongoingDeliveries != '') ? this.state.ongoingDeliveries : 0} /></span></h4>
                                                    </div>
                                                    <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "preferredDeliveries")}>
                                                        <span className="f13"><i className="icofont icofont-truck f24 greenfont"></i><br />Confirmed</span>
                                                        <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.preferredDeliveries != '') ? this.state.preferredDeliveries : 0} /></span></h4>
                                                    </div>
                                                </div>
                                            </div> 
                                            : ""}
                                            {(this.props.path == "/prtdeliveredconsignments") ?
                                            <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "preferredDeliveries")}>
                                                <span className="f13"><i className="icofont icofont-truck f24 greenfont"></i><br />Preferred Deliveries</span>
                                                <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.preferredDeliveries != '') ? this.state.preferredDeliveries : 0} /></span></h4>

                                            </div>
                                            : ""}
                                            {(this.props.path == "/prtdeliveredconsignments") ?
                                            <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "onTimeDeliveriesCount")}>
                                                <span className="f13"><i className="fa fa-ban f22 maroon"></i><br /> On Time</span>
                                                <h4 className="maroon f40"><span className="counter"><CountUp end={(this.state.onTimeDeliveriesCount != '') ? this.state.onTimeDeliveriesCount : 0} /></span></h4>
                                            </div>
                                            : ""}
                                            {(this.props.path == "/prtdeliveredconsignments") ?
                                            <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "delayedDeliveriesCount")}>
                                                <span className="f13"><i className="icofont icofont-clock-time f22 redfont"></i><br />Delays</span>
                                                <h4 className="txt-danger f40"><span className="counter"><CountUp end={this.state.delayedDeliveriesCount ? this.state.delayedDeliveriesCount : 0} /></span></h4>
                                            </div>
                                            : ""}
                                            {(this.props.path == "/prtdeliveredconsignments") ?
                                            <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "forceClosedDeliveriesCount")}>
                                                <span className="f13"><i className="icofont icofont-close-squared f22 warning-ico"></i><br />Force Closed</span>
                                                {/* <h4 className="txt-danger f40"><span className="counter"><CountUp end={0} /></span></h4> */}
                                                <h4 className="txt-google-plus f40"><span className="counter"><CountUp end={this.state.forceClosedDeliveriesCount ? this.state.forceClosedDeliveriesCount : 0} /></span></h4>
                                            </div>
                                            : ""}
                                        </div>
                                        {(this.props.path == "/prtconsignments") ?
                                            <>
                                            <div className='mt-3 mb-3'>
                                                <hr />
                                                <h4 className='header' style={{"fontSize": "18px", "fontWeight": 600, "fontFamily": "inherit"}}><span>Customer Engagement</span></h4>
                                                
                                            </div>
                                            
                                            <div className='row'>
                                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "messageNotTriggeredCount")}>
                                                    <span className="f13"><i className="icofont icofont-ui-message f24 redfont"></i><br /> Message Not Sent</span>
                                                    <h4 className="txt-danger f40"><span className="counter"><CountUp end={(this.state.messageNotTriggeredCount != '') ? this.state.messageNotTriggeredCount : 0} /></span></h4>
                                                </div>
                                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "invalidWhatsAppNoCount")}>
                                                    <span className="f13"><i className="icofont icofont-brand-whatsapp f24" style={{color: "green"}}></i><br /> Invalid WhatsApp No</span>
                                                    <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.invalidWhatsAppNoCount != '') ? this.state.invalidWhatsAppNoCount : 0} /></span></h4>
                                                </div>
                                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "prefLangStatusCount")}>
                                                    <span className="f13"><i className="icofont icofont-envelope-open f24 maroon" style={{color:"brown"}}></i><br /> Yet to Respond</span>
                                                    <h4 className="maroon f40"><span className="counter"><CountUp end={(this.state.prefLangStatusCount != '') ? this.state.prefLangStatusCount : 0} /></span></h4>

                                                </div>
                                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "offerPrefStatusCount")}>
                                                    <span className="f13"><i className="icofont icofont-fast-delivery f24 txt-secondary"></i><br />PTD Service Offered</span>
                                                    <h4 className="txt-secondary f40"><span className="counter"><CountUp end={(this.state.offerPrefStatusCount != '') ? this.state.offerPrefStatusCount : 0} /></span></h4>

                                                </div>
                                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "noAvailableSlotsCount")}>
                                                    <span className="f13"><i className="icofont icofont-ban f24 darkorange"></i><br /> Slots Unavailable</span>
                                                    <h4 className="darkorange f40"><span className="counter"><CountUp end={(this.state.noAvailableSlotsCount != '') ? this.state.noAvailableSlotsCount : 0} /></span></h4>
                                                </div>
                                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "selectPrefDateStatusCount")}>
                                                    <span className="f13"><i className="icofont icofont-ui-calendar f24 txt-primary"></i><br /> Date Slot Offered</span>
                                                    <h4 className="txt-primary f40"><span className="counter"><CountUp end={(this.state.selectPrefDateStatusCount != '') ? this.state.selectPrefDateStatusCount : 0} /></span></h4>

                                                </div>

                                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "selectPrefTimeStatusCount")}>
                                                    <span className="f13"><i className="icofont icofont-clock-time f24 darkorange"></i><br /> Time Slot Offered</span>
                                                    <h4 className="darkorange f40"><span className="counter"><CountUp end={(this.state.selectPrefTimeStatusCount != '') ? this.state.selectPrefTimeStatusCount : 0} /></span></h4>

                                                </div>

                                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "confirmPrefStatusCount")}>
                                                    <span className="f13"><i className="icofont icofont-clock-time f24 redfont"></i><br /> Time Slot Confirmed</span>
                                                    <h4 className="txt-danger f40"><span className="counter"><CountUp end={(this.state.confirmPrefStatusCount != '') ? this.state.confirmPrefStatusCount : 0} /></span></h4>

                                                </div>
                                                <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "preferredDeliveries")}>
                                                    <span className="f13"><i className="icofont icofont-money-bag f24 greenfont"></i><br />Price Confirmed</span>
                                                    <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.thankYouStatusCount != '') ? this.state.thankYouStatusCount : 0} /></span></h4>

                                                </div>
                                                
                                            </div>
                                            </>
                                        : ""}

                                        {/*
                                        <div className="col cursorPointer">
                                            <span className="f13"><i className="icofont icofont-social-google-map f24 txt-danger"></i><br /> GPS Data Not Available</span>
                                            <h4 className="txt-secondary f40">

                                            <span className="counter"  onClick={this.onClickCounter.bind(this,"no_gps_data")}>

                                                <CountUp className="txt-danger" end={(this.state.no_gps_data_available != '')?this.state.no_gps_data_available:0}/>
                                            </span>
                                            <span className="f24">&nbsp;/&nbsp;</span>
                                            <span className="counter" onClick={this.onClickCounter.bind(this,"no_gps_data_lifetime")}>
                                                <CountUp  className="txt-secondary" end={(this.state.nogpslifetime != '')?this.state.nogpslifetime:0}/>
                                            </span>

                                        </h4>

                                    </div>
                                    */}


                                        {/* <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"no_gps_data")}>
                                        <span className="f13"><i className="icofont icofont-social-google-map f24 txt-secondary"></i><br /> GPS Data NA</span>
                                        <h4 className="txt-secondary f40"><span className="counter"><CountUp end={(this.state.no_gps_data_available != '')?this.state.no_gps_data_available:0}/></span></h4>

                                    </div> */}

                                        {/* <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "overspeeding_exception")}>
                                            <span className="f13"><i className="fa fa-tachometer f22 txt-success"></i><br /> Overspeeding</span>
                                            <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.overspeeding != '') ? this.state.overspeeding : 0} /></span></h4>

                                        </div> */}
                                        {/* {(this.props.deptcode == 'LOG-PRT') ? "" :
                                            <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "nightdriving_exception")}>
                                                <span className="f13"><i className="icofont icofont-full-night f24 txt-primary"></i><br /> Night Driving</span>
                                                <h4 className="txt-primary f40"><span className="counter"><CountUp end={(this.state.night_driving != '') ? this.state.night_driving : 0} /></span></h4>

                                            </div>
                                        } */}
                                        {/* {(this.props.deptcode == 'LOG-PRT') ? "" :
                                            <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "loading_delay_exception")} >
                                                <span className="f13"><i className="icofont icofont-truck-loaded f24 darkorange"></i><br /> Loading Delay</span>
                                                <h4 className="darkorange f40"><span className="counter"><CountUp end={(this.state.loading_delay != '') ? this.state.loading_delay : 0} /></span></h4>
                                            </div>
                                        } */}
                                        {/* {(this.props.deptcode == 'LOG-PRT' || (this.props.unloadshow == true)) ? "" :
                                            <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "unloading_delay_exception")} >
                                                <span className="f13"><i className="icofont icofont-vehicle-delivery-van f24 mildgreen"></i><br /> Unloading Delay</span>
                                                <h4 className="mildgreen f40"><span className="counter"><CountUp end={(this.state.unloading_delay != '') ? this.state.unloading_delay : 0} /></span></h4>
                                            </div>
                                        } */}
                                        {/* <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "enroute_exception")}>
                                            <span className="f13"><i className="icofont icofont-pause f22 maroon"></i><br /> Stoppages</span>
                                            <h4 className="maroon f40"><span className="counter"><CountUp end={(this.state.enroute_stoppage != '') ? this.state.enroute_stoppage : 0} /></span></h4>
                                        </div> */}
                                        {/* {(this.props.deptcode.includes("PRT")) ?                                        
                                                <div className="col cursorPointer">
                                                    <span className="f13"><i className="icofont icofont-map-pins f22 txt-danger"></i><br />Deviated Routes</span>
                                                    <h4 className="txt-secondary f40">
                                                        <span className="counter" onClick={this.onClickCounter.bind(this, "deviation")}>
                                                            <CountUp className="txt-danger" end={(this.state.deviated_routes != '' && this.state.deviated_routes != undefined) ? this.state.deviated_routes.length : 0} />
                                                        </span>
                                                    </h4>
                                                </div>                                           
                                            : ""} */}

                                        {(this.props.priority_counter_display == 1) ?
                                            <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "is_priority")}>
                                                <span className="f13"><i className="icofont icofont-check f22 green"></i><br /> Priority</span>
                                                <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.is_priority != '') ? this.state.is_priority : 0} /></span></h4>

                                            </div>
                                            : ""}

                                        {/* {(this.props.path == "/prtallconsignments" || this.props.path == "/prtdeliveredconsignments") ?
                                            <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "cancelled")}>
                                                <span className="f13"><i className="fa fa-window-close-o f22 maroon"></i><br /> Cancelled</span>
                                                <h4 className="maroon f40"><span className="counter"><CountUp end={(this.state.cancelledCount != '') ? this.state.cancelledCount : 0} /></span></h4>
                                            </div>
                                            : ""} */}
                                            
                                        {
                                            // (this.props.path == '/prtconsignments') ?
                                            // <>
                                            //     <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "no_gate_out_time")}>
                                            //         <span className="f13"><i className="fa fa-ban f22 maroon"></i><br /> No GateOut Time</span>
                                            //         <h4 className="maroon f40"><span className="counter"><CountUp end={(this.state.withoutTruckGateOutTimeData != '') ? this.state.withoutTruckGateOutTimeData : 0} /></span></h4>
                                            //     </div>
                                            //     <div className="col cursorPointer">
                                            //         <span className="f13"><i className="icofont icofont-social-google-map f24 txt-danger"></i><br /> GPS Not Available</span>
                                            //         <h4 className="txt-secondary f40">

                                            //             <span className="counter" onClick={this.onClickCounter.bind(this, "no_gps_data")}>

                                            //                 <CountUp className="txt-danger" end={(this.state.no_gps_data_available != '') ? this.state.no_gps_data_available : 0} />
                                            //             </span>
                                            //             {/* <span className="f24">&nbsp;/&nbsp;</span>
                                            //             <span className="counter" onClick={this.onClickCounter.bind(this, "no_gps_data_lifetime")}>
                                            //                 <CountUp className="txt-secondary" end={(this.state.nogpslifetime != '') ? this.state.nogpslifetime : 0} />
                                            //             </span> */}
                                            //         </h4>
                                            //     </div>
                                            // </>
                                            //      : ''
                                        }

                                        {
                                    /*(this.props.path == "/prtallconsignments" || this.props.path == "/prtconsignments"
                                        || this.props.path == "/prtdeliveredconsignments")?
                                    // <div className="">

                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"pod")}>
                                            <span className="f13"><i className="icofont icofont-vehicle-delivery-van cus-i f22 txt-success"></i><br /> POD Received</span>
                                            <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.pod_count != '')?this.state.pod_count:0}/></span></h4>

                                        </div>
                                    // </div>
                                    :""
                                    */}
                                        {/* {(this.props.path == "/prtallconsignments"
                                        || this.props.path == "/prtdeliveredconsignments")?
                                    // <div className="">

                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"trip_closed")}>
                                            <span className="f13"><i className="icofont icofont-vehicle-delivery-van cus-i f22 txt-warning"></i><br /> Trip Closed By POD</span>
                                            <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.trip_closed_by_pod_count != '')?this.state.trip_closed_by_pod_count:0}/></span></h4>

                                        </div>
                                    // </div>
                                    :""} */}
                                        {
                                     /*(this.props.path == "/prtallconsignments" || this.props.path == "/prtconsignments"
                                        || this.props.path == "/prtdeliveredconsignments")?
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"truck_reporting_date")}>
                                        <span className="f13"><i className="icofont icofont-vehicle-delivery-van cus-i f22 txt-warning"></i><br /> Trucks Reported</span>
                                        <h4 className="txt-success f40"><span className="counter"><CountUp end={(this.state.truckReportedDate != '')?this.state.truckReportedDate:0}/></span></h4>

                                    </div>
                                    :""
                                    */}

                                    </div>
                                    :
                                    <div className="row">
                                        <div className="col cirlce-d cursorPointer" onClick={this.onClickCounter.bind(this, "all")}>
                                            <span className="f13"><i className="icofont icofont-ui-browser f24 greenfont"></i><br /> Orders</span>
                                            <h4 className="txt-info f40"><span className="counter"><CountUp end={(this.state.active_consignments != '') ? this.state.active_consignments : 0} /></span></h4>

                                        </div>

                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "preferredDeliveries")}>
                                            <span className="f13"><i className="icofont icofont-pause f22 maroon"></i><br /> Preferred Deliveries</span>
                                            <h4 className="maroon f40"><span className="counter"><CountUp end={(this.state.preferredDeliveries != '') ? this.state.preferredDeliveries : 0} /></span></h4>
                                        </div>
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "onTimeDeliveriesCount")}>
                                            <span className="f13"><i className="icofont icofont-pause f22 maroon"></i><br /> On Time</span>
                                            <h4 className="maroon f40"><span className="counter"><CountUp end={(this.state.onTimeDeliveriesCount != '') ? this.state.onTimeDeliveriesCount : 0} /></span></h4>
                                        </div>
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this, "delayedDeliveriesCount")}>
                                            <span className="f13"><i className="icofont icofont-clock-time f22 redfont"></i><br /> Transit Delays</span>
                                            <h4 className="txt-danger f40"><span className="counter"><CountUp end={(this.state.delayedDeliveriesCount != '') ? this.state.delayedDeliveriesCount : 0} /></span></h4>

                                        </div>

                                        {/* <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"unloading_delay_exception")} >
                                        <span className="f13"><i className="icofont icofont-vehicle-delivery-van f24 mildgreen"></i><br /> Unloading Delay</span>
                                        <h4 className="mildgreen f40"><span className="counter"><CountUp end={(this.state.unloading_delay != '')?this.state.unloading_delay:0}/></span></h4>
                                    </div>

                                    {(this.props.counterScreen == "")?"":
                                        <div className="col cursorPointer" onClick={this.onClickCounter.bind(this,"is_critical")}>
                                            <span className="f13"><i className="icofont icofont-minus-circle f22 maroon"></i><br /> Critical</span>
                                            <h4 className="maroon f40"><span className="counter"><CountUp end={(this.state.is_critical != '')?this.state.is_critical:0}/></span></h4>
                                        </div>
                                    } */}


                                    </div>
                                }

                            </div>

                        </div>
                    </div>
                </div>

            </div>
        );
    }
}
