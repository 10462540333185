import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import _ from "lodash";
import Modal from 'react-responsive-modal';

import axios from 'axios';
import Select from 'react-select';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';

import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";

import SweetAlert from 'react-bootstrap-sweetalert';
import CustomLoadingCellRenderer from "../layouts/customLoadingCellRenderer";
import Submenus from "../superadmin/submenus";
import Empty from "../superadmin/empty";
var redirectURL = require('../redirectURL');
var googleAnalytics = require("../common/googleanalytics");

export default class AssignRoles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle: "Role Matrix",
            eventGridAction: "gridAction",
            eventCounterAction: "counterAction",
            eventFormAction: "formAction",
            loggedInDepartmentAdminRoles:null,
            customColumnFields:null,
            allUsers:null,
            customColumnDefinition:null,
            errorDuplicateRole:false,
            errorSelectionDepartment:false,
            selectedDepartment:null,
            departmentList:[],
            errorRoleUpdatedFailed: false,
            errorRoleNotEditable: false,
            errorEmptyValue: false,
            messageRoleUpdateSuccessful: false,
            userType:'',
            menu_title:'',
            loadshow:'show-n',
            overly:'show-n',
            updateForm:'show-n',
            addForm:'show-m',
            role_name:"",
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            sliderTranslate:'',
            allmenus:[],
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
            },
            modules: AllModules,
            rowData: [],
            headerHeight: 40,
            groupHeaderHeight:60,
            rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
               
            animateRows: true,
            debug: true,
            showToolPanel: false,
            uppressAggFuncInHeader: true,
                
            childconfs: [],
            childrow:[],
            rowModelType: "serverSide",
            paginationPageSize:100,
            cacheBlockSize: 10,
            maxBlocksInCache: 1,
            frameworkComponents: {
                customLoadingCellRenderer: CustomLoadingCellRenderer,
                submenus:Submenus,
                empty:Empty
            },
            detailCellRendererParams:{},
            statusBar: {
                statusPanels: [
                    {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                    },
                    {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center"
                    },
                    { statusPanel: "agFilteredRowCountComponent" },
                    { statusPanel: "agSelectedRowCountComponent" },
                    { statusPanel: "agAggregationComponent" }
                ]
            },
            sideBar: {
                toolPanels: [
                    {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel"
                    },
                    {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel"
                    }
                ]
            },
            otherusers:[],
            customotherusers:[],
            allRoles:[]


        };
    }
    getUserType = () => {
        let isAdminUser = localStorage.getItem('is_admin');
        let isDeptAdminUser = localStorage.getItem('is_dept_admin');
        let isGlobalUser = localStorage.getItem('is_global');
        //console.log(" isAdminUser and isGlobalUser = ", isAdminUser, isGlobalUser);
        if (isAdminUser == "true") {
            //console.log(" IN getUserType, ADMIN");
            this.setState({
                "userType": "admin"
            });

        }
        if (isDeptAdminUser == "true") {
            //console.log(" IN getUserType, is_dept_admin");
            this.setState({
                "userType": "departmentadmin"
            });
        }
        if (isAdminUser == "true" && isGlobalUser == "true") {
            //console.log(" IN getUserType, SUPERADMIN");
            this.setState({
                "userType": "superadmin"
            });
        }

    }
    createColumnDefinition = async () => {
        let roleList = this.state.allRoles;
        let isHidden = true;
        if (this.state.userType != "admin" ) {
            isHidden = false;
        };
        //console.log("this.state.userType = ", this.state.userType);

        let columnDefinition = [];
        let userEmailColumn = {
            headerName: "User-Email",
            field: "email",
            width: 200,
            hide: isHidden,
            pinned: true
        }
        let userFirstNameColumn = {
            headerName: "First Name",
            field: "firstname",
            width: 100,
            hide: isHidden,
            pinned: true
        }
        let userLastNameColumn = {
            headerName: "Last Name",
            field: "lastname",
            width: 100,
            hide: isHidden,
            pinned: true
        }
        let userType = {
            headerName: "User Type",
            field: "user_type",
            width: 120,
            hide: isHidden,
            pinned: true
        }
        let userDepartmentColumn = {
            headerName: "Department",
            field: "dept_code",
            width: 110,
            hide: isHidden,
            pinned: true
        }
        let verifiedColumn = {
            headerName: "Enabled",
            field: "verified",
            width: 110,
            hide: isHidden,
            pinned: true,
            cellStyle: function(params) {
                // console.log("params = ", params.data.is_editable, params.value, params);
                 if (params.value == 'Yes') {
                    // console.log("params = ", params.value, params.data);
                     return {backgroundColor: '#00dea7'};
                 } else {
                     return  {backgroundColor: '#d4d4d4', color:'#797979'};
                 }
             },
             editable: true,
             cellEditor: 'agSelectCellEditor',
             cellEditorParams: {
                 values: ['Yes', 'No'],
             },
             valueSetter: function(params) {
                console.log("IN ValyeSetter, data = ",params.data);
                if (params.oldValue !== params.newValue) {
                    //console.log(params.oldValue,params.newValue);
                    if (googleAnalytics.page.enableGA) {
                        let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                        let label = googleAnalytics.page.action.roleAssigned;
                        let pageTitle = "Role Matrix";
                        let eventOptions = {
                            "category": pageTitle,
                            "action": eventGridAction,
                            "label": label,
                        }
                        googleAnalytics.logEvent(eventOptions);
                    }

                    let selectedColumnName = params.colDef.field;
                    //console.log("selectedColumnName = ", selectedColumnName);
                    
                    let fields = Object.keys(params.data);
                   // console.log("fields ", fields)
                    let selectedRoles = [];
                    fields.forEach(field => {
                        if (params.data[field] == 'Yes') {
                            selectedRoles.push(field);
                        }
                    })                         
                    //console.log("IN ValyeSetter, selectedRoles = ",selectedRoles);
                    
                    // add if the value was changed for current column
                    var changeVerified;
                    if (params.newValue == 'Yes') {
                        changeVerified = 1;
                        selectedRoles.push(selectedColumnName);
                    } else {
                        changeVerified = 0;
                    }
                    console.log("IN ValyeSetter, selectedRolesFINAL = ",selectedRoles);
                    let layoutdataRole = {
                        changeVerified : changeVerified,
                        email: params.data.email
                    }
                    redirectURL.post('/superadmin/updateuserverification', layoutdataRole,{
                        headers:{
                            "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
                        }
                    })
                    .then((response) => {
                        var response = JSON.parse(JSON.stringify(response));
                        console.log("/superadmin/updaterolealias = ",response.data);
                        if (response.data.modifiedCount == 1) {
                            window.location.reload();
                        }
                        if (response.data.status == 400) {
                            alert(response.data.message);   
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                   // console.log("Role changed = ", newRoleAlias, data.role_alias);
                    return true;
                } else {
                    return false;
                }
            }
        }
        columnDefinition.push(userEmailColumn);
        columnDefinition.push(userFirstNameColumn);
        columnDefinition.push(userLastNameColumn);
        columnDefinition.push(userType);
        columnDefinition.push(verifiedColumn);
        
        //columnDefinition.push(userDepartmentColumn);
        if(roleList.length > 0)
        {
            roleList.forEach(role => {
                let definition = {
                    headerName: role.role_alias,
                    field: role.role_name,
                    width: 150,
                    hide: isHidden,
                    cellStyle: function(params) {
                    // console.log("params = ", params.data.is_editable, params.value, params);
                        if (params.value == 'Yes') {
                        // console.log("params = ", params.value, params.data);
                            return {backgroundColor: '#00dea7'};
                        } else {
                            return  {backgroundColor: '#d4d4d4', color:'#797979'};
                        }
                    },
                    editable: true,
                    cellEditor: 'agSelectCellEditor',
                    cellEditorParams: {
                        values: ['Yes', 'No'],
                    },
                    valueSetter: function(params) {
                    console.log("IN ValyeSetter, data = ",params.data);
                        if (params.oldValue !== params.newValue) {

                            if (googleAnalytics.page.enableGA) {
                                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                                let label = googleAnalytics.page.action.roleAssigned;
                                let pageTitle = "Role Matrix";
                                let eventOptions = {
                                    "category": pageTitle,
                                    "action": eventGridAction,
                                    "label": label,
                                }
                                googleAnalytics.logEvent(eventOptions);
                            }

                            let selectedColumnName = params.colDef.field;
                            //console.log("selectedColumnName = ", selectedColumnName);
                            let fields = Object.keys(params.data);
                            //console.log("fields ", fields)
                            let selectedRoles = [];
                            fields.forEach(field => {
                                if (params.data[field] == 'Yes') {
                                    selectedRoles.push(field);
                                }
                            })                         
                            //console.log("IN ValyeSetter, selectedRoles = ",selectedRoles);
                            
                            // add if the value was changed for current column
                            if (params.newValue == 'Yes') {
                                selectedRoles.push(selectedColumnName);
                            } else {
                                selectedRoles = selectedRoles.filter(role => {
                                    return role != params.colDef.field
                                });
                            }
                            console.log("IN ValyeSetter, selectedRolesFINAL = ",selectedRoles);
                            let layoutdataRole = {
                                roles: selectedRoles,
                                email: params.data.email
                            }
                            redirectURL.post('/superadmin/updateuserroles', layoutdataRole,{
                                headers:{
                                    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
                                }
                            })
                            .then((response) => {
                                var response = JSON.parse(JSON.stringify(response));
                                console.log("/superadmin/updaterolealias = ",response.data);
                                if (response.data.modifiedCount == 1) {
                                    window.location.reload();
                                }
                                if (response.data.status == 400) {
                                    alert(response.data.message);   
                                }
                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                        // console.log("Role changed = ", newRoleAlias, data.role_alias);
                            return true;
                        } else {
                            return false;
                        }
                    }
                }
                columnDefinition.push(definition);
            })
        }
        console.log(columnDefinition);
        this.setState({customColumnDefinition: columnDefinition});
    }
    setColumnFields = () => {
        let columnDefinition = this.state.customColumnDefinition;
        console.log(columnDefinition);
        let columnFields = [];
        columnDefinition.forEach(column => {
            columnFields.push(column.field);
        })
        this.setState({customColumnFields: columnFields});
    }
    createRowData = () => {
        let columnDefinition = this.state.customColumnDefinition;
        let roleList = this.state.allRoles;
        let userList = this.state.allUsers;
        let otherusers = this.state.otherusers;
        //  console.log("In Create Data, this.state.allUsers, userList = ", this.state.allUsers, userList);
        let columnFields = this.state.customColumnFields;
        // console.log(columnFields);
        let rowData = [];
        //console.log("COldDEF = ", columnDefinition);
        //console.log("In createRowData, userList = ", userList);
        userList.forEach(user => {
            let row = [];

            //console.log("In createRowData, row = ", row);
            columnFields.forEach(columnField => {
                //console.log("columnField = ", columnField, user);
                let isRoleAssiged = user.roles.includes(columnField);
                row[columnField] = (isRoleAssiged) ? 'Yes' : 'No';
            });
            row['email'] = user.email;
            row['firstname'] = user.firstname;
            row['lastname'] = user.lastname;
            row['user_type'] = user.user_type;
            if (user.verified == 1)
            {
                row['verified'] = "Yes";
            }
            else
            {
                row['verified'] = "No";
            }
            
            row['dept_code'] = user.dept_code;
            //console.log("In createRowData, Endrow = ", row);
            rowData.push(row);
            

        })
        let otherusersrowData = []
        otherusers.forEach(user => {
            let row = [];

            //console.log("In createRowData, row = ", row);
            columnFields.forEach(columnField => {
                //console.log("columnField = ", columnField, user);
                try{
                    let isRoleAssiged = user.roles.includes(columnField);
                    row[columnField] = (isRoleAssiged) ? 'Yes' : 'No';
                }
                catch(e){
                    console.log(e)
                }                
            });
            row['email'] = user.email;
            row['firstname'] = user.firstname;
            row['lastname'] = user.lastname;
            row['user_type'] = user.user_type;
            if (user.verified == 1)
            {
                row['verified'] = "Yes";
            }
            else
            {
                row['verified'] = "No";
            }
            
            row['dept_code'] = user.dept_code;
            //console.log("In createRowData, Endrow = ", row);
            otherusersrowData.push(row);
            

        })
       console.log("IN createRowData, rowData = ", rowData);
        this.setState({customRowData: rowData});
        this.setState({rowData: rowData});


       console.log("IN createRowData, rowData = ", otherusersrowData);
       this.setState({customotherusers: otherusersrowData});
       this.setState({otherusers: otherusersrowData});
    }

    logPageView = () => {
        try {
            if (googleAnalytics.page.enableGA) {
                googleAnalytics.logPageView();
                let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
                let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
                let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
                this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
            } else {
                console.log("GA disabled...");
            }
        } catch(error) {
            console.error("Error occured while logging to GA, error = ", error);
        }
    }
    
    componentDidMount(){
        this.getUserType();
        //console.log("this.state.userType",this.state.userType);
        this.logPageView(); // Log page view to GA
        //this.getUserType();
        redirectURL.post("/superadmin/siteusers")
        .then( async (response) => {
            console.log("IN componentDidMount, superadmin/users = ",response.data);
            let currentUserType = this.state.userType;
            //console.log("currentUserType ", currentUserType)
            let userList = response.data;
            var otherusers = response.data
            // let userDeptCode = JSON.parse(localStorage.getItem("dept_code"));
            // if (currentUserType == "superadmin") {
            //     userList = response.data;
            // }
            // if (currentUserType == "admin") {
            //     userList = response.data.filter(user => user.email.includes("@maruti.co.in"));
            // }
            // if (currentUserType == "departmentadmin") {
            //     // get roles of current user, these roles will be displayed as columns for department admins
            //     let currentUserEmail = localStorage.getItem("email");
            //     //console.log("currentUserEmail = ", currentUserEmail);
            //     let currentUserDetails = response.data.filter(user => user.email === currentUserEmail);
            //     //console.log("currentUserDetails = ", currentUserDetails, response.data);
            //     let loggedInDepartmentAdminRoles = (currentUserDetails) ? currentUserDetails[0].roles : null;
            //     this.setState({loggedInDepartmentAdminRoles: loggedInDepartmentAdminRoles});
            //     //console.log("loggedInDepartmentAdminRoles = ", loggedInDepartmentAdminRoles);

            //     let userListMaruti = response.data.filter(user => user.email.includes("@maruti.co.in"));
            //     response.data.filter(user => {
            //         if(user.user_type == 'TRANSPORTER')
            //         {
            //             userListMaruti.push(user)
            //         }
            //     })
            //     userList = userListMaruti.filter(user => {
            //         return (user.dept_code == userDeptCode)
            //     });
            //     console.log("Coming in departmentadmin, userList, userListMaruti, response.data = ", userList.length, userListMaruti.length, response.data.length);
            // }
            console.log("userList ", userList)
            this.setState({
                allUsers:userList,
                otherusers:otherusers
                //loadshow:'show-n'
            });

            //////////////////////// START FETCHING ROLES ////////////////////////////////////////////
            redirectURL.post("/superadmin/mllroles")
            .then((response) => {
                //console.log("IN componentDidMount, Roles =  ",response.data);
                
                let currentUserType = this.state.userType;
                let roles = null;
                let userDeptCode = JSON.parse(localStorage.getItem("dept_code"));
                
    
                //console.log("currentUserType, roles, userDeptCode = ", currentUserType, roles, userDeptCode);
                if (currentUserType == "superadmin") {
                    roles = response.data;
                   // console.log("Coming in superadmin, roles = ", roles);
                }
                if (currentUserType == "admin") {
                    roles = response.data.filter(role => role.mahindra_roles == 1); /////////////////ENABLE IT//////////////////
                   // console.log("Coming in admin, roles = ", roles);
                }
                if (currentUserType == "departmentadmin") {
                    let loggedInDepartmentAdminRoles = this.state.loggedInDepartmentAdminRoles;
                        //console.log("loggedInDepartmentAdminRoles ",loggedInDepartmentAdminRoles)
                    roles = response.data.filter(role => {
                        //console.log("Roels ", role)
                        if(role.dept_code == userDeptCode)
                        {
                            loggedInDepartmentAdminRoles.push(role.role_name)
                        }
                        //console.log("IN departmentadmin, role.dept_code, userDeptCode =",role.dept_code, userDeptCode);
                        //return (role.mahindra_roles == 0)
                        //return (role.mahindra_roles == 1) && (role.dept_code == userDeptCode)
                        
                        return (role.mahindra_roles == 1) && (loggedInDepartmentAdminRoles.includes(role.role_name));
                    });
                    //console.log("Coming in departmentadmin, roles, response.data = ", roles,  response.data);
                }
                //console.log("rowData : " ,roles);
                this.setState({allRoles: roles});
                this.createColumnDefinition();
                this.setColumnFields();
                this.createRowData();
                //console.log(" STATE customColumnDefinition = ", this.state.customColumnDefinition);
                //console.log("STATE customRowData = ", this.state.customRowData);
            })
            //////////////////////////// END FETCHING ROLES ////////////////////////////////////////
         
        })
        .catch(function(e){
            console.log(e)
        })




        redirectURL.post('/masters/getdepartments')
        .then((response) => {
            //console.log("/masters/getdepartments = ",response.data);
            this.setState({departmentList:response.data});
        });
    }
    hideAlert = () => {
        this.setState({
            alert: null
        });
    }
    closeAlert = () => {
        this.setState({
            show: false
        });
    }
	changeInputHandler = (event) => {
    	let name = event.target.name;
		let value = event.target.value;
    	this.setState({[name]:value});
    }

	changeFileHandler(e){
		this.setState({file:e.target.files[0]});
	}
    
	onClickHideAll(){
		this.setState({
			overly:'show-n',
			sliderTranslate:''
		});
		
	}
    onShowRoleForm(){

        this.setState({
            sliderTranslate:'slider-translate',
            overly:'show-m',
            addForm:'show-m',
            updateForm:'show-n'

        });
    }
    onShowUpdateRoleForm(){

        this.setState({
            sliderTranslate:'slider-translate',
            overly:'show-m',
            addForm:'show-n',
            updateForm:'show-m'

        });
    }
    formHandler = (event) =>{
        event.preventDefault();
        this.setState({errorDuplicateRole: false});
        if (!this.state.selectedDepartment || !this.state.selectedDepartment.value) {
           // console.log("IN Form Handler, this.state.selectedDepartment = ",this.state.selectedDepartment)
            this.setState({
                errorSelectionDepartment: true
            })
            return false;
        }
        

        var formdata = {
            role_name:this.state.role_name,
            mahindra_roles:1,
            dept_code: this.state.selectedDepartment.value
        }
        //console.log("IN Form Handler, formdata ", formdata);

        redirectURL.post("/superadmin/createrole", formdata)
        .then((response) => {
            //console.log("response ", response)
            if (response.data.status == 400) {
                this.setState({errorDuplicateRole: true});
                return false;
            }
            this.setState({
                loadshow:'show-n',
            });
            window.location.reload();
        })
        .catch(function(e){
            console.log(e.message);
        });
		
    }

	onGridReady = params => {
	    this.gridApi = params.api;
	    //console.log("Grid is: ", this.gridOptionsWrapper);
	    this.gridColumnApi = params.columnApi;
	
	   //    ChartMenu.prototype.saveChart = function (e) {
	// 	   var event = {
	// 	      data: this.gridOptionsWrapper.getApi(),
	// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
	// 	    };
	// 	   //console.log("Testing", event);
	// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
	// 	   this.dispatchEvent(event);
	// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;
		   
	//    };
	   
	};

	onGridState = () =>{
		 //console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
           this.gridApi.setFilterModel(window.filterState);
           
    }
    
	handlerForm = selectedDepartment => {
		//console.log("Select", event.target.value);
		this.setState(
			{ selectedDepartment },
			() => console.log(`Option selected:`, this.state.selectedDepartment)
		  );
        this.setState({
            errorSelectionDepartment: false
        })
        this.resetMatrix(selectedDepartment.value);

    }
    resetMatrix = (selectedDepartmentCode) => {
        console.log("selectedDepartmentCode = ", selectedDepartmentCode);
        //console.log("rowData = ", this.state.rowData);
        if(selectedDepartmentCode != "ALL")
        {
            if(selectedDepartmentCode == "TRANSPORTER")
            {
                let rowData = this.state.otherusers;
                let rowDataFiltered = rowData.filter(user => {
                    //console.log("User ", user)
                    //return user.dept_code == selectedDepartmentCode
                    // if (Array.isArray(user.dept_code)) {
                    //     return user.user_type.includes("TRANSPORTER");
                    // } else {
                    //     return user.user_type == "TRANSPORTER";
                    // }
                    if(user.user_type == "TRANSPORTER")
                    {
                        return user;
                    }
                })
                console.log("rowDataFiltered = ", rowDataFiltered);
                this.setState({rowData: rowDataFiltered});
            }
            else{
                let rowData = this.state.customRowData;
                let rowDataFiltered = rowData.filter(user => {
                    console.log("User ", user)
                    //return user.dept_code == selectedDepartmentCode
                    if (Array.isArray(user.dept_code)) {
                        return user.dept_code.includes(selectedDepartmentCode);
                    } else {
                        return user.dept_code == selectedDepartmentCode;
                    }
                })
                console.log("rowDataFiltered = ", rowDataFiltered);
                this.setState({rowData: rowDataFiltered});
            }
        }
        else{

            let rowData = this.state.customRowData;
            //console.log("rowData ", rowData)
            let rowDataFiltered = rowData.filter(user => {
                if(user.user_type == "MLLORGUSER" || user.user_type == "DCTUSER")
                {
                    return user;
                }
                //console.log("User ", user.user_type)
                //return user.dept_code == selectedDepartmentCode
                // if (Array.isArray(user.dept_code)) {
                //     return user.dept_code.includes(selectedDepartmentCode);
                // } else {
                //     return user.dept_code == selectedDepartmentCode;
                // }
            })
            //console.log("rowDataFiltered = ", rowDataFiltered);
            this.setState({rowData: rowDataFiltered});
            
            // this.setState({
            //     rowData : this.state.customRowData
            // })
        }
        

        let allRoles = this.state.allRoles;
        //console.log("allRoles ", allRoles)
        //let selectedRoles = allRoles.filter(role => role.dept_code === selectedDepartmentCode);
        let selectedRoles = allRoles; // As per new Change Request, Assigned all available roles for Site Admin
        // this.gridColumnApi.setColumnVisible('tnp',false);
        this.hideAllColumns();
        if (selectedRoles.length) {
            this.showSelectedColumns(selectedRoles);
            this.showAlwaysColumns();
        }
    }
    hideAllColumns = () => {
        let allColumns = this.state.customColumnDefinition;
        allColumns.forEach(column => {
            //console.log(column.field);
            this.gridColumnApi.setColumnVisible(column.field,false);
        })
    }
    showAlwaysColumns = () => {
        this.gridColumnApi.setColumnVisible("email",true);
        this.gridColumnApi.setColumnVisible("firstname",true);
        this.gridColumnApi.setColumnVisible("lastname",true);
        this.gridColumnApi.setColumnVisible("user_type",true);
        this.gridColumnApi.setColumnVisible("verified",true);
        //this.gridColumnApi.setColumnVisible("dept_code",true);
    }
    showSelectedColumns = (selectedRoles) => {
        selectedRoles.forEach(role => {
            //console.log("IN showSelectedColumns, role.role_name (column.field) = ", role.role_name);
            this.gridColumnApi.setColumnVisible(role.role_name,true);
        });
    }

    showAllColumns = () => {
        let allColumns = this.state.customColumnDefinition;
        allColumns.forEach(column => {
            this.gridColumnApi.setColumnVisible(column.field,true);
        })
    }

	selectOptionsItems() {
        let items = [];
        let departmentList = this.state.departmentList;
        //console.log(departmentList);
        items.push({"value":"ALL","label":"ALL"});
        departmentList.forEach(item => {
            // items.push({"value":item.dept_code,"label":item.dept_name});	
        });

        // items.push({"value":"TRANSPORTER","label":"Transporter"});
        return items;
    }
    
    checkRadioEvent = (event) => {
    	let name = event.target.name;
		let value = event.target.value;
    	this.setState({[name]:value});
    }

    checkGroupRadioEvent = (event) => {
    	let name = event.target.name;
        let value = event.target.value;
       // console.log("event.target.value ", event.target.value)
        if(event.target.value == true)
        {
            this.setState({[name]:value, groupheader:"show-m"});
        }
        else{
            this.setState({[name]:value, groupheader:"show-n"});
        }
    }

    render(){
        const columnDefs = this.state.customColumnDefinition;
        
        return (
            
    		<div className="container-fluid">
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
	        >
	        </SweetAlert>
	            {this.state.alert}
            	<div className="row">
            		<div className="col-xl-12 col-lg-12">
		            	<div className="card">
		            	
			                <div className="card-header">
				       			<h5>
				       				<i className="icofont icofont-navigation-menu cus-i"></i> <span>{this.state.pageTitle}</span>
                                    {/* <a href="/assignmenus" className="btn btn-warning ml-5p float-right">Assign Menus to Role</a> */}
                                </h5>
				   			</div>
				   			<div className="card-body row">
                                <div className="col-xl-12 col-lg-12 col-sm-12">
                                {/*
                                    {(this.state.messageRoleUpdateSuccessful) ? 
                                        <div className="txt-success f15" style={{padding:'0px 0px 20px 0px'}}>{this.state.messageFromServer}</div>
                                        : ""
                                    }
                                    {(this.state.errorRoleUpdatedFailed) ? 
                                        <div className="txt-danger f15" style={{padding:'0px 0px 20px 0px'}}> {this.state.messageFromServer}</div>
                                        : ""
                                    }
                                    {(this.state.errorRoleNotEditable) ? 
                                        <div className="txt-danger f15" style={{padding:'0px 0px 20px 0px'}}>Selected Role is not editable. Please contact Support Team.</div>
                                        : ""
                                    }
                                     {(this.state.errorEmptyValue) ? 
                                        <div className="txt-danger f15" style={{padding:'0px 0px 20px 0px'}}>Please provide a valid Role Name.</div>
                                        : ""
                                    } */}
                                </div>
                                {(this.state.userType == "admin" &&  this.state.allRoles != undefined) ? 
                                    <div className="form-group  col-xl-5 col-lg-5">
                                        <label className="c-lbl">Please select a Department Name to proceed:</label>
                                    
                                        <Select name="department_name" placeholder="Select Department" className="rselect" 
                                            value={this.state.department_name} onChange={this.handlerForm.bind(this)} 
                                            options={this.selectOptionsItems()} />
                                        {(this.state.errorSelectionDepartment)? 
                                            <div id="errorSelectionDepartment" className="txt-danger-medium">Please select a department.</div>
                                        : ""}
                                    </div>
                                : ""
                                }
                                <div className=" col-xl-12 col-lg-12">
                                    <div id="myGrid" style={{ height: "580px",width: "100%"}}  className="ag-theme-balham">
                                    <AgGridReact
                                        modules={this.state.modules}
                                        columnDefs={columnDefs}
                                        defaultColDef={this.state.defaultColDef}
                                        rowData={this.state.rowData}
                                        enableCharts={false}
                                        enableRangeSelection={false}
                                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                                        onGridReady={this.onGridReady}
                                        onGridState={this.onGridState}
                                        statusBar={this.state.statusBar}
                                        sideBar={this.state.sideBar}
                                        paginationPageSize={this.state.paginationPageSize}
                                        pagination={true}
                                        frameworkComponents={this.state.frameworkComponents}
									    detailCellRendererParams={this.state.detailCellRendererParams}
                                        //floatingFilter={true}
                                        gridOptions={{
                                            context: { componentParent: this }
                                        }}
                                        suppressCellFlash={true}
									    masterDetail={true}
                                        
                                    />

									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
									</div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                 <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
            
            </div>
        )
    }
}
function numberParser(params) {
    return Number(params.newValue);
  }