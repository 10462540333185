import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import redirectURL from '../../redirectURL';
import { getDDMMMYYYYHHMMDefault } from '../utils';
import io from "socket.io-client";
var moment = require('moment');
var socket = io("http://bpclptd.in/bpcl", {
    transports: ["websocket"],
    withCredentials: true,
    extraHeaders: {
      "x-custom-origin": "http://192.168.2.4"
    }
});
// var socket = io("http://localhost:5017");
// Import authService
class Notification extends Component {
    constructor(props){
        super(props);
        this.state = {
            notificationData : "",
            notificationCount : "",
            rowData : []
        }

       this.redirectToModule = this.redirectToModule.bind(this);
    }
    redirectToModule(rowId){
        console.log("Notification ID",rowId);
        var payload = {
            id:rowId
        }

        var consigneeCode = localStorage.getItem("consignee_code");
        if (consigneeCode != "" && consigneeCode != null && consigneeCode != undefined) {
            var params = {
                consigneeCode: consigneeCode
            }
        } else {
            params = {}
        }
        redirectURL.post("/consignments/changeNotificationReadStatus",payload).then((resp)=>{
            console.log(resp);
            redirectURL.post("/consignments/getDistributorNotifications",params).then((response)=>{

                this.setState({ 
                    notificationCount: response.data.length,
                    rowData: response.data 
                });

            }).catch((error)=>{
                console.log("Notifications err",error);
            }) 
        }).catch((error)=>{
            console.log("Mark Read Err", error);
        })
        // // console.log(rowId);
        // var userRole = localStorage.getItem('roles');
        // var params = {
        //     rowId:rowId,
        // }
        // if(userRole.includes("MahindraAdmin") || userRole.includes("Plant") || userRole.includes("RSO")
        // || userRole.includes("Loading Office") || userRole.includes("Unloading Officer") || userRole.includes("BA"))
        // {
        //     var urlpath2 = "/mllconsignments/changeNotificationReadStatus";
        // }
        // else
        // {
        //     var urlpath2 = "/consignments/changeNotificationReadStatus";

        // }
        // redirectURL.post(urlpath2,params)
        // .then((response) =>{
        //     var userEmail = localStorage.getItem('email');
        //     var userRole = localStorage.getItem('roles');
        //     var erparmas = {
        //         userEmail:userEmail,
        //         read_status:0,            
        //     }
        //     var erparmas1 = {
        //         userRole:userRole,
        //         read_status:0,            
        //     }
        //     if(userRole.includes("MahindraAdmin") || userRole.includes("Plant") || userRole.includes("RSO")
        //     || userRole.includes("Loading Office") || userRole.includes("Unloading Officer") || userRole.includes("BA"))
        //     {
        //         var urlpath = "/mllconsignments/getUserNotifications";
        //         var urlpath1 = "/mllconsignments/getUserNotificationsByRoles";
        //     }
        //     else
        //     {
        //         var urlpath = "/consignments/getUserNotifications";
        //         var urlpath1 = "/consignments/getUserNotificationsByRoles";

        //     }
        //     redirectURL.post(urlpath,erparmas)   
        //     .then((response) => {
        //         var records = response.data;
        //         redirectURL.post(urlpath1,erparmas1)
        //         .then((response1) => {
        //             var records1 = response1.data;
        //             var merRecords = records.concat(records1);
        //             const key = '_id';

        //             try{
        //                 const allrecords = [...new Map(merRecords.map(item =>
        //                     [item[key], item])).values()];
            
        //                     console.log(allrecords);
            
        //                     this.setState({
        //                         notificationCount : allrecords.length,
        //                         rowData : allrecords
        //                     })
        //             }
        //             catch(e){
        //                 const allrecords = [];
        
        //                 console.log(allrecords);
        
        //                 this.setState({
        //                     notificationCount : allrecords.length,
        //                     rowData : allrecords
        //                 })
        //             }
        //         })
        //     })
        //     .catch(function(e){
        //         console.log("Error ",e)
        //     })
        // })
    }
    componentDidMount(){

        // To open socket for further updates and notifications
        socket.on("updateNotifications", (notifications) => {
            this.setState({ notificationCount: notifications.length,rowData:notifications });
        });

        // Initial call to get unread messages from "notifications" collection
        var consigneeCode = localStorage.getItem("consignee_code");
        if (consigneeCode != "" && consigneeCode != null && consigneeCode != undefined) {
            var params = {
                consigneeCode: consigneeCode
            }
        } else {
            params = {}
        }
        
        redirectURL.post("/consignments/getDistributorNotifications", params).then((response)=>{
            this.setState({ notificationCount: response.data.length,rowData:response.data });
        }).catch((error)=>{
            console.log(error);
        }) 
        
        // var userEmail = localStorage.getItem('email');
        // var userRole = localStorage.getItem('roles');
        // // console.log("userRole ", userRole)
        
        // var erparmas = {
        //     userEmail:userEmail,
        //     read_status:0,            
        // }
        // var erparmas1 = {
        //     userRole:userRole,
        //     read_status:0,            
        // }
        // if(userRole.includes("MahindraAdmin") || userRole.includes("Plant") || userRole.includes("RSO")
        // || userRole.includes("Loading Office") || userRole.includes("Unloading Officer") || userRole.includes("BA"))
        // {
        //     var urlpath = "/mllconsignments/getUserNotifications";
        //     var urlpath1 = "/mllconsignments/getUserNotificationsByRoles";
        // }
        // else
        // {
        //     var urlpath = "/consignments/getUserNotifications";
        //     var urlpath1 = "/consignments/getUserNotificationsByRoles";

        // }
        // redirectURL.post(urlpath,erparmas)
        // .then((response) => {
        //     var records = response.data;
        //     console.log("records ", records)
        //     redirectURL.post(urlpath1,erparmas1)
        //     .then((response1) => {
        //         var records1 = response1.data;
        //         console.log("records1 ", records1)
        //         var merRecords = records.concat(records1);
        //         const key = '_id';

        //         try{
        //             const allrecords = [...new Map(merRecords.map(item =>
        //                 [item[key], item])).values()];
        
        //                 console.log("allrecords ",allrecords);
        
        //                 this.setState({
        //                     notificationCount : allrecords.length,
        //                     rowData : allrecords
        //                 })
        //         }
        //         catch(e){
        //             const allrecords = [];
    
        //             console.log("allrecords ",allrecords);
    
        //             this.setState({
        //                 notificationCount : allrecords.length,
        //                 rowData : allrecords
        //             })
        //         }
        //     })
        // })
        // .catch(function(e){
        //     console.log("Error ",e)
        // })
        
    }
    
    render() {
        // var userRole = localStorage.getItem('roles');
        // if(userRole.includes("MahindraAdmin") || userRole.includes("Plant") || userRole.includes("RSO")
        // || userRole.includes("Loading Office") || userRole.includes("Unloading Officer") || userRole.includes("BA"))
        // {
        //     var ismll = 1
        // }
        // else
        // {
        //     var ismll = 0
        // }

        return (
            <li className="onhover-dropdown">
                <a className="txt-dark">
                    <img className="align-self-center pull-right mr-2" src={require("../../../assets/images/dashboard/notification.png")} alt="header-notification" />
        <span className="badge badge-pill badge-primary notification">{(this.state.notificationCount==0)?"":this.state.notificationCount}</span>
                </a>
                {/* {(ismll == 0)? */}
                <ul className="notification-dropdown onhover-show-div" style={{height:"300px",overflowY:"auto"}}>
                    <li>Notifications <span className="badge badge-pill badge-secondary text-white text-uppercase pull-right" style={{backgroundColor:"rgba(237,23,36,0.8)",boxShadow:"0.5px 0.5px 0.5px 0.5px rgba(0,0,0,0.2)"}} >{(this.state.notificationCount==0)?"No New":this.state.notificationCount +" New"}</span></li>
                    {/* {console.log("Render loop ", this.state.rowData)} */}

                    {(this.state.rowData && Array.isArray(this.state.rowData))? this.state.rowData.map((e, i) => {
                        return <li key={e._id}>
                                <div className="media">
                                    <div className="media-body">
                                        <p className="mb-0">{e.notification_message}</p>
                                        {e.notification_date ?
                                        <span><i className="icofont icofont-clock-time p-r-5"></i>{moment.parseZone(e.notification_date).format("DD-MM-YYYY HH:mm")}</span>
                                        : ""}
                                        &nbsp;&nbsp;
                                        <span style={{color:"#e5503f",float:"right"}} onClick={this.redirectToModule.bind(this,e._id)}>Mark As Read</span>
                                    </div>
                                </div>
                            </li> 
                        }) : <></>
                    }
                    
                    {/* <li className="text-center">You have Check <a href="#">all</a> notification  </li> */}
                </ul>
                {/* :""} */}
            </li>
        );
    }
}

export default Notification