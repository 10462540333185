/* eslint-disable array-callback-return */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import _, { join } from "lodash";
import axios from 'axios';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
//import {ChartMenu} from '@ag-grid-enterprise/charts/dist/es6/chartAdaptor/chartComp/menu/chartMenu';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
//import Grid from '../layouts/gridnosidebarComponent';
import Grid from '../layouts/gridComponent';
import CSVFileValidator from 'csv-file-validator'
import ConsignmentActions from './ConsignmentActionsComponent';
import CommentActions from '../layouts/commentsComponent';
import SegmentAction from "../layouts/segmentAction";
import KpiActions from './kpiActionsComponent';
import KpiDeviationActions from './kpiDeviationActionsComponent';
import SegmentSideBar from "../layouts/segmentComponent";
import KpiTransitDelayActions from './kpiTransitDelayActions';
import Nooverspeed from './nooverspeedComponent.js';
import Notransit from './notransitComponent.js';
import RoutemapAction from './routemapActionComponent';
import RoutemapTransitAction from './routemaptransit';
import RoutemapNoGPSAction from './routemapnogps';
import RouteMapForDayWiseDistanceTrip from './routeMapForDayWiseDistanceTrip';
import RouteNightDrivemap from './routenightdrivemap';
import RoutemapenrouteAction from './routemapenroute';
import KpiNightDriveActions from './kpiNightDriveActionsComponent';
import Nonightdrive from './nonightdriveComponent.js';
import KpiEnrouteStoppagesActions from './kpiEnrouteStoppagesActionsComponent';
import kpiDayWiseDistanceActions from './kpiDayWiseDistanceActionsComponent'
import KpiLoadingDelayActions from './kpiLoadingDelayActions';
import KpiUnLoadingDelayActions from './kpiUnLoadingDelayActions';
import Noenroutes from './noenroutesComponent.js';
import Gps from './gpsActionComponent.js';
import Gpsdata from './gpsdataaction.js';
import NoGps from './nogpsActionComponent.js';
import NoGpsLifetime from './nogpslifetimeActionComponent.js';
import Consignmentforceclose from './consignmentforceclose.js';
import Select from 'react-select';
import ForceCloseSideBar from '../layouts/forceclosuresidebarcomponent.js';
import Modal from 'react-responsive-modal';
import CustomLoadingCellRenderer from "../layouts/customLoadingCellRenderer";
import AppendComponentIndex from '../common/appendComponents';
import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
//import Counters from '../layouts/consignmentcountersComponent';
import Counters from '../layouts/consignmentkpicountersComponent';
import DateFormater from '../layouts/dateComponent';
import tollImg from '../../assets/icons/tolls-image.png';
import {
	getDDMMYYYY,
	getDDMMYYYYHHMMSS,
	getYYYYMMDD,
	getYYYYMMDDHHMMSS,
	getHyphenYYYYMMDDHHMMSS,
	getHyphenDDMMMYYYYHHMM,
	getHyphenYYYYMMDD,
	getHyphenDDMMYYYYHHMMSS,
	getHyphenDDMMYYYY,
	getHyphenDDMMMYYYY,
	getDDMMMYYYYHHMMDefault
} from '../common/utils';
import 'react-vertical-timeline-component/style.min.css';
import DrawMap from '../common/drawmap';
import ConsignmentDrawMap from '../common/consignmentdrawmap';
import MarkDeliver from "../courier/markDeliveredButton.js";
import ComponentIndex from '../common/appendComponents';
import CustomDateComponent from '../common/dateFilterComponent';
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import datetimepicker from 'jquery-datetimepicker';
import ProbableAction from "../layouts/probableDataComponent";
import Consignmenttickets from "./consignmenttickets";
import e from 'jquery-datetimepicker';

$.datetimepicker.setLocale('en');
var infoBox = require('../common/google-infowindow');
var request = require('request');
var redirectURL = require('../redirectURL');
var moment = require('moment');
var googleAnalytics = require("../common/googleanalytics");

var drawingManager;
var selectedShape;
var colors = ['#1D9567', '#1692A5', '#F4D749', '#F73E3E', '#B7B3B3'];
var selectedColor;
var colorButtons = {};
var fencingLength = 0;
var mapZoom = 12;
var coordinates = [];
var geoFencingArea;
var containerslist = [];

const decipher = salt => {
	const textToChars = text => text.split('').map(c => c.charCodeAt(0));
	const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
	return encoded => encoded.match(/.{1,2}/g)
		.map(hex => parseInt(hex, 16))
		.map(applySaltToChar)
		.map(charCode => String.fromCharCode(charCode))
		.join('');
}
var decode = decipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

export default class ManageConsignments extends Component {

	constructor(props) {
		super(props);
		this.displayData = [];
		this.state = {
			activeDataCounters: {
				preferredDeliveriesCount: 0,
				offerPrefStatusCount: 0,
				prefLangStatusCount: 0,
				selectPrefDateStatusCount: 0,
				selectPrefTimeStatusCount: 0,
				confirmPrefStatusCount: 0,
				thankYouStatusCount: 0,
				invalidWhatsAppNoCount: 0,
				noAvailableSlotsCount: 0,
				messageNotTriggeredCount: 0,
			},
			consignerAreas:{},
			deptcode: "",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			eventAction: null,
			forceCloseRowNode: "",
			allRowData: [],
			activeFilter: null,
			uploadDivWidth: '0%',
			sliderTranslate: '',
			sliderRouteTranslate: '',
			sliderCommentTranslate: "",
			tabsliderTranslate: '',
			timelineinfo: '',
			consignment_code: '',
			sliderSegmentTranslate: "",
			mapinfo: '',
			commentsRowData: [],
			dealer: '',
			maptruckno: '',
			loadshow: 'show-n',
			showDiv: 'show-n',
			alertshow: 'fade',
			alertmg: 'show-n',
			alerterrmg: 'show-n',
			overly: 'show-n',
			alerterrshow: 'fade',
			alert: null,
			show: false,
			basicTitle: '',
			rownode: '',
			leg_no: '',
			basicType: "default",
			modules: AllModules,
			filterstatess: {},
			columnsdatas: {},
			areaStatusMap: {},
			rowgroupdcols: [],
			valuecolumns: [],
			activeconsignemnt: [],
			activetrucks: [],
			transitdelays: [],
			gpsdatana: [],
			overspeedtrucks: [],
			nightdrive: [],
			open: false,
			openforceclose: false,
			pivotmodeis: false,
			pivotcols: [],
			defTransitCoords: '',
			routeTruck: {},
			sidebarSubHeader: '',
			googelRoutes: '',
			plants: [],
			bulkPrioritySlide: "",
			hideTransporterBtns: "show-m",
			defaultColDef: {
				// sortable: true,
				filter: true,
				resizable: true,
				pivot: true,
				enableValue: true,
				enableRowGroup: false,
			},
			rowData: [],
			forceCloseConsignmentNo: "",
			forceCloseConsigneeCode: "",
			colDefFieldName: "",
			forceCloseRemarks: "",
			// delivery_status: "",
			delayedDeliveries:[],
			onTimeDeliveries:[],
			forceClosedDeliveriesData:[],
			maprowData: null,
			rowSelection: 'multiple',
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			overlayNoRowsTemplate: "",
			frameworkComponents: {
				consignmentActions: ConsignmentActions,
				commentActions: CommentActions,
				SegmentAction: SegmentAction,
				kpiActions: KpiActions,
				kpiDeviationActions: KpiDeviationActions,
				kpiTransitDelayActions: KpiTransitDelayActions,
				MarkDeliver: MarkDeliver,
				nooverspeed: Nooverspeed,
				notransit: Notransit,
				kpiNightDriveActions: KpiNightDriveActions,
				nonightdrive: Nonightdrive,
				kpiEnrouteStoppagesActions: KpiEnrouteStoppagesActions,
				kpiDayWiseDistanceActions: kpiDayWiseDistanceActions,
				noenroutes: Noenroutes,
				kpiLoadingDelayActions: KpiLoadingDelayActions,
				kpiUnLoadingDelayActions: KpiUnLoadingDelayActions,
				dateFormater: DateFormater,
				gps: Gpsdata,
				nogps: NoGps,
				noGpsLifetime: NoGpsLifetime,
				RoutemapenrouteAction: RoutemapenrouteAction,
				customLoadingCellRenderer: CustomLoadingCellRenderer,
				consignmentforceclose: Consignmentforceclose,
				customDateComponent: CustomDateComponent,
				ProbableAction: ProbableAction,
				Consignmenttickets: Consignmenttickets
			},

			animateRows: true,
			debug: true,
			showToolPanel: false,
			uppressAggFuncInHeader: true,
			activeOrdersForceCloseModal: false,

			childconfs: [],
			childrow: [],
			rowModelType: "serverSide",
			paginationPageSize: 100,
			cacheBlockSize: 10,
			maxBlocksInCache: 1,
			statusBar: {
				statusPanels: [
					{
						statusPanel: "agTotalAndFilteredRowCountComponent",
						align: "left"
					},
					{
						statusPanel: "agTotalRowCountComponent",
						align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
						id: "columns",
						labelDefault: "Columns",
						labelKey: "columns",
						iconKey: "columns",
						toolPanel: "agColumnsToolPanel"
					},
					{
						id: "filters",
						labelDefault: "Filters",
						labelKey: "filters",
						iconKey: "filter",
						toolPanel: "agFiltersToolPanel"
					}
				]
			},
			detailCellRendererParams: {},
			rowClassRules: {
				//"highlitRow": "data.transit_delay == 1",
				//"green":"data.status >= 3"

			},
			triptype: { "value": 'all', "label": 'All' },
			tripvalue: '',
			// consigner: [{ "value": 'all', "label": 'All' }],
			// consignee: [{"value": 'all', "label": 'All'}],
			originalData: [],
			isCounterDisplay: 1,
			screenpage: '',
			pagetitle: 'Active Orders',
			countersjson: '',
			originalcountersjson: '',
			consigneecoords: '',
			consigner_coordinates: '',
			containerslist: [],
			forceclosedata: '',
			reached_dealer_time: moment.parseZone().format('YYYY-MM-DD'),
			reachedhh: '',
			reachedmm: '',
			reachedss: '00',
			left_dealer_time: moment.parseZone().format('YYYY-MM-DD'),
			lefthh: '',
			leftmm: '',
			leftss: '00',
			invoice_time: '',
			invoicehh: '',
			invoicemm: '',
			segementConsignmentCode: "",
			counter_screen: "",
			priority_counter_display: 0,
			invoicess: '00',
			reasonforceclose: '',
			sliderTranslatesidebar: '',
			consignment_details: '',
			startDatetime: '',
			endDatetime: '',
			tolls: [],
			excelStyles: [
				{
					id: "dateFormat",
					dataType: 'dateTime',
					numberFormat: { format: "yyyy-mm-dd" }
				}
			],
			getRowHeight: function (params) {
				if (typeof params.data.dct_comment != 'undefined') {
					//console.log("pars ", params.data.dct_comment.length)
					return (
						//params.api.getSizesForCurrentTheme().rowHeight *
						(100 * Math.floor(params.data.dct_comment.length / 50))
					);
				}
				if (typeof params.data.vin_list != 'undefined') {
					//console.log("pars ", params.data.vin_list.toString().length)
					return (
						//params.api.getSizesForCurrentTheme().rowHeight *
						(100 * Math.floor(params.data.vin_list.toString().length / 50))
					);
				}
				else if (typeof params.data.force_closure_reason != 'undefined') {
					//console.log("pars ", params.data.dct_comment.length)
					return (
						//params.api.getSizesForCurrentTheme().rowHeight *
						(100 * Math.floor(params.data.force_closure_reason.length / 50))
					);
				}
				else {
					return 30;
				}
			},
			defaultsdate: '',
			defaultedate: '',
			movementtype: { "value": "all", "label": "All" },
			usergridstate: [],
			screenurl: "",
			screentitle: "",
			showumncr: 0,
			showummove: 0,
			usermanualncrmodal: false,
			usermanualmovemodal: false,
			slideuploadeway: "",
			cancelled_data: [],
			pod_received_data: [],
			trip_closed_by_pod: [],
			returnConsignments: [],
			tnpButtonTitle: "",
			tnpReturnConsignmentsVisibilty: false,
			consBtn: "btn-danger",
			returnConsBtn: "btn-default",
			truckReportedDate: [],
			consigneeAddressMap: {},
			filterCities: [],
			filterStates: [],
			filterConsignmentAreas: [],
			customer_val: {"value": "", "label": ""},			
			customerarea_val: { "value": '', "label": '' },
			filterCustomerAreas: [],
			filterConsignees: [],
			filterConsigners: [],
			filterDistributors: [],
			filterClusters: [],
			filterZones: [],
			filterTransporters: [],
			beforeNewFilterRowData: [],
			beforeNewFiltercountersjson: [],
			file: '',
			flCity: "",
			flState: "",
			flConsignee: [{ value: 'all', label: 'All' }],
			flConsigner: [{ value: 'all', label: 'All' }],
			flArea: [{ value: 'all', label: 'All' }],
			filterCustomers: [],
			deliveryStatusOptions: [
				{value: "delivered", label: "Delivered"},
				{value: "not delivered", label: "Not Delivered"}
			],
			custMobileMap: {},
			custCylinderMap: {},
			areaDeliveryMap: {},
			areaDeliveryMobileMap: {},
			custAreaCodeMap: {},
			custAreaMap: {},
			productCode: "",
			refillType: "",
			noOfCylinders: "",
			paymentOption: {value: "", label: ""},
			deliveryFlag: {value: "", label: ""},
			deliveryPersonName: "",
			deliveryPersonMobileNo: "",
			flCluster: "",
			flZone: "",
			flTransporter: "",
			sliderCreateConsignmentTranslate: "",
			devation_flag: false,
			truck_no: [],
			markDeliverDefault: "",
			sliderMarkDeliverTranslate: "",
			bookingNo: "",
			customer_id: "",
			plant_code: { "value": "Select Plant", "label": "Select Plant" },
			grno_consignment_code: "",
			invoice_no: "",
			invoice_date: "",
			booking_date: "",
			gate_out_time: "",
			consignee_code: "",
			trucklist: [],
			plantlist: [],
			dept_code: "",
			consignees: [],
			loginplant: "",
			selectedFilter: [],
			baseRouteCoords: "",
			withoutTruckGateOutTimeData :[],
			forceClosed : [],
			sliderRso:"",
			startDate:"",
			endDate:"",
			driver_name:[],
			driver_mobile_num:[],
			customer_mobile_no: [],
			transportersdata:[],
			transporter_code:[],
			consigneeCode: "",
			userType: "",
		}
		this.onShowRouteDiv = this.onShowRouteDiv.bind(this);
		this.onShowCommentsDiv = this.onShowCommentsDiv.bind(this);
		this.changeSegmentHandler = this.changeSegmentHandler.bind(this);
		this.onShowTimelineDiv = this.onShowTimelineDiv.bind(this);
		this.onShowRouteMap = this.onShowRouteMap.bind(this);
		this.onShowTransitRouteMap = this.onShowTransitRouteMap.bind(this);
		this.onShowNoGPSRouteMap = this.onShowNoGPSRouteMap.bind(this);
		this.onShowGoogleRoute = this.onShowGoogleRoute.bind(this);
		this.selectConsignerOptionsItems = this.selectConsignerOptionsItems.bind(this);
		this.onShowEnrouteRouteMap = this.onShowEnrouteRouteMap.bind(this);
		this.onClickShowMapView = this.onClickShowMapView.bind(this);
		this.onClickForceClose = this.onClickForceClose.bind(this);
		this.changeHandler = this.changeHandler.bind(this);
		this.handleInput = this.handleInput.bind(this);
		this.closeSegmentSideBar = this.closeSegmentSideBar.bind(this);
		this.onLoadGetConsignmentsList = this.onLoadGetConsignmentsList.bind(this);
		this.resetState = this.resetState.bind(this);
		this.onShowConsignmentTickets = this.onShowConsignmentTickets.bind(this);
		this.onShowUploadEWay = this.onShowUploadEWay.bind(this);
		this.onBulkPriorityBtn = this.onBulkPriorityBtn.bind(this);
		this.uploadEway = this.uploadEway.bind(this);
		this.changeFileHandler = this.changeFileHandler.bind(this);
		this.onClickCounterShowData = this.onClickCounterShowData.bind(this);
		this.onClickMarkDelivered = this.onClickMarkDelivered.bind(this);

		this.cipher = this.cipher.bind(this);
	}
	cipher = (salt) => {
		const textToChars = text => text.split('').map(c => c.charCodeAt(0));
		const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
		const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
		try {
			return text => text.split('')
				.map(textToChars)
				.map(applySaltToChar)
				.map(byteHex)
				.join('');
		}
		catch (e) {

		}

	}


	componentDidCatch(error, info) {
		console.log("Error here ", error)
		console.log("Error Info here ", info)
	}
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({ eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction });
			} else {
				console.log("GA disabled...");
			}
		} catch (error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
	componentDidMount() {
		loadDateTimeScript();
		var loguser = decode(localStorage.getItem("m"));
		// console.log("LOGUSER ", JSON.parse(loguser));
		console.log("user_type", localStorage.getItem('user_type'));
		var userType = localStorage.getItem('user_type');
		this.setState({
			userType: userType
		})
		if(userType == "DISTRIBUTOR"){
			var consigneeCode = localStorage.getItem('consignee_code')
			this.setState({
				consigneeCode: consigneeCode
			})
		};
		var loginplant = "";
		try {
			var luser = JSON.parse(loguser);
			loginplant = luser.is_plant_code;
			// console.log("logplant", loginplant)
			if (loginplant !== "" && loginplant !== undefined) {
				this.setState({
					loginplant: loginplant,
					consigner: [{ "value": luser.is_plant_code, "label": luser.is_plant_name }]

				})
			}
		}
		catch (e) {

		}
		if(userType == "DISTRIBUTOR"){
			var params = {
				consigneeCode: consigneeCode
			}
		} else {
			params = {}
		}
		
		redirectURL.post('/masters/customers', params).then((res) => {
			var customersList = []
			var custMobileMap = {}
			var custCylinderMap = {}
			var custAreaCodeMap = {}
			var custAreaMap = {}
			if (userType == "NAGPURORGUSER") {
				res.data = res.data.filter(e => ["NAGPUR", "Nagpur"].includes(e.customer_city))
			} else if (userType == "NASIKORGUSER") {
				res.data = res.data.filter(e => ["NASIK", "Nasik", "NASHIK", "Nashik"].includes(e.customer_city));
			} else if (userType == "SOLAPURORGUSER"){
				res.data = res.data.filter(e => ["Solapur"].includes(e.customer_city))
			} else if (userType == "INDOREORGUSER"){
				res.data = res.data.filter(e => ["Indore"].includes(e.customer_city))
			} else if (userType == "AHMEDABADORGUSER"){
				res.data = res.data.filter(e => ["Ahmedabad"].includes(e.customer_city))
			}
			if(res.data && Array.isArray(res.data)){
				res.data.map((item) => {
					var customer = {}
					customer.value = item.customer_id
					customer.label = `${item.customer_id} - ${item.customer_name}`
					customersList.push(customer)
					custMobileMap[`${item.customer_id}`] = item.customer_mobile_no
					custCylinderMap[`${item.customer_id}`] = item.no_of_cylinders
					custAreaCodeMap[`${item.customer_id}`] = item.customer_area_id
					custAreaMap[`${item.customer_id}`] = item.customer_area
				})
			}
			this.setState({
				filterCustomers: customersList,
				custMobileMap: custMobileMap,
				custCylinderMap: custCylinderMap,
				custAreaCodeMap: custAreaCodeMap,
				custAreaMap: custAreaMap
			})
			console.log("custCylinderMap", custCylinderMap)
		})

		redirectURL.post('/masters/deliveryboy', params).then((res) => {
			var areaDeliveryMap = {}
			var areaDeliveryMobileMap = {}
			if(res.data && Array.isArray(res.data)){
				res.data.map((item) => {
					areaDeliveryMap[`${item.customer_area_id}`] = item.delivery_person_name
					areaDeliveryMobileMap[`${item.customer_area_id}`] = item.delivery_person_mobile_no
				})
			}
			this.setState({
				areaDeliveryMap: areaDeliveryMap,
				areaDeliveryMobileMap: areaDeliveryMobileMap
			})
			console.log("areaDeliveryMap", areaDeliveryMap)
			console.log("areaDeliveryMobileMap", areaDeliveryMobileMap)
		})

		var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		var edate = moment.parseZone().format('YYYY-MM-DD');
		if (this.props.match.path.includes("deliveredconsignments") || this.props.match.path.includes("allconsignments")) {
			var sdate = moment.parseZone().subtract(10, 'days').format('YYYY-MM-DD');
		}
		else {
			var sdate = moment.parseZone().subtract(7, 'days').format('YYYY-MM-DD');
			// var sdate = "2023-04-01";
		}


		// var sdate = '2020-03-01';

		var edatetime = moment.parseZone().format('DD-MM-YYYY HH:mm');
		var sdatetime = moment.parseZone().format('DD-MM-YYYY HH:mm');
		this.logPageView(); // Log page view to GA
		let eventAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
		this.setState({ eventAction: eventAction });
		//console.log("Props ", this.props.match)
		this.setState({
			loadshow: 'show-m',
			startDate: sdate,
			endDate: edate,
			defaultsdate: sdate,
			defaultedate: edate,
			startDatetime: sdatetime,
			endDatetime: edatetime
		});
		var urlpath;
		var dpt;
		var isCounterDisplay = 1;
		var screenpage = '';
		var title = '';

		var consignData = {}
		if (loginplant != "" && loginplant != undefined) {
			var consigners = [{ "value": loginplant, "label": loginplant }]
		}
		else {
			var consigners = this.state.flConsigner;
		}
		if (this.props.match.path == "/sndconsignments") {

			urlpath = '/consignments/sndconsignments';
			dpt = 'SNDG';
			title = 'Active Consignments (Sales and Dispatch)';

			consignData = {
				dept_code: encode(dpt),
				startDate: sdate,
				endDate: edate,
				consigner: consigners
			}
			this.setState({
				startDate: "",
				endDate: "",
				counter_screen: "active"
			});
		}
		else if (this.props.match.path == "/prtconsignments") {
			urlpath = '/consignments/prtconsignments';
			dpt = 'LOG-PRT';
			title = 'Active Orders';
			if(localStorage.getItem('consignee_code') !== "" && localStorage.getItem('consignee_code') !== null && localStorage.getItem('consignee_code') !== undefined)
			{
				var cCode = [localStorage.getItem('consignee_code')];
			}
			else{
				var cCode =this.state.flConsignee.map(e => e.value); 
			}
			consignData = {
				// dept_code: encode(dpt),
				consigner: this.state.flConsigner.map(e => e.value),
				consignee: cCode,
				areas: this.state.flArea.map(e => e.value),
				startDate: sdate,
				endDate: edate,
				state: this.state.flState,
				city: this.state.flCity
				// movement: [this.state.movementtype]
			}
			// this.getWithoutGateoutTime(consignData)
			this.setState({
				counter_screen: "active",
				// priority_counter_display: 1,
			});
		}
		else if (this.props.match.path == "/tnpconsignments") {
			urlpath = '/consignments/tnpconsignments';
			dpt = 'LOG-TNP';
			title = 'Active Consignments (Train and Production)';
			consignData = {
				dept_code: encode(dpt),
				startDate: sdate,
				endDate: edate,
				consigner: consigners,
				triptype: this.state.triptype
			}
			var transportercode = localStorage.getItem('transportercode');
			if (transportercode != undefined && transportercode != "undefined" && transportercode != "") {
				var tptCode = JSON.parse(transportercode);
				consignData.transporter_code = tptCode[0];
			}
			this.setState({
				startDate: sdate,
				endDate: edate,
				counter_screen: "active",
				tnpButtonTitle: "Active"
			});
		}
		else if (this.props.match.path == "/sndallconsignments") {

			urlpath = '/consignments/allsndconsignments';
			dpt = 'SNDG';
			isCounterDisplay = 1;
			screenpage = 'all';
			title = 'All Consignments (Sales and Dispatch)';
			consignData = {
				dept_code: encode(dpt),
				startDate: sdate,
				endDate: edate,
				consigner: consigners,
				screen: "all"
			}
			this.setState({
				startDate: sdate,
				endDate: edate
			});

		}
		else if (this.props.match.path == "/prtallconsignments") {

			// commented out the gate_out time in backend as of 27-07-2022 because we had a sample data and later we need to uncomment them after getting live data  

			urlpath = '/consignments/allprtconsignments';
			dpt = 'LOG-PRT';
			isCounterDisplay = 1;
			screenpage = 'all';
			title = 'All Orders';
			consignData = {
				// dept_code: encode(dpt),
				startDate: sdate,
				endDate: edate,
				consigner: this.state.flConsigner.map(e => e.value),
				consignee: this.state.flConsignee.map(e => e.value),
				areas: this.state.flArea.map(e => e.value),
				state: this.state.flState,
				city: this.state.flCity,
				// movement: [this.state.movementtype],
				// screen: "all"
			}
		}
		else if (this.props.match.path == "/tnpallconsignments") {
			urlpath = '/consignments/alltnpconsignments';
			dpt = 'LOG-TNP';
			isCounterDisplay = 1;
			screenpage = 'all';
			title = 'All Consignments (Train and Production)';
			consignData = {
				dept_code: encode(dpt),
				startDate: sdate,
				endDate: edate,
				consigner: this.state.consigner,
				triptype: this.state.triptype,
				screen: "all",
			}
			this.setState({
				tnpButtonTitle: "All"
			})
		}
		else if (this.props.match.path == "/snddeliveredconsignments") {
			urlpath = '/consignments/deliveredsndconsignments';
			dpt = 'SNDG';
			isCounterDisplay = 1;
			screenpage = 'deliver';
			title = 'Delivered Consignments (Sales and Dispatch)';
			consignData = {
				dept_code: encode(dpt),
				startDate: sdate,
				endDate: edate,
				consigner: consigners,
				consignee: this.state.consignee,
			}
			this.setState({
				startDate: sdate,
				endDate: edate
			});
		}
		else if (this.props.match.path == "/prtdeliveredconsignments") {
			urlpath = '/consignments/deliveredprtconsignments';
			dpt = 'LOG-PRT';
			isCounterDisplay = 1;
			screenpage = 'deliver';
			title = 'Delivered Orders';
			consignData = {
				// dept_code: encode(dpt),
				startDate: sdate,
				endDate: edate,
				consigner: this.state.flConsigner.map(e => e.value),
				consignee: this.state.flConsignee.map(e => e.value),
				areas: this.state.flArea.map(e => e.value),
				state: this.state.flState,
				city: this.state.flCity
				// movement: [this.state.movementtype]
			}
			// this.setState({
			// 	startDate: sdate,
			// 	endDate: edate
			// });
		}
		else if (this.props.match.path == "/tnpdeliveredconsignments") {
			urlpath = '/consignments/deliveredtnpconsignments';
			dpt = 'LOG-TNP';
			isCounterDisplay = 1;
			screenpage = 'deliver';
			title = 'Delivered Consignments (Train and Production)';
			consignData = {
				dept_code: encode(dpt),
				startDate: sdate,
				endDate: edate,
				consigner: this.state.consigner,
				consignee: this.state.consignee,
				triptype: this.state.triptype
			}
			this.setState({
				tnpButtonTitle: "Delivered"
			})
		}
		else if (this.props.match.path == "/railconsignments") {
			urlpath = '/consignments/railsndconsignments';
			dpt = 'SNDG';
			isCounterDisplay = 1;
			screenpage = 'rail';
			title = 'Rail Consignments';
			consignData = {
				dept_code: encode(dpt),
				startDate: sdate,
				endDate: edate,
				consigner: this.state.consigner,
				consignee: this.state.consignee,
			}
		}
		else if (this.props.match.path == "/ncrconsignments") {
			urlpath = '/consignments/ncrsndconsignments';
			dpt = 'SNDG';
			isCounterDisplay = 1;
			screenpage = 'ncr';
			title = 'NCR Consignments (Sales and Dispatch)';
			consignData = {
				dept_code: encode(dpt),
				startDate: sdate,
				endDate: edate,
				consigner: this.state.consigner,
				consignee: this.state.consignee,
			}

			this.setState({
				startDate: sdate,
				endDate: edate,
				showumncr: 1
			});
		}
		else if (this.props.match.path == "/moveconsignments") {
			urlpath = '/consignments/movesndconsignments';
			dpt = 'SNDG';
			isCounterDisplay = 1;
			screenpage = 'move';
			title = 'Move Consignments (Sales and Dispatch)';
			consignData = {
				dept_code: encode(dpt),
				startDate: sdate,
				endDate: edate,
				consigner: this.state.consigner,
				consignee: this.state.consignee,
			}
			this.setState({
				showummove: 1
			})
		}
		else if (this.props.match.path == "/insidemsilvicinty") {
			urlpath = '/consignments/allsndconsignments';
			dpt = 'SNDG';
			isCounterDisplay = 1;
			screenpage = 'all';
			title = 'Inside MSIL Vicinity';
			consignData = {
				dept_code: encode(dpt),
				startDate: sdate,
				endDate: edate,
				consigner: this.state.consigner,
				consignee: this.state.consignee,
				screen: "insidemsil"
			}
			this.setState({
				startDate: sdate,
				endDate: edate
			});

		}
		this.setState({
			deptcode: dpt,
			isCounterDisplay: isCounterDisplay,
			screenpage: screenpage,
			pagetitle: title
		});

		if (userType == "DISTRIBUTOR") {
			var params = {
				consigneeCode: consigneeCode
			}
		} else if (userType == "NAGPURORGUSER") {
			params = {
				customerCity: ["Nagpur", "NAGPUR"]
			}
		} else if (userType == "NASIKORGUSER") {
			params = {
				customerCity: ["Nasik", "NASIK", "Nashik", "NASHIK"]
			}
		} else if (userType == "SOLAPURORGUSER") {
			params = {
				customerCity: ["Solapur"]
			}
		} else if (userType == "INDOREORGUSER") {
			params = {
				customerCity: ["Indore"]
			}
		} else if (userType == "AHMEDABADORGUSER") {
			params = {
				customerCity: ["Ahmedabad"]
			}
		} else {
			params = {}
		}
		redirectURL.post("/consignments/getConsignmentsNewFilterKeys", params)
		.then((resp) => {
			var cities = resp.data.cities;
			let consignerAreas = resp.data.consignerAreas;
			this.setState({
				consignerAreas:consignerAreas
			})
			var filterCities = [];
			if (cities != "" && cities != undefined && cities.length > 0) {
				cities.map(function (c) {
					filterCities.push({
						label: c,
						value: c,
					});
				});
			}
			var states = resp.data.states;
			var filterStates = [];
			if (states != "" && states != undefined && states.length > 0) {
				states.map(function (s) {
					filterStates.push({
						label: s,
						value: s,
					});
				});
			}
			// var clusters = resp.data.clusters;
			// var filterClusters = [];
			// if (clusters.length > 0) {
			// 	clusters.map(function (cl) {
			// 		filterClusters.push({
			// 			label: cl,
			// 			value: cl,
			// 		});
			// 	});
			// }
			var consignmentAreas = resp.data.consignmentAreas;
			var filterConsignmentAreas = [];
			if (consignmentAreas != "" && consignmentAreas != undefined && consignmentAreas.length > 0) {
				consignmentAreas.map(function (cl) {
					filterConsignmentAreas.push({
						label: cl,
						value: cl,
					});
				});
			}

			var customerAreas = resp.data.customerAreas;
			var filterCustomerAreas = [];
			if (customerAreas != "" && customerAreas != undefined && customerAreas.length > 0) {
				customerAreas.map((area) => {
					filterCustomerAreas.push({
						label: area.customer_area,
						value: area.customer_area_id
					})
				})
				filterCustomerAreas.unshift({"value": "all", "label": "All" })
			}

			var consignees = resp.data.consignees;
			var filterConsignees = [];
			if (consignees != "" && consignees != undefined && consignees.length > 0) {
				consignees.map((consignee) => {
					filterConsignees.push({
						label: consignee.consignee_name,
						value: consignee.consignee_code
					})
				})
				filterConsignees.unshift({ "value": "all", "label": "All" })
			}

			var consigneeAddressMap = {}
			var consigneeContactNoMap = {}
			var consigneeEmergencyNoMap = {}
			if (consignees != "" && consignees != undefined && consignees.length > 0) {
				consignees.map((consignee) => {
					consigneeAddressMap[`${consignee.consignee_code}`] =  consignee.consignee_address;
					consigneeContactNoMap[`${consignee.consignee_code}`] =  [consignee.consignee_contact_no_1, consignee.consignee_contact_no_2];
					consigneeEmergencyNoMap[`${consignee.consignee_code}`] =  [consignee.consignee_emergency_no_1, consignee.consignee_emergency_no_2];
				})
			}

			var consigners = resp.data.consigners;
			var filterConsigners = [];
			if (consigners != "" && consigners != undefined && consigners.length > 0) {
				consigners.map((consigner) => {
					filterConsigners.push({
						label: consigner.consigner_name,
						value: consigner.consigner_code
					})
				})
				filterConsigners.unshift({ "value": "all", "label": "All" })
			}
			
			// var transporters = resp.data.transporters;
			// var filterTransporters = [];
			// if (transporters.length > 0) {
			// 	transporters.map(function (t) {
			// 		filterTransporters.push({
			// 			label: t,
			// 			value: t,
			// 		});
			// 	});
			// }
			this.setState({
				filterCities: filterCities,
				filterStates: filterStates,
				// filterClusters: filterClusters,
				filterConsignmentAreas: filterConsignmentAreas,
				filterCustomerAreas: filterCustomerAreas,
				filterConsignees: filterConsignees,
				filterConsigners: filterConsigners,
				consigneeAddressMap: consigneeAddressMap,
				consigneeContactNoMap: consigneeContactNoMap,
				consigneeEmergencyNoMap: consigneeEmergencyNoMap
				// filterTransporters: filterTransporters,
			});
		})

		//Use following code after ACL enable


		//console.log("dept_code ", this.state.dept_code)
		/*urlpath = '/consignments/consignmentByDepartment';
			dpt=localStorage.getItem('dept_code');
		redirectURL.post(urlpath,consignData)    */



		var activeconsignemntarr = [];
		var activetrucksarr = [];
		var transitdelaysarr = [];
		var gpsdatanaarr = [];
		var overspeedtrucksarr = [];
		var nightdrivearr = [];
		var enroutearr = [];
		var loadingarr = [];
		var unloadingarr = [];
		var alltrucksarr = [];

		redirectURL.post("/consignments/plants", { dept_code: encode(dpt) })
			.then((resp) => {
				this.setState({
					plants: resp.data
				});
				console.log("consigners", this.state.plants)
			})

		redirectURL.post("/consignments/usergridstates", { userId: localStorage.getItem("userid"), screenurl: window.location.pathname })
			.then(async (resp) => {
				//console.log("resp ", resp)
				await this.setState({
					usergridstate: resp.data
				});
				await this.restoreGridStates();
			})
		// console.log("urlPath:", urlpath, "consignData:", consignData)

		var urlpath = '';
		if (this.props.match.path == "/sndconsignments") {

			urlpath = '/consignments/filterSNDActiveConsignments';

		}
		else if (this.props.match.path == "/prtconsignments") {
			urlpath = '/consignments/filterPRTActiveConsignments';

		}
		else if (this.props.match.path == "/tnpconsignments") {
			urlpath = '/consignments/tnpconsignments';
		}
		else if (this.props.match.path == "/sndallconsignments") {
			urlpath = '/consignments/allsndconsignments';
		}
		else if (this.props.match.path == "/insidemsilvicinty") {
			urlpath = '/consignments/allsndconsignments';
		}
		else if (this.props.match.path == "/prtallconsignments") {
			urlpath = '/consignments/allsndconsignments';
		}
		else if (this.props.match.path == "/tnpallconsignments") {
			urlpath = '/consignments/alltnpconsignments';
		}
		else if (this.props.match.path == "/snddeliveredconsignments") {
			urlpath = '/consignments/deliveredsndconsignments';
		}
		else if (this.props.match.path == "/prtdeliveredconsignments") {
			urlpath = '/consignments/deliveredprtconsignments';
		}
		else if (this.props.match.path == "/tnpdeliveredconsignments") {
			urlpath = '/consignments/deliveredtnpconsignments';
		}
		else if (this.props.match.path == "/railconsignments") {
			urlpath = '/consignments/railsndconsignments';
		}
		else if (this.props.match.path == "/ncrconsignments") {
			urlpath = '/consignments/ncrsndconsignments';
		}
		else if (this.props.match.path == "/moveconsignments") {
			urlpath = '/consignments/movesndconsignments';
		}
		this.onLoadGetConsignmentsList(urlpath, consignData,consigneeCode, userType);

		// var aurlpath = "";
		// var aurlpathplant = "";
		// var aconsignmenturlpath = "";
		// if (this.props.match.path == "/prtconsignments") {
		// 	//var	dept_code = dpt;
		// 	aurlpath = "/dashboard/prtdepttrucks";
		// 	aurlpathplant = "/dashboard/prtdeptplants";
		// 	aconsignmenturlpath = "/dashboard/prtdeptconsignments";
		// }
		// var parameters = {
		// 	dept_code: encode(dpt)
		// }
		// if (aurlpath != "") {
		// 	redirectURL.post(aurlpath, parameters)
		// 		.then((response) => {
		// 			var records = response.data.records;
		// 			var trucks = []
		// 			if (records.length > 0) {
		// 				records.map((item) => {
		// 					trucks.push({
		// 						value: item.truck_no,
		// 						label: item.truck_no
		// 					})
		// 				})
		// 			}
		// 			this.setState({
		// 				trucklist: trucks
		// 			})
		// 		})
		// }
		// if (aurlpathplant != "") {
		// 	redirectURL.post(aurlpathplant, parameters)
		// 		.then((response) => {
		// 			var records = response.data.records;
		// 			var plants = []
		// 			if (records.length > 0) {
		// 				records.map((item) => {
		// 					if (this.state.loginplant != "" && this.state.loginplant != undefined) {
		// 						if (item.plant_code == this.state.loginplant) {
		// 							plants.push({
		// 								value: item.plant_code,
		// 								label: item.plant_name
		// 							})
		// 						}
		// 					}
		// 					else {
		// 						plants.push({
		// 							value: item.plant_code,
		// 							label: item.plant_name + " " + "-" + " " + item.plant_code
		// 						})
		// 					}

		// 				})
		// 			}
		// 			this.setState({
		// 				plantlist: plants
		// 			})
		// 		})
		// }
		// var consignData = {
		// 	dept_code: encode(dpt)
		// }
		// redirectURL.post("/dashboard/prtconsignees")
		// 	.then((response) => {
		// 		var records = response.data.records;
		// 		console.log(records)
		// 		var consignees = []
		// 		if (records.length > 0) {
		// 			records.map((item) => {

		// 				consignees.push({
		// 					value: item.consignee_code,
		// 					label: item.consignee_name + " " + "-" + " " + item.consignee_code
		// 				})
		// 			})
		// 		}
		// 		this.setState({
		// 			consignees: consignees
		// 		})
		// })

		// redirectURL.post("/dashboard/getlogprttransporterlist")
		// .then((response1) => {
		// 	var transporterrecords = response1.data.records;
		// 	var transportersdata = []
		// 	if (transporterrecords.length > 0) {
		// 		transporterrecords.map((tt) => {

		// 			transportersdata.push({
		// 				value: tt.transporter_code,
		// 				label: tt.transporter_name + " " + "-" + " " + tt.transporter_code
		// 			})
		// 		})
		// 	}
		// 	this.setState({
		// 		transportersdata: transportersdata
		// 	})
		// })
	};

	// getWithoutGateoutTime = (consignData) =>{
	// 	redirectURL.post('/consignments/prtconsignmentsNoGateOut', consignData).then( response =>{
	// 		// console.log("responseNoGateOut", response.data)
	// 		var withoutTruckGateOutTimeData = response.data.consignments;
	// 		this.setState({
	// 			withoutTruckGateOutTimeData
	// 		})
	// 		// var pagecounters = response.data.counters;
	// 	})
	// }


	onLoadGetConsignmentsList(urlpath, parameters, consigneeCode, userType) {
		this.setState({
			loadshow: 'show-m',
			overly: 'show-m'
		})
		var userType = localStorage.getItem("user_type");
		var consigneeCode = localStorage.getItem("consignee_code");
		/*  */
		// console.log("user_type, consigneeCode", userType, consigneeCode)
		// console.log("this.state.loginplant ", this.state.loginplant)
		// consigneeCode = this.state.consigneeCode;
		if (userType == "DISTRIBUTOR" && consigneeCode !== undefined) {
			var params = {
				consigneeCode: consigneeCode
			} 
		} else {
			params = {}
		}
		var areaStatusMap;
		redirectURL.post('/masters/areastatus', params).then((res) => {
			areaStatusMap = res.data
			redirectURL.post(urlpath, parameters)
			.then(async (response) => {
				console.log("response 987", response.data)
				var records = response.data.consignments;
				var newRecords = response.data.consignments;
				var gridData = newRecords;
				var pagecounters = response.data.counters;
				var onTimeDeliveries = []
				var delayedDeliveries = []
				
				if(pagecounters.hasOwnProperty("onTimeDeliveries")){
					onTimeDeliveries = pagecounters.onTimeDeliveries;
				}
				if(pagecounters.hasOwnProperty("delayedDeliveries")){
					delayedDeliveries = pagecounters.delayedDeliveries;
				}
				if (userType == "DISTRIBUTOR") {
					onTimeDeliveries = onTimeDeliveries.filter((each)=>{
						if(each.consignee_code === consigneeCode){
							return each;
						}
					})

					delayedDeliveries = delayedDeliveries.filter((each)=>{
						if(each.consignee_code === consigneeCode){
							return each;
						}
					})
				}
				
				var returnConsignments = (response.data.returnConsignments != undefined) ? response.data.returnConsignments : []
				pagecounters.cancelled_count = "Not Applicable"
				// var areaStatusMap = this.state.areaStatusMap;
				console.log("areaStatusMap", areaStatusMap)
				console.log("Page Counters",pagecounters);
				// pagecounters['deviated_routes'] = records.filter(e => e.deviation_flag == 1)
				// console.log("recordsLength", records.length);
				console.log("userType", userType)
				if (userType == "DISTRIBUTOR") {
					console.log("newRecords ",newRecords)
					if (this.props.match.path == "/prtconsignments") {
						var activeRecords = await newRecords.filter((e) => areaStatusMap[e.customer_area_id] == "Active" && e.consignee_code == consigneeCode)
					} else {
						var activeRecords = await newRecords.filter((e) => e.consignee_code == consigneeCode)
					}
					
				} else if (userType == "NAGPURORGUSER") {
					if (this.props.match.path == "/prtconsignments") {
						activeRecords = await newRecords.filter((e) => areaStatusMap[e.customer_area_id] == "Active" &&  ["Nagpur", "NAGPUR"].includes(e.customer_city))
					} else {
						activeRecords = await newRecords.filter((e) => ["Nagpur", "NAGPUR"].includes(e.customer_city))
					}
				} else if (userType == "NASIKORGUSER") {
					if (this.props.match.path == "/prtconsignments") {
						activeRecords = await newRecords.filter((e) => areaStatusMap[e.customer_area_id] == "Active" && ["Nasik", "NASIK", "Nashik", "NASHIK"].includes(e.customer_city))
					} else {
						activeRecords = await newRecords.filter((e) => ["Nasik", "NASIK", "Nashik", "NASHIK"].includes(e.customer_city))
					}
				} else if (userType == "SOLAPURORGUSER") {
					if (this.props.match.path == "/prtconsignments") {
						activeRecords = await newRecords.filter((e) => areaStatusMap[e.customer_area_id] == "Active" && ["Solapur"].includes(e.customer_city))
					} else {
						activeRecords = await newRecords.filter((e) => ["Solapur"].includes(e.customer_city))
					}
				} else if (userType == "INDOREORGUSER") {
					if (this.props.match.path == "/prtconsignments") {
						activeRecords = await newRecords.filter((e) => areaStatusMap[e.customer_area_id] == "Active" && ["Indore"].includes(e.customer_city))
					} else {
						activeRecords = await newRecords.filter((e) => ["Indore"].includes(e.customer_city))
					}
				} else if (userType == "AHMEDABADORGUSER") {
					if (this.props.match.path == "/prtconsignments") {
						activeRecords = await newRecords.filter((e) => areaStatusMap[e.customer_area_id] == "Active" && ["Ahmedabad"].includes(e.customer_city))
					} else {
						activeRecords = await newRecords.filter((e) => ["Ahmedabad"].includes(e.customer_city))
					}
				} else {
					if (this.props.match.path == "/prtconsignments") {
						activeRecords = await newRecords.filter((e) => areaStatusMap[e.customer_area_id] == "Active")
					} else {
						activeRecords = newRecords
					}
				}
				
				console.log("activeRecords", activeRecords)

				let preferredDeliveriesArr = [];
				let offerPrefStatusArr = [];
				let prefLangStatusArr = [];
				let selectPrefDateStatusArr = [];
				let selectPrefTimeStatusArr = [];
				let confirmPrefStatusArr = [];
				let thankYouStatusArr = [];
				let invalidWhatsAppNoArr = [];
				let noAvailableSlotsArr = [];
				let messageNotTriggeredArr = [];

				activeRecords.forEach((each)=>{
					let {step_code, delivery_type} = each;
					if(delivery_type === "Preferred"){
						preferredDeliveriesArr.push(each)
					}

					if(step_code === "OFFER_PREFERRED_TIME_DELIVERY"){
						offerPrefStatusArr.push(each)
					}

					if(step_code === "PREFERRED_LANGUAGE"){
						prefLangStatusArr.push(each)
					}

					if(step_code === "SELECT_PREFERRED_DATE"){
						selectPrefDateStatusArr.push(each)
					}


					if(step_code === "SELECT_PREFERRED_TIME_SLOT"){
						selectPrefTimeStatusArr.push(each)
					}

					if(step_code === "CONFIRM_PREFERRED_TIME_DELIVERY"){
						confirmPrefStatusArr.push(each)
					}

					if(step_code === "THANK_YOU"){
						thankYouStatusArr.push(each)
					}


					if(step_code === "NOT_VALID_WHATSAPP_NUMBER"){
						invalidWhatsAppNoArr.push(each)
					}


					if(step_code === "NO_AVAILABLE_SLOTS"){
						noAvailableSlotsArr.push(each)
					}


					if(step_code === "NA"){
						messageNotTriggeredArr.push(each)
					}
				})

				let preferredDeliveriesCount = preferredDeliveriesArr.length;
				let offerPrefStatusCount = offerPrefStatusArr.length;
				let prefLangStatusCount = prefLangStatusArr.length;
				let selectPrefDateStatusCount = selectPrefDateStatusArr.length;
				let selectPrefTimeStatusCount = selectPrefTimeStatusArr.length
				let confirmPrefStatusCount = confirmPrefStatusArr.length;
				let thankYouStatusCount = thankYouStatusArr.length
				let invalidWhatsAppNoCount = invalidWhatsAppNoArr.length;
				let noAvailableSlotsCount = noAvailableSlotsArr.length;
				let messageNotTriggeredCount = messageNotTriggeredArr.length;


				
				let activeDataCounters = {
					preferredDeliveries: preferredDeliveriesArr,
					offerPrefStatusCount: offerPrefStatusArr,
					prefLangStatusCount: prefLangStatusArr,
					selectPrefDateStatusCount: selectPrefDateStatusArr,
					selectPrefTimeStatusCount: selectPrefTimeStatusArr,
					confirmPrefStatusCount: confirmPrefStatusArr,
					thankYouStatusCount: thankYouStatusArr,
					invalidWhatsAppNoCount: invalidWhatsAppNoArr,
					noAvailableSlotsCount: noAvailableSlotsArr,
					messageNotTriggeredCount: messageNotTriggeredArr,
					transit_delay: 0,
					no_gps: 0,
					nogpslifetime: 0,
					overspeeding: 0,
					night_drive: 0,
					enroute_stoppage: 0,
					loading_delay: 0,
					unloading_delay: 0,
					criticalCount: 0,
					cancelled_count: "Not Applicable",
				};
				

				this.setState({
					activeDataCounters: activeDataCounters
				},()=>{
					console.log("activeDataCounters",activeDataCounters)
				})



				if(activeRecords.length > 0){
					var forceClosedDeliveriesData = activeRecords.filter(rec => {
						if(rec.closure_status === "Force Closed"){
							return rec
						}
					})
				}
				var cancelled_data = []
				var pod_received_data = [];
				var trip_closed_by_pod = [];
				// console.log("counters", pagecounters);
				// records = records.sort(GetSortDescOrder("_id"));
				// records = records.sort(GetSortDescOrder("invoice_time_seconds"));
				// if (this.state.loginplant !== "" && this.state.loginplant !== undefined) {
				// 	records = records.filter(e => e.consigner_code == this.state.loginplant);
				// 	console.log("loginplant",this.state.loginplant)
				// 	console.log("0")
				// }
				if (records.length > 0) {

					if (this.props.match.path == "/sndallconsignments"
						|| this.props.match.path == "/prtallconsignments"
						|| this.props.match.path == "/tnpallconsignments") {

						if (this.props.match.path == "/prtallconsignments") {
							cancelled_data = records.filter(function (e) {
								return e.cancelled == 1
							})
							pagecounters.cancelled_count = cancelled_data.length
							pod_received_data = records.filter(function (e) {
								return e.pod_received == 1
							})
							pagecounters.pod_received_data = pod_received_data.length
							trip_closed_by_pod = records.filter(function (e) {
								return e.trip_closed_by_pod == 1
							})
							// console.log(trip_closed_by_pod, "trip_closed_by_pod")
							pagecounters.trip_closed_by_pod = trip_closed_by_pod.length
						}

					}
					else if (this.props.match.path == "/ncrconsignments"
						|| this.props.match.path == "/moveconsignments") {

						records = records.filter(rec => rec.status <= 3)
					}
					else if (this.props.match.path == "/snddeliveredconsignments"
						|| this.props.match.path == "/prtdeliveredconsignments"
						|| this.props.match.path == "/tnpdeliveredconsignments") {
						if (this.props.match.path == "/prtdeliveredconsignments") {
							pod_received_data = records.filter(function (e) {
								return e.pod_received == 1
							})
							pagecounters.pod_received_data = pod_received_data.length
							trip_closed_by_pod = records.filter(function (e) {
								return e.trip_closed_by_pod == 1
							})
							pagecounters.trip_closed_by_pod = trip_closed_by_pod.length
							cancelled_data = records.filter(function (e) {
								return e.cancelled == 1
							})
							pagecounters.cancelled_count = cancelled_data.length
							var forceClosed = []
							// records = records.filter(rec => {
							// 	if(rec.status == 6){
							// 		// console.log('status', rec)
							// 		forceClosed.push(rec)
							// 	}else{
							// 		return rec.status > 2
							// 	}
							// })

						}else{
							records = records.filter(rec => rec.status > 2)
						}
					}
					else {

						records = records.filter(rec => rec.status >= 1)
						if (this.props.match.path == "/prtconsignments") {
							pod_received_data = records.filter(function (e) {
								return e.pod_received == 1
							})
							pagecounters.pod_received_data = pod_received_data.length
							trip_closed_by_pod = records.filter(function (e) {
								return e.trip_closed_by_pod == 1
							})
							pagecounters.trip_closed_by_pod = trip_closed_by_pod.length
						}
					}
				}
				var trucksReported = [];
				if (this.props.match.path == "/prtconsignments" || this.props.match.path == "/prtdeliveredconsignments" || this.props.match.path == "/prtallconsignments") {
					trucksReported = records.filter(function (e) {
						return (e.truck_reporting_date !== undefined && e.truck_reporting_date !== "")
					})
				}
				//console.log("records ", records.filter(e=> e.tpt_confirm_delivery == 1))
				
				// console.log("gridlenBefore",gridData.length)
				var selectedCity = $("#selectedCity").text();
				var selectedState = $("#selectedState").text();
				var selectedCluster = $("#selectedCluster").text();
				var selectedTransporter = $("#selectedTransporter").text();
				// console.log(selectedCity,selectedState,selectedCluster,selectedTransporter,"filters")
				//console.log(filterRowData,"0")
				if (selectedCity !== undefined && selectedCity !== '' && selectedCity !== "Select...") {
					gridData = gridData.filter(e => e.customer_city == selectedCity);
					// console.log("1")
				}
				if (selectedState !== undefined && selectedState !== '' && selectedState !== "Select...") {
					gridData = gridData.filter(e => e.customer_state == selectedState);
					// console.log("2")

				}
				if (selectedCluster !== undefined && selectedCluster !== '' && selectedCluster !== "Select...") {
					// console.log("selectedCluster",selectedCluster)
					// console.log("3")
					// gridData = gridData.filter(e => e.cluster == selectedCluster);
					gridData = gridData.filter(e => {
						if (e.zone == selectedCluster) {
							// console.log("ecluster",e.cluster)
							return e
						}
					});
				}
				if (selectedTransporter !== undefined && selectedTransporter !== '' && selectedTransporter !== "Select...") {
					// console.log("selectedCluster",selectedTransporter)
					// console.log("4")
					// gridData = gridData.filter(e => e.transporter_name == selectedTransporter);
					gridData = gridData.filter(e => {
						if (e.transporter_name == selectedTransporter) {
							// console.log("transporter_name",e.transporter_name)
							return e
						}

					});
				}
				// if (this.props.match.path == "/prtconsignments") {
				// 		// console.log('checkinging')
				// 		records = records.filter((item) =>item.no_gps_data !== 1)
				// }
				// console.log("gridData",gridData)
				// console.log("gridData",gridData.length)
				this.setState({
					rowData: activeRecords,
					activeRecords: activeRecords,
					delayedDeliveries:delayedDeliveries,
					onTimeDeliveries:onTimeDeliveries,
					forceClosedDeliveriesData: forceClosedDeliveriesData,
					areaStatusMap: areaStatusMap,
					allRowData: gridData,
					originalData: gridData,
					beforeNewFilterRowData: gridData,
					loadshow: 'show-n',
					overly: 'show-n',
					countersjson: pagecounters,
					originalcountersjson: pagecounters,
					beforeNewFiltercountersjson: pagecounters,
					cancelled_data: cancelled_data,
					trip_closed_by_pod: trip_closed_by_pod,
					pod_received_data: pod_received_data,
					returnConsignments: returnConsignments,
					consBtn: "btn-danger",
					returnConsBtn: "btn-default",
					truckReportedDate: trucksReported,
					forceClosed
				});
				
				// if(this.state.urlpath == '/consignments/filterTNPActiveConsignments')
				// {

				// }
				// else
				// {
				// 	this.setState({

				// 	})
				// }


			})
			.catch(function (error) {
				console.log(error);
			});
		})
		console.log("areaStatusMap", areaStatusMap)
		//console.log(parameters);
		
		
	}

	onShowTimelineDiv = (e) => {
		//console.log("Props data ", e)
		this.setState({
			loadshow: 'show-m'
		});
		var reqData = {
			consignment_code: e.consignment_code
		}
		//console.log("reqData: ", reqData);
		redirectURL.post('/consignments/timeline', reqData, {
			headers: {
				'content-type': 'application/json'
			}
		})
			.then((response) => {
				var records = response.data;
				//console.log("records", records);

				this.setState({
					uploadDivWidth: '30%',
					sliderTranslate: "slider-translate-50p",
					showDiv: 'show-m',
					timelinedata: records,
					loadshow: 'show-n',
					overly: 'show-m'
				});
			})
			.catch(function (error) {
				console.log(error);
			});

	}

	onCloseUploadDiv = () => {
		this.setState({
			uploadDivWidth: '0%',
			sliderTranslate: "",
			tabsliderTranslate: '',
			showDiv: 'show-n',
			sliderTranslatesidebar: "",
			sliderCommentTranslate: "",
			uploadFile: '',
			file: '',
			csvmessage: '',
			csverrmessage: '',
			overly: 'show-n',
			loadshow: 'show-n',
			slideuploadeway: ""
		});
	}
	onShowGoogleRoute(consignmentcode, consigneecode, legno, truck_no) {
		// 	console.log("GOogle params ", consignmentcode+", "+ consigneecode+", "+legno)
		// if (googleAnalytics.page.enableGA) {
		// 	let pageTitle = this.state.pagetitle;
		// 	let eventOptions = {
		// 		"category": pageTitle,
		// 		"action": this.state.eventGridAction,
		// 		"label": googleAnalytics.page.action.viewRoute,
		// 	}
		// 	googleAnalytics.logEvent(eventOptions);
		// }

		// this functionality is missing in the python flask so we are commmenting the code here

		var reqData = {
			consignment_code: consignmentcode,
			consignee_code: consigneecode,
			leg_no: legno,
			truck_no: truck_no
		}
		// redirectURL.post('/consignments/googlemaproutes', reqData, {
		// 	headers: {
		// 		'content-type': 'application/json'
		// 	}
		// })
		// 	.then((response) => {
		// 		// console.log("Google ", response.data)
		// 		//var gdt = '[{	"lat": 28.367420000000003,	"lng": 76.90442},{	"lat": 28.379795,	"lng": 76.903625},{	"lat": 28.380361999999998,	"lng": 76.899445},{	"lat": 28.383162,	"lng": 76.90275},{	"lat": 28.384619,	"lng": 76.900024},{	"lat": 28.385069,	"lng": 76.900024},{	"lat": 28.384990000000002,	"lng": 76.8998}]'
		// 		this.setState({
		// 			googelRoutes: response.data.googleroute
		// 		})
		// 	})
		// 	.catch(function (e) {
		// 		console.log(e)
		// 	})
	}
	onShowCommentsDiv = async (params) => {
		// console.log(params);
		var reqparams = {
			consignment_code: params.data.consignment_code,
			truck_no: params.data.truck_no,
			dept_code: this.state.deptcode,
			screen: "consignments",
			type: "Force Close"
		}
		console.log(reqparams);

		let eventLabel = googleAnalytics.page.action.comments;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);

		var commentHeaders = [
			{
				headerName: "Truck No",
				field: "truck_no",
				resizable: true,
				width: 100,
				filter: true
			},
			{
				headerName: "GR No",
				field: "consignment_code",
				resizable: true,
				width: 120,
				filter: true
			},
			{
				headerName: "User Type",
				field: "user_type",
				width: 100,
				resizable: true,
				filter: true,
				valueGetter: function (params) {
					if (params.data.user_type == "ORGUSER") {
						return "MSIL"
					}
					if (params.data.user_type == "DCTUSER") {
						return "DCT"
					}
					if (params.data.user_type == "TRANSPORTER") {
						return "TRANSPORTER"
					}
				}
			},
			{
				headerName: "Comment",
				field: "comment",
				width: 250,
				cellClass: ['wraptext'],
				resizable: true,
				filter: true
			},
			{
				headerName: "Commented By",
				field: "username",
				width: 150,
				resizable: true,
				filter: true
			},

			{
				headerName: "Commented Date",
				field: "comment_date",
				resizable: true,
				filter: true,
				width: 150,
				valueGetter: function (params) {
					if (params.data.comment_date != "") {
						return getDDMMYYYYHHMMSS(params.data.comment_date);
					}
				}
			}
		]
		if (params != 0) {
			var overspeedData = [];

			//console.log("Step 3 ",overspeedData)
			var propsdata = params;
			var deptcode = this.state.deptcode;
			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: commentHeaders,
						overlayNoRowsTemplate: 'No rows to show',

					},
					getDetailRowData: async function (param) {
						param.successCallback([]);
						// console.log("Step 4 ", param)
						// console.log("reqparams", reqparams);
						await redirectURL.post("/consignments/getCommentLogs", {
							consignment_code: param.data.consignment_code,
							truck_no: param.data.truck_no,
							dept_code: deptcode,
							screen: "consignments",
							type: "Force Close"
						}).then(async (response) => {
							//console.log("Step 1 ",response.data)
							// console.log(response.data.data);
							var comments = response.data.data;
							param.successCallback(comments);


							//console.log("Step 2 ",response.data)

						}).catch(function (error) {
							console.log(error);
						})

					},
					masterDetail: true
				}
			});
			// console.log(propsdata);
			if (params.column.colDef.field == 'comment_exists') {

				params.node.setExpanded(!params.node.expanded);
			}
			else {

				params.node.setExpanded(false);
			}

		}
		else {
			this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: commentHeaders

					},
					getDetailRowData: function (params) {
						//console.log("Child ", params);
						params.successCallback([]);
					}
				}
			});
		}
	}
	onShowRouteDiv = (e) => {
		if (googleAnalytics.page.enableGA) {
			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}

		//console.log("IN onShowRouteDiv, Rute params ", e)
		this.setState({
			loadshow: 'show-m',
			maptruckno: e.truck_no
		});

		// If gateouttime is current time - It shows "No Route data available"
		if (e.invoice_time) {
			// console.log("came into invoice time", e.invoice_time)
			//console.log("Step1", e.invoice_time)
			var gateouttime = moment.parseZone(e.invoice_time).format('YYYY-MM-DD HH:mm:ss');
		}
		else {
			//console.log("Step2")
			if (e.gate_in_time) {
				// console.log("came into gate in time", e.gate_in_time)
				//console.log("Step3")
				var gateouttime = moment.parseZone(e.gate_in_time).format('YYYY-MM-DD HH:mm:ss');
			}
			else {
				//console.log("Step4")
				if (e.gate_out_time) {
					//console.log("Step5")
					// console.log("came into gate out time", e.gate_out_time)
					var gateouttime = moment.parseZone(e.gate_out_time).format('YYYY-MM-DD HH:mm:ss');
				}
			}
		}

		// if (e.gate_out_time) {
		// 	var gateouttime = e.gate_out_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		// }

		var edate = '';
		var end_date;
		if (e.status <= 2) {
			//var ms = new Date(e.gate_out_time).getTime() + ((e.transit_time+1) * 86400000);
			//var edatesecond = new Date(ms);
			// console.log("status less than 2");
			end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
			edate = getHyphenDDMMYYYYHHMMSS(end_date);
		}
		else if (e.status == 3 || e.status == 4) {
			if (e.recent_dealer_reported) {
				// end_date = e.recent_dealer_reported.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
				edate = getHyphenDDMMYYYYHHMMSS(e.recent_dealer_reported);
			}
			// console.log("status 3 and 4");
		}
		else if (e.status == 5) {
			if (e.left_dealer_marked) {
				// end_date = e.left_dealer_marked.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
				edate = getHyphenDDMMYYYYHHMMSS(e.left_dealer_marked);
			}
			// console.log("status 5");
			console.log()
		}
		else if (e.status == 6) {
			if (e.force_closure_time) {
				let splitT = e.force_closure_time.split('T')
				let yMD = splitT[0]
				let hMS = splitT[1]
				let hms1 = hMS.split('.')
				// console.log('hms1',hms1)
				let finalHms = hms1[0]
				let final_end_date = yMD + ' ' + finalHms
				// end_date = e.force_closure_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
				end_date = final_end_date;
				// edate = getHyphenDDMMYYYYHHMMSS(e.force_closure_time);
				edate = final_end_date
			}
			// console.log("status 6");
		}
		else {
			end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
			edate = getHyphenDDMMYYYYHHMMSS(end_date);
			// console.log("in else condition");
		}

		// console.log("end date",end_date);
		//console.log("checking e",e)

		if ((e.transit_time) > 0) {
			var seconddate = new Date(new Date(e.gate_out_time).getTime() + ((e.transit_time + 1) * 24 * 60 * 60 * 1000));
		}
		else {
			var seconddate = new Date(new Date(e.gate_out_time).getTime() + 2 * 24 * 60 * 60 * 1000);
		}

		var sdate = seconddate.getFullYear() + "-" + (seconddate.getMonth() + 1) + "-" + seconddate.getDate() + " " + seconddate.getHours() + ":" + seconddate.getMinutes() + ":" + seconddate.getSeconds();

		if (!end_date) {
			// When end date is unavailable then current date is considered as end date.
			end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
			//console.log("Defining end_date ", end_date);
		}
		var reqData = {
			truck_no: e.truck_no,
			consignment_code: e.invoice_no,
			consignee_code: e.consignee_code,
			gate_out_time: gateouttime,
			seconddate: end_date,
			screen: "consignment"
		}
		if (["2", "3", "4"].includes(e.status)) reqData.mapMode = 2

		// console.log("reqData: ", reqData);
		redirectURL.post('/consignments/maproutes', reqData, {
			headers: {
				'content-type': 'application/json'
			}
		})
			.then((response) => {
				var records = response.data;
				// console.log("Props data ", records)

				this.setState({
					loadshow: 'show-m'
				});
				try {
					if (records.coords.length == 0 || records.status == "failure") {
						//console.log("records", records.coords);
						this.setState({
							show: true,
							basicTitle: 'No Route data available',
							basicType: "danger",
							loadshow: 'show-n'
						});
					}
					else {
						if (records != '') {
							var sdate = e.gate_out_time;
							if (e.invoice_time) {
								// console.log("came into invoice time", e.invoice_time)
								//console.log("Step1", e.invoice_time)
								var sdate = e.invoice_time;
							}
							else {
								//console.log("Step2")
								if (e.gate_in_time) {
									// console.log("came into gate in time", e.gate_in_time)
									//console.log("Step3")
									var sdate = e.gate_in_time;
								}
								else {
									//console.log("Step4")
									if (e.gate_out_time) {
										//console.log("Step5")
										// console.log("came into gate out time", e.gate_out_time)
										var sdate = e.gate_out_time;
									}
								}
							}


							var edate = '';
							if (e.status <= 4) {
								//var ms = new Date(e.gate_out_time).getTime() + ((e.transit_time+1)*86400000);
								//var edatesecond = new Date(ms);
								end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
								edate = getHyphenDDMMYYYYHHMMSS(end_date);
								// console.log("End Date status4 ", edate)

							}
							else if (e.status == 4) {
								// console.log("end_time", e)
								edate = getHyphenDDMMYYYYHHMMSS(e.end_time);
							}
							else if (e.status == 5) {
								if (e.left_dealer_marked) {
									edate = getHyphenDDMMYYYYHHMMSS(e.left_dealer_marked);
									// console.log("End Date if one ", edate)

								}
								else {
									end_date = moment.parseZone().format('YYYY-MM-DD HH:mm:ss')
									edate = getHyphenDDMMYYYYHHMMSS(end_date);
									// console.log("End Date else one", edate)
								}
							}
							// console.log("End Date ", edate)
							if (e.deviation_flag == 1 && this.state.deptcode == "LOG-PRT") {
								var params = {
									"base_route_id": e.base_route_id
								}
								redirectURL.post("/consignments/getLiveRouteDeviationBaseRoute",
									params).then((resp) => {
										this.setState({
											sliderRouteTranslate: "slider-translate-60p",
											showDiv: 'show-m',
											mapinfo: records,
											dealer: e.consignee_code,
											consignment_code: "Invoice No : " + e.invoice_no,
											maptruckno: e.truck_no,
											routeTruck: { "truck_no": e.truck_no, "startTime": sdate, "endTime": end_date },
											loadshow: 'show-n',
											sidebarSubHeader: "Consignment Information",
											overly: 'show-m',
											rownode: e,
											leg_no: 0,
											tolls: records.tolls,
											baseRouteCoords: resp.data.alteredHeroPRTBaseRoute,
											devation_flag: true
										});
										this.onShowGoogleRoute(e.invoice_no, e.consignee_code, 0, e.truck_no)
										this.renderMap();
									})
							}
							else {
								this.setState({
									sliderRouteTranslate: "slider-translate-60p",
									showDiv: 'show-m',
									mapinfo: records,
									dealer: e.consignee_code,
									consignment_code: "Invoice No : " + e.invoice_no,
									maptruckno: e.truck_no,
									routeTruck: { "truck_no": e.truck_no, "startTime": sdate, "endTime": end_date },
									loadshow: 'show-n',
									sidebarSubHeader: "Consignment Information",
									overly: 'show-m',
									rownode: e,
									leg_no: 0,
									tolls: records.tolls
								});
								this.onShowGoogleRoute(e.invoice_no, e.consignee_code, 0, e.truck_no)
								this.renderMap();
							}
						}
					}
				}
				catch (e) {
					this.setState({
						show: true,
						basicTitle: 'No Route data available',
						basicType: "danger",
						loadshow: 'show-n'
					});
				}

			})
			.catch(function (error) {
				console.log(error);
			});
	}
	onCloseRouteDiv = () => {
		this.setState({
			uploadDivWidth: '0%',
			sliderRouteTranslate: "",
			sliderCommentTranslate: "",
			sliderSegmentTranslate: "",
			showDiv: 'show-n',
			uploadFile: '',
			file: '',
			csvmessage: '',
			csverrmessage: '',
			overly: 'show-n',
			devation_flag: false
		});
	}



	onGridReady = params => {
		this.gridApi = params.api;

		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;

		//    ChartMenu.prototype.saveChart = function (e) {
		// 	   var event = {
		// 	      data: this.gridOptionsWrapper.getApi(),
		// 	      type: ChartMenu.EVENT_DOWNLOAD_CHART
		// 	    };
		// 	   //console.log("Testing", event);
		// 	  // console.log("hart Cell Ranges : ", event.data.chartService.rangeController.cellRanges)
		// 	   this.dispatchEvent(event);
		// 	   var chrtdata = event.data.chartService.rangeController.cellRanges;

		//    };
		if (this.state.deptcode == 'LOG-PRT') {
			// console.log("Step 1 ")
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception", "loading_delay_exception", "unloading_delay_exception", "trip_type"], false);

			this.gridColumnApi.setColumnsVisible(["trip_type"], false);
		}
		else if (this.state.deptcode == 'SNDG') {
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], true);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], true);
			//this.gridColumnApi.setColumnsVisible(["unloading_delay_exception"], true);
			this.gridColumnApi.setColumnsVisible(["consignee_city"], true);
			this.gridColumnApi.setColumnsVisible(["trip_type"], false);
		}
		else if (this.state.deptcode == 'LOG-TNP') {
			this.gridColumnApi.setColumnsVisible(["nightdriving_exception"], true);
			this.gridColumnApi.setColumnsVisible(["loading_delay_exception"], true);
			//this.gridColumnApi.setColumnsVisible(["unloading_delay_exception"], true);
			this.gridColumnApi.setColumnsVisible(["consignee_city"], true);
			this.gridColumnApi.setColumnsVisible(["trip_type"], true);
		}
		else if ((this.props.match.path == "/sndconsignments")
			|| (this.props.match.path == "/prtconsignments")
			|| (this.props.match.path == "/tnpconsignments")) {
			this.gridColumnApi.setColumnsVisible(["unloading_delay_exception"], false);

		}
		else { }



	};


	onGridState = () => {
		//console.log(this.gridApi);

		/*Get  Current Columns State and Store in this.colState */
		this.colState = this.gridColumnApi.getColumnState();

		/*Get Current RowGroup Columns State and Store in this.rowGroupState */
		this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		/*Get Current Pivot Columns State and Store in this.pivotColumns,
		 * User should enable the Pivot mode.*/
		this.pivotColumns = this.gridColumnApi.getPivotColumns();

		/*Get Current Filter State and Store in window.filterState */
		window.filterState = this.gridApi.getFilterModel();
		this.gridApi.setFilterModel(window.filterState);



	}

	renderMap = () => {
		//loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyA9LP10EYr__tHTyPWXJCTrIdBBeSrdjTY&libraries=places,drawing&callback=initMap");
		//window.initMap = this.initMap
	}

	initMap = () => {
		//console.log("mapinfo ",this.state.mapinfo);
		var currentwindow;

		var jsondata = this.state.mapinfo;
		var locations = jsondata.breaks;
		var arr = jsondata.coords;


		//console.log("Here",arr);
		if (arr.length > 0) {
			var lt = arr[0].lat;
			var ln = arr[0].lng;
		}
		else {
			if (this.state.defTransitCoords == '') {
				var lt = 28.4519751;
				var ln = 77.0310713;
			}
			else {
				var lt = JSON.parse(this.state.defTransitCoords).lat;
				var ln = JSON.parse(this.state.defTransitCoords).lng;
			}

		}
		var routeinfo = jsondata.route_details;
		var mapOptions = {
			zoom: 7,
			zoomControl: true,
			mapTypeControl: true,
			scaleControl: true,
			streetViewControl: true,
			rotateControl: true,
			fullscreenControl: true,
			labels: true,
			mapTypeControlOptions: {
				mapTypeIds: ['hybrid', 'roadmap'],
			},
			center: new window.google.maps.LatLng(lt, ln),
			mapTypeId: window.google.maps.MapTypeId.ROADMAP
		};

		var map = new window.google.maps.Map(document.getElementById('map'),
			mapOptions);

		var bounds = new window.google.maps.LatLngBounds();
		bounds.extend(new window.google.maps.LatLng(lt, ln));
		// Create our info window content
		var currentinfowindow = null;
		var line = new window.google.maps.Polyline(
			{
				map: map,
				strokeColor: '#157254',
				strokeOpacity: 1.0,
				strokeWeight: 2.5,
				icons: [{
					icon: {
						path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
						strokeColor: '#ff8c52',
						fillColor: '#ff8c52',
						fillOpacity: 1,
						strokeWeight: 2
					},
					repeat: '100px',
					path: []
				}]
			});

		if (arr.length > 0) {
			for (let i = 0; i < arr.length; i++) {
				var path = line.getPath().getArray();

				let latLng = new window.google.maps.LatLng(arr[i].lat, arr[i].lng);
				path.push(latLng);
				line.setPath(path);




				//Change line color based on map type
				window.google.maps.event.addListener(map, 'maptypeid_changed', function () {
					if (map.getMapTypeId() == 'hybrid' || map.getMapTypeId() == 'satellite') {
						var line = new window.google.maps.Polyline(
							{
								map: map,
								strokeColor: '#FFFFFF',
								strokeOpacity: 1.0,
								strokeWeight: 2.5,
								icons: [{
									icon: {
										path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
										strokeColor: '#ff8c52',
										fillColor: '#ff8c52',
										fillOpacity: 1,
										strokeWeight: 2
									},
									repeat: '100px',
									path: []
								}]
							});
					}
					else {
						var line = new window.google.maps.Polyline(
							{
								map: map,
								strokeColor: '#157254',
								strokeOpacity: 1.0,
								strokeWeight: 2.5,
								icons: [{
									icon: {
										path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
										strokeColor: '#ff8c52',
										fillColor: '#ff8c52',
										fillOpacity: 1,
										strokeWeight: 2
									},
									repeat: '100px',
									path: []
								}]
							});

					}

					for (i = 0; i < arr.length; i++) {
						var path = line.getPath().getArray();
						let latLng = new window.google.maps.LatLng(arr[i].lat, arr[i].lng);
						path.push(latLng);
						line.setPath(path);
						//map.setCenter(latLng);
					}

					var x = map.getZoom();
					var c = map.getCenter();
					window.google.maps.event.trigger(map, 'resize');
					map.setZoom(x);
					map.setCenter(c);
				});

				bounds.extend(new window.google.maps.LatLng(arr[i].lat, arr[i].lng));
			}

			var infowindow = new window.google.maps.InfoWindow();
			var marker, l;

			for (l = 0; l < locations.length; l++) {
				marker = new window.google.maps.Marker({
					position: new window.google.maps.LatLng(locations[l].lat, locations[l].lng),
					icon: require('../../assets/icons/cf.png'),
					map: map,
				});

				window.google.maps.event.addListener(marker, 'click', (function (marker, l) {
					return function () {
						//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Break - "+(l+1)+"</B></p><p>Break time "+locations[l].break_start+" - "+locations[l].break_end+" ("+timeConvert(locations[l].break_time_seconds)+")</p>";
						var contentarr = []
						var header = "Break - " + (l + 1)
						contentarr.push({ "key": "Start time", "value": getDDMMYYYYHHMMSS(locations[l].break_start) })
						contentarr.push({ "key": "End time", "value": getDDMMYYYYHHMMSS(locations[l].break_end) + " (" + secondsToString(locations[l].break_time_seconds) + ")" })
						var contentString = infoBox(marker.icon, header, contentarr)
						infowindow.setContent(contentString);
						currentwindow = infowindow;
						infowindow.open(map, marker);
						//console.log(marker.position.toJSON());
						//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
					}
				})(marker, l));
			}
			marker = new window.google.maps.Marker({
				position: new window.google.maps.LatLng(arr[0].lat, arr[0].lng),
				icon: require('../../assets/icons/track_start.png'),
				map: map,
			});
			window.google.maps.event.addListener(marker, 'click', (function (marker) {
				return function () {
					//	console.log(clusters[0])
					var contentarr = []
					//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> Starting Point</B></p><p>Started at "+routeinfo.start_time+"</p>";
					var header = "Starting Point"
					contentarr.push({ "key": "Started at ", "value": getDDMMYYYYHHMMSS(routeinfo.start_time) })
					var contentString = infoBox(marker.icon, header, contentarr, '')


					infowindow.setContent(contentString);
					currentwindow = infowindow;
					infowindow.open(map, marker);
					//console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
				}
			})(marker));
			marker = new window.google.maps.Marker({
				position: new window.google.maps.LatLng(arr[(arr.length - 1)].lat, arr[(arr.length - 1)].lng),
				icon: require('../../assets/icons/truck-end.png'),
				map: map,
			});
			window.google.maps.event.addListener(marker, 'click', (function (marker) {
				return function () {
					//	console.log(clusters[0])
					var contentarr = []
					//var contentString = "<p style='color:#1F749B'><img src="+marker.icon+">"+"<B> End Point</B></p><p>End at "+routeinfo.end_time+"</p>";
					var header = "End Point"
					contentarr.push({ "key": "End at ", "value": getDDMMYYYYHHMMSS(routeinfo.end_time) })
					var contentString = infoBox(marker.icon, header, contentarr)

					infowindow.setContent(contentString);
					currentwindow = infowindow;
					infowindow.open(map, marker);
					// console.log('{"lat":'+marker.position.lat() + ',"lng":' +marker.position.lng() +'}');
				}
			})(marker));
			if (this.state.tolls.length > 0) {
				var infowindow = new window.google.maps.InfoWindow();
				var l;
				this.state.tolls.map(function (e, index) {
					var tollMarker = new window.google.maps.Marker({
						position: new window.google.maps.LatLng(e.lat, e.lon),
						icon: require('../../assets/icons/barrier.png'),
						map: map,
					});
					window.google.maps.event.addListener(tollMarker, 'mouseover', (function (tollMarker, index) {
						return function () {
							var contentarr = []
							var header = "Toll - " + e.name
							contentarr.push({ "key": "Address", "value": e.location })
							var contentString = infoBox(tollMarker.icon, header, contentarr)
							infowindow.setContent(contentString);
							var currentwindow = infowindow;
							infowindow.open(map, tollMarker);
						}
					})(tollMarker, index));
					window.google.maps.event.addListener(tollMarker, 'mouseout', function () {
						infowindow.close();
					});
				})

			}
		}
		map.fitBounds(bounds)
	}



	onShowOverspeedKpi = async (params) => {
		let eventLabel = googleAnalytics.page.action.overspeed;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		//console.log("IN onShowOverspeedKpi, params = ",params);
		//console.log(params.column.colId);
		if (params != 0) {
			var overspeedData = [];

			//console.log("Step 3 ",overspeedData)
			var propsdata = params;
			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{
								headerName: "", field: "_id",
								width: 50,
								cellRendererFramework: RoutemapAction,
								resizable: true
							},
							{ headerName: "Truck No", field: "truck_no", filter: true, resizable: true },
							{ headerName: "Speed (km/h)", field: "speed", filter: true, resizable: true },
							// {
							// 	headerName: "Reported At", field: "first_instance", filter: true, resizable: true,
							// 	valueGetter: function (params) {
							// 		return getDDMMYYYYHHMMSS(params.data.first_instance);
							// 	}
							// },

							{
								headerName: "Reported From Date", field: "first_instance", filter: true, resizable: true,
								valueGetter: function (params) {
									// return getDDMMYYYYHHMMSS(params.data.first_instance);
									try {
										if (params.data.first_instance != "" && params.data.first_instance != undefined) {
											return getDDMMYYYYHHMMSS(params.data.first_instance);
										}
										else {
											return "";
										}
									}
									catch (e) { }
								}
							},

							{
								headerName: "Reported End Date", field: "recent_instance", filter: true, resizable: true,
								valueGetter: function (params) {
									try {
										if (params.data.recent_instance != "" && params.data.recent_instance != undefined) {
											return getDDMMYYYYHHMMSS(params.data.recent_instance);
										}
										else {
											return "";
										}
									}
									catch (e) { }

								}
							},
						],
						overlayNoRowsTemplate: 'No rows to show',

					},
					getDetailRowData: function (param) {
						// console.log("Step 4 ", param)
						redirectURL.post('/consignments/overspeed', {
							consignment_code: param.data.consignment_code,
							truck_no: param.data.truck_no
						})
							.then(async (response) => {
								//console.log("Step 1 ",response.data)
								overspeedData = response.data;
								param.successCallback(overspeedData);
								//console.log("Step 2 ",response.data)

							});

					},
					masterDetail: true
				}
			});

			if (propsdata.column.colDef.field == 'overspeeding_exception') {

				propsdata.node.setExpanded(!propsdata.node.expanded);
			}
			else {

				propsdata.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {
			this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Truck No", field: "truck_no" },
							{ headerName: "Speed", field: "speed" },
							{ headerName: "Reported At", field: "first_instance" }
						]

					},
					getDetailRowData: function (params) {
						//console.log("Child ", params);
						params.successCallback([]);
					}
				}
			});
		}

	}

	onShowTransitKpi = async (params) => {
		console.log("params", params)
		if (params != 0) {
			let eventLabel = googleAnalytics.page.action.transitDelay;
			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventAction,
				"label": eventLabel,
			}
			googleAnalytics.logEvent(eventOptions);
			//console.log("IN onShowTransitKpi, params = ",params);
			//console.log(params);
			//console.log("Consign onShowTransitKpi params = ",params);
			//console.log(params.column.colId);
			if (params != 0) {
				var transitedelaydata = [];

				await this.setState({

					detailCellRendererParams: {
						suppressRefresh: true,
						detailGridOptions: {
							headerHeight: 50,
							columnDefs: [
								{
									headerName: "", field: "_id",

									cellRendererFramework: RoutemapTransitAction,
									width: 50,
									suppressSizeToFit: true,
									cellClass: function (params) {
										if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
											return 'green';
										}
										else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
											return 'lightred';
										}
										else {
											return '';
										}

									}
								},
								{
									headerName: "Truck No", field: "truck_no", width: 100, resizable: true,
									cellClass: function (params) {
										if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
											return 'green';
										}
										else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
											return 'lightred';
										}
										else {
											return '';
										}

									}
								},
								{
									headerName: "Leg Start Time", field: "leg_start", width: 120, resizable: true,
									valueGetter: function (params) {
										return getDDMMYYYYHHMMSS(params.data.leg_start);
									},
									cellClass: function (params) {
										if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
											return 'green';
										}
										else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
											return 'lightred';
										}
										else {
											return '';
										}

									}
								},
								{
									headerName: "Leg End Time", field: "leg_end_eta",
									width: 120, resizable: true,
									valueGetter: function (params) {
										return getDDMMYYYYHHMMSS(params.data.leg_end_eta);
									},
									cellClass: function (params) {
										if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
											return 'green';
										}
										else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
											return 'lightred';
										}
										else {
											return '';
										}

									}
								},
								{
									headerName: "Exp. Leg Distance (KM)",
									field: "leg_expected_distance", width: 140, resizable: true,
									valueGetter: function (params) {
										return Math.round(params.data.leg_expected_distance);
									},
									cellClass: function (params) {
										if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
											return 'green';
										}
										else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
											return 'lightred';
										}
										else {
											return '';
										}

									}
								},
								{
									headerName: "Actual Leg Distance Covered (KM)",
									field: "actual_leg_distance", width: 140, resizable: true,
									valueGetter: function (params) {
										//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
										if (params.data.actual_leg_distance != '' && params.data.actual_leg_distance != undefined) {
											return Math.round(params.data.actual_leg_distance);
										}
										else {
											return 0;
										}

									},
									cellClass: function (params) {
										if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
											return 'green';
										}
										else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
											return 'lightred';
										}
										else {
											return '';
										}

									}
								},
								{
									headerName: "Exp. Distance from Trip Start(KM)",
									field: "expected_distance_start_to_leg", width: 140, resizable: true,
									valueGetter: function (params) {
										//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
										if (params.data.expected_distance_start_to_leg != '' && params.data.expected_distance_start_to_leg != undefined) {
											return Math.round(params.data.expected_distance_start_to_leg);
										}
										else {
											return 0;
										}

									},
									cellClass: function (params) {
										if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
											return 'green';
										}
										else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
											return 'lightred';
										}
										else {
											return '';
										}

									}

								},
								{
									headerName: "Actual Distance from Trip Start (KM)",
									field: "actual_distance_traveled_start_to_leg", width: 140, resizable: true,
									valueGetter: function (params) {
										//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
										if (params.data.actual_distance_traveled_start_to_leg != '' && params.data.actual_distance_traveled_start_to_leg != undefined) {
											return Math.round(params.data.actual_distance_traveled_start_to_leg);
										}
										else {
											return 0;
										}

									},
									cellClass: function (params) {
										if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
											return 'green';
										}
										else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
											return 'lightred';
										}
										else {
											return '';
										}

									}


								},

								{
									headerName: "Google Distance from Start (KM)",
									field: "actual_start_to_leg_google_distance",
									width: 140,
									resizable: true,
									cellClass: function (params) {
										if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
											return 'green';
										}
										else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
											return 'lightred';
										}
										else {
											return '';
										}

									}


								},
								{
									headerName: "GPS Data Available",
									field: "no_gps_data",
									width: 140,
									resizable: true,
									valueGetter: function (params) {
										//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
										if (params.data.no_gps_data == 1) {
											return "Not Available";
										}
										if (params.data.no_gps_data == 0) {
											return "Available";
										}

									},
									cellClass: function (params) {
										if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
											return 'green';
										}
										else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
											return 'lightred';
										}
										else {
											return '';
										}

									}


								},


								{
									headerName: "Trip Completed",
									field: "trip_completed",
									width: 140,
									resizable: true,
									valueGetter: function (params) {
										//console.log("Actual Leg Dist ", params.data.actual_leg_distance);
										if (params.data.trip_completed == 1) {
											return "Yes";
										}
										if (params.data.trip_completed == 2) {
											return "";
										}
										if (params.data.trip_completed == 0) {
											return "";
										}

									},
									cellClass: function (params) {
										if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
											return 'green';
										}
										else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
											return 'lightred';
										}
										else {
											return '';
										}

									}


								}
							],
							overlayNoRowsTemplate: 'No rows to show',
						},
						getDetailRowData: function (params) {
							console.log("Step 4 ",transitedelaydata)
							console.log("Step 4 ",params)
							redirectURL.post('/consignments/transitdelay', {
								consignment_code: params.data.consignment_code,
								consignee_code: params.data.consignee_code,
								truck_no: params.data.truck_no
							})
								.then(async (response) => {
									console.log("Step 1 ",response.data)
									transitedelaydata = response.data;
									var legsarr = []
									transitedelaydata.map((item) => {
										//if(item.trip_completed < 2)
										//{
										legsarr.push(item)
										//}
									})
									// console.log('transitedelaydata ', transitedelaydata);

									params.successCallback(legsarr);
									//console.log("Step 2 ",response.data)

								});

						}
					}
				});

				//if( params.column.colId == 'transit_delay' || params.column.colId == 'transit_delay_1' ||   params.column.colId == 'no_gps_data' || params.column.colId == 'no_gps_data_1')
				if (params.column.colDef.field == 'transit_delay' || params.column.colDef.field == 'no_gps_data') {
					params.node.setExpanded(!params.node.expanded);
					//console.log("expanded ",params.node.expanded)
					if (params.node.expanded == false) {
						transitedelaydata = []
					}
				}
				else {

					params.node.setExpanded(false);
				}
				//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

			}
			else {
				this.setState({

					detailCellRendererParams: {
						detailGridOptions: {
							columnDefs: [
								{ headerName: "Truck No", field: "truck_no" },
								{ headerName: "Speed (km/h)", field: "speed" },
								{ headerName: "Reported At", field: "first_instance" }
							]

						},
						getDetailRowData: function (params) {
							//console.log("Child ", params);
							params.successCallback([]);
						}
					}
				});
			}
		}
		else {
			this.setState({
				show: true,
				basicTitle: "Transit for this consignment has not yet started",
				basicType: "info"
			});
		}

	}

	/*NO GPS DATA*/


	onShowNoGPSKpi = async (params) => {
		let eventLabel = googleAnalytics.page.action.gpsAvailable;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		//console.log("IN onShowNoGPSKpi, params = ",params);
		//console.log(params);
		//console.log("Consign onShowNoGPSKpi params = ",params);
		//console.log(params.column.colId);
		if (params != 0) {
			var transitedelaydata = [];

			await this.setState({

				detailCellRendererParams: {
					suppressRefresh: true,
					detailGridOptions: {
						columnDefs: [
							{
								headerName: "",
								field: "_id",
								cellRendererFramework: RoutemapNoGPSAction,
								width: 50,
								suppressSizeToFit: true,
								cellClass: function (params) {
									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
										return 'green';
									}
									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							},

							{
								headerName: "Truck No", field: "truck_no", width: 100, resizable: true,
								cellClass: function (params) {
									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
										return 'green';
									}
									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							},
							{
								headerName: "Last Data Receieved",
								field: "last_packet_datetime",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									return getDDMMYYYYHHMMSS(params.data.last_packet_datetime);
								},
								cellClass: function (params) {
									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
										return 'green';
									}
									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							},
							{
								headerName: "Data Received on",
								field: "data_received_on",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									return getDDMMYYYYHHMMSS(params.data.packet_received_on);
								},
								cellClass: function (params) {
									if (params.data.leg_transit_delay == 0 && params.data.processed == 1) {
										return 'green';
									}
									else if (params.data.processed == 1 && params.data.leg_transit_delay == 1) {
										return 'lightred';
									}
									else {
										return '';
									}

								}
							}
						],
						overlayNoRowsTemplate: 'No rows to show',
					},
					getDetailRowData: function (params) {
						//console.log("Step 4 ",transitedelaydata)
						//console.log("Step 4 ",params)
						redirectURL.post('/consignments/nogpsdata', {
							consignment_code: params.data.consignment_code,
							truck_no: params.data.truck_no
						})
							.then(async (response) => {
								//console.log("Step 1 ",response.data)
								transitedelaydata = response.data;
								var legsarr = []
								transitedelaydata.map((item) => {
									//if(item.trip_completed < 2)
									//{
									legsarr.push(item)
									//}
								})
								// console.log('transitedelaydata ', transitedelaydata);
								params.successCallback(legsarr);
								//console.log("Step 2 ",response.data)

							});

					}
				}
			});

			//if( params.column.colId == 'transit_delay' || params.column.colId == 'transit_delay_1' ||   params.column.colId == 'no_gps_data' || params.column.colId == 'no_gps_data_1')
			if (params.column.colDef.field == 'transit_delay' || params.column.colDef.field == 'no_gps_data') {
				params.node.setExpanded(!params.node.expanded);
				//console.log("expanded ",params.node.expanded)
				if (params.node.expanded == false) {
					transitedelaydata = []
				}
			}
			else {

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {
			this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Truck No", field: "truck_no" },
							{ headerName: "Speed (km/h)", field: "speed" },
							{ headerName: "Reported At", field: "first_instance" }
						]

					},
					getDetailRowData: function (params) {
						//console.log("Child ", params);
						params.successCallback([]);
					}
				}
			});
		}

	}
	onClickShowProbableData = async (params) => {
		let eventLabel = googleAnalytics.page.action.nightDrive;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		// console.log("IN probable data, params = ", params);
		var probableData = [];
		await this.setState({

			detailCellRendererParams: {
				detailGridOptions: {
					columnDefs: [
						{ headerName: "Truck No", field: "truck_no", width: 150, resizable: true },
						{ headerName: "GPS Provider", field: "actual_lspuser", width: 180, resizable: true, },
						{
							headerName: "Event Time", field: "timestamp", resizable: true,
							valueGetter: function (params) {
								return getHyphenDDMMMYYYYHHMM(params.data.timestamp);
							}
						},
						{
							headerName: "Event", field: "", width: 180, resizable: true,
							width: 220,
							valueGetter: function (params) {
								return "Harsh Braking / Probable Accident"
							}
						},
					],
					overlayNoRowsTemplate: 'No rows to show',
				},
				getDetailRowData: function (params) {
					//console.log("Step 4 ",overspeedData)
					redirectURL.post('/consignments/probableAccidentsData', {
						consignment_code: params.data.consignment_code,
					})
						.then(async (response) => {
							// console.log("Step 1 ", response.data)
							probableData = response.data;
							params.successCallback(probableData);
							//console.log("Step 2 ",response.data)

						});

				}

			}
		});
		//console.log("Parmas ", params)
		if (params.column.colDef.field == 'probable_accident') {

			params.node.setExpanded(!params.node.expanded);
		}
		else {

			params.node.setExpanded(false);
		}

	}

	onShowNightDriveKpi = async (params) => {
		let eventLabel = googleAnalytics.page.action.nightDrive;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		//console.log("IN onShowNightDriveKpi, params = ",params);
		//console.log(e);
		//console.log("Consign Overspeed ",params);

		if (params != 0) {
			var overspeedData = [];


			//console.log("Step 3 ",overspeedData)

			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{
								headerName: "", field: "_id",

								cellRendererFramework: RouteNightDrivemap,
								width: 50,
								suppressSizeToFit: true
							},
							{ headerName: "Truck No", field: "truck_no", width: 150, resizable: true },
							{
								headerName: "Traveled From", field: "first_instance", width: 180, resizable: true,
								valueGetter: function (params) {
									return getDDMMYYYYHHMMSS(params.data.first_instance);
								}
							},
							{
								headerName: "Traveled Until", field: "recent_instance", resizable: true,
								valueGetter: function (params) {
									return getDDMMYYYYHHMMSS(params.data.recent_instance);
								}
							},
							{
								headerName: "Distance Traveled (KM)", field: "distance_from_first_instance", width: 180,
								resizable: true,
								valueGetter: function (params) {
									return Math.round(params.data.distance_from_first_instance)
								}
							}
						],
						overlayNoRowsTemplate: 'No rows to show',
					},
					getDetailRowData: function (params) {
						//console.log("Step 4 ",overspeedData)
						redirectURL.post('/consignments/nightdriving', {
							consignment_code: params.data.consignment_code,
							gate_out_time: params.data.gate_out_time,
							truck_no: params.data.truck_no
						})
							.then(async (response) => {
								//console.log("Step 1 ",response.data)
								overspeedData = response.data;
								params.successCallback(overspeedData);
								//console.log("Step 2 ",response.data)

							});

					}

				}
			});

			//if(params.column.colId == 'nightdriving_exception')
			if (params.column.colDef.field == 'nightdriving_exception') {

				params.node.setExpanded(!params.node.expanded);
			}
			else {

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {
			this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Truck No", field: "truck_no" },
							{ headerName: "Speed (km/h)", field: "speed" },
							{ headerName: "Reported At", field: "first_instance" }
						]

					},
					getDetailRowData: function (params) {
						//console.log("Child ", params);
						params.successCallback([]);
					}
				}
			});
		}

	}

	onShowDayWiseDistanceTrip = async (params) => {
		// console.log("params", params)
		// let eventLabel = googleAnalytics.page.action.nightDrive;
		// let eventOptions = {
		// 	"category": this.state.pagetitle,
		// 	"action": this.state.eventAction,
		// 	"label": eventLabel,
		// }
		// googleAnalytics.logEvent(eventOptions);
		if (params != 0) {
			var transitedelaydata = [];

			await this.setState({

				detailCellRendererParams: {
					suppressRefresh: true,
					detailGridOptions: {
						columnDefs: [
							{
								headerName: "",
								field: "_id",
								cellRendererFramework: RouteMapForDayWiseDistanceTrip,
								width: 50,
								suppressSizeToFit: true,
								// cellClass:function(params){
								// 	if(params.data.leg_transit_delay == 0 && params.data.processed == 1)
								// 	{
								// 		return 'green';
								// 	}
								// 	else if(params.data.processed == 1 && params.data.leg_transit_delay == 1)
								// 	{
								// 		return 'lightred';
								// 	}
								// 	else{
								// 		return '';
								// 	}
								// }
							},

							{
								headerName: "Truck No",
								field: "truck_no",
								width: 100,
								resizable: true,
							},
							{
								headerName: "Distance Travelled (KM)",
								field: "distance_travelled_km",
								width: 180,
								resizable: true,
							},
							{
								headerName: "Day",
								field: "day",
								width: 180,
								resizable: true,
							},
							{
								headerName: "Start time",
								field: "start_time",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									return getDDMMYYYYHHMMSS(params.data.start_time);
								}
							},
							{
								headerName: "End time",
								field: "end_time",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									return getDDMMYYYYHHMMSS(params.data.end_time);
								},
							}
						],
						overlayNoRowsTemplate: 'No rows to show',
					},
					getDetailRowData: function (params) {
						//console.log("Step 4 ",transitedelaydata)
						//console.log("Step 4 ",params)
						redirectURL.post('/consignments/getDayWiseDistanceTravelled', {
							invoice_nos: [params.data.invoice_no],
							invoice_time: getHyphenYYYYMMDDHHMMSS(params.data.invoice_time)
						})
							.then(async (response) => {
								// console.log("Step 1 ",response.data)
								transitedelaydata = response.data;
								var legsarr = []
								transitedelaydata.map((item) => {
									//if(item.trip_completed < 2)
									//{
									legsarr.push(item)
									//}
								})
								// console.log('transitedelaydata ', transitedelaydata);

								params.successCallback(legsarr);
								//console.log("Step 2 ",response.data)

							});

					}
				}
			});

			//if( params.column.colId == 'transit_delay' || params.column.colId == 'transit_delay_1' ||   params.column.colId == 'no_gps_data' || params.column.colId == 'no_gps_data_1')
			if (params.column.colDef.field == 'day_wise_distance_trip' || params.column.colDef.field == 'day_wise_distance_trip') {
				params.node.setExpanded(!params.node.expanded);
				//console.log("expanded ",params.node.expanded)
				if (params.node.expanded == false) {
					transitedelaydata = []
				}
			}
			else {
				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {
			// console.log("elseData")
			this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Truck No", field: "truck_no" },
							{ headerName: "Distance Travelled(Km)", field: "distance_travelled_km" },
							{ headerName: "Day", field: "day" },
							{ headerName: "Start Time", field: "start_time" },
							{ headerName: "End Time", field: "end_time" }
						]

					},
					getDetailRowData: function (params) {
						//console.log("Child ", params);
						params.successCallback([]);
					}
				}
			});
		}

	}


	onShowEnrouteStoppagesKpi = async (params) => {
		let eventLabel = googleAnalytics.page.action.enrouteStoppages;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		// console.log("IN onShowEnrouteStoppagesKpi, params = ",params);
		//console.log(e);
		//console.log("Consign Overspeed ",params);

		if (params != 0) {
			var overspeedData = [];


			//console.log("Step 3 ",overspeedData)

			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [

							{
								headerName: "", field: "_id",

								cellRendererFramework: RoutemapenrouteAction,
								width: 50,
								suppressSizeToFit: true,

							},
							{
								headerName: "Break Start",
								field: "stoppage_start_time",
								width: 150,
								resizable: true,
								valueGetter: function (params) {
									if (params.data.stoppage_start_time != undefined) {
										return getDDMMYYYYHHMMSS(params.data.stoppage_start_time)
									}
									else {
										return '';
									}

								}
							},
							// {
							// 	headerName: "Break End",
							// 	field: "reset_start_time",
							// 	width:180,
							// 	resizable: true,
							// 	valueGetter:function(params){
							// 		if(params.data.reset_start_time != undefined)
							// 		{
							// 			return getDDMMYYYYHHMMSS(params.data.reset_start_time)
							// 		}
							// 		else{
							// 			return '';
							// 		}

							// 	}
							// },

							{
								headerName: "Exception Closed On",
								field: "modified_date",
								width: 220,
								resizable: true,
								valueGetter: function (params) {
									if (params.data.exception_closed == 1) {

										if (params.data.modified_date != undefined) {
											return getDDMMYYYYHHMMSS(params.data.modified_date)
										}
										else {
											return '';
										}
									}
									else {
										return '';
									}

								}
							},
							{
								headerName: "Break Time",
								field: "elaped_time_from_last_moved_seconds",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									if (params.data.exception_closed == 1) {
										// if(params.data.elaped_time_from_last_moved_seconds != undefined)
										// {
										// 	var seconds = params.data.elaped_time_from_last_moved_seconds;
										// 	var days = parseInt(seconds / (24 * 3600));
										// 	var hours = parseInt(seconds/3600);
										// 	var minutes = Math.round((seconds-(hours*3600))/60)
										// 	//return hours+" Hr(s) "+minutes+" Min(s)";
										// 	return secondsToString(seconds)
										// }
										// else{
										// 	return '';
										// }
										var sdate = moment.parseZone(params.data.stoppage_start_time).format("x");
										if (typeof params.data.reset_datetime == 'undefined') {
											var edate = moment.parseZone().format("x");
										}
										else {
											var edate = moment.parseZone(params.data.reset_datetime).format("x");
										}
										var diff = (edate - sdate) / 1000;
										return secondsToString(diff);
									}
									else {
										return '';
									}
								}
							}
						],
						overlayNoRowsTemplate: 'No rows to show',
					},
					getDetailRowData: async function (params) {
						//console.log("Step 4 ",overspeedData)
						if (params.data.is_transshipment == 1) {
							var oldtruck = params.data.old_truck_no
						}
						else {
							var oldtruck = ''
						}
						redirectURL.post('/consignments/enroutes', {
							consignment_code: params.data.consignment_code,
							truck_no: params.data.truck_no,
							old_truck_no: oldtruck
						})
							.then(async (response) => {
								//console.log("Step 1 ",response.data)
								overspeedData = response.data;
								params.successCallback(overspeedData);
								//console.log("Step 2 ",response.data)

							});

					}
				}
			});

			if (params.column.colDef.field == 'enroute_exception') {

				params.node.setExpanded(!params.node.expanded);
			}
			else {

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {
			this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Truck No", field: "truck_no" },
							{ headerName: "Speed (km/h)", field: "speed" },
							{ headerName: "Reported At", field: "first_instance" }
						]

					},
					getDetailRowData: function (params) {
						//console.log("Child ", params);
						params.successCallback([]);
					}
				}
			});
		}

	}

	onShowEnrouteRouteMap(rownode) {
		//console.log("IN onShowEnrouteRouteMap, params = ",params);
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		// console.log("rownode", rownode);
		this.setState({
			loadshow: 'show-m'
		})
		var fintance = rownode.stoppage_start_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		if (rownode.reset_datetime) {
			var eintance = rownode.reset_datetime.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();

		}
		else {
			var eintance = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');

		}
		//var eintance = rownode.packet_received_on.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var params = {
			truck_no: rownode.truck_no,
			//consignment_code:rownode.consignment_code,
			//consignee_code:rownode.consignee_code,
			first_instance: fintance,
			recent_instance: eintance,
			screen: "consignment"
		}
		redirectURL.post('/reports/nightdriveroutes', params)
			.then((response) => {

				//var records = JSON.parse(JSON.stringify(response)).data;
				// console.log("responseMapData",response.data);
				if (response.data.coords.length == 0) {
					this.setState({
						show: true,
						basicTitle: 'No Route data available',
						basicType: "danger",
						loadshow: 'show-n'
					});
				}
				else {
					var sdate = '';
					var edate = '';

					if (rownode.stoppage_start_time != '' && rownode.stoppage_start_time != undefined) {
						sdate = rownode.stoppage_start_time;
					}
					if (rownode.modified_date != '' && rownode.modified_date != undefined) {
						edate = rownode.modified_date
					}
					this.setState({
						mapinfo: response.data,
						dealer: rownode.consignee_code,
						sliderRouteTranslate: "slider-translate-60p",
						defTransitCoords: rownode.consignee_coordinates,
						consignment_code: "Truck No : " + rownode.truck_no,
						maptruckno: rownode.truck_no,
						routeTruck: { "truck_no": rownode.truck_no, "startTime": sdate, "endTime": edate },
						loadshow: 'show-n',
						sidebarSubHeader: "Consignment Leg Information",
						overly: 'show-m',
						rownode: rownode,
						leg_no: rownode.leg_no,
						tolls: response.data.tolls

					});

					this.onShowGoogleRoute(rownode.consignment_code, rownode.consignee_code, rownode.leg_no, rownode.truck_no)
					this.renderMap();

				}
			})
			.catch(function (error) {
				console.log(error);
			});

	}

	onShowLoadingDelayKpi = async (params) => {

		let eventLabel = googleAnalytics.page.action.loadingDelay;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		//console.log("IN onShowLoadingDelayKpi, params = ",params);
		//console.log(e);
		//console.log("Consign onShowLoadingDelayKpi ",params);
		//console.log(params.column.colId);
		if (params != 0) {
			var overspeedData = [];

			var hideonTNP = (this.state.deptcode == 'LOG-TNP') ? true : false
			var showonTNP = (this.state.deptcode == 'LOG-TNP') ? false : true
			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [

							{
								headerName: "Gate In Time",
								field: "gate_in_time",
								width: 180,
								resizable: true,
								hide: hideonTNP,
								valueGetter: function (params) {

									if (params.data.gate_in_time) {
										//return getDDMMYYYYHHMMSS(params.data.gate_in_time);
										return params.data.gate_in_time;
									}
									else {
										return "NA"
									}

								}
							},

							{
								headerName: "Gate Out Time",
								field: "gate_out_time",
								width: 180,
								resizable: true,
								hide: hideonTNP,
								valueGetter: function (params) {
									//	console.log("IN gate_out_time valueGetter, params = ", params);

									if (params.data.gate_out_time) {
										//console.log("IN gate_out_time valueGetter, IF params.data.gate_out_time = ", params.data.gate_out_time);
										//return getDDMMYYYYHHMMSS(params.data.gate_out_time);
										return params.data.gate_out_time;
									}
									else {
										//console.log("IN gate_out_time valueGetter, ELSE params.data.gate_out_time = ", params.data.gate_out_time);
										return "NA"
									}
								}
							},


							{
								headerName: "Gate In Time",
								field: "gate_in_time",
								width: 180,
								resizable: true,
								hide: showonTNP,
								valueGetter: function (params) {
									// console.log("IN gate_out_time valueGetter, params = ", params);

									if (params.data.gate_in_time) {
										// console.log("IN gate_out_time valueGetter, IF params.data.gate_out_time = ", params.data.gate_in_time);
										//return getDDMMYYYYHHMMSS(params.data.gate_out_time);
										return params.data.gate_in_time;
									}
									else {
										// console.log("IN gate_out_time valueGetter, ELSE params.data.gate_out_time = ", params.data.gate_in_time);
										return "NA"
									}
								}
							},


							{
								headerName: "Gate Out Time",
								field: "gate_out_time",
								width: 180,
								resizable: true,
								hide: showonTNP,
								valueGetter: function (params) {
									// console.log("IN gate_out_time valueGetter, params = ", params);

									if (params.data.gate_out_time) {
										// console.log("IN gate_out_time valueGetter, IF params.data.gate_out_time = ", params.data.gate_out_time);
										//return getDDMMYYYYHHMMSS(params.data.gate_out_time);
										return params.data.gate_out_time;
									}
									else {
										// console.log("IN gate_out_time valueGetter, ELSE params.data.gate_out_time = ", params.data.gate_out_time);
										return "NA"
									}
								}
							},
							{
								headerName: "Loading Time",
								field: "loading_time",
								width: 180,
								valueGetter: param => {
									const data = param.data.loading_time
									if (data.includes('NaN')) {
										return 'NA'
									} else {
										return data
									}
								}
							}

						],
						overlayNoRowsTemplate: 'No rows to show',


					},
					getDetailRowData: function (params) {
						// console.log("Step 4 ", params)
						if (hideonTNP != true) {
							// console.log("Inner here")
							if (params.data.gate_in_time != undefined) {
								var g1 = getDDMMYYYYHHMMSS(params.data.gate_in_time);
								//var g1 = (params.data.gate_in_time) ? moment.parseZone(params.data.gate_in_time).format('DD-MM-YYYY') : "";
								var gdate1 = new Date(params.data.gate_in_time);
								var fromDate = g1;
								var secondsgate1 = moment.parseZone(new Date(params.data.gate_in_time)).format("x")
							}
							else {

								var g1 = getDDMMYYYYHHMMSS(params.data.inside_fence);
								var gdate1 = new Date(params.data.inside_fence);
								var fromDate = g1;
								var secondsgate1 = moment.parseZone(new Date(params.data.inside_fence)).format("x")

							}
							//console.log("g1 ", g1)//console.log("Step 4 ",params)
							if (params.data.gate_out_time != undefined) {
								var g2 = getDDMMYYYYHHMMSS(params.data.gate_out_time);
								var gdate2 = new Date(params.data.gate_out_time);
								var toField = getDDMMYYYYHHMMSS(params.data.gate_out_time);
								//console.log("gatein ", gatein)
								//console.log("gateout ", gateout)
								//var total =0 ;
								var secondsgate2 = moment.parseZone(new Date(params.data.gate_out_time)).format("x")

							}
							else {
								var c = new Date();
								var todaydate = moment(new Date(c)).format("x");
								var g2 = getDDMMYYYYHHMMSS(todaydate);
								var gdate2 = new Date(todaydate);
								var toField = getDDMMYYYYHHMMSS(new Date());
								var secondsgate2 = todaydate

							}
						}
						else {
							var fromDate = getDDMMYYYYHHMMSS(params.data.inside_fence);
							var secondsgate1 = moment.parseZone(new Date(params.data.inside_fence)).format("x")
							var secondsgate2 = moment.parseZone(new Date(params.data.outside_fence)).format("x")
							var toField = getDDMMYYYYHHMMSS(params.data.outside_fence);
						}

						//console.log("secondsg1 ", secondsg1)
						//var g2 = getDDMMYYYY(params.data.gate_out_time);

						//console.log("g1 ", g2)
						// var s1 = g1.split("-");
						// var s2 = g2.split("-");


						// var gatein = gdate1.getTime();
						// var gateout = gdate2.getTime();
						var total = secondsgate2 - secondsgate1;
						//console.log("total ", total)
						var diffMs = total;
						var diffDays = Math.ceil(diffMs / 86400000); // days
						var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
						var diffMins = parseInt(((diffMs % 86400000) % 3600000) / 60000);
						// console.log(diffDays + " " + diffHrs + " " + diffMins);
						let loadingTimeDifference = null;

						var dataarr = []

						dataarr.push({
							"gate_in_time": fromDate,
							"gate_out_time": toField,
							//"loading_time": diffHrs+" Hr(s) "+" "+diffMins+" Min(s)"
							"loading_time": secondsToString(diffMs / 1000)
						})
						// console.log("dataarr ", dataarr)
						params.successCallback(dataarr);
					}

				}
			});

			//if(params.column.colId == 'loading_delay_exception')

			if (params.column.colDef.field == 'loading_delay_exception') {

				params.node.setExpanded(!params.node.expanded);
			}
			else {

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {

		}

	}

	onShowUnLoadingDelayKpi = async (params) => {
		let eventLabel = googleAnalytics.page.action.unloadingDelay;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		//console.log("IN onShowUnLoadingDelayKpi, params = ",params);
		//console.log(e);
		//console.log("Consign Overspeed ",params);
		//console.log(params.column.colId);
		if (params != 0) {
			var overspeedData = [];


			//console.log("Step 3 ",overspeedData)

			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [

							{
								headerName: "From Date",
								field: "from_date",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									// console.log("child params ", params)
									if (params.data.from_date != undefined) {
										return getDDMMYYYYHHMMSS(params.data.from_date);
									}
									else {
										return "NA"
									}

								}
							},
							{
								headerName: "To Date",
								field: "to_date",
								width: 180,
								resizable: true,
								valueGetter: function (params) {
									if (params.data.to_date != undefined) {
										return getDDMMYYYYHHMMSS(params.data.to_date);
									}
									else {
										return "NA"
									}
								}
							},
							{
								headerName: "Unloading Time",
								field: "unloading_time",
								width: 180,

							}

						],
						overlayNoRowsTemplate: 'No rows to show',

					},
					getDetailRowData: function (params) {
						//console.log("Step 4 ",params)
						if (params.data.locked_dealer_reported == undefined) {
							var g1 = getYYYYMMDDHHMMSS(params.data.first_dealer_reported);
							var fromField = g1;
						}
						else {

							var g1 = getYYYYMMDDHHMMSS(params.data.locked_dealer_reported);
							var fromField = g1;
						}
						//console.log("params.data.left_dealer_marked ", params.data.left_dealer_marked)
						if (params.data.left_dealer_marked == undefined) {
							var c = new Date();
							var todaydate = c;
							var g2 = getYYYYMMDDHHMMSS(todaydate);
							var toField = g2;
						}
						else {
							var g2 = getYYYYMMDDHHMMSS(params.data.left_dealer_marked);
							var toField = g2;
							//console.log("toField stee " ,toField);
						}
						var fstr = fromField.split("-");
						//console.log(fstr);
						var gdate1 = new Date(fromField);
						var gdate2 = new Date(toField);
						var frmd = gdate1.getTime();
						var tod = gdate2.getTime();
						var total = tod - frmd;
						//console.log("From Date ", fromField)
						//console.log("To Date ", toField)
						//var total =0 ;
						//console.log("gdate1 ", frmd)
						//console.log("gdate2 ", tod)
						//console.log("Total ", total)
						var diffMs = total;
						var diffDays = Math.floor(diffMs / 86400000); // days
						var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
						var diffMins = parseInt(((diffMs % 86400000) % 3600000) / 60000);

						// var sr = params.data.unloading_time_in_hrs;
						// var hrs=sr.toString().split(".");
						// var hours = parseInt(hrs[0]);
						// var mins = Math.round((hrs[1]/100)*60)
						var dataarr = []
						// dataarr.push({
						// 	"from_date":fromField,
						// 	"to_date": toField,
						// 	"unloading_time":diffHrs+" Hr(s) "+" "+diffMins+" Min(s)"
						// 	//"unloading_time":hours+"Hr(s) "+mins+" min(s)"
						// })
						let loadingTimeDifference = null;
						// if (diffDays) {
						// 	loadingTimeDifference = diffDays+" Day(s) " + diffHrs + " Hr(s) "+" "+diffMins+" Min(s)";
						// } else {
						// 	loadingTimeDifference = diffHrs+" Hr(s) "+" "+diffMins+" Min(s)";
						// }
						loadingTimeDifference = secondsToString(diffMs / 1000)
						dataarr.push({
							"from_date": fromField,
							"to_date": toField,
							"unloading_time": loadingTimeDifference
							//"unloading_time":hours+"Hr(s) "+mins+" min(s)"
						})
						params.successCallback(dataarr);

					}

				}
			});

			//if(params.column.colId == 'unloading_delay_exception')
			if (params.column.colDef.field == 'unloading_delay_exception') {

				params.node.setExpanded(!params.node.expanded);
			}
			else {

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {

		}

	}


	onShowDeviate = async (params) => {
		let eventLabel = googleAnalytics.page.action.deviation;
		let eventOptions = {
			"category": this.state.pagetitle,
			"action": this.state.eventAction,
			"label": eventLabel,
		}
		googleAnalytics.logEvent(eventOptions);
		// console.log("IN onShowDeviate, params = ", params);

		//console.log(e);
		//console.log("Consign Overspeed ",params);

		if (params != 0) {
			var overspeedData = [];

			await this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Truck No", field: "truck_no" },
							{ headerName: "Speed (km/h)", field: "speed" },
							{ headerName: "Reported At", field: "first_instance" }
						]
					},
					getDetailRowData: async function (params) {
						// console.log("Step 4 ", overspeedData)
						await params.successCallback(overspeedData);
					}
				}
			});

			if (params.column.colId == '_id_1' || params.column.colId == '_id_2') {

				params.node.setExpanded(!params.node.expanded);
			}
			else {

				params.node.setExpanded(false);
			}
			//console.log("detailCellRendererParams ", this.state.detailCellRendererParams);

		}
		else {
			this.setState({

				detailCellRendererParams: {
					detailGridOptions: {
						columnDefs: [
							{ headerName: "Truck No", field: "truck_no" },
							{ headerName: "Speed (km/h)", field: "speed" },
							{ headerName: "Reported At", field: "first_instance" }
						]

					},
					getDetailRowData: function (params) {
						//console.log("Child ", params);
						params.successCallback([]);
					}
				}
			});
		}

	}
	onRowClicked(params) {
		// console.log("IN onRowClicked, params = ", params);
		// console.log("row click ", params)
		if (params.column.colId == 'consignment_code') {
			this.setState({
				sliderSegmentTranslate: "slider-translate-60p",
				segementConsignmentCode: params.data.consignment_code,
				overly: "show-m"
			})
		}
	}
	closeSegmentSideBar() {
		this.setState({
			sliderSegmentTranslate: "",
			segementConsignmentCode: "",
			overly: "show-n",
			basicTitle: "No Segment Record Found",
			basicType: "danger",
			show: true
		});
	}
	onShowRouteMap(rownode) {
		// console.log("IN onShowRouteMap, params = ",rownode);
		// console.log("rownode", rownode);
		// console.log("rownode.consignment_code ", rownode.consignment_code)
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.setState({
			loadshow: 'show-m'
		})
		var d = new Date(rownode.first_instance);
		var r = new Date(rownode.recent_instance);
		var finstance = rownode.first_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var rinstance = rownode.recent_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var params = {
			truck_no: rownode.truck_no,
			consignment_code: rownode.consignment_code,
			consignee_code: rownode.consignee_code,
			first_instance: finstance,
			recent_instance: rinstance,
			screen: "consignment"
		}

		redirectURL.post('/reports/overspeedgpsroutes', params)
			.then((response) => {

				//var records = JSON.parse(JSON.stringify(response)).data;
				// console.log(response.data);
				this.setState({
					mapinfo: response.data,
					dealer: rownode.consignee_code,
					sliderRouteTranslate: "slider-translate-50p",
					consignment_code: rownode.truck_no,
					maptruckno: rownode.truck_no,
					routeTruck: {
						"truck_no": rownode.truck_no, "startTime": response.data['route_details']['start_time'],
						"endTime": response.data['route_details']['end_time']
					},
					overly: 'show-m',
					loadshow: 'show-n',
					rownode: rownode,
					tolls: response.data.tolls
				});
				this.renderMap();


			})
			.catch(function (error) {
				console.log(error);
			});

	}

	onShowTransitRouteMap(rownode) {
		//console.log("IN onShowTransitRouteMap, params = ",rownode);
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		//console.log("rownode", rownode);
		this.setState({
			loadshow: 'show-m'
		})
		var d = new Date(rownode.leg_start);
		var r = new Date(rownode.leg_end_eta);
		var fintance = rownode.leg_start.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var eintance = rownode.leg_end_eta.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var params = {
			truck_no: rownode.truck_no,
			consignment_code: rownode.consignment_code,
			consignee_code: rownode.consignee_code,
			first_instance: fintance,
			recent_instance: eintance,
			screen: "consignment"
		}
		redirectURL.post('/reports/nightdriveroutes', params)
			.then((response) => {

				//var records = JSON.parse(JSON.stringify(response)).data;
				//console.log("Inner Grid ",response.data);
				if (response.data.coords.length == 0) {
					this.setState({
						show: true,
						basicTitle: 'No Route data available',
						basicType: "danger",
						loadshow: 'show-n'
					});
				}
				else {
					var sdate = '';
					var edate = '';

					if (rownode.leg_start != '' && rownode.leg_start != undefined) {
						sdate = rownode.leg_start;
					}
					if (rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined) {
						edate = rownode.leg_end_eta;
					}
					this.setState({
						mapinfo: response.data,
						dealer: rownode.consignee_code,
						sliderRouteTranslate: "slider-translate-60p",
						defTransitCoords: rownode.consignee_coordinates,
						consignment_code: "Truck No : " + rownode.truck_no,
						maptruckno: rownode.truck_no,
						routeTruck: { "truck_no": rownode.truck_no, "startTime": sdate, "endTime": edate },
						loadshow: 'show-n',
						sidebarSubHeader: "Consignment Leg Information",
						overly: 'show-m',
						rownode: rownode,
						leg_no: rownode.leg_no,
						tolls: response.data.tolls

					});

					this.onShowGoogleRoute(rownode.consignment_code, rownode.consignee_code, rownode.leg_no, rownode.truck_no)
					this.renderMap();

				}
			})
			.catch(function (error) {
				console.log(error);
			});

	}

	onShowNoGPSRouteMap(rownode) {
		//console.log("IN onShowNoGPSRouteMap, params = ",rownode);
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		// console.log("rownode", rownode);
		this.setState({
			loadshow: 'show-m'
		})
		var fintance = rownode.last_packet_datetime.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		//var eintance = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
		var eintance = rownode.packet_received_on.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var params = {
			truck_no: rownode.truck_no,
			consignment_code: rownode.consignment_code,
			//consignee_code:rownode.consignee_code,
			first_instance: fintance,
			recent_instance: eintance,
			screen: "consignment"
		}
		redirectURL.post('/reports/nightdriveroutes', params)
			.then((response) => {

				//var records = JSON.parse(JSON.stringify(response)).data;
				// console.log("responseDataForLeg",response.data);
				if (response.data.coords.length == 0) {
					this.setState({
						show: true,
						basicTitle: 'No Route data available',
						basicType: "danger",
						loadshow: 'show-n'
					});
				}
				else {
					var sdate = '';
					var edate = '';

					if (rownode.last_packet_datetime != '' && rownode.last_packet_datetime != undefined) {
						sdate = rownode.last_packet_datetime;
					}

					// if (rownode.data_received_on != '' && rownode.data_received_on != undefined) {
					// 	edate = rownode.data_received_on;
					// }
					if (rownode.packet_received_on != '' && rownode.packet_received_on != undefined) {
						edate = rownode.packet_received_on;
						// console.log("edate",edate)
					}
					this.setState({
						mapinfo: response.data,
						dealer: rownode.consignee_code,
						sliderRouteTranslate: "slider-translate-60p",
						defTransitCoords: rownode.consignee_coordinates,
						consignment_code: "Truck No : " + rownode.truck_no,
						maptruckno: rownode.truck_no,
						routeTruck: { "truck_no": rownode.truck_no, "startTime": sdate, "endTime": edate },
						loadshow: 'show-n',
						sidebarSubHeader: "Consignment Leg Information",
						overly: 'show-m',
						rownode: rownode,
						leg_no: rownode.leg_no,
						tolls: response.data.tolls

					});

					this.onShowGoogleRoute(rownode.consignment_code, rownode.consignee_code, rownode.leg_no, rownode.truck_no)
					this.renderMap();

				}
			})
			.catch(function (error) {
				console.log(error);
			});

	}

	onShowNightDriveRouteMap(rownode) {
		// console.log("IN onShowNightDriveRouteMap, params = ",rownode);
		// console.log("nightdrivenode", rownode);
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		this.setState({
			loadshow: 'show-m'
		});
		var d = new Date(rownode.first_instance);
		var r = new Date(rownode.recent_instance);
		var fintance = rownode.first_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var eintance = rownode.recent_instance.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var params = {
			truck_no: rownode.truck_no,
			first_instance: fintance,
			recent_instance: eintance,
		}

		redirectURL.post('/reports/nightdriveroutes', params)
			.then((response) => {

				//var records = JSON.parse(JSON.stringify(response)).data;
				// console.log("nightroutedata", response.data);

				this.setState({
					mapinfo: response.data,
					sliderRouteTranslate: "slider-translate-50p",
					consignment_code: rownode.truck_no,
					defTransitCoords: { "lat": rownode.first_lat, "lng": rownode.first_lng },
					maptruckno: rownode.truck_no,
					routeTruck: {
						"truck_no": rownode.truck_no, "startTime": response.data['route_details']['start_time'],
						"endTime": response.data['route_details']['end_time']
					},
					loadshow: 'show-n',
					overly: 'show-m',
					tolls: response.data.tolls
				});
				this.renderMap();


			})
			.catch(function (error) {
				console.log(error);
			});
	}

	onShowDayWiseRouteMap = (rownode) => {
		//console.log("IN onShowNoGPSRouteMap, params = ",rownode);
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		//console.log("rownode", rownode);
		this.setState({
			loadshow: 'show-m'
		})
		var fintance = rownode.start_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		//var eintance = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
		var eintance = rownode.end_time.replace("T", " ").replace("Z", " ").replace(".000", " ").trim();
		var params = {
			truck_no: rownode.truck_no,
			// consignment_code:rownode.consignment_code,
			//consignee_code:rownode.consignee_code,
			startDate: fintance,
			endDate: eintance,
			screen: "consignment"
		}
		if (["/prtconsignments", "/sndconsignments"].includes(this.props.match.path)) params.inTransit = 1

		redirectURL.post('/gmap', params)
			.then((response) => {
				var records = JSON.parse(response.data.data);
				// console.log("records ", records)
				if (records.length == 0) {
					this.setState({
						show: true,
						basicTitle: 'No Route data available',
						basicType: "danger",
						loadshow: 'show-n'
					});
				}
				else {
					var sdate = '';
					var edate = '';

					// if (rownode.leg_start != '' && rownode.leg_start != undefined) {
					// 	sdate = rownode.leg_start;
					// }
					// if (rownode.leg_end_eta != '' && rownode.leg_end_eta != undefined) {
					// 	edate = getDDMMYYYYHHMMSS(rownode.leg_end_eta)
					// }
					this.setState({
						mapinfo: records,
						dealer: rownode.consignee_code,
						sliderRouteTranslate: "slider-translate-60p",
						defTransitCoords: rownode.consignee_coordinates,
						consignment_code: "Truck No : " + rownode.truck_no,
						maptruckno: rownode.truck_no,
						routeTruck: { "truck_no": rownode.truck_no, "startTime": fintance, "endTime": eintance },
						loadshow: 'show-n',
						sidebarSubHeader: "View Information",
						overly: 'show-m',
						rownode: rownode,
						leg_no: rownode.leg_no,
						tolls: response.data.tolls

					});

					this.onShowGoogleRoute(rownode.consignment_code, rownode.consignee_code, rownode.leg_no, rownode.truck_no)
					this.renderMap();

				}
			})
			.catch(function (error) {
				console.log(error);
			});

	}
	onClickHideAll() {
		var uploadfilebulk = $("#uploadfile").val(null)
		this.setState({
			loadshow: 'show-n',
			overly: 'show-n',
			slideuploadeway: '',
			sliderRouteTranslate: '',
			sliderSegmentTranslate: "",
			tabsliderTranslate: '',
			sliderTranslatesidebar: '',
			sliderForceCloseTranslate: '',
			sliderCommentTranslate: "",
			bulkPrioritySlide: "",
			sliderCreateConsignmentTranslate: "",
			sliderRso:"",
			uploadfilebulk:uploadfilebulk
		});

	}
	changeFileHandlerFc = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
				{ 
					name: 'AreaDescription',
					inputName: 'consignment_area',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'ConsumerNumber',
					inputName: 'customer_id',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'ConsumerName',
					inputName: 'customer_name',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'MobileNumber',
					inputName: 'customer_mobile_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'OrderNo',
					inputName: 'consignment_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'BookDate',
					inputName: 'booking_date',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				// { 
				// 	name: 'delivery_type',
				// 	inputName: 'delivery_type',
				// 	required: true,
				// 	requiredError: function (headerName, rowNumber, columnNumber) {
				// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
				// 	}
				// },
				
				// { 
				// 	name: 'preferred_delivery_date',
				// 	inputName: 'preferred_delivery_date',
				// 	required: true,
				// 	requiredError: function (headerName, rowNumber, columnNumber) {
				// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
				// 	}
				// },
				// { 
				// 	name: 'preferred_delivery_time_slot',
				// 	inputName: 'preferred_delivery_time_slot',
				// 	required: true,
				// 	requiredError: function (headerName, rowNumber, columnNumber) {
				// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
				// 	}
				// },
				// { 
				// 	name: 'consigner_name',
				// 	inputName: 'consigner_name',
				// 	required: true,
				// 	requiredError: function (headerName, rowNumber, columnNumber) {
				// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
				// 	}
				// },
				// { 
				// 	name: 'consignee_name',
				// 	inputName: 'consignee_name',
				// 	required: true,
				// 	requiredError: function (headerName, rowNumber, columnNumber) {
				// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
				// 	}
				// },
				// { 
				// 	name: 'shipping_address',
				// 	inputName: 'shipping_address',
				// 	required: true,
				// 	requiredError: function (headerName, rowNumber, columnNumber) {
				// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
				// 	}
				// },
				// { 
				// 	name: 'customer_city',
				// 	inputName: 'customer_city',
				// 	required: true,
				// 	requiredError: function (headerName, rowNumber, columnNumber) {
				// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
				// 	}
				// },
				// { 
				// 	name: 'customer_state',
				// 	inputName: 'customer_state',
				// 	required: true,
				// 	requiredError: function (headerName, rowNumber, columnNumber) {
				// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
				// 	}
				// },
				// { 
				// 	name: 'customer_area',
				// 	inputName: 'customer_area',
				// 	required: true,
				// 	requiredError: function (headerName, rowNumber, columnNumber) {
				// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
				// 	}
				// },
				{ 
					name: 'ProductCode',
					inputName: 'product_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'RefillType',
					inputName: 'refill_type',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'DeliveryFlag',
					inputName: 'delivery_flag',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'NoofCyliders',
					inputName: 'no_of_cylinders',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'PaymentOption',
					inputName: 'payment_method',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				// { 
				// 	name: 'delivery_person_name',
				// 	inputName: 'delivery_person_name',
				// 	required: true,
				// 	requiredError: function (headerName, rowNumber, columnNumber) {
				// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
				// 	}
				// },
				// { 
				// 	name: 'delivery_person_mobile_no',
				// 	inputName: 'delivery_person_mobile_no',
				// 	required: true,
				// 	requiredError: function (headerName, rowNumber, columnNumber) {
				// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
				// 	}
				// },
				// { 
				// 	name: 'consignee_code',
				// 	inputName: 'consignee_code',
				// 	required: true,
				// 	requiredError: function (headerName, rowNumber, columnNumber) {
				// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
				// 	}
				// },
				// { 
				// 	name: 'consigner_code',
				// 	inputName: 'consigner_code',
				// 	required: true,
				// 	requiredError: function (headerName, rowNumber, columnNumber) {
				// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
				// 	}
				// },
				// { 
				// 	name: 'amount',
				// 	inputName: 'amount',
				// 	required: true,
				// 	requiredError: function (headerName, rowNumber, columnNumber) {
				// 		return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
				// 	}
				// },
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			if(csvData.inValidMessages.length > 0 || csvData.data.length === 0){
				var uploadfilebulk = $("#uploadfile").val(null)
				this.setState({
					show: true,
					basicType: 'danger',
					basicTitle: 'Please upload file having correct fields..!',
					uploadfilebulk:uploadfilebulk
				})
			}else if(csvData.inValidMessages.length === 0 && csvData.data.length > 0){
				this.setState({
					csvcontent:csvData.data,				
				});
			}
		})
		.catch(err => {})
		
		var contentList = []
		try{
			var out = new Promise(function(reject, resolve){
				var reader =  new FileReader();
				if (reader != "" && reader != undefined) {
					reader.onload = async function(e) {
						var contents = await e.target.result;
						resolve(contents);
					};
					if(e.target.files[0] != "" && e.target.files[0] != undefined)
					{
						var tt =  reader.readAsText(e.target.files[0]);
					}
				}
			});
			console.log(contentList);
			this.setState({
				file:e.target.files[0]
			});
			// console.log("e.target.files[0].type ", e.target.files[0]);
			if (e.target.files[0] != "" && e.target.files[0] != undefined) {
				if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
				{
					// console.log(fileData);
					// console.log(typeof(fileData))
					
				}
				else{
					e.target.value = null;
					this.setState({
						uploadFile:'',
						file:"",
						show: true, 
						basicType:'danger', 
						basicTitle:'Please upload file having extensions .csv only.',
					});
				}
			}
		}
		catch{

		}
		
	}
	sendOrderDetails = async (data) => {
		this.setState({
			loadshow: 'show-m',
			overly: 'show-m'
		})
		if (data != "" && data != undefined && data.length > 0) {
			var consigneeAddressMap = this.state.consigneeAddressMap;
			var consigneeContactNoMap = this.state.consigneeContactNoMap;
			var consigneeEmergencyNoMap = this.state.consigneeEmergencyNoMap;
			var areaStatusMap = this.state.areaStatusMap;
			var consigneeCode = localStorage.getItem("consignee_code");
			var orderDetailsArr = [];
			var chunkSize = 50;
			
			await data.map(order => {
				// if (areaStatusMap.hasOwnProperty(`${order.customer_area_id}`) && areaStatusMap[`${order.customer_area_id}`] == "Active" && order.consignee_code == consigneeCode) {
				if (order.consignee_code == consigneeCode) {
					var ordersDict = {}
					ordersDict["consignment_no"] = order.consignment_no;
					// ordersDict["booking_date"] = order.booking_date;
					ordersDict["consignee_code"] = order.consignee_code;
					ordersDict["consignee_name"] = order.consignee_name;
					ordersDict["consignee_address"] = consigneeAddressMap[order.consignee_code];
					ordersDict["customer_id"] = order.customer_id;
					ordersDict["customer_name"] = order.customer_name;
					ordersDict["customer_mobile_no"] = order.customer_mobile_no;
					ordersDict["customer_city"] = order.customer_city;
					ordersDict["shipping_address"] = order.shipping_address;
					ordersDict["consignee_contact_nos"] = consigneeContactNoMap[order.consignee_code];
					ordersDict["consignee_emergency_nos"] = consigneeEmergencyNoMap[order.consignee_code];
					// ordersDict["consignment_area"] = order.consignment_area;
					// ordersDict["no_of_cylinders"] = order.no_of_cylinders;
					// ordersDict["preferred_delivery_date"] = order.preferred_delivery_date;
					// ordersDict["preferred_delivery_time_slot"] = order.preferred_delivery_time_slot;
					// ordersDict["delivery_person_name"] = order.delivery_person_name;
					// ordersDict["delivery_person_mobile_no"] = order.delivery_person_mobile_no;
					// ordersDict["delivery_flag"] = order.delivery_flag;
					orderDetailsArr.push(ordersDict);
				}
			})
			// redirectURL.post("/consignments/notifyBookingConfirmation", orderDetailsArr)
			// 	.then((res) => console.log("res", res))
			// fetch("https://494b-216-48-177-225.in.ngrok.io/notifications/notifyBookingConfirmation", {
			// 	method: "POST",
			// 	headers: {
			// 		'Content-Type': "application/json"
			// 	},
			// 	body: orderDetailsArr
			// }).then((res) => console.log("res from ngrok", res))
			const chunkedData = orderDetailsArr.reduce((acc, item, index) => {
				if (index % chunkSize === 0) {
				  acc.push([item]);
				} else {
				  acc[acc.length - 1].push(item);
				}
				return acc;
			}, []);
			console.log("chunkedData", chunkedData)
			for (const data of chunkedData) {
				var options = { 
					// method: 'POST',
					// url: "http://localhost:3018/notifications/notifyBookingConfirmation",
					url: "http://bpclptd.in/saira/notifications/notifyBookingConfirmation",
					// url: "https://494b-216-48-177-225.in.ngrok.io/notifications/notifyBookingConfirmation",
					headers: {
						'cache-control': 'no-cache',
						'content-type': 'application/json'
					},
					body: data,
					json: true
				};
				console.log("Data to be sent to Saira", options)
				request.post(options, (err, res) => {
					if (err) {
						console.log("Saira Error", err)
					} else {
						console.log("Saira Response", res.body);
					}			
				})
			}
			this.setState({
				show: true,
				basicTitle: "WhatsApp message sent to customers.",
				basicType: "success",
				loadshow: "show-n",
				overly: "show-n",
				sliderRso: "",
			});
		}
		
	}
	onSubmitBulkUpload = async (event) => {
		event.preventDefault()
		this.setState({
			loadshow: 'show-m',
			overly: 'show-m'
		})
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": "Buk Upload",
				"action": "form",
				"label": "Buk Upload",
			}
			googleAnalytics.logEvent(eventOptions);
		}
        // console.log(this.state.csvcontent,"bulk upload data");
		// console.log("upload records count", this.state.csvcontent.length);
		
			if(this.state.csvcontent != undefined && this.state.csvcontent != "")
			{
				if (this.state.csvcontent.length > 0) 
				{
					var duplicateconsignmentcode = [];
					var duplicatetruckno = [];
					var booking_date_flag = 0;
					var customermobilenumber = [];
					var specialCharcters = 0;
					var specialCharcters1 = 0;
					var numberlength = true;
					// var uploadFile = this.state.file;
					// console.log("Upload file", uploadFile)
					// console.log("csvcontent", this.state.csvcontent)
					this.state.csvcontent.map((em, i) => {
						if(em.consignment_no != "" && em.consignment_no != undefined)
						{
							duplicateconsignmentcode.push(em.consignment_no)
						}
						// if(em.truck_no != "" && em.truck_no != undefined)
						// {
						// 	duplicatetruckno.push(em.truck_no)
						// }
						if(em.customer_mobile_no != "" && em.customer_mobile_no != undefined)
						{
							customermobilenumber.push(em.customer_mobile_no)
						}
						
						var bookingdate = em.booking_date;
						console.log("bookingdate", bookingdate);
						if(bookingdate.indexOf("/") > 0)
						{
							bookingdate = bookingdate.split("/");
							if(bookingdate[0].length !== 2 || bookingdate[1].length !== 2 || bookingdate[2].length !== 4)
							{
								booking_date_flag = 1;
							}
							
							if(booking_date_flag == 0)
							{
								em.booking_date = bookingdate[2]+"-"+bookingdate[1]+"-"+bookingdate[0];
							}
						}
						else if(bookingdate.indexOf("-") > 0)
						{
							bookingdate = bookingdate.split("-");
							if(bookingdate[0].length !== 2 || bookingdate[1].length !== 2 || bookingdate[2].length !== 4)
							{
								booking_date_flag = 1;
							}
							if(booking_date_flag == 0)
							{
								em.booking_date = bookingdate[2]+"-"+bookingdate[1]+"-"+bookingdate[0];
							}
						}
						else{}
					})
					// console.log(drivermobilenumber,"drivermobilenumber")
					// duplicatetruckno.map((e) => {
					// 	if (e.match(/[^a-zA-Z0-9]/g)) {
					// 		specialCharcters = 1
					// 	}
					// })
					duplicateconsignmentcode.map((e) => {
						if (e.match(/[^a-zA-Z0-9]/g)) {
							specialCharcters1 = 1
						}
					})
					customermobilenumber.map((dm) => {
						// console.log(dm.length,"e.length")
						if (dm.length != 10) 
						{
							numberlength = false
						}
					})
					
					console.log(numberlength,"numberlength")
					// console.log(duplicatetruckno,"duplicatetruckno")
					// console.log(specialCharcters,"specialCharcters")
					// console.log(duplicateconsignmentcode,"duplicateconsignmentcode")
					var uniqueCheck = checkIfArrayIsUnique(duplicateconsignmentcode)
					var consigneeCode = localStorage.getItem("consignee_code");
					var userType = localStorage.getItem("user_type");
					// var uniquetruckcheck = checkIfArrayIsUnique(duplicatetruckno)
					console.log(uniqueCheck,'uniqueCheck')
					// if(duplicateconsignmentcode != "" && duplicateconsignmentcode != undefined ){

						// if(specialCharcters == 0 && specialCharcters1 == 0){

							// if(uniqueCheck == true){

								// if(numberlength == true){

									var params = {
										csvcontent: JSON.stringify(this.state.csvcontent),
										// uploadFile: JSON.stringify(uploadFile),
										// accessby: accessby,
										// transporter_code: transporter_stockyard_code,
									}
									if (userType == "DISTRIBUTOR") {
										params.consigneeCode = consigneeCode
									}
									// console.log("content ", this.state.csvcontent)
									// console.log("Params ", params)
									await redirectURL.post("/dashboard/bulkuploadprtconsignmentdata", params).then(async (res) => {
										this.setState({
											uploadfilebulk: $("#uploadfile").val(null)
										})
										if (res.data.insertedCount >= 1) {
											var uploadfilebulk = $("#uploadfile").val(null)
											let finalMessage = ""
											if(res.data.insertedCount > 0){
												finalMessage += `${res.data.insertedCount} Order(s) inserted`
											}
											
											if(res.data.duplicateValues > 0){
												finalMessage += `, ${res.data.duplicateValues} duplicate order(s)`
											}

											if(res.data.invalidDatesCount > 0){
												finalMessage += `, ${res.data.invalidDatesCount} order(s) with invalid date format`
											}

											if(res.data.custNotFoundCount > 0){
												finalMessage += `, ${res.data.custNotFoundCount} order(s) with invalid Cutomer no.`
											}


											if(res.data.invalidCustMobileNoCount > 0){
												finalMessage += `, ${res.data.invalidCustMobileNoCount} order(s) with invalid mobile no.`
											}

											if(res.data.invalidOrderNoCount > 0){
												finalMessage += `, ${res.data.invalidOrderNoCount} invalid order(s)`
											}

											if(res.data.invalidCustIdCount > 0){
												finalMessage += `, ${res.data.invalidCustIdCount} invalid customers`
											}

											if(res.data.duplicateValues > 0 || res.data.invalidDatesCount > 0 || res.data.custNotFoundCount > 0 || res.data.invalidCustMobileNoCount > 0 || res.data.invalidOrderNoCount > 0 || res.data.invalidCustIdCount > 0){

												finalMessage += " have been found and ignored"
											}

											this.setState({
												show: true,
												basicTitle: finalMessage,
												basicType: "success",
												loadshow: "show-n",
												overly: "show-n",
												sliderRso: "",
												uploadfilebulk: uploadfilebulk
											});
											console.log("Inserted Data", this.state.csvcontent);
											console.log("Inserted Count", res.data.insertedCount);
											// this.componentDidMount();
											var consigneeCode = localStorage.getItem("consignee_code");
											if (userType == "DISTRIBUTOR") {
												var params = {
													consigneeCode: consigneeCode
												}
											} else {
												params = {}
											}
											await redirectURL.post("/consignments/updateConsignments", params).then((res) => {
												console.log("Update consignments response", res.data);
												this.sendOrderDetails(res.data.consignments);
											});
										}	
										else if(res.data.invalidDatesCount >= 1)
										{
											this.setState({
												show: true,
												basicTitle: 'Enter Valid Date Format',
												basicType: "danger",
											});
										} else if(res.data.updatedCount > 0 && res.data.insertedCount > 0){
											this.setState({
												show: true,
												basicTitle: `${res.data.insertedCount} Order(s) inserted and ${res.data.updatedCount} Order(s) updated.`,
												basicType: "success",
											});
										} else if (res.data.custNotFountCount > 0) {
											this.setState({
												show: true,
												basicTitle: `${res.data.custNotFountCount} Customer(s) not found and ignored.`,
												basicType: "success",
											});
										} else if(res.data.updatedCount > 0){
											this.setState({
												show: true,
												basicTitle: `${res.data.updatedCount} Order(s) updated.`,
												basicType: "success",
											});
										}
										else if(res.data.duplicateValues >= 1)
										{
											this.setState({
												show: true,
												basicTitle: `${res.data.duplicateValues} Duplicate Order(s) found and ignored`,
												basicType: "success",
											});
										}
										else if (res.data.inactiveAreaStatusCount > 0) {
											this.setState({
												show: true,
												basicTitle: `${res.data.inactiveAreaStatusCount} Inactive Area(s) found and those orders ignored`,
												basicType: "success",
											});
										} else if (res.data.noAreaStatusMappingCount > 0) {
											this.setState({
												show: true,
												basicTitle: `${res.data.noAreaStatusMappingCount} Area(s) not found and orders ignored`,
												basicType: "success",
											});
										} else {
											this.setState({
												show: true,
												basicType: "danger",
												basicTitle: "Failed to create consignment",
												// var uploadfilebulk = $("#uploadfile").val(null)
												uploadfilebulk: uploadfilebulk
											})
										}
										this.setState({
											loadshow: "show-n",
											overly: "show-n",
										})
									})
								// }
								// else{
								// 	this.setState({
								// 		show: true,
								// 		basicTitle: 'Customer Mobile Number should be 10 digits',
								// 		basicType: "danger",
								// 	});
								// }
							// } 
							// else{
							// 	this.setState({
							// 		show: true,
							// 		basicTitle: 'Order No Should not be duplicate',
							// 		basicType: "danger",
							// 	});
							// }
						// }
						// else{
						// 	this.setState({
						// 		show: true,
						// 		basicTitle: 'Special Characters are not allowed in Order No',
						// 		basicType: "danger",
						// 	});
						// }
					// }
					// else{
					// 	this.setState({
					// 		show: true,
					// 		basicTitle: 'Order No Should not be empty',
					// 		basicType: "danger",
					// 	});
					// }
				}
								
			}
			else{
				this.setState({
					show: true,
					basicTitle: 'Choose file to continue',
					basicType: "danger",
					loadshow: 'show-n',
					overly: 'show-n'
				});
			}
	}
	showGoogleRouteAlert = () => {
		this.setState({
			show: true,
			basicTitle: "No google routes found",
			basicType: "danger"
		});
	}

	closeAlert = () => {
		this.setState({
			show: false
		});
	}
	onClickCounterShowDataTEST(params) {
		// console.log("IN onClickCounterShowData Do NOTHING- params = ", params);
	}

	onClickCounterShowData(params) {
		console.log("IN onClickCounterShowData, params = ", params);
		if (googleAnalytics.page.enableGA) {
			let eventLabel = null;
			if (params) {
				eventLabel = googleAnalytics.page.kpi[params];
				//console.log("eventLabel = ", eventLabel);
			}

			let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventCounterAction,
				"label": eventLabel,
			}
			googleAnalytics.logEvent(eventOptions);
		}


		if (params == "transit_delay") {
			// var filterComponent = this.gridApi.getFilterInstance("transit_delay");
			// filterComponent.selectNothing();
			// filterComponent.selectValue("1");
			// if(this.state.tripvalue != undefined && this.state.tripvalue != '')
			// {
			// 	filterComponent.selectValue(this.state.tripvalue);
			// }
			// filterComponent.applyModel();
			var gridData = this.state.allRowData
			if (this.state.tnpReturnConsignmentsVisibilty) { gridData = this.state.returnConsignments }
			let isFilterDataAvailable = gridData.filter(rec => {
				if (rec["transit_delay"]) {
					return rec["transit_delay"] == 1;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if(params == 'no_gate_out_time'){
			this.gridApi.setRowData(this.state.withoutTruckGateOutTimeData);
			this.gridApi.onFilterChanged();
		}
		else if (params == 'preferredDeliveries') {
			console.log("gridData",this.state.rowData)
			let preferredDeliveryData = this.state.rowData.filter(rec => {
				return rec.delivery_type == "Preferred"
			})
			console.log("preferredDeliveryData",preferredDeliveryData);
			this.gridApi.setRowData(preferredDeliveryData);
			this.gridApi.onFilterChanged();
		}
		else if (params == 'offerPrefStatusCount') {
			console.log("gridData",this.state.rowData)
			let offerPrefStatusCountData = this.state.rowData.filter(rec => {
				return rec.step_code == "OFFER_PREFERRED_TIME_DELIVERY"
			})
			this.gridApi.setRowData(offerPrefStatusCountData);
			this.gridApi.onFilterChanged();
		}
		else if (params == 'prefLangStatusCount') {
			console.log("gridData",this.state.rowData)
			let prefLangStatusCountData = this.state.rowData.filter(rec => {
				return rec.step_code == "PREFERRED_LANGUAGE"
			})
			this.gridApi.setRowData(prefLangStatusCountData);
			this.gridApi.onFilterChanged();
		}
		else if (params == 'selectPrefDateStatusCount') {
			console.log("gridData",this.state.rowData)
			let selectPrefDateStatusCountData = this.state.rowData.filter(rec => {
				return rec.step_code == "SELECT_PREFERRED_DATE"
			})
			this.gridApi.setRowData(selectPrefDateStatusCountData);
			this.gridApi.onFilterChanged();
		}
		else if (params == 'onTimeDeliveriesCount') {
			console.log("gridData",this.state.onTimeDeliveries)
			let onTimeDeliveriesData = this.state.onTimeDeliveries
			this.gridApi.setRowData(onTimeDeliveriesData);
			this.gridApi.onFilterChanged();
		}
		else if (params == 'delayedDeliveriesCount') {
			console.log("gridData",this.state.delayedDeliveries)
			let delayedDeliveriesData = this.state.delayedDeliveries
			this.gridApi.setRowData(delayedDeliveriesData);
			this.gridApi.onFilterChanged();
		}
		else if(params === "forceClosedDeliveriesCount"){
			this.gridApi.setRowData(this.state.forceClosedDeliveriesData);
			this.setState({
				forceClosedDeliveriesData: this.state.forceClosedDeliveriesData
			})
			this.gridApi.onFilterChanged();
		}
		else if(params === "all"){
			console.log("all Deliveries Data",this.state.rowData);
			this.gridApi.setRowData(this.state.rowData);
			this.gridApi.onFilterChanged();
		}
		else if (params == 'selectPrefTimeStatusCount') {
			console.log("gridData",this.state.rowData)
			let selectPrefTimeStatusCountData = this.state.rowData.filter(rec => {
				return rec.step_code == "SELECT_PREFERRED_TIME_SLOT"
			})
			this.gridApi.setRowData(selectPrefTimeStatusCountData);
			this.gridApi.onFilterChanged();
		}
		else if (params == 'confirmPrefStatusCount') {
			console.log("gridData",this.state.rowData)
			let confirmPrefStatusCountData = this.state.rowData.filter(rec => {
				return rec.step_code == "CONFIRM_PREFERRED_TIME_DELIVERY"
			})
			this.gridApi.setRowData(confirmPrefStatusCountData);
			this.gridApi.onFilterChanged();
		}
		else if (params == 'ongoingDeliveries') {
			console.log("gridData",this.state.rowData)
			let ongoingDeliveriesData = this.state.rowData.filter(rec => {
				return (rec.step_code == "SELECT_PREFERRED_DATE" || rec.step_code == "SELECT_PREFERRED_TIME_SLOT" || rec.step_code == "CONFIRM_PREFERRED_TIME_DELIVERY")
			})
			console.log("ongoingDeliveriesData",ongoingDeliveriesData)
			this.gridApi.setRowData(ongoingDeliveriesData);
			this.gridApi.onFilterChanged();
		}
		else if (params == 'invalidWhatsAppNoCount') {
			console.log("gridData",this.state.rowData)
			let invalidWhatsAppNoData = this.state.rowData.filter(rec => {
				return rec.step_code == "NOT_VALID_WHATSAPP_NUMBER"
			})
			this.gridApi.setRowData(invalidWhatsAppNoData);
			this.gridApi.onFilterChanged();
		}
		else if (params == 'noAvailableSlotsCount') {
			console.log("gridData",this.state.rowData)
			let noAvailableSlotsData = this.state.rowData.filter(rec => {
				return rec.step_code == "NO_AVAILABLE_SLOTS"
			})
			this.gridApi.setRowData(noAvailableSlotsData);
			this.gridApi.onFilterChanged();
		}
		else if (params == 'messageNotTriggeredCount') {
			console.log("gridData",this.state.rowData)
			let messageNotTriggeredData = this.state.rowData.filter(rec => {
				return rec.step_code == "NA"
			})
			this.gridApi.setRowData(messageNotTriggeredData);
			this.gridApi.onFilterChanged();
		}
		else if (params == "truck_reporting_date") {
			// console.log(this.state.truckReportedDate, "trkfilter");
			this.gridApi.setRowData(this.state.truckReportedDate);
			this.gridApi.onFilterChanged();
		}
		else if (params == "probable_accident") {
			let isFilterDataAvailable = this.state.allRowData.filter(rec => {
				if (rec["probable_accident"]) {
					return rec["probable_accident"] == 1;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if (params == "probable_accident_confirm") {
			let isFilterDataAvailable = this.state.allRowData.filter(rec => {
				if (rec["probable_accident_confirm"]) {
					return rec["probable_accident_confirm"] == 1;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if (params == "is_critical") {
			//var filterComponent = this.gridApi.getFilterInstance("is_critical");
			// filterComponent.selectNothing();
			// filterComponent.selectValue("1");
			if (this.state.tripvalue != undefined && this.state.tripvalue != '') {
				//filterComponent.selectValue(this.state.tripvalue);
			}
			// filterComponent.applyModel();
			var gridData = this.state.allRowData
			if (this.state.tnpReturnConsignmentsVisibilty) { gridData = this.state.returnConsignments }
			let isFilterDataAvailable = gridData.filter(rec => {
				if (rec["is_critical"]) {
					return rec["is_critical"] == true;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if (params == "is_priority") {
			//var filterComponent = this.gridApi.getFilterInstance("is_critical");
			// filterComponent.selectNothing();
			// filterComponent.selectValue("1");
			if (this.state.tripvalue != undefined && this.state.tripvalue != '') {
				//filterComponent.selectValue(this.state.tripvalue);
			}
			// filterComponent.applyModel();
			var gridData = this.state.allRowData
			if (this.state.tnpReturnConsignmentsVisibilty) { gridData = this.state.returnConsignments }
			let isFilterDataAvailable = gridData.filter(rec => {
				if (rec["is_priority"]) {
					return rec["is_priority"] == true;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if (params == "no_gps_data") {
			// var filterComponent = this.gridApi.getFilterInstance("no_gps_data");
			// filterComponent.selectNothing();
			// filterComponent.selectValue("1");
			// if(this.state.tripvalue != undefined && this.state.tripvalue != '')
			// {
			// 	filterComponent.selectValue(this.state.tripvalue);
			// }
			// filterComponent.applyModel();
			let rowData = this.state.allRowData			
			// const uniqueTruck = rowData.reduce((item, truck) =>{
			// 	item[truck.truck_no] = truck
			// 	return item
			// }, {})

			// const output = Object.values(uniqueTruck)
			// console.log("output", output)

			let isFilterDataAvailable = rowData.filter(rec => {
				if (rec["no_gps_data"]) {
					return rec["no_gps_data"] == 1;
				}
			});
			// console.log("isFilterDataAvailable ", isFilterDataAvailable)
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if (params == "no_gps_data_lifetime") {
			// var filterComponent = this.gridApi.getFilterInstance("no_gps_data_lifetime");
			// filterComponent.selectNothing();
			// filterComponent.selectValue("1");
			if (this.state.tripvalue != undefined && this.state.tripvalue != '') {
				this.gridApi.getFilterInstance("no_gps_data_lifetime").selectValue(this.state.tripvalue);
			}
			//this.gridApi.getFilterInstance("no_gps_data_lifetime").applyModel();
			let isFilterDataAvailable = this.state.allRowData.filter(rec => {
				if (rec["no_gps_data_lifetime"]) {
					return (rec["no_gps_data_lifetime"] == 1);
				}
			});
			// console.log("isFilterDataAvailable ", isFilterDataAvailable)
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if (params == "overspeeding_exception") {
			// console.log(this.gridApi, "this.gridApi")
			// console.log("in oveerspeeding");
			// console.log(this.state.rowData);
			let isFilterDataAvailable = this.state.allRowData.filter(rec => {
				if (rec["overspeeding_exception"]) {
					return rec["overspeeding_exception"] == 1;
				}
			});
			// console.log(isFilterDataAvailable);
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if (params == "nightdriving_exception") {
			// var filterComponent = this.gridApi.getFilterInstance("nightdriving_exception");
			// filterComponent.selectNothing();
			// filterComponent.selectValue("1");
			// if(this.state.tripvalue != undefined && this.state.tripvalue != '')
			// {
			// 	filterComponent.selectValue(this.state.tripvalue);
			// }
			// filterComponent.applyModel();
			let isFilterDataAvailable = this.state.allRowData.filter(rec => {
				if (rec["nightdriving_exception"]) {
					return rec["nightdriving_exception"] == 1;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if (params == "unloading_delay_exception") {
			// var filterComponent = this.gridApi.getFilterInstance("unloading_delay_exception");
			// filterComponent.selectNothing();
			// filterComponent.selectValue("1");
			// if(this.state.tripvalue != undefined && this.state.tripvalue != '')
			// {
			// 	filterComponent.selectValue(this.state.tripvalue);
			// }
			// filterComponent.applyModel();
			let isFilterDataAvailable = this.state.allRowData.filter(rec => {
				if (rec["unloading_delay_exception"]) {
					return rec["unloading_delay_exception"] == 1;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if(params == 'force_closed'){
			let isFilterDataAvailable = this.state.allRowData.filter(rec => {
				if (rec["status"]) {
					return rec["status"] == 6;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if (params == "loading_delay_exception") {
			// var filterComponent = this.gridApi.getFilterInstance("loading_delay_exception");
			// filterComponent.selectNothing();
			// filterComponent.selectValue("1");
			// if(this.state.tripvalue != undefined && this.state.tripvalue != '')
			// {
			// 	filterComponent.selectValue(this.state.tripvalue);
			// }
			// filterComponent.applyModel();
			let isFilterDataAvailable = this.state.allRowData.filter(rec => {
				if (rec["loading_delay_exception"]) {
					return rec["loading_delay_exception"] == 1;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else if (params == "enroute_exception") {
			var gridData = this.state.allRowData
			if (this.state.tnpReturnConsignmentsVisibilty) { gridData = this.state.returnConsignments }
			let isFilterDataAvailable = gridData.filter(rec => {
				if (rec["enroute_exception"] != undefined || rec["enroute_exception"] != '') {
					return rec["enroute_exception"] == 1;
				}
			});
			//console.log("isFilterDataAvailable Enrotue ", isFilterDataAvailable)
			if (isFilterDataAvailable.length > 0) {
				// var filterComponent = this.gridApi.getFilterInstance("enroute_exception");
				// try{
				// 	if (filterComponent) {
				// 		filterComponent.selectNothing();
				// 		filterComponent.selectValue(1);
				// 		if(this.state.tripvalue != undefined && this.state.tripvalue != '')
				// 		{
				// 			filterComponent.selectValue(this.state.tripvalue);
				// 		}
				// 		filterComponent.applyModel();
				// 	}

				// }
				// catch(e){
				// 	console.log(e)
				// }

				this.gridApi.setRowData(isFilterDataAvailable);
				this.gridApi.onFilterChanged();
			}
			else {
				this.gridApi.setRowData([]);
				this.gridApi.onFilterChanged();
			}

		}
		else if (params == "cancelled") {
			this.gridApi.setRowData(this.state.cancelled_data)
		}
		else if (params == "pod") {
			this.gridApi.setRowData(this.state.pod_received_data)
		}
		else if (params == "trip_closed") {
			this.gridApi.setRowData(this.state.trip_closed_by_pod)
		}
		else if (params == "deviation") {
			// this.gridApi.setRowData(this.state.originalcountersjson.deviated_routes)
			let isFilterDataAvailable = this.state.rowData.filter(rec => {
				if (rec["deviation_flag"]) {
					return rec["deviation_flag"] == 1;
				}
			});
			this.gridApi.setRowData(isFilterDataAvailable);
			this.gridApi.onFilterChanged();
		}
		else {
			if (this.state.tnpReturnConsignmentsVisibilty == true) {
				this.gridApi.setRowData(this.state.returnConsignments)
			}
			else if (this.state.tripvalue != undefined && this.state.tripvalue != '') {

				this.gridApi.setRowData(this.state.rowData);
			}
			else {
				if (this.props.match.path == "/prtconsignments") {
					this.gridApi.setRowData(this.state.rowData);
				}else{
					this.gridApi.setRowData(this.state.allRowData);
				}				
			}
			this.gridApi.onFilterChanged();
		}
	}
	// onSelectTrip(value){
	// 	//console.log(value.target.value)
	// 	this.setState({
	// 		triptype:value.target.value
	// 	});
	// }


	onSelectTrip = triptype => {
		this.setState(
			{ triptype },
			// () => console.log(`Trip Option selected:`, this.state.triptype)
		);

		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}

	changeConsignerArrayHandler = consigner => {
		this.setState(
			{ consigner },
			// () => console.log(`Consigner Option selected:`, this.state.consigner)
		);

		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}
	changeMovementArrayHandler = movementtype => {
		this.setState(
			{ movementtype },
			// () => console.log(`Movement Option selected:`, this.state.movementtype)
		);

	}
	selectConsignerOptionsItems() {
		let items = [];

		var plants = this.state.plants;

		items.push({ value: 'all', label: 'All' });
		if (plants.length > 0) {
			plants.forEach(item => {
				if (this.state.loginplant != "" && this.state.loginplant != undefined) {
					if (this.state.loginplant == item.consigner_code) {
						items.push({ value: item.consigner_code, label: item.consigner_name });
					}

				}
				else {
					items.push({ value: item.consigner_code, label: item.consigner_name });
				}

			});
		}


		return items;
	}

	selectMovementTypeOptionsItems() {
		let items = [];
		items.push({ value: "all", label: "All" });
		items.push({ value: true, label: "Local Movement" });
		items.push({ value: false, label: "Non Local Movement" });
		return items;
	}
	/*Venkatesh Jalla : New code for filters*/
	// onGetConsigners = async (event) => {

	// 	var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

	// 	if (this.props.match.path == "/sndconsignments" || this.props.match.path == "/prtconsignments"
	// 		|| this.props.match.path == "/tnpconsignments") {

	// 		if (this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN") {
	// 			//var sDate = this.state.defaultsdate;
	// 			var sDate = "";
	// 		}
	// 		else {
	// 			var sDate = this.state.startDate;
	// 		}
	// 		if (this.state.endDate == '' || this.state.startDate == "NaN-NaN-NaN") {
	// 			//var eDate = this.state.defaultedate;
	// 			var eDate = "";
	// 		}
	// 		else {
	// 			var eDate = this.state.endDate;
	// 		}

	// 	}
	// 	else {
	// 		if (this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN") {
	// 			var sDate = this.state.defaultsdate;
	// 		}
	// 		else {
	// 			var sDate = this.state.startDate;
	// 		}
	// 		if (this.state.endDate == '' || this.state.startDate == "NaN-NaN-NaN") {
	// 			var eDate = this.state.defaultedate;
	// 		}
	// 		else {
	// 			var eDate = this.state.endDate;
	// 		}
	// 	}

	// 	// console.log("flCity", this.state.flCity);
	// 	// console.log("flState", this.state.flState);
	// 	// console.log("flZone", this.state.flZone);
	// 	// console.log("flTransporter", this.state.flTransporter);
	// 	// var sDate=this.state.startDate;
	// 	// var eDate = this.state.endDate;
	// 	if (this.state.flTransporter != "" && this.state.flTransporter != undefined && this.state.flTransporter != null) {
	// 		var transporter_code = this.state.flTransporter.value;
	// 	}
	// 	else {
	// 		var transporter_code = "";
	// 	}

	// 	if (this.state.flZone != "" && this.state.flZone != undefined && this.state.flZone != null) {
	// 		var zone = this.state.flZone.value;
	// 	}
	// 	else {
	// 		var zone = "";
	// 	}


	// 	if (this.state.flState != "" && this.state.flState != undefined && this.state.flState != null) {
	// 		var state = this.state.flState.value;
	// 	}
	// 	else {
	// 		var state = "";
	// 	}


	// 	if (this.state.flCity != "" && this.state.flCity != undefined && this.state.flCity != null) {
	// 		var city = this.state.flCity.value;
	// 	}
	// 	else {
	// 		var city = "";
	// 	}

	// 	var selectconsigners = []
	// 	// console.log('selectconsigners',this.state.consigner)
	// 	if (this.state.consigner == null) {
	// 		selectconsigners = [{ "value": 'all', "label": 'All' }]
	// 	}
	// 	else {
	// 		selectconsigners = this.state.consigner
	// 	}

	// 	var mvmtype = []
	// 	if (this.state.movementtype == null) {
	// 		mvmtype = [{ "value": 'all', "label": 'All' }]
	// 	}
	// 	else {
	// 		mvmtype = [this.state.movementtype]
	// 	}

	// 	//console.log("this.state.movementtype ", this.state.movementtype)
	// 	//console.log("mvmtype ", mvmtype)
	// 	var selecttrip = []
	// 	// if(this.state.triptype == null)
	// 	// {
	// 	// 	selecttrip = [{"value":'all', "label":'All'}]
	// 	// }
	// 	// else{
	// 	selecttrip = [this.state.triptype]
	// 	// }
	// 	if (this.state.deptcode == 'SNDG') {
	// 		//console.log("S1 ")
	// 		var requestparams = {
	// 			consigner: selectconsigners,
	// 			dept_code: encode(this.state.deptcode),
	// 			startDate: sDate,
	// 			endDate: eDate,
	// 			transporter_code: transporter_code,
	// 			zone: zone,
	// 			state: state,
	// 			city: city
	// 		}

	// 	}
	// 	else if (this.state.deptcode == 'LOG-PRT') {
	// 		//console.log("S2 ")
	// 		var requestparams = {
	// 			consigner: selectconsigners,
	// 			dept_code: encode(this.state.deptcode),
	// 			startDate: sDate,
	// 			endDate: eDate,
	// 			movement: mvmtype,
	// 			transporter_code: transporter_code,
	// 			state: state,
	// 			city: city
	// 		}
	// 	}
	// 	else {
	// 		//console.log("S3 ")
	// 		var requestparams = {
	// 			consigner: selectconsigners,
	// 			dept_code: encode(this.state.deptcode),
	// 			startDate: sDate,
	// 			endDate: eDate,
	// 			triptype: selecttrip
	// 		}
	// 	}

	// 	//console.log("requestparams ", requestparams)

	// 	var urlpath = '';
	// 	if (this.props.match.path == "/sndconsignments") {

	// 		urlpath = '/consignments/filterSNDActiveConsignments';

	// 	}
	// 	else if (this.props.match.path == "/prtconsignments") {
	// 		urlpath = '/consignments/filterPRTActiveConsignments';

	// 	}
	// 	else if (this.props.match.path == "/tnpconsignments") {
	// 		urlpath = '/consignments/tnpconsignments';
	// 	}
	// 	else if (this.props.match.path == "/sndallconsignments") {
	// 		urlpath = '/consignments/allsndconsignments';
	// 		requestparams['screen'] = "all";
	// 	}
	// 	else if (this.props.match.path == "/insidemsilvicinty") {
	// 		urlpath = '/consignments/allsndconsignments';
	// 	}
	// 	else if (this.props.match.path == "/prtallconsignments") {
	// 		urlpath = '/consignments/allsndconsignments';
	// 		requestparams['screen'] = "all";
	// 	}
	// 	else if (this.props.match.path == "/tnpallconsignments") {
	// 		urlpath = '/consignments/alltnpconsignments';
	// 		requestparams['screen'] = "all";
	// 	}
	// 	else if (this.props.match.path == "/snddeliveredconsignments") {
	// 		urlpath = '/consignments/deliveredsndconsignments';
	// 	}
	// 	else if (this.props.match.path == "/prtdeliveredconsignments") {
	// 		urlpath = '/consignments/deliveredprtconsignments';
	// 	}
	// 	else if (this.props.match.path == "/tnpdeliveredconsignments") {
	// 		urlpath = '/consignments/deliveredtnpconsignments';
	// 	}
	// 	else if (this.props.match.path == "/railconsignments") {
	// 		urlpath = '/consignments/railsndconsignments';
	// 	}
	// 	else if (this.props.match.path == "/ncrconsignments") {
	// 		urlpath = '/consignments/ncrsndconsignments';
	// 	}
	// 	else if (this.props.match.path == "/moveconsignments") {
	// 		urlpath = '/consignments/movesndconsignments';
	// 	}
	// 	// console.log("urlpath",urlpath,"requestparams",requestparams)
	// 	this.onLoadGetConsignmentsList(urlpath, requestparams)
	// }
	// onGetConsigners = async (event) => {

	// 	var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

	// 	if (this.props.match.path == "/sndconsignments" || this.props.match.path == "/prtconsignments"
	// 		|| this.props.match.path == "/tnpconsignments") {

	// 		if (this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN") {
	// 			//var sDate = this.state.defaultsdate;
	// 			var sDate = "";
	// 		}
	// 		else {
	// 			var sDate = this.state.startDate;
	// 		}
	// 		if (this.state.endDate == '' || this.state.startDate == "NaN-NaN-NaN") {
	// 			//var eDate = this.state.defaultedate;
	// 			var eDate = "";
	// 		}
	// 		else {
	// 			var eDate = this.state.endDate;
	// 		}

	// 	}
	// 	else {
	// 		if (this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN") {
	// 			var sDate = this.state.defaultsdate;
	// 		}
	// 		else {
	// 			var sDate = this.state.startDate;
	// 		}
	// 		if (this.state.endDate == '' || this.state.startDate == "NaN-NaN-NaN") {
	// 			var eDate = this.state.defaultedate;
	// 		}
	// 		else {
	// 			var eDate = this.state.endDate;
	// 		}
	// 	}

	// 	// console.log("flCity", this.state.flCity);
	// 	// console.log("flState", this.state.flState);
	// 	// console.log("flZone", this.state.flZone);
	// 	// console.log("flTransporter", this.state.flTransporter);
	// 	// var sDate=this.state.startDate;
	// 	// var eDate = this.state.endDate;
	// 	if (this.state.flTransporter != "" && this.state.flTransporter != undefined && this.state.flTransporter != null) {
	// 		var transporter_code = this.state.flTransporter.value;
	// 	}
	// 	else {
	// 		var transporter_code = "";
	// 	}

	// 	if (this.state.flZone != "" && this.state.flZone != undefined && this.state.flZone != null) {
	// 		var zone = this.state.flZone.value;
	// 	}
	// 	else {
	// 		var zone = "";
	// 	}


	// 	if (this.state.flState != "" && this.state.flState != undefined && this.state.flState != null) {
	// 		var state = this.state.flState;
	// 	}
	// 	else {
	// 		var state = "";
	// 	}


	// 	if (this.state.flCity != "" && this.state.flCity != undefined && this.state.flCity != null) {
	// 		var city = this.state.flCity;
	// 	}
	// 	else {
	// 		var city = "";
	// 	}

	// 	var selectconsigners = []
	// 	// console.log('selectconsigners',this.state.consigner)
	// 	if (this.state.flConsigner == null || this.state.flConsigner == undefined || this.state.flConsigner == "") {
	// 		selectconsigners = [{ "value": 'all', "label": 'All' }]
	// 	}
	// 	else {
	// 		selectconsigners = this.state.flConsigner.value;
	// 	}

	// 	var selectconsignees = []
	// 	// console.log('selectconsignees',this.state.consignee)
	// 	if (this.state.flConsignee == null || this.state.flConsignee == undefined || this.state.flConsignee == "") {
	// 		selectconsignees = [{ "value": 'all', "label": 'All' }]
	// 	}
	// 	else {
	// 		selectconsignees = this.state.flConsignee.value;
	// 	}
	// 	console.log("selectconsignees", selectconsignees)

	// 	var mvmtype = []
	// 	if (this.state.movementtype == null) {
	// 		mvmtype = [{ "value": 'all', "label": 'All' }]
	// 	}
	// 	else {
	// 		mvmtype = [this.state.movementtype]
	// 	}

	// 	//console.log("this.state.movementtype ", this.state.movementtype)
	// 	//console.log("mvmtype ", mvmtype)
	// 	var selecttrip = []
	// 	// if(this.state.triptype == null)
	// 	// {
	// 	// 	selecttrip = [{"value":'all', "label":'All'}]
	// 	// }
	// 	// else{
	// 	selecttrip = [this.state.triptype]
	// 	// }
	// 	if (this.state.deptcode == 'SNDG') {
	// 		//console.log("S1 ")
	// 		var requestparams = {
	// 			consigner: selectconsigners,
	// 			consignee: selectconsignees,
	// 			dept_code: encode(this.state.deptcode),
	// 			startDate: sDate,
	// 			endDate: eDate,
	// 			transporter_code: transporter_code,
	// 			zone: zone,
	// 			state: state,
	// 			city: city
	// 		}

	// 	}
	// 	else if (this.state.deptcode == 'LOG-PRT') {
	// 		//console.log("S2 ")
	// 		if (state !== "" && city !== ""){
	// 			var requestparams = {
	// 				consigner: selectconsigners,
	// 				consignee: selectconsignees,
	// 				// dept_code: encode(this.state.deptcode),
	// 				startDate: sDate,
	// 				endDate: eDate,
	// 				// movement: mvmtype,
	// 				// transporter_code: transporter_code,
	// 				state: state,
	// 				city: city
	// 			}
	// 		} else if (state !== "") {
	// 			var requestparams = {
	// 				consigner: selectconsigners,
	// 				consignee: selectconsignees,
	// 				// dept_code: encode(this.state.deptcode),
	// 				startDate: sDate,
	// 				endDate: eDate,
	// 				// movement: mvmtype,
	// 				// transporter_code: transporter_code,
	// 				state: state
	// 			}
	// 		} else {
	// 			var requestparams = {
	// 				consigner: selectconsigners,
	// 				consignee: selectconsignees,
	// 				// dept_code: encode(this.state.deptcode),
	// 				startDate: sDate,
	// 				endDate: eDate,
	// 				// movement: mvmtype,
	// 				// transporter_code: transporter_code,
	// 				city: city
	// 			}
	// 		}
			
	// 	}
	// 	else {
	// 		//console.log("S3 ")
	// 		var requestparams = {
	// 			consigner: selectconsigners,
	// 			consignee: selectconsignees,
	// 			dept_code: encode(this.state.deptcode),
	// 			startDate: sDate,
	// 			endDate: eDate,
	// 			triptype: selecttrip,
	// 			state: state,
	// 			city: city
	// 		}
	// 	}

	// 	//console.log("requestparams ", requestparams)

	// 	var urlpath = '';
	// 	if (this.props.match.path == "/sndconsignments") {

	// 		urlpath = '/consignments/filterSNDActiveConsignments';

	// 	}
	// 	else if (this.props.match.path == "/prtconsignments") {
	// 		urlpath = '/consignments/filterPRTActiveConsignments';

	// 	}
	// 	else if (this.props.match.path == "/tnpconsignments") {
	// 		urlpath = '/consignments/tnpconsignments';
	// 	}
	// 	else if (this.props.match.path == "/sndallconsignments") {
	// 		urlpath = '/consignments/allsndconsignments';
	// 		requestparams['screen'] = "all";
	// 	}
	// 	else if (this.props.match.path == "/insidemsilvicinty") {
	// 		urlpath = '/consignments/allsndconsignments';
	// 	}
	// 	else if (this.props.match.path == "/prtallconsignments") {
	// 		urlpath = '/consignments/allsndconsignments';
	// 		requestparams['screen'] = "all";
	// 	}
	// 	else if (this.props.match.path == "/tnpallconsignments") {
	// 		urlpath = '/consignments/alltnpconsignments';
	// 		requestparams['screen'] = "all";
	// 	}
	// 	else if (this.props.match.path == "/snddeliveredconsignments") {
	// 		urlpath = '/consignments/deliveredsndconsignments';
	// 	}
	// 	else if (this.props.match.path == "/prtdeliveredconsignments") {
	// 		urlpath = '/consignments/deliveredprtconsignments';
	// 	}
	// 	else if (this.props.match.path == "/tnpdeliveredconsignments") {
	// 		urlpath = '/consignments/deliveredtnpconsignments';
	// 	}
	// 	else if (this.props.match.path == "/railconsignments") {
	// 		urlpath = '/consignments/railsndconsignments';
	// 	}
	// 	else if (this.props.match.path == "/ncrconsignments") {
	// 		urlpath = '/consignments/ncrsndconsignments';
	// 	}
	// 	else if (this.props.match.path == "/moveconsignments") {
	// 		urlpath = '/consignments/movesndconsignments';
	// 	}
	// 	// console.log("urlpath",urlpath,"requestparams",requestparams)
	// 	this.onLoadGetConsignmentsList(urlpath, requestparams)
	// }
	onGetConsigners = async (event) => {

		var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

		if (this.props.match.path == "/sndconsignments" || this.props.match.path == "/prtconsignments"
			|| this.props.match.path == "/tnpconsignments") {

			if (this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN") {
				//var sDate = this.state.defaultsdate;
				var sDate = "";
			}
			else {
				var sDate = this.state.startDate;
			}
			if (this.state.endDate == '' || this.state.startDate == "NaN-NaN-NaN") {
				//var eDate = this.state.defaultedate;
				var eDate = "";
			}
			else {
				var eDate = this.state.endDate;
			}

		}
		else {
			if (this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN") {
				var sDate = this.state.defaultsdate;
			}
			else {
				var sDate = this.state.startDate;
			}
			if (this.state.endDate == '' || this.state.startDate == "NaN-NaN-NaN") {
				var eDate = this.state.defaultedate;
			}
			else {
				var eDate = this.state.endDate;
			}
		}

		// console.log("flCity", this.state.flCity);
		// console.log("flState", this.state.flState);
		// console.log("flZone", this.state.flZone);
		// console.log("flTransporter", this.state.flTransporter);
		// var sDate=this.state.startDate;
		// var eDate = this.state.endDate;
		// if (this.state.flTransporter != "" && this.state.flTransporter != undefined && this.state.flTransporter != null) {
		// 	var transporter_code = this.state.flTransporter.value;
		// }
		// else {
		// 	var transporter_code = "";
		// }

		// if (this.state.flZone != "" && this.state.flZone != undefined && this.state.flZone != null) {
		// 	var zone = this.state.flZone.value;
		// }
		// else {
		// 	var zone = "";
		// }


		if (this.state.flState != "" && this.state.flState != undefined && this.state.flState != null) {
			var state = this.state.flState;
		}
		else {
			var state = "";
		}


		if (this.state.flCity != "" && this.state.flCity != undefined && this.state.flCity != null) {
			var city = this.state.flCity;
		}
		else {
			var city = "";
		}

		var selectconsigners = []
		var consigner = this.state.flConsigner;

		console.log('selectconsigners',consigner)
		if (consigner == null || consigner == undefined || consigner == "") {
			consigner = [{ "value": 'all', "label": 'All' }]
			selectconsigners = consigner.map(e => e.value);
		} else {
			selectconsigners = consigner.map(e => e.value);
		}
		
		console.log('selectconsigners',selectconsigners)
		var selectconsignees = []
		var consignee = this.state.flConsignee;
		
		if (this.state.flConsignee == null || this.state.flConsignee == undefined || this.state.flConsignee == "") {
			consignee = [{ "value": 'all', "label": 'All' }]
			selectconsignees = consignee.map(e => e.value);
		} else {
			selectconsignees = this.state.flConsignee.map(e => e.value);
		}
		
		console.log("selectconsignees", selectconsignees)

		var selectareas = []
		var areas = this.state.flArea;
		if (this.state.flArea == null || this.state.flArea == undefined || this.state.flConsignee == "") {
			areas = [{ "value": 'all', "label": 'All' }]
			selectareas = areas.map(e => e.value)
		} else {
			selectareas = this.state.flArea.map(e => e.value)
		}
		console.log('selected area', selectareas)
		var mvmtype = []
		if (this.state.movementtype == null) {
			mvmtype = [{ "value": 'all', "label": 'All' }]
		}
		else {
			mvmtype = [this.state.movementtype]
		}

		//console.log("this.state.movementtype ", this.state.movementtype)
		//console.log("mvmtype ", mvmtype)
		var selecttrip = []
		// if(this.state.triptype == null)
		// {
		// 	selecttrip = [{"value":'all', "label":'All'}]
		// }
		// else{
		selecttrip = [this.state.triptype]
		// }
		if (this.state.deptcode == 'SNDG') {
			//console.log("S1 ")
			var requestparams = {
				consigner: selectconsigners,
				consignee: selectconsignees,
				areas: selectareas,
				// dept_code: encode(this.state.deptcode),
				startDate: sDate,
				endDate: eDate,
				// transporter_code: transporter_code,
				// zone: zone,
				state: state.value,
				city: city.value
			}

		}
		else if (this.state.deptcode == 'LOG-PRT') {
			//console.log("S2 ")
			if (state !== "" && city !== ""){
				var requestparams = {
					consigner: selectconsigners,
					consignee: selectconsignees,
					areas: selectareas,
					// dept_code: encode(this.state.deptcode),
					startDate: sDate,
					endDate: eDate,
					// movement: mvmtype,
					// transporter_code: transporter_code,
					state: state.value,
					city: city.value
				}
			} else if (state !== "") {
				var requestparams = {
					consigner: selectconsigners,
					consignee: selectconsignees,
					areas: selectareas,
					// dept_code: encode(this.state.deptcode),
					startDate: sDate,
					endDate: eDate,
					// movement: mvmtype,
					// transporter_code: transporter_code,
					state: state.value
				}
			} else if (city !== "") {
				var requestparams = {
					consigner: selectconsigners,
					consignee: selectconsignees,
					areas: selectareas,
					// dept_code: encode(this.state.deptcode),
					startDate: sDate,
					endDate: eDate,
					// movement: mvmtype,
					// transporter_code: transporter_code,
					city: city.value,
				}
			} else {
				var requestparams = {
					consigner: selectconsigners,
					consignee: selectconsignees,
					areas: selectareas,
					// dept_code: encode(this.state.deptcode),
					startDate: sDate,
					endDate: eDate,
					// movement: mvmtype,
					// transporter_code: transporter_code,
				}
			}
			
		}
		else {
			//console.log("S3 ")
			var requestparams = {
				consigner: selectconsigners,
				consignee: selectconsignees,
				areas: selectareas,
				dept_code: encode(this.state.deptcode),
				startDate: sDate,
				endDate: eDate,
				triptype: selecttrip,
				state: state.value,
				city: city.value
			}
		}

		//console.log("requestparams ", requestparams)

		var urlpath = '';
		if (this.props.match.path == "/sndconsignments") {

			urlpath = '/consignments/filterSNDActiveConsignments';

		}
		else if (this.props.match.path == "/prtconsignments") {
			urlpath = '/consignments/filterPRTActiveConsignments';

		}
		else if (this.props.match.path == "/tnpconsignments") {
			urlpath = '/consignments/tnpconsignments';
		}
		else if (this.props.match.path == "/sndallconsignments") {
			urlpath = '/consignments/allsndconsignments';
			requestparams['screen'] = "all";
		}
		else if (this.props.match.path == "/insidemsilvicinty") {
			urlpath = '/consignments/allsndconsignments';
		}
		else if (this.props.match.path == "/prtallconsignments") {
			urlpath = '/consignments/allsndconsignments';
			requestparams['screen'] = "all";
		}
		else if (this.props.match.path == "/tnpallconsignments") {
			urlpath = '/consignments/alltnpconsignments';
			requestparams['screen'] = "all";
		}
		else if (this.props.match.path == "/snddeliveredconsignments") {
			urlpath = '/consignments/deliveredsndconsignments';
		}
		else if (this.props.match.path == "/prtdeliveredconsignments") {
			urlpath = '/consignments/deliveredprtconsignments';
		}
		else if (this.props.match.path == "/tnpdeliveredconsignments") {
			urlpath = '/consignments/deliveredtnpconsignments';
		}
		else if (this.props.match.path == "/railconsignments") {
			urlpath = '/consignments/railsndconsignments';
		}
		else if (this.props.match.path == "/ncrconsignments") {
			urlpath = '/consignments/ncrsndconsignments';
		}
		else if (this.props.match.path == "/moveconsignments") {
			urlpath = '/consignments/movesndconsignments';
		}
		console.log("urlpath",urlpath,"requestparams",requestparams)
		this.onLoadGetConsignmentsList(urlpath, requestparams)
	}

	getTruckConsignments = (event) => {
		this.setState({
			loadshow: "show-m",
			overly: "show-m"
		})
		event.preventDefault();
		// var selectedFilter = $("#selectedFilter").text();
		let selectedFilter = this.state.selectedFilter
		var consigneeCode = localStorage.getItem("consignee_code")
		// console.log("selectedFilter",selectedFilter)
		var inputConsignmentNo = $("#inputConsignmentNo").val();
		if (selectedFilter == "Select..." || inputConsignmentNo == "") {
			this.setState({
				basicTitle: "Fields Should not be Empty",
				basicType: "danger",
				show: true,
				loadshow: "show-n",
				overly: "show-n"
			})
		}
		else {
			//console.log(selectedFilter,inputConsignmentNo);
			var urlpath = ""
			if (this.props.match.path == "/sndconsignments" ||
				this.props.match.path == "/prtconsignments" ||
				this.props.match.path == "/tnpconsignments") {
				urlpath = "/consignments/filterByConsignment";
			}
			if (this.props.match.path == "/snddeliveredconsignments" ||
				this.props.match.path == "/prtdeliveredconsignments" ||
				this.props.match.path == "/tnpdeliveredconsignments") {
				urlpath = "/consignments/filterDeliveredByConsignment";
			}
			if (this.props.match.path == "/ncrconsignments") {
				urlpath = "/consignments/filterNCRByConsignment"
			}
			if (this.props.match.path == "/sndallconsignments" ||
				this.props.match.path == "/prtallconsignments" ||
				this.props.match.path == "/tnpallconsignments") {
				urlpath = "/consignments/filterAllByConsignment";
			}
			if (this.props.match.path == "/insidemsilvicinty") {
				urlpath = "/consignments/filterInsideMSILByConsignment";
			}
			var transporter_code = "";
			var transportercode = localStorage.getItem('transportercode');
			if (transportercode != undefined && transportercode != "undefined" && transportercode != "") {
				var tptCode = JSON.parse(transportercode);
				var transporter_code = tptCode[0];
			}
			var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
			var params = {
				"inputConsignmentNo": inputConsignmentNo,
			}
			if (consigneeCode != "" && consigneeCode != null && consigneeCode != undefined) {
				params.consigneeCode = consigneeCode
			}
			redirectURL.post(urlpath, params)
				.then((response) => {
					var counters = response.data.counters
					// if (this.state.tnpReturnConsignmentsVisibilty) {
					// 	counters.transit_delay = [{ "transit_delay_count": this.state.returnConsignments.filter(e => e.transit_delay == 1).length }];
					// 	counters.enroute_stoppage = [{ "enroute_exception_count": this.state.returnConsignments.filter(e => e.enroute_exception == 1).length }];
					// 	counters.criticalCount = [{ "criticalCount": this.state.returnConsignments.filter(e => e.is_critical == true).length }];
					// }
					// counters.deviated_routes = [{ "deviated_routes": response.data.consignments.filter(e => e.deviation_flag == true).length }];
					this.setState({
						rowData: response.data.consignments,
						allRowData: response.data.consignments,
						countersjson: counters,
						loadshow: "show-n",
						overly: "show-n"
					})
				}).catch = ((e) => {
					console.log(e);
				})
		}

	}
	onClickTripButton = async () => {

		if (this.state.startDate == '' || this.state.startDate == "NaN-NaN-NaN") {
			var sDate = this.state.defaultsdate;
		}
		else {
			var sDate = this.state.startDate;
		}
		if (this.state.endDate == '' || this.state.startDate == "NaN-NaN-NaN") {
			var eDate = this.state.defaultedate;
		}
		else {
			var eDate = this.state.endDate;
		}
		// var sDate=this.state.startDate;
		// var eDate = this.state.endDate;
		var selectconsigners = []
		if (this.state.consigner == null) {
			selectconsigners = [{ "value": 'all', "label": 'All' }]
		}
		else {
			selectconsigners = this.state.consigner
		}

		var selecttrip = []
		if (this.state.triptype == null) {
			selecttrip = { "value": 'all', "label": 'All' }
		}
		else {
			selecttrip = this.state.triptype
		}
		var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		var requestparams = {
			consigner: selectconsigners,
			dept_code: encode(this.state.deptcode),
			startDate: sDate,
			endDate: eDate,
			triptype: selecttrip,
			returnConsignments: this.state.tnpReturnConsignmentsVisibilty
		}

		var urlpath = '';
		if (this.props.match.path == "/tnpconsignments") {
			urlpath = '/consignments/tnpconsignments';
			var transportercode = localStorage.getItem('transportercode');
			if (transportercode != undefined && transportercode != "undefined" && transportercode != "") {
				var tptCode = JSON.parse(transportercode);
				requestparams.transporter_code = tptCode[0];
			}
		}

		else if (this.props.match.path == "/tnpallconsignments") {
			urlpath = '/consignments/alltnpconsignments';
		}

		else if (this.props.match.path == "/tnpdeliveredconsignments") {
			urlpath = '/consignments/deliveredtnpconsignments';
		}

		this.onLoadGetConsignmentsList(urlpath, requestparams)
	}


	handlerStartDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());
		if (this.state.endDate != undefined && this.state.endDate != "") {
			// console.log(startdate,"startdate")
			// console.log(this.state.endDate,"endDate")
			var startDate = new Date(startdate);
			var endDate = new Date(this.state.endDate);
			var startTime = startDate.getTime();
			var endTime = endDate.getTime();
			// console.log(startTime,endTime);
			if (startTime > endTime) {
				this.setState({
					startDate: this.state.startDate,
					show: true,
					basicType: "danger",
					basicTitle: "From Date shouldn't be greater than To Date"
				});
			}
			else {
				this.setState({
					startDate: startdate
				});
			}
		}
		else {
			this.setState({
				startDate: startdate
			});
		}
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}

	handlerEndDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);
		var edate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate())
		if (this.state.endDate != undefined && this.state.endDate != "") {
			// console.log(this.state.startDate,"startdate")
			// console.log(edate,"endDate")
			var startDate = new Date(this.state.startDate);
			var endDate = new Date(edate);
			var startTime = startDate.getTime();
			var endTime = endDate.getTime();
			// console.log(startTime,endTime);
			if (startTime > endTime) {
				this.setState({
					endDate: this.state.endDate,
					show: true,
					basicType: "danger",
					basicTitle: "To Date shouldn't be lesser than From Date"
				});
			}
			else {
				this.setState({
					endDate: edate
				});
			}
		}
		else {
			this.setState({
				endDate: edate
			});
		}
	}
	onRowSelection(event) {
		var containerslist = [];
		//console.log("rowselect", event)
		var rwCount = event.api.getSelectedNodes();
		//console.log("rwCount ", rwCount)
		//if(event.node.selected == true)
		//{
		//var containerlist=[];
		//console.log("rowselect", event.data)
		var rowCount = event.data
		//console.log("rowCount ", rowCount)

		rwCount.map((item) => {
			containerslist.push(item.data.truck_no)
		});

		this.setState({
			containerslist: containerslist
		})
		//console.log("checkedlist ", containerslist)
		// }
		// else{
		// 	if(containerslist.length > 0)
		// 	{
		// 		containerslist=[]
		// 		this.setState({
		// 			containerslist:containerslist
		// 		})
		// 	}
		// }

	}
	onClickShowMapView = async () => {
		//this.gridApi.deselectAll();
		if (this.state.containerslist.length > 0) {
			var reqparms = {
				truck_no: this.state.containerslist,
				dept_code: this.state.deptcode
			}
		}
		else {
			var reqparms = {
				truck_no: [],
				dept_code: this.state.deptcode
			}
		}

		await redirectURL.post("/consignments/mapview", reqparms)
			.then((response) => {
				//console.log("Res ",response.data.message)
				var records = response.data.message;

				var truck = JSON.parse(records.truck_info);
				if (truck.length > 0) {
					if (truck.length > 1) {
						var consinees = this.state.plants;
					}
					else {
						var consinees = records.consignee_coordinates;
					}
				}
				else {
					var consinees = [];
				}
				// console.log("truckinfo,", truck)
				//console.log("truck.consignee_coordinates,",JSON.parse(records.consignee_coordinates))
				this.setState({
					open: true,
					maprowData: truck,
					rownode: truck,
					maptruckno: records.truck_no,
					consigneecoords: consinees,
					consigner_coordinates: records.consigner_coordinates
				});


				//containerslist=[];
			})
			.catch(function (e) {
				console.log("Error ", e)
			})
	}
	onOpenModal = () => {
		this.setState({ open: true });
	};

	onCloseModal = () => {
		this.gridApi.deselectAll();
		this.setState({ open: false });
	};
	changeSegmentHandler = (e) => {
		//  alert(e.consignment_code);
		this.setState({
			sliderSegmentTranslate: "slider-translate-60p",
			segementConsignmentCode: e.consignment_code,
			overly: "show-m"
		})
	}

	onOpenForceCloseModal = () => {
		this.setState({ openforceclose: true });
	};

	onCloseForceCloseModal = () => {
		this.gridApi.deselectAll();
		this.setState({ openforceclose: false });
	};

	openActiveOrdersForceCloseModal = () => {
		this.setState({ activeOrdersForceCloseModal: true });
	};

	closeActiveOrdersForceCloseModal = () => {
		this.setState({ 
			activeOrdersForceCloseModal: false,
			forceCloseConsignmentNo:"",
			forceCloseConsigneeCode:"",
			forceCloseRemarks:"",
		});

	};

	handleActiveOrdersForceClose = (e) => {
		e.preventDefault();
		let consignmentNo = this.state.forceCloseConsignmentNo;
		let consigneeCode = this.state.forceCloseConsigneeCode;
		let colDefFieldName = this.state.colDefFieldName;
		let remarks = this.state.forceCloseRemarks
		// let deliveryStatus = this.state.delivery_status;

		if (colDefFieldName == "force_close"){
			var params = {
				consignmentNo,
				consigneeCode,
				remarks,
				// deliveryStatus: deliveryStatus.value,				
			}
			redirectURL.post("/consignments/forceCloseConsignment", params).then((res) => {
				if(res.data.status == "Success") {
					this.setState({
						basicTitle:"Updated Successfully.",
						basicType : "success",
						show : true,
						forceCloseConsignmentNo:"",
						forceCloseConsigneeCode:"",
						forceCloseRemarks:"",
						// delivery_status:"",
						activeOrdersForceCloseModal:false
					})
				}
				else
				{
					this.setState({
						basicTitle: res.data.message,
						basicType : "warning",
						basicTitle:"Error in updating",
						show : true,
						forceCloseConsignmentNo:"",
						forceCloseConsigneeCode:"",
						forceCloseRemarks:"",
						activeOrdersForceCloseModal:false
					})
				}
			})
		}
	}


	onCellUpdateData = (params) => {
		// console.log("Parms ", params);
		var reqs = {
			rowid: params.data._id,
			tnpconsignment: params.value
		}
		redirectURL.post("consignments/updatetnpmarking", reqs)
			.then((response) => {
				// console.log("Res ", response);
			})
	}
	async onShowSidebarAquireComponent(e) {

		// console.log(e);
		if (e.colDef.field == 'truck_no') {
			this.setState({
				sliderTranslatesidebar: "",
				loadshow: 'show-m'
			});
			let ComponentInfo = ComponentIndex['truck'];
			//console.log("ComponentInfo", ComponentInfo);
			var layoutdata1 = {
				truck_no: e.data['truck_no']
			};
			//console.log("layoutdata",layoutdata);
			await redirectURL.post('/consignments/truckDetails', layoutdata1)
				.then((response) => {
					// console.log("trucks response", response);
					var records = JSON.parse(JSON.stringify(response)).data;
					var truckrecords = records.truck;
					var consignrecords = records.consignments;
					var currenttrucklocation = records.maplocation;
					// console.log("Current Location",currenttrucklocation);
					this.setState({
						truckInfo: truckrecords,
						consignments: consignrecords,
						currenttrucklocation: currenttrucklocation
					});
				})
				.catch(function (error) {
					console.log(error);
				});
			this.displayData = await (<ComponentInfo context={this} truckNo={e.data[e.colDef.field]} truckInfo={this.state.truckInfo} consignments={this.state.consignments} currenttrucklocation={this.state.currenttrucklocation}></ComponentInfo>);
			this.setState({
				showdata: this.displayData,
				sliderTranslatesidebar: "slider-translate",
				loadshow: 'show-n',
				overly: 'show-m'
			});
		}
		else if (e.colDef.field == 'consignment_code') {
			this.setState({
				sliderTranslatesidebar: "",
				loadshow: 'show-m'
			});
			let ComponentInfo = ComponentIndex['consignment'];
			//console.log("ComponentInfo", ComponentInfo);
			var layoutdata1 = {
				consignment_code: e.data['consignment_code'],
				consignee_code: e.data['consignee_code']
			};
			// console.log(layoutdata1);
			await redirectURL.post('/consignments/consignmentDetails', layoutdata1)
				.then((response) => {
					// console.log("trucks response", response.data[0]);
					var consignment_details = response.data;
					// If gateouttime is current time - It shows "No Route data available"

					this.setState({
						consignment_details: consignment_details,
						//consignments:consignrecords,
						//currenttrucklocation:currenttrucklocation
					});
					// console.log(this.state.consignment_details);
				})
				.catch(function (error) {
					console.log(error);
				});
			try {
				this.displayData = await (<ComponentInfo context={this} consignment={this.state.consignment_details}></ComponentInfo>);
				this.setState({
					showdata: this.displayData,
					sliderTranslatesidebar: "slider-translate",
					loadshow: 'show-n',
					overly: 'show-m'
				});
			}
			catch (e) {
				this.setState({
					showdata: "",
					sliderTranslatesidebar: "",
					loadshow: 'show-n',
					overly: 'show-n'
				});
			}

		}
	}


	handlerReachedDealerTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

		this.setState({
			reached_dealer_time: startdate
		});
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}

	handlerLeftDealerTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

		this.setState({
			left_dealer_time: startdate
		});
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}

	handlerInvoiceTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);

		var startdate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + (d.getDate() < 10 ? "0" + d.getDate() : d.getDate());

		this.setState({
			invoice_time: startdate
		});
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}
	onClickForceClose = (params) => {
		//console.log("Pareas ", params)
		this.setState({
			forceclosedata: params.data,
			forceCloseRowNode: params,
			sliderForceCloseTranslate: "slider-translate-40p",
			overly: 'show-m',
			reasonforceclose: ""
		})
	}
	handleInput(val) {
		this.setState({ reasonforceclose: val[1], selectedReason: val[0] });
		// console.log(val)
	}
	changeHandler = (event) => {
		//console.log("Select", event.target.value);
		let name = event.target.name;
		let value = event.target.value;
		if(name == "customer_mobile_no")
        {
            if(value !="" && value !=undefined)
            {
                if(value > 0)
                {
                    this.setState({[name]:value.replace(/\D/g,'')});
                }
            }
            else
            {
                this.setState({[name]:value.replace(/\D/g,'')});
            }
        }
        else
        {
            this.setState({[name]:value});
    	}
		// this.setState({ [name]: value });
	}
	formForceCloseHandler(event) {
		event.preventDefault()
		var cnf = window.confirm("Are you sure you want to force close item?");
		if (cnf == true) {
			var consignemnt = this.state.forceCloseRowNode.data;
			var consignment_code = consignemnt.consignment_code;
			var invoice_no = consignemnt.invoice_no;
			var consignee_code = consignemnt.consignee_code;

			var truck_no = consignemnt.truck_no;
			var dept_code = this.state.deptcode;
			var force_closure_time = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
			var userid = localStorage.getItem('userid');
			var reasonforceclose = this.state.reasonforceclose;
			var others_Reason = this.state.selectedReason;
			//  var reached_dealer_time =  moment.parseZone(new Date(this.state.reached_dealer_time)).format("YYYY-MM-DD")+" "+this.state.reachedhh+":"+this.state.reachedmm+":"+this.state.reachedss;
			//  var left_dealer = moment.parseZone(new Date(this.state.left_dealer_time)).format("YYYY-MM-DD")+" "+this.state.lefthh+":"+this.state.leftmm+":"+this.state.leftss;

			// 	var reached_dealer_time =  document.getElementById("reached_dealer_time").value;
			// 	if(reached_dealer_time != '')
			// 	{
			// 		var rtime = reached_dealer_time.split(" ");
			// 		var splitrtime = rtime[0].split("-");
			// 		var reachdealertime = splitrtime[2]+"-"+splitrtime[1]+"-"+splitrtime[0]+" "+rtime[1]+":00";
			// 		//console.log("reached_dealer_time ", reachdealertime)
			// 	}
			//    else{
			// 	var reachdealertime = "";
			//    }
			// 	var left_dealer = document.getElementById("left_dealer_time").value;
			// 	if(left_dealer != '')
			// 	{
			// 		var ltime = left_dealer.split(" ");
			// 		var splitltime = ltime[0].split("-");
			// 		var leftdealertime = splitltime[2]+"-"+splitltime[1]+"-"+splitltime[0]+" "+ltime[1]+":00";
			// 		//console.log("leftdealertime ", leftdealertime)
			// 	}
			// 	else
			// 	{
			// 		var leftdealertime=""
			// 	}

			if (this.state.deptcode == "SNDG") {
				//console.log(consignemnt.invoice_time);
				var invoice_time = getHyphenYYYYMMDDHHMMSS(consignemnt.invoice_time);
				var reqdata = {
					dept_code: dept_code,
					consignment_code: [consignment_code],
					invoice_no: [invoice_no],
					//consignee_code:consignee_code,
					truck_no: truck_no,
					//force_closure_time:force_closure_time,
					userid: userid,
					reasonforceclose: reasonforceclose,
					others_Reason: others_Reason,
					screen: ""
					// reached_dealer_time:reachdealertime,
					//invoice_time:invoice_time,
					// left_dealer:leftdealertime
				}
			}
			else {
				var reqdata = {
					dept_code: dept_code,
					consignment_code: [consignment_code],
					invoice_no: [invoice_no],
					//consignee_code:"",
					truck_no: truck_no,
					//force_closure_time:force_closure_time,
					userid: userid,
					screen: "",
					reasonforceclose: reasonforceclose,
					others_Reason: others_Reason,
					// reached_dealer_time:reachdealertime,
					//invoice_time:"",
					// left_dealer:leftdealertime
				}
			}
			var flag = 0;
			if (others_Reason == "" && reasonforceclose == "Others") {
				flag = 1;
			}
			if (flag == 0 && reasonforceclose == "Others") {
				//    console.log(others_Reason.replace(/\s/g,"").length,"lengt");
				if (others_Reason.replace(/\s/g, "").length == 0) {
					flag = 1;
				}
			}
			// console.log("reqdata ",reqdata)
			if (flag == 0) {
				reqdata["returnConsignment"] = this.state.tnpReturnConsignmentsVisibilty
				redirectURL.post("/consignments/updateConsignmentForceClose", reqdata)
					.then((response) => {
						//console.log("Resposne updateConsignmentForceClose ", response)
						// console.log(response);
						if (response.data.status == 'Success') {
							// document.getElementById("reached_dealer_time").value='';
							// document.getElementById("left_dealer_time").value='';
							this.setState({
								show: true,
								basicTitle: "Success",
								basicType: "success",
								loadshow: 'show-n',
								overly: 'show-n',
								left_dealer_time: '',
								reached_dealer_time: '',
								reachedhh: '',
								reachedmm: '',
								reachedss: '',
								lefthh: '',
								leftmm: '',
								leftss: '',
								reasonforceclose: "",
								openforceclose: false,
								sliderForceCloseTranslate: '',
								sliderTranslatesidebar: "",
							});
						}
						else {
							this.setState({
								show: true,
								basicTitle: "Error",
								basicType: "danger",
								loadshow: 'show-n',
								overly: 'show-n',
								left_dealer_time: '',
								sliderTranslatesidebar: "",
								reached_dealer_time: '',
								reachedhh: '',
								reachedmm: '',
								reachedss: '',
								lefthh: '',
								leftmm: '',
								leftss: '',
								reasonforceclose: '',
								openforceclose: false,
								sliderForceCloseTranslate: ''
							});
						}

					})
					.catch(function (e) {
						console.log("Error ", e)
					})
			}
			else {
				this.setState({
					show: true,
					basicTitle: 'Reason for closue should not be empty',
					basicType: "danger",
				});
			}
			event.target.reset();
		}
		else {

		}
		//  if(this.state.deptcode == 'SNDG')
		//  {
		// 	 var invoice_time = moment.parseZone(this.state.invoice_time).format("YYYY-MM-DD")+" "+this.state.invoicehh+":"+this.state.invoicemm+":"+this.state.invoicess;
		//  }
		//  else{
		// 	var invoice_time=""
		//  }

	}

	onClickSaveGridState() {
		window.colState = this.gridColumnApi.getColumnState();
		window.groupState = this.gridColumnApi.getColumnGroupState();
		window.sortState = this.gridApi.getSortModel();
		window.filterState = this.gridApi.getFilterModel();
		// console.log("Columns ", window.colState)
		// console.log("Group ", window.groupState)
		// console.log("Sort ", window.sortState)
		// console.log("Filter ", window.filterState)
		let screenpage = '';

		if (this.props.match.path == "/sndconsignments") {
			screenpage = 'snd active consignments';
		}
		else if (this.props.match.path == "/prtconsignments") {
			screenpage = 'prt active consignments';
		}
		else if (this.props.match.path == "/tnpconsignments") {
			screenpage = 'tnp active consignments';
		}
		else if (this.props.match.path == "/sndallconsignments") {
			screenpage = 'snd all consignments';

		}
		else if (this.props.match.path == "/prtallconsignments") {
			screenpage = 'prt all consignments';
		}
		else if (this.props.match.path == "/tnpallconsignments") {
			screenpage = 'tnp all consignments';
		}
		else if (this.props.match.path == "/snddeliveredconsignments") {
			screenpage = 'snd delivered consignments';
		}
		else if (this.props.match.path == "/prtdeliveredconsignments") {
			screenpage = 'prt delivered consignments';
		}
		else if (this.props.match.path == "/tnpdeliveredconsignments") {
			screenpage = 'tnp delivered consignments';
		}
		else if (this.props.match.path == "/railconsignments") {
			screenpage = 'railconsignments';
		}
		else if (this.props.match.path == "/ncrconsignments") {
			screenpage = 'ncr consignments';
		}
		else if (this.props.match.path == "/moveconsignments") {
			screenpage = 'move consignments';
		}

		let reqparams = {
			gridcolumns: window.colState,
			gridgroup: window.groupState,
			gridcolsort: window.sortState,
			gridcolfilter: window.filterState,
			userId: localStorage.getItem("userid"),
			screenurl: window.location.pathname,
			screentitle: screenpage
		}
		// console.log("reqparams ", reqparams)
		redirectURL.post("/consignments/saveGridStates", reqparams)
			.then((response) => {
				//console.log("State response ",response.data)
				this.setState({
					show: true,
					basicTitle: "Successfully saved grid layout",
					basicType: "success",
					screenurl: window.location.pathname,
					screentitle: screenpage
				})
			})
			.catch(function (e) {
				console.log("Error ", e)
			})
	}

	restoreGridStates() {
		if (this.state.usergridstate != "" && this.state.usergridstate != null && this.state.usergridstate != undefined) {
			if (this.state.usergridstate.length > 0) {
				var windowstates = this.state.usergridstate;
				this.gridColumnApi.setColumnState(windowstates[0].gridcolumns);
				this.gridColumnApi.setColumnGroupState(windowstates[0].gridgroup);
				this.gridApi.setSortModel(windowstates[0].gridcolsort);
				this.gridApi.setFilterModel(windowstates[0].gridcolfilter);
	
			}
		}
	}

	resetState = () => {
		this.gridColumnApi.resetColumnState();
		this.gridColumnApi.resetColumnGroupState();
		this.gridApi.setSortModel(null);
		this.gridApi.setFilterModel(null);
		//console.log('column state reset');
		var reqparams = {
			userId: localStorage.getItem("userid"),
			screenurl: window.location.pathname,
			screentitle: this.state.screenpage
		}

		redirectURL.post("/consignments/removeUserGridState", reqparams)
			.then((response) => {
				this.setState({
					show: true,
					basicTitle: "Successfully reset default grid layout",
					basicType: "success"
				})
			})

	};

	onShowConsignmentTickets = async (item) => {
		//console.log(item)
		var deptcode = this.state.deptcode

		await this.setState({

			detailCellRendererParams: {
				detailGridOptions: {
					columnDefs: [
						{
							headerName: "Ticket",
							field: "ticket_type",
							valueGetter: function (params) {
								var tickettype = params.data.ticket_type;
								var str = tickettype.replace(/_/g, " ")
								return str;
							},
							cellClass: "capitlize"
						},
						{
							headerName: "Ticket No",
							field: "ticket_number",
							width: 120,
						},
						{
							headerName: "Truck No",
							field: "truck_no",
							width: 120,
						},

						{
							headerName: "Transporter",
							field: "transporter_name",
							width: 120,
						},
						{
							headerName: "Department",
							field: "dept_code",
							width: 150,
						},
						{
							headerName: "Actual LSP User",
							field: "actual_lspuser",
							width: 150,
						},
						{
							headerName: "Status",
							field: "ticket_status",
							width: 130,
							valueGetter: function (params) {
								if (params.data.ticket_status == 1) {
									return "Open";
								}
								if (params.data.ticket_status == 2) {
									return "Work in progress";
								}
								if (params.data.ticket_status == 3) {
									return "Closed";
								}
								if (params.data.ticket_status == 4) {
									return "On hold";
								}
								if (params.data.ticket_status == 5) {
									return "Auto closed";
								}
								if (params.data.ticket_status == 6) {
									return "Invalid";
								}
								if (params.data.ticket_status == 7) {
									return "Closed - No response";
								}
								if (params.data.ticket_status == 8) {
									return "Force Closed";
								}
							}
						},
						{
							headerName: "Deaer City",
							field: "consignee_city",
							width: 180,
							valueGetter: function (params) {
								if (typeof params.data.consignee_city == undefined) {
									return '';
								}
								else {
									return params.data.consignee_city;
								}
							}
						},
						{
							headerName: "Transit Delay Hours",
							field: "transit_delay_hours",
							width: 180,
							valueGetter: function (params) {
								if (typeof params.data.transit_delay_hours == undefined) {
									return '';
								}
								else {
									return params.data.transit_delay_hours;
								}
							}
						},
						{
							headerName: "Exception Location",
							field: "exception_location",
							width: 170,
							resizable: true
						},
						// {
						// 	headerName : "Exception Longitude",
						// 	field : "exception_lng",
						// 	width : 140,
						// 	resizable : true
						// },
						// {
						// 	headerName: "Last Known City/Area",
						// 	field: "area",
						// 	width: 180,
						// 	valueGetter: function (params) {
						// 		return params.data.truck[0].area
						// 	}
						// },
						// {
						// 	headerName: "Last Known State",
						// 	field: "area",
						// 	width: 180,
						// 	valueGetter: function (params) {
						// 		return params.data.truck[0].state
						// 	}
						// },
						{
							headerName: "Last Packet Time",
							field: "timestamp",
							width: 180,
							valueGetter: function (params) {
								return getHyphenDDMMMYYYYHHMM(params.data.truck[0].timestamp)
							},
							comparator: dateComparator,
						},
						{
							headerName: "Gate Out / Invoice Time",
							field: "gate_out_invoice_time",
							width: 180,
							valueGetter: function (params) {
								return getHyphenDDMMMYYYYHHMM(params.data.gate_out_invoice_time)
							},
							//filter: "agDateColumnFilter",
							comparator: dateComparator,
						},
						{
							headerName: "Created Date",
							field: "ticket_creation_date",
							width: 180,
							valueGetter: function (params) {
								return getHyphenDDMMMYYYYHHMM(params.data.ticket_creation_date)
							},
							//filter: "agDateColumnFilter",
							comparator: dateComparator,
						},
						{
							headerName: "Modified Date",
							field: "ticket_modification_date",
							width: 180,
							valueGetter: function (params) {
								return getHyphenDDMMMYYYYHHMM(params.data.ticket_modification_date)
							},
							//filter: "agDateColumnFilter",
							comparator: dateComparator,
						},
					]
				},
				getDetailRowData: async function (params) {
					var parameter = {
						consignment_code: params.data.consignment_code,
						dept_code: deptcode
					}
					redirectURL.post("/consignments/ticketsbyconsignment", parameter)
						.then((response) => {
							params.successCallback(response.data)
						})
						.catch(function (e) {

						})

				}
			}
		});

		if (item.column.colDef.field == 'tickets') {
			item.node.setExpanded(!item.node.expanded);
		}
		else {

			item.node.setExpanded(false);
		}


	}


	onCloseUserManualModal = () => {
		this.setState({ usermanualncrmodal: false });
	};

	onClickShowUserManaul = () => {
		this.setState({ usermanualncrmodal: true });
	};

	onCloseUserManualMoveModal = () => {
		this.setState({ usermanualmovemodal: false });
	};

	onClickShowUserMoveManaul = () => {
		this.setState({ usermanualmovemodal: true });
	};

	onShowUploadEWay() {
		this.setState({
			slideuploadeway: "slider-translate",
			overly: 'show-m'
		})
	}

	uploadBulkPriorityFormHandler(event) {
		event.preventDefault();
		if (googleAnalytics.page.enableGA) {
			let pageTitle = this.state.pagetitle;
			let eventOptions = {
				"category": pageTitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.formAction,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		var bulkMdData = this.state.pCsvContent;
		//console.log(bulkMdData);
		var flag = 0;
		// console.log("bulkMdData: =>", bulkMdData)
		bulkMdData.map(function (e, index) {
			var keys = Object.keys(e);
			var consignmentKey = ""
			keys.map(function (key) {
				if (key == "Consignment_Code" ||
					key == "GR No" ||
					key == "consignment_code" ||
					key == "gr no" ||
					key == "ConsignmentCode" ||
					key == "consignmentcode"
				) {
					consignmentKey = key;
				}
			})
			if (index != (bulkMdData.length - 1)) {
				if (e[consignmentKey] == "") {
					flag = 1
				}
			}

		})
		if (flag == 0) {
			var reqParams = {
				email: localStorage.getItem("email"),
				csvData: bulkMdData
			}
			redirectURL.post("/consignments/bulkUploadConsignmentPriority", reqParams).then((response) => {
				this.setState({
					basicTitle: "Success",
					basicType: "success",
					show: true,
					file: "",
					bulkPrioritySlide: "",
					overly: "show-n",
					loadshow: "show-n"
				})
				// window.location.reload();
			}).catch((e) => {
				console.log(e);
			})

		}
		else {
			$("#bulkUploadPID").val();
			this.setState({
				basicTitle: "Consignment_code fields must not be empty",
				basicType: "danger",
				show: true,
				file: "",
				pCsvContent: "",
			})
		}

		var urlpath = '/consignments/prtconsignments';
		var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		var dpt = 'LOG-PRT';
		var consignData = {
			dept_code: encode(dpt),
			startDate: this.state.startDate,
			endDate: this.state.endDate,
			consigner: this.state.consigner,
			movement: [this.state.movementtype]
		}


		this.onLoadGetConsignmentsList(urlpath, consignData)
	}

	changePriorityFileHandler = async (e) => {
		var dt = '';

		const config = {
			headers: [
				{
					name: 'consignment_code',
					inputName: 'consignment_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
			.then((csvData) => {
				this.setState({
					pCsvContent: csvData.data
				});
			})
			.catch(err => { })
		// console.log("e.target.files[0]",e.target.result);
		var contentList = []

		var out = new Promise(function (reject, resolve) {
			var reader = new FileReader();
			if (reader != "" && reader != undefined) {
				reader.onload = async function (e) {
					var contents = await e.target.result;
					resolve(contents);
				};
				if(e.target.files[0] != "" && e.target.files[0] != undefined) {
					var tt = reader.readAsText(e.target.files[0]);
				}
				
			}
		});
		this.setState({
			file: e.target.files[0]
		});
		// console.log("e.target.files[0].type ", e.target.files[0]);
		if (e.target.files[0] != "" && e.target.files[0] != undefined) {
			if (e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel') {
			}
			else {
				e.target.value = null;
				this.setState({
					uploadFile: '',
					file: "",
					show: true,
					basicType: 'danger',
					basicTitle: 'Please upload file having extensions .csv only.',
				});
			}
		}
	}


	onBulkPriorityBtn() {
		this.setState({
			overly: "show-m",
			bulkPrioritySlide: "slider-translate"
		})
	}

	changeFileHandler = async (e) => {
		const config = {
			headers: [
				{
					name: 'consignment_code',
					inputName: 'consignment_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'truck_no',
					inputName: 'truck_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'ewaybill_no',
					inputName: 'ewaybill_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{
					name: 'expiry_date',
					inputName: 'expiry_date',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				}
			]
		}
		var data = CSVFileValidator(e.target.files[0], config)
			.then((csvData) => {
				this.setState({
					csvcontent: csvData.data
				});
			})
			.catch(err => { })

		var out = new Promise(function (reject, resolve) {
			var reader = new FileReader();
			if (reader != "" && reader != undefined) {
				reader.onload = async function (e) {
					var contents = await e.target.result;
					// console.log("contents ", contents)
					resolve(contents);
				};
				if(e.target.files[0] != "" && e.target.files[0] != undefined) {
					var tt = reader.readAsText(e.target.files[0]);
					// console.log("tt ", tt)
				}
			}
		});
		//console.log("Out ", out);

		this.setState({
			file: e.target.files[0]
		});
		//console.log("e.target.files[0].type ", e.target.files[0].type);
		// if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
		// {
		// }
		// else{
		// 	e.target.value = null;
		// 	this.setState({
		// 		uploadFile:'',
		// 		show: true,
		// 		basicType:'danger',
		// 		basicTitle:'Please upload file having extensions .csv only.',
		// 	});
		// }

	}

	resetUpload = () => {
		this.setState({
			slideuploadeway: '',
			overly: 'show-n',
			file: '',
			showDiv: 'show-n',
			uploadDivWidth: '0%',
			bulkPrioritySlide: '',
			bulkForceCloseSlide: '',
		});
	}

	uploadEway(event) {
		event.preventDefault();

		if (this.state.file != '') {
			// console.log("this.state.file ", this.state.file)
			var csvdd = this.state.file;

			// console.log("event ", event.target.uploadFile.value);
			var formData = new FormData();
			formData.append('uploadFile', this.state.file);
			formData.append("userId", localStorage.getItem('userid'));
			formData.append("csvcontent", this.state.csvcontent);
			var rparams = {
				uploadFile: this.state.file,
				userId: localStorage.getItem('userid'),
				csvcontent: this.state.csvcontent
			}
			//console.log('file',formData);
			//console.log('rparams',rparams);
			this.setState({
				loadshow: 'show-m'
			});
			redirectURL.post("/consignments/uploadewayexpires", rparams)
				.then(
					(response) => {
						//console.log(response);
						// console.log(response.data);
						//document.getElementById("upform").reset();
						if (response.data.status == 'failure') {
							if (response.data.message.empty != "")
								this.setState({
									//csverrmessage:response.data.status,
									show: true,
									basicType: 'danger',
									basicTitle: "Failed",
									slideuploadeway: '',
									overly: 'show-n',
									showDiv: 'show-n',
									loadshow: 'show-n',
									uploadFile: '',
									file: ''
								});

						}
						else {
							//console.log("here")
							this.setState({
								show: true,
								basicType: 'success',
								basicTitle: "Successfully Uploaded.",
								slideuploadeway: '',
								overly: 'show-n',
								showDiv: 'show-n',
								uploadFile: '',
								loadshow: 'show-n',
								file: ''
							});
						}
					}
				)
				.catch(function (error) {
					console.log(error);
				});
		}

	}
	onCellClicked(e) {
		try {
			console.log("e.data",e.data);
			var rowId = e.data._id;
			var consignmentNo = e.data.consignment_no;
			var consigneeCode = e.data.consignee_code;
			// this.openActiveOrdersForceCloseModal();

			this.setState({
				forceCloseConsignmentNo: consignmentNo,
				forceCloseConsigneeCode: consigneeCode,
				colDefFieldName: e.colDef.field
			})
		} catch(e) {
			console.log(e)
		}
		// if (cell.colDef.field == 'breaks_at_tolls' || cell.colDef.field == 'toll_details') {
		// 	this.setState({
		// 		detailCellRendererParams : []
		// 	})
		// 	console.log('cell.data.toll_details',cell.data.toll_details)
		// 	try{
		// 		var tollDetails = []
		// 		if(JSON.parse(cell.data.toll_details) !== undefined) tollDetails = JSON.parse(cell.data.toll_details)				
		// 	}
		// 	catch(e){
		// 		tollDetails = []
		// 	}
		// 	// try{
		// 	// 	var breaks_at_tolls = []
		// 	// 	if(JSON.parse(cell.data.breaks_at_tolls) !== undefined) breaks_at_tolls = JSON.parse(cell.data.breaks_at_tolls)
		// 	// }
		// 	// catch(e){
		// 	// 	breaks_at_tolls = []
		// 	// }
		// 	// let toll_details1 = []
		// 	// let tollNames = []
		// 	// tollDetails.map(e =>{
		// 	// 	breaks_at_tolls.map(i =>{
		// 	// 		if(e.name === i.toll_name){ 
		// 	// 			toll_details1.push(i)
		// 	// 			tollNames.push(i.toll_name)
		// 	// 		}
		// 	// 	})
		// 	// 	if(!tollNames.includes(e.name)){	
		// 	// 		tollNames.push(e.name)
		// 	// 		toll_details1.push(e)	
		// 	// 	}
		// 	// })
		// 	// console.log("t",tollDetails )
		// 	// console.log("b", breaks_at_tolls)
		// 	Promise.all([tollDetails]).then(async ()=>{
		// 		await this.setState({
		// 			detailCellRendererParams: {
		// 				detailGridOptions: {
		// 					columnDefs: [
		// 						{
		// 							"headerName": "Toll Name",
		// 							field: "toll_name",
		// 							width: 140, resizable: true,
		// 							valueGetter: param => {
		// 								if (param.data.toll_name !== '' && param.data.toll_name !== undefined) {
		// 									return param.data.toll_name
		// 								}else{
		// 									return param.data.name
		// 								}
		// 							}
		// 						},
		// 						{
		// 							"headerName": "State",
		// 							field: "state",
		// 							width: 120, resizable: true
		// 						},
		// 						{
		// 							"headerName": "Area",
		// 							field: "area",
		// 							width: 120, resizable: true
		// 						},
		// 						{
		// 							"headerName": "Rate To Be considered",
		// 							field: 'rate_to_be_considered',
		// 							width: 120, resizable: true,
		// 							valueGetter: param => {
		// 								if (param.data.rate_to_be_considered !== '') {
		// 									return `${param.data.rate_to_be_considered} Rs`
		// 								}
		// 							}
		// 						},
		// 						{
		// 							"headerName": "Break Start Time",
		// 							field: "break_start_time",
		// 							width: 140, resizable: true,
		// 							valueGetter: (params) => {
		// 								if (params.data.break_start_time !== '' && params.data.break_start_time !== undefined) {
		// 									// console.log(" break_start_time ", getHyphenDDMMMYYYYHHMM(params.data.break_start_time),'\n time', params.data.break_start_time)
		// 									return getHyphenDDMMMYYYYHHMM(params.data.break_start_time);
		// 								} else {
		// 									return 'NA'
		// 								}
		// 							},
		// 						},
		// 						{
		// 							"headerName": "Break End Time",
		// 							field: "break_end_time",
		// 							width: 140, resizable: true,
		// 							valueGetter: (params) => {
		// 								// console.log(" ETA ", params.data)
		// 								if (params.data.break_end_time !== '' && params.data.break_end_time !== undefined) {
		// 									return getHyphenDDMMMYYYYHHMM(params.data.break_end_time);
		// 								} else {
		// 									return 'NA'
		// 								}
		// 							},
		// 						},
		// 						{
		// 							"headerName": "Break duration",
		// 							field: "break_time_seconds",
		// 							width: 200, resizable: true,
		// 							valueGetter: function (params) {
		// 								// console.log(" ETA ", params.data.break_time_seconds)
		// 								if (params.data.break_time_seconds !== '' && params.data.break_time_seconds !== undefined) {
		// 									let sec = params.data.break_time_seconds
		// 									return secondsToMinutesSeconds(sec)
		// 								} else {
		// 									return 'No Break'
		// 								}
		// 							},
		// 						},
		// 					],
		// 					overlayNoRowsTemplate: 'No rows to show',
		// 				},
		// 				getDetailRowData: (params) => {
		// 					params.successCallback(tollDetails);
		// 				},
		// 				masterDetail: true
		// 			}
		// 		})
		// 		cell.node.setExpanded(!cell.node.expanded);
		// 	})
		// }

		// if (cell.colDef.field == "srv_logs") {
		// 	await this.setState({

		// 		detailCellRendererParams: {
		// 			detailGridOptions: {
		// 				columnDefs: [
		// 					{
		// 						"headerName": "gr no",
		// 						field: "consignment_code",
		// 						width: 120, resizable: true
		// 					},
		// 					{
		// 						"headerName": "Invoice No",
		// 						field: "invoice_no",
		// 						width: 120, resizable: true
		// 					},
		// 					{
		// 						"headerName": "SRV No",
		// 						field: "srv_no",
		// 						width: 110, resizable: true,
		// 						valueGetter: function (resp) {
		// 							if (resp.data.srv_no != "nan" && resp.data.srv_no != undefined) {
		// 								return parseInt(resp.data.srv_no).toString()
		// 							}
		// 							else {
		// 								return ""
		// 							}
		// 						}
		// 					},
		// 					{
		// 						"headerName": "SRV Status",
		// 						field: "srv_status",
		// 						width: 120,
		// 						resizable: true,
		// 						valueGetter: function (resp) {
		// 							if (resp.data.srv_status != "nan" && resp.data.srv_status != undefined && resp.data.srv_status != "NA") {
		// 								return resp.data.srv_status
		// 							}
		// 							else {
		// 								return ""
		// 							}
		// 						}
		// 					},
		// 					{
		// 						"headerName": "DLoc Code",
		// 						field: "dloc_code",
		// 						width: 120, resizable: true
		// 					},
		// 					{
		// 						"headerName": "Part Name",
		// 						field: "part_name",
		// 						width: 120, resizable: true
		// 					},
		// 					{
		// 						"headerName": "Part No",
		// 						field: "part_no",
		// 						width: 120, resizable: true
		// 					},
		// 					{
		// 						"headerName": "Quantity",
		// 						field: "quantity",
		// 						width: 120, resizable: true
		// 					},
		// 					{
		// 						"headerName": "Transaction Type",
		// 						field: "transaction_type",
		// 						width: 120, resizable: true
		// 					},
		// 					{
		// 						"headerName": "Unload No",
		// 						field: "unload_no",
		// 						width: 120, resizable: true,
		// 						valueGetter: function (resp) {
		// 							if (resp.data.unload_no != "nan" && resp.data.unload_no != undefined) {
		// 								return parseInt(resp.data.unload_no).toString()
		// 							}
		// 							else {
		// 								return ""
		// 							}
		// 						}
		// 					},
		// 				],
		// 				overlayNoRowsTemplate: 'No rows to show',

		// 			},
		// 			getDetailRowData: async function (param) {
		// 				param.successCallback([]);
		// 				await redirectURL.post("/dashboard/getSRVLogs", {
		// 					"consignment_code": param.data.consignment_code,
		// 					"invoice_no": param.data.invoice_no
		// 				}).then((response) => {
		// 					param.successCallback(response.data);
		// 				}).catch(function (error) {
		// 					console.log(error);
		// 				})

		// 			},
		// 			masterDetail: true
		// 		}
		// 	});


		// 	cell.node.setExpanded(!cell.node.expanded);
		// }
		// if (cell.colDef.field == "delete") {
		// 	var rowId = cell.data._id;
		// 	var consigner_code = cell.data.consigner_code;
		// 	var qry = {
		// 		rowId: rowId,
		// 		rowData: cell.data,
		// 		user_id: localStorage.getItem('userid'),
		// 		user_name: localStorage.getItem('username'),
		// 		user_email: localStorage.getItem('email'),
		// 	}
		// 	if (consigner_code == "SMG") {
		// 		if (window.confirm("Are You Sure, Consignment and related details will be deleted")) {
		// 			// console.log(rowId, "rowId")
		// 			redirectURL.post("/consignments/deleteReturnPallets", qry).then((response) => {
		// 				// console.log("responseData:", response.data);
		// 				if (response.data.message == "Success") {
		// 					this.setState({
		// 						basicTitle: "Deleted Successfully.",
		// 						basicType: "success",
		// 						show: true
		// 					})
		// 					window.location.reload();
		// 				}
		// 				else {
		// 					this.setState({
		// 						basicTitle: "Failed to Delete.",
		// 						basicType: "warning",
		// 						show: true
		// 					})
		// 				}
		// 			}).catch((e) => {
		// 				console.log(e);
		// 			})
		// 		}
		// 	}
		// }

		// if (cell.colDef.field == "priority") {
		// 	if (cell.data.is_priority != 1) {
		// 		this.setState({
		// 			loadshow: "show-m",
		// 			overly: "show-m",
		// 		});
		// 		var consignmentId = cell.data.consignment_code;
		// 		var email = localStorage.getItem("email");
		// 		var reqParams = {
		// 			consignmentId: consignmentId,
		// 			email: email
		// 		}
		// 		redirectURL.post("/consignments/updateConsignmentPriority", reqParams).then((response) => {
		// 			this.setState({
		// 				loadshow: "show-n",
		// 				overly: "show-n",
		// 				basicTitle: "Consignment Marked As Priority",
		// 				basicType: "success",
		// 				show: true,
		// 			});
		// 			// window.location.reload();
		// 			var urlpath = '/consignments/prtconsignments';
		// 			var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		// 			var dpt = 'LOG-PRT';
		// 			var consignData = {
		// 				dept_code: encode(dpt),
		// 				startDate: this.state.startDate,
		// 				endDate: this.state.endDate,
		// 				consigner: this.state.consigner,
		// 				movement: [this.state.movementtype]
		// 			}


		// 			this.onLoadGetConsignmentsList(urlpath, consignData)

		// 		}).catch((cell) => {
		// 			// console.log("cell:", cell)
		// 		})
		// 	}
		// }

		//commented the priority because it was msil requirement
	}

	onClickTab(clickedTab) {
		var colsToHide = ["overspeeding_exception", "nightdriving_exception", "loading_delay_exception"]
		if (clickedTab == 1) {
			var title = this.state.pagetitle;
			// console.log("initialTitle:", title)
			title = title.split(" ")

			if (title.includes("Return", false)) { title.splice(title.indexOf("Return"), 1) }
			title = join(title, " ")
			this.setState({
				rowData: this.state.originalData,
				tnpReturnConsignmentsVisibilty: false,
				consBtn: "btn-danger",
				returnConsBtn: "btn-default",
				countersjson: this.state.originalcountersjson,
				allRowData: this.state.originalData,
				pagetitle: title
			})
			this.gridColumnApi.setColumnsVisible(colsToHide, true)
		}
		else {
			this.state.countersjson.transit_delay = [{ "transit_delay_count": this.state.returnConsignments.filter(e => e.transit_delay == 1).length }];
			this.state.countersjson.enroute_stoppage = [{ "enroute_exception_count": this.state.returnConsignments.filter(e => e.enroute_exception == 1).length }];
			this.state.countersjson.criticalCount = [{ "criticalCount": this.state.returnConsignments.filter(e => e.is_critical == true).length }];
			this.state.countersjson.no_gps = [{ "no_gps_data_count": this.state.returnConsignments.filter(e => e.no_gps_data == 1).length }]
			this.state.countersjson.unloading_delay = [{
				"unloading_delay_exception_count":
					this.state.returnConsignments.filter(e => e.unloading_delay_exception == 1).length
			}]
			this.state.countersjson.nogpslifetime = [{
				"no_gps_data_lifetime_count":
					this.state.returnConsignments.filter(e => e.no_gps_data_lifetime == 1).length
			}]
			var title = this.state.pagetitle;

			// console.log("initialTitle", title)
			title = title.split(" ")
			// console.log(typeof (title))
			// console.log(title, "title")
			var tarray = [];
			var ttl;
			title.map(function (t) {
				ttl = t.replace(/Return/g, "");
				tarray.push(ttl);
			})
			tarray.splice(1, 0, "Return")
			var title = join(tarray, " ")

			this.setState({
				rowData: this.state.returnConsignments,
				tnpReturnConsignmentsVisibilty: true,
				consBtn: "btn-default",
				returnConsBtn: "btn-danger",
				countersjson: this.state.countersjson,
				allRowData: this.state.returnConsignments,
				pagetitle: title
			})

			// colsToHide.map((e)=>{
			this.gridColumnApi.setColumnsVisible(colsToHide, false)
			// })
		}

	}
	onSetCritical(params, status) {
		//console.log("onSetCritical ", params);
		//console.log("onSetCritical value ", status);
		redirectURL.post('/consignments/setCriticalStatus', {
			params: params,
			is_critical: status,
			token: localStorage.getItem("token"),
			userId: localStorage.getItem("userid"),
			returnConsignment: this.state.tnpReturnConsignmentsVisibilty,

		})
			.then((response) => {

				//var records = JSON.parse(JSON.stringify(response)).data;
				// console.log(response.data);
			});

	}

	getNewFilterConsignmnets = (event) => {
		this.setState({
			loadshow: "show-m",
			overly: "show-m"
		})
		event.preventDefault();
		var selectedCity = $("#selectedCity").text();
		var selectedState = $("#selectedState").text();
		var selectedCluster = $("#selectedCluster").text();
		var selectedTransporter = $("#selectedTransporter").text();
		var beforeNewFilterRowData = this.state.rowData;
		var filterRowData = this.state.rowData;
		// console.log(selectedCity, selectedState, selectedCluster, selectedTransporter, "Bhanu Teja")
		//console.log(filterRowData,"0")
		if (selectedCity != undefined && selectedCity != '' && selectedCity != "Select...") {
			filterRowData = filterRowData.filter(e => e.consignee_city == selectedCity);
			// console.log(filterRowData, "1")
		}
		if (selectedState != undefined && selectedState != '' && selectedState != "Select...") {
			filterRowData = filterRowData.filter(e => e.consignee_state == selectedState);
		}
		if (selectedCluster != undefined && selectedCluster != '' && selectedCluster != "Select...") {
			filterRowData = filterRowData.filter(e => e.cluster == selectedCluster);
		}
		if (selectedTransporter != undefined && selectedTransporter != '' && selectedTransporter != "Select...") {
			filterRowData = filterRowData.filter(e => e.transporter_name == selectedTransporter);
		}
		var conCodes = [];
		filterRowData.map(function (f) {
			conCodes.push(f.consignment_no);
		});
		console.log("filterRowData", filterRowData)
		console.log("concodes", conCodes)
		redirectURL.post('/consignments/getConsignmentsNewFilterCounters', {
			consignmentCodes: conCodes,
			filterType: 1,
		}).then((response) => {
			//var records = JSON.parse(JSON.stringify(response)).data;
			console.log(response.data.counters, "filter counters");
			var pagecounters = response.data.counters
			// console.log(filterRowData, "final")
			var beforeNewFiltercountersjson = this.state.countersjson;
			this.setState({
				rowData: filterRowData,
				allRowData: filterRowData,
				beforeNewFilterRowData: beforeNewFilterRowData,
				beforeNewFiltercountersjson: beforeNewFiltercountersjson,
				countersjson: pagecounters,
				loadshow: "show-n",
				overly: "show-n"
			})
			this.gridApi.refreshCells();
		});
	}

	ClearNewFilter = () => {
		this.setState({
			loadshow: "show-m",
			overly: "show-m"
		})
		this.setState({
			flCity: "",
			flState: "",
			flCluster: "",
			flZone: "",
			flTransporter: ""
		});
		this.setState({
			rowData: this.state.beforeNewFilterRowData,
			allRowData: this.state.beforeNewFilterRowData,
			countersjson: this.state.beforeNewFiltercountersjson,
			loadshow: "show-n",
			overly: "show-n"
		});
		this.gridApi.refreshCells();
	}
	onClickMarkDelivered = (params) => {
		console.log(params.data);
		const todate = moment.parseZone().format('YYYY-MM-DD');
		if (params.data.shipping_date != undefined && params.data.shipping_date != null && params.data.shipping_date != "") {
			var markdate = params.data.shipping_date.split("T")[0];
			console.log("markdate", markdate)
			this.setState({
				markDeliverDefault: markdate
			});
		}
		else {
			// console.log("else")
			this.setState({
				markDeliverDefault: todate
			});
		}
		this.setState({
			markDeliverRowNode: params,
			markDeliverData: params.data,
			sliderMarkDeliverTranslate: "slider-translate",
			overly: 'show-m',
		})
	}

	onChangeMarkedDeliverDate = (event, currentDate, selectedDate) => {
    	var d = new Date(event._d);
    	
    	var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+(d.getDate()<10?"0"+d.getDate():d.getDate());
    	console.log("markDeliverDefault", startdate)
    	this.setState({
    		markDeliverDefault:startdate
    	});
		
    	//let value = event.target.value;
        //this.setState({'startDate':value});
	}

	formMarkDeliverHandler = (event) => {
		event.preventDefault()
		var consignmentId = this.state.markDeliverData.consignment_code;
		// var delivery_date_time = $("#delivery_date_time").val();
		var delivery_date_time = this.state.markDeliverDefault
		console.log('markdeliverdefault', this.state.markDeliverDefault)
		console.log("delivery_dateTime", delivery_date_time)
		if (delivery_date_time != "__-__-____") {
			var rowdata = this.state.markDeliverData;

			var temp = delivery_date_time
			if (temp.split("-")[1].length < 2) {
				temp = temp.split("-")[2] + "-0" + temp.split("-")[1] + "-" + temp.split("-")[0]
			}
			else {
				temp = temp.split("-")[2] + "-" + temp.split("-")[1] + "-" + temp.split("-")[0]
			}

			var flag = 0;
			var checkDeliveryDate = new Date(temp);
			var shipping_date = this.state.markDeliverData.shipping_date;
			shipping_date = new Date(shipping_date.split("T")[0]);
			var currentdate = moment.parseZone(new Date())
			// console.log(new Date(currentdate),shipping_date,checkDeliveryDate);
			if (checkDeliveryDate < shipping_date) {
				flag = 1;
			}
			if (flag == 0) {
				var email = localStorage.getItem("email");
				var reqParams = {
					consignmentId: consignmentId,
					delivery_datetime: delivery_date_time,
					email: email
				}
				// console.log(reqParams);
				redirectURL.post("/consignments/courierMarkDelivered", reqParams).then((response) => {
					this.setState({
						sliderMarkDeliverTranslate: "",
						loadshow: "show-n",
						overly: "show-n",
						basicTitle: "Marked as Delivered",
						basicType: "success",
						show: true,
						markDeliverRowNode: "",
					})
					window.location.reload();
				}).catch((e) => {
					console.log(e)
				})
			}
			else {
				this.setState({
					deliverdate: "",
					basicTitle: "Selected Date Must be Between greater than MGPA Date",
					basicType: "warning",
					show: true
				});
			}
		}
		else {
			this.setState({
				basicTitle: "Delivery Date is Not Selected.",
				basicType: "danger",
				show: true
			})
		}
	}

	newFilter = (filterType, selOption) => {
		this.setState({ [filterType]: selOption },()=>{
			if(filterType === "flConsignee" && this.state.userType !== "DISTRIBUTOR"){
				this.setState({
            	loadshow: 'show-m',
				["flArea"]: []
				})
				let areas = [{label:"All", value:"all"}]
				if(!selOption){
					this.setState({
						filterCustomerAreas:[],
						loadshow: 'show-n',
					})
				}
				if(selOption && this.state.flConsignee.length === 0){
					this.setState({
						filterCustomerAreas:[],
						loadshow: 'show-n',
					})
				}
				if(selOption && this.state.flConsignee.length > 0){
					for(let each of selOption){
						let {value} = each;
						if(value === "all"){
							let areas = [{label:"All", value:"all"}]
							console.log(this.state.consignerAreas["all"],"all");
							for(let each of this.state.consignerAreas["all"]){
								for(let eachArea of each){
									let {customer_area_id,customer_area} = eachArea;
									let option = {value:customer_area_id,label:customer_area}
									areas.push(option)
								}								
							}
							this.setState({
								filterCustomerAreas : areas,
								loadshow: 'show-n',
							})							
							break;
						}else{
							console.log(this.state.consignerAreas[value],"value");
							this.state.consignerAreas[Number(value)].forEach((eachArea)=>{
								let {customer_area_id,customer_area} = eachArea;
								let option = {value:customer_area_id,label:customer_area}
								areas.push(option)
							})
							this.setState({
								filterCustomerAreas : areas,
								loadshow: 'show-n',
							})							
						}
					}
				}
			}
		});	
	}

	handleCustomerChange = (filterType, selOption) => {
		this.setState({ [filterType]: selOption })
		var deliveryPersonName = this.state.areaDeliveryMap[`${this.state.custAreaCodeMap[`${selOption.value}`]}`]
		var deliveryPersonMobileNo = this.state.areaDeliveryMobileMap[`${this.state.custAreaCodeMap[`${selOption.value}`]}`]
		this.setState({
			deliveryPersonName: deliveryPersonName,
			deliveryPersonMobileNo: deliveryPersonMobileNo
		})
	}

	reloadPageData = () => {
		window.location.reload();
	}

	onClickShowForm() {
		this.setState({
			overly: "show-m",
			sliderCreateConsignmentTranslate: "slider-translate"
		});
	}
	onClickShowForm1() {
		this.setState({
			overly: "show-m",
			sliderRso: "slider-translate"
		});
	}
	saveConsignmentData = async (event) => {
		event.preventDefault();
		var specialCharcters = 0;
		var custMobileMap = this.state.custMobileMap;
		var custAreaCodeMap = this.state.custAreaCodeMap;
		var custAreaMap = this.state.custAreaMap;
		var custCylinderMap = this.state.custCylinderMap;
		var areaDeliveryMap = this.state.areaDeliveryMap;
		var areaDeliveryMobileMap = this.state.areaDeliveryMobileMap;
		var bookingNo = this.state.bookingNo;
		// var areaCode = this.state.customerarea_val.value;

		var customerId = this.state.customer_val.value;
		var customerName = this.state.customer_val.label.split("-")[1];
		var areaCode = custAreaCodeMap[`${customerId}`];
		// var consignmentArea = this.state.customerarea_val.label;
		var consignmentArea = custAreaMap[`${customerId}`];
		var consigneeCode = this.state.consigneeCode;
		var bookingDate = $("#booking_date").val();
		var productCode = this.state.productCode;
		var refillType = this.state.refillType;
		var deliveryFlag = this.state.deliveryFlag.value;
		// var noOfCylinders = this.state.noOfCylinders;
		var noOfCylinders = custCylinderMap[`${customerId}`];
		var paymentOption = this.state.paymentOption.value;
		var deliveryPersonName = this.state.deliveryPersonName;
		// if (areaDeliveryMap.hasOwnProperty(`${areaCode}`)) {
		// 	var deliveryPersonName = areaDeliveryMap[`${areaCode}`];
		// } else {
		// 	deliveryPersonName = this.state.deliveryPersonName
		// }
		var deliveryPersonMobileNo = this.state.deliveryPersonMobileNo;
		// if (areaDeliveryMobileMap.hasOwnProperty(`${areaCode}`)) {
		// 	var deliveryPersonMobileNo = areaDeliveryMobileMap[`${areaCode}`];
		// } else {
		// 	deliveryPersonMobileNo = this.state.deliveryPersonMobileNo
		// }
		var customerMobileNo = custMobileMap[`${customerId}`];

		// console.log("DeliveryFlag", deliveryFlag)
		// var invoice_date = $("#invoice_date").val();
		// console.log(drivermobilenum,"drivermobilenum")
		var encode = this.cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");
		this.setState({
			loadshow: 'show-m',
			overly: 'show-m'
		})
		// var gate_out_time = $("#gate_out_time").val();
		// if (this.state.consignee_code != "") {
		// 	var consignee_code = this.state.consignee_code.value;
		// 	var consignee_name = this.state.consignee_code.label;
		// }
		// else {
		// 	var consignee_code = "";
		// 	var consignee_name = "";
		// }
		if(bookingNo != "" && bookingNo != undefined)
		{
			if (bookingNo.match(/[^a-zA-Z0-9]/g)) {
				specialCharcters = 1
			}
		}
		
		console.log(specialCharcters,"specialCharcters")
		if(specialCharcters == 0)
		{
			if (bookingNo != "" && areaCode != "" && bookingDate != "" && bookingDate != "__-__-____ __:__"
			&& consigneeCode != "" && customerId != "" && deliveryPersonName != "" && deliveryPersonMobileNo != "" && noOfCylinders != "" && deliveryFlag != "" && paymentOption != "") 
			{
				if (parseInt(deliveryPersonMobileNo[0]) < 6){
					this.setState({
						show: true,
						basicType: "danger",
						basicTitle: "Invalid mobile number!"
					})
				}
				if(deliveryPersonMobileNo.length == 10)
				{
					var params = {
						bookingNo: bookingNo,
						consignmentArea: consignmentArea,
						areaCode: areaCode,
						customerId: customerId,
						customerName: customerName,
						customerMobileNo: customerMobileNo,
						bookingDate: bookingDate,
						consigneeCode: consigneeCode,
						productCode: productCode,
						refillType: refillType,
						deliveryFlag: deliveryFlag,
						noOfCylinders: noOfCylinders,
						paymentOption: paymentOption,
						deliveryPersonName: deliveryPersonName,
						deliveryPersonMobileNo : deliveryPersonMobileNo
					}
					console.log("parameters ", params);
	
					redirectURL.post("/dashboard/saveConsignmentData",params)
						.then(async (response) => {
							// console.log("save response ", response.data)
							if (response.data.status == "Success") {
								if (response.data.insertedCount > 0) {
									var bookingdatedata = $("#booking_date").val("");
									this.setState({
										show: true,
										basicType: "success",
										basicTitle: "Successfully created order",
										sliderCreateConsignmentTranslate:"",
										bookingNo: "",
										customerarea_val: { "value": '', "label": '' },
										customer_val: {"value": "", "label": ""},
										productCode: "",
										refillType: "",
										deliveryFlag: {"value": "", "label": ""},
										noOfCylinders: "",
										paymentOption: {"value": "", "label": ""},
										deliveryPersonName: "",
										deliveryPersonMobileNo: "",
										bookingdatedata: bookingdatedata,
										loadshow:"show-n",
										overly:"show-n"
									})
									// this.componentDidMount()
									var userType = localStorage.getItem("user_type");
									if (userType == "DISTRIBUTOR") {
										var updateParams = {
											consigneeCode: consigneeCode
										} 
									} else {
										updateParams = {}
									}
									await redirectURL.post("/consignments/updateConsignments", updateParams).then((res) => {
										console.log("updateConsignments res", res.data)
										this.sendOrderDetails(res.data.consignments);
										// this.setState({
										// 	show: true,
										// 	basicType: "success",
										// 	basicTitle: "Processed Order Successfully",
										// 	loadshow: 'show-n',
										// 	overly: 'show-n'
										// })
									})
								} else {
									this.setState({
										show: true,
										basicType: "danger",
										basicTitle: "Failed to create order"
									})
								}
							}
							else {
								if (response.data.message == "Order No Already Exists") {
									this.setState({
										show: true,
										basicType: "danger",
										basicTitle: "Order No Already Exists"
									})
								}
								else {
									this.setState({
										show: true,
										basicType: "danger",
										basicTitle: "Failed to create consignment"
									})
								}
	
							}
					})
				}
				else {
					this.setState({
						show: true,
						basicType: "danger",
						basicTitle: "Delivery Person Mobile Number should be 10 digits"
					})
	
				}
			}
			else {
				this.setState({
					show: true,
					basicType: "danger",
					basicTitle: "All fields are mandatory"
				})

			}
		}
		else {
			this.setState({
				show: true,
				basicType: "danger",
				basicTitle: "Special characters are not allowed"
			})

		}
		this.setState({
			loadshow: 'show-n',
			overly: 'show-n'
		})
	}

	onChangeTruckItem(truck_no) {
		this.setState(
			{ truck_no },
			// () => console.log(`Truck  Option selected:`, this.state.truck_no)
		);

		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}

	onChangeConsigneeItem(consignee_code) {
		this.setState(
			{ consignee_code },
			// () => console.log(`Code  Option selected:`, this.state.consignee_code)
		);

		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}
	onChangeTransporterItem(transporter_code) {
		this.setState(
			{ transporter_code },
			// () => console.log(`Code  Option selected:`, this.state.consignee_code)
		);

		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}
	onChangePlantItem(plant_code) {
		this.setState(
			{ plant_code },
			// () => console.log(`Plant Option selected:`, this.state.plant_code)
		);

		this.setState({
			errorMessage: '',
			successMessage: ''
		})
	}
	render() {
		const modalStyles = {
			width: '1300px !important',
		}
		const { open } = this.state;
		const { openforceclose } = this.state;
		const { usermanualncrmodal } = this.state;
		const { usermanualmovemodal } = this.state;
		var maptransporter = '';
		try {
			if (this.state.rownode.transporter) {
				///console.log("map rownode ",this.state.rownode.transporter[0])
				maptransporter = this.state.rownode.transporter[0].transporter_name;
			}
		}
		catch (e) {
			console.log(e)
		}
		var screen_type = this.state.deptcode
		//console.log("Rendere ",this.state.deptcode)
		let transitTimeHeading = (this.state.deptcode === "LOG-TNP") ? "Transit Time (Hours)" : "Transit Time (Days)";
		var department_code = this.state.deptcode;
		let isHiddenLocalMovement = (this.state.deptcode === "LOG-PRT") ? false : true;
		//console.log("isHiddenLocalMovement = ", isHiddenLocalMovement, this.state.deptcode);
		let hideonlyPRT = (this.state.deptcode === "LOG-PRT") ? true : false;
		let hideonlyTNP = (this.state.deptcode === "LOG-TNP") ? true : false;
		let hideTNPconsignment = (this.state.deptcode === "LOG-TNP" && this.state.tnpReturnConsignmentsVisibilty == false) ? false : true;
		let invoiceTimeName = (this.state.deptcode === "LOG-PRT") ? 'Invoice Time' : 'Invoice Time';
		//let hideOnlyActive =
		let hideonlyCol = ((this.props.match.path == "/sndconsignments")
			|| (this.props.match.path == "/prtconsignments")) ? true : false;


		let showOnlyCol = false;
		let showtimestamp = false;
		if ((this.props.match.path == "/sndconsignments")
			|| (this.props.match.path == "/prtconsignments")
			|| (this.props.match.path == "/tnpconsignments")
			|| (this.props.match.path == "/railconsignments")
			|| (this.props.match.path == "/ncrconsignments")) {
			showOnlyCol = false;
			showtimestamp = false;
		}
		else if (localStorage.getItem("roles") == "PACC") {
			showOnlyCol = true;
		}
		else {
			showOnlyCol = true;
		}
		if (this.props.match.path == "/insidemsilvicinity") {
			showtimestamp = false;
		}
		else {
			if ((this.props.match.path == "/sndconsignments")
				|| (this.props.match.path == "/prtconsignments")
				|| (this.props.match.path == "/tnpconsignments")
				|| (this.props.match.path == "/railconsignments")
				|| (this.props.match.path == "/ncrconsignments")) {
				showtimestamp = false;
			}
			else {
				showtimestamp = true;
			}

		}

		let showOnlyColDeliver = ((this.props.match.path == "/snddeliveredconsignments")
			|| (this.props.match.path == "/prtdeliveredconsignments")
			|| (this.props.match.path == "/tnpdeliveredconsignments")) ? false : true;

		let showonlyactivencr = ((this.props.match.path == "/sndconsignments")
			|| (this.props.match.path == "/prtconsignments")
			|| (this.props.match.path == "/tnpconsignments")
			|| (this.props.match.path == "/ncrconsignments")) ? false : true;

		let showdistance = ((this.props.match.path == "/sndconsignments")
			|| (this.props.match.path == "/prtconsignments")
			|| (this.props.match.path == "/tnpconsignments")) ? false : true;

		let invoicedisable = (this.state.deptcode == 'SNDG') ? false : true;
		let showonlysnd = (this.props.match.path == "/sndconsignments" || this.props.match.path == "/snddeliveredconsignments" || this.props.match.path == "sndallconsignments") ? false : true;
		//console.log("hideonlyCol ", hideonlyCol)
		var pagepath = this.props.match.path;
		if ((this.state.counter_screen == "active" && this.state.deptcode != "LOG-TNP") || this.props.match.path == "/insidemsilvicinty") {
			var checkboxSelectionval = true;
			var hideStatus = true;
			var truckNoHeader = {
				headerName: "Truck No",
				field: "truck_no",
				width: 100,
				filter: "agSetColumnFilter",
				pinned: "left",
				cellRenderer: '', resizable: true
			}
		}
		else {
			var checkboxSelectionval = false;
			var hideStatus = false;
			var truckNoHeader = {
				headerName: "Truck No",
				field: "truck_no",
				width: 100,
				filter: "agSetColumnFilter",
				cellRenderer: '', resizable: true
			}
		}
		var hideNCR;
		if (this.props.match.path == "/ncrconsignments" || this.state.tnpReturnConsignmentsVisibilty) {
			hideNCR = true;
		} else {
			hideNCR = false;
		}
		if (this.state.screenpage == "deliver") {
			var hideOnTime = false
		} else { var hideOnTime = true }
		var hideonlypacc = (localStorage.getItem("roles") == "PACC") ? true : false;
		var hideTransporterCode = (this.state.deptcode === 'LOG-PRT' || this.state.deptcode === 'SNDG') ? false : true;
		let hideGPSFields = ((this.props.match.path == "/snddeliveredconsignments")
			|| (this.props.match.path == "/prtdeliveredconsignments")
			|| (this.props.match.path == "/tnpdeliveredconsignments")
			|| (this.props.match.path == "/sndallconsignments")
			|| (this.props.match.path == "/prtallconsignments")
			|| (this.props.match.path == "/tnpallconsignments")) ? false : true;

		let showOnlyDeliver = ((this.props.match.path == "/snddeliveredconsignments")
			|| (this.props.match.path == "/sndallconsignments")) ? false : true;
		var hideAccidentColumn = true;
		if (this.state.deptcode == "SNDG") {
			hideAccidentColumn = false;
		}
		var hidePalletColumns = (this.props.match.path == "/tnpconsignments") ? false : true;
		var hideCritical = (this.props.match.path == "/insidemsilvicinty") ? true : false;
		var hideDuration = (this.props.match.path == "/insidemsilvicinty") ? false : true;
		var last_consignment_zone = ((this.props.match.path == "/prtallconsignments") ||
			(this.props.match.path == "/prtdeliveredconsignments") ||
			(this.props.match.path == "/prtconsignments")) ? false : true;
		var screen_url = this.props.match.path;
		var hideDealerGateIn = true;
		if (screen_type == "LOG-PRT") {
			if (screen_url != "/prtconsignments") {
				hideDealerGateIn = false;
			} else { hideDealerGateIn = true };
		} else { hideDealerGateIn = true; };
		const mgpa_fields = (this.state.deptcode == "LOG-PRT") ? false : true;
		var hideDeleteOpt = true;
		if (this.props.match.path == "/tnpconsignments" && this.state.tnpReturnConsignmentsVisibilty == false) {
			var userType = localStorage.getItem('user_type');
			// console.log(userType, "userType")
			if (userType == 'TRANSPORTER') {
				// console.log(hideDeleteOpt, "hideDeleteOpt1")
				hideDeleteOpt = true;
			}
			else {
				// console.log(hideDeleteOpt, "hideDeleteOpt2")
				hideDeleteOpt = false;
			}
		}
		let showForDeliveryPrt = (this.props.match.path == "/prtdeliveredconsignments") ? false : true
		var hideVinsbvh = (this.state.deptcode == "SNDG") ? false : true;
		var hidePriority = (["/prtconsignments", "/prtdeliveredconsignments", "/prtallconsignments"].includes(this.props.match.path)) ? true : false;
		const columnwithDefs =
			[
				// {
				// 	headerName: "Actions",
				// 	field: "_id",
				// 	colId: "_id",
				// 	width: 40,
				// 	pinned: 'left',
				// 	filter: false, resizable: true,
				// 	// headerCheckboxSelection: checkboxSelectionval,
				// 	//headerCheckboxSelectionFilteredOnly: true,
				// 	checkboxSelection: true

				// },
				// {
				// 	headerName: "View Route",
				// 	field: "_id",
				// 	colId: "_id",
				// 	width: 40,
				// 	pinned: 'left',
				// 	cellRenderer: 'consignmentActions',
				// 	filter: false, resizable: true

				// },

				// {
				// 	headerName: "Transit Delay",
				// 	field: "transit_delay",
				// 	colId: "transit_delay",
				// 	width: 40,
				// 	pinned: 'left',
				// 	pivot: false,
				// 	cellRendererSelector: function (params) {
				// 		if (params.data.transit_delay == 1) {
				// 			var rendComponent = {
				// 				component: 'kpiTransitDelayActions'
				// 			};
				// 			return rendComponent
				// 		}
				// 		else {
				// 			rendComponent = {
				// 				component: 'notransit'
				// 			};
				// 			return rendComponent
				// 		}
				// 	},
				// 	filter: true, resizable: true
				// },
				// {
				// 	headerName: "Gps Data",
				// 	field: "no_gps_data",
				// 	colId: "no_gps_data",
				// 	width: 40,
				// 	pinned: 'left',
				// 	pivot: false,
				// 	cellRendererSelector: function (params) {
				// 		var comp = 'gps';
				// 		if (params.data.no_gps_data == 0 && params.data.no_gps_data_lifetime == 1) {
				// 			comp = 'noGpsLifetime'

				// 		}
				// 		else if (params.data.no_gps_data == 1) {
				// 			comp = 'nogps'
				// 		}

				// 		else if (params.data.no_gps_data == 0) {
				// 			comp = 'gps'

				// 		}
				// 		var rendComponent = {
				// 			component: comp
				// 		};
				// 		return rendComponent
				// 	},
				// 	filter: true, resizable: true
				// },
				// {
				// 	headerName: "Overspeed",
				// 	field: "overspeeding_exception",
				// 	colId: "overspeeding_exception",
				// 	width: 40,
				// 	pinned: 'left',
				// 	pivot: false,
				// 	cellRendererSelector: function (params) {
				// 		if (params.data.overspeeding_exception == 1) {
				// 			var rendComponent = {
				// 				component: 'kpiActions'
				// 			};
				// 			return rendComponent
				// 		}
				// 		else {
				// 			var rendComponent = {
				// 				component: 'nooverspeed'
				// 			};
				// 			return rendComponent
				// 		}
				// 	},
				// 	filter: true, resizable: true
				// },
				// {
				// 	headerName: "Night Drive",
				// 	field: "nightdriving_exception",
				// 	colId: "nightdriving_exception",
				// 	width: 40,
				// 	pinned: 'left',
				// 	pivot: true,
				// 	hide: hideonlyPRT,
				// 	cellRendererSelector: function (params) {
				// 		//console.log("Except ",params.data.nightdriving_exception)
				// 		//console.log("Consignemnt code ", params.data.consignment_code)
				// 		if (params.data.nightdriving_exception != undefined && params.data.nightdriving_exception == 1) {
				// 			var rendComponent = {
				// 				component: 'kpiNightDriveActions'
				// 			};
				// 			return rendComponent
				// 		}
				// 		else {
				// 			var rendComponent = {
				// 				component: 'nonightdrive'
				// 				//component: 'kpiNightDriveActions'
				// 			};
				// 			return rendComponent
				// 		}
				// 	},
				// 	filter: true, resizable: true
				// },
				// {
				// 	headerName: "Loading Delay",
				// 	field: "loading_delay_exception",
				// 	colId: "loading_delay_exception",
				// 	width: 40,
				// 	pinned: 'left',
				// 	pivot: false,
				// 	hide: hideonlyPRT,
				// 	cellRendererSelector: function (params) {
				// 		var rendComponent = {
				// 			component: 'kpiLoadingDelayActions'
				// 		};
				// 		return rendComponent;
				// 	},
				// 	filter: true, resizable: true
				// },
				// {
				// 	headerName: "Unloading Delay",
				// 	field: "unloading_delay_exception",
				// 	colId: "unloading_delay_exception",
				// 	width: 40,
				// 	pinned: 'left',
				// 	pivot: false,
				// 	hide: hideonlyCol,
				// 	cellRendererSelector: function (params) {
				// 		if (params.data.unloading_delay_exception != undefined && params.data.unloading_delay_exception == 1) {
				// 			var rendComponent = {
				// 				component: 'kpiUnLoadingDelayActions'
				// 			};
				// 			return rendComponent;
				// 		}
				// 		else {
				// 			var rendComponent = {
				// 				component: 'kpiUnLoadingDelayActions'
				// 			};
				// 			return rendComponent;
				// 		}

				// 	},
				// 	filter: true, resizable: true
				// },
				// {
				// 	headerName: "Enroute Stoppages",
				// 	field: "enroute_exception",
				// 	width: 40,
				// 	pinned: 'left',
				// 	pivot: false,
				// 	cellRendererSelector: function (params) {

				// 		var rendComponent = {
				// 			component: 'kpiEnrouteStoppagesActions'
				// 		};
				// 		return rendComponent

				// 	},
				// 	filter: true, resizable: true,
				// },
				// {
				// 	headerName: "Day Wise Distance Trip",
				// 	field: "day_wise_distance_trip",
				// 	colId: "day_wise_distance_trip",
				// 	width: 40,
				// 	pinned: 'left',
				// 	pivot: false,
				// 	hide: hideonlyPRT,
				// 	cellRenderer: 'kpiDayWiseDistanceActions',
				// 	filter: true, resizable: true
				// },
				// {
				// 	headerName: "Tolls",
				// 	field: "toll_details",
				// 	// colId: "breaks_at_tolls",
				// 	width: 40,
				// 	pinned: 'left',
				// 	filter: true,
				// 	resizable: true,
				// 	hide: showForDeliveryPrt,
				// 	cellRenderer: (params) => `<img style="height: 25px; width: 24px" src=${tollImg} />`,			

				// },
				/*	{
						headerName: "Actions",
						field: "comment_exists",
						//colId: "_id",
						width: 40,
						pinned: 'left',
						cellRenderer:'commentActions',
						filter: true,
						resizable: true,
						hide: (this.state.tnpReturnConsignmentsVisibilty)?true:false
	
					},
					{
						headerName: "",
						field: "tickets",
						//colId: "_id",
						width: 40,
						pinned: 'left',
						filter: true,
						resizable: true,
						hide: (this.state.tnpReturnConsignmentsVisibilty)?true:false,
						cellRendererSelector:function(params){
	
							var rendComponent = {
								component: 'Consignmenttickets'
							};
							return rendComponent
	
						},
	
					},*/
				/*			filter: true,
						resizable: true,
						hide: hideTNPconsignment,
						cellRenderer:function(params){
							return "<i class='fa fa-file'><i>"
						},
	
					},
					// {
					// 	headerName: "Actions",
					// 	field: "_id",
					// 	colId: "_id",
					// 	width: 40,
					// 	pinned: 'left',
					// 	resizable: true,
					// 	cellRenderer : "SegmentAction"
	
					// },
	
					{
						pinned:"left",
						headerName : "",
						field:"delete",
						width:40,
						resizable : true,
						editable : false,
						cellRenderer:function(params){
							if(params.data.consigner_code == "SMG")
							{
								return '<i class="icofont icofont-ui-delete"></i>';
							}
						},
						hide:hideDeleteOpt
					},*/

				{
					headerName: "Order No",
					field: "consignment_no",
					// pinned: "left",
					width: 100,
					resizable: true,
					// hide:hideTNPconsignment,
					// comparator: dateComparator,
					valueGetter: function (params) {
						if (params.data.hasOwnProperty('consignment_no') && params.data.consignment_no != '' && params.data.consignment_no != undefined) {
							return parseInt(params.data.consignment_no).toString();
						}
						else {
							return "";
						}
					},
				},

				// {
				// 	headerName: invoiceTimeName,
				// 	field: "invoice_time",
				// 	width: 150,
				// 	pinned: 'left',
				// 	//filter: "agDateColumnFilter",
				// 	cellRenderer: '', resizable: true,
				// 	comparator: dateComparator,

				// 	valueGetter: function (params) {
				// 		if (params.data.invoice_time != '' && params.data.invoice_time != undefined && params.data.invoice_time !== ' ') {
				// 			return getHyphenDDMMMYYYYHHMM(params.data.invoice_time);
				// 		}
				// 		else {
				// 			return "NA";
				// 		}
				// 	},
				// 	// hide: (this.state.tnpReturnConsignmentsVisibilty) ? true : hideonlyPRT, // this logic was imported from msil so for hero we are commenting this as of 27-07-2022
				// },
				{
					headerName: "Booking Date",
					field: "booking_date",
					width: 150,
					// pinned: "left",
					//filter: "agDateColumnFilter",
					// cellRenderer: '', 
					resizable: true,
					// comparator: dateComparator,
					comparator: (date1, date2) => {
						// console.log("dateComparator");
						// console.log("dateComparator",date1, date2);
						// console.log("date1MOment",moment(date1).isAfter(date2));
						
						// console.log(date1.length);
						if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
							let d1 = moment(date1,'DD-MMM-YYYY')
							let d2 = moment(date2,'DD-MMM-YYYY')
							return d2.isBefore(d1) ? 1: -1
							// console.log(date1,date2);
							// var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
							// console.log("date_1",date_1);
							// date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
							// console.log("date1",date1);
							// var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
							// date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
							// console.log("date2",date2);
							// // console.log(date1,date2);
							// var date1Number = monthToComparableNumber(date1);
							// var date2Number = monthToComparableNumber(date2);
							// // console.log(date1Number,date2Number);
							// if (date1Number === null && date2Number === null) {
							// 	return 0;
							// }
							// if (date1Number === null) {
							// 	return -1;
							// }
							// if (date2Number === null) {
							// 	return 1;
							// }
							// console.log("date1Number - date2Number",date1Number - date2Number);
							// return date1Number - date2Number;
						}
					},
					sortable: true,
					// sort: 'desc',
					// sortingOrder:['desc'],
					valueGetter: function (params) {
						if (params.data.booking_date != '' && params.data.booking_date != undefined && params.data.booking_date !== ' ') {
							return getHyphenDDMMMYYYY(params.data.booking_date);
							// const dateString = getHyphenDDMMMYYYY(params.data.booking_date);
							// const dateParts = dateString.split('-');
							// const day = parseInt(dateParts[0], 10);
							// const month = getMonthNumber(dateParts[1]);
							// const year = parseInt(dateParts[2], 10);
							// return new Date(year, month-1, day);
						}
						else {
							return "NA";
						}
					},
					// hide: (this.state.tnpReturnConsignmentsVisibilty) ? true : hideonlyPRT, // this logic was imported from msil so for hero we are commenting this as of 27-07-2022
				},
				// {
				// 	headerName: "Truck Starting Point",
				// 	field: "truck_starting_point",
				// 	width: 200,
				// 	filter: "agSetColumnFilter",
				// 	hide: last_consignment_zone,
				// 	cellRenderer: '', resizable: true
				// },
				// {
				// 	headerName: "GR No",
				// 	field: "consignment_code",
				// 	//colId : "consignment_code",
				// 	width: 120,
				// 	editable: false,
				// 	filter: "agSetColumnFilter", resizable: true,
				// 	//cellRenderer : "agGroupCellRenderer"
				// },
				{
					headerName: "Delivery Type",
					field: "delivery_type",
					//colId : "consignment_code",
					width: 120,
					// pinned: "left",
					editable: false,
					filter: "agSetColumnFilter", resizable: true,
					//cellRenderer : "agGroupCellRenderer"
				},
				{
					headerName: "Preferred Delivery Date",
					field: "preferred_delivery_date",
					width: 150,
					// pinned: "left",
					//filter: "agDateColumnFilter",
					cellRenderer: '', resizable: true,
					comparator: dateComparator,

					valueGetter: function (params) {
						if (params.data.preferred_delivery_date != '' && params.data.preferred_delivery_date != undefined && params.data.preferred_delivery_date !== ' ' && params.data.preferred_delivery_date !== 'NA') {
							return getHyphenDDMMMYYYY(params.data.preferred_delivery_date);
						}
						else {
							return "NA";
						}
					},
					// hide: (this.state.tnpReturnConsignmentsVisibilty) ? true : hideonlyPRT, // this logic was imported from msil so for hero we are commenting this as of 27-07-2022
				},
				{
					headerName: "Preferred Delivery Time Slot",
					field: "preferred_delivery_time_slot",
					width: 150,
					// pinned: "left",
					hide: hideonlyTNP,
					filter: "agSetColumnFilter",
					cellRenderer: '', resizable: true
				},
				{
					headerName: "Interaction Status",
					field: "step_code",
					width: 150,
					// pinned: "left",
					hide: hideonlyTNP,
					filter: "agSetColumnFilter",
					cellRenderer: '', resizable: true,
					valueGetter: (params) => {
						if (params.data.step_code == "PREFERRED_LANGUAGE") {
							return "Yet to Respond"
						}
						if (params.data.step_code == "OFFER_PREFERRED_TIME_DELIVERY") {
							return "PTD Service Offered"
						}
						if (params.data.step_code == "SELECT_PREFERRED_DATE") {
							return "Date Slot Offered"
						}
						if (params.data.step_code == "SELECT_PREFERRED_TIME_SLOT") {
							return "Time Slot Offered"
						}
						if (params.data.step_code == "CONFIRM_PREFERRED_TIME_DELIVERY") {
							return "Time Slot Confirmed"
						}
						if (params.data.step_code == "THANK_YOU") {
							return "Price Confirmed"
						}
						if (params.data.step_code == "NOT_VALID_WHATSAPP_NUMBER") {
							return "Invalid WhatsApp Number"
						}
						if (params.data.step_code == "NO_AVAILABLE_SLOTS") {
							return "No Available Slots"
						}
						if (params.data.step_code == "NA") {
							return "Message Not Triggered"
						}
						if (params.data.step_code == "DELIVERY_CONFIRMATION_EN") {
							return "Delievered"
						}
						
						if (["FEEDBACK_DELIVERED_ON_TIME", "FEEDBACK_ON_TIME", "FEEDBACK_THANKYOU"].includes(params.data.step_code)) {
							return "Feedback sent"
						}
						
					}
				},
				{
					headerName: "Amount",
					field: "amount",
					//colId : "consignment_code",
					width: 120,
					// pinned: "left",
					editable: false,
					filter: "agSetColumnFilter", resizable: true,
					//cellRenderer : "agGroupCellRenderer"
				},
				
				/*	{
						headerName: "VIN No(s)",
						field: "vin_list",
						width: 120,
						pinned: "left",
						editable: false,
						resizable: true,
						hide: hideVinsbvh
					},
					{
						headerName: "SBVH Code",
						field: "sbvh",
						width: 120,
						pinned: "left",
						editable: false,
						resizable: true,
						hide: hideVinsbvh
					},*/
				// {
				// 	headerName: "Status",
				// 	field: "status",
				// 	width: 120,
				// 	pinned: 'left',
				// 	// hide : hideStatus,
				// 	filter: "agSetColumnFilter",
				// 	resizable: true,
				// 	valueGetter: (params) => {
				// 		//console.log("GPS ", params.data.gps_data_available);
				// 		if (params.data.status == 1) {
				// 			return "Inside Plant"
				// 		}
				// 		else if (params.data.status == 2) {
				// 			return "In Transit"
				// 		}
				// 		else if (params.data.dest_geofence_id != undefined && screen_type == "LOG-PRT") {
				// 			if (screen_url != "/prtconsignments") {
				// 				return "Reached Plant Gate";
				// 			}
				// 		}
				// 		else if (params.data.status == 3) {
				// 			return "Near Destination"
				// 		}
				// 		else if (params.data.status == 4) {
				// 			return "Reached Destination"
				// 		}

				// 		else if (params.data.status == 5) {
				// 			return "Left Destination"
				// 		}
				// 		else if (params.data.status == 6) {
				// 			return "Force Closed"
				// 		}
				// 		else if (params.data.status == 7) {
				// 			return "Proximity Trip Closure"
				// 		}
				// 		else if (params.data.status == 8 && this.state.deptcode == "LOG-PRT") {
				// 			return "POD Received From MSIL"
				// 		}
				// 		else {
				// 			return ""
				// 		}

				// 	}
				// },
				// {
				// 	headerName: "Original ETA",
				// 	field: "expected_trip_end",
				// 	id: "expected_trip_end",
				// 	width: 120,
				// 	//pinned: 'left',
				// 	resizable: true,
				// 	valueGetter: function (params) {
				// 		if (params.data.expected_trip_end != "" && params.data.expected_trip_end != undefined && params.data.expected_trip_end != null) {
				// 			return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end);
				// 		}
				// 		else {
				// 			return "";
				// 		}


				// 	},
				// 	//filter: "agDateColumnFilter",
				// 	comparator: dateComparator,

				// },
				// {
				// 	headerName: "Revised ETA",
				// 	field: "revised_trip_end",
				// 	width: 120,
				// 	//pinned: 'left',
				// 	resizable: true,
				// 	// cellClass : "dateFormat",
				// 	valueGetter: function (params) {
				// 		//console.log(" ETA ", params.data)
				// 		if (params.data.revised_trip_end == '' || params.data.revised_trip_end == undefined) {
				// 			return getHyphenDDMMMYYYYHHMM(params.data.expected_trip_end);
				// 		}
				// 		else {
				// 			return getHyphenDDMMMYYYYHHMM(params.data.revised_trip_end);
				// 		}

				// 	},
				// 	//filter: "agDateColumnFilter",
				// 	comparator: dateComparator,

				// 	cellClass: function (params) {
				// 		let hourDifference = 0;
				// 		if (params.data.revised_trip_end && params.data.expected_trip_end) {
				// 			let revisedDateOnlyMoment = moment(params.data.revised_trip_end);
				// 			let expectedDateOnlyMoment = moment(params.data.expected_trip_end);
				// 			hourDifference = revisedDateOnlyMoment.diff(expectedDateOnlyMoment, 'hours');
				// 			//console.log("diff= ", params.data.consignment_code, hourDifference);
				// 		} else {
				// 			//console.log("ELSE = ", params.data.revised_trip, params.data.expected_trip_end);
				// 		}

				// 		if (hourDifference >= 1) {
				// 			return 'bgColorDangerMedium'
				// 		}

				// 		if (hourDifference <= -1) {
				// 			return 'bgColorSuccessMedium'
				// 		}
				// 		else {
				// 			return ''
				// 		}
				// 	}
				// },
				// {
				// 	headerName: "Transit Delay (Days)",
				// 	field: "consignment_code",
				// 	width: 120,
				// 	filter: "agSetColumnFilter",
				// 	cellRenderer: '',
				// 	resizable: true,
				// 	valueGetter: function (params) {
				// 		var expected = params.data.expected_trip_end;
				// 		if (params.data.revised_trip_end == '' || params.data.revised_trip_end == undefined) {
				// 			var revised = params.data.expected_trip_end;
				// 		}
				// 		else {
				// 			var revised = params.data.revised_trip_end;
				// 		}
				// 		//console.log("Exp ", expected)
				// 		//console.log("revised ", revised)
				// 		var s = moment.parseZone(expected).format("x");
				// 		var ed = moment.parseZone(revised).format("x");
				// 		//console.log("Exp ", s)
				// 		//console.log("revised ", ed)
				// 		var diff = (ed - s)
				// 		// console.log("Diff",diff/1000)
				// 		var days = secondsToDays(diff)
				// 		//console.log("Days ", Math.round(days/1000))
				// 		var totaldays = Math.round(days / 1000);
				// 		if (totaldays > 0) {
				// 			return totaldays;
				// 		}
				// 		else {
				// 			return 0;
				// 		}
				// 		//return Math.round(days/1000);

				// 	}
				// },
				// {
				// 	headerName: "Truck No",
				// 	field: "truck_no",
				// 	width: 120,
				// 	pinned: "left",
				// 	filter: "agSetColumnFilter",
				// 	resizable: true
				// },
				// {
				// 	headerName: "Duration from Gate Out",
				// 	field: "duration_from_gate_out",
				// 	width: 170,
				// 	pinned: "left",
				// 	filter: true,
				// 	resizable: true,
				// 	hide: hideDuration,
				// 	valueGetter: function (params) {
				// 		if (params.data.gate_out_time != undefined &&
				// 			params.data.gate_out_time != "" &&
				// 			params.data.gate_out_time != null
				// 		) {
				// 			var gateout = new Date(params.data.gate_out_time);
				// 			var lastpacket = new Date();
				// 			var diff = lastpacket.getTime() - gateout.getTime();
				// 			var diffdate = new Date(diff);
				// 			var days = Math.floor(diff / (1000 * 60 * 60 * 24))
				// 			days = (days < 0) ? 0 : days;
				// 			return days + " Day(s) : " + diffdate.getHours() + " hours : " + diffdate.getMinutes() + " Minutes"
				// 		}
				// 		else {
				// 			return "";
				// 		}

				// 	}
				// },

				// {
				// 	headerName: "Transporter Name",
				// 	field: "transporter_name",
				// 	width: 150,
				// 	filter: "agSetColumnFilter",
				// 	cellRenderer: '',
				// 	resizable: true,
				// 	valueGetter: function (params) {
				// 		if (params.data.truck_no != "" && params.data.truck_no != "") {
				// 			return params.data.transporter_name
				// 		}
				// 	}
				// },
				// {
				// 	headerName: "Transporter Code",
				// 	field: "transporter_code",
				// 	width: 110,
				// 	filter: "agSetColumnFilter",
				// 	hide: hideTransporterCode,
				// 	cellRenderer: '', resizable: true,
				// 	valueGetter: function (params) {
				// 		if (params.data.truck_no != "" && params.data.truck_no != "") {
				// 			return params.data.transporter_code
				// 		}
				// 	}
				// },

				// {
				// 	headerName: "Last Location",
				// 	field: "address",
				// 	width: 170,
				// 	// hide:showOnlyCol,
				// 	valueGetter: function (params) {
				// 		//console.log(params.data.transporter);
				// 		try {
				// 			// console.log("params.data.truck.length ", params.data.truck.length)
				// 			if (params.data.truck.length > 0) {
				// 				// console.log("params.data.truck ", params.data.truck)
				// 				if (params.data.truck[0].last_known_address != undefined) {
				// 					if (params.data.truck_no != "" && params.data.truck_no != "") {
				// 						return params.data.truck[0].last_known_address;
				// 					}
				// 					else {
				// 						return "";
				// 					}
				// 				}
				// 				else {
				// 					return "";
				// 				}
				// 			}
				// 			else {
				// 				return "";
				// 			}
				// 		}
				// 		catch (e) {

				// 		}
				// 	},
				// 	filter: "agSetColumnFilter",
				// 	cellRenderer: '', resizable: true
				// },

				// {
				// 	headerName: "Travel Status",
				// 	field: "elaped_time_from_last_moved_seconds",
				// 	width: 150,
				// 	filter: "agSetColumnFilter",
				// 	valueGetter: function (params) {
				// 		try {
				// 			if (params.data.truck_no != "" && params.data.truck_no != "") {
				// 				if (params.data.elaped_time_from_last_moved_seconds != "" && params.data.elaped_time_from_last_moved_seconds != undefined) {
				// 					return "Idle (" + secondsToDhms(params.data.elaped_time_from_last_moved_seconds) + ")";
				// 				}

				// 				else {
				// 					if (params.data.elaped_time_from_last_moved_seconds == "" && params.data.elaped_time_from_last_moved_seconds == undefined) {

				// 						return "";
				// 					}
				// 					else {
				// 						return "Running";
				// 					}

				// 				}
				// 			}
				// 		}
				// 		catch (e) {

				// 		}
				// 	}
				// },

				// {
				// 	headerName: "Speed",
				// 	field: "speed",
				// 	width: 150,
				// 	filter: "agSetColumnFilter"
				// },

				// {
				// 	headerName: "Travelled Distance (KM)",
				// 	field: "aprox_dist_travelled_from_start",
				// 	width: 170,
				// 	filter: "agSetColumnFilter",
				// 	valueGetter: params => {
				// 		let data = params.data.aprox_dist_travelled_from_start
				// 		if (data == 0) {
				// 			return 0
				// 		}
				// 		return data
				// 		// console.log("params",params.data.aprox_dist_travelled_from_start)
				// 	}
				// },
				// {
				// 	headerName: "Last Packet Time",
				// 	field: "truck",
				// 	// hide:showtimestamp,
				// 	valueGetter: function (params) {
				// 		//console.log(params.data.transporter);
				// 		if (params.data.hasOwnProperty('truck')) {
				// 			if (params.data.truck_no != "" && params.data.truck_no != "") {
				// 				if (params.data.truck.length > 0) {
				// 					if (params.data.truck[0].timestamp != undefined) {
				// 						return getHyphenDDMMMYYYYHHMM(params.data.truck[0].timestamp);
				// 					}
				// 					else {
				// 						// var s1 = moment.parseZone(params.data.truck[0].timestamp).format("x");
				// 						// var s2 = moment.parseZone(params.data.truck[0].manual_timestamp).format("x");
				// 						// if (s1 > s2) {
				// 						// 	return getHyphenDDMMMYYYYHHMM(params.data.truck[0].timestamp);
				// 						// }
				// 						// else {
				// 						// 	var tstamp = getHyphenDDMMMYYYYHHMM(params.data.truck[0].manual_timestamp);
				// 						// 	return tstamp + " (M)";
				// 						// }
				// 						return "";
				// 					}
				// 				}
				// 			}
				// 		}

				// 	},
				// 	comparator: dateComparator,

				// 	width: 150,
				// 	//filter: "agDateColumnFilter",
				// 	cellRenderer: '', resizable: true
				// },
				// {
				// 	headerName: "GPS Provider",
				// 	field: "truck",
				// 	width: 150,
				// 	filter: "agSetColumnFilter",
				// 	cellRenderer: '', resizable: true,
				// 	hide: showOnlyCol,
				// 	valueGetter: function (params) {
				// 		//console.log(params.data.transporter);
				// 		if (params.data.hasOwnProperty('truck')) {
				// 			if (params.data.truck.length > 0) {
				// 				return params.data.truck[0].actual_lspuser;
				// 			}
				// 		}

				// 	},
				// },
				{
					headerName: "Plant Name",
					field: "consigner_name",
					width: 120,
					filter: "agSetColumnFilter",
					sortable:false,
					cellRenderer: '', resizable: true
				},
				// {
				// 	headerName: "Distributor Code",
				// 	field: "consignee_code",
				// 	//colId : "consignment_code",
				// 	width: 120,
				// 	editable: false,
				// 	filter: "agSetColumnFilter", resizable: true,
				// 	//cellRenderer : "agGroupCellRenderer"
				// },
				{
					headerName: "Distributor Name",
					field: "consignee_name",
					//colId : "consignment_code",
					width: 120,
					editable: false,
					filter: "agSetColumnFilter", resizable: true,
					//cellRenderer : "agGroupCellRenderer"
				},
				{
					headerName: "Customer ID",
					field: "customer_id",
					width: 120,
					filter: "agSetColumnFilter",
					cellRenderer: '', 
					resizable: true
				},
				{
					headerName: "Customer Name",
					field: "customer_name",
					width: 120,
					filter: "agSetColumnFilter",
					cellRenderer: '', 
					resizable: true
				},
				{
					headerName: "Shipping Address",
					field: "shipping_address",
					width: 120,
					filter: "agSetColumnFilter",
					cellRenderer: '', 
					resizable: true
				},

				// {
				// 	headerName: "Plant Code",
				// 	field: "consigner_code",
				// 	width: 120,
				// 	filter: "agSetColumnFilter",
				// 	cellRenderer: '', resizable: true
				// },
				// {
				// 	headerName: "Dist. From Destination (km)",
				// 	field: "distance_from_dealer_location",
				// 	width: 150,
				// 	filter: "agSetColumnFilter",
				// 	cellRenderer: '', resizable: true,
				// 	valueGetter: function (params) {
				// 		//console.log("params ", params.data);
				// 		if (typeof params.data.distance_from_dealer_location == 'undefined') {

				// 		}
				// 		else {
				// 			return Math.round(params.data.distance_from_dealer_location);
				// 		}

				// 	},
				// 	hide: showonlyactivencr
				// },
				// {
				// 	headerName: "Dealer Code",
				// 	field: "consignee_code",
				// 	width: 150,
				// 	filter: "agSetColumnFilter",
				// 	cellRenderer: '', resizable: true
				// },

				// {
				// 	headerName: "Dealer Name",
				// 	field: "consignee_name",
				// 	width: 150,
				// 	hide: hideonlyTNP,
				// 	filter: "agSetColumnFilter",
				// 	cellRenderer: '', resizable: true
				// },
				{
					headerName: "City",
					field: "customer_city",
					width: 150,
					hide: hideonlyTNP,
					filter: "agSetColumnFilter",
					cellRenderer: '', resizable: true
				},

				{
					headerName: "State",
					field: "customer_state",
					width: 150,
					hide: hideonlyTNP,
					filter: "agSetColumnFilter",
					cellRenderer: '', resizable: true
				},
				{
					headerName: "Registered Mobile No",
					field: "customer_mobile_no",
					width: 150,
					hide: hideonlyTNP,
					filter: "agSetColumnFilter",
					cellRenderer: '', resizable: true
				},
				{
					headerName: "Area Code",
					field: "customer_area",
					width: 150,
					hide: hideonlyTNP,
					filter: "agSetColumnFilter",
					cellRenderer: '', resizable: true
				},
				{
					headerName: "Booking Area",
					field: "consignment_area",
					width: 150,
					hide: hideonlyTNP,
					filter: "agSetColumnFilter",
					cellRenderer: '', resizable: true
				},
				// {
				// 	headerName: "Preferred Delivery Opted",
				// 	field: "is_preferred_delivery",
				// 	width: 150,
				// 	hide: hideonlyTNP,
				// 	filter: "agSetColumnFilter",
				// 	cellRenderer: '', resizable: true
				// },
				
				// {
				// 	headerName: "Amount",
				// 	field: "amount",
				// 	width: 150,
				// 	hide: hideonlyTNP,
				// 	filter: "agSetColumnFilter",
				// 	cellRenderer: '', resizable: true
				// },
				{
					headerName: "Product Code",
					field: "product_code",
					width: 150,
					hide: hideonlyTNP,
					filter: "agSetColumnFilter",
					cellRenderer: '', resizable: true
				},
				{
					headerName: "Refill Type",
					field: "refill_type",
					width: 150,
					hide: hideonlyTNP,
					filter: "agSetColumnFilter",
					cellRenderer: '', resizable: true
				},
				{
					headerName: "Delivery Status",
					field: "delivery_flag",
					width: 150,
					hide: hideonlyTNP,
					filter: "agSetColumnFilter",
					cellRenderer: '', resizable: true
				},
				{
					headerName: "No of Cylinders",
					field: "no_of_cylinders",
					width: 150,
					hide: hideonlyTNP,
					filter: "agSetColumnFilter",
					cellRenderer: '', resizable: true
				},
				{
					headerName: "Payment Method",
					field: "payment_method",
					width: 150,
					hide: hideonlyTNP,
					filter: "agSetColumnFilter",
					cellRenderer: '', resizable: true
				},
				{
					headerName: "Delivery Person",
					field: "delivery_person_name",
					width: 150,
					hide: hideonlyTNP,
					filter: "agSetColumnFilter",
					cellRenderer: '', resizable: true
				},
				{
					headerName: "Delivery Person Mobile No",
					field: "delivery_person_mobile_no",
					width: 150,
					hide: hideonlyTNP,
					filter: "agSetColumnFilter",
					cellRenderer: '', resizable: true
				},
				// {
				// 	headerName: "Mark Delivered",
				// 	field: "booking_no",
				// 	width: 100,
				// 	cellRendererSelector: function (params) {
				// 		var rendComponent = {
				// 			component: 'MarkDeliver'
				// 		};
				// 		return rendComponent;
				// 	},
				// 	hide: false,
				// 	filter: false, resizable: true,
				// },
				// {
				// 	headerName: "GPS Gate In Time",
				// 	field: "inside_fence",
				// 	width: 180,
				// 	hide: (this.state.tnpReturnConsignmentsVisibilty) ? true : false,
				// 	//filter: "agDateColumnFilter",
				// 	comparator: dateComparator,

				// 	valueGetter: function (params) {
				// 		return getHyphenDDMMMYYYYHHMM(params.data.inside_fence);
				// 	},
				// 	resizable: true
				// },
				// {
				// 	headerName: "GPS Gate out Time",
				// 	field: "outside_fence",
				// 	width: 180,
				// 	hide: (this.state.tnpReturnConsignmentsVisibilty) ? true : false,
				// 	//filter: "agDateColumnFilter",
				// 	comparator: dateComparator,

				// 	valueGetter: function (params) {
				// 		return getHyphenDDMMMYYYYHHMM(params.data.outside_fence);
				// 	},
				// 	resizable: true
				// },
				// {
				// 	headerName: "Dist. Traveled last 24Hr (KM)",
				// 	field: "distance_travelled_in_24_hrs_kms",
				// 	//colId : "consignment_code",
				// 	width: 120,
				// 	editable: false,
				// 	filter: "agSetColumnFilter", resizable: true,
				// 	hide: showdistance,
				// 	valueGetter: function (params) {
				// 		//console.log(params.data.distance_travelled_in_24_hrs_kms);
				// 		if (params.data.distance_travelled_in_24_hrs_kms != "" && params.data.distance_travelled_in_24_hrs_kms != undefined) {
				// 			return params.data.distance_travelled_in_24_hrs_kms;
				// 		}
				// 		else {
				// 			return 0;
				// 		}
				// 	}
				// 	//cellRenderer : "agGroupCellRenderer"
				// },
				// {
				// 	headerName: "Truck Reporting Date",
				// 	field: "truck_reporting_date",
				// 	width: 130,
				// 	editable: false,
				// 	// cellEditor:DateEditor,
				// 	valueGetter: (params) => {
				// 		if (params.data.truck_reporting_date != undefined && params.data.truck_reporting_date != null) {
				// 			return getHyphenDDMMMYYYYHHMM(params.data.truck_reporting_date)
				// 		}
				// 		else {
				// 			return ""
				// 		}
				// 	},
				// 	resizable: true,
				// 	hide: isHiddenLocalMovement

				// },
				// {
				// 	headerName: "Transshipment",
				// 	field: "is_transshipment",
				// 	width: 110,
				// 	filter: "agSetColumnFilter",
				// 	valueGetter: function (params) {
				// 		if (params.data.is_transshipment == 1) {
				// 			return "Yes";
				// 		}
				// 		else {
				// 			return "No";
				// 		}
				// 	},
				// 	resizable: true,
				// 	// hide: (this.state.tnpReturnConsignmentsVisibilty) ? true : false
				// 	hide: hidePriority
				// },

				// {
				// 	headerName: "Old Truck No",
				// 	field: "old_truck_no",
				// 	width: 200,
				// 	filter: "agSetColumnFilter",
				// 	cellRenderer: '', resizable: true,
				// 	// hide: (this.state.tnpReturnConsignmentsVisibilty) ? true : false
				// 	hide: hidePriority
				// },
				// {
				// 	headerName: "Transshipment Date",
				// 	field: "transshipment_date",
				// 	width: 200,
				// 	filter: "agSetColumnFilter",
				// 	resizable: true,
				// 	valueGetter: function (params) {
				// 		return getHyphenDDMMMYYYYHHMM(params.data.transshipment_date);
				// 	},
				// 	// hide: (this.state.tnpReturnConsignmentsVisibilty) ? true : false
				// 	hide: hidePriority

				// },

				// {
				// 	headerName: "Reached Destination At",
				// 	field: "first_dealer_reported",
				// 	width: 180,

				// 	hide: hideonlyCol,
				// 	//cellRenderer:'dateFormater',
				// 	valueGetter: function (params) {
				// 		if (params.data.status == 4 || params.data.status == 5) {
				// 			return getHyphenDDMMMYYYYHHMM(params.data.recent_dealer_reported);
				// 		}
				// 		else {
				// 			return "";
				// 		}

				// 	},
				// 	resizable: true,

				// },
				// {
				// 	headerName: "Reached Plant Gate In",
				// 	field: "locked_dealer_reported",
				// 	width: 150,
				// 	//filter: "agDateColumnFilter",
				// 	cellRenderer: '', resizable: true,
				// 	hide: hideDealerGateIn,
				// 	comparator: dateComparator,
				// 	valueGetter: function (params) {

				// 		if (params.data.locked_dealer_reported != '' && params.data.locked_dealer_reported != undefined
				// 			&& params.data.dest_geofence_id != undefined) {
				// 			return getHyphenDDMMMYYYYHHMM(params.data.locked_dealer_reported);
				// 		}
				// 		else {
				// 			return "";
				// 		}
				// 	},
				// },
				// {
				// 	headerName: "Left Destination At",
				// 	field: "left_dealer_marked",
				// 	width: 180,
				// 	//filter: "agDateColumnFilter",
				// 	hide: (this.state.tnpReturnConsignmentsVisibilty) ? true : hideonlyCol,
				// 	//cellRenderer:'dateFormater',
				// 	valueGetter: function (params) {
				// 		if (params.data.status == 5) {
				// 			return getHyphenDDMMMYYYYHHMM(params.data.left_dealer_marked);
				// 		}
				// 		else {
				// 			return "";
				// 		}

				// 	},
				// 	resizable: true,

				// },
				// {
				// 	headerName: "OnTime Delivery",
				// 	field: "ontime_status",
				// 	width: 110,
				// 	hide: hideOnTime,
				// 	valueGetter: function (params) {
				// 		if (params.data.ontime_status == 1) {
				// 			return "On Time";
				// 		}
				// 		else if (params.data.ontime_status == 0) {
				// 			return "Delayed";
				// 		} else {
				// 			return "";
				// 		}
				// 	},
				// 	cellClass: function (params) {
				// 		if (params.data.ontime_status == 1) {
				// 			return "bgColorSuccessMedium";
				// 		}
				// 		else if (params.data.ontime_status == 0) {
				// 			return "bgColorDangerMedium";
				// 		} else {
				// 			return "";
				// 		}
				// 	}


				// },


				// {
				// 	headerName: transitTimeHeading,
				// 	field: "transit_time",
				// 	width: 200,
				// 	filter: "agSetColumnFilter",
				// 	cellRenderer: '', resizable: true,
				// 	valueGetter: function (params) {
				// 		if (department_code == "LOG-PRT") {
				// 			if (params.data.transit_time == 0) {
				// 				return "-";
				// 			}
				// 			else {
				// 				return params.data.transit_time;
				// 			}
				// 		}
				// 		else {
				// 			return params.data.transit_time;
				// 		}

				// 	}
				// },
				// {

				// 	headerName: "No Of Tolls",
				// 	field: "number_of_tolls",
				// 	width: 100,
				// 	filter: "agSetColumnFilter",
				// 	hide: showForDeliveryPrt,
				// 	cellRenderer: '', resizable: true,
				// 	valueGetter: function (params) {
				// 		if (department_code == "LOG-PRT") {
				// 			if (params.data.number_of_tolls !== 0 && params.data.number_of_tolls !== undefined) {
				// 				return params.data.number_of_tolls;
				// 			}
				// 			else if(params.data.status === 6){
				// 				return 'NA'
				// 			}
				// 			else {
				// 				return 0;
				// 			}
				// 		}						
				// 	}
				// },
				// {
				// 	headerName: "Expected Distance (KM)",
				// 	field: "distance_in_km",
				// 	width: 200,
				// 	filter: "agSetColumnFilter",
				// 	cellRenderer: '', resizable: true
				// },
				// {
				// 	headerName: "Warehouse",
				// 	field: "warehouse",
				// 	width: 160,
				// 	filter: "agSetColumnFilter",
				// 	cellRenderer: '',
				// 	resizable: true,
				// 	// valueGetter: param =>{
				// 	// 	return param.data.wh_transshipment_date ? getHyphenDDMMMYYYYHHMM(param.data.wh_transshipment_date) : ""
				// 	// }
				// 	hide: hidePriority

				// },
				// {
				// 	headerName: "TVP",
				// 	field: "tvp",
				// 	width: 160,
				// 	filter: "agSetColumnFilter",
				// 	cellRenderer: '',
				// 	resizable: true,
				// 	// valueGetter: param =>{
				// 	// 	return param.data.wh_transshipment_date ? getHyphenDDMMMYYYYHHMM(param.data.wh_transshipment_date) : ""
				// 	// }
				// 	hide: hidePriority

				// },
				// {
				// 	headerName: "TVP Load",
				// 	field: "tvp_load",
				// 	width: 160,
				// 	filter: "agSetColumnFilter",
				// 	cellRenderer: '',
				// 	resizable: true,
				// 	valueGetter: param => {
				// 		return param.data.tvp_load == 1 ? "Yes" : "No"
				// 	},
				// 	hide: hidePriority


				// },
				// {
				// 	headerName: "TVP Reached On",
				// 	field: "reached_tvp_on",
				// 	width: 160,
				// 	filter: "agSetColumnFilter",
				// 	cellRenderer: '',
				// 	resizable: true,
				// 	valueGetter: param => {
				// 		return (param.data.tvp_load == 1) ? getHyphenDDMMMYYYYHHMM(param.data.reached_tvp_on) : ''
				// 	},
				// 	hide: hidePriority

				// },
				// {
				// 	headerName: "Warehouse Transshipment",
				// 	field: "is_wh_transshipment",
				// 	width: 160,
				// 	filter: "agSetColumnFilter",
				// 	cellRenderer: '',
				// 	resizable: true,
				// 	valueGetter: param => {
				// 		return param.data.is_wh_transshipment == 1 ? "Yes" : "No"
				// 	},
				// 	hide: hidePriority

				// },
				// {
				// 	headerName: "Warehouse Transshipment Date",
				// 	field: "wh_transshipment_date",
				// 	width: 160,
				// 	filter: "agSetColumnFilter",
				// 	cellRenderer: '',
				// 	resizable: true,
				// 	valueGetter: param => {
				// 		return param.data.wh_transshipment_date ? getHyphenDDMMMYYYYHHMM(param.data.wh_transshipment_date) : ""
				// 	},
				// 	hide: hidePriority

				// },
				// {
				// 	headerName: "Warehouse Transshipment Old Truck",
				// 	field: "old_wh_truck_no",
				// 	width: 160,
				// 	filter: "agSetColumnFilter",
				// 	cellRenderer: '',
				// 	resizable: true,
				// 	hide: hidePriority,
				// 	// valueGetter: param =>{
				// 	// 	return param.data.wh_transshipment_date ? getHyphenDDMMMYYYYHHMM(param.data.wh_transshipment_date) : ""
				// 	// }
				// },
				// {
				// 	headerName: "TVP Transshipment",
				// 	field: "is_tvp_transshipment",
				// 	width: 160,
				// 	filter: "agSetColumnFilter",
				// 	cellRenderer: '',
				// 	resizable: true,
				// 	valueGetter: param => {
				// 		return param.data.is_tvp_transshipment == 1 ? "Yes" : "No"
				// 	},
				// 	hide: hidePriority
				// },
				// {
				// 	headerName: "TVP Transshipment Date",
				// 	field: "tvp_transshipment_date",
				// 	width: 160,
				// 	filter: "agSetColumnFilter",
				// 	cellRenderer: '',
				// 	resizable: true,
				// 	valueGetter: param => {
				// 		return param.data.tvp_transshipment_date ? getHyphenDDMMMYYYYHHMM(param.data.tvp_transshipment_date) : ""
				// 	},
				// 	hide: hidePriority

				// },
				// {
				// 	headerName: "TVP Transshipment Old Truck",
				// 	field: "old_tvp_truck_no",
				// 	width: 160,
				// 	filter: "agSetColumnFilter",
				// 	cellRenderer: '',
				// 	resizable: true,
				// 	hide: hidePriority,
				// 	// valueGetter: param =>{
				// 	// 	return param.data.wh_transshipment_date ? getHyphenDDMMMYYYYHHMM(param.data.wh_transshipment_date) : ""
				// 	// }
				// },
				// {
				// 	headerName: "Sim Tracking State",
				// 	field: "sim_tracking_state",
				// 	width: 120,
				// 	// pinned: 'left',
				// 	// hide : hideStatus,
				// 	filter: "agSetColumnFilter",
				// 	resizable: true,
				// 	valueGetter: (params) => {
				// 		//console.log("GPS ", params.data.gps_data_available);
				// 		if (params.data.sim_tracking_state == 1) {
				// 			return "NOT ENABLED"
				// 		}
				// 		else if (params.data.sim_tracking_state == 2) {
				// 			return "NUMBER NOT UPDATED"
				// 		}
				// 		else if (params.data.sim_tracking_state == 3) {
				// 				return "ACTIVATED";
				// 		}
				// 		else if (params.data.sim_tracking_state == 4) {
				// 			return "DEACTIVATED"
				// 		}
				// 		else if (params.data.sim_tracking_state == 5) {
				// 			return "YET TO REGISTER"
				// 		}

				// 		else if (params.data.sim_tracking_state == 6) {
				// 			return "PENDING FOR CONSENT APPROVAL"
				// 		}
				// 		else if (params.data.sim_tracking_state == 7) {
				// 			return "CONSENT ACCEPTED"
				// 		}
				// 		else if (params.data.sim_tracking_state == 8) {
				// 			return "CONSENT DECLINED FIRST TIME"
				// 		}
				// 		else if (params.data.sim_tracking_state == 9) {
				// 			return "ENABLED"
				// 		}
				// 		else if (params.data.sim_tracking_state == 10) {
				// 			return "CONSENT DECLINED ON TRIP"
				// 		}
				// 		else if (params.data.sim_tracking_state == 11) {
				// 			return "REGISTERED"
				// 		}
				// 		else if (params.data.sim_tracking_state == 12) {
				// 			return "DEREGISTERED"
				// 		}
				// 		else if (params.data.sim_tracking_state == 13) {
				// 			return "ERROR"
				// 		}
				// 		else if (params.data.sim_tracking_state == 14) {
				// 			return "REQUESTED REGISTRATION"
				// 		}
				// 		else if (params.data.sim_tracking_state == 15) {
				// 			return "REQUESTED DEREGISTRATION"
				// 		}
				// 		else if (params.data.sim_tracking_state == 16) {
				// 			return "REQUESTED ACTIVATION"
				// 		}
				// 		else if (params.data.sim_tracking_state == 17) {
				// 			return "REQUESTED DEACTIVATION"
				// 		}
				// 		else if (params.data.sim_tracking_state == 18) {
				// 			return "REQUESTED LOCATION"
				// 		}
				// 		else if (params.data.sim_tracking_state == 19) {
				// 			return "ACTIVATION FAILED"
				// 		}
				// 		else if (params.data.sim_tracking_state == 20) {
				// 			return "DEACTIVATION FAILED"
				// 		}
				// 		else if (params.data.sim_tracking_state == 21) {
				// 			return "DEREGISTRATION FAILED"
				// 		}
				// 		else if (params.data.sim_tracking_state == 22) {
				// 			return "REGISTRATION FAILED"
				// 		}
				// 		else if (params.data.sim_tracking_state == 23) {
				// 			return "STOPPED TRIP END"
				// 		}
				// 		else if (params.data.sim_tracking_state == 24) {
				// 			return "DEACTIVATED DUE TO GPS EXISTS"
				// 		}
				// 		else if (params.data.sim_tracking_state == 25) {
				// 			return "REQUESTED DEACTIVATE DUE TO GPS EXISTS"
				// 		}
				// 		else if (params.data.sim_tracking_state == 26) {
				// 			return "REQUEST FAILED DEACTIVATE DUE TO GPS EXISTS"
				// 		}
				// 		else if (params.data.sim_tracking_state == 27) {
				// 			return "PENDING FOR CONSENT APPROVAL LONG TIME"
				// 		}
				// 		else {
				// 			return ""
				// 		}

				// 	}
				// },
				// {
				// 	headerName: "Driver Name",
				// 	field: "driver_name",
				// 	width: 150,
				// 	hide: hideonlyTNP,
				// 	filter: "agSetColumnFilter",
				// 	cellRenderer: '', resizable: true
				// },
				// {
				// 	headerName: "Driver Number",
				// 	field: "driver_mobile_no",
				// 	width: 150,
				// 	hide: hideonlyTNP,
				// 	filter: "agSetColumnFilter",
				// 	cellRenderer: '', resizable: true
				// },
				// {
				// 	headerName: "Remarks",
				// 	field: "transit_time_remarks",
				// 	width: 110,
				// 	editable:true,
				// 	// hide: (localStorage.getItem("transportercode").includes("T305")|| localStorage.getItem("transportercode").includes("T329"))?false:true,	
				// 	valueSetter:(params)=>{
				// 	// console.log(params)
				// 		if(params.oldValue != params.newValue)
				// 		{
				// 			var data = {
				// 				truck_no:params.data.truck_no,
				// 				transit_time_remarks:params.newValue,
				// 				email:localStorage.getItem("email")
				// 			}
				// 			params.data.transit_time_remarks = params.newValue;
				// 			redirectURL.post("/consignments/updatetransittimeremarks", data,{
				// 			headers:{
				// 				'Content-Type': 'application/json'
				// 				}
				// 				}).then((response)=>{
				// 			})
				// 		}
				// 	}
				// },
				// {
				// 	headerName: "Priority",
				// 	field: "is_priority",
				// 	width: 120,
				// 	hide: hidePriority,
				// 	valueGetter: function (params) {
				// 		if (params.data.is_priority == 1) {
				// 			return "Yes";
				// 		}
				// 		else {
				// 			return "No";
				// 		}
				// 	}
				// },
				// {
				// 	headerName: "Mark Priority",
				// 	field: "priority",
				// 	width: 130,
				// 	cellRenderer: function (params) {
				// 		if (params.data.is_priority == 1) {
				// 			return "";
				// 		}
				// 		else {
				// 			var htmloption = '<button class="btn btn-info" style="padding-top:0px">Priority</button>';
				// 			return htmloption;
				// 		}
				// 	},
				// 	hide: hidePriority,
				// 	filter: false,
				// 	resizable: true,
				// },
				// {
				// 	headerName: "Force Close",
				// 	field: "",
				// 	width: 80,
				// 	hide: showOnlyCol,
				// 	cellRendererSelector: function (params) {
				// 		var rendComponent = {
				// 			component: 'consignmentforceclose'
				// 		};
				// 		return rendComponent;
				// 	},
				// 	filter: false, resizable: true,


				// },

				// {
				// 	headerName: "Force Closed By",
				// 	field: "force_closure_performed_by",
				// 	width: 150,
				// 	hide:showOnlyColDeliver,
				// 	filter: false,resizable: true,


				// },

				// {
				// 	headerName: "Force Closed Reason",
				// 	field: "force_closure_reason",
				// 	width: 200,
				// 	hide: showOnlyColDeliver,
				// 	cellClass: ['wraptext'],
				// 	filter: false, resizable: true,


				// },

				// {
				// 	headerName: "Force Closed On",
				// 	field: "force_closure_time",
				// 	width: 180,
				// 	hide:showOnlyColDeliver,

				// 	filter: false,resizable: true,
				// 	valueGetter:function(params){
				// 		return getDDMMYYYYHHMMSS(params.data.force_closure_time)
				// 	}

				// }


			];
		const dStyles = {
			width: this.props.width,
			height: '500px',
			marginLeft: this.props.marginLeft,

		}
		const forceCloseColumnWithDefs = {
			headerName: "Force Close",
			field: "force_close",
			width:100,
			filter: true,
			resizable: true,
			// editable:true,
			cellRendererFramework: (e)=>{
				console.log("xdsds",e);
				return <button style={{
						color:"white",
						border:"none",
						textAlign:"center",
						background:"red"
					}} 
					onClick={()=>{
						var rowId = e.data._id;
						var consignmentNo = e.data.consignment_no;
						var consigneeCode = e.data.consignee_code;
						this.openActiveOrdersForceCloseModal();

						this.setState({
							forceCloseConsignmentNo: consignmentNo,
							forceCloseConsigneeCode: consigneeCode,
							colDefFieldName: e.colDef.field
						})
					}}>Force Close</button>
			},
			// cellRenderer: function (params) {
				// if (params.data.preferred_delivery_date != "" && params.data.preferred_delivery_date != undefined && params.data.preferred_delivery_date != "NA") {
				// 	var htmloption = '<button style="color: white; border: none; text-align: center; background-color: red">Force Close</button>';
				// } else {
				// 	htmloption = '<button style="color: white; border: none; text-align: center; background-color: red; opacity: 0.4;" disabled>Force Close</button>';
				// }
				// var htmloption = '<button style="color: white; border: none; text-align: center; background-color: red">Force Close</button>';
				// return htmloption
			// }
		}
		const closureStatusColumnWithDefs = [
			{
				headerName: "Closure Status",
				field: "closure_status",
				width: 100,
				hide: hideonlyTNP,
				filter: "agSetColumnFilter",
				cellRenderer: '', resizable: true
			},
			{
				headerName: "Delivered At",
				field: "marked_delivered_at",
				width: 150,
				hide: hideonlyTNP,
				filter: "agSetColumnFilter",
				cellRenderer: '', resizable: true,
				comparator: dateComparator,
				valueGetter: function (params) {
					if (params.data.marked_delivered_at != '' && params.data.marked_delivered_at != undefined && params.data.marked_delivered_at !== ' ') {
						return getHyphenDDMMYYYYHHMMSS(params.data.marked_delivered_at);
					}
					else {
						return "NA";
					}
				},
			},
		]
		if (this.props.match.path == "/prtdeliveredconsignments") {
			columnwithDefs.push(...closureStatusColumnWithDefs)
		}
		if (this.props.match.path == "/prtconsignments") {
			columnwithDefs.push(forceCloseColumnWithDefs)
		}
		const commentColumns = [
			{
				headerName: "Truck No",
				field: "truck_no",
				resizable: true,
				width: 100,
				filter: true
			},
			{
				headerName: "GR No",
				field: "consignment_code",
				resizable: true,
				width: 120,
				filter: true
			},
			{
				headerName: "User Type",
				field: "user_type",
				width: 100,
				resizable: true,
				filter: true,
				valueGetter: function (params) {
					if (params.data.user_type == "ORGUSER") {
						return "MSIL"
					}
					if (params.data.user_type == "DCTUSER") {
						return "DCT"
					}
					if (params.data.user_type == "TRANSPORTER") {
						return "TRANSPORTER"
					}
				}
			},
			{
				headerName: "Comment",
				field: "comment",
				width: 250,
				cellClass: ['wraptext'],
				resizable: true,
				filter: true
			},
			{
				headerName: "Commented By",
				field: "username",
				width: 150,
				resizable: true,
				filter: true
			},

			{
				headerName: "Commented Date",
				field: "comment_date",
				resizable: true,
				filter: true,
				width: 150,
				valueGetter: function (params) {
					if (params.data.comment_date != "") {
						return getDDMMYYYYHHMMSS(params.data.comment_date);
					}
				}
			}

		]
		var hText = 'All';
		// var filterClassName = "form-group col-xl-6 col-lg-6";
		// var filterCardStyle1 = {};
		// var filterCardStyle2 = {padding:'10px 30px'};
		var filterClassName = "form-group col-xl-12 col-lg-12";
		var filterCardStyle1 = { minHeight: "234px" };
		var filterCardStyle2 = { padding: '10px' };
		var mnFilterClass = { marginLeft: "0px", padding: "0px" };
		if (this.state.deptcode == 'SNDG') {
			hText = 'Sales and Dispatch';
			filterClassName = "form-group col-xl-12 col-lg-12";
			filterCardStyle1 = { minHeight: "234px" };
			filterCardStyle2 = { padding: '10px' };
		}
		if (this.state.deptcode == 'LOG-TNP') {
			hText = 'Train and Production';
			filterCardStyle1 = { minHeight: "240px" };
		}

		if (this.state.deptcode == 'LOG-PRT') {
			hText = 'Spare Parts';
			mnFilterClass = { marginLeft: "0px" };
		}
		const tripoptoins = [
			{ "value": "all", label: "All" },
			{ "value": "S", label: "Short Haul" },
			{ "value": "L", label: "Long Haul" }
		]
		
		return (
			<div className="container-fluid">
				<div className={"dataLoadpage " + (this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " + (this.state.loadshow)}>
					<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
				</div>
				<div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					onConfirm={this.closeAlert}>
				</SweetAlert>
				<Modal open={this.state.activeOrdersForceCloseModal} onClose={this.closeActiveOrdersForceCloseModal} styles={modalStyles}>
					<form method="POST" className="col-lg-12 col-xl-12 pr-4 pt-4 theme-form" onSubmit={this.handleActiveOrdersForceClose.bind(this)}>					
							<div className='row'>
								<div className="col-4"><label htmlFor="">Remarks <span className="text-danger">*</span></label></div>
								<div className="col-8">
									<textarea name="" id="" cols="30" rows="3" placeholder='Remarks' 
									style={{resize:"none",padding:"5px 0px 0px 10px"}}
									value={this.state.forceCloseRemarks}
									onChange={(e)=>{
										this.setState({
											forceCloseRemarks: e.target.value
										})
									}}
									required
									></textarea>
								</div>							
							</div>
							{/* <div className='row'>
								<div className="col-5">
									<label htmlFor="">Delivery Status <span className="text-danger">*</span></label>
									</div>
								<div className="col-7 p-0">
									<Select
										placeholder={"Delivery Status"}
										closeMenuOnSelect={true}
										onChange={this.newFilter.bind(this, "delivery_status")}
										className={"col-xl-12 col-lg-12 border-radius-0"}
										style={{ borderRadius: "0px" }}
										name="customers"
										value={this.state.delivery_status}
										options={this.state.deliveryStatusOptions}
										required 
									/>
								</div>	
							</div> */}
							<div className='row justify-content-center mt-2'>
								<button className="btn btn-info">Force Close</button>
							</div>
					</form>		
				</Modal>
				{(this.state.deptcode == "LOG-TNP") ?
					<div className="button-group">
						<button className={"btn " + this.state.consBtn} onClick={this.onClickTab.bind(this, 1)}>{this.state.tnpButtonTitle} Consignments</button>
						<button className={"btn " + this.state.returnConsBtn} onClick={this.onClickTab.bind(this, 2)}>{this.state.tnpButtonTitle} Return Consignments</button>
					</div>
					: ""}
				<div className="row" style={{ marginBottom: "1%" }}>
					<div className="col-xl-12 col-lg-12">
						{this.state.userType == "DISTRIBUTOR" ? 
						<div className="float-right">
							<button type="button" className="btn btn-success float-right" onClick={this.onClickShowForm.bind(this)}>Tatkal Order</button>
							<button type="button" style = {{marginRight:"1em"}}  className="btn btn-warning float-right" onClick={this.onClickShowForm1.bind(this)}>Bulk Upload</button>
						</div>
						: ""}
						<button onClick={this.reloadPageData.bind(this)} className="btn btn-info" style={{ float: "right",marginRight:"1em"}}>Reset Filters</button>
					</div>
				</div>
				<div className="row">
					<div className="col-xl-4 col-lg-4">
						<div className="card" style={filterCardStyle1}>
							<div className="row card-body" style={{ padding: "10px 30px" }}>
								<form className="row" onSubmit={this.getTruckConsignments}>
									{/* <div className={filterClassName}>
										<label>Select Type :  </label>
										<Select
											className="border-radius-0"
											isMulti={false}
											id="selectedFilter"
											style={{ borderRadius: "0px" }}
											options={[{ label: "Truck", vaue: "Truck" }, { label: "GR No", value: "Consingments" }, { label: "Invoice", value: "Invoice" }]}
											onChange={e => { this.setState({ selectedFilter: e.label }) }} required />
									</div> */}
									<div className="form-group col-xl-12 col-lg-12 mt-auto">
										<label className="reason">Order No</label>
										<input type="text" name="reason" autoComplete="off" className="form-control" id="inputConsignmentNo" />
									</div>
									<div className="form-group col-xl-12 col-lg-12" style={{ textAlign: "left" }}>
										<button type="submit" className="btn btn-success">Submit</button>
									</div>
								</form>
							</div>
						</div>
					</div>
					{/* <div className="" id="or" style={{padding:"33px 0px 0px 10px"}}>OR</div> */}
					<div className="col-xl-8 col-lg-8">
						<div className="card" style={filterCardStyle1}>
							<div className="row card-body" style={filterCardStyle2}>
								{(this.state.deptcode == 'LOG-TNP') ?
									<div className="row col-xl-12 col-lg-12">
										<div className="row col-xl-12 col-lg-12">
											<div className="form-group col-xl-3 col-lg-3">
												<label>Trip Type: </label>
												<Select placeholder={"All"} value={this.state.triptype} className="border-radius-0" onChange={this.onSelectTrip.bind(this)} style={{ borderRadius: "0px" }} options={tripoptoins} required />
											</div>

											<div className="form-group col-xl-3 col-lg-3">
												<label>Select Consigner: </label>
												<Select placeholder={"All"} closeMenuOnSelect={false} value={this.state.consigner} isMulti="true" className="border-radius-0" onChange={this.changeConsignerArrayHandler.bind(this)} style={{ borderRadius: "0px" }} options={this.selectConsignerOptionsItems()} required />

											</div>
											<div className="col-xl-3 col-lg-3 form-group">
												<label>From Date</label>
												<Datetime 
													value={this.state.startDate}
													disableCloseOnClickOutside={false}
													closeOnSelect={true}
													inputProps={{ placeholder: 'From', name: 'startDate', autoComplete: 'off', readOnly: true, value: this.state.startDate }}
													dateFormat="YYYY-MM-DD"
													name="startDate"
													onChange={this.handlerStartDateTime.bind(this)} />
													
												{/* <input type="text" placeholder="From Date" id="from_date" value={this.state.startDate} autoComplete="off"  className="datetimepicker_date form-control"  required /> */}

												{/* <input type="text" placeholder="From Date" id="from_date" value={this.state.startDate} autoComplete="off"  className="datetimepicker_date form-control"  required /> */}

											</div>

											<div className="col-xl-3 col-lg-3 form-group">
												<label>To Date</label>
												<input type="text" placeholder="To Date" autoComplete="off" id="to_date" value={this.state.endDate}  className="datetimepicker_date form-control"  required />
												{/* <Datetime 
													value={this.state.endDate}
													disableCloseOnClickOutside={false}
													closeOnSelect={true}
													inputProps={{ placeholder: 'To', name: 'endDate', autoComplete: 'off', readOnly: true }}
													dateFormat="YYYY-MM-DD"
													onChange={this.handlerEndDateTime.bind(this)} /> */}

											</div>
											{/* <div className="form-group col-xl-12 col-lg-12">
										<button type="button" style={{margin:"0 auto"}} className="btn btn-success" onClick={this.onClickTripButton.bind(this)}>Submit</button>
									</div> */}

										</div>
									</div>
									: ""}

								{(this.state.deptcode == 'LOG-PRT' || this.state.deptcode == 'SNDG') ?
									<div className="row col-xl-12 col-lg-12" style={mnFilterClass}>
										{/* {(this.state.deptcode == 'LOG-PRT') ?

											<div className="form-group col-xl-2 col-lg-2 n-p-0">
												<label>Select Type: </label>
												<Select placeholder={"All"} closeMenuOnSelect={true} value={this.state.movementtype} className="border-radius-0" onChange={this.changeMovementArrayHandler.bind(this)} style={{ borderRadius: "0px" }} options={this.selectMovementTypeOptionsItems()} required />

											</div>
											: ""} */}
										<div className="col-xl-3 col-lg-3 form-group">
											<label>From Date</label>
											{/* <input type="text" placeholder="From Date" id="from_date" value={this.state.startDate} autoComplete="off"  className="datetimepicker_date form-control"  required /> */}
											<Datetime
												value={this.state.startDate}
												disableCloseOnClickOutside={false}
												closeOnSelect={true}
												inputProps={{ placeholder: 'From', name: 'startDate', autoComplete: 'off', readOnly: true, value: this.state.startDate }}
												dateFormat="YYYY-MM-DD"
												name="startDate"
												onChange={this.handlerStartDateTime.bind(this)} />
										</div>

										<div className="col-xl-3 col-lg-3 form-group">
											<label>To Date</label>
											{/* <input type="text" placeholder="To Date" id="to_date" value={this.state.endDate} autoComplete="off"  className="datetimepicker_date form-control"  required /> */}
											<Datetime
												value={this.state.endDate}
												disableCloseOnClickOutside={false}
												closeOnSelect={true}
												inputProps={{ placeholder: 'To', name: 'endDate', autoComplete: 'off', readOnly: true }}
												dateFormat="YYYY-MM-DD"
												onChange={this.handlerEndDateTime.bind(this)} />
										</div>
										<div className="form-group col-xl-4 col-lg-4">
											<label>Select Territory: </label>
											<Select
												closeMenuOnSelect={false} 
												value={this.state.flConsigner} 
												isMulti={true}
												className="border-radius-0" 
												onChange={this.newFilter.bind(this, "flConsigner")} 
												style={{ borderRadius: "09px" }} 
												options={this.state.filterConsigners} 
												required />
										</div>
										{/* {(this.state.deptcode == 'LOG-PRT')?
								<div className="form-group col-xl-12 col-lg-12 n-p-0">
									<button type="button" className="btn btn-success" onClick={this.onGetConsigners.bind(this)}>Submit</button>
								</div>:""} */}

									</div>
									: ""}

								<div className="row col-xl-12 col-lg-12" style={{ marginLeft: "0px", padding: "0px" }}>
									
									{(this.state.deptcode == 'LOG-PRT' || this.state.deptcode == 'SNDG') ?
										<div className="form-group col-xl-3 col-lg-3">
											<label>Distributor :  </label>
											<Select
												className="border-radius-0"
												isMulti={true}
												id="selectedDistributor"
												style={{ borderRadius: "0px" }}
												value={this.state.flConsignee}
												onChange={this.newFilter.bind(this, "flConsignee")}
												options={this.state.filterConsignees}
											/>
										</div>
										: ""}
									{(this.state.deptcode == 'LOG-PRT' || this.state.deptcode == 'SNDG') ?
										<div className="form-group col-xl-3 col-lg-3">
											<label>Area :  </label>
											<Select
												className="border-radius-0"
												isMulti={true}
												id="selectedCustomerArea"
												style={{ borderRadius: "0px" }}
												value={this.state.flArea}
												onChange={this.newFilter.bind(this, "flArea")}
												options={this.state.filterCustomerAreas}
											/>
										</div>
										: ""}
									{(this.state.deptcode == 'LOG-PRT' || this.state.deptcode == 'SNDG') ?
										<div className="form-group col-xl-3 col-lg-3">
											<label>City :  </label>
											<Select
												className="border-radius-0"
												isMulti={false}
												id="selectedCity"
												style={{ borderRadius: "0px" }}
												value={this.state.flCity}
												onChange={this.newFilter.bind(this, "flCity")}
												options={this.state.filterCities}
											/>
										</div>
										: ""}
									{(this.state.deptcode == 'LOG-PRT' || this.state.deptcode == 'SNDG') ?
										<div className="form-group col-xl-3 col-lg-3">
											<label>State :  </label>
											<Select
												className="border-radius-0"
												isMulti={false}
												id="selectedState"
												style={{ borderRadius: "0px" }}
												value={this.state.flState}
												onChange={this.newFilter.bind(this, "flState")}
												options={this.state.filterStates}
											/>
										</div>
										: ""}
									
									{(this.state.deptcode == 'SNDG') ?
										<div className="form-group col-xl-3 col-lg-3">
											<label>Zone :  </label>
											<Select
												className="border-radius-0"
												isMulti={false}
												id="selectedCluster"
												style={{ borderRadius: "0px" }}
												value={this.state.flZone}
												onChange={this.newFilter.bind(this, "flZone")}
												options={this.state.filterZones}
											/>
										</div>
										: ""}
									{/* <div className="form-group col-xl-3 col-lg-3">
										<label>Transporter :  </label>
										<Select
											className="border-radius-0"
											isMulti={false}
											id="selectedTransporter"
											style={{ borderRadius: "0px" }}
											value={this.state.flTransporter}
											onChange={this.newFilter.bind(this, "flTransporter")}
											options={this.state.filterTransporters}
										/>
									</div> */}
									{/* <div className="form-group col-xl-12 col-lg-12" style={{textAlign:"left"}}>
										<button type="submit"  className="btn btn-success">Submit</button> &nbsp;&nbsp;
										<button type="button" onClick={this.ClearNewFilter.bind(this)} className="btn btn-danger">Clear</button>
									</div> */}
								</div>
								{(this.state.deptcode == "LOG-TNP") ?
									<div className="form-group col-xl-12 col-lg-12">
										<button type="button" style={{ margin: "0 auto" }} className="btn btn-success" onClick={this.onClickTripButton.bind(this)}>Submit</button>
									</div>
									:
									<div className="row col-xl-12 col-lg-12" style={{ marginLeft: "0px" }}>
										<button type="button" className="btn btn-success" onClick={this.onGetConsigners.bind(this)}>Submit</button>
									</div>
								}
							</div>
						</div>
					</div>
				</div>
				{

					(this.state.isCounterDisplay == 1 ) ?
						<Counters
							activeDataCounters = {this.state.activeDataCounters}
							onTimeDeliveries={this.state.onTimeDeliveries}
							delayedDeliveries={this.state.delayedDeliveries}
							forceClosedDeliveriesData= {this.state.forceClosedDeliveriesData}
							hide={hideonlyCol}
							context={this}
							countersjson={this.state.activeDataCounters}
							// countersjson={this.state.countersjson}
							unloadshow={hideonlyCol}
							gridData={this.state.rowData}
							deptcode={this.state.deptcode}
							triptype={this.state.tripvalue}
							counterScreen={this.state.counter_screen}
							path={this.props.match.path}
							returnConsignments={this.state.tnpReturnConsignmentsVisibilty}
							priority_counter_display={this.state.priority_counter_display}
							truckReportedDate={this.state.truckReportedDate.length}
							withoutTruckGateOutTimeData = {this.state.withoutTruckGateOutTimeData}
						/>

						: ""
				}

				<div className="row">
					<div className="col-xl-12 col-lg-12">
						<div className="card">
							<div className="card-header">
								<h5>
									<i className="icofont icofont-vehicle-delivery-van cus-i"></i> <span>{this.state.pagetitle} </span>
								</h5>
								<span className="layoutbtns"> 
									<button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
										<i className="icofont icofont-save"></i> Save Grid Layout
									</button>
								</span>
							</div>
							<div className="card-body pt-10px" >
								{/* <div className="row">
									<div className="col-xl-12 col-lg-12 mb-10p">

										<span className="float-right" style={{ fontSize: "13px", fontWeight: "100" }}>
											<i className="icofont icofont-map-pins f20" style={{ fontSize: "22px" }}></i> - View Route
											&nbsp;
											<i className="icofont icofont-clock-time" style={{ color: "#ff0000", fontSize: "22px	" }}></i> - Transit Delay
											&nbsp;<i className="icofont icofont-social-google-map txt-danger" style={{ fontSize: "22px" }}></i> - GPS Data Not Available
											&nbsp;<i className="icofont icofont-social-google-map txt-secondary" style={{ fontSize: "22px" }}></i> - GPS Data Not Adequate
											&nbsp;
											{(this.state.tnpReturnConsignmentsVisibilty == false) ?
												<span>
													<i className="fa fa-tachometer txt-success" style={{ fontSize: "22px" }}></i> - Overspeed
												</span>
												: ""}
											{!(this.state.deptcode == 'LOG-PRT') ?
												<span>
													{(this.state.tnpReturnConsignmentsVisibilty == false) ?
														<span>&nbsp;<i className="icofont icofont-full-night txt-primary" style={{ fontSize: "22px" }}></i> - Night Driving
														</span>
														: ""}
													{(this.state.tnpReturnConsignmentsVisibilty == false) ?
														<span>&nbsp;<i className="icofont icofont-truck-alt darkorange" style={{ fontSize: "22px" }}></i> - Loading Delay
														</span>
														: ""}


												</span>
												: ""}
											&nbsp;<i className="icofont icofont-pause maroon" style={{ fontSize: "22px	" }}></i> - Enroute Stoppages
											{
												(this.props.match.path == "/sndallconsignments") ||
													(this.props.match.path == "/snddeliveredconsignments") ?
													<span><i className="icofont icofont-vehicle-delivery-van" style={{ fontSize: "22px" }}></i> - Unloading Delay</span> : ''}
											&nbsp;
											<span>&nbsp;<i className="icofont icofont-list" style={{ fontSize: "22px" }}></i> - Day Wise Distance Trip
											</span>
											&nbsp; */}
											{/* {showForDeliveryPrt == false ?																		
											<span>&nbsp;<img src={tollImg} alt="toll_img" style={{ width: "24px",height: '25px' }}></img> - Tolls Details
											</span>  : ''} */}
										{/* </span> */}

									{/* </div>
									<div className="col-xl-12 mb-10p">
										{((this.props.match.path == "/sndconsignments" || this.props.match.path == "/prtconsignments" || this.props.match.path == "/tnpconsignments") && (this.state.tnpReturnConsignmentsVisibilty == false)) ?
											<button className="float-left custom-btn btn-warning" onClick={this.onClickShowMapView.bind(this)}>
												<i className="icofont icofont-social-google-map"></i> Map View
											</button>
											: ""}
										<span className="layoutbtns"> */}


											{/* <button className="float-right custom-btn white btn-danger" onClick={this.onClickSaveGridState.bind(this)}>
													<i className="icofont icofont-save"></i> Save Grid Layout
												</button>
												<button className="float-right custom-btn white btn-info" style={{marginRight:"10px"}} onClick={this.resetState.bind(this)}>
													<i className="icofont icofont-refresh"></i> Reset Default Layout
												</button> */}
											{/*(this.props.match.path == "/prtconsignments")?
													<button type="button" className="float-right custom-btn btn-success" style={{marginRight:"10px"}} onClick={this.onShowUploadEWay}>
														Upload Eway Enquiry
													</button>
												:""}
												{(this.props.match.path == "/prtconsignments")?
													<div className="float-right">
													<button
														type="button"
														title="Bulk Upload"
														onClick={this.onBulkPriorityBtn}
														className={"float-right custom-btn white btn-danger mr-2 "+(this.state.hideTransporterBtns)}
														type="button">
														Bulk Mark Priority
													</button>
												</div>
											:""*/}
										{/* </span>
									</div>
								</div> */}
								{/*
				   				   <Grid
								   ashow={this.state.show}
								   atype={this.state.basicType}
								   atitle={this.state.basicTitle}
								   actionCall={"manage"}
									actionMethod={"updateTruckRow"}
									table={"trucklocation"}
									columnyaxis={this.state.rowData}
									columnaxis={columnwithDefs}
									frameworkComponents={this.state.frameworkComponents}
									detailCellRendererParams={this.state.detailCellRendererParams}
									childconfs={this.state.childconfs}
									childrow={this.state.childrow}
									context={this }

									/>
									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div class="loader-box"><div class="loader-box"><div class="rotate dashed colored"></div></div></div>
									</div>
								*/}
								

								<div id="myGrid" style={{ height: "580px", width: "100%" }} className="ag-theme-balham">
									<AgGridReact
										modules={this.state.modules}
										columnDefs={columnwithDefs}
										defaultColDef={this.state.defaultColDef}
										rowData={this.state.rowData}
										// onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
										enableCharts={true}
										enableRangeSelection={true}
										autoGroupColumnDef={this.state.autoGroupColumnDef}
										onGridReady={this.onGridReady}
										onGridState={this.onGridState}
										statusBar={this.state.statusBar}
										sideBar={this.state.sideBar}
										paginationPageSize={this.state.paginationPageSize}
										pagination={true}
										frameworkComponents={this.state.frameworkComponents}
										detailCellRendererParams={this.state.detailCellRendererParams}
										//editType={this.state.editType}
										stopEditingWhenGridLosesFocus={true}
										//floatingFilter={true}

										enableCellChangeFlash={false}
										//suppressCellFlash={true}
										rowClassRules={this.state.rowClassRules}
										onCellDoubleClicked={this.onRowClicked.bind(this)}
										gridOptions={{
											context: { componentParent: this }
										}}
										masterDetail={true}
										// getRowHeight={this.state.getRowHeight}
										// onCellEditingStopped={this.onCellUpdateData.bind(this)}

										rowSelection={this.state.rowSelection}
										onRowSelected={this.onRowSelection.bind(this)}
										suppressRowClickSelection={true}
										// suppressCellSelection= {true}
										overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
										excelStyles={this.state.excelStyles}
										onCellClicked={this.onCellClicked.bind(this)}
									/>

									{/*
									<div className={"dataLoadpage " +(this.state.loadshow)}>
									</div>
									<div className={"dataLoadpageimg " +(this.state.loadshow)}>
										<div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
									</div> */}

								</div>
							</div>
						</div>
					</div>

				</div>
				<div className={"slide-r " + (this.state.sliderMarkDeliverTranslate)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							Mark Delivered
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll} >X</span>

						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', overflow: 'hidden', zIndex: '9999', height: "302px" }} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formMarkDeliverHandler}>

								{/* <div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Delivery Time</label>
									<input type="text" placeholder="" autoComplete="off" class="form-control datetimepicker_date" value={this.state.markDeliverDefault} id="delivery_date_time" required />
								</div> */}
								<div className="form-group col-xl-12 col-lg-12">
										<label>Delivery Date: </label>
										<Datetime
											// id="delivery_date_time" 
											value={this.state.markDeliverDefault}
											disableCloseOnClickOutside={false}
											closeOnSelect={true}
											inputProps={{ placeholder: 'Delivery Date', name: 'markDeliverDefault', autoComplete: 'off' }}
											dateFormat="YYYY-MM-DD"
											onChange={this.onChangeMarkedDeliverDate.bind(this)}
											required />
								</div>
								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
							</form>
						</div>
					</div>
				</div>
				{this.state.sliderTranslate != '' ?
					<div className={"slide-r " + (this.state.sliderTranslate)} style={{ overflow: "auto" }}>
						<div className="slide-r-title">
							<h4>
								Timeline #{this.state.timelinedata[0].consignment_code}
								<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onCloseUploadDiv.bind(this)} >X</span>

							</h4>
						</div>
						<div className="slide-r-body" style={{ position: "relative" }}>

							<div className="row col-xl-12 col-lg-12">
								<VerticalTimeline>
									<VerticalTimelineElement
										className="vertical-timeline-element--work"
										contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff', borderTopLeftRadius: "20%", borderBottomRightRadius: "20%" }}
										contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
										date="Basic Information"
										iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}

									>
										<h3 className="vertical-timeline-element-title">Truck No</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].truck_no}</h4>
										<h3 className="vertical-timeline-element-title">Transporter Code</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].transporter_code}</h4>
										<h3 className="vertical-timeline-element-title">Department Code</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].dept_code}</h4>
										<h3 className="vertical-timeline-element-title">Vehicle Mode</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].vehicle_mode}</h4>
										<h3 className="vertical-timeline-element-title">Items</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].items}</h4>
										<h3 className="vertical-timeline-element-title">Distance (Kms)</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].distance_in_km}</h4>

									</VerticalTimelineElement>


									<VerticalTimelineElement
										className="vertical-timeline-element--work"
										date="Gate in time"
										contentStyle={{ background: 'rgb(234, 68, 99)', color: '#fff', borderTopRightRadius: "20%", borderBottomLeftRadius: "20%" }}
										contentArrowStyle={{ borderRight: '7px solid  rgb(243, 33, 33)' }}
										iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}

									>
										<h3 className="vertical-timeline-element-title">MSIL Gate intime</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].gate_in_time}</h4>

										<h3 className="vertical-timeline-element-title">GPS Gate intime</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].inside_fence}</h4>

										<h3 className="vertical-timeline-element-title">Invoice time</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].invoice_time}</h4>

									</VerticalTimelineElement>

									<VerticalTimelineElement
										className="vertical-timeline-element--work"
										date="MSIL Gate out time"
										contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff', borderTopLeftRadius: "20%", borderBottomRightRadius: "20%" }}
										contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
										iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}

									>
										<h3 className="vertical-timeline-element-title">MSIL Gate out time</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].gate_out_time}</h4>

										<h3 className="vertical-timeline-element-title">GPS Gate out time</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].outside_fence}</h4>

									</VerticalTimelineElement>

									<VerticalTimelineElement
										className="vertical-timeline-element--work"
										date="Consignee Code"
										contentStyle={{ background: 'rgb(234, 68, 99)', color: '#fff', borderTopRightRadius: "20%", borderBottomLeftRadius: "20%" }}
										contentArrowStyle={{ borderRight: '7px solid  rgb(243, 33, 33)' }}
										iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}

									>
										<h3 className="vertical-timeline-element-title">Consignee Code</h3>
										<h4 className="vertical-timeline-element-subtitle">{this.state.timelinedata[0].consignee_code}</h4>

									</VerticalTimelineElement>

								</VerticalTimeline>

							</div>
						</div>
					</div>
					: ""
				}

				{this.state.sliderRouteTranslate != '' ?
					<div className={"slide-r " + (this.state.sliderRouteTranslate)} style={{ overflow: "auto" }}>
						<div className="slide-r-title">
							<h4>
								{(this.state.consignment_code)}
								<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onCloseRouteDiv.bind(this)} >X</span>

							</h4>
						</div>
						<div className="slide-r-body" style={{ position: "relative" }}>

							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
								<DrawMap
									context={this}
									rownode={this.state.rownode}
									mapFor={"consignment"}
									tolls={this.state.tolls}
									googleroutes={this.state.googelRoutes}
									truckno={this.state.maptruckno}
									dealer={this.state.dealer}
									mapinfo={this.state.mapinfo}
									defTransitCoords={this.state.defTransitCoords}
									dept_code={this.state.deptcode}
									devation_flag={this.state.devation_flag}
									baseRouteCoords={this.state.baseRouteCoords} />
								{/*
								<div id="map" className="" style={{width:'100%',height:"70vh"}}></div>
								*/}
								<div className="col-xl-12 col-lg-12 n-p-0">
									<div className="crm-numbers pb-0">
										<h3 className="subH">{this.state.sidebarSubHeader}</h3>
										<div className="col-xl-12 col-lg-12 row">
											<div className="col route-block">
												<label className="sidebar-label">Truck No</label>
												<div>{this.state.routeTruck.truck_no}</div>
											</div>
											{(maptransporter != '') ?
												<div className="col route-block">
													<label className="sidebar-label">Transporter</label>
													<div>{(!isEmpty(maptransporter)) ? maptransporter : ""}</div>
												</div>

												: ""}
											<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(this.state.routeTruck.startTime != '') ? getDDMMYYYYHHMMSS(this.state.routeTruck.startTime) : "NA"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(this.state.routeTruck.endTime != '') ? getDDMMYYYYHHMMSS(this.state.routeTruck.endTime) : "NA"}</div>
											</div>

											<div className="col route-block">
												<label className="sidebar-label">GPS Distance</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details)) ? Math.round(this.state.mapinfo.route_details.distance_without_google / 1000) : "0"} Kms</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">Google Distance</label>
												<div>{Math.round(this.state.mapinfo.route_details.distance / 1000)} Kms</div>
											</div>

											{/* {(this.state.leg_no == 0 || this.state.leg_no == null) ? ""
												:
												<div className="col route-block">
													<label className="sidebar-label">Google Distance</label>
													<div>{(this.state.rownode.actual_google_leg_distance) ? parseInt(this.state.rownode.actual_google_leg_distance) : "0"} Kms</div>
												</div>
											} */}

											{/*<div className="col route-block">
												<label className="sidebar-label">Start Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.start_time):"-"}</div>
											</div>
											<div className="col route-block">
												<label className="sidebar-label">End Time</label>
												<div>{(!isEmpty(this.state.mapinfo.route_details))?getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.end_time):"-"}</div>
											</div>
										*/}

										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
					: ""
				}

				{this.state.sliderCommentTranslate != '' ?
					<div className={"slide-r " + (this.state.sliderCommentTranslate)} style={{ overflow: "auto" }}>
						<div className="slide-r-title">
							<h4>
								Consignment Comments
								<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onCloseRouteDiv.bind(this)} >X</span>
							</h4>
						</div>
						<div className="slide-r-body" style={{ position: "relative" }}>
							<div id="myGrid1" style={{ height: "580px", width: "100%" }} className="ag-theme-balham">
								<AgGridReact
									modules={this.state.modules}
									columnDefs={commentColumns}
									defaultColDef={this.state.defaultColDef}
									rowData={this.state.commentsRowData}
									onCellDoubleClicked={this.onShowSidebarAquireComponent.bind(this)}
									enableCharts={true}
									enableRangeSelection={true}
									autoGroupColumnDef={this.state.autoGroupColumnDef}
									onGridReady={this.onGridReady}
									onGridState={this.onGridState}
									statusBar={this.state.statusBar}
									// sideBar={this.state.sideBar}
									paginationPageSize={this.state.paginationPageSize}
									pagination={true}

								/>



							</div>
						</div>

					</div>
					: ""
				}


				{this.state.sliderSegmentTranslate != '' ?
					<SegmentSideBar context={this} closeSegmentSideBar={this.closeSegmentSideBar} sliderValue={this.state.sliderSegmentTranslate} segmentConsignmentCode={this.state.segementConsignmentCode} onCloseRouteDiv={this.onCloseRouteDiv.bind(this)} />
					: ""
				}

				<Modal open={open} onClose={this.onCloseModal} classNames={{
					overlay: 'customOverlay',
					modal: 'customModal',
				}}>
					<div className={"row"} style={{ padding: '5px 0px', width: '100%', overflow: 'hidden', zIndex: '9999', }} >
						{(this.state.maprowData != '') ?
							<ConsignmentDrawMap
								context={this}
								griddata={this.state.maprowData}
								consigner_coordinates={this.state.consigner_coordinates}
								consigneecoords={this.state.consigneecoords}
								rownode={this.state.rownode} mapFor={"consignment"} />
							: ""}

					</div>
				</Modal>
				
				<Modal open={openforceclose} onClose={this.onCloseForceCloseModal} styles={modalStyles}>
					<div className={"row"} style={{ padding: '5px 0px', width: '100%', overflow: 'hidden', zIndex: '9999', }} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formForceCloseHandler.bind(this)}>
							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Reached Dealer Time</label>

								<input type="text" placeholder="Reached Dealer " id="reached_dealer_time" className="datetimepicker_mask form-control" required />

							</div>

							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Left Dealer Time</label>

								<input type="text" placeholder="Left Dealer " id="left_dealer_time" className="datetimepicker_mask form-control" required />

							</div>

							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Reason for closure</label>
								<textarea name="reasonforceclose" id="reasonforceclose" onChange={this.changeHandler.bind(this)} rows="5" value={this.state.reasonforceclose} className="form-control col-xl-12 col-lg-12"></textarea>
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>
					</div>
				</Modal>

				<div className={"slide-r " + (this.state.sliderForceCloseTranslate)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							Force Closure
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>

						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', overflow: 'hidden', zIndex: '9999', height: "100em" }} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formForceCloseHandler.bind(this)}>
								{/* <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Reached Dealer Time</label>

                                <input readonly type="text" placeholder="Reached Dealer " value={this.state.startDatetime} id="reached_dealer_time" className="datetimepicker_mask form-control" />

							</div>

							<div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Left Dealer Time</label>

                                <input readonly type="text" placeholder="Left Dealer " value={this.state.endDatetime} id="left_dealer_time" className="datetimepicker_mask form-control" />

							</div> */}
								<ForceCloseSideBar context={this} handleInput={this.handleInput} deptcode={this.state.deptcode} />
								{/* <div className="col-xl-12 col-lg-12 row form-group">
								<label className="col-xl-12 col-lg-12">Reason for closure</label>
								<textarea name="reasonforceclose" onChange={this.changeHandler.bind(this)} rows="5" value={this.state.reasonforceclose} className="form-control col-xl-12 col-lg-12" required></textarea>
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div> */}
							</form>
						</div>
					</div>
				</div>

				<div className={"slide-r " + (this.state.sliderTranslatesidebar)} >
					{this.displayData}
				</div>

				{(this.state.showumncr == 1) ?
					<div>
						<span className="usermanual f22" onClick={this.onClickShowUserManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span>
						<Modal open={usermanualncrmodal} onClose={this.onCloseUserManualModal.bind(this)} styles={modalStyles}>
							<div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
								<h5 className="model-title">NCR Consignments</h5>
								<div className="col-xl-12 col-lg-12 mt-20p">
									<div className="col-xl-12 col-lg-12">
										This screen is for SND department only. This screen shows those consignments which have cluster as NCR and originate from F,M plants. These are generally local movements happening. For these consignments there are no violation tickets generated except for No GPS data. The grid columns are similar to that of active consignment screen.
									</div>
									<div className="col-xl-12 col-lg-12">
										Image Here
									</div>
									<div className="col-xl-12 col-lg-12">
										The trips that are seen in this screen are active in system.
									</div>
									<div className="col-xl-12 col-lg-12">
										Save Grid Layout :  this filter is used to save the  interchanged columns.
									</div>
									<div className="col-xl-12 col-lg-12">
										Reset default layout :  this filter is used to reset the grid layout  to the default layout.
									</div>
								</div>
							</div>
						</Modal>
					</div>
					: ""}
				{(this.state.showummove == 1) ?
					<div>
						<span className="usermanual f22" onClick={this.onClickShowUserMoveManaul.bind(this)}><i className="icofont icofont-read-book-alt"></i></span>
						<Modal open={usermanualmovemodal} onClose={this.onCloseUserManualMoveModal.bind(this)} styles={modalStyles}>
							<div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
								<h5 className="model-title">Move Consignments</h5>
								<div className="col-xl-12 col-lg-12 mt-20p">
									<div className="col-xl-12 col-lg-12">
										Those consignments which are dispatched from Gurgaon plant to Manesar Plant (interplant movement) are shown in this screen. All those consignments that are active in system and have MOV as cluster are shown in this screen. The trips can be searched with the filters available in the screen.
									</div>
									<div className="col-xl-12 col-lg-12">
										Image Here
									</div>
									<div className="col-xl-12 col-lg-12">
										Grid option available in system are:
									</div>

									<div className="col-xl-12 col-lg-12">
										<table className="table table-border">
											<tr>
												<th>
													Column
												</th>
												<th>
													Remarks
												</th>
											</tr>
											<tr>
												<td>
													Truck No
												</td>
												<td>
													vehicle details
												</td>
											</tr>
											<tr>
												<td>
													Transporter code
												</td>
												<td>
													MSIL code for transporter
												</td>
											</tr>
											<tr>
												<td>
													Transporter Name
												</td>
												<td>
													Service provider name
												</td>
											</tr>
											<tr>
												<td>
													GR No
												</td>
												<td>
													Destination code
												</td>
											</tr>
											<tr>
												<td>
													Move Number
												</td>
												<td>
													MSIL parameter
												</td>
											</tr>
											<tr>
												<td>
													Selection
												</td>
												<td>
													default name
												</td>
											</tr>
											<tr>
												<td>
													VIN
												</td>
												<td>
													MSIL parameter
												</td>
											</tr>
											<tr>
												<td>
													Model
												</td>
												<td>
													Model of consignment
												</td>
											</tr>
											<tr>
												<td>
													Spec
												</td>
												<td>
													MSIL parameter
												</td>
											</tr>
											<tr>
												<td>
													Source
												</td>
												<td>
													Origin point
												</td>
											</tr>
											<tr>
												<td>
													Out Date
												</td>
												<td>
													Gate out details
												</td>
											</tr>
											<tr>
												<td>
													Destination
												</td>
												<td>
													Destination location
												</td>
											</tr>
											<tr>
												<td>
													In Date
												</td>
												<td>
													Gate in details
												</td>
											</tr>
											<tr>
												<td>
													Last City
												</td>
												<td>
													Last known GPS location
												</td>
											</tr>
											<tr>
												<td>
													Last State
												</td>
												<td>
													Last known GPS location
												</td>
											</tr>
											<tr>
												<td>
													Last Packet Time
												</td>
												<td>
													Data time packet
												</td>
											</tr>
										</table>
									</div>
								</div>
							</div>
						</Modal>
					</div>
					: ""}


				<div className={"slide-r " + (this.state.slideuploadeway)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							Upload Eway Expiry
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>

						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className="container-fluid">
							<form method="POST" id="upform" className="theme-form" onSubmit={this.uploadEway}>

								<div className="form-group mt-20p">
									<label className="">Upload File</label>
									<input type="file" name="uploadFile" onChange={this.changeFileHandler} className="form-control" required />
								</div>
								<div className="form-group">
									<button type="submit" className="btn btn-success">Submit</button>
									<button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
								</div>
							</form>

							<div className="form-group">
								<a className="btn btn-primary" href={require('../../assets/json/sample_upload_eway_expiry.csv')} target="_blank">Sample Template</a>

							</div>
						</div>
					</div>
				</div>

				<div className={"slide-r " + (this.state.bulkPrioritySlide)} style={{ overflow: "hidden" }}>
					<h3 className="subH">Bulk Priority</h3>

					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="container-fluid">
							<form method="POST" id="upform" className="theme-form" onSubmit={this.uploadBulkPriorityFormHandler.bind(this)}>

								<div className="form-group mt-20p">
									<label className="">Upload File</label>
									<input type="file" name="uploadFile" id="bulkUploadPID" onChange={this.changePriorityFileHandler} className="form-control" required />
								</div>
								<div className="form-group">
									<button type="submit" className="btn btn-success">Submit</button>
									<button type="button" onClick={this.resetUpload.bind(this)} className="btn btn-default">Cancel</button>
								</div>
							</form>
							<div className="form-group">
								<a className="btn btn-primary" href={require('../../assets/json/bulk_couriers_mark_deliver_sample.csv')} target="_blank">Sample Template</a>
							</div>
							<div className="form-group">
								{/* <a className="btn btn-primary" href={require('../../assets/json/Bulk_tickets_update_file.csv')} target="_blank">Sample Template</a> */}

							</div>
						</div>
					</div>
				</div>

				<div className={"slide-r " + (this.state.sliderRso)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							Bulk Upload
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>

						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>
						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{ padding: '5px 0px', width: '100%', zIndex: '9999', }} >
                            
                            <form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.onSubmitBulkUpload.bind(this)}>

                            <div className="col-xl-12 col-lg-12 row form-group">
                                <label className="col-xl-12 col-lg-12">Upload File *</label>
                                <input
                                    type="file"
                                    name="uploadfile"
									id = "uploadfile"
                                    className="form-control"
                                    onChange={this.changeFileHandlerFc.bind(this)} 
									required
									/>
                            </div>

                            <div className="col-xl-12 col-lg-12 form-group">
                                <button type="submit" className="btn btn-success">Submit</button>
                            </div>
                            <div className="col-xl-12 col-lg-12 form-group" style={{color:"red",fontWeight:"bold"}}>
                                Date format should be in dd-mm-yyyy hh:mm:ss
                            </div>
                            <div className="col-xl-12 col-lg-12 form-group">
                                <a href={require("../../assets/json/sample_upload_booking_data.csv")} target="_blank" className="btn btn-warning">Download Template</a>
                            </div>
                        </form>
                        
					</div>
				</div>
				</div>
				<div className={"slide-r " + (this.state.sliderCreateConsignmentTranslate)} style={{ overflow: "auto" }}>
					<div className="slide-r-title">
						<h4>
							Add Order
							<span className="float-right closebtn" style={{ marginRight: "25px" }} onClick={this.onClickHideAll.bind(this)} >X</span>

						</h4>
					</div>
					<div className="slide-r-body" style={{ position: "relative" }}>

						<div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div>
						<div className={"row"} style={{ padding: '5px 0px', width: '100%', overflow: 'hidden', zIndex: '9999', height: "100em" }} >
							<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.saveConsignmentData.bind(this)}>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Customer:</label>
									<Select
										placeholder={"Select Customer"}
										closeMenuOnSelect={true}
										onChange={this.handleCustomerChange.bind(this, "customer_val")}
										className={"col-xl-12 col-lg-12 border-radius-0"}
										style={{ borderRadius: "0px" }}
										name="customers"
										value={this.state.customer_val}
										options={this.state.filterCustomers}
										required 
									/>
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Order No:</label>

									{/* <Select
										placeholder={"Select Truck"}
										closeMenuOnSelect={true}
										onChange={this.onChangeTruckItem.bind(this)}
										className={"col-xl-12 col-lg-12 border-radius-0"}
										style={{ borderRadius: "0px" }}
										name="truck_no"
										value={this.state.truck_no}
										options={this.state.trucklist} /> */}
									<input
										// placeholder={"Enter Truck No"}
										type="text"
										className="form-control"
										onChange={(e) => this.setState({bookingNo: e.target.value})}
										value={this.state.bookingNo}
										name="bookingNo"
										autoComplete='off'
										required
									/>
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Area:</label>
									<label className="col-xl-12 col-lg-12">
										{
										this.state.custAreaCodeMap[`${this.state.customer_val.value}`] && this.state.custAreaMap[`${this.state.customer_val.value}`]? `${this.state.custAreaCodeMap[`${this.state.customer_val.value}`]} - ${this.state.custAreaMap[`${this.state.customer_val.value}`]}`: "_ - _"
										
										}
									</label>
									{/* <Select
										placeholder={"Select Area"}
										closeMenuOnSelect={true}
										onChange={this.newFilter.bind(this, "customerarea_val")}
										className={"col-xl-12 col-lg-12 border-radius-0"}
										style={{ borderRadius: "0px" }}
										name="customer_areas"
										value={this.state.customerarea_val}
										options={this.state.filterCustomerAreas}
										required 
									/> */}
								</div>
								
								{/* <div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Invoice No* :</label>
									<input
										type="text"
										className="form-control"
										onChange={this.changeHandler}
										value={this.state.invoice_no}
										name="invoice_no"
										autoComplete='off'
									/>
								</div> */}
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Booking Date:</label>
									<input
										type="text"
										className="form-control datetimepicker_mask"
										id="booking_date"
										name="booking_date"
										autoComplete='off'
										required
									/>
								</div>
								{/* <div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Trip Started On* :</label>
									<input
										type="text"
										className="form-control datetimepicker_mask"
										id="gate_out_time"
										name="gate_out_time"
										autoComplete='off'
									/>
								</div> */}
								{/* <div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Select Plant* :</label>

									<Select
										placeholder={"Select Plant"}
										closeMenuOnSelect={true}
										onChange={this.onChangePlantItem.bind(this)}
										className={"col-xl-12 col-lg-12 border-radius-0"}
										style={{ borderRadius: "0px" }}
										name="plant_code"
										value={this.state.plant_code}
										options={this.state.plantlist} />
								</div> */}

								{/* <div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Distributor Code:</label>

									<Select
										placeholder={"Select Distributor Code"}
										closeMenuOnSelect={true}
										onChange={this.onChangeConsigneeItem.bind(this)}
										className={"col-xl-12 col-lg-12 border-radius-0"}
										style={{ borderRadius: "0px" }}
										name="consignee_code"
										value={this.state.consignee_code}
										options={this.state.consignees} />
								</div> */}
								{/* <div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Driver Name* :</label>
									<input
										type="text"
										className="form-control"
										onChange={this.changeHandler}
										value={this.state.driver_name}
										name="driver_name"
										autoComplete='off'
									/>
								</div> */}
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Customer Mobile No:</label>
									<label className="col-xl-12 col-lg-12">
										{this.state.custMobileMap[`${this.state.customer_val.value}`]}
									</label>
									{/* <input
										type="text"
										className="form-control"
										onChange={this.changeHandler}
										value={this.state.customer_mobile_no}
										name="customer_mobile_no"
										autoComplete='off'
									/> */}
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Product Code:</label>
									<input
										type="text"
										className="form-control"
										onChange={(e) => this.setState({productCode: e.target.value})}
										value={this.state.productCode}
										name="productCode"
										autoComplete='off'
									/>
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Refill Type:</label>
									<input
										// placeholder={"Enter Truck No"}
										type="text"
										className="form-control"
										onChange={(e) => this.setState({refillType: e.target.value})}
										value={this.state.refillType}
										name="refillType"
										autoComplete='off'
									/>
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Delivery Flag:</label>
									<Select
										// placeholder={"Enter Truck No"}
										closeMenuOnSelect={true}
										className={"col-xl-12 col-lg-12 border-radius-0"}
										style={{borderRadius: "0px"}}
										onChange={this.newFilter.bind(this, "deliveryFlag")}
										options={[{label: "Booked Not Printed", value: "Booked Not Printed"}, {label: "Scheduled", value: "Scheduled"}]}
										value={this.state.deliveryFlag}
										name="deliveryFlag"
										autoComplete='off'
										required
									/>
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">No Of Cylinders:</label>
									<label className="col-xl-12 col-lg-12">
										{this.state.custCylinderMap[`${this.state.customer_val.value}`]}
									</label>
									{/* <input
										// placeholder={"Enter Truck No"}
										type="text"
										className="form-control"
										onChange={(e) => this.setState({noOfCylinders: e.target.value})}
										value={this.state.noOfCylinders}
										name="noOfCylinders"
										autoComplete='off'
										required
									/> */}
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Payment Option:</label>
									<Select
										// placeholder={"Enter Truck No"}
										closeMenuOnSelect={true}
										className={"col-xl-12 col-lg-12 border-radius-0"}
										style={{borderRadius: "0px"}}
										onChange={this.newFilter.bind(this, "paymentOption")}
										options={[{value: "Online", label: "Online"}, {value: "COD", label: "COD"}]}
										value={this.state.paymentOption}
										name="paymentOption"
										autoComplete='off'
										required
									/>
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Delivery Person Name:</label>
									<input
										// placeholder={"Enter Truck No"}
										type="text"
										className="form-control"
										
										onChange={(e) => {
											let regex = /^[a-zA-Z ]*$/;
											if(regex.test(e.target.value)){
												this.setState({deliveryPersonName: e.target.value})
											}
										}}
										value={this.state.deliveryPersonName}
										// value={this.state.areaDeliveryMap[`${this.state.custAreaCodeMap[`${this.state.customer_val.value}`]}`]}
										name="deliveryPersonName"
										autoComplete='off'
										required
									/>
								</div>
								<div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Delivery Person Mobile No:</label>
									<input
										// placeholder={"Enter Truck No"}
										type="text"
										className="form-control"
										// onChange={(e) => this.setState({deliveryPersonMobileNo: e.target.value})}
										onChange={(event) => {
											const input = event.target.value;
											var regex = /^[6,7,8,9][0-9]{0,9}$/;
											if (
											  event.nativeEvent.inputType ===
											  "deleteContentBackward"
											) {
											  this.setState({ deliveryPersonMobileNo: input });
											} else if (
											  event.nativeEvent.inputType === "insertText"
											) {
											  if (regex.test(input)) {
												this.setState({ deliveryPersonMobileNo: input });
											  }
											}
										  }}
										value={this.state.deliveryPersonMobileNo}
										// value={this.state.areaDeliveryMobileMap[`${this.state.custAreaCodeMap[`${this.state.customer_val.value}`]}`]}
										name="deliveryPersonMobileNo"
										autoComplete='off'
										required
									/>
								</div>
								{/* <div className="col-xl-12 col-lg-12 row form-group">
									<label className="col-xl-12 col-lg-12">Transporter Name* :</label>

									<Select
										placeholder={"Select Transporter Name"}
										closeMenuOnSelect={true}
										onChange={this.onChangeTransporterItem.bind(this)}
										className={"col-xl-12 col-lg-12 border-radius-0"}
										style={{ borderRadius: "0px" }}
										name="transporter_code"
										value={this.state.transporter_code}
										options={this.state.transportersdata} />
								</div> */}
								<div className="col-xl-12 col-lg-12 form-group">
									<button type="submit" className="btn btn-success">Submit</button>
								</div>
							</form>
						</div>
					</div>
				</div>

			</div>

		);
	}
}



// function loadScript(url){
//     var index  =window.document.getElementsByTagName("script")[0]
//     var script = window.document.createElement("script")
//     script.src=url
//     script.async=true
//     script.defer = true
//     index.parentNode.insertBefore(script,index)
// }
function isEmpty(obj) {
	for (var key in obj) {
		if (obj.hasOwnProperty(key))
			return false;
	}
	return true;
}
function timeConvert(n) {
	// var num = n/1000;
	// var hours = (num / (3600*24));
	// var rhours = Math.floor(hours);
	// var minutes = (n) / (60*60);
	// var rminutes = Math.round(minutes);

	var diffMs = n;
	var diffDays = Math.floor(diffMs / 86400000); // days
	var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
	var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes


	return diffHrs + " hour(s) and " + (diffMins / 60) + " minute(s).";
}
var currentinfowindow = null;
function getTimeInfo(marker, timedet, coords) {
	var timeinfo = new window.google.maps.InfoWindow({
		content: "Time at this location: " + timedet
	});

	marker.addListener('click', function () {

		if (currentinfowindow != null) {
			currentinfowindow.close();
			currentinfowindow = timeinfo;
			timeinfo.open(marker.get('map'), marker);
		}
		else {
			currentinfowindow = timeinfo;
			timeinfo.open(marker.get('map'), marker);
		}

	});
}



function secondsToString(seconds) {
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays + " days " + numhours + " hours " + numminutes + " minutes ";
}

function secondsToDays(seconds) {
	var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numdays;
}
function CheckForHtmlTag() {
	var rs = document.getElementById("invalid_reason");
	var reg = /<(.|\n)*?>/g;
	if (reg.test(rs.value)) {
		var ErrorText = "Oops! HTML or Script is not allowed.";
		rs.value = ''
		//alert();
		return 1;
	}
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	console.log("dateComparator",date1, date2);
	// console.log(date1.length);
	if (date1 != "" && date2 != "" && date1 != undefined && date2 != undefined) {
		// console.log(date1,date2);
		var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
		date1 = date1.replace(date1.split(" ")[0].split("-")[1], date_1);
		var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
		date2 = date2.replace(date2.split(" ")[0].split("-")[1], date_2);
		// console.log(date1,date2);
		var date1Number = monthToComparableNumber(date1);
		var date2Number = monthToComparableNumber(date2);
		// console.log(date1Number,date2Number);
		if (date1Number === null && date2Number === null) {
			return 0;
		}
		if (date1Number === null) {
			return -1;
		}
		if (date2Number === null) {
			return 1;
		}
		return date1Number - date2Number;
	}
}
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	//console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName) {
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
	];
	var monthNum = months.indexOf(monthName) + 1;
	if (monthNum < 10) {
		monthNum = "0" + monthNum.toString();
	}
	return monthNum;
}

function loadDateTimeScript() {
	$('#reached_dealer_time').datetimepicker({
		mask: '39-19-9999 29:59',
		format: 'd-m-Y H:i',
		onShow: false
	});
	$('#left_dealer_time').datetimepicker({
		mask: '39-19-9999 29:59',
		format: 'd-m-Y H:i',
		onShow: false
	});
	$('.datetimepicker_date').datetimepicker({
		mask: '39-19-9999',
		format: 'd-m-Y',
		timepicker: false,
		onShow: false
	});
	$('#invoice_date').datetimepicker({
		mask: '39-19-9999 29:59',
		format: 'd-m-Y H:i'
	});
	$('#booking_date').datetimepicker({
		mask: '39-19-9999 29:59',
		format: 'd-m-Y H:i'
	});
	$('#gate_out_time').datetimepicker({
		mask: '39-19-9999 29:59',
		format: 'd-m-Y H:i'
	});

	var index = window.document.getElementsByTagName("script")[1]
	var script = window.document.createElement("script")
	script.src = "https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async = true
	script.defer = true
	index.parentNode.insertBefore(script, index)
}


function GetSortDescOrder(prop) {
	return function (a, b) {
		if (a[prop] < b[prop]) {
			return 1;
		} else if (a[prop] > b[prop]) {
			return -1;
		}
		return 0;
	}
}


function GetSortAscOrder(prop) {
	return function (a, b) {
		if (a[prop] > b[prop]) {
			return 1;
		} else if (a[prop] < b[prop]) {
			return -1;
		}
		return 0;
	}
}

function secondsToDhms(seconds) {
	seconds = Number(seconds);
	var d = Math.floor(seconds / (3600 * 24));
	var h = Math.floor((seconds % (3600 * 24)) / 3600);
	var m = Math.floor((seconds % 3600) / 60);
	var s = Math.floor(seconds % 60);

	var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
	var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
	var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
	var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
	return dDisplay + hDisplay + mDisplay;
}

function secondsToMinutesSeconds(seconds) {
	// var numdays = Math.floor(seconds / 86400);
	var numhours = Math.floor((seconds % 86400) / 3600);
	var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
	var numseconds = ((seconds % 86400) % 3600) % 60;
	return numhours + " hours " + numminutes + " minutes " + numseconds + ' seconds';
}
function checkIfArrayIsUnique(myArray) {
    return myArray.length === new Set(myArray).size;
}